import React, {PureComponent} from 'react';
import * as _ from "lodash";
import {getCDNUrl} from "../commons/CommonFunctions";

class BusinessPageItem extends PureComponent {

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImageUrl;
    }

    render() {

        return (
            <li className="postCardBlk">
                <a href="javascript:void(0)" onClick={this.props.setAsUser}>
                    <figure className="usimg">
                        <img
                            src={!_.isUndefined(this.props.item.profileImageUrl) && !_.isNull(this.props.item.profileImageUrl) ? getCDNUrl(this.getOptimizedImage(this.props.item)) : "https://cdn.dscovr.com/images/DefaultBusiness.webp"}
                            onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/DefaultBusiness.png"}}
                            alt=""/>
                    </figure>
                    <div>
                        <h5>{this.props.item.companyName}</h5>
                        <span>Organization</span>
                    </div>
                </a>
            </li>
        );
    }
}

export default BusinessPageItem;
