import React, { Component } from 'react'
import { authService } from './auth';
import axios from 'axios';
import * as _ from "lodash";
import $ from "jquery";
import BlogPopup from './BlogPopup';
import ForumPopUpModal from './forum/ForumPopUpModal';
import PollPopUpModal from './forum/PollPopUpModal';
import LinkPopup from './LinkPopup';
import PostPopup from './PostPopup';
import PostRedirectionPop from './PostRedirectionPop';
import ProjectTypeSection from './project/container/Project/ProjectTypeSection';
import CleverTapUtils from './commons/CleverTapUtils';

class PostSectionPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            isPostPopUpModalOpen: this.props.postPopUpOpenFlag ? true : false,
            isBlogPopUpModalOpen: false,
            isLinkPopUpModalOpen: false,
            isForumPopUpModalOpen: false,
            isPollPopUpModalOpen: false,
            isPostRedirectionModalOpen: false,
            postId: "",
            subscriptionConfig: {},
            projectDonationConfig: {}
        }
    }

    getSubscriptionConfig = () => {
        if (_.isEmpty(this.state.subscriptionConfig)) {
            let postBody = {
                "userId": this.state.userData.userId,
            };
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_userServiceURL}/user/subscription/global/config`,
                data: postBody,
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }).then((response) => {
                let res = response.data;
                if (res.status === '200 OK') {
                    this.setState({
                        subscriptionConfig: res.body
                    })
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    getProjectDonationConfig = (entityType) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_userServiceURL}/user/subscription/donation/initial/config?userId=${this.state.userData.userId}&entityType=${entityType}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    projectDonationConfig: response.data.body
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    openModalDetailsApiCall = (modalType, modalId) => {
        this.setState({
            [modalType]: true
        }, () => {
            window.$("#addpostpop").modal("hide");
            window.$("#" + modalId).modal("show");
        })
        let type = "Post";
        if(modalId === 'postblog'){
            type = "Blog";
        }
        else if(modalId === 'addForum'){
            type = "Forum";
        }
        else if(modalId === 'addPoll'){
            type = "Poll";
        }
        else if(modalId === 'postblog'){
            type = "Blog";
        }

        CleverTapUtils.eventClick({}, "Create "+type) ;

    }

    openModalClose = () => {
        this.setState({
            isPostPopUpModalOpen: this.props.postPopUpOpenFlag ? true : false,
            isBlogPopUpModalOpen: false,
            isLinkPopUpModalOpen: false,
            isForumPopUpModalOpen: false,
            isPollPopUpModalOpen: false
        });
        window.$("#toppostwrap").modal("hide");
        window.$("#postblog").modal("hide");
        window.$("#postlink").modal("hide");
        window.$("#addForum").modal("hide");
        window.$("#addPoll").modal("hide");
    }

    acceptPopUpModalDetail = (postId) => {
        this.setState({
            postId: postId,
            isPostRedirectionModalOpen: true
        }, () => {
            window.$("#AcceptPopUp").modal("show");
            setTimeout(() => {
                window.$("#AcceptPopUp").modal("hide");
                this.setState({
                    isPostRedirectionModalOpen: false
                })
            }, 60000);
        })
    }

    componentDidMount() {
        this.getProjectDonationConfig("PROJECT");
        this.getSubscriptionConfig();
    }

    render() {
        const { isPostPopUpModalOpen, isBlogPopUpModalOpen, isLinkPopUpModalOpen, isForumPopUpModalOpen, isPollPopUpModalOpen, isPostRedirectionModalOpen, postId, subscriptionConfig, projectDonationConfig } = this.state;
        return (
            <>
                <div id="addpostpop" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="popsharescreenlist newPostList">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                        <i className="iCross" />
                                    </button>
                                    <div className="newPostListTop">
                                        <button type="button" className="postCard" onClick={(e) => this.openModalDetailsApiCall("isPostPopUpModalOpen", "toppostwrap")}>
                                            <i className="iAdd_Post" />
                                            <p>Post</p>
                                        </button>
                                        <button type="button" className="postCard" onClick={(e) => this.openModalDetailsApiCall("isBlogPopUpModalOpen", "postblog")}>
                                            <i className="iBlog" />
                                            <p>Article</p>
                                        </button>
                                        <button type="button" className="postCard" onClick={(e) => this.openModalDetailsApiCall("isForumPopUpModalOpen", "addForum")}>
                                            <i className="iAddForum" />
                                            <p>Forum</p>
                                        </button>
                                        <button type="button" className="postCard" onClick={(e) => this.openModalDetailsApiCall("isPollPopUpModalOpen", "addPoll")}>
                                            <i className="iPolls_FL" />
                                            <p>Poll</p>
                                        </button>
                                    </div>
                                    <hr />                                    
                                    <div className="newPostListBott">
                                        <h4>Post in Project</h4>
                                        <div id="accordion">
                                            <div className="accorTopNav">
                                                <div class="card-header" id="heading-1">
                                                    <a class="collapsed btn" role="button" data-toggle="collapse" href="#collapse-1" aria-expanded="false">
                                                        New Project
                                                        <span>Projects posted here will be shown in upcoming projects</span>
                                                    </a>                                                    
                                                </div>
                                                <div class="card-header" id="heading-2">
                                                    <a class="collapsed btn" role="button" data-toggle="collapse" href="#collapse-2" aria-expanded="false">
                                                        Past Project
                                                        <span>Projects posted here will be shown in your experience</span>
                                                    </a>                                                    
                                                </div>
                                            </div>
                                            <div id="collapse-1" class="collapse" data-parent="#accordion" aria-labelledby="heading-1">
                                                <div class="card-body">
                                                    <div className="row">
                                                        {!_.isEmpty(subscriptionConfig) && <ProjectTypeSection pageType={"GLOBAL"} formType={"ONLINE"} subscriptionConfig={subscriptionConfig} projectDonationConfig={projectDonationConfig} nominationData={this.props.nominationData} />}
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="collapse-2" class="collapse" data-parent="#accordion" aria-labelledby="heading-2">
                                                <div class="card-body">
                                                    <div className="row">
                                                        {!_.isEmpty(subscriptionConfig) && <ProjectTypeSection pageType={"GLOBAL"} formType={"OFFLINE"} subscriptionConfig={subscriptionConfig} projectDonationConfig={projectDonationConfig} nominationData={this.props.nominationData} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="popsharegrid">
                                    <a
                                        href="javascript:void(0)"
                                        onClick={(e) => this.openModalDetailsApiCall("isLinkPopUpModalOpen", "postlink")}
                                    >
                                        <div className="icoblk">
                                            <img
                                                src="https://cdn.dscovr.com/images/link-post-ico.webp"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = "https://cdn.dscovr.com/images/link-post-ico.png"
                                                }}
                                                height="43"
                                                alt=""
                                            />
                                        </div>
                                        <span>Link</span>
                                    </a>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isPostPopUpModalOpen && <PostPopup openModalClose={this.openModalClose} acceptPopUpModalDetail={this.acceptPopUpModalDetail} />}
                {isBlogPopUpModalOpen && <BlogPopup openModalClose={this.openModalClose} acceptPopUpModalDetail={this.acceptPopUpModalDetail} />}
                {isLinkPopUpModalOpen && <LinkPopup openModalClose={this.openModalClose} acceptPopUpModalDetail={this.acceptPopUpModalDetail} />}
                {isForumPopUpModalOpen && <div className="forumpage" style={{ padding: '0' }}><ForumPopUpModal forumPopUpClose={this.openModalClose} /></div>}
                {isPollPopUpModalOpen && <div className="forumpage" style={{ padding: '0' }}><PollPopUpModal pollPopUpClose={this.openModalClose} /></div>}

                {isPostRedirectionModalOpen && <PostRedirectionPop postId={postId} />}
            </>
        )
    }
}

export default PostSectionPopUp;