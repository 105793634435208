import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { authService } from "../auth";
import { detailsPageRedirection, getCDNUrl, getOptimizedImage } from "../commons/CommonFunctions";
import Follow from "../commons/Follow";
import MutualConnect from "../commons/MutualConnect";
import {v4 as uuidv4} from 'uuid';
import TimeAgo from "javascript-time-ago";

const timeAgo = new TimeAgo('en-US')

class CommonPostHeaderSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            redirectToProfile: false
        }
    }

    redirectToProfile = () => {
        this.setState({redirectToProfile: true});
    }

    redirectToCircle = (customUrl) => {
        window.location.href = "/community/" + customUrl;
    }

    render() {
        const { item } = this.props;
        const { redirectToProfile } = this.state;
        const utcSeconds = this.props.item.createTime;
        const d = new Date(0);
        d.setUTCSeconds(utcSeconds / 1000);
        const linkTarget = {
            pathname: "/profile/" + this.props.item.userCustomUrl,
            key: uuidv4(),
            state: {
                applied: true
            }
        };
        const postTarget = {
            pathname: item.postType === "FORUM" ? `/forumDetail/${this.props.item.metaData?.slug}` : item.postType === "POLL" ? `/pollDetail/${this.props.item.metaData?.slug}` : `/post/${this.props.item.id}`,
            key: uuidv4(),
            state: {
                applied: true
            }
        };
        return (
            <>
                {redirectToProfile && <Redirect push to={`/profile/${item.userCustomUrl}`}/>}
                <div className="hposthead">
                    {(item && !item.userEntityType) ?
                        <figure className="userimg" onClick={(e) => {
                            e.preventDefault();
                            this.redirectToProfile()
                        }}>
                            <img src={item.userProfileImage ?
                                getCDNUrl(getOptimizedImage(this.props.item, 'userProfileImage')) :
                                (item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                alt="" />
                        </figure> :
                        <figure className="userimg" onClick={(e) => {
                            e.preventDefault();
                            this.redirectToCircle(item && item.params && item.params['circleSlug'])
                        }}>
                            <img
                                src={item && item.params && item.params['circleImage'] != null
                                    ? getCDNUrl(item.params && item.params['circleImage'])
                                    : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/circleDefault.png" }}
                                alt="" />
                        </figure>}
                    {!item.userEntityType ?
                        <div className="d-flex">
                            <h3><NavLink style={{ textDecoration: "none", textTransform: "none" }}
                                to={linkTarget}>{item.userName}</NavLink> {/*<span><a href="javascript:void(0)">Follow</a></span>*/}
                                {item.connectDepth ? <MutualConnect connectDepth={item.connectDepth}></MutualConnect> : null}
                            </h3>

                            {item && !item.deactivated && !item.deactivated && (!item.connectDepth || (item.connectDepth && item.connectDepth < 1)) &&
                                <Follow followed={item.followed}
                                    userId={item.userId}
                                    callback_fn={() => this.props.callback_fn(item.userId)} />}
                        </div> :
                        <div className="d-flex">
                            <h3 onClick={(e) => {
                                e.preventDefault();
                                this.redirectToCircle(item.params && item.params['circleSlug'])
                            }}>{item.params['circleTitle']}</h3>
                        </div>}
                    {item && item.persona &&
                        <div className="hpostPersona">{item.persona}</div>}
                    <div className="hpostdtls">
                        {item && item.country && <span className="address"><i
                            className="iLocation" />{item.country}</span>}
                        <span>
                            {(item.attachmentType === "IMAGE" || item.attachmentType === "VIDEO") ?
                                <a href="javascript:void(0)" onClick={(e) => detailsPageRedirection(item.id)}>{timeAgo.format(d, 'mini-now')}</a> :
                                <NavLink to={postTarget}>{timeAgo.format(item.createTime, 'mini-now')}</NavLink>
                            }
                        </span>
                    </div>
                </div>
            </>
        )
    }
}

export default CommonPostHeaderSection;