import React from 'react'
import { authService } from '../auth';
import httpService from '../AxiosInterceptors';
import { Redirect } from "react-router-dom";
import ProfileDeactivateOtp from "./ProfileDeactivateOtp";
import axios from "axios";
import SelectNamePopup from "./SelectNamePopup";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CleverTapUtils from '../commons/CleverTapUtils';

const ErorrMsgPopUp = withReactContent(alertPopUp);

httpService.setupInterceptors();

class ProfileDeactivation extends React.Component {

    constructor(props) {
        super(props);

        let reasonMap = [];
        reasonMap['DUPLICATE_ACCOUNT_CREATED'] = 'Duplicate account';
        reasonMap['ACCOUNT_CREATED_BY_MISTAKE'] = 'Account created by mistake';
        reasonMap['NO_LONGER_INTERESTED'] = 'No longer interested ';
        reasonMap['DONT_SEE_VALUE_BEING_A_MEMBER'] = "I don't see value in being a member ";
        reasonMap['GETTING_SPAMMED_EMAILS'] = 'I am getting spammed by emails';
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            errors: {},
            reasonMap: reasonMap,
            isDisabled : true,
            error: null,
            success: null,
            data:{},
            details:'',
            config:{},
            Otprefresh:false,
            collapseOpen: false
        };
    }


    handleReasonClicked = (e) => {
        this.setState({reason: e.target.value,
            isDisabled:false,
            isSubmitted:false})
        if(e.target.value === 'OTHERS'){
            this.setState({isDisabled:false,
                isSubmitted:false})
        }
    }
    componentWillUnmount() {
        this.setState({
            reason:'',
            details:'',
        })
        window.$('#deactivateProfileOTP').modal('hide');
        window.$('#dltslctnamepop').modal('hide');
    }

    getCurrentDeactivationConfig() {
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL + '/deactivation/get/updation/config/'+this.state.userData.userId,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.setState({ config: response.data.body });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    cancelDeactivationRequest = (event)=> {
        event.preventDefault();
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL + '/deactivation/cancel?userId='+this.state.userData.userId+'&adminRightChangeQueueId='+this.state.config.adminRightChangeQueueId,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.getCurrentDeactivationConfig();
                    this.props.refreshDeactivation();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleDetailsUpdate = (event) => {
        this.setState({'details': event.target.value});
        if(event.target.value && event.target.value.length > 0){
            this.setState({isDisabled : false});
        }else{
            this.setState({isDisabled : true});
        }
    }

    OtpVerifiedSuccess=(event)=>{
        window.$('#dltslctnamepop').modal('show');
    }

    validateProfileDeactivation=(event)=>{
        if(this.props.userId === this.state.userData.userId) {
            this.setState({'isSubmitted': true});
            event.preventDefault();
            axios({
                method:'get',
                url:process.env.REACT_APP_userServiceURL + '/deactivation/validate?userId='+this.state.userData.userId,
                headers:{ 'Content-Type': 'application/json' },
                withCredentials: true
            }).then((response)=>{
                let res = response.data
                if(res && res.status === '200 OK'){
                    this.setState({
                        'data': res.body
                    });
                    window.$('#deactivateProfileOTP').modal('show');

                } else {
                    this.setState({'isSubmitted': false});
                }
            }).catch((err)=>{
                console.log(err)
                this.setState({'isSubmitted': false});
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm();
                    }
                }).then((result) => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <><p>{err.response.data.message}</p></>,
                    })
                })
            })
        } else {
            ErorrMsgPopUp.fire({
                didOpen: () => {
                    ErorrMsgPopUp.clickConfirm();
                }
            }).then((result) => {
                return ErorrMsgPopUp.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    title: <><p>{"Please log in from organization account"}</p></>,
                })
            })
        }
    }

    checkAndRedirect = (event)=>{
       event.preventDefault();
       if(this.state.config && this.state.config.adminRightRequired){
          window.location.href = '/profile-deactivate-update-admin-rights';
       }
    }


    componentDidMount() {
        this.loadInitialJquery();
        this.getCurrentDeactivationConfig();

    }

    loadInitialJquery = () =>{
        window.$('.collapser').click(function () {
            window.$(this).next().collapse('toggle');
        });

        window.$('a.dgscroll').click(function (e) {
            e.preventDefault();
            var target = window.$(window.$(this).attr('href'));
            if (target.length) {
                var scrollTo = target.offset().top - 140;
                window.$('body, html').animate({ scrollTop: scrollTo + 'px' }, 300);
            }
        });
        window.$("#clickdlt").click(function() {
            // assumes element with id='button'
            window.$("#dltprfl").hide();
        });
        window.$("#clickdlt").click(function() {
            // assumes element with id='button'
            window.$(".opendlt").show();
        });
        window.$("button.btn.btn-cancel").click(function() {
            // assumes element with id='button'
            window.$("#dltprfl").show();
            window.$(".opendlt").hide();
        });
    }

    refreshState=()=>{
        this.setState({
            reason:'',
            details:'',
            'isSubmitted': true,
            'isDisabled' : true,
            Otprefresh:true
        })
    }

    onClickDeleteProdfile = () => {
        if(!this.state.collapseOpen) {
            CleverTapUtils.eventClick({}, "Delete Profile");this.setState({collapseOpen: true})
        }
        this.setState({ collapseOpen: !this.state.collapseOpen})
    }


    render() {
        const { error } = this.state;
        if (!this.state.loginSuccess) {
            return <Redirect to='/login' />
        }  else {
            return <div className="card deleteProfilebox" id="deleteProfilebx">
                {!this.state.config.deactivationRequestSent &&
                <div className="card-header" id="dltprfl">
                    <div className="left">
                        <h2>Delete your Profile</h2>
                        <p>Proceed here to start the process to delete your profile. You can reactivate your account within one year of deactivation.</p>
                    </div>
                    <div className="right">
                        <a className="btn edit dltProfbtn" id="clickdlt" onClick={() => this.onClickDeleteProdfile()}><i className="iCaution"></i>Proceed</a>
                    </div>
                </div>
                }
                {!this.state.config.deactivationRequestSent &&
                <div className="fadeIn animated profeditmain opendlt">
                    <div className="profeditmaininn">
                        <div className="dltformwrap">
                            <h2>Delete your Profile</h2>
                            <p>We are sorry to see you go. <br/>Please choose your reason for deleting your profile.</p>
                            {
                                this.state.reasonMap && Object.keys(this.state.reasonMap).length > 0
                                && <>
                                    {
                                        Object.keys(this.state.reasonMap).map((reasonCode) =>
                                            <div key={reasonCode} className="form-group">
                                                <label
                                                    className="radio-container">{this.state.reasonMap[reasonCode]}
                                                    <input id={"reason" + reasonCode}
                                                           value={reasonCode}
                                                           checked={this.state.reason === reasonCode}
                                                           onChange={this.handleReasonClicked}
                                                           type="radio" name="radio"/>
                                                    <span className="radio-checkmark"/>
                                                </label>
                                            </div>
                                        )
                                    }

                                    <div className="form-group">
                                        <label className="radio-container">Others (Please specify)
                                            <input type="radio" name="radio" value="OTHERS"
                                                   checked={this.state.reason === 'OTHERS'}
                                                   onChange={this.handleReasonClicked}/>
                                            <span className="radio-checkmark"/>
                                        </label>
                                        {
                                            this.state.reason === 'OTHERS'
                                            && <div
                                                className="form-group fadeIn animated profeditmain others">
                                                            <textarea className="form-control custom-input"
                                                                      placeholder="Write the details"
                                                                      value={this.state.details}
                                                                      onChange={this.handleDetailsUpdate}
                                                                      required/>
                                            </div>
                                        }
                                    </div>
                                </>
                            }

                            <div className="formbottom">
                                <button type="button" className="btn btn-primary" data-dismiss="modal"
                                        disabled={this.state.isDisabled || this.state.isSubmitted}
                                        onClick={this.validateProfileDeactivation}>Proceed
                                </button>
                                <button type="button" className="btn btn-cancel" data-dismiss="modal" onClick={this.refreshState}>Cancel</button>
                            </div>
                        </div>
                        <div className="forminfo"></div>
                    </div>
                </div>
                }
                {this.state.config.deactivationRequestSent &&
                <div className="card-header" id="dltprfl">
                    <div className="left" onClick={this.checkAndRedirect} style={{cursor:'pointer'}}>
                        <h2>Deactivation in process</h2>
                        <p>Your account deactivation request is in process. You can cancel the deactivation request during this process.</p>
                    </div>
                    <div className="right"><button type="button" className="btn btn-cancel m-0" onClick={this.cancelDeactivationRequest}>Cancel Request</button></div>
                </div>
                }
                {this.state.data && this.state.data.transactionId &&
                <ProfileDeactivateOtp key={this.state.data} userId={this.state.userData.userId} data={this.state.data}
                                      OtpVerifiedSuccess={this.OtpVerifiedSuccess}
                                      transactionId={this.state.data.transactionId} refreshState={this.refreshState} optrefresh={this.state.Otprefresh}/>
                }
                <SelectNamePopup reason={this.state.reason} details={this.state.details} refreshState={this.refreshState}/>
            </div>
        }
    }
}

export default ProfileDeactivation
