import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { authService } from "../auth";
import stripHtml from "string-strip-html";
import * as _ from "lodash";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "../landing/landing.min.css";
import "../landing/responsive-landing.min.css";
import { CapitalizeFirstLetter } from "../utils/StringUtility";
import ImageCompress from "quill-image-compress";
import { doubleHashTagsFunc, doubleUserTagsFunc, getCDNUrl, getOptimizedImageQuill } from "../commons/CommonFunctions";
import AutoSuggest from "../AutoSuggest";
import QuillMention from "quill-mention";
import MagicUrl from "quill-magic-url";
import { connect } from "react-redux";
import { requestPostCreationFulfilled } from "../reducer/post";
import CommonPostAsForumPoll from "../commons/CommonPostAsForumPoll";
import uuid from "react-uuid";
import CleverTapUtils from "../commons/CleverTapUtils";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);
Quill.register("modules/mentions", QuillMention);
Quill.register("modules/magicUrl", MagicUrl);

var BaseImageFormat = Quill.import("formats/image");
const ImageFormatAttributesList = ["alt", "height", "width", "style"];

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);


let hashTagModule;
let selectedHashtagsList = [];
let selectedUserTagList = [];
class ForumPopUpModal extends Component {
    constructor(props) {
        super(props);
        this.Quillref = React.createRef();
        this.interval = null;
        let validation = {
            forumTitle: {},
            forumContent: {},
            forumTag: {},
            strtDate: {},
            enDate: {},
        };
        this.state = {
            error: false,
            messages: null,
            validation,
            errors: {},
            loginSuccess: authService.isAuthenticated(),
            localStorageData: authService.getUserDetailsFromLocalStorage(),
            isLoading: true,
            token: "",
            title: "",
            content: "",
            tags: [],
            hashTags: [],
            status: "ENABLE",
            userType: "WENAT",
            forumData: [],
            forumRecommendedData: [],
            like: true,
            name: "",
            type: "",
            dropDownShowItem: "Forum",
            isForumPopUpOpen: false,
            isPollPopUpOpen: false,
            pageSize: 5,
            pageNo: 0,
            pagination: false,
            showStatus: "PUBLISH",
            pageFilterType: "All",
            disable: false,
            contentLength: 0,
            search: "",
            trendingPagination: false,
            trendingContentLength: 0,
            pageCount: 0,
            theme: "snow",
            forumError: false,
            topPanelAll: "ALL FORUMS",
            topPanelMy: "MY FORUM",
            url: "",
            draftId: "",
            trendingUrl: "",
            trendingPageNo: 0,
            responseMessage: "",
            loading: true,
            activeForumTab: "ALL_FORUM",
            entityDetails: {},
            intervalId: null,
            remove_id: uuid(),
            suggestedHasTag: []
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    mentionModule = {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["@", "#"],
        source: function (searchTerm, renderList, mentionChar) {
            let values;
            let tagSuggestionArray = [];
            let userSuggestionArray = [];
            let postBody = {
                description: searchTerm,
            };
            if (mentionChar === "#") {
                hashTagModule = true;
                axios({
                    method: "post",
                    url: process.env.REACT_APP_userServiceURL + "/tags/recommended",
                    headers: { "Content-Type": "application/json" },
                    data: postBody,
                    withCredentials: true,
                })
                    .then((response) => {
                        if (
                            response &&
                            response.data &&
                            response.data.message === "Success!"
                        ) {
                            let hashTagsData = [];
                            if (selectedHashtagsList && response.data.body) {
                                hashTagsData = (response.data.body).filter((tag) => !(selectedHashtagsList).includes(tag));
                            } else {
                                hashTagsData = response.data.body;
                            }
                            hashTagsData &&
                                hashTagsData.map((tag, index) => {
                                    tagSuggestionArray.push({
                                        id: index,
                                        value: "# " + tag,
                                        link: `/explore-hashtag/${tag}`,
                                    });
                                });
                            values = tagSuggestionArray;
                            if (searchTerm.length === 0) {
                                renderList(values, searchTerm);
                            } else {
                                const matches = [];
                                for (let i = 0; i < values.length; i++)
                                    if (
                                        ~values[i].value
                                            .toLowerCase()
                                            .indexOf(searchTerm.toLowerCase())
                                    )
                                        matches.push(values[i]);
                                renderList(matches, searchTerm);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else if (mentionChar === "@") {
                hashTagModule = false;
                if (
                    authService.getUserDetailsFromLocalStorage() &&
                    authService.getUserDetailsFromLocalStorage().userId
                ) {
                    axios({
                        method: "get",
                        url:
                            process.env.REACT_APP_userServiceURL +
                            "/search/user/suggestions/" +
                            authService.getUserDetailsFromLocalStorage().userId +
                            "?query=" +
                            searchTerm,
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }).then((response) => {
                        let usersDataList = [];
                        if (selectedUserTagList && response.data.body) {
                            usersDataList = (response.data.body).filter((user) => !(selectedUserTagList).includes(user.customUrl));
                        } else {
                            usersDataList = response.data.body;
                        }
                        usersDataList &&
                            usersDataList.map((user, index) => {
                                userSuggestionArray.push({
                                    id: user.customUrl,
                                    display: user.username,
                                    value: user.username,
                                    link: `/profile/${user.customUrl}`,
                                    secondaryHeader:
                                        user.addressDetail && user.addressDetail.country
                                            ? user.addressDetail.country +
                                            (user.personalInfo && user.personalInfo.persona
                                                ? ", " + user.personalInfo.persona
                                                : "")
                                            : user.personalInfo && user.personalInfo.persona
                                                ? user.personalInfo.persona
                                                : "",
                                    avatar:
                                        user.personalInfo && user.personalInfo.profileImage
                                            ? getCDNUrl(getOptimizedImageQuill(user.personalInfo))
                                            : user.type !== "COMPANY"
                                                ? "https://cdn.dscovr.com/images/users1.png"
                                                : "https://cdn.dscovr.com/images/DefaultBusiness.png",
                                    type: user.type
                                });
                                values = userSuggestionArray;
                                if (searchTerm.length === 0) {
                                    renderList(values, searchTerm);
                                } else {
                                    const matches = [];
                                    for (let i = 0; i < values.length; i++)
                                        if (
                                            ~values[i].value
                                                .toLowerCase()
                                                .indexOf(searchTerm.toLowerCase())
                                        )
                                            matches.push(values[i]);
                                    renderList(matches, searchTerm);
                                }
                            });
                    });
                }
            }
        },
        renderItem(item) {
            return !hashTagModule
                ? `
          <div class="mentionSuggestionsEntryContainer">
            <div class="mentionSuggestionsEntryContainerLeft">
              <img
                src=${getCDNUrl(item.avatar)}
                class="mentionSuggestionsEntryAvatar"
              />
            </div>
            <div class="mentionSuggestionsEntryContainerRight">
              <div className= {"mentionSuggestionsEntryText" + ${(item.type === "COMPANY" ? " orgnzName" : "")}}>
                ${item.display}
              </div>
              <div class="mentionSuggestionsEntryTitle">
                ${item.secondaryHeader}
              </div>
            </div>
          </div>
        `
                : `
        <span>${item.value}</span>
      `;
        },
    };

    modules = {
        toolbar: [
            [{ header: "2" }, { font: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: [] }],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "video"],
        ],
        clipboard: {
            matchVisual: false,
        },
        imageResize: {
            parchment: Quill.import("parchment"),
            handleStyles: {
                backgroundColor: "black",
                border: "none",
                color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
        },
        imageCompress: {
            quality: 0.7, // default
            maxWidth: 750, // default
            maxHeight: 500, // default
            imageType: "image/jpeg" + "image/png", // default
        },
        mention: this.mentionModule,
        magicUrl: {
            urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
            globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
        },
    };

    handleDelete(i) {
        this.setState({
            tags: this.state.tags.filter((tag, index) => index !== i),
        });
    }

    handleTagsChange = (hashTags) => {
        let hashTagsList = [];
        let oldHashTag = this.state.hashTags;
        if (hashTags && hashTags.length > 0) {
            hashTags &&
                hashTags.map(function (data) {
                    data = data.replace(
                        /[`~!@$%^&*()|+\=?;:'",.#<>\{\}\[\]\\\/\s]/gi,
                        ""
                    );
                    if (data != "") {
                        hashTagsList.push(data);
                    }
                });
            let allHashTagsList = hashTagsList;
            hashTagsList = [...new Set(allHashTagsList)];
        }
        const removeData = oldHashTag.filter((item) => !hashTagsList.includes(item));
        this.triggerDeleteApiCall(removeData);
        this.setState({ hashTags: hashTagsList });
    };

    handleEditorData = (content, delta, source, editor) => {
        let descriptionContent = content.replace(/#\s/g, "#");
        this.setState({ content: descriptionContent }, () => {
            if (this.state.content && this.state.content.length > 0) {
                this.checkDraftInterval();
            }
        });
        selectedHashtagsList = doubleHashTagsFunc(content);
        selectedUserTagList = doubleUserTagsFunc(content);
    };


    handleAddition(tag) {
        let { tags } = this.state;
        this.setState({
            tags: [...this.state.tags, { id: tags.length + 1, text: tag }],
        });
    }

    handleTitleChange = (e) => {
        this.setState({ title: e.target.value }, () => {
            if (this.state.title && this.state.title.length > 0) {
                this.checkDraftInterval();
            }
        });
    };



    handleStatusChange = (e, type) => {
        clearInterval(this.interval);
        this.interval = null;
        let errors = {};
        if (e.target.name === "ENABLE") this.setState({ showStatus: "PUBLISH" });
        else if (e.target.name === "DISCARD") {
            this.setState({
                forumError: false,
                startDate: "",
                endDate: "",
                showStatus: "PUBLISH",
                title: "",
                content: "",
                tags: [],
                draftId: "",
                hashTags: [],
            });
            window.$("#ForumcancelPopup").modal("hide");
            window.$("#addForum").modal("hide");
            this.props.forumPopUpClose();
            //document.getElementById("quill-mention-list").innerHTML = ""
        } else if (e.target.name === "DRAFT") {
            this.setState({
                forumError: false,
                startDate: "",
                endDate: "",
                showStatus: "PUBLISH",
            });
            if (type === "FORUM" && this.state.title.trim() === "") {
                errors["title"] = "Please enter the title";
            } else {
                this.saveAsDraft(e, type);
                window.$("#addForum").modal("hide");
            }
            this.setState({
                errors: errors,
            });
            setTimeout(() => this.setState({ errors: {} }), 3000);
        } else this.setState({ showStatus: e.target.name });
        this.setState({ status: "ENABLE" });
    };

    componentDidMount() {
        this.Quillref.current.editor.root.setAttribute("spellcheck", "false");
        // create suggested hashtag
        this.triggerApiCall();
    }

    componentDidUpdate(prevProps, prevState) {
        var regex = /\s+/gi;
        if (this.state.content !== prevState.content) {
            if (!this.state.intervalId && stripHtml(this.state.content).trim().replace(regex, " ").split(" ")
                .length > 49) {
                const intervalId = setInterval(() => {
                    // make API call
                    this.triggerApiCall();
                }, 5000);
                this.setState({ intervalId });
            }else if(stripHtml(this.state.content).trim().replace(regex, " ").split(" ")
                .length === 1){
                clearInterval(this.state.intervalId);

                const manualHasTags= this.state.hashTags.filter(item => !this.state.suggestedHasTag.includes(item));
                this.setState({
                    intervalId: null,
                    // hashTags:[]
                    hashTags: manualHasTags
                })
            }
        }
    }

    triggerApiCall() {
        // make API call here
        let data = {
            "post": this.state.content,
            "postId": this.state.remove_id
        }
        axios({
            method: "POST",
            url: process.env.REACT_APP_userServiceURL + "/recommend/runtimehashtag/",
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
            data: data
        }).then((res) => {
            if (res?.data?.status === 200) {
                let allHashTagsList = res?.data?.data;
                let hashTagsList = this.state.hashTags.concat([...new Set(allHashTagsList)]);
                this.setState({ hashTags: [...new Set(hashTagsList)],suggestedHasTag: allHashTagsList });
            }
        }).catch((error) => {
            console.log("error", error)
        })
    }

    triggerDeleteApiCall = (tag) => {
        // make API call here
        let data = {
            "tag": tag.join(""),
            "postId": this.state.remove_id
        }
        axios({
            method: "POST",
            url: process.env.REACT_APP_userServiceURL + "/recommend/runtimehashtag/",
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
            data: data
        }).then((res) => {
            if (res?.data?.status === 200) {
                let allHashTagsList = res?.data?.data;
                let hashTagsList = this.state.hashTags.concat([...new Set(allHashTagsList)]);
                this.setState({ hashTags: [...new Set(hashTagsList)] });
            }
        }).catch((error) => {
            console.log("error", error)
        })
    }

    componentWillUnmount() {
        window.$("#addForum").modal("hide");
        window.$("#addpostpop").modal("hide");
        window.$("#toppostwrap").modal("hide");
        window.$("#postblog").modal("hide");
        window.$("#postlink").modal("hide");
        window.$("#blogpostpreviewwrap").modal("hide");
        clearInterval(this.interval);
        this.interval = null;
        clearInterval(this.state.intervalId);
    }

    checkValid = (value) => {
        if (!_.isUndefined(value) && !_.isNull(value) && value !== "") {
            return true;
        }
        return false;
    };

    checkEmptyValidation = (value) => {
        if (
            !_.isUndefined(value) &&
            !_.isNull(value) &&
            value !== "" &&
            value.length > 0
        ) {
            return true;
        }
        return false;
    };

    checkDraftInterval = () => {
        if (!this.interval) {
            this.interval = setInterval(() => {
                this.handleAutoSave();
            }, 60000);
        }
    };

    handleAutoSave = () => {
        if (this.state.title && this.state.title.length > 0) {
            let parameterDetails = {};
            if (this.checkValid(this.state.title))
                parameterDetails.title = this.state.title;
            if (this.checkValid(this.state.content))
                parameterDetails.content = this.state.content;
            if (this.checkEmptyValidation(this.state.tags)) {
                this.state.hashTags = [];
                for (let data of this.state.tags) {
                    if (data.text.charAt(0) === "#") data.text = data.text.substring(1);
                    this.state.hashTags.push(data.text);
                }
            }
            parameterDetails.hashTags = this.state.hashTags;
            if (this.checkValid(this.state.userType))
                parameterDetails.userType = this.state.userType;
            if (this.checkValid(this.state.createdBy))
                parameterDetails.createdBy = this.state.createdBy;

            let postBody = {
                id: this.state.draftId ? this.state.draftId : null,
                userId: !_.isEmpty(this.state.entityDetails) ? this.state.entityDetails?.entityId : this.state.localStorageData.userId,
                parameterDetails: parameterDetails,
                type: "FORUM",
            };
            axios({
                method: "post",
                url: process.env.REACT_APP_userServiceURL + "/backend/drafts/create",
                headers: { "Content-Type": "application/json" },
                data: postBody,
                withCredentials: true,
            })
                .then((response) => {
                    let res = response.data;
                    if (res.status === "201 CREATED") {
                        this.setState({
                            draftId: res && res.body && res.body.id,
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        error: { message: err.response.data.message, err: err.response },
                    });
                });
        }
    };

    saveAsDraft = (event, type) => {
        clearInterval(this.interval);
        this.interval = null;
        event.preventDefault();
        this.setState({ disable: true });
        let parameterDetails = {};
        if (this.checkValid(this.state.title))
            parameterDetails.title = this.state.title;
        if (this.checkValid(this.state.content))
            parameterDetails.content = this.state.content;
        if (this.checkEmptyValidation(this.state.tags)) {
            this.state.hashTags = [];
            for (let data of this.state.tags) {
                if (data.text.charAt(0) === "#") data.text = data.text.substring(1);
                this.state.hashTags.push(data.text);
            }
        }

        parameterDetails.hashTags = this.state.hashTags;
        if (this.checkValid(this.state.userType))
            parameterDetails.userType = this.state.userType;
        if (this.checkValid(this.state.createdBy))
            parameterDetails.createdBy = this.state.createdBy;

        let postBody = {
            userId: !_.isEmpty(this.state.entityDetails) ? this.state.entityDetails?.entityId : this.state.localStorageData.userId,
            parameterDetails: parameterDetails,
            type: type,
        };
        if (this.state.draftId) {
            postBody.id = this.state.draftId;
        }
        axios({
            method: "post",
            url: process.env.REACT_APP_userServiceURL + "/backend/drafts/create",
            headers: { "Content-Type": "application/json" },
            data: postBody,
            withCredentials: true,
        })
            .then((response) => {
                let res = response.data;
                if (res.status === "201 CREATED") {
                    this.setState({
                        messages: res.message,
                        success: true,
                        draftId: "",
                        hashTags: [],
                    });
                    setTimeout(() => {
                        window.$("#addForum").modal("hide");
                        this.revokeSuccess();
                        this.props.forumPopUpClose();
                    }, 1000);
                    if (this.props.getForumList) {
                        this.props.getForumList(0, 5, false);
                    }
                    if (this.props.getTrendingList) {
                        this.props.getTrendingList(0, 5, false);
                    }
                }
                this.state.disable = false;
            })
            .catch((err) => {
                this.state.disable = false;
                this.state.fail = true;
                setTimeout(() => {
                    this.revokeError();
                }, 5000);
                this.setState({
                    isLoaded: true,
                    error: { message: err.response && err.response.data && err.response.data.message, err: err.response },
                    disable: false,
                });
            });
        setTimeout(() => this.setState({ errors: {} }), 3000);
    };

    handleSubmit = (event) => {
        clearInterval(this.interval);
        this.interval = null;
        event.preventDefault();
        if (this.validateForum()) {
            this.setState({ disable: true });
            let postBody = {
                userId: !_.isEmpty(this.state.entityDetails) ? this.state.entityDetails?.entityId : this.state.localStorageData.userId,
                title: this.state.title,
                content: this.state.content,
                hashTags: this.state.hashTags
                    ? this.state.hashTags.map((item) => {
                        return item.replace(/#/g, "");
                    })
                    : [],
                status: this.state.status,
                userType: this.state.userType,
                createdBy: !_.isEmpty(this.state.entityDetails) ? this.state.entityDetails?.entityName : (this.state.localStorageData.companyName == null ? this.state.localStorageData.firstName : this.state.localStorageData.companyName),
            };
            if (this.state.draftId) {
                postBody.draftId = this.state.draftId;
            }

            axios({
                method: "post",
                url: `${process.env.REACT_APP_userServiceURL}/backend/public/forum/create`,
                headers: { "Content-Type": "application/json" },
                data: postBody,
                withCredentials: true,
            })
                .then((response) => {
                    let res = response.data;
                    if (res.status === "201 CREATED") {
                        this.state.messages = res.message;
                        this.state.success = true;
                        this.setState({ hashTags: [], draftId: "" });
                        setTimeout(() => {
                            window.$("#addForum").modal("hide");
                            this.setState({ disable: false });
                            this.revokeSuccess();
                            this.props.requestPostCreationFulfilled({ success: true });
                            this.props.forumPopUpClose();
                        }, 3000);
                        if (this.props.getForumList) {
                            this.props.getForumList(0, 5, false);
                        }
                        if (this.props.getTrendingList) {
                            this.props.getTrendingList(0, 5, false);
                        }
                        this.state.disable = true;

                        CleverTapUtils.eventClick({}, "Add Forum") ;
                    }
                })
                .catch((err) => {
                    this.state.disable = false;
                    if (err && err.response && err.response.data) {
                        this.setState({
                            isLoaded: true,
                            error: { message: err.response.data.message, err: err.response },
                        });
                    }
                });
        }
        setTimeout(() => {
            this.setState({
                error: {},
                errors: {},
                validation: {},
                messages: "",
            });
        }, 5000);
    };

    revokeError() {
        this.setState({ messages: "" });
        this.setState({ error: false });
    }

    revokeSuccess() {
        this.setState({ messages: "" });
        this.setState({ success: false });
    }

    validateForum() {
        this.state.validation.forumTitle = {};
        this.state.validation.forumContent = {};
        this.state.validation.forumTag = {};
        let errors = {};
        let hashTags = [];
        for (let data of this.state.hashTags) {
            if (data.charAt(0) === "#") data = data.substring(1);
            hashTags.push(data);
            this.setState({ hashTags: hashTags });
        }
        let formIsValid = true;
        if (
            stripHtml(this.state.content) == "" &&
            this.state.content.indexOf('"') == -1
        ) {
            errors["content"] = "Please enter the description";
            this.state.validation.forumContent = {
                error: true,
                message: "Please enter the description",
                style: { borderColor: "#b34c4c", padding: "10px" },
            };
            this.setState({ forumError: true });
            formIsValid = false;
        }
        if (this.state.title.trim() == "") {
            errors["title"] = "Please enter the title";
            this.state.validation.forumTitle = {
                error: true,
                message: "Please enter the title",
                style: { borderColor: "#b34c4c", padding: "10px" },
            };
            this.setState({ forumError: true });
            formIsValid = false;
        }
        if (this.state.hashTags == "" || this.state.hashTags.length == 0) {
            errors["hashTags"] = "Please enter atleast one hashtag";
            this.state.validation.forumTag = {
                error: true,
                message: "Please enter atleast one hashtag",
                style: { borderColor: "#b34c4c", padding: "10px" },
            };
            this.setState({ forumError: true });
            formIsValid = false;
        }
        this.setState({
            errors: errors,
        });
        return formIsValid;
    }

    redirect = (id) => {
        this.props.history.push({
            pathname: "/forumDetail/" + id,
        });
    };

    getOptimizedImage(personalInfo) {
        if (
            personalInfo.resizedProfileImages &&
            personalInfo.resizedProfileImages["200x200"] != null
        ) {
            return personalInfo.resizedProfileImages["200x200"];
        }
        return personalInfo.profileImage || personalInfo.entityImageUrl ? personalInfo.profileImage || personalInfo.entityImageUrl : (personalInfo.type !== "COMPANY" && personalInfo.entityType !== "BUSINESS_PAGES") ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png";
    }

    ignoreModal = () => {
        window.$("#ForumcancelPopup").modal("hide");
        window.$("#addForum").modal("show");
    };

    hideModal = () => {
        this.setState({
            forumError: false,
            startDate: "",
            endDate: "",
            showStatus: "PUBLISH",
            title: "",
            content: "",
            tags: [],
            draftId: "",
            hashTags: [],
        });
        this.props.forumPopUpClose();
        window.$("#ForumcancelPopup").modal("hide");
        window.$("#addForum").modal("hide");
    };

    confirmModal = () => {
        window.$("#ForumcancelPopup").modal("show");
        window.$("#addForum").modal("hide");
    };

    setEntity = (userDetails) => {
        this.setState({
            entityDetails: userDetails
        })
    }

    componentDidMount() {
        this.Quillref.current.editor.root.setAttribute("spellcheck", "false");
    }

    render() {
        return (
            <>
                <div id="addForum" className="modal fade dgpopup">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="blogpostwrap">
                                    {(this.state.title && this.state.title !== "") ||
                                        (this.state.content && this.state.content !== "") ||
                                        (this.state.hashTags &&
                                            this.state.hashTags.length > 0) ? (
                                        <button
                                            onClick={this.confirmModal}
                                            className="close topCrossModal"
                                        >
                                            <i className="iCross" />
                                        </button>
                                    ) : (
                                        <button
                                            onClick={this.hideModal}
                                            className="close topCrossModal"
                                        >
                                            <i className="iCross" />
                                        </button>
                                    )}
                                    <figure className="userimg">
                                        <img src={getCDNUrl(this.getOptimizedImage(!_.isEmpty(this.state.entityDetails) ? this.state.entityDetails : this.state.localStorageData))} alt="" />
                                    </figure>
                                    <div className="toppostinn">
                                        <CommonPostAsForumPoll setEntity={this.setEntity} />
                                        <div className="blogpostbody">
                                            <div className="blogtoptitle">Create a Forum</div>
                                            <div className="blogpostcontwrap">
                                                <div className="form-group">
                                                    <textarea
                                                        type="text"
                                                        id="title"
                                                        name="title"
                                                        value={this.state.title || ""}
                                                        onChange={this.handleTitleChange}
                                                        className="title-textfield"
                                                        placeholder="Add forum title"
                                                    ></textarea>
                                                    <span
                                                        className="login-feedback-error mt-1"
                                                        style={{ display: "block" }}
                                                    >
                                                        {this.state.errors.title}
                                                    </span>
                                                </div>
                                                <div className="form-group reactQuillWrap">
                                                    <ReactQuill
                                                        theme={this.state.theme}
                                                        onChange={this.handleEditorData}
                                                        ref={this.Quillref}
                                                        value={this.state.content}
                                                        modules={this.modules}
                                                        placeholder="Write Something here "
                                                    />
                                                    <div className="blogMedinfo">
                                                        <a
                                                            href="/media-info"
                                                            title="Media Info"
                                                            className="btninfo"
                                                            target="_blank"
                                                        >
                                                            <i className="iInfo_I" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <span
                                                    className="login-feedback-error mt-1"
                                                    style={{ display: "block" }}
                                                >
                                                    {this.state.errors.content}
                                                </span>
                                                <div className="tagwrap">
                                                    <div className="tagfield">
                                                        <AutoSuggest
                                                            hashTags={this.state.hashTags}
                                                            onDelete={this.handleDelete}
                                                            onAddition={this.handleAddition}
                                                            handleTagsChange={this.handleTagsChange}
                                                        />
                                                    </div>
                                                </div>
                                                <span
                                                    className="login-feedback-error mt-1"
                                                    style={{ display: "block" }}
                                                >
                                                    {this.state.errors.hashTags}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="bottpostpopbody">
                                            {this.state.error.message !== "" &&
                                                this.state.error.message !== undefined && (
                                                    <div className="alert alert-warning">
                                                        {this.state.error.message}
                                                    </div>
                                                )}
                                            {this.state.success && (
                                                <span className="success">
                                                    {" "}
                                                    {this.state.messages}{" "}
                                                </span>
                                            )}

                                            <div className="bottpostpopright">
                                                <div className="contxmenu primntn_dropdown">
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        value={"ENABLE"}
                                                        onClick={this.handleSubmit}
                                                        disabled={this.state.disable}
                                                        id="status"
                                                        name="status"
                                                    >
                                                        {this.state.showStatus}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn dropdown-toggle dropdown-toggle-split"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span className="sr-only">Toggle Dropdown</span>
                                                    </button>
                                                    <div className="dropdown-menu fadeIn animated">
                                                        {!this.state.disable && (
                                                            <a name="ENABLE" onClick={this.handleSubmit}>
                                                                Publish
                                                            </a>
                                                        )}
                                                        <a
                                                            onClick={(e) =>
                                                                this.handleStatusChange(e, "FORUM")
                                                            }
                                                            name="DRAFT"
                                                        >
                                                            Draft
                                                        </a>

                                                        {(this.state.title &&
                                                            this.state.title !== "") ||
                                                            (this.state.content &&
                                                                this.state.content !== "") ||
                                                            (this.state.hashTags &&
                                                                this.state.hashTags.length > 0) ? (
                                                            <a onClick={this.confirmModal}>DISCARD</a>
                                                        ) : (
                                                            <a
                                                                onClick={(e) =>
                                                                    this.handleStatusChange(e, "FORUM")
                                                                }
                                                                name="DISCARD"
                                                            >
                                                                DISCARD
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade dgpopup confirmpop"
                    id={"ForumcancelPopup"}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Cancel Confirmation</h5>
                            </div> */}
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true" onClick={this.ignoreModal}>
                                    &times;
                                </span>
                            </button>
                            <div className="confirm-popup-modal-body">
                                Are you sure you want to cancel this Forum?
                            </div>
                            <div className="modal-footer confirm-popup-modal-footer">
                                <a
                                    className="btn btn-primary"
                                    name="DISCARD"
                                    onClick={(e) => this.handleStatusChange(e, "FORUM")}
                                >
                                    Yes
                                </a>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={this.ignoreModal}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) =>
            dispatch(requestPostCreationFulfilled(value)),
    };
};

export default connect(null, mapDispatchToProps)(ForumPopUpModal);
