import React, {Component} from 'react';
import {detailsPageRedirection, getCDNUrl, isWebView, postMsg} from "../commons/CommonFunctions";
import {NavLink} from "react-router-dom";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import Images from "../commons/Images";

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {authService} from "../auth";

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

class UnAuthPhotosPostItem extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }
    componentDidMount() {
        let photoListMap = [];
        let photoListOriginalMap = [];
        this.props.item && this.props.item.attachmentIds && this.props.item.attachmentIds.map((attachment, index) => {
            photoListOriginalMap.push(getCDNUrl(attachment.attachmentUrl));
            if (window.innerWidth > 1000 && attachment.resizedImages && attachment.resizedImages['1000x1000'] != null) {
                photoListMap.push(getCDNUrl(attachment.resizedImages['1000x1000']));
            } else if (window.innerWidth > 600 && window.innerWidth <= 1000 && attachment.resizedImages && attachment.resizedImages['600x600'] != null) {
                photoListMap.push(getCDNUrl(attachment.resizedImages['600x600']));
            } else if (window.innerWidth > 320 && window.innerWidth <= 600 && attachment.resizedImages && attachment.resizedImages['400x400'] != null) {
                photoListMap.push(getCDNUrl(attachment.resizedImages['400x400']));
            } else {
                photoListMap.push(getCDNUrl(attachment.attachmentUrl));
            }
        })
        this.setState({'photoListMap': photoListMap, 'photoListOriginalMap': photoListOriginalMap});
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.userProfileImage;
    }
    render() {
        const utcSeconds = this.props.item.createTime;
        const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);
        return (
            <div className="hpostblock"> {/*pNominate*/}
                {(this.props.item && !this.props.item.userEntityType) ?
                    <figure className="userimg" onClick={(e) => {
                        e.preventDefault();
                    }}>
                        <img src={this.props.item.userProfileImage ?
                            getCDNUrl(this.getOptimizedImage(this.props.item)) :
                            (this.props.item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                             alt=""/>
                    </figure> :
                    <figure className="userimg" onClick={(e) => {
                        e.preventDefault();
                    }}>
                        <img
                            src={this.props.item && this.props.item.params && this.props.item.params['circleImage'] != null
                                ? getCDNUrl(this.props.item.params && this.props.item.params['circleImage'])
                                : "https://cdn.dscovr.com/images/circleDefault.webp"}
                            onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/circleDefault.png"}}
                            alt=""/>
                    </figure>}
                <div className="hpostblockinn">
                    <div className="hposthead">
                        {!this.props.item.userEntityType ?
                            <div className="d-flex">
                                <h3>{this.props.item.userName}
                                </h3>
                            </div> :
                            <div className="d-flex">
                                <h3 onClick={(e) => {
                                    e.preventDefault();
                                }}>{this.props.item.params['circleTitle']}</h3>
                            </div>}
                            {this.props.item && this.props.item.persona &&
                                <div className="hpostPersona">{this.props.item.persona}</div>}
                        <div className="hpostdtls">
                            {this.props.item && this.props.item.country &&
                                <span className="address"><i
                                    className="iLocation"/>{this.props.item && this.props.item.country}</span>}
                            <span>{timeAgo.format(d, 'mini-now')}</span>
                        </div>
                    </div>
                    <div className="hpostcontent">

                        <div className="hpostconttop">
                            {this.props.item && this.props.item.description && this.props.item.description !== '' &&
                            <ReadMoreReadLess id={this.props.item.id} description={this.props.item.description} hashTags={this.props.item.hashTags} />
                            }

                            {this.state.photoListMap && this.state.photoListMap.length > 0 &&
                                <Images images={this.state.photoListMap} countFrom={5}
                                    photoListOriginalMap={this.state.photoListOriginalMap}/>
                            }
                            
                            {this.props.item.postLocation &&
                            <span className="address"><i
                                className="iLocation"/>{this.props.item.postLocation}</span>
                            }
                        </div>

                        
                            {
                                this.props.item.secondaryAttachmentIds && this.props.item.secondaryAttachmentIds.length > 0
                                    && <div className="hpostcontbott">
                                        <a href="javascript:void(0)" onClick={this.openModalList} className="fattach"
                                            data-toggle="modal">
                                            <i className="iLink"/>
                                            {this.props.item.secondaryAttachmentIds.length === 1
                                                ? this.props.item.secondaryAttachmentIds.length + ' File '
                                                : this.props.item.secondaryAttachmentIds.length + ' Files '} Attached
                                        </a>
                                    </div>
                            }
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default UnAuthPhotosPostItem;