import React from "react";
import {authService} from "../auth";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import alertPopUp from "sweetalert2";
import { withRouter } from "react-router-dom";
import CleverTapUtils from "./CleverTapUtils";


const ErorrMsgPopUp = withReactContent(alertPopUp);
class Pinned extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            pinned: props.item.pinned,
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.item.pinned !== this.state.pinned){
            this.setState({'pinned':nextProps.item.pinned});
        }
    }

    handleRedirection  = () => {
        this.props.history.push({
            pathname: "/saved-posts",
            state : { selectedTab: this.props.entityType, userId: this.state.userId }
        });
        ErorrMsgPopUp.closeModal();
    }

    handlePin = (event) => {
        event.preventDefault();
        let newState = !this.state.pinned;
        if(newState) {
            CleverTapUtils.eventClick({}, "Pin Project");
        }
        let entityId;
        if(this.props.item && ((this.props.entityType.toLowerCase() === 'job' || this.props.entityType.toLowerCase() === 'assignment' ||
            this.props.entityType.toLowerCase() === 'event' || this.props.entityType.toLowerCase() === 'training' ||
            this.props.entityType.toLowerCase() === 'expedition' || this.props.entityType.toLowerCase() === 'fundraise' ||
            this.props.entityType.toLowerCase() === 'storybook') && this.props.item.project && this.props.item.project.id)){
            entityId = this.props.item.project.id;
            }else {
            entityId = this.props.item.id;
        }
        this.setState({
            'pinned': newState,
        })
        const data = {
            userId: this.state.userId,
            entityId: entityId,
            entityType:this.props.entityType,
            pinned: newState
        }
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/pinned/create/',
            data: data,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                this.setState({'pinned': newState})
                if(newState && (this.props.entityType === "FORUM" || this.props.entityType === "POLL")){
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm();
                        }
                    }).then((result) => {
                        return ErorrMsgPopUp.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            title: <><p>You can view your pinned <a href="javascript:void(0)" onClick={() => this.handleRedirection()} >{this.props.entityType.toLowerCase()}</a> on your Dashboard Saved Items</p></>,
                        })
                    })
                }
                
            } else {
                this.setState({'pinned': !newState})
            }
        }).catch((err) => {
            this.setState({'pinned': !newState})
            console.log(err);
        });
    }

    componentWillUnmount() {
        window.$('#likeuser' + this.props.item.id).modal('hide');
    }

    render() {
        return (
            <button type="button" onClick={this.handlePin} className={this.state.pinned ? 'btn btnpin active' : 'btn btnpin'}><i className="iPin" />
            </button>
        );
    }
}

export default withRouter(Pinned)