import React, { Component } from "react";
import axios from "axios";
import { authService } from "../auth";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import DonationViewDonorsItem from "./DonationViewDonorsItem";
class DonationViewDonorsPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            viewDonorsList: [],
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    getViewDonorsDataList = (data) => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_userServiceURL}/donation/${data?.modalType === "VIEW" ? "seen" : data?.modalType === "DONORS" ? "donated" : "donated"}/list?userId=${this.state.userId}&id=${data.id}&entityType=${data.entityType}&paymentReceived=${data.modalType === 'DONORS_AMOUNT'}&page=0&size=10`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    viewDonorsList: response.data.body
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    componentWillUnmount() {
        this.props.onCloseDonationViewDonorPopUp();
    }

    componentDidMount() {
        this.getViewDonorsDataList(this.props.popUpData);
    }

    render() {
        const { viewDonorsList } = this.state;
        const { popUpData, type, data } = this.props;
        return (
            <div id={"donationViewDonorsPopUp"} className={"modal fade dgpopup reqendorse donationCountPopUp" + (popUpData.modalType === "VIEW" ? " viewModal" : popUpData.modalType === "DONORS" ? " donorModal" : "")} tabIndex="-1" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog likesblk">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="similarAllpopWrap forwcontwrap" style={{ maxWidth: popUpData?.modalType === "DONORS_AMOUNT" ? "615px" : popUpData.modalType === "DONORS" ? "960px" : "575px", position: "relative" }}>
                                <button type="button" className="close topCrossModal" aria-label="Close" onClick={(e) => { this.props.onCloseDonationViewDonorPopUp() }}>
                                    <i className="iCross" />
                                </button>
                                <div>
                                    <div className="similarAllpopHeader">
                                        <h2>
                                            Details
                                            {/* {popUpData.modalType === "VIEW" ? "Views" : popUpData.modalType === "DONORS" ? "Donors" : "Amount Collected"} */}
                                        </h2>
                                    </div>
                                    <div className={"similarAllpopBody"}>                                        
                                        <Scrollbars
                                            className={"forwcontlistmain"}
                                            autoHide
                                            autoHeight
                                            autoHeightMin={0}
                                            autoHeightMax={322}
                                            universal={true}
                                            renderTrackHorizontal={(props) => (
                                                <div {...props} className="track-horizontal" />
                                            )}
                                            renderTrackVertical={(props) => (
                                                <div {...props} className="track-vertical" />
                                            )}
                                        >
                                            <div id="donationViewAccordion">
                                                <li className="historyHead">
                                                    <div className="userBlk"><h5>Name</h5></div>
                                                    <div className="userDonateInfo date">
                                                        <div className="donateDate"><span>Date</span></div>
                                                    </div>
                                                    {popUpData?.modalType === "DONORS" &&
                                                        <div className="userDonateInfo status">
                                                            <div className="donateDate"><span>Status</span></div>
                                                        </div>
                                                    }
                                                    {popUpData?.modalType === "DONORS" || popUpData?.modalType === "DONORS_AMOUNT" ?
                                                        <div className="userDonateInfo bankName">
                                                            <div className="donateDate"><span>Bank</span></div>
                                                        </div> : null
                                                    }
                                                    <div className="userDonateInfo fundreveive">
                                                        <div className="donateDate"><span>Amount</span></div>
                                                    </div>
                                                    {popUpData?.modalType === "DONORS" && 
                                                        <>                                                            
                                                            <div className="userDonateInfo upload">
                                                                <div className="donateDate"><span>Receipt</span></div>
                                                            </div>
                                                            <div className="userDonateInfo reminder">
                                                                <div className="donateDate"><span>Reminder</span></div>
                                                            </div>
                                                        </>
                                                    }
                                                </li>
                                                {viewDonorsList && viewDonorsList.content && viewDonorsList.content.map((data, index) => {
                                                    return (
                                                        <DonationViewDonorsItem data={data} index={index} type={popUpData?.modalType.toLowerCase()} entityData={popUpData} key={data.id} />
                                                    )
                                                })}
                                            </div>
                                        </Scrollbars>
                                        {viewDonorsList && viewDonorsList.page && !viewDonorsList.page.last && 
                                            <div className="btnblock text-center">
                                                <button className="btn morebtnsm" onClick={(e) => this.props.history.push({
                                                    pathname: `/donation/${popUpData.modalType.toLowerCase()}/list/${popUpData.entitySlug}`,
                                                    state: {
                                                        entityDetails: this.props.popUpData
                                                    }
                                                    })}>View All</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(DonationViewDonorsPopUp);