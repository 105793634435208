import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en';
import { authService } from "../auth";
import { getCDNUrl, getOptimizedImage } from "../commons/CommonFunctions";
import '../commons/styles/fb-image-grid-style.min.css'
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import PresentationPostView from './PresentationPostView';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

class PresentationSharePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage()
        }
    }
    render() {
        var utcSeconds = this.props.item.createTime;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);
        const linkTarget = {
            pathname: "/profile/" + this.props.item.customUrl,
            key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
            state: {
                applied: true
            }
        };
        const postTarget = {
            pathname: "/post/" + this.props.entityItem.sharedEntityId,
            key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
            state: {
                applied: true
            }
        };
        return (
            <>
                {this.props.item && this.props.item.attachmentIds && this.props.item.attachmentIds.length > 0 && 
                    <PresentationPostView attachments={this.props.item.attachmentIds[0]} entityId={this.props.item?.id} entityItem={this.props.entityItem} />
                }
            </>
        );
    }
}

export default PresentationSharePreview;