import React, {PureComponent} from 'react';

class ResearchPostList extends PureComponent {

    render() {

        return (
            <>
                {/*<li className="dropdown-item dropdown-submenu">
                                <a href="javascript:void(0)" data-toggle="dropdown">
                                  <figure className="ico">
                                    <i className="iReseach_F"/>
                                  </figure>
                                  <div>
                                    <h5>Research</h5>
                                  </div>
                                </a>
                                <ul className="dropdown-menu">
                                  <li className="dropdown-item">
                                    <a href="javascript:void(0)">
                                      <figure className="usimg">
                                        <img src="https://cdn.dscovr.com/images/misc1.webp" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/misc1.png"}} alt="" />
                                      </figure>
                                      <div>
                                        <h5>Birdwacthing Sumit</h5>
                                        <span>Research</span>
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                              </li>*/
                }

            </>
        );
    }
}

export default ResearchPostList;
