import React, { Component } from "react";
import { authService } from "../../../auth";
import { Link, NavLink } from "react-router-dom";
import Images from "../../../commons/Images";
import { detailsPageRedirection, getCDNUrl } from "../../../commons/CommonFunctions";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import MutualConnect from "../../../commons/MutualConnect";
import Follow from "../../../commons/Follow";
import { redirectToProfile, redirectToCircle, getFormattedDate, renderTaggedUsers } from './HashtagsCommonFunction';
import { getImageDetails, publishedTimeSection, redirecToStudioDetailsPage } from "../../../project/component/Studio/StudioContentCommonFunction";

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

class CommonHashTagsStudio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            photoListMap: [],
            photoListOriginalMap: [],
            paidContentPostLock: false
        }
    }

    getOptimizedImage = (personalInfo) => {
        if (
            personalInfo.resizedProfileImages &&
            personalInfo.resizedProfileImages["200x200"] != null
        ) {
            return personalInfo.resizedProfileImages["200x200"];
        }
        return personalInfo.userProfileImage;
    };

    componentDidMount() {

    }

    render() {
        const { item } = this.props;
        let linkifiedHtml =
            '<p class="pdtlsLink forum-pdtlsLink">' +
            renderTaggedUsers(
                this.props.item && this.props.item.description && this.props.item.description !== ""
                    ? this.props.item.description
                    : ""
            )
                .replace(/<img[^>]*>/g, "")
                .replace(/<h2[^>]*>/g, "")
                .replace(/&nbsp;/g, " ")
                .replace(/(\r\n|\r|\n)/g, "<br>")
                .replace(/(\\n)/g, "<br>") +
            "</p>";
        return (
            <div className={"paidProjBlks" + (item.visibleToUser ? "" : " ppLock")}>
                <div style={{ position: 'relative' }}>
                    <a href="javascript:void(0)" onClick={() => redirecToStudioDetailsPage(item)}>
                        <figure className="figImg">
                            <img src={getImageDetails(item)}
                                alt="" />
                            <i className={item.contentType === "IMAGE" ? "iImg" : item.contentType === "PPT" ? "iPPT_New" : item.contentType === "PDF" ? "iPDF" : "iPlay"} />
                        </figure>
                    </a>
                    <div className= {"postedBy" + (this.props.item.userType === "COMPANY" ? " orgnzName" : "")}>
                        <Link to={`/profile/${item.userCustomUrl}`}>Posted By <span>{this.props.item.userName}</span></Link>
                        <div>{publishedTimeSection(item.publishedTime !== 0 ? item.publishedTime : item.createTime, item.publishedTime !== 0 ? "PUBLISH" : "UPDATE")}</div>
                    </div>
                </div>
                {/* <div className="explstoriesdesc">
                    <div className="postbookMhead">
                        {!this.props.item.userEntityType ? (
                            <figure
                                style={{ cursor: "pointer" }}
                                className="complogo"
                                onClick={(e) => this.props.item.curatedPost ? "javascript:void(0)" :
                                    redirectToProfile(
                                        this.props.item.userCustomUrl
                                    )
                                }
                            >
                                <img
                                    src={this.props.item.curatedPost ? "https://cdn.dscovr.com/images/welogoNew.webp" :
                                        this.props.item.userProfileImage &&
                                            this.props.item.userProfileImage !== ""
                                            ? getCDNUrl(
                                                this.getOptimizedImage(this.props.item)
                                            )
                                            : this.props.item.userType !== "COMPANY"
                                                ? "https://cdn.dscovr.com/images/users1.png"
                                                : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                    }
                                    alt=""
                                />
                            </figure>
                        ) : (
                            <figure
                                style={{ cursor: "pointer" }}
                                className="complogo"
                                onClick={(e) => {
                                    e.preventDefault();
                                    redirectToCircle(
                                        this.props.item.params &&
                                        this.props.item.params["circleSlug"]
                                    );
                                }}
                            >
                                <img
                                    src={
                                        this.props.item.params &&
                                            this.props.item.params["circleImage"] !=
                                            null
                                            ? getCDNUrl(
                                                this.props.item.params &&
                                                this.props.item.params[
                                                "circleImage"
                                                ]
                                            )
                                            : "https://cdn.dscovr.com/images/circleDefault.webp"
                                    }
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                            "https://cdn.dscovr.com/images/circleDefault.png";
                                    }}
                                    alt=""
                                />
                            </figure>
                        )}
                        <div style={{ paddingRight: '0' }}>
                            {!this.props.item.userEntityType ? (
                                <h5 className="compuser"
                                    onClick={(e) => this.props.item.curatedPost ? "javascript:void(0)" :
                                        redirectToProfile(
                                            this.props.item.userCustomUrl
                                        )
                                    }
                                    style={{ cursor: 'pointer' }}>{this.props.item.curatedPost ? "EDITOR'S DESK" : this.props.item.userName}</h5>
                            ) : (
                                <h5
                                    className="compuser"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToCircle(
                                            this.props.item.params &&
                                            this.props.item.params["circleSlug"]
                                        );
                                    }}
                                >
                                    {this.props.item.params["circleTitle"]}
                                </h5>
                            )}
                            <div className="d-flex hpostdtls">
                                {this.props.item && this.props.item.country &&
                                    <span className="address"><i
                                        className="iLocation" />{this.props.item && this.props.item.country}</span>}
                                {this.props.item.curatedPost ?
                                    <span className="designation">
                                        {getFormattedDate(
                                            this.props.item.createTime
                                        )}
                                    </span> :
                                    <span className="designation" style={{ cursor: 'pointer' }}>
                                        <Link to={"/post/" + this.props.item.id}>
                                            {getFormattedDate(
                                                this.props.item.createTime
                                            )}
                                        </Link>
                                    </span>}
                                {this.state.loginSuccess === true ? ((this.props.item.connectDepth && this.props.item.userId != this.state.userData.userId) ?
                                    <MutualConnect connectDepth={this.props.item.connectDepth}></MutualConnect> : null) : null}
                            </div>
                        </div>
                    </div>
                </div> */}
                <div>
                    <a href="javascript:void(0)" onClick={() => redirecToStudioDetailsPage(item)} className="paidProjBlksDesc">
                        <h4>{item.title}</h4>
                        <p>{item.shortBrief}</p>
                    </a>
                </div>
            </div>
        )
    }
}

export default CommonHashTagsStudio;