import React, {PureComponent} from 'react';
import {getCDNUrl} from "../commons/CommonFunctions";

class CausePostItem extends PureComponent {

    render() {

        return (
            <li className="dropdown-item">
                <a href="javascript:void(0)" onClick={this.props.setAsSource}>
                    <figure className="usimg">
                        <img src={this.props.item.imageUrl ? getCDNUrl(this.props.item.imageUrl) : "https://cdn.dscovr.com/images/misc1.webp"}
                        onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/misc1.png"}}
                        alt=""/>
                    </figure>
                    <div>
                        <h5>{this.props.item.name}</h5>
                        {/* <span>{this.props.item.primaryHashtags}</span> */}
                        <span>Causes</span>
                    </div>
                </a>
            </li>
        );
    }
}

export default CausePostItem;
