const API_URL = process.env.REACT_APP_userServiceURL;

const Apis = {

    //Follow API
    CheckUserFollow: (data) =>  `${API_URL}/graph/users/${data.userId}/checkIfFollows/${data.otherUserId}`,

    //License API
    LicenseMemberRequest: `${API_URL}/license/update/member/request`,

    //Fund Raise API
    GetFundRaiseRestriction: (data) => `${API_URL}/fund-raise-restriction/get?entityId=${data?.entityId}`,
    ChangeFundRaiseRestrictionPermission: `${API_URL}/fund-raise-restriction/change/permission`,
    CheckFundRaiseValidPermission: (data) => `${API_URL}/fund-raise-restriction/check/valid/permission?userId=${data?.userId}`,
    FundRaiseRestrictionIntimationAdmin: (data) => `${API_URL}/fund-raise-restriction/intimation/admin?entityId=${data?.entityId}`,
    FundRaiseRestrictionIntimationMember: (data) => `${API_URL}/fund-raise-restriction/intimation/member?entityId=${data?.entityId}`,

    //Subscription API
    PaymentCreditUtilization: `${API_URL}/payment/update_credit_utiliation`,
    
    //User API
    UserMasterConfigData: `${API_URL}/master/config/get`,
    ProfileDataUnauth: (data) => `${API_URL}/unauth/userData?customUrl=${data?.customUrl}`,
    PrivacySettingConfigUnauth: (data) => `${API_URL}/unauth/get/privacy-setting-config?customUrl=${data?.customUrl}`,

    //Project API
    ProjectProfileVisibility: (data) => `${API_URL}/backend/project/profile-invisible-data?userId=${data?.userId}&showOnlyMyPost=${data?.showOnlyMyPost}&page=${data?.page}&size=${data?.size}`,
    ProjectUserHideConfig: (projectId) => `${API_URL}/backend/participation/isparticipant-available?projectId=${projectId}`,
    ProjectHiddenConfig: (data) => `${API_URL}/backend/hidden/project/config?userId=${data.otherUserId}&projectId=${data.projectId}`,
    ProjectThreeDotConfig: (data) => `${API_URL}/backend/three/dot/project/get?requestingUserId=${data.requestingUserId}&entityId=${data.entityId}&status=${data.status}`,
    PrivateProjectChangeStatus: `${API_URL}/private/project/change/status`,

    //Paidcontent API
    UserPostCount: (data) => `${API_URL}/paidContentPost/getUserPostCount?userId=${data?.userId}`,

    //Scrapping API
    UrlScrapping: `${API_URL}/recommend/ogscrapper`,

    //Post API
    PostMetaTagsById: (id) => `${API_URL}/unauth/post-metatags-ById?id=${id}`,

    //Hidden API
    HideEntity: `${API_URL}/hidden/hide`,

    //Subscription API
    UserSubscriptionGlobalConfig: `${API_URL}/user/subscription/global/config`,

    //WPOND API
    GetWpondUpcomingYear: `${API_URL}/wpondAbout/getUpcomingYear`,
    GetYearData: (year) => `${API_URL}/wpondAbout/getByYear?year=${year}`,

    //Post API for post click hook
    Pageclickhookpost: `${API_URL}/recommend/pageclickhookentity`,
    GetViewImpressionCount: (id, entityType) => `${API_URL}/recommend/entityanalytics?entityId=${id}&entityType=${entityType}`,
    GetViewUserRestriction: (entityId) => `${API_URL}/recommend/checkoutsideuser?entityId=${entityId}`,
    
    //Faq API
    GetFaqById: (id) => `${API_URL}/backend/unauth/getByQuestionId?id=${id}`,
    GetFaqListData: (data) => `${API_URL}/backend/public/faq/list?searchParam=${data}`,

    //Share API
    ShareCheckRestriction: (data) => `${API_URL}/backend/share/check-restriction?activityId=${data?.entityId}&userId=${data?.userId}`,

    //HeatMap Analytics API
    GetHeatMapAnalyticsData: `${API_URL}/recommend/heatmapanalytics`,
    Geographyinsights: (data) => `${API_URL}/recommend/geographyinsights?user_by=${data?.user_by}&date_string=${data?.date_string}&page=${data?.page}&size=${data?.size}`,
    
    //Top Persona List API
    // TopPersonaCountryListData: `${API_URL}/recommend/postloginpersonaandcountry/`
    TopPersonaCountryListData: `${API_URL}/master/config/get/`
}

export {API_URL, Apis}
