import React, { Component } from "react";
import { authService } from "../auth";
import * as _ from "lodash";
import axios from "axios";
import { convertCurrencySystem } from "../commons/CommonFunctions";
import { withRouter } from "react-router-dom";

class DonationTargetPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            minimumAmount: this.props.donationTargetData?.minimumAmount > 0 ? this.props.donationTargetData?.minimumAmount : "",
            targetAmount: this.props.donationTargetData?.targetAmount > 0 ? this.props.donationTargetData?.targetAmount : "",
            errors: {},
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : !_.isEmpty(this.props.donateNowConfig) && this.props.donateNowConfig?.superAdminId ? this.props.donateNowConfig?.superAdminId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    onHandleChange = (event) => {
        let value = (event.target.value).replace(/[,]/ig, "");
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            this.setState({
                [event.target.name]: value.trimStart()
            });
        }
    }

    removeTargetData = (field) => {
        this.setState({
            [field]: ""
        })
    }   

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (this.state.minimumAmount && parseInt(this.state.minimumAmount) == 0) {
            errors["minimumAmount"] = "Minimum amount should be greater than 0.";
            formIsValid = false;
        }
        if (this.state.targetAmount && parseInt(this.state.targetAmount) == 0) {
            errors["targetAmount"] = "Total colection should be greater than 0.";
            formIsValid = false;
        }
        if(this.state.targetAmount && parseInt(this.state.targetAmount) < this.props.donationBarData?.minValue) {
            errors["targetAmount"] = "Amount should be more than fund raised " + (this.props.indian ? "₹" : "$") + convertCurrencySystem(this.props.donationBarData?.minValue);
            formIsValid = false;
        }
        if(this.state.minimumAmount) {
            if((parseInt(this.state.targetAmount) > 0 && parseInt(this.state.targetAmount) < parseInt(this.state.minimumAmount))) {
                errors["targetAmount"] = "Amount should be lower than the targeted contribution.";
                formIsValid = false;
            }
            if(!this.state.targetAmount && this.props.donationBarData?.minValue < parseInt(this.state.minimumAmount)) {
                errors["minimumAmount"] = "Min. Contribution amount should not be greater than Total Contribution.";
                formIsValid = false;
            }
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    }

    submitDonationTargetData = () => {
        if(this.validateForm()) {
            if(this.state.targetAmount || this.state.minimumAmount || (this.state.targetAmount !== this.props.donationTargetData?.targetAmount) || (this.state.minimumAmount !== this.props.donationTargetData?.minimumAmount)) {
                let postData = {
                    userId: this.state.userId,
                    donationId: this.props.donateNowConfig?.id,
                    entityType: this.props.donateNowConfig?.type,
                    targetAmount: parseInt(this.state.targetAmount),
                    minimumAmount: parseInt(this.state.minimumAmount),
                    controlTargetAmount: parseInt(this.state.targetAmount) > 0 ? true : false,
                    controlMinimumAmount: parseInt(this.state.minimumAmount) > 0 ? true : false,
                    currencyType: this.props.indian ? "INR" : "USD"
                }
                axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_userServiceURL}/backend/donation/set/target/amount`,
                    headers: { 'Content-Type': 'application/json' },
                    data: postData,
                    withCredentials: true
                }).then((response) => {
                    if(response && response.data && response.data.message === "Success!") {
                        this.props.successDonationTargetData(postData);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                this.props.onCloseDonationTargetPopUp();
            } 
        }
    }

    render() {
        const { minimumAmount, targetAmount, errors } = this.state;
        const { indian } = this.props;
        return (
            <div id="donationTargetPop" className="modal fade dgpopup donationTargetPop" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="sProjectListPop" style={{ maxWidth: '500px' }}>
                                <button type="button" className="close topCrossModal" aria-label="Close" onClick={this.props.onCloseDonationTargetPopUp}>
                                    <i className="iCross" />
                                </button>
                                <h2>Fund Raise Target</h2>
                                <div className="pjaddjobsec" style={{ padding: '0', border: 'none' }}>
                                    <p>Provide details of your fund raise</p>
                                    <div className="form-group row align-items-center">
                                        <label className="label col-sm-6">Fund Raise Target <span style={{display: 'block'}}>(optional)</span></label>
                                        <div className="col-sm-6">
                                            <div className="amtInput">
                                                <i>{indian ? <>&#8377; </> : <>&#36; </>}</i>
                                                <input type="text" name="targetAmount" class="form-control custom-input text-right" value={targetAmount > 0 ? new Intl.NumberFormat('en-US').format(targetAmount) : ""} onChange={this.onHandleChange} />
                                                {targetAmount > 0 && <button type="button" className="btn delbtn"><i className="iTrashBin" onClick={() => this.removeTargetData("targetAmount")} /></button>}
                                            </div>
                                            {errors["targetAmount"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{errors["targetAmount"]}</div>}
                                        </div>
                                    </div>
                                    <div className="form-group row align-items-center">
                                        <label className="label col-sm-6">Min. Contribution per User <span style={{display: 'block'}}>(optional)</span></label>
                                        <div className="col-sm-6">
                                            <div className="amtInput">
                                                <i>{indian ? <>&#8377; </> : <>&#36; </>}</i>
                                                <input type="text" name="minimumAmount" class="form-control custom-input text-right" value={minimumAmount > 0 ? new Intl.NumberFormat('en-US').format(minimumAmount) : ""} onChange={this.onHandleChange} />
                                                {minimumAmount > 0 && <button type="button" className="btn delbtn"><i className="iTrashBin"  onClick={() => this.removeTargetData("minimumAmount")} /></button>}
                                            </div>
                                            {errors["minimumAmount"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{errors["minimumAmount"]}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='sProjectListPopBott'>
                                    <p></p>
                                    <button type="button" class="btn btn-primary" onClick={this.submitDonationTargetData}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(DonationTargetPopUp);