import React, {Component} from "react";
import "../../profile/container/profile/css/main-profile.min.css";
import "../../project/container/Project/css/main-projects.min.css";
import "react-toastify/dist/ReactToastify.css";
import {Redirect} from "react-router-dom";
import {authService} from "../../auth";
import Header from "../../Header";
import {Link} from "react-router-dom";
import axios from "axios";
import { userAgentGetData } from "../../commons/CommonFunctions";

class RestrictedProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
        };
    }

    componentDidMount() {
        this.validateDevice();
    }


    validateDevice = () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_userServiceURL}/profile/validate/device?deviceId=`+localStorage.getItem("deviceuuID"),
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response.data.message === "Success!") {
                    window.location.href="/";
                }
            })
            .catch((err) => {
                if (err && err.response && err.response.data) {
                    this.setState({
                        isLoaded: true,
                        loading: false,
                    });
                }
            });
    }

    newTabLink = () => {
        window.open("mailto:support@wenaturalists.com", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
    }

    render() {
        return (
            <div className="wrapper projectpage profilepage pabout ppersonal">
                {/* <Header hamburgerVisibility={false}/> */}
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="bk_dactiveprflbx">
                                    <img src="https://cdn.dscovr.com/images/HiddenProfileImg.png" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/HiddenProfileImg.png"}} alt=""/>
                                        <p>Access to WeNaturalists restricted. <a href="javascript:void(0)" onClick={() => this.newTabLink()} style={{color: '#bfc52e', textDecoration: 'underline' }}><span>Contact Support</span></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }

}

export default RestrictedProfile;
