import React from 'react';
import {Col} from "react-bootstrap";

class LazyBackground extends React.Component {
    state = {src: null};

    componentDidMount() {
        const {src} = this.props;

        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            this.setState({src});
        };
    }

    render() {
        return <Col {...this.props}
                    style={{background: `url(${this.state.src || this.props.placeholder})`, cursor: 'pointer'}}/>;
    }
}

export default LazyBackground
