import React from 'react';
import BypassHeader from '../BypassHeader'
import '../landing/landing.min.css';
import "../landing/responsive-landing.min.css"
import AuthModalComponent from '../LoginExtra/AuthModalComponent';
import { Link, NavLink, Redirect } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { authService } from "../auth";
import SignUpModal from "../SignUpModal/signUpModal";
import LeftPanelUnauth from "../commons/LeftPanelUnauth";
import axios from "axios";
import { getCDNUrl, userAgentGetData } from "../commons/CommonFunctions";
import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en';
import FeedsUnauthItem from './FeedsUnauthItem';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')
class Feeds extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginRequired: false,
      loginSuccess: authService.isAuthenticated(),
      pathname: "",
      signUpRequired: false,
      unAuthPost: {}
    };
  }

  loginRequired = (slug) => {
    this.setState({ loginRequired: true, pathname: slug });
    window.$("#loginModal").modal("show");
  };
  handleLoginClose = () => {
    this.setState({ loginRequired: false });
    window.$("#loginModal").modal("hide");
  };

  signUpRequired = () => {
    this.setState({ signUpRequired: true });
    window.$("#signupModal").modal("show");
  };

  handleSignUpClose = () => {
    this.setState({ signUpRequired: false });
    window.$("#signupModal").modal("hide");
  };

  // Unauth feeds fetch
  getUnAuthFeedsFetch = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/unauth/getNewsFeed/trending?newsFeedType=LATEST&hide=false&page=0&size=3${userAgentGetData()}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            unAuthPost: response.data.body
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    document.title = "Explore a smart way to work for Nature | WeNaturalists";
    if (!this.state.loginSuccess) {
      this.getUnAuthFeedsFetch();
    }
  }

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.userProfileImage;
  }

  render() {
    if (this.state.loginSuccess) {
      return <Redirect to="/" />;
    } else {
      return (
        <div className="wrapper projectpage feed nologinpage">
          <BypassHeader />
          <main className="dgcontainer hm3col">
            <div className="container">
              <div className="row">
                <LeftPanelUnauth signUpRequired={this.signUpRequired} />
                <div className="col-md-9">
                  <div className="mainpanel">
                    <div className="pagetitle">
                      <h1 className="h1ttl">Posting and Feeds</h1>
                    </div>
                    <div className="hpostlist">
                      {this.state.unAuthPost && this.state.unAuthPost.content && this.state.unAuthPost.content.map((item) => {
                        return <FeedsUnauthItem item={item} key={item.id} signUpRequired={this.signUpRequired} loginRequired={this.loginRequired} isFeed={true} /> 
                      })}
                    </div>
                    <div className="postVisualzdata">
                      <a
                        href="javascript:void(0)"
                        onClick={(e) => this.signUpRequired("/feeds")}
                      >
                        Join Us
                      </a>{" "}
                      to discover your passion for nature.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          {/* Login Modal */}
          {this.state.loginRequired === true ? (
            <AuthModalComponent
              handleLoginClose={this.handleLoginClose}
              pathname={this.state.pathname}
              history={this.props.history}
            />
          ) : null}
          {/* Signup Modal */}
          {this.state.signUpRequired === true ? (
            <SignUpModal
              handleSignUpClose={this.handleSignUpClose}
              history={this.props.history}
            />
          ) : null}
        </div>
      );
    }
  }
}

export default Feeds;
