import React, { Component } from "react";
import { authService } from "../../../auth";
import {redirectToProfile, redirectToCircle} from './HashtagsCommonFunction';


class CommonHashTagUserSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
        }
    }
    
    render() {
        return (
            <div>
                {!this.props.item.userEntityType ? (
                    <h5 className={"compuser" + (this.props.item.userType === "COMPANY" ? " orgnzName" : "")}
                        onClick={(e) => this.props.item.curatedPost ? "javascript:void(0)" :
                            redirectToProfile(
                                this.props.item.userCustomUrl
                            )
                        }
                        style={{ cursor: 'pointer' }}>{this.props.item.curatedPost ? "EDITOR'S DESK" : this.props.item.userName}</h5>
                ) : (
                    <h5
                        className="compuser"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault();
                            redirectToCircle(
                                this.props.item.params &&
                                this.props.item.params["circleSlug"]
                            );
                        }}
                    >
                        {this.props.item.params["circleTitle"]}
                    </h5>
                )}
            </div>
        )
    }
}

export default CommonHashTagUserSection;