import React, { Component } from 'react';
import {authService} from "../auth";
import {getCDNUrl, getOptimizedImage} from "../commons/CommonFunctions";
import {Scrollbars} from 'react-custom-scrollbars';

class AssignAdminPop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            userId: '',
            messages: null,
            isLoading: true,
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            data: {},
            message: '',
            success: false,
            searchMembers: '',
            user:{},
            isDisable:true
        };

    }

    handleSearch=(event)=>{
        this.setState({
            searchMembers: event.target.value
        })
    }

    addMember=(user)=>{
        this.setState({'user':user , userId : user.id}, () => {
            if (this.state.user.id != null) {
                this.setState({isDisable: false});
            } else {
                this.setState({isDisable: true});
            }
        })
    }
    submitUser = (event) => {
        event.preventDefault();
        this.setState({'user':{}, radioChecked:false,searchMembers:'' ,isDisable : true, userId : ''
        })
        window.$('#assignsinglepop').modal('hide');
        if(this.props.forAll){
            this.props.assignAdminForAll(this.state.user);
        } else{
            this.props.assignAdminForSingle(this.state.user);
        }
    }

    cancelUser = (event) =>{
        event.preventDefault();
        this.setState({'user':{}, radioChecked:false,searchMembers:'' ,isDisable : true, userId : ''}, () => {
            window.$('#assignsinglepop').modal('hide');
        })
    }

    render() {
        const lowercasedSearch = this.state.searchMembers.toLowerCase();
        const searchedList = this.props.adminList.filter(item=>{
           return Object.keys(item).some(key=>
                   item.username.toLowerCase().includes(lowercasedSearch)
               );
          });
        return <>
            <div id="assignsinglepop" className="chatlistpop modal fade dgpopup" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="forwcontwrap">
                                <div className="forwcontwraptop">
                                    <h4>Select Connect</h4>
                                    <div className="forwcontbodywrap">
                                        <div className="addmemberwrap">
                                            <div className="search2"><input type="text" className="form-control"
                                                                            placeholder="Search Connect" value={this.state.searchMembers}
                                                                            onChange={this.handleSearch}/>
                                            </div>
                                        </div>
                                        
                                        <Scrollbars
                                            className={'addmemberlist admnslctradio'}
                                            autoHide
                                            autoHeight
                                            autoHeightMin={0}
                                            autoHeightMax={230}
                                            universal={true}
                                            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                                        >
                                        <ul>

                                            {searchedList.map((user,userId)=>{
                                                return <li key={userId} onClick={(e)=>this.addMember(user)}>
                                                    <label className="radio-container">
                                                        <div className="members">
                                                            <div className="logo">
                                                                <img src={(user && user.originalProfileImage) ?  getCDNUrl(getOptimizedImage(user,"originalProfileImage")) : 'https://cdn.dscovr.com/images/users1.webp'} onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/users1.png"}} alt="" />
                                                            </div>
                                                            <h5>{user.username}</h5>
                                                        </div>
                                                        <input type="radio"  checked={this.state.userId === user.id} name="radio"/>
                                                        <span className="radio-checkmark"/>
                                                    </label>
                                                </li>
                                            })}


                                        </ul>
                                        </Scrollbars>
                                    </div>
                                </div>
                                <div className="forwcontwrapbott">
                                    <button type="button" className="btn btn-primary" disabled={this.state.isDisable} onClick={this.submitUser}>Select
                                    </button>
                                    <button type="button" className="btn btn-cancel" onClick={this.cancelUser} >Cancel
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

}

export default AssignAdminPop


