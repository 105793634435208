import React from 'react'
import FooterDiv from "../FooterDiv";
import Header from "../Header";
import './main-faq.min.css';
import {authService} from "../auth";
import axios from "axios";
import {Link} from "react-router-dom";
import httpService from "../AxiosInterceptors";
import LeftPanel from '../LeftPanel';
import { getFaqListData } from '../services/FaqApi';

httpService.setupInterceptors();

class FaQ extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            faqPageSize: 20,
            pageNo: 0,
            pagination: false,
            search: '',
            loginSuccess: authService.isAuthenticated(),
            localStorageData: authService.getUserDetailsFromLocalStorage(),
            POSTINGData: [],
            SHARINGData: [],
            PROJECTData: [],
            EXPLOREData: [],
            CHATSData: [],
            FORUMData: [],
            COMPANYData: [],
            PROFILEData: [],
            CommonQuestionsData: [],
            section1: 'PROFILE',
            section2: 'FEED',
            section3: 'NETWORK',
            section4: 'MESSAGES',
            section5: 'EXPLORE',
            section6: 'PROJECTS',
            section7: 'CAUSES',
            section8: 'FORUMANDPOLL',
            section9: 'SUPPORT',
            uniqueId: '1b9d6bcd-bbfd',
            faqCountData: []

        };
        this.getFaqList = this.getFaqList.bind(this);
        this.handelSearchChange = this.handelSearchChange.bind(this);
    }

    componentDidMount() {
        document.title = "FAQs | WeNaturalists"
        this.getFaqList();
        this.getFaqListByCount();
        // window.$(".feqtopsearch").sticky({topSpacing: 65, responsiveWidth: true});
        // if (window.$('#sticky-wrapper').hasClass('sticky-wrapper'))
        //     window.$('.projectinn').css({'padding-bottom' : '150px'});
        var navPos = window.$('.feqtopsearch').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.feqtopsearch').addClass('is-sticky');
            } else {
                window.$('.feqtopsearch').removeClass('is-sticky');
            }
        });
            
    }


    async getFaqList() {
        this.setState({'faqData': {}});
        let response = await getFaqListData(this.state.search);
        if(response?.message === "Success!") {
            this.setState({'faqData': response.body});
            this.getBiFercatedData();
        } else {
            this.setState({
                isLoaded: true,
                error: {message: response.data.message, err: response}
            });
        }
    }

    getFaqListByCount() {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/public/faq/listByCount',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response) => {
            if (response && response.data && response.data.message === 'Success!') {
                this.setState({'faqCountData': response.data.body});
            }
        }).catch((err) => {
            this.setState({
                isLoaded: true,
                error: {message: err.response.data.message, err: err.response}
            });
        });
    }

    shuffleList = (data) => {
        for (let i = data?.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [data[i], data[j]] = [data[j], data[i]];
        }
        return data;
    }

    getBiFercatedData() {
        if (this.state.faqData.POSTING) {
            this.setState({'POSTINGData': this.shuffleList(this.state.faqData.POSTING)});
        }
        if (this.state.faqData.SHARING) {
            this.setState({'SHARINGData': this.shuffleList(this.state.faqData.SHARING)})
        }
        if (this.state.faqData.PROJECT) {
            this.setState({'PROJECTData': this.shuffleList(this.state.faqData.PROJECT)});
        }
        if (this.state.faqData.EXPLORE) {
            this.setState({'EXPLOREData': this.shuffleList(this.state.faqData.EXPLORE)});
        }
        if (this.state.faqData.CHATS) {
            this.setState({'CHATSData': this.shuffleList(this.state.faqData.CHATS)});
        }
        if (this.state.faqData.FORUM) {
            this.setState({'FORUMData': this.shuffleList(this.state.faqData.FORUM)});
        }
        if (this.state.faqData.COMPANY) {
            this.setState({'COMPANYData': this.shuffleList(this.state.faqData.COMPANY)});
        }
        if (this.state.faqData.PROFILE) {
            this.setState({'PROFILEData': this.shuffleList(this.state.faqData.PROFILE)});
        }
        if (this.state.faqData.CommonQuestions) {
            this.setState({'CommonQuestionsData': this.shuffleList(this.state.faqData.CommonQuestions)});
        }
    }

    handelSearchChange = (e) => {
        e.preventDefault();
        this.state.search = e.target.value;
        this.setState({
            'POSTINGData': [],
            'SHARINGData': [],
            'PROJECTData': [],
            'EXPLOREData': [],
            'CHATSData': [],
            'FORUMData': [],
            'COMPANYData': [],
            'PROFILEData': [],
            'CommonQuestionsData': []
        })
        this.getFaqList();
    }

    handleSearchSubmit = (e) => {
        e.preventDefault();
        this.getFaqList();
    }

    commonCardSection = (sectionName, sectionData) => {
        return (
            <div className="CommonQuesblk dgcard">
                <h3><i className="iEdit_16pt"/> 
                    <Link to={`/FaQDetail/${sectionName}/${this.state.uniqueId}`}>{sectionName !== "FORUMANDPOLL" ? sectionName : "FORUM AND POLL"}</Link>
                </h3>
                {sectionData === undefined &&
                    <div className="zdata">
                        <div className="desc">
                            <h4>No Result Found</h4>
                        </div>
                    </div>
                }
                {sectionData != undefined &&
                    <>
                        <div>
                            <ul>
                                {sectionData && sectionData.map((data, index) => {
                                    if (index <= 5) {
                                        return <li key={index}>
                                            <Link to={`/faq-details/${data.slug}`}>
                                                {data.question}
                                            </Link>
                                        </li>
                                    }
                                })}
                            </ul>
                        </div>
                        <div className="bottbtns">
                            <Link to={`/FaQDetail/${sectionName}/${this.state.uniqueId}`} className="link">Learn more</Link>
                        </div>
                    </>
                    
                }
            </div>
        )
    }

    render() {
        return <div className="staticPage">
            <Header/>
            <main className="dgcontainer hm3col">
                <div className="container">
                    <div className="feqtopsearch">
                        <div>
                            <form action="" onSubmit={this.handleSearchSubmit.bind(this)}>
                                <input type="text" className="search_query form-control"
                                       id="search" name="search" value={this.state.search || ''}
                                       placeholder="Search" onChange={this.handelSearchChange}
                                       autoComplete="off"/>
                            </form>
                        </div>
                    </div>
                    <div className="giveFeedbackWrap">
                        <div className="giveFeedbTopbar dgcard">
                            <Link to="/FaQFeedback">
                                <i className="iFeedback"/>
                                <h2>Share your Feedback!</h2>
                                <p>Please share your feedback with us. Every feedback helps us improve and evolve as a
                                    community.</p>
                            </Link>
                        </div>
                    </div>

                    <div className="faqCommonQueswrap">
                        <h2>Common Questions</h2>
                        <div className="row">

                            <div className="col-lg-4 col-md-6">
                                {this.commonCardSection("PROFILE", this.state.PROFILEData)}
                            </div>

                            <div className="col-lg-4 col-md-6">
                                {this.commonCardSection("FEED", this.state.POSTINGData)}
                            </div>

                            <div className="col-lg-4 col-md-6">
                                {this.commonCardSection("NETWORK", this.state.COMPANYData)}
                            </div>

                            <div className="col-lg-4 col-md-6">
                                {this.commonCardSection("MESSAGES", this.state.CHATSData)}
                            </div>

                            <div className="col-lg-4 col-md-6">
                                {this.commonCardSection("EXPLORE", this.state.EXPLOREData)}
                            </div>

                            <div className="col-lg-4 col-md-6">
                                {this.commonCardSection("PROJECTS", this.state.PROJECTData)}
                            </div>

                            <div className="col-lg-4 col-md-6">
                                {this.commonCardSection("CAUSES", this.state.SHARINGData)}
                            </div>

                            <div className="col-lg-4 col-md-6">
                                {this.commonCardSection("FORUMANDPOLL", this.state.FORUMData)}
                            </div>

                            <div className="col-lg-4 col-md-6">
                                {this.commonCardSection("SUPPORT", this.state.CommonQuestionsData)}
                            </div>
                        </div>
                    </div>
                    <div className="faqbottwrap">
                        <div className="faqbottgrid">
                            <i className="iCall_Service"/>
                            <h3>Contact Support</h3>
                            <p>Do you have more questions? Feel free to contact <a href="#contactInfopop1"
                                                                                   data-toggle="modal">Support</a>.</p>
                        </div>

                        <div id="contactInfopop1" className="modal fade dgpopup">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body">

                                        <div className="contactInfopop">
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-label="Close"><span
                                                aria-hidden="true">&times;</span></button>

                                            <div className="contactInfopoprow"><span>Phone Number</span><p><a
                                                href="tel:9920605555">+91 9920605555</a></p></div>
                                            <div className="contactInfopoprow"><span>Email</span><p><a
                                                href="mailto:support@wenaturalists.com"
                                                target="_blank">support@wenaturalists.com</a></p></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
            <LeftPanel/>
            <FooterDiv/>
        </div>

    }

}

export default FaQ
