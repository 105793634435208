import React from "react";
import axios from "axios";
import {authService} from "../auth";
import {requestPostCreationFulfilled} from "../reducer/post";
import {connect} from "react-redux";
import uuid from "react-uuid";
import { userAgentGetData } from "./CommonFunctions";
import CleverTapUtils from "./CleverTapUtils";

class ReportAbuse extends React.Component {

    constructor(props) {
        super(props);
        let userData = authService.getUserDetailsFromLocalStorage();

        let reasonMap = [];
        if (this.props.entityType === 'USER') {
            reasonMap['FAKE_SPAM_OR_SCAM'] = 'Fake, spam or scam';
            reasonMap['ACCOUNT_MAY_BE_HACKED'] = 'Account may be hacked';
            reasonMap['IMPERSONATING_SOMEONE'] = 'Impersonating someone';
            reasonMap['VIOLATES_TERMS_OF_USE'] = 'Violates Terms Of Use';
        } else {
            reasonMap['INAPPROPRIATE_ABUSIVE_OR_OFFENSIVE_CONTENT'] = 'Inappropriate, abusive or offensive content';
            reasonMap['CONTENT_PROMOTING_VIOLENCE_OR_TERRORISM'] = 'Content promoting violence or terrorism';
            reasonMap['FAKE_SPAM_OR_SCAM'] = 'Fake, spam or scam';
            reasonMap['ACCOUNT_MAY_BE_HACKED'] = 'Account may be hacked';
            reasonMap['DEFAMATION_TRADEMARK_OR_COPYRIGHT_VIOLATION'] = 'Defamation, trademark or copyright violation';
            reasonMap['HARASSMENT_OR_THREAT'] = 'Harassment or threat';
        }

        this.state = {
            userData: userData,
            reasonMap: reasonMap,
            details: '',
            reason: '',
            error: '',
            modalEntityId: this.props.entityId + uuid(),
            isDisabled : true,
            errors: {}
        }
    }

    handleReportAbuseSubmit = () => {
        if (this.validateForm()) {
            this.setState({'isSubmitted': true});

            let data = {
                reporterId: this.state.userData.userId,
                entityId: this.props.entityId,
                entityType: this.props.entityType,
                reason: this.state.reason,
                description: this.state.details
            }

            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/backend/reportabuse/add',
                data: data,
                withCredentials: true
            }).then(response => {
                if (response && response.status === 201) {
                    //this.props.requestPostCreationFulfilled({success: true});

                    //this.hideModal();
                    this.setState({
                        'successMsg': 'Your request has been taken and appropriate action will be taken as per our report abuse policy.',
                        'isSubmitted': false
                    }, () => {
                        if (this.props.refreshAllData) {
                            this.props.refreshAllData()
                        }
                    });
                    if(this.props.entityType){
                        CleverTapUtils.eventClick({}, "Report "+this.props.entityType.toLowerCase()) ;
                    }

                } else {
                    console.log(response);
                    this.setState({'isSubmitted': false});
                }
            }).catch((err) => {
                if (err && err.response && err.response.status === 409) {
                    this.setState({'error': 'Your report request was already taken', 'isSubmitted': false});
                } else {
                    console.log(err);
                    this.setState({'isSubmitted': false});
                }
            });
        }
    }

    componentDidMount() {

        window.$('.modal').on('hidden.bs.modal', function () {
            //If there are any visible
            if(window.$(".modal:visible").length > 0) {
                //Slap the class on it (wait a moment for things to settle)
                setTimeout(function() {
                    window.$('body').addClass('modal-open');
                },200)
            }
        });

        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/reportabuse/verifyAlreadyReported?reporterId='
                + this.state.userData.userId + '&entityId=' + this.props.entityId,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 200 && response.data && response.data.body) {
                if (response.data.body.reported === true) {
                    this.setState({'error': 'Your report request was already taken', 'isSubmitted': false}, () => {
                        window.$('#reportPop' + this.state.modalEntityId).modal('show');
                    });
                } else {
                    window.$('#reportPop' + this.state.modalEntityId).modal('show');
                }

            } else {
                console.log(response);
            }
        }).catch((err) => {
            console.log(err);
        });
        this.getUserDataByUserId();
    }

    getUserDataByUserId() {
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL +
                "/profile/get?id=" +
                this.props.entityId+'&otherUserId='+this.state.userData.userId+userAgentGetData(),
            headers: {"Content-Type": "application/json"},
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    let reasonMap = [];
                    this.setState({adminUserData: response.data.body}, ()=>{
                        if(this.state.adminUserData.type=== 'COMPANY'){
                            reasonMap['FAKE_SPAM_OR_SCAM'] = 'Fake, spam or scam';
                            reasonMap['ACCOUNT_MAY_BE_HACKED'] = 'Account may be hacked';
                            reasonMap['VIOLATES_TERMS_OF_USE'] = 'Violates Terms Of Use';
                            this.setState({reasonMap:reasonMap});
                        }
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    componentWillUnmount() {
        this.hideModal();
    }

    handleDetailsUpdate = (event) => {
        this.setState({'details': event.target.value});
        if(event.target.value && event.target.value.length > 0 && event.target.value.trim().length>0){
            this.setState({isDisabled : false});
        }else{
            this.setState({isDisabled : true});
        }
    }

    handleReasonClicked = (e) => {
        this.setState({reason: e.target.value,
        isDisabled : false})
        if(e.target.value === 'OTHERS'){
            this.setState({isDisabled : true})
        }
    }

    hideModal = () => {
        this.setState({
            isSubmitted: false,
            details: '',
            reason: '',
            error: ''
        });
        window.$('#reportPop' + this.state.modalEntityId).modal('hide');
        this.props.triggerReportClose();
    }

    onClickSuccessReportMessagePopUp = () => {
        this.hideModal();
        if(this.props.removePostListItem && this.props.currentTab !== "TOP_FEED" && (this.props.postSection === "FEED" || this.props.entityType === "FORUM" || this.props.entityType === "POLL")) {
            this.props.removePostListItem(this.props.entityId);
        }
    }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (this.state.reason === 'OTHERS' && (!this.state.details.trim() || this.state.details.trim() === '')) {
            errors["detailsText"] = "Please enter a message";
            formIsValid = false;
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    render() {
        return (
            <div id={"reportPop" + this.state.modalEntityId} className="modal fade dgpopup reportPop" tabIndex="-1" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="forwcontwrap">
                                <div className="formwrap">
                                    {
                                        this.state.error && this.state.error !== '' ?
                                            <>
                                                {this.state.error}
                                                <div className="formbottom">
                                                    <button type="button" className="btn btn-cancel"
                                                            onClick={this.hideModal}>Cancel
                                                    </button>
                                                </div>
                                            </>
                                            : (this.state.successMsg && this.state.successMsg !== '' ?
                                            <>
                                                {this.state.successMsg}
                                                <div className="formbottom">
                                                    <button type="button" className="btn btn-cancel"
                                                            onClick={this.onClickSuccessReportMessagePopUp}>Ok
                                                    </button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <label>Reason for reporting</label>
                                                {
                                                    this.state.reasonMap && Object.keys(this.state.reasonMap).length > 0
                                                    && <>
                                                        {
                                                            Object.keys(this.state.reasonMap).map((reasonCode) =>
                                                                <div key={reasonCode} className="form-group">
                                                                    <label
                                                                        className="radio-container">{this.state.reasonMap[reasonCode]}
                                                                        <input id={"reason" + reasonCode}
                                                                               value={reasonCode}
                                                                               checked={this.state.reason === reasonCode}
                                                                               onChange={this.handleReasonClicked}
                                                                               type="radio" name="radio"/>
                                                                        <span className="radio-checkmark"/>
                                                                    </label>
                                                                </div>
                                                            )
                                                        }

                                                        <div className="form-group">
                                                            <label className="radio-container">Others
                                                                <input type="radio" name="radio" value="OTHERS"
                                                                       checked={this.state.reason === 'OTHERS'}
                                                                       onChange={this.handleReasonClicked}/>
                                                                <span className="radio-checkmark"/>
                                                            </label>
                                                            {
                                                                this.state.reason === 'OTHERS'
                                                                && <div>
                                                                    <div
                                                                        className="form-group fadeIn animated profeditmain others">
                                                                    <textarea className="form-control custom-input"
                                                                          placeholder="Write the details"
                                                                          value={this.state.details}
                                                                          onChange={this.handleDetailsUpdate}
                                                                          required/>
                                                                    </div>
                                                                    <span className="login-feedback-error mt-1"
                                                                    style={{display: "block"}}>{this.state.errors.detailsText}</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                }

                                                <div className="formbottom">
                                                    <button type="button" className="btn btn-primary"
                                                            onClick={this.handleReportAbuseSubmit}
                                                            disabled={this.state.isDisabled || this.state.isSubmitted}
                                                            data-toggle="modal">{this.state.isSubmitted ? "Loading..." : "SUBMIT"}
                                                    </button>
                                                    <button type="button" className="btn btn-cancel"
                                                            onClick={this.hideModal}>Cancel
                                                    </button>
                                                </div>
                                            </>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(ReportAbuse)
