import React, {Component} from 'react';
import {authService} from "../auth";
import axios from "axios";
import {UserService} from "../UserData";
import "./styles/EndorsementPopUp.min.css";
import {connect} from "react-redux";
import {setOtherUserData} from "../reducer/auth";
import ProfileVisibility from "./ProfileVisibility";
import mappingUtils from "../project/component/commons/utils/mappingUtils";
import {convertUnicode} from "../commons/CommonFunctions";
import uuid from "react-uuid";


class MembershipRequestPopupForAdminApproval extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            isSubmitted: false,
            errors: {},
            isLoading: false,
            modalEntityId: this.props.entityId + uuid(),
        }

    }

    componentDidMount() {
        window.$('#membership_admin_approval' + this.state.modalEntityId).modal('show');
    }
    componentWillUnmount() {
        window.$('#membership_admin_approval' + this.state.modalEntityId).modal('hide');
    }
    hideModal = () => {
        window.$('#membership_admin_approval' + this.state.modalEntityId).modal('hide');
        this.props.triggerMembershipApprovalForAdminPopUpClose();
    }


    acceptRequest=(event)=>{
        event.preventDefault();
        this.setState({isLoading: true, isSubmitted: false});
        let postData = {
            circleId: this.props.entityId,
            userId: this.props.senderUserId,
            createdBy:this.props.requestedUserId,
            adminId : this.state.userData.userId
        }
        axios({
            method:'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/circle/approve-member-request-by-admin',
            data:postData,
            header:{ 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response)=>{
            if (response && response.data && response.data.status === '202 ACCEPTED') {
                this.setState({isLoading: false});
                this.hideModal();
                this.props.removeNotification(this.props.entityId);
            }
        }).catch((err)=>{
            console.log(err)
        })

    }

    redirection = (event)=>{
        event.preventDefault();
        window.location.href="/profile/"+this.props.customurl;
    }

    declineRequest=(event)=>{
        event.preventDefault();
        this.setState({isLoading: true});
        let postData = {
            circleId: this.props.entityId,
            userId: this.props.senderUserId,
            createdBy:this.props.requestedUserId,
            adminId : this.state.userData.userId
        }
        axios({
            method:'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/circle/decline-member-request-by-admin',
            data:postData,
            header:{ 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response)=>{
            if (response && response.data && response.data.status === '202 ACCEPTED') {
                this.hideModal();
                this.props.removeNotification(this.props.entityId);
                this.setState({isLoading: false});
            }
        }).catch((err)=>{
            console.log(err)
        })

    }


    render() {
        return <>
            <div className="modal fade dgpopup confirmpop" id={'membership_admin_approval'+this.state.modalEntityId} tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" onClick={this.hideModal} aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                        <div className="confirm-popup-modal-body">
                            <div class="membership_admin" onClick={this.redirection}>
                                <figure class="userlogo"><img src={this.props.profileImage ?this.props.profileImage:mappingUtils.returnDefaultImage(this.props.userType)} alt="" /></figure>
                                <div>
                                    <h3>{mappingUtils.capitalizeFirstLetter(this.props.username)}</h3>
                                    <span class="address">{this.props.userCountry}</span>
                                </div>
                            </div>
                            Are you sure you want to accept Membership request of {mappingUtils.capitalizeFirstLetter(this.props.username)} for {this.props.entityTitle} community?
                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.acceptRequest}
                                    disabled={this.state.isLoading}>Yes
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={this.declineRequest}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>


    }
}

export default MembershipRequestPopupForAdminApproval;


