import React, { PureComponent } from 'react';
import { Redirect } from "react-router-dom";

class HashtagSearchItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            redirectToHatags: false
        }
    }

    redirect = () => {
        document.body.classList.remove('searchDropActive');
        window.location.href = "/explore-hashtag/" + this.props.item.tags;
    }

    render() {

        return (
            <>
                {this.state.redirectToHatags && <Redirect push to={"/explore-hashtag/" + this.props.item.tags} />}

                <li>
                    <a href="javascript:void(0)" onClick={this.redirect}>
                        <div className="srchpostmain">
                            <div>
                                <h5># {this.props.item.tags}</h5>
                            </div>
                        </div>
                    </a>
                </li>
            </>
        );
    }
}

export default HashtagSearchItem;