import {authService} from "../auth";

import axios from "axios";

import httpService from '../AxiosInterceptors';

httpService.setupInterceptors();

export const findAppliedParticipants =
    process.env.REACT_APP_userServiceURL +
    "/backend/participation/find-project-applicants-details-by-approverId-and-projectId/";

export const findPublishedParticipants =
    process.env.REACT_APP_userServiceURL +
    "/backend/participation/find-project-published-applicants-details-by-approverId-and-projectId/";

export const fetchAppliedParticipants = function (
    projectId,
    isPublished = true,
    page = 0,
    size = 6
) {
    let uid =
        authService.getUserDetailsFromLocalStorage() == null
            ? ""
            : authService.getUserDetailsFromLocalStorage().userId;
    let url =
        (isPublished ? findPublishedParticipants : findAppliedParticipants) +
        uid +
        "/" +
        projectId +
        "&page=" +
        page +
        "&size=" +
        size;
    return axios({
        method: 'get',
        url: url,
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};
