import React, {Component} from 'react';
import {getCDNUrl} from "../commons/CommonFunctions";

class CauseSearchItem extends Component {

    redirect = () => {
        document.body.classList.remove('searchDropActive');
        window.location.href = '/cause/' + this.props.item.slug
    }

    getCauseOptimizedImage(data) {
        if (data.resizedImages && data.resizedImages['300x300'] != null) {
            return data.resizedImages['300x300'];
        }
        return data.imageUrl;
    }

    render() {

        return (
            <li>
                <a href="javascript:void(0)" onClick={this.redirect}>
                    <div className="srchpostmain">
                        <figure className="users"><img
                            src={this.props.item.imageUrl ?
                               getCDNUrl(this.getCauseOptimizedImage(this.props.item))  : "https://cdn.dscovr.com/images/circleDefault.webp"} onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/circleDefault.png"}}
                            alt=""/></figure>
                        <div>
                            <h5>{this.props.item.name}</h5>
                            {
                                this.props.item.hashtags && this.props.item.hashtags.length > 0 &&
                                <div className="causestag">{this.props.item.hashtags[0]}</div>
                            }
                        </div>
                    </div>
                    <div className="srchposttime">
                        {this.props.item.location}
                    </div>
                </a>
            </li>
        );
    }
}

export default CauseSearchItem;
