import React from 'react';
import {authService} from "../auth";
import $ from 'jquery';
import ProjectPostItem from "./ProjectPostItem";
import Scrollbars from 'react-custom-scrollbars';

class ProjectPostList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            projectList: props.projectList ? props.projectList : []
        }
    }

    componentDidMount() {
        window.$('.dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.dropdown-menu').addClass('show');
            e.stopPropagation();
        });
    };


    render() {

        return (
            <>
            {this.props.projectList && this.props.projectList.length > 0 &&
                <div className="postCardBlk">
                    <div className='postCardHeading' id={'heading-project'}>
                        <a role="button" data-toggle="collapse" href={'#projectList'} aria-expanded="false">
                            <figure className="ico">
                                <i className="iProjects_OL"/>
                            </figure>
                            <div>
                                <h5>Project</h5>
                            </div>
                        </a>
                    </div>                    
                    <div id={'projectList'} className="collapse" data-parent="#accordion" aria-labelledby={'heading-project'}>
                        <div className="postCardbody">
                            <Scrollbars
                                className={'forwcontlistmain'}
                                autoHide
                                autoHeight
                                autoHeightMin={0}
                                autoHeightMax={230}
                                universal={true}
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                renderTrackVertical={props => <div {...props} style={{display: 'none'}} className="track-vertical"/>}
                            >
                            {
                                this.props.projectList.map((item) =>
                                    <ProjectPostItem key={item.id} item={item}
                                                    setAsSource={() => this.props.setAsEntity(item.id, 'Project', item.title)}/>
                                )
                            }
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            }
            </>
        );
    }
}

export default ProjectPostList;
