import React from "react";
import uuid from "react-uuid";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import Linkify from "react-linkify";
import {renderToString} from 'react-dom/server';
import ReactHtmlParser from 'react-html-parser';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

function matchHashTags(text) {
    if(!text || text.length === 0){
        return text;
    }
    var string = text;
    var regex = /(#[a-z\d-_]+)/gi;
    var matches = string.matchAll(regex);
    for (var match of matches) {
        if (match && match.length > 1) {
            string = string.replace(match[0], 
                '<a class=\"hashLinkPost\" href="/explore-hashtag/' + match[0].substr(1) + '">' + match[0].substr(1) + '</a>');
        }
    }
    return string;
}

function transform (node, index) {
    if(node.type === 'text') {
        let modifiedString = matchHashTags(node.data)
        if(modifiedString !== node.data) {
            node.type = 'tag';
            node.name = 'div';
            return <>{ReactHtmlParser(modifiedString)}</>
        }
    }
}

const options = {
    decodeEntities: true,
    transform
};

class ReadMoreReadLess extends React.Component {

    constructor(props) {
        super(props);
        let id = uuid();
        if (this.props.id) {
            id = this.props.id;
        }
        this.state = {
            id: id,
            isShowSeeTranslate: true
        }
    }

    handleReflow = () => {
        var readmoreDivSelector = "feeddesctxt" + this.state.id;

        window.$("#" + readmoreDivSelector + ' .LinesEllipsis-ellipsis').click(function () {
            window.$("#" + readmoreDivSelector).addClass("current");
        });
        window.$("#" + readmoreDivSelector + ' .readlss').click(function () {
            window.$("#" + readmoreDivSelector).removeClass("current");
        })
    }

    renderTaggedUsers = (text) => {
        if(!text || text.length === 0) {
            return text;
        }
        text = text.split("@@@__").join("<a href=\"/profile/");
        text = text.split("^^__").join("\">")
        text = text.split("&&__").join("\">")
        text = text.split("&amp;&amp;__").join("\">")
        text = text.split("&amp;&amp;__").join("\">")
        text = text.split("@@@^^^").join("</a>");
       text = text.split("###__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/explore-hashtag/");
        text = text.split("&&__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/explore-hashtag/");
        text = text.split("###^^^").join("</a>");
        //text =  this.checkHashTag(text);
        return text;
    }

    checkHashTag = (text) =>
    {
        if(text.includes("/explore-hashtag")){
            let data = text.substring(
                text.indexOf(">") + 1,
                text.lastIndexOf("<")
            );
            return ("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/explore-hashtag/"+data+'">'+data+"</a>");


        }
        return text;
    }

    handleTranslateData = () => {
        this.setState({
            isShowSeeTranslate: !this.state.isShowSeeTranslate
        })
    }

    render() {
    
        let linkfiedHtml = "<p class=\"pdtlsLink\">" + this.renderTaggedUsers(renderToString(
            window.ReactNativeWebView ? <Linkify>{this.props.description
                ? this.props.description.replace(/<br\/>/g, '\n').replace(/&nbsp;/g, ' ')
                : ''}</Linkify>:<Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                    </a>
                )}>{this.props.description
                ? this.props.description.replace(/<br\/>/g, '\n').replace(/&nbsp;/g, ' ')
                : ''}</Linkify>
            )).replace(/&nbsp;/g, ' ')
            .replace(/#+/g, '#')
            .replace(/(\r\n|\r|\n)/g, '<br>')
            .replace(/(\\n)/g, '<br>') + "</p>"
        let linkfiedHashtagHtml = ((this.props.hashTags && this.props.hashTags !== undefined && this.props.hashTags !== null && this.props.hashTags.length > 0) ? 
        "<div class=\"hposthash\">"+
            this.props.hashTags.map((item) => {
                    return ("<a href=\"/explore-hashtag/"+item+"\">"
                    + "<i class=\"iHashtag\">"+"</i>" +item+
                    "</a>")
                }
            )
       + "</div>" : "")
       linkfiedHashtagHtml = linkfiedHashtagHtml.replace(/,/g, '');
        //linkfiedHtml = renderToString(ReactHtmlParser(linkfiedHtml,options)).replace(/,/g, '');
        linkfiedHtml = renderToString(ReactHtmlParser(linkfiedHtml+linkfiedHashtagHtml,options));
        let maxLine = '5'
        if(this.props.line){
            maxLine = this.props.line
        }
        return ((this.props.description || this.props.hashTags) && <div className="feeddesctxt" id={"feeddesctxt" + this.state.id}>
                <div className="descfull">
                    <p dangerouslySetInnerHTML={{
                        __html: linkfiedHtml
                    }}/>

                    {/* {this.getHashTag} */}

                    {/* {
                        this.props.hashTags && this.props.hashTags.length > 0
                        && <div className="hposthash">
                            {
                                this.props.hashTags.map((item) => {
                                    return <div key={item} className="hposthashitem"><a href={'/explore-hashtag/'+item}><i
                                        className="iHashtag"/>{item}</a></div>
                                })
                            }
                        </div>
                    } */}
                    <span className="readlss">...Read less</span>
                </div>
                <ResponsiveEllipsis
                    unsafeHTML={linkfiedHtml}
                    maxLine={maxLine}
                    ellipsis={`...${this.props.pageName !== "CIRCLE" ? "Read more" : ""}`}
                    onReflow={this.handleReflow}
                    trimRight
                    basedOn='words'
                />
                {this.props.item?.translationDescription && <a href="javascript:void(0)" onClick={(e) => this.handleTranslateData()}>{this.state.isShowSeeTranslate ? "See" : "Hide"} translation</a>}
                {!this.state.isShowSeeTranslate && 
                    <div className="">
                        <p dangerouslySetInnerHTML={{ __html: this.props.item?.translationDescription}}/>
                    </div>
                }
            </div>
        );
    }
}

export default ReadMoreReadLess
