import React from 'react'
import {authService} from "../auth";
import $ from 'jquery';
import Header from "../Header";
import FooterDiv from "../FooterDiv";
import StaticHeader from "../StaticHeader";
import {Link, NavLink} from "react-router-dom";
import LeftPanel from '../LeftPanel';
import AuthModalComponent from "../LoginExtra/AuthModalComponent";
import SignUpModal from "../SignUpModal/signUpModal";
import LeftPanelUnauth from "../commons/LeftPanelUnauth";


class MediaInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        window.$('#header .iCross').hide();
        window.$("#header .iHamburger").on('click', function (event) {
            window.$('.leftpanel').addClass('navactive');
            window.$('.dgcontainer').addClass('navactive');
            window.$('.staticPage').addClass('navactive');
            window.$('#header .iHamburger').hide();            
            window.$('#header .iCross').show();
        });
        $("body, html").on('click', function (event) {
            if (!$(event.target).closest('#header .iHamburger, .read-more-less-btn').length) {
                $('.leftpanel').removeClass('navactive');
                $('.dgcontainer').removeClass('navactive');
                window.$('.staticPage').removeClass('navactive');
                $('.dropdown-menu.show').removeClass('show');
                window.$('#header .iHamburger').show();            
                window.$('#header .iCross').hide();
            }
        });
    }

    loginRequired=(slug)=> {
        this.setState({'loginRequired': true, 'pathname':slug})
        window.$('#loginModal').modal('show');
    }
    handleLoginClose = () => {
        this.setState({'loginRequired': false})
        window.$('#loginModal').modal('hide');
    }

    signUpRequired=()=>{
        this.setState({'signUpRequired':true})
        window.$('#signupModal').modal('show');
    }

    handleSignUpClose = () => {
        this.setState({'signUpRequired': false})
        window.$('#signupModal').modal('hide');
    }

    render() {
        return (
            <div className="staticPage">
                {!this.state.loginSuccess ? <StaticHeader/> : <Header/> }
                <main className="dgcontainer hm3col">
                    <div className="container">

                        <section className="globalinn">
                            <div className="pagetitle bkstatcpgttl text-center">
                                <h1>MEDIA FILE TYPES SUPPORTED ON WENATURALISTS</h1>
                            </div>

                            <div className="globalPgCont">
                                <p>You can enhance your WeNaturalists experience by adding and sharing media samples.</p>
                                <p>The following file formats of media samples are supported:</p>
                                <ul className="mdinfoul">
                                    <li>Adobe PDF (.pdf)</li>
                                    <li>Microsoft PowerPoint (.ppt/.pptx)</li>
                                    <li>Microsoft Excel (.xls/.xlsx)</li>
                                    <li>Microsoft Word (.doc/.docx)</li>
                                    <li>.csv</li>
                                    <li>.txt</li>
                                    <li>.jpg/.jpeg</li>
                                    <li>.png</li>
                                    <li>.gif - this doesn’t support animation</li>
                                    <li>Audio formats (.mp3/.ogg/.mpeg/.aac/.wav)</li>
                                    <li>Video formats (.mp4/.webm/.mkv/.mov/.avi/.ogg/.flv/.wmv)</li>
                                </ul>
                                <h4 className="sf-ttl2">Important:</h4>
                                <ul className="mdinfoul">
                                    <li>Individual file size cannot exceed 100 MB.</li>
                                    <li>Blog cover image size cannot exceed 15 MB.</li>
                                </ul>
                            </div>
                        </section>

                    </div>
                </main>
                
                {!this.state.loginSuccess ? <LeftPanelUnauth signUpRequired={this.signUpRequired} path={'/media-info'}/>
                 :
                <LeftPanel/>}
                <FooterDiv/>
                {this.state.loginRequired === true ? <AuthModalComponent handleLoginClose={this.handleLoginClose} pathname={this.state.pathname}/> : null}
                {this.state.signUpRequired === true ? <SignUpModal  handleSignUpClose={this.handleSignUpClose} history={this.props.history}/> : null}
            </div>
        );

    }

}

export default MediaInfo
