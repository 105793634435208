import React, { Component } from "react";
import { authService } from "../auth";
import axios from "axios";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import uuid from "react-uuid";
import DonationSubscriptionItemList from "./DonationSubscriptionItemList";
import DonationDateSelecItemlist from "./DonationDateSelecItemlist";
import DonationPopUp from "./DonationPopUp";
import { disclaimerText, donationModalForEntity, donationTopUpForEntity, donationTopUpForEntityMessage, getFormattedDate, userSubscriptionError } from "./DonationCommonFunction";
import SendMessageModal from "../commons/SendMessageModal";
import { Redirect } from "react-router-dom";
import DonationProgressbar from "./DonationProgressbar";
import {withRouter} from "react-router-dom";
import DonationTargetComponent from "./DonationTargetComponent";
import { checkFundRaiseValidPermission } from "../services/FundRaiseApi";
import { fundRaiseRestrictionMessage } from "../commons/CommonFunctions";
import Share from "../commons/Share";
import EntityFollow from "../commons/EntityFollow";

const ErorrMsgPopUp = withReactContent(alertPopUp)

class DonationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            entityConfigData: {},
            donationItemListPop: false,
            donationDateItemListPop: false,
            donationPopUp: false,
            openSendMessageModal: false,
            donationDateSelectData: {},
            donateNowConfig: {},
            subscriptionPageRedirect: false,
            showAcceptDonationBtn: false,
            donationBar: this.props.donationBar,
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : this.props.userId ? this.props.userId : authService.getUserDetailsFromLocalStorage().userId,
            userConfigData: {}
        }
    }

    getEntityConfig = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_userServiceURL}/user/subscription/donation/entity/config?userId=${this.state.userId}&entityId=${this.props.entityId}&entityType=${this.props.entityType}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.data.message === 'Success!') {
                this.setState({
                    entityConfigData: response.data.body,  
                },() => {
                    if(this.state.entityConfigData?.initialPopupShow) {
                        this.onDonateFunction();
                    }
                    if(this.props.needDonationConfig) {
                        this.props.getDonationConfig(this.state.entityConfigData);
                    }
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getDonateNowConfig = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_userServiceURL}/donation/data?userId=${this.state.userId}&entityId=${this.props.entityId}&entityType=${this.props.entityType}&requestingUserId=${this.state.userData.userId}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.data.message === 'Success!') {
                this.setState({
                    donateNowConfig: response.data.body, 
                    showAcceptDonationBtn: true 
                }, () => {
                    if(this.props.needDonationConfig && this.props.pageType !== "PARTICIPANT" && this.props.pageType !== "GUEST_INVITEE") {
                        this.props.getEntityDonationConfig(this.state.donateNowConfig);
                    }
                    if(this.state.donateNowConfig.id) {
                        this.donationProjectSeenAndUpdate(this.state.donateNowConfig.id, "PROJECT_SEEN");
                    }
                })
            }
        }).catch((err) => {
            console.log(err);
            if(err.response.data.message) {
                userSubscriptionError("DONATE_NOW", err.response.data.message);
            }
        });
    }

    checkFundRaiseValidPermission = async () => {
        let response = await checkFundRaiseValidPermission({"userId": this.props.entityType === "CIRCLE" ? this.props.entityId : this.props.projectUserId});
        if(response?.message === "Success!") {
            if(response?.body) {
                this.onClickProceedDonateBtn();
            } else {
                fundRaiseRestrictionMessage();
            }
        }
    }

    onClickProceedDonateBtn = async () => {
        if(this.state.entityConfigData?.donateNowAvailable && this.state.entityConfigData.bankDetailAvailable) {
            this.setState({
                donationItemListPop: true
            },() => {
                window.$("#donationItemListPop").modal("show");
            })
        }
        if(this.state.entityConfigData?.donateNowAvailable && !this.state.entityConfigData.bankDetailAvailable) {
            this.donationTopUpMessageForAdminSuperAdmin("BANK", this.props.entityDetails?.superAdminId && ((this.props.entityDetails?.superAdminId !== this.state.userData?.userId) && (this.props.entityDetails?.userId !== this.state.userData?.userId)) ? "ADMIN" : "SUPER_ADMIN");
        }
        if(this.state.entityConfigData?.limitExceeded || (this.state.entityConfigData?.currentPlan === "BRONZE" && (this.state.entityConfigData?.limitExceeded || (this.state.entityConfigData?.availableFromTopUp === 0 && this.state.entityConfigData?.totalAvailable === 0)))) {
            if(this.props.entityDetails?.superAdminId && (this.props.entityDetails?.superAdminId !== this.state.userData?.userId) && (this.props.entityDetails?.userId !== this.state.userData?.userId)) {
                this.donationTopUpMessageForAdminSuperAdmin("SUBSCRIPTION");
            } else {
                const swalval = await ErorrMsgPopUp.fire(donationTopUpForEntity(this.state.entityConfigData?.currentPlan, this.props.entityType));
                if(swalval && swalval.value === true) {
                    this.setState({
                        subscriptionPageRedirect: true
                    })
                }
                if(swalval && swalval.isDismissed) {
                    this.postUserPreferenceDonation("LATER");
                }
            }
        }
    }

    donationTopUpMessageForAdminSuperAdmin = (messageType) => {
        ErorrMsgPopUp.fire({
            didOpen: () => {
                ErorrMsgPopUp.clickConfirm();
            }
        }).then((result) => {
            return ErorrMsgPopUp.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                title: <>{messageType === "BANK" ? 
                            <p>Please update bank details. {this.props.entityDetails?.superAdminId && ((this.props.entityDetails?.superAdminId !== this.state.userData?.userId) && (this.props.entityDetails?.userId !== this.state.userData?.userId)) ? 
                                <>Contact Super Admin <a href="javascript:void(0)" onClick={(e) => { e.preventDefault();  ErorrMsgPopUp.close(); this.setState({openSendMessageModal: true}); }}>{this.props.entityDetails?.superAdminName}</a></> :
                                <a class="styleBtn" onClick={(e)=>this.props.history.push({
                                    pathname: '/security-and-login',
                                    state: {
                                        pageName: "DETAILS",
                                        slug: this.props.entitySlug,
                                        entityType: this.props.entityType,
                                        userId: this.state.userId
                                    }
                                })} href='/security-and-login'>Update now</a>
                            }</p> : 
                            <p>{donationTopUpForEntityMessage(this.state.entityConfigData?.currentPlan, this.props.entityType)} Contact Super Admin <a href="javascript:void(0)" onClick={(e) => { e.preventDefault();  ErorrMsgPopUp.close(); this.setState({openSendMessageModal: true}); }}>{this.props.entityDetails?.superAdminName}</a></p>
                        }
                    </>,
            })
        })
    }

    onDonateFunction = () => {
        const donationConfirm = async () => {
            const swalval = await ErorrMsgPopUp.fire(donationModalForEntity(this.state.entityConfigData.type));
            if (swalval && swalval.value === true) {
                this.onClickProceedDonateBtn();
            } 
            if(swalval && (swalval.isDenied || swalval.isDismissed) && (swalval?.dismiss === "cancel" || swalval?.value === false)) {
                this.postUserPreferenceDonation(swalval.isDenied ? "IGNORE" : "LATER");
            }
        };
        donationConfirm();
    }

    postUserPreferenceDonation = (preferanceType) => {
        let postData = {
            userId: this.state.userId,
            entityId: this.props.entityId,
            entityType: this.props.entityType,
            preferanceType: preferanceType
        }
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/backend/donation/set/user/preferances`,
            headers: { 'Content-Type': 'application/json' },
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                this.getEntityConfig();
                this.getDonateNowConfig();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    donationProjectSeenAndUpdate = (id, type) => {
        let postData = {
            userId: this.state.userId,
            entityType: this.props.entityType
        }
        if(type === "PROJECT_SEEN") {
            postData.id = id;
        } 
        if(type === "DONATION_EXPIRED" || type === "DONATION_LATER") {
            postData.entityId = id;
        }
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/backend/donation/${type === "PROJECT_SEEN" ? "seen" : "update/donate-button"}`,
            headers: { 'Content-Type': 'application/json' },
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.data.message === 'Success!') {
                
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    onClickDonateNowBtn = () => {
        if(this.state.donateNowConfig?.donateNowButtonExpired) {
            this.donationExceptionPopUp("DONATION_EXPIRED");
        } else if (this.state.donateNowConfig?.yetToStart && !this.state.donateNowConfig?.running) {
            this.donationExceptionPopUp("DONATION_LATER");
        } else if((this.state.donationBar.maxValue - this.state.donationBar.minValue) === 0) {
            this.donationExceptionPopUp("DONATION_LIMIT");
        } else {
            this.setState({
                donationPopUp: true
            }, () => {
                window.$("#donationPopUp").modal("show");
            })   
        }
    }

    donationExceptionPopUp = (type) => {
        if(type !== "DONATION_LIMIT") {
            this.donationProjectSeenAndUpdate(this.props.entityId, type);
        } else {
            this.donationTargetAchived();
        }
        ErorrMsgPopUp.fire({
            didOpen: () => {
                ErorrMsgPopUp.clickConfirm();
            }
        }).then((result) => {
            return ErorrMsgPopUp.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                title: <><p>{type === "DONATION_LATER" ? `It will accept funds from ${getFormattedDate(this.state.donateNowConfig?.activationTime)}.` : `${type === "DONATION_LIMIT" ? `It is no longer accepting funds.`: `It is no longer accepting funds.`}`} {((this.props.entityDetails?.superAdminId !== this.state.userData?.userId) && (this.props.entityDetails?.userId !== this.state.userData?.userId)) && <>{type === "DONATION_LIMIT" ? "contact" : "Contact"} Super Admin <a href="javascript:void(0)" onClick={(e) => { e.preventDefault();  ErorrMsgPopUp.close(); this.setState({openSendMessageModal: true}); }}>{this.props.entityDetails?.superAdminName || this.state.donateNowConfig?.superAdminName}</a></>}</p></>,
            })
        })
    }

    donationTargetAchived = () => {
        let postData = {
            id: this.state.donateNowConfig?.id,
            entityId: this.props.entityId,
            entityType: this.props.entityType
        }
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/backend/donation/notify/target/amount/exceed`,
            method: "POST",
            data: postData,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.body && response.data.message === "Success!") {
                
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    triggerCloseModalPopUp = (type) => {
        window.$("#"+type).modal("hide");
        this.setState({
            [type]: false
        })
    }

    triggerMessageModalClose = () => {
        this.setState({openSendMessageModal: false});
    }

    handlePublishDonationList = (donationDetails) => {
        let postData = {
            userId: this.state.userId,
            entityType: donationDetails?.type
        }
        let entityList = [];
        entityList.push({"entityId": donationDetails?.entity?.entityId, "bankId": donationDetails.bankDetails && donationDetails.bankDetails[0].id})
        postData.entityList = entityList;
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/user/subscription/donation/create`,
            headers: { 'Content-Type': 'application/json' },
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.data.message === 'Success!') {
                this.onSuccessDonationEntitySubmit(response.data.body);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    onSuccessDonationEntitySubmit = (data) => {
        window.$("#donationItemListPop").modal("hide");
        this.setState({
            donationItemListPop: false,
            donationDateItemListPop: true,
            donationDateSelectData: data
        }, () => {
            window.$("#donationDateItemListPop").modal("show");
        })
    }
    
    onClickAdditionalDonation = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_userServiceURL}/backend/donation/activated/entity?entityId=${this.props.entityId}&entityType=${this.props.entityType}&userId=${this.state.userId}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response) => {
            let responseData = response.data.body;
            let entityConfigData = this.state.entityConfigData;
            entityConfigData.showAdditonalTopUp = true;
            this.setState({
                entityConfigData: entityConfigData
            }, () => {
                this.onSuccessDonationEntitySubmit(responseData);
            })
        }).catch((err) => {
            console.log(err);
        });
    }

    onClickViewStats = () => {
        if(this.props.entityType === "PROJECT") {
            this.props.changeTab("stats");
        } else {
            this.props.changeTab();
        }
    }

    onSuccessDonationTargetUpdate = (donationBarData) => {
        this.setState({
            donationBar: donationBarData
        })
    }

    getButtonDetails = (donateNowConfig) => {
        return (
            <>  
                
                {donateNowConfig?.id && this.props.pageType !== "STATS_PAGE" &&  
                    <div className="viewStatBtnBlk">                   
                        <button className={'btn payNow'} onClick={() => this.onClickDonateNowBtn()}>Fund Now</button>
                    </div>
                }                    
                
                {this.state.showAcceptDonationBtn && ((this.props.entityType === "PROJECT" && this.props.entityDetails.project?.type !== "JOB") || (this.props.entityType === "CIRCLE" && this.props.pageType === "DETAILS" && this.props.entityDetails?.active)) && !this.state.entityConfigData?.initialPopupShow && !donateNowConfig?.donateNowButtonVisible && !donateNowConfig?.yetToStart && !donateNowConfig?.running && ((this.props.superAdminId === this.state.userId) || (this.props.creatorUserId === this.state.userId)) &&
                    <button className={'btn payNow'} onClick={() => this.checkFundRaiseValidPermission()}>Raise Funds</button>
                }
                
                <>
                    {this.state.donationBar?.donateActive && <DonationProgressbar donationBar={this.state.donationBar} />}
                    <div className={donateNowConfig?.id && donateNowConfig?.manageDataPermission && this.state.donationBar?.donateActive && this.props.entityType === "CIRCLE" ? "donationBarWrap ownDonateStats" : "donationBarWrap"}>                    
                        {donateNowConfig?.id && donateNowConfig?.manageDataPermission && this.props.pageType !== "STATS_PAGE" && 
                            <button className={'btn viewStatsBtn'} onClick={() => this.onClickViewStats()}><i className="iBarGraphs" /></button>
                        }
                        {this.props.entityType === "CIRCLE" &&
                            <>
                                {donateNowConfig?.id && donateNowConfig?.manageDataPermission && this.state.donationBar?.donateActive && 
                                    <DonationTargetComponent donateNowConfig={donateNowConfig} indian={this.state.entityConfigData?.indian} donationBarData={this.state.donationBar} onSuccessDonationTargetUpdate={this.onSuccessDonationTargetUpdate} />
                                }
                                {this.state.donationBar?.donateActive &&
                                    <>
                                        <span className="disclaimer" data-toggle="modal" data-target="#disclimerpop">Disclaimer</span>
                                        <div id="disclimerpop" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        <div className="contactInfopop disclaimerPop">
                                                            <button type="button" className="close topCrossModal" data-dismiss="modal">
                                                                <i className="iCross" />
                                                            </button>
                                                            <p>Disclaimer : {disclaimerText("COMMUNITY")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }                       
                    </div>
                </>
                
            </>
        )
    }

    getUserConfig = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_userServiceURL}/circle/get/user/config/${this.props.entityId}/${this.state.userData.userId}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res && res.status === '200 OK') {
                this.setState({
                    userConfigData: res.body
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    getShareFollowBlock = () => {
        return (
            <div className="shareFollowInDonationWrap">
                {this.state.loginSuccess && this.props.entitySlug &&
                    <Share shareUrl={"/community/"}
                        hideShareOnPortalBtn={true}
                        sharingSetting={this.props.entityPrivacySharingData?.sharingSetting?.sharingSetting}
                        shareId={this.props.entitySlug} />
                }
                {this.state.userConfigData && this.props.entityId &&
                    <EntityFollow entityType={'CIRCLE'} entityId={this.props.entityId}
                        followed={this.state.userConfigData?.followed}
                        privacySetting={this.props.entityPrivacySharingData?.privacySetting?.privacySetting}
                        userId={this.state.userData?.userId} />
                }
            </div>
        )
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.userId && (this.state.userId !== nextProps.userId)) {
            this.setState({
                userId: nextProps.userId
            }, () => {
                this.getEntityConfig();
                this.getDonateNowConfig();
            })  
        }
    }

    componentDidMount() {
        this.getEntityConfig();
        this.getDonateNowConfig();
        if(this.props.entityType === "CIRCLE") {
            this.getUserConfig();
        }
    }

    render() {
        const { loginSuccess, userData, entityConfigData, donationItemListPop, donationDateItemListPop, donationDateSelectData, donateNowConfig, donationPopUp, openSendMessageModal, subscriptionPageRedirect } = this.state;   
        const { entityDetails, entitySlug, entityId } = this.props;   
        return (
            <>
                {subscriptionPageRedirect && <Redirect to={{pathname: '/wenaturalists-pro', state: {userId: this.state.userId}}}/>}
                {((donateNowConfig?.id && this.props.pageType !== "STATS_PAGE") || this.state.donationBar?.donateActive || (this.state.showAcceptDonationBtn && ((this.props.entityType === "PROJECT" && this.props.entityDetails.project?.type !== "JOB") || (this.props.entityType === "CIRCLE" && this.props.pageType === "DETAILS" && this.props.entityDetails?.active)) && !this.state.entityConfigData?.initialPopupShow && !donateNowConfig?.donateNowButtonVisible && !donateNowConfig?.yetToStart && !donateNowConfig?.running && ((this.props.superAdminId === this.state.userId) || (this.props.creatorUserId === this.state.userId)))) ?
                    (this.props.entityType === "CIRCLE" ?                         
                        <div className={"circleDonatTopWrap newBar" + (donateNowConfig?.id && donateNowConfig?.manageDataPermission ? ((this.state.donationBar?.donateActive ? " donateActive" : "") + (this.props.pageType === "STATS_PAGE" ? " withoutStats" : "")) : "")}>
                            <span className="dntBtnBlk">
                                {this.getButtonDetails(donateNowConfig)}
                                {/* {this.getShareFollowBlock()} */}
                            </span>
                        </div> : 
                        <div className="prdbannDonateWrap projectDonation">{this.getButtonDetails(donateNowConfig)}</div>
                    ) : 
                    <></>
                    // this.props.entityType === "CIRCLE" && 
                    // <div className="circleDonatTopWrap newBar">
                    //     <span className="dntBtnBlk justify-content-end">
                    //         {this.getShareFollowBlock()}
                    //     </span>
                    // </div>
                }

                {donationItemListPop && <DonationSubscriptionItemList donationDetails={entityConfigData} donateNowConfig={donateNowConfig} onCloseDonationListDetails={this.triggerCloseModalPopUp} onSuccessDonationEntitySubmit={this.onSuccessDonationEntitySubmit} key={uuid()} type={"ENTITY_DONATE"} />}

                {donationDateItemListPop && <DonationDateSelecItemlist key={uuid()} entityConfigData={entityConfigData} donateNowConfig={donateNowConfig} entityDetails={entityDetails}  donationDateSelectData={donationDateSelectData} onCloseDonationDateListDetails={this.triggerCloseModalPopUp} type={"ENTITY_DONATE"} /> }

                {donationPopUp && 
                    <DonationPopUp key={uuid()} donateNowConfig={donateNowConfig} indian={entityConfigData?.indian} entityId={this.props.entityId} entityType={this.props.entityType} onCloseDonationPopUp={this.triggerCloseModalPopUp} donationBarData={this.state.donationBar} onSuccessDonationTargetUpdate={this.onSuccessDonationTargetUpdate} />
                } 
                
                {openSendMessageModal &&
                    <SendMessageModal profileId={entityDetails?.superAdminId ? entityDetails?.superAdminId : donateNowConfig?.superAdminId} userName={entityDetails?.superAdminName ? entityDetails?.superAdminName : donateNowConfig?.superAdminName} triggerMessageModalClose={this.triggerMessageModalClose}/>
                }
            </>
        )
    }
}

export default withRouter(DonationComponent);