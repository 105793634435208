var initState = {
  applications: {},
  applicationsPage: {},
  participants: {},
  participantsPage: {},
};

export const SET_APPLICATIONS = "/project/project/SET_APPLICATIONS";
export const SET_APPLICATIONS_SUCCESS =
  "/project/project/SET_APPLICATIONS_SUCCESS";
export const SET_PARTICIPANTS = "/project/project/SET_PARTICIPANTS";
export const SET_PARTICIPANTS_SUCCESS =
  "/project/project/SET_PARTICIPANTS_SUCCESS";

const applicantReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case "/project/project/SET_APPLICATIONS":
      state = { ...state, initiated: { [action.type]: false } };
      return state;
    case "/project/project/SET_APPLICATIONS_SUCCESS":
      state = {
        ...state,
        applications: action.payload.content,
        initiated: { [action.type]: true },
        applicationsPage: {
          seeMore: action.payload.seeMore,
          currentPage: action.payload.currentPage,
          size: action.payload.size,
        },
      };
      return state;
    case "/project/project/SET_PARTICIPANTS":
      state = { ...state, initiated: { [action.type]: false } };
      return state;
    case "/project/project/SET_PARTICIPANTS_SUCCESS":
      state = {
        ...state,
        participants: action.payload.content,
        initiated: { [action.type]: true },
        participantsPage: {
          seeMore: action.payload.seeMore,
          currentPage: action.payload.currentPage,
          size: action.payload.size,
        },
      };
      return state;
    default:
      return state;
  }
};

export default applicantReducer;

export function setParticipants() {
  return { type: SET_PARTICIPANTS };
}

export function setParticipantsOnSuccess(state) {
  return { type: SET_PARTICIPANTS_SUCCESS, payload: state };
}

export function setApplications() {
  return { type: SET_APPLICATIONS };
}

export function setApplicationsOnSuccess(state) {
  return { type: SET_APPLICATIONS_SUCCESS, payload: state };
}
