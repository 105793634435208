export const NOTIFICATIONS_RECEIVED_FULFILLED = "NOTIFICATIONS_RECEIVED_FULFILLED";
export const MESSAGE_RECEIVED_FULFILLED = "MESSAGE_RECEIVED_FULFILLED";

let initialState = {
    notificationReceivedResult: {},
    messageReceivedResult: {}
};

const notificationsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case NOTIFICATIONS_RECEIVED_FULFILLED:
            state = {notificationReceivedResult: {value: action.payload}, isLoaded: true};
            return state;

         case MESSAGE_RECEIVED_FULFILLED:
         	state = {messageReceivedResult: {value: action.payload}, isLoaded: true};
         	return state;

        default:
            return state;
    }
};

export default notificationsReducer;

export function requestNotificationReceivedFulfilled(response) {
    return {type: NOTIFICATIONS_RECEIVED_FULFILLED, payload: response};
}

export function messageReceivedFulfilled(response) {
    return {type: MESSAGE_RECEIVED_FULFILLED, payload: response};
}
