import React, { Component } from 'react';
import { authService } from "../../../auth";
import { setPersonalProfileEducationsOnSuccess } from "../../../reducer/profile/ProfileReducer";
import { connect } from "react-redux";
import FooterDiv from "../../../FooterDiv";
import axios from "axios";
import * as _ from "lodash";
import { Link, NavLink, withRouter } from "react-router-dom";
import { getCDNUrl, isWebView } from "../../../commons/CommonFunctions";

import httpService from '../../../AxiosInterceptors';
import Select, { components } from "react-select";

httpService.setupInterceptors();
const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>{children}</components.SingleValue>
);
class CreateBusiness extends Component {

    constructor(props) {
        super(props);
        let token = null;
        let validation = {
            companyName: {},
            organizationType: {},
            email: {},
            password: {}
        }
        this.state = {
            validation,
            userData: authService.getUserDetailsFromLocalStorage(),
            organizationList: [],
            companyName: '',
            email: '',
            organizationType: '',
            errors: {},
            operatorInfoSameAsUser: true,
            agreeToTerms: true,
            isOrganizationBtnDisabled: false,
            data: {}
        }
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    componentDidMount() {
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/api/authenticate?username=' + process.env.REACT_APP_authUser + '&password=' + process.env.REACT_APP_authSecret,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then((response) => {
                this.token = response.headers.authorization;
                this.getOrganizationList();
            })
        if(this.props.pageName === "NETWORK") {
            window.$("#createOrganizationPopUp").modal("show");
        }
    }

    handleOperatorInfoChange = (e) => {
        this.setState({ operatorInfoSameAsUser: e.target.checked });
    };

    // onSelectHandler = (event) => {
    //     event.preventDefault();
    //     this.state.validation.organizationType = {};
    //     this.setState({'organizationType': event.target.value});
    // }

    handleOraginiztion = (event) => {
        if (event && event.value !== null) {
            let organizationType = event.value;
            this.state.validation.organizationType = {};
            this.setState({
                organizationType: organizationType
            })
        }
    }

    getOrganizationTypeList = () => {
        return (
            <div className="form-group">
                {/*<select*/}
                {/*    name="otganizationType"*/}
                {/*    className="form-control"*/}
                {/*    onChange={this.onSelectHandler}*/}
                {/*    style={this.state.validation.organizationType.style}*/}
                {/*    required={true}>*/}
                {/*    {this.getOrganizationTypeOptions()}*/}
                {/*</select>*/}
                <Select
                    options={this.getOrganizationTypeOptions()}
                    placeholder={"Select Organization Type"}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, SingleValue }}
                    closeMenuOnSelect={true}
                    onChange={this.handleOraginiztion}
                    styles={
                        this.state.validation &&
                            this.state.validation.organizationType &&
                            this.state.validation.organizationType.style
                            ? {
                                control:
                                    styles => ({
                                        ...styles,
                                        borderColor: '#b34c4c',
                                        ":hover": {
                                            ...styles[":hover"],
                                            borderColor: '#b34c4c',
                                        }
                                    }),
                                option: (
                                    styles,
                                    { data, isDisabled, isFocused, isSelected }
                                ) => {
                                    const color = "#cfe7c7";
                                    return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? null
                                            : isSelected
                                                ? "#cfe7c7"
                                                : isFocused
                                                    ? "#cfe7c7"
                                                    : null,
                                        color: isDisabled
                                            ? "#ccc"
                                            : isSelected
                                                ? color > 2
                                                    ? "#00374d"
                                                    : "#00374d"
                                                : data.color,
                                        cursor: isDisabled ? "not-allowed" : "default",

                                        ":active": {
                                            ...styles[":active"],
                                            backgroundColor:
                                                !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                        },
                                    };
                                },
                            }
                            : {
                                option: (
                                    styles,
                                    { data, isDisabled, isFocused, isSelected }
                                ) => {
                                    const color = "#cfe7c7";
                                    return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? null
                                            : isSelected
                                                ? "#cfe7c7"
                                                : isFocused
                                                    ? "#cfe7c7"
                                                    : null,
                                        color: isDisabled
                                            ? "#ccc"
                                            : isSelected
                                                ? color > 2
                                                    ? "#00374d"
                                                    : "#00374d"
                                                : data.color,
                                        cursor: isDisabled ? "not-allowed" : "default",

                                        ":active": {
                                            ...styles[":active"],
                                            backgroundColor:
                                                !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                        },
                                    };
                                },
                            }
                    }
                />
                <span className="login-feedback-error mt-1"
                    style={{ display: "block" }}>{this.state.errors.organizationType}</span>
                <span
                    className="login-feedback-error mt-1">{this.state.validation.organizationType && this.state.validation.organizationType.message}</span>

            </div>
        );
    };

    // getOrganizationTypeOptions = () => {
    //     let types =
    //         this.state.organizationList.length > 0
    //             ? this.state.organizationList.map((type) => <option key={type}>{type}</option>)
    //             : [];
    //
    //     return types;
    // };

    getOrganizationTypeOptions = () => {
        let types = this.state.organizationList && this.state.organizationList.sort((a, b) => a.localeCompare(b)).map((organizationList) => {
            return {
                value: organizationList,
                label: organizationList,
                name: "organizationList",
            };
        })
        return types
    }

    getOrganizationList() {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/master/config/get',
            headers: { 'Content-Type': 'application/json', 'Authorization': this.token }

        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.setState({ 'organizationList': this.state.organizationList.concat(res.body.fields.companyTypes) }, () => {
                    this.setState({ 'organizationType': '' });

                });

            }
        }).catch((err) => {
            this.setState({
                isLoaded: true,
                error: { message: err.response.data.message, err: err.response }
            });
        });
    }

    validate = (fieldName, value) => {
        let isValid = true;
        switch (fieldName) {
            case "companyName":
                const cn = /^[a-zA-Z\s\d\D\w\W]+$/;
                if (value === '' || cn.test(value)) {
                    this.setState({ companyName: value });
                }
                this.state.validation.companyName = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    value.trim().replace(" ", "").length > 100 ||
                    value.trim().replace(" ", "").length < 3
                ) {
                    this.state.validation.companyName = {
                        error: true,
                        message: 'Organization name must have minimum 3 characters',
                        style: { borderColor: "#b34c4c", padding: "10px" }
                    }

                }
                break;

            case "email":
                const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.setState({ email: value });
                this.state.validation.email = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    !email.test(value)
                ) {
                    this.state.validation.email = {
                        error: true,
                        message: 'Please enter a valid Organization email address',
                        style: { borderColor: "#b34c4c" }
                    }
                }
                break;
            case "password":
                this.setState({ password: value });
                this.state.validation.password = {};
                this.analyzePassword(value);
                break;

            default:
                isValid = true;
                break;
        }
        return isValid;
    };

    validateForm() {
        this.state.validation.companyName = {};
        this.state.validation.email = {};
        this.state.validation.organizationType = {};
        this.state.validation.password = {};
        let validationRequired = false;
        let errors = {};
        let formIsValid = true;
        if (_.isUndefined(this.state.companyName) ||
            _.isEmpty((this.state.companyName || "").toString()) ||
            _.isNull(this.state.companyName)) {
            errors["companyName"] = "Please enter the Organization name";
            this.state.validation.companyName = {
                error: true,
                style: { borderColor: "#b34c4c" }
            }
            formIsValid = false;
            validationRequired = true;
        } else {
            const cn = /^[a-zA-Z\s\d\D\w\W]+$/;
            if (this.state.companyName === '' || cn.test(this.state.companyName)) {
                this.setState({ companyName: this.state.companyName });
            }
            if (this.state.companyName.trim().replace(" ", "").length > 100 ||
                this.state.companyName.trim().replace(" ", "").length < 3
            ) {
                this.state.validation.companyName = {
                    error: true,
                    message: 'Organization name must have minimum 3 characters',
                    style: { borderColor: "#b34c4c", padding: "10px" }
                }
                formIsValid = false;

            }
        }
        if (this.state.email === '') {
            if (_.isUndefined(this.state.email) ||
                _.isEmpty((this.state.email || "").toString()) ||
                _.isNull(this.state.email)) {
                errors["email"] = "Please enter the Organization email address";
                this.state.validation.email = {
                    error: true,
                    style: { borderColor: "#b34c4c" }
                }
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.setState({ email: this.state.email });
            this.state.validation.email = {};
            if (!email.test(this.state.email)
            ) {
                this.state.validation.email = {
                    error: true,
                    message: 'Please enter a valid Organization email address',
                    style: { borderColor: "#b34c4c" }
                }
                formIsValid = false;
            }
        }
        if (_.isUndefined(this.state.organizationType) ||
            _.isEmpty((this.state.organizationType || "").toString()) ||
            _.isNull(this.state.organizationType)) {
            errors["organizationType"] = "Please select the type of Organization";
            this.state.validation.organizationType = {
                error: true,
                style: { borderColor: "#b34c4c" }
            }
            validationRequired = true;
        }
        if (this.state.organizationType === '' || this.state.organizationType === 'Select Organization Type *') {
            validationRequired = true;
            errors["organizationType"] = "Please select the type of Organization";
            this.state.validation.organizationType = {
                error: true,
                style: { borderColor: "#b34c4c" }
            }
            formIsValid = false;
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    sendToProfileInfoScreen = (event) => {
        event.preventDefault();
        window.$('#emailVerifySendpopBusiness').modal('hide');
        this.props.history.push({
            pathname: '/business-profile-info',
            state: {
                businessProfileData: this.state.data
            }
        })
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validateForm()) {
            let postBody = {
                "userId": this.state.userData.userId,
                "companyName": this.state.companyName.trim(),
                "email": this.state.email.toLowerCase(),
                "organizationType": this.state.organizationType,
                "operatorInfoSameAsUser": this.state.operatorInfoSameAsUser,
                "agreeToTerms": this.state.agreeToTerms
            };
            this.setState({
                isOrganizationBtnDisabled: true
            })
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/user/signup/business',
                headers: { 'Content-Type': 'application/json' },
                data: postBody,
                withCredentials: true
            }).then((response) => {
                let res = response.data;
                if (res.message === 'Success!') {
                    window.$("#createOrganizationPopUp").modal("hide");
                    let businessPageResponse = res.body;
                    let data = {};
                    data.userId = businessPageResponse.userId;
                    this.follow(businessPageResponse.companyId);

                    window.$('#emailVerifySendpopBusiness').modal('show');
                    this.setState({ data: data, isOrganizationBtnDisabled: false });
                    /*this.props.history.push({
                        pathname: '/business-profile-info',
                        state: {
                            businessProfileData: data
                        }
                    })*/
                }
            }).catch((err) => {
                console.log(err);
                if (err && err.response && err.response.data) {
                    setTimeout(() => {
                        this.setState({
                            isLoaded: false,
                            error: {},
                            isOrganizationBtnDisabled: false
                        });
                    }, 5000);
                    this.setState({
                        isLoaded: true,
                        error: { message: err.response.data.message, err: err.response }
                    });
                }
            });
        }
        {
            setTimeout(() => {
                this.setState({
                    error: {}
                });
            }, 5000);

        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 5000);

        }

    };

    follow = (userId) => {
        let url = process.env.REACT_APP_userServiceURL + '/graph/users/' + this.state.userData.userId + '/follows/' + userId;
        axios({
            method: 'post',
            url: url,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {

            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getCreateOrganizationBody = () => {
        return (
            <>
                <h1 className="signup-header-text">Create an organization Page</h1>
                <div className="signup-form-container">
                    <form onSubmit={this.handleSubmit} id="create-business-form" name="create-business-form">
                        <div className="form-group">
                            <input type="text"
                                className="custom-input form-control"
                                style={this.state.validation.companyName.error ? this.state.validation.companyName.style : null}
                                value={this.state.companyName}
                                data-tip={
                                    this.state.validation.companyName.error &&
                                    this.state.validation.companyName.message
                                }
                                onBlur={(e) => this.validate("companyName", e.target.value)}
                                onChange={(e) => this.validate("companyName", e.target.value)}
                                autoComplete="given-name"
                                maxLength="100"
                                placeholder="Name of the organization *" />
                            <span className="login-feedback-error mt-1"
                                style={{ display: "block" }}>{this.state.errors.companyName}</span>
                            <span
                                className="login-feedback-error mt-1">{this.state.validation.companyName && this.state.validation.companyName.message}</span>
                        </div>
                        <div className="form-group">
                            <input type="text" className="custom-input form-control emailSignup"
                                id="email"
                                style={this.state.validation.email.error ? this.state.validation.email.style : null}
                                value={this.state.email}
                                data-tip={
                                    this.state.validation.email.error &&
                                    this.state.validation.email.message
                                }
                                onBlur={(e) => this.validate("email", e.target.value)}
                                onChange={(e) => this.validate("email", e.target.value)}
                                aria-describedby="inputGroupPrepend" placeholder="Organization Email *" />
                            <span className="login-feedback-error mt-1"
                                style={{ display: "block" }}>{this.state.errors.email}</span>
                            <span
                                className="login-feedback-error mt-1">{this.state.validation.email && this.state.validation.email.message}</span>
                        </div>
                        {this.getOrganizationTypeList()}
                        <p className="login-paragraph-text">I accept the WeNaturalists <strong><NavLink
                            to="/TermsConditions" className="privacy-link" target="_blank"> Terms &
                            Conditions</NavLink>, <NavLink to="/UserAgreement" className="privacy-link"
                                target="_blank"> User
                                Agreement</NavLink>, <NavLink to="/PrivacyPolicy" className="privacy-link"
                                    target="_blank">Privacy Policy</NavLink>,
                            and <NavLink to="/CookiePolicy" className="privacy-link" target="_blank">Cookie
                                Policy.</NavLink></strong></p>
                        <button type="submit" className="login-btn btn btn-primary" disabled={this.state.isOrganizationBtnDisabled}>Agree & Join
                        </button>
                        {this.state.error &&
                            <span className="login-feedback-error mt-1"
                                style={{ display: "block" }}> {this.state.error.message} </span>}
                    </form>
                </div>
            </>
        )
    }

    render() {
        return (
            <>
                {this.props.pageName !== "NETWORK" ?
                    <section className="signup-wrapper createbusiness">
                        <div className="signup-container pt-3">
                            <div className="signup-box text-center joincompany">
                                <div className="signup-boxinn">
                                    {!isWebView() ? <Link to="/"><img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" className="logotop" alt="" /></Link>
                                        :
                                        <Link to="/login"><img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" className="logotop" alt="" /></Link>}
                                    {this.getCreateOrganizationBody()}
                                </div>
                            </div>

                            <FooterDiv />
                        </div>
                    </section> :

                    <div id="createOrganizationPopUp" className="modal fade dgpopup createOrganizationPopUp" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='createOrganizationBox'>
                                        <button type="button" className="close topCrossModal" aria-label="Close" onClick={() => this.props.closeOrgModal()}>
                                            <i className="iCross" />
                                        </button>
                                        {this.getCreateOrganizationBody()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div id="emailVerifySendpopBusiness" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="emailVerifySendpop">
                                    <div className='icoImg'><i className='iSend-Message' /></div>
                                    <h3>Verification Email Sent</h3>
                                    <p>Hi {this.state.companyName}! We sent a verification link on your <br />email id : {this.state.email}</p>

                                    <div className='btnwrap'><a href='javascript:void(0)' onClick={this.sendToProfileInfoScreen} className='btnProceed' >Proceed</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        personalEducations: state.ProfileReducer.personalEducations
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPersonalEducationDataOnSuccess: (data) =>
            dispatch(setPersonalProfileEducationsOnSuccess(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateBusiness));
