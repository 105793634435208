import React, {Component} from 'react';
import {authService} from "../auth";
import "./styles/EndorsementPopUp.min.css";

class DeactivationErrorPopUpNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),

        }

    }

    componentWillUnmount() {
        window.$('#deactivationProcessErrorNotification').modal('hide');
    }

    close = (event)=>{
        event.preventDefault();
        window.$('#deactivationProcessErrorNotification').modal('hide');
    }

    render() {
        return <>
            <div className="modal fade dgpopup confirmpop" id="deactivationProcessErrorNotification" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="confirm-popup-modal-body">
                            <p>{this.props.message} <span>{this.props.entityType}</span>.</p>
                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.close}>OK</button>
                        </div>
                    </div>
                </div>
            </div>

        </>


    }
}



export default DeactivationErrorPopUpNotification;


