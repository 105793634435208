import React, { Component, memo } from "react";
import { authService } from "../auth";
import "./donation-subscription.min.css";
import { userSubscriptionError } from "./DonationCommonFunction";

class DonationTopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            additionalToppDataList: [],
            selectedTopUpId: ""
        }
    }

    onChangeTopUpDay = (id) => {
        this.setState({
            selectedTopUpId: id
        })
    }

    onSubmitDonationTopUp = () => {
        if(this.state.selectedTopUpId) {
            let additionalToppDataList = this.state.additionalToppDataList;
            let selectedIndex = additionalToppDataList && additionalToppDataList.findIndex((data) => data.id === this.state.selectedTopUpId);
            let dateTopUp = {};
            if(selectedIndex > -1) {
                dateTopUp = {"timeFrame": additionalToppDataList[selectedIndex].days, "amount": additionalToppDataList[selectedIndex].amount};
            }
            this.props.onSuccessDonationTopUp(dateTopUp, this.props.donationTopUpData?.entityId);
        } else {
            userSubscriptionError("DATE_TOP_UP");
        }
    }

    componentDidMount() {
        let additionalToppData = [];
        this.props.additionalDaysPlanList && this.props.additionalDaysPlanList.map((dayPlan, index) => {
            additionalToppData.push({"id": "dayPlan"+index, "days": dayPlan.days, "amount": dayPlan.amount});
        })
        this.setState({
            additionalToppDataList: additionalToppData
        })
    }

    render() {
        const { additionalToppDataList, selectedTopUpId } = this.state;
        return (
            <div id="donationTopUpPop" className="modal fade dgpopup donationTopUpPop" data-backdrop="false" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="pGatewayWrap stTopUpPop">
                                <button type="button" className="close" aria-label="Close" onClick={() => this.props.onCloseDonationTopUp()}>
                                    <i class="iCross" />
                                </button>
                                <div className="pGatewayWrapInn">
                                    <div className="pGateDesc">
                                        <div className='title'><h2><span>TOPUP DAYS FOR FUND RAISE </span></h2></div>
                                        <div className='orderTable table-responsive-sm'>
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th><p>No. of days</p></th>
                                                                        <th><p>Amount</p></th>
                                                                    </tr>
                                                                    {additionalToppDataList && additionalToppDataList.map((data) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    <label className="radio-container" onClick={(e) => this.onChangeTopUpDay(data.id)}>
                                                                                        <input type="radio" name="radio" className={selectedTopUpId === data.id ? "checked" : ""} />
                                                                                        <span className="radio-checkmark"></span>
                                                                                        <p>{data.days} days</p>
                                                                                    </label>
                                                                                </td>
                                                                                <td>
                                                                                    <p>{this.props.isIndian ? <>&#8377;</> : <>&#36;</>} {data.amount}</p>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='sProjectListPopBott'>
                                            <button type="button" class="btn btn-primary" onClick={this.onSubmitDonationTopUp}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default memo(DonationTopUp);