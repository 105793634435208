import React, { PureComponent } from 'react';
import { authService } from "../auth";
import { NavLink, Redirect } from "react-router-dom";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import axios from 'axios';
import { get720pTranscodedVideo, getCDNUrl, getOptimizedImage, getOptimizedArticleImage, getEnviormentRegex, videoThumbnailFunction, getPercentageData } from "../commons/CommonFunctions";
import Images from '../commons/Images'
import '../commons/styles/fb-image-grid-style.min.css'
import { v4 as uuidv4 } from 'uuid';
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import { ReactTinyLink } from "react-tiny-link";
import TimeAgo from 'javascript-time-ago'
import ExploreBlogSharePreview from "./ExploreBlogSharePreview";
import ProjectSharePreview from "./ProjectSharePreview";
import ForumSharePreview from "./ForumSharePreview";
import PollSharePreview from "./PollSharePreview";
import StorySharePreview from "./StorySharePreview";
import ShareContentRemovedPreview from "./ShareContentRemovedPreview";
// English.
import * as _ from "lodash";
import en from 'javascript-time-ago/locale/en';
import ReactPlayer from "react-player";
import CommonAttachmentSection from '../commons/CommonAttachmentSection';
import { defaultProjectImage } from '../project/component/Studio/StudioContentCommonFunction';
import PresentationSharePreview from './PresentationSharePreview';
import CommonLinkPreview from '../commons/CommonLinkPreview';
import { Waypoint } from 'react-waypoint';
import { pageClickHookPostMethod } from '../services/PostApi';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

let audioDuration = 0;
class PostSharePreview extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            og_data: {},
            paidContentPostLock: false,
            shouldPlay: true,
            triggerHookPost: false
        }
    }

    handleEnterViewport = () => {
        this.setState({
            shouldPlay: true,
        })
    };
    handleExitViewport = () => {
        this.setState({
            shouldPlay: false
        })
    };

    redirect(id) {
        window.location = '/post/' + id;
    };

    getLinkPreviewUrl = (text) => {
        let data = {
            url : text
        }
        axios({
            method: 'POST',
            url : `${process.env.REACT_APP_userServiceURL}/recommend/ogscrapper`,
            data: data,
            withCredentials: true
        }).then((response)=>{
            if(response?.data?.status === 200){
                this.setState({
                    og_data: response?.data?.data
                })
            }
        }).catch((error)=>{
            console.log("Error",error);
        })
    };

    componentDidMount() {
        let photoListMap = [];
        let photoListOriginalMap = [];
        this.props.item && this.props.item.attachmentIds && this.props.item.attachmentIds.map((attachment, index) => {
            photoListOriginalMap.push(getCDNUrl(attachment.attachmentUrl));
            if (window.innerWidth > 1000 && attachment.resizedImages && attachment.resizedImages['1000x1000'] != null) {
                photoListMap.push(getCDNUrl(attachment.resizedImages['1000x1000']));
            } else if (window.innerWidth > 600 && window.innerWidth <= 1000 && attachment.resizedImages && attachment.resizedImages['600x600'] != null) {
                photoListMap.push(getCDNUrl(attachment.resizedImages['600x600']));
            } else if (window.innerWidth > 320 && window.innerWidth <= 600 && attachment.resizedImages && attachment.resizedImages['400x400'] != null) {
                photoListMap.push(getCDNUrl(attachment.resizedImages['400x400']));
            } else {
                photoListMap.push(getCDNUrl(attachment.attachmentUrl));
            }
        })
        if(this.props.item && this.props.item.attachmentIds.length === 0 && this.props.item.paidPost) {
            photoListMap.push(getCDNUrl(defaultProjectImage.attachmentUrl));
            photoListOriginalMap.push(getCDNUrl(defaultProjectImage.attachmentUrl));
            this.setState({
                paidContentPostLock: true
            })
        }
        this.getLinkPreviewUrl(this.props.item?.postLinkTypeUrl)
        this.setState({ 'photoListMap': photoListMap, 'photoListOriginalMap': photoListOriginalMap });
        window.$('.playbutt').bind('contextmenu', function (e) {
            return false;
        });
    }

    redirectToProfile = () => {
        this.setState({ redirectToProfile: true });
    }

    redirectToArticleDetail(slug) {
        var url = '/explore-detail/article/' + slug;
        if (url !== undefined && url !== null) {
            window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
        }
    }

    isValidUrl = (str) => {
        let regexp = /^(https?|ftp):\/\/(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        return regexp.test(str)
    }

    redirectToCircle = (customUrl) => {
        window.location.href = "/community/" + customUrl;
    }

    redirectToPost = (id) => {
        window.location.href = "/post/" + id;
    }

    handlePageClickHookPost = async () => {
        let postData = {
          entityId: this.props.entityItem?.description ? this.props.entityItem?.id : this.props.item?.id,
          userId: this.state.loginSuccess ? this.state.userData?.userId : null,
          entityType: "POST"
        }
        await pageClickHookPostMethod(postData);
        if(this.props.entityItem?.id && !this.props.entityItem?.description) {
            postData.entityId = this.props.entityItem?.id;
            await pageClickHookPostMethod(postData);
        }
    }

    onListenAudio = (data) => {
        if(!this.state.triggerHookPost && getPercentageData(audioDuration, 5) < data.target.currentTime) {
            this.setState({triggerHookPost: true})
            this.handlePageClickHookPost();
        }
    }

    render() {
        var utcSeconds = this.props.item.createTime;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);
        const linkTarget = {
            pathname: "/profile/" + this.props.item.customUrl,
            key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
            state: {
                applied: true
            }
        };
        const postTarget = {
            pathname: "/post/" + this.props.item.id,
            key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
            state: {
                applied: true
            }
        };
        return (
            <>
                {this.state.redirectToProfile && <Redirect push to={"/profile/" + this.props.item.customUrl} />}
                <div className="sharepostpreview">
                    <div className="hpostblock">
                        <div className="hpostblockinn">
                            {this.props.entityItem && this.props.entityItem.userId !== this.props.item.userId && !this.props.isHashtagPage &&
                                <div className="hposthead">
                                    {this.props.entityItem && this.props.entityItem.userId !== this.props.item.userId &&
                                        <>{!this.props.item.userEntityType ? <figure className="userimg" onClick={(e) => {
                                            e.preventDefault();
                                            this.redirectToProfile()
                                        }} style={{ cursor: 'pointer' }}>
                                            <img src={this.props.item.profileImage ?
                                                getCDNUrl(getOptimizedImage(this.props.item, 'profileImage')) : (this.props.item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                                alt="" />
                                        </figure> : <figure className="userimg" onClick={(e) => {
                                            e.preventDefault();
                                            this.redirectToCircle(this.props.item && this.props.item.params && this.props.item.params['circleSlug'])
                                        }}>
                                            <img
                                                src={this.props.item && this.props.item.params && this.props.item.params['circleImage'] != null
                                                    ? getCDNUrl(this.props.item.params && this.props.item.params['circleImage'])
                                                    : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                                onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/circleDefault.png" }}
                                                alt="" />
                                        </figure>}
                                        </>
                                    }
                                    {!this.props.item.userEntityType ? <h3><NavLink style={{ textDecoration: "none" }}
                                        to={linkTarget}>{this.props.item.username}</NavLink> {/*<span><a href="javascript:void(0)">Follow</a></span>*/}
                                    </h3> : <h3 onClick={(e) => {
                                        e.preventDefault();
                                        this.redirectToCircle(this.props.item.params && this.props.item.params['circleSlug'])
                                    }}>{this.props.item.params['circleTitle']}</h3>}
                                    {this.props.item && this.props.item.persona &&
                                        <div className="hpostPersona">{this.props.item.persona}</div>}
                                    <div className="hpostdtls">
                                        {this.props.item && this.props.item.addressDetail && this.props.item.addressDetail.country && <span className="address"><i
                                            className="iLocation" /> {this.props.item.addressDetail.country}</span>}
                                        {
                                            this.props.item.postLocation &&
                                            <span className="address"><i
                                                className="iLocation" />{this.props.item.postLocation}</span>
                                        }
                                        <span><NavLink
                                            to={postTarget}>{timeAgo.format(d, 'mini-now')}</NavLink></span>
                                    </div>
                                </div>}

                            {this.props.item.postType === 'ARTICLE' ?
                                    <>
                                        <div className="postarticleblock">
                                            <div className="postarticleimg">
                                                {
                                                    this.props.item.attachmentIds && this.props.item.attachmentIds[0]
                                                        && this.props.item.attachmentIds[0].attachmentUrl ?
                                                        <img
                                                            src={getCDNUrl(getOptimizedArticleImage(this.props.item.attachmentIds[0]))}
                                                            alt="" /> :
                                                        <img
                                                            src="https://cdn.dscovr.com/images/banner-explore-blog-small.webp"
                                                            onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/banner-explore-blog-small-new.png" }}
                                                            alt="" />
                                                }
                                            </div>
                                            <div className="postarticledesc">
                                                <div className="paauthor">{this.props.item.username}</div>
                                                <h2 className='articlePostTitle'>{this.props.item.title}</h2>
                                                <div className="padesctxt"
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.props.item.description
                                                            ? this.props.item.description
                                                                .replace(/&nbsp;/g, ' ')
                                                                .replace(/(\r\n|\r|\n)/g, '<br>')
                                                            : ''
                                                    }} />
                                                <div className="text-center">
                                                    <a href="javascript:void(0)"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.redirectToArticleDetail(this.props.item?.slug)
                                                        }}
                                                        className="more btn">read more</a>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.item.postLocation &&
                                            <span className="address"><i
                                                className="iLocation" />{this.props.item.postLocation}</span>
                                        }
                                    </> : (this.props.item.attachmentIds && this.props.item.attachmentIds.length > 0) ?
                                        (this.props.item.attachmentIds[0].attachmentType === 'VIDEO' ?
                                            <div>
                                                <div className="hpostconttop">

                                                    {!this.props.isHashtagPage && 
                                                    <div>
                                                        {((this.props.item.description && this.props.item.description !== '') || this.props.item.hashTags) &&
                                                            <ReadMoreReadLess description={this.props.item.description} hashTags={this.props.item.hashTags} />
                                                        }
                                                    </div>}
                                                    <figure className="hpostimggrid">
                                                        <div className="himggrid vidbox">
                                                            <a data-fancybox={this.props.entityItem.id} href={getCDNUrl(get720pTranscodedVideo(this.props.item.attachmentIds[0]))} onClick={() => this.handlePageClickHookPost()}>
                                                                {(this.props.item?.thumbnail) || (this.props.item.attachmentIds[0].thumbnails && this.props.item.attachmentIds[0].thumbnails[0]) ?
                                                                    <img
                                                                        src={getCDNUrl(videoThumbnailFunction(this.props.item))}
                                                                        alt="" />
                                                                    : <img
                                                                        src="https://cdn.dscovr.com/images/video-blank2.webp"
                                                                        onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/video-blank2.png" }}
                                                                        alt="" />}
                                                                <span className="playbutt"><i className="iPlay" /></span>
                                                            </a>
                                                        </div>
                                                    </figure>
                                                    {this.props.item.postLocation &&
                                                        <span className="address"><i
                                                            className="iLocation" />{this.props.item.postLocation}</span>
                                                    }
                                                </div>
                                            </div>
                                            : (this.props.item.attachmentIds[0].attachmentType === 'AUDIO' ?
                                                <div>

                                                    <div className="hpostconttop">
                                                        {!this.props.isHashtagPage &&
                                                        <div>
                                                            {((this.props.item.description && this.props.item.description !== '') || this.props.item.hashTags) &&
                                                                <ReadMoreReadLess description={this.props.item.description} hashTags={this.props.item.hashTags} />
                                                            }
                                                        </div>}
                                                        <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                                            <AudioPlayer
                                                                showJumpControls={false}
                                                                customVolumeControls={[]}
                                                                customAdditionalControls={[]}
                                                                showDownloadProgress={false}
                                                                src={getCDNUrl(this.props.item.attachmentIds[0].attachmentUrl)}
                                                                onListen={e => this.onListenAudio(e)}
                                                            />
                                                        </div>
                                                        {this.props.item.postLocation &&
                                                            <span className="address"><i
                                                                className="iLocation" />{this.props.item.postLocation}</span>
                                                        }
                                                    </div>
                                                </div> :
                                                this.props.item.attachmentIds[0].attachmentType === "PPT" || this.props.item.attachmentIds[0].attachmentType === "PDF" ?
                                                    <div>
                                                        <div className="hpostconttop">
                                                            {!this.props.isHashtagPage &&
                                                                <div>
                                                                    {((this.props.item.description && this.props.item.description !== '') || this.props.item.hashTags) &&
                                                                        <ReadMoreReadLess description={this.props.item.description} hashTags={this.props.item.hashTags} />
                                                                    }
                                                                </div>
                                                            }
                                                            <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                                                <PresentationSharePreview type={this.props.item?.sharedEntityType} item={this.props.item} entityItem={this.props.entityItem} />
                                                            </div>
                                                            {this.props.item.postLocation &&
                                                                <span className="address"><i
                                                                    className="iLocation" />{this.props.item.postLocation}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                : <div>
                                                    <div className="hpostconttop">
                                                        {!this.props.isHashtagPage && 
                                                        <div>
                                                            {((this.props.item.description && this.props.item.description !== '') || this.props.item.hashTags) &&
                                                                <ReadMoreReadLess description={this.props.item.description} hashTags={this.props.item.hashTags} />
                                                            }
                                                        </div>}
                                                        {
                                                            this.state.photoListMap && this.state.photoListMap.length > 0 &&
                                                            <Images images={this.state.photoListMap} countFrom={5}
                                                                    photoListOriginalMap={this.state.photoListOriginalMap} paidContentPostLock={this.state.paidContentPostLock} entityId={this.props.item?.id} entityItem={this.props.entityItem} />
                                                        }
                                                        {this.props.item.postLocation &&
                                                            <span className="address"><i
                                                                className="iLocation" />{this.props.item.postLocation}</span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        ) :
                                            this.props.item && this.props.item.attachmentIds.length === 0 && this.props.item.paidPost ?
                                            <div>
                                                <div className="hpostconttop">
                                                    {!this.props.isHashtagPage && 
                                                    <div>
                                                        {((this.props.item.description && this.props.item.description !== '') || this.props.item.hashTags) &&
                                                            <ReadMoreReadLess description={this.props.item.description} hashTags={this.props.item.hashTags} />
                                                        }
                                                    </div>}
                                                    {
                                                        this.state.photoListMap && this.state.photoListMap.length > 0 &&
                                                        <Images images={this.state.photoListMap} countFrom={5}
                                                                photoListOriginalMap={this.state.photoListOriginalMap} paidContentPostLock={this.state.paidContentPostLock} />
                                                    }
                                                    {this.props.item.postLocation &&
                                                        <span className="address"><i
                                                            className="iLocation" />{this.props.item.postLocation}</span>
                                                    }
                                                </div>
                                            </div> : 
                                        (this.props.item.postType === 'LINK')
                                            ? this.props.item.postLinkTypeUrl
                                            && this.isValidUrl(this.props.item.postLinkTypeUrl)
                                            && <>
                                                {!this.props.isHashtagPage && this.props.item.description && this.props.item.description !== '' &&
                                                    <div className="hpostconttop">
                                                        {((this.props.item.description && this.props.item.description !== '') || this.props.item.hashTags) &&
                                                            <ReadMoreReadLess description={this.props.item.description} hashTags={this.props.item.hashTags} />}
                                                        {this.props.item.postLocation &&
                                                            <span className="address"><i
                                                                className="iLocation" />{this.props.item.postLocation}</span>
                                                        }
                                                    </div>
                                                }
                                                <div className="linkpostpreview">
                                                    <div className="prevtitle">
                                                        Preview
                                                    </div>
                                                    <div className="postarticledesc">
                                                        {this.props.item.postLinkTypeUrl.indexOf('https://youtu.be') !== -1 || this.props.item.postLinkTypeUrl.indexOf('www.youtube.com/watch') !== -1 ? <>
                                                            <div className="vidPlayerOverWrap">
                                                                <Waypoint onEnter={() => this.handleEnterViewport()} onLeave={() => this.handleExitViewport()} scrollableAncestor='window'>
                                                                    <div>
                                                                        <ReactPlayer
                                                                            url={this.props.item.postLinkTypeUrl}
                                                                            width={"100%"}
                                                                            playing={this.state.shouldPlay}
                                                                            controls={true}
                                                                            muted={true}
                                                                        />
                                                                    </div>
                                                                </Waypoint>
                                                            </div>
                                                            <span className="vidOverlay" onClick={(e) => this.redirectToPost(this.props.item.id)}></span>
                                                            <div className="customLinkPost" onClick={()=>{
                                                                window.location.href = this.props.item.postLinkTypeUrl }}>
                                                                <div className="customLinkPostDesc">
                                                                    <h5>{this.state.og_data?.title}</h5>
                                                                    <p>{this.state.og_data?.description}</p>
                                                                    {/* <p>{this.props.item.postLinkTypeUrl}</p> */}
                                                                </div>
                                                            </div>
                                                            <a href={this.props.item.postLinkTypeUrl} className="btn playYoutube" target="_blank">
                                                                <i className="iYouTube_Logo" /> Play on Youtube</a>
                                                        </> :
                                                            (!_.isEmpty(this.state.og_data) &&  
                                                                <CommonLinkPreview linkdata={this.props.item.postLinkTypeUrl} og_data={this.state.og_data} />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </>

                                            : <div className="hpostconttop">
                                                <div>
                                                    {((this.props.item.description && this.props.item.description !== '') || this.props.item.hashTags) &&
                                                        <ReadMoreReadLess description={this.props.item.description} hashTags={this.props.item.hashTags} />}</div>

                                                {
                                                    (this.props.item.sharedEntityId && this.props.item.sharedEntityId !== '')
                                                        ? (this.props.item.sharedEntityParams && Object.keys(this.props.item.sharedEntityParams).length > 0
                                                            ? (this.props.item.sharedEntityParams['content-not-found'] ?
                                                                <ShareContentRemovedPreview />
                                                                : (!this.props.item.sharedEntityParams['max-depth-reached'] ?
                                                                    (this.props.item.sharedEntityType === 'EXPLOREBLOG'
                                                                        ? <ExploreBlogSharePreview
                                                                            type={this.props.item.sharedEntityType}
                                                                            item={this.props.item.sharedEntityParams} />
                                                                        : ((this.props.item.sharedEntityType === 'ASSIGNMENT')
                                                                            || (this.props.item.sharedEntityType === 'JOB')
                                                                            || (this.props.item.sharedEntityType === 'TRAINING')
                                                                            || (this.props.item.sharedEntityType === 'EVENT'))
                                                                            ?
                                                                            <ProjectSharePreview
                                                                                type={this.props.item.sharedEntityType}
                                                                                item={this.props.item.sharedEntityParams} />
                                                                            : (this.props.item.sharedEntityType === 'FORUM'
                                                                                ? <ForumSharePreview
                                                                                    type={this.props.item.sharedEntityType}
                                                                                    item={this.props.item.sharedEntityParams}
                                                                                    entityItem={this.props.item} />
                                                                                : (this.props.item.sharedEntityType === 'POLL'
                                                                                    ? <PollSharePreview
                                                                                        type={this.props.item.sharedEntityType}
                                                                                        item={this.props.item.sharedEntityParams}
                                                                                        entityItem={this.props.item} />
                                                                                    : (this.props.item.sharedEntityType === 'POST'
                                                                                        ? <PostSharePreview
                                                                                            type={this.props.item.sharedEntityType}
                                                                                            item={this.props.item.sharedEntityParams}
                                                                                            entityItem={this.props.item} />
                                                                                        : (this.props.item.sharedEntityType === 'STORY'
                                                                                            ? <StorySharePreview
                                                                                                type={this.props.item.sharedEntityType}
                                                                                                item={this.props.item.sharedEntityParams} />
                                                                                            : null))))) : null)
                                                            ) : null)
                                                        : null

                                                }
                                                {this.props.item.postLocation &&
                                                    <span className="address"><i
                                                        className="iLocation" />{this.props.item.postLocation}</span>
                                                }
                                            </div>
                            }

                            {this.props.item.secondaryAttachmentIds && this.props.item.secondaryAttachmentIds.length > 0 ? 
                                <div className="hpostcontbott">
                                    <CommonAttachmentSection attachments={this.props.item.secondaryAttachmentIds} itemId={this.props.item.id} />
                                </div> : 
                                <div className="fattach"/>
                            }

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PostSharePreview
