import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./reducer/rootReducer";
import {createLogger} from "redux-logger";
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import {routerMiddleware} from 'react-router-redux';
import {createBrowserHistory} from "history";

const browserHistory = createBrowserHistory();
const routeMiddleware = routerMiddleware(browserHistory);


const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__
        ? compose(
        applyMiddleware(sagaMiddleware, routeMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__(),
        )
        : applyMiddleware(createLogger({predicate: () => process.env.NODE_ENV !== 'production'}), sagaMiddleware, routeMiddleware),
);
sagaMiddleware.run(rootSaga);
export default store;
