import React from 'react'
import {authService} from "./auth";
import $ from "jquery";
import axios from "axios";
import {connect} from "react-redux";
import {requestPostCreationFulfilled} from "./reducer/post";
import Loader from "react-loader-spinner";
import ProjectPostList from "./post/ProjectPostList";
import ResearchPostList from "./post/ResearchPostList";
import CausePostList from "./post/CausePostList";
import BusinessPageList from "./post/BusinessPageList";
import CirclePostList from "./post/CirclePostList";
import httpService from "./AxiosInterceptors";
import {convertUnicode, getCDNUrl, isMobileDevice, matchPostLinkUrl, userAgentGetData} from "./commons/CommonFunctions";
import mappingUtils from "./project/component/commons/utils/mappingUtils";
import stripHtml from "string-strip-html";
import {Scrollbars} from 'react-custom-scrollbars';
import HorizontalScroll from 'react-scroll-horizontal';
import './commons/styles/react-mentions.css'
import MentionTextArea from "./commons/MentionTextArea";
import cloneDeep from "lodash/cloneDeep";
import Picker from 'emoji-picker-react';
import AutoSuggest from "./AutoSuggest";
import {CapitalizeFirstLetter} from "./utils/StringUtility";
import _ from 'lodash';
import CommonLinkPreview from './commons/CommonLinkPreview';


if (isMobileDevice()) {
    import('./commons/styles/responsive-useragent.css').then(() => {
        console.log("Imported mobile css");
    });
}

httpService.setupInterceptors();
const maxMbFileSize = 100 * 1024 * 1024;


class LinkPopup extends React.Component {

    constructor(props) {
        super(props);

        let userData = authService.getUserDetailsFromLocalStorage();
        let validation = {
            urlLink: {}
        }
        this.state = {
            userData: userData,
            postPrivacy: 'ANYONE',
            urlLink: '',
            hashTags: [],
            postText: '',
            isPostSubmitted: false,
            userList: [],
            searchUserByChars: '',
            selectedList: [],
            hiddenPrivacy: false,
            visibleToUserIds: [],
            userId: '',
            postId: '',
            validation,
            isModalLoading: false,
            errors: {},
            forcePost: false,
            entityName: (userData ? (userData.type === 'COMPANY' ?
                userData.companyName : CapitalizeFirstLetter(userData.firstName)) : ''),
            entityType: '',
            scondaryEntityName: 'Feeds',
            projectList: props.projectList,
            causeList: props.causeList,
            circleList: props.circleList,
            circleAdminList: props.circleAdminList,
            coverImage: userData && userData.profileImage ?
                getCDNUrl(this.getOptimizedImage(userData)) : (userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"),
            userName: (userData ? (userData.type === 'COMPANY' ?
                userData.companyName : CapitalizeFirstLetter(userData.firstName)) : ''),
            smileDisplay: false,
            og_data: {}
        }
    }

    handleSmileDisplay = () =>{
        this.setState({
            smileDisplay:this.state.smileDisplay ? false : true
        })
    }

    onToggleEmojiWindow = () => {
        const { smileDisplay } = this.state;
        this.setState({ smileDisplay: !smileDisplay });
    };

    onEmojiClick =(event, emojiObject)=>{
        let postText = cloneDeep(this.state.postText)
        postText = postText + ' '+emojiObject.emoji
        this.setState({
            'postText':postText
        })
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    getLinkPreviewUrl = (text) => {
        let data = {
            url : text
        }
        axios({
            method: 'POST',
            url : `${process.env.REACT_APP_userServiceURL}/recommend/ogscrapper`,
            data: data,
            withCredentials: true
        }).then((response)=>{
            if(response?.data?.status === 200){
                this.setState({
                    og_data: response?.data?.data
                })
            }
        }).catch((error)=>{
            console.log("Error",error);
        })
    };

    componentDidMount() {
        this.getCauseList(this.state.userData.userId);
        this.getProjectList(this.state.userData.userId);
        this.getBusinessPageList(this.state.userData.userId);
        this.getCircleList(this.state.userData.userId);
        this.getCircleAdminList(this.state.userData.userId);
        window.$("input#colon").on({
            // e.which >= 32 && e.which <= 47 && e.which >= 58 && e.which <= 64 && e.which >= 91 && e.which <= 95
            keydown: function (e) {
                if (e.key === '#')
                    return false;
            },
            change: function () {
                this.value = this.value.replace(/\s/g, "");
            }
        });
        window.$('.dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.dropdown-menu').addClass('show');
            e.stopPropagation();
        });

        document.querySelector(".wrapper").addEventListener("click", event => {
            // Get parent element and check if click happened outside parent only
            const parent = document.querySelector(".emoji-picker-react");
            const { smileDisplay } = this.state;
            if (smileDisplay && !parent.contains(event.target)) {
              this.onToggleEmojiWindow();
            }
        });
    }

    changePostPrivacy = (event) => {
        this.setState({'postPrivacy': event.target.value});
    }

    isValidUrl = (str) => {
        let regexp = /^(https?|ftp):\/\/(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        return regexp.test(str)
    }

    onDelete (i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags })
    }

    onAddition (tag) {
        const tags = [].concat(this.state.tags, tag)
        this.setState({ tags })
    }

    handleTagsChange = (hashTags) => {
        let hashTagsList = [];
        if (hashTags && hashTags.length > 0) {
            hashTags && hashTags.map(function(data){
                data = data.replace(/[`~!@$%^&*()|+\=?;:'",.#<>\{\}\[\]\\\/\s]/gi, ''); 
                if(data != "") {
                    hashTagsList.push(data); 
                }  
            });
            let allHashTagsList = hashTagsList;
            hashTagsList = [...new Set(allHashTagsList)];
        }
        this.setState({'hashTags': hashTagsList});
    }

    handleUrlUpdate = (event) => {
        this.setState({
            'validUrlLinkPreview': '',
            'urlLink': event.target.value
        });

        let value = event.target.value;

        if (this.isValidUrl(value)) {
            let data = matchPostLinkUrl(value);
            setTimeout((value) => {
                this.setState({
                    'validUrlLinkPreview': data
                },()=>{
                    this.getLinkPreviewUrl(this.state.validUrlLinkPreview)
                });
            }, 2000, value);
        }
    }

    check_words = (e) => {
        const BACKSPACE = 8;
        const DELETE = 46;
        const MAX_WORDS = 400;
        const valid_keys = [BACKSPACE, DELETE];
        const words = this.value && this.value.split(' ');

        if (words && words.length >= MAX_WORDS && valid_keys.indexOf(e.keyCode) === -1) {
            e.preventDefault();
            words.length = MAX_WORDS;
            this.value = words.join(' ');
        }
    }

    handlePostTextUpdate = (event) => {
        this.setState({'postText': event.target.value});
        this.check_words(event)
    }

    handleLinkPostSubmit = () => {
        if (this.validateForm()) {
            const formData = new FormData();
            this.setState({'isPostSubmitted': true});
            let errors = {};
            let attachmentList = [];
            let params = {
                creatorId: this.state.userData.userId,
                userId: this.state.userId ? this.state.userId : this.state.userData.userId,
                entityId: this.state.entityId ? this.state.entityId : null,
                description: this.state.postText.trim("\n"),
                postType: 'LINK',
                hashTags: this.state.hashTags ? this.state.hashTags.map((item) => {
                    return item.replace(/#/g, '')
                }) : [],
                postVisibility: this.state.postPrivacy,
                visibleToUserIds: this.state.visibleToUserIds,
                postLinkTypeUrl: this.state.validUrlLinkPreview,
                forcePost: this.state.forcePost
            }

            if (this.state.secondaryAttachments) {
                params.secondaryAttachmentIds = this.state.secondaryAttachments;
            }

            formData.append("data", JSON.stringify(params));

            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/post/createUpdated',
                data: formData,
                withCredentials: true
            }).then(response => {
                if (response && response.status === 201) {
                    this.setState({'forcePost': false,'postId': response.data.body});
                    this.props.requestPostCreationFulfilled({success: true});
                    this.hideModal();
                } else {
                    console.log(response);
                    this.setState({'isPostSubmitted': false});
                }
            }).catch((err) => {
                console.log(err);
                errors["connectionText"] = "No Connects Yet Post will be visible only to you Want to Proceed?";
                this.setState({
                    'isPostSubmitted': false,
                    'forcePost': true
                });
            });
            this.setState({
                errors: errors
            });
        }
        setTimeout(() => {
            this.setState({
                error: '',
                errors: {},
            });
        }, 5000);
    }

    validateForm = () => {
        let errors = {};
        var regex = /\s+/gi;
        let formIsValid = true;
        if (!this.state.urlLink || this.state.urlLink === ''
            || !this.isValidUrl(this.state.urlLink)) {
            errors["urlLink"] = "Please enter a valid url";
            formIsValid = false;
        }
        if (stripHtml(this.state.postText).trim().replace(regex, ' ').split(' ').length > 400
        ) {
            errors["postText"] = "Please enter upto 400 words";
            this.state.validation.postText = {
                error: true,
                message: 'Please enter upto 400 words',
                style: {borderColor: "#b34c4c", padding: "10px"}
            }
            formIsValid = false;

        }
        if (this.state.postPrivacy == "SELECTED" && this.state.selectedList.length == 0) {
            errors["privacyText"] = "Please Select at least one user";
            formIsValid = false;
        }
        this.setState({
            errors: errors
        });
        return formIsValid;

    }

    componentWillUnmount() {
        this.hideModal(true);
        window.$('#linkSelectUserModal').modal('hide');
        window.$('#AcceptLinks').modal('hide');
    }

    hideModal = (isModalOpen) => {
        this.setState({
            'postPrivacy': 'ANYONE',
            'urlLink': '',
            'validUrlLinkPreview': null,
            'hashTags': [],
            'postText': '',
            'isPostSubmitted': false,
            'isModalLoading': false,
            'secondaryAttachments': null,
            'hiddenPrivacy': false,
            'userId': this.state.userData && this.state.userData.userId,
            'entityType': 'Individual',
            'entityId': null,
            'entityName': this.state.userName,
            'scondaryEntityName': 'Feeds',
            'coverImage': this.state.userData && this.state.userData.profileImage ?
                getCDNUrl(this.getOptimizedImage(this.state.userData)) : (this.state.userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"),
            'projectList': this.props.projectList,
            'causeList': this.props.causeList,
            'circleList':this.props.circleList,
            'error':'',
            smileDisplay:false
        });
        this.clearUsers();
        this.props.openModalClose();
        if(!isModalOpen) {
            this.props.acceptPopUpModalDetail(this.state.postId);
        }
        
        //window.$('#postlink').modal('hide');
        window.$('#cancelPopup1').modal('hide');
        if (this.refs.TagsRef) {
            this.refs.TagsRef.clearInput();
        }
    }

    confirmModal = () => {
        window.$('#cancelPopup1').modal('show');
        window.$('#postlink').modal('hide');
    }

    ignoreModal = () => {
        window.$('#cancelPopup1').modal('hide');
        window.$('#postlink').modal('show');
    }

    getConnects(page, size) {
        return new Promise((resolve) => {
            axios({
                method: 'get',
                url: process.env.REACT_APP_userServiceURL + '/network/' + this.state.userData.userId + '/connects'
                    + "?page=" + page + "&size=" + size + userAgentGetData(),
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }).then(response => {
                if (response && response.data && response.data.message === 'Success!') {
                    resolve(response.data.body.content);
                }
            }).catch((err) => {
                console.log(err);

            });
        });
    };

    addUserToGroup(evt, data) {
        const selectedList = this.state.selectedList;
        selectedList.push(data);
        let array = [...this.state.userList];
        let index = array.indexOf(data)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({userList: array});
        }
        this.setState({selectedList: selectedList});
        evt.preventDefault();
    }

    removeUserToGroup(evt, data) {
        const userList = this.state.userList;
        userList.push(data);
        let array = [...this.state.selectedList];
        let index = array.indexOf(data)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({selectedList: array});
        }
        this.setState({userList: userList});
        evt.preventDefault();
    }

    handleSelectedOnly = () => {
        this.setState({'isModalLoading': true});
        this.getConnects(0, 1000)
            .then((userList) => {
                let selectedList = [];
                if (this.state.visibleToUserIds && this.state.visibleToUserIds.length > 0) {
                    selectedList = userList.filter(({id}) => this.state.visibleToUserIds.includes(id));
                    selectedList.forEach((data) => {
                        let index = userList.indexOf(data)
                        if (index !== -1) {
                            userList.splice(index, 1);
                        }
                    });
                }
                this.setState({userList: userList, selectedList: selectedList, 'isModalLoading': false}, () => {

                })
            })
            .catch((err) => console.log(err));
        window.$('#linkSelectUserModal').modal('show');
    }

    keyPressedBySearchGroupByChars(event) {
        this.setState({searchUserByChars: event.target.value});
    }

    clearUsers() {
        document.getElementById('searchGroupByChars').value = '';
        this.setState({selectedList: [], userList: [], searchUserByChars: '', visibleToUserIds: []});
    }

    handleUsersSelectSubmit = (event) => {
        event.preventDefault();
        let userIds = [];
        for (let data of this.state.selectedList) {
            userIds.push(data.id);
        }

        document.getElementById('searchGroupByChars').value = '';
        this.setState({'visibleToUserIds': userIds, searchUserByChars: ''});

        window.$('#linkSelectUserModal').modal('hide')
    };

    handleCheckedPrivacy = () => {
        if (this.state.visibleToUserIds && this.state.visibleToUserIds.length > 0) {
            this.setState({'visibleToUserIds': []});
        }
    }

    setAsUser = (userId, entityType, entityName, entityId, coverImage) => {
        this.setState({
            userId: userId,
            entityType: entityType,
            entityId: (entityType === 'Circle') ? entityId : null,
            entityName: entityName,
            scondaryEntityName: (entityType === 'Circle') ? 'Community Activity' : 'Feeds',
            hiddenPrivacy: (entityType === 'Circle' || entityType === 'Company') ? true : false,
            postPrivacy: 'ANYONE',
            coverImage: coverImage ? coverImage : (this.state.userData && this.state.userData.profileImage ?
                getCDNUrl(this.getOptimizedImage(this.state.userData)) : (this.state.userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"))
        }, () => {
            this.getProjectList(this.state.userId)
            this.getCauseList(this.state.userId)
            this.getCircleList(this.state.userId)
        })
    }

    setAsEntity = (entityId, entityType, scondaryEntityName) => {
        this.setState({
            entityId: entityId,
            scondaryEntityName: scondaryEntityName,
            hiddenPrivacy: (entityType === 'Circle' || entityType === 'Cause' || this.state.entityType === 'Company' || (this.state.entityType === 'Circle' && entityType === 'Project')) ? true : false,
            postPrivacy: 'ANYONE',
        })
    }

    getCircleList = (entityId) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/circle/get/list-for-post/' + entityId,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.status === 200) {
                if (response.data.body) {
                    this.setState({
                        'circleList': mappingUtils.circleSorting(response.data.body)
                    });
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }


    getCauseList = (entityId) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/public/cause/joined/list?userId=' + entityId + '&page=0&size=1000',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                if (response.data.body && response.data.body.content) {
                    this.setState({
                        'causeList': mappingUtils.sorting(response.data.body.content)
                    });
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getProjectList = (entityId) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/getProjects/' + entityId + '?excludeJobs=true&requestingUserId='+this.state.userData.userId,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.status === 200) {
                if (response.data.body) {
                    this.setState({
                        'projectList': response.data.body
                    });
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getBusinessPageList(entityId) {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/profile/get/all-business/pages?userId=' + entityId + userAgentGetData(),
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.status === 200) {
                if (response.data.body) {
                    this.setState({
                        'businessPageList': mappingUtils.businessPagesSorting(response.data.body)
                    });
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  
    getCircleAdminList(entityId) {
      axios({
          method: 'get',
          url: process.env.REACT_APP_userServiceURL + '/circle/get/list-for-post/' + entityId + '?isAdmin=true',
          headers: {'Content-Type': 'application/json'},
          withCredentials: true
      }).then(response => {
          if (response && response.data && response.status === 200) {
              if (response.data.body) {
                  this.setState({
                      'circleAdminList': mappingUtils.circleSorting(response.data.body)
                  });
              }
          }
      }).catch((err) => {
          console.log(err);
      });
    }

    removeSecondaryAttachments = () => {
        this.setState({
            'secondaryAttachments': []
        }, () => {
            document.getElementById('secondaryAttachmentsFileUploader').value = null;
        });
    }

    uploadSecondaryAttachments = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
                let currentFile = null;
                let fileSize = 0;
                currentFile = e.target.files[i];
                fileSize += e.target.files[i].size;
                const fileName = e.target.files[i].name;
                const idxDot = fileName.lastIndexOf(".") + 1;
                const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                const allowedAttachmentsTypeArr = ["csv", "xlsx", "xls", "doc", "docx", "ppt", "pptx", "txt", "pdf"];

                if (!allowedAttachmentsTypeArr.includes(extFile)) {
                    this.setState({
                        'error': 'Only ' + allowedAttachmentsTypeArr.join("/") + ' files are allowed!',
                        'displayError': true,
                        isPostSubmitted:false
                    });
                    document.getElementById('secondaryAttachmentsFileUploader').value = null;
                    setTimeout(() => {
                        this.setState({
                            error: '',
                            displayError: false,
                            isPostSubmitted:false
                        });
                    }, 5000);
                    return;
                }

                if (fileSize > maxMbFileSize) {
                    this.setState({'error': 'Uploaded files exceeds total limit of 100 MB. Replace/Remove some files'});
                    document.getElementById('secondaryAttachmentsFileUploader').value = null;
                    setTimeout(() => {
                        this.setState({
                            error: '',
                            isPostSubmitted: false,

                        });
                    }, 5000);
                } else {
                    if (currentFile !== null) {
                        this.setState({
                            isPostSubmitted: true,
                            error: "File Uploading..."
                        },()=>{
                            this.uploadAttachment('SECONDARY', currentFile);
                        })
                    }
                }
            }
        }
    }

    secondaryAttachmentsUpload = () => {
        this.refs.secondaryAttachmentsUploader.click();
    }

    uploadAttachment(attachmentType, currentFile) {
        return new Promise((resolve, reject) => {
            this.setState({error: 'File Uploading...'});
            const formData = new FormData();
            formData.append("file", currentFile);
            formData.append("userId", this.state.userData.userId);
            let attachmentTypeJson;
            if (attachmentType === 'SECONDARY') {
                attachmentTypeJson = 'UNKNOWN';
            } else if (attachmentType === 'WEBCAM') {
                attachmentTypeJson = 'IMAGE';
            } else {
                attachmentTypeJson = attachmentType;
            }

            formData.append("attachmentType", attachmentTypeJson);
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + "/backend/post/uploadAttachment",
                data: formData,
                withCredentials: true
            }).then(response => {
                this.setState({error: '', isPostSubmitted: false});
                if (response && response.data.body !== null && response.data.body.id !== null) {
                    if (attachmentType === 'IMAGE') {
                        let imageList = cloneDeep(this.state.imageList);
                        let imagePreviewList = cloneDeep(this.state.imagePreviewList);
                        imageList = imageList ? imageList : [];
                        imagePreviewList = imagePreviewList ? imagePreviewList : [];
                        imageList.push(response.data.body.id);
                        imagePreviewList.push(response.data.body.attachmentUrl);

                        this.setState({
                            imageList: imageList,
                            imagePreviewList: imagePreviewList
                        });
                    } else if (attachmentType === 'AUDIO') {
                        this.setState({
                            audioFilePreview: response.data.body.attachmentUrl,
                            audioFile: response.data.body.id
                        });
                    } else if (attachmentType === 'VIDEO') {
                        this.setState({
                            videoFilePreview: response.data.body.attachmentUrl,
                            videoFile: response.data.body.id
                        });
                    } else if (attachmentType === 'SECONDARY') {
                        let secondaryAttachments = cloneDeep(this.state.secondaryAttachments);
                        secondaryAttachments = secondaryAttachments ? secondaryAttachments : [];
                        secondaryAttachments.push(response.data.body.id);

                        this.setState({
                            secondaryAttachments: secondaryAttachments
                        });
                    } else if (attachmentType === 'WEBCAM') {
                        this.setState({
                            imageUri: response.data.body.attachmentUrl,
                            webcamImageFile: response.data.body.id,
                            webcamOn: false
                        });
                    }
                    window.$('#postVideoPreview').modal('hide');
                    window.$('#postAudioPreview').modal('hide');
                }
                resolve();
            }).catch((err) => {
                console.log(err);
                this.setState({error: 'Please try again...', displayError: true,});
                reject(err)
                setTimeout(() => {
                    this.setState({
                        error: '',
                        isPostSubmitted: false,
                    });
                }, 5000);
            });
        });
    }

    showUser =()=>{
        window.location.href = "/post/" + this.state.postId;
    }

    render() {
        var regex = /\s+/gi;
        let Stripbody = stripHtml(this.state.postText).trim().replace(regex, ' ').split(' ').length;
        return (
            <>
                <div id="postlink" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="linkpostwrap">
                                    {((this.state.postText && this.state.postText !== '') || (this.state.urlLink && this.state.urlLink !== '') || (this.state.hashTags && this.state.hashTags.length > 0) || (this.state.secondaryAttachments && this.state.secondaryAttachments.length > 0)) ?
                                        <button className="close topCrossModal"
                                            onClick={this.confirmModal}><i className="iCross" /></button> :
                                        <button className="close topCrossModal"
                                            onClick={() => this.hideModal(true)}><i className="iCross" /></button>}
                                    <figure className="userimg">
                                        <img src={this.state.coverImage}
                                             alt=""/></figure>
                                    <div className="toppostinn">
                                        <div className="topposthead clearfix">
                                            <div className="dropdown">Post as <button type="button"
                                                                                      className={(this.state.businessPageList && this.state.businessPageList.businessPage && this.state.businessPageList.businessPage.length < 1 && this.state.circleAdminList && this.state.circleAdminList.length < 1) ? 'btn' : 'btn dropdown-toggle'}
                                                                                      data-toggle="dropdown"
                                                                                      aria-haspopup="true"
                                                                                      aria-expanded="false"
                                                                                      disabled={(this.state.businessPageList && this.state.businessPageList.businessPage && this.state.businessPageList.businessPage.length < 1 && this.state.circleAdminList && this.state.circleAdminList.length < 1) ? true : false}>
                                                {this.state.userData.type === "COMPANY" ? this.state.entityName : convertUnicode(this.state.entityName)}
                                            </button>
                                                <div className="dropdown-menu fadeIn animated">
                                                    <div className="demohead">Post as <button type="button"
                                                                                              className="btn dropdown-toggle"
                                                                                              data-toggle="dropdown"
                                                                                              aria-haspopup="true"
                                                                                              aria-expanded="false">
                                                        {this.state.userData.type === "COMPANY" ? this.state.entityName : convertUnicode(this.state.entityName)}</button>
                                                    </div>
                                                    {this.state.userData && this.state.userData.type === 'INDIVIDUAL' ?
                                                        <div className="userpanel"
                                                             onClick={() => this.setAsUser(this.state.userData.userId, 'Individual', this.state.userName, null, this.state.userData && this.state.userData.profileImage ?
                                                                 getCDNUrl(this.getOptimizedImage(this.state.userData)) : (this.state.userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"))}>
                                                            <figure className="usimg">
                                                                <img
                                                                    src={this.state.userData && this.state.userData.profileImage ?
                                                                        getCDNUrl(this.getOptimizedImage(this.state.userData)) : (this.state.userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                                                    alt=""/>
                                                            </figure>
                                                            {this.state.userName}
                                                        </div> : <div className="userpanel"
                                                                      onClick={() => this.setAsUser(this.state.userData.userId, 'Company', this.state.userName, null, this.state.userData && this.state.userData.profileImage ?
                                                                          getCDNUrl(this.getOptimizedImage(this.state.userData)) : (this.state.userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"))}>
                                                            <figure className="usimg">
                                                                <img
                                                                    src={this.state.userData && this.state.userData.profileImage ?
                                                                        getCDNUrl(this.getOptimizedImage(this.state.userData)) : (this.state.userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                                                    alt=""/>
                                                            </figure>
                                                            {this.state.userName}
                                                        </div>
                                                    }
                                                    {this.state.businessPageList && this.state.businessPageList.businessPage && this.state.businessPageList.businessPage.length > 0 ? (this.state.userData && this.state.userData.type === 'COMPANY' ? '' :
                                                        <BusinessPageList setAsUser={this.setAsUser}
                                                                          businessPageList={this.state.businessPageList}/>) : ''}
                                                    {this.state.circleAdminList && this.state.circleAdminList.length > 0 ? (
                                                        <CirclePostList setAsUser={this.setAsUser}
                                                                        circleList={this.state.circleAdminList}/>) : ''}
                                                </div>
                                            </div>
                                            <div className="dropdown last">in <button type="button"
                                                                                      className={(this.state.projectList && this.state.projectList.length < 1 && this.state.causeList && this.state.causeList.length < 1 && this.state.businessPageList && this.state.businessPageList.businessPage && this.state.businessPageList.businessPage.length < 1 && this.props.circleList && this.props.circleList.length < 1) ? 'btn' : 'btn dropdown-toggle'}
                                                                                      data-toggle="dropdown"
                                                                                      aria-haspopup="true"
                                                                                      aria-expanded="false"
                                                                                      disabled={(this.state.projectList && this.state.projectList.length < 1 && this.state.causeList && this.state.causeList.length < 1 && this.state.businessPageList && this.state.businessPageList.businessPage && this.state.businessPageList.businessPage.length < 1 && this.props.circleList && this.props.circleList.length < 1) ? true : false}>
                                                {convertUnicode(this.state.scondaryEntityName)}</button>
                                                <a className="bkqstn" href="/post-help" target="_blank" title="Help"><i
                                                    className="iQuestion"/></a>
                                                <div className="dropdown-menu fadeIn animated">
                                                    <div className="demohead">in <button type="button"
                                                                                         className="btn dropdown-toggle"
                                                                                         data-toggle="dropdown"
                                                                                         aria-haspopup="true"
                                                                                         aria-expanded="false">
                                                        {convertUnicode(this.state.scondaryEntityName)}</button>
                                                    </div>

                                                    {this.state.entityType === 'Circle' ? <>
                                                            <li className="dropdown-item">
                                                                <a href="javascript:void(0)"
                                                                   onClick={() => this.setAsEntity(this.state.userId, 'Circle', 'Community Activity')}>
                                                                    <figure className="ico">
                                                                        <i className="iCircle_Default"
                                                                           style={{fontSize: '15px'}}/>
                                                                    </figure>
                                                                    <div>
                                                                        <h5>{'Community Activity'}</h5>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </> :
                                                        <li className="dropdown-item">
                                                            <a href="javascript:void(0)"
                                                               onClick={() => this.setAsEntity(null, 'Individual', 'Feeds')}>
                                                                <figure className="ico">
                                                                    <i className="iWN_Feeds_OL"
                                                                       style={{fontSize: '15px'}}/>
                                                                </figure>
                                                                <div>
                                                                    <h5>{'Feeds'}</h5>
                                                                </div>
                                                            </a>
                                                        </li>}
                                                    {this.state.projectList && this.state.projectList.length > 0 ? (
                                                        <ProjectPostList setAsEntity={this.setAsEntity}
                                                                         projectList={this.state.projectList}/>) : ''}
                                                    <ResearchPostList setAsSource={this.setAsSourceSecondEntity}/>
                                                    {this.state.causeList && this.state.causeList.length > 0 ? (
                                                        <CausePostList setAsEntity={this.setAsEntity}
                                                                       causeList={this.state.causeList}/>) : ''}

                                                    {this.state.circleList && this.state.circleList.length > 0 ? (this.state.entityType && (this.state.entityType === 'Circle') ? '' :
                                                        <CirclePostList setAsUser={this.setAsEntity}
                                                                        circleList={this.state.circleList}/>) : ''}

                                                </div>
                                            </div>

                                            <i className="ilinkico">
                                                <img src="https://cdn.dscovr.com/images/link-post-ico.webp"
                                                     onError={(e) => {
                                                         e.target.onerror = null;
                                                         e.target.src = "https://cdn.dscovr.com/images/link-post-ico.png"
                                                     }}
                                                     alt=""/></i>
                                        </div>
                                        
                                        <div className="blogpostbody">
                                            {this.state.error &&
                                            this.state.error === "File Uploading..." ? (
                                                <span className="alartImgUpload" style={{marginBottom: '10px'}}>
                                                        {this.state.error}
                                                    </span>
                                            ) : null}
                                            <div className="linkpostcontwrap">
                                                <form onSubmit={e => {
                                                    e.preventDefault();
                                                }}>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control linkfield"
                                                            value={this.state.urlLink}
                                                            onChange={this.handleUrlUpdate}
                                                            placeholder="type or paste your URL here..."/>
                                                        <span className="login-feedback-error mt-1"
                                                            style={{display: "block"}}>{this.state.errors.urlLink}</span>
                                                    </div>
                                                    <MentionTextArea
                                                        value={this.state.postText}
                                                        onChange={this.handlePostTextUpdate}
                                                        placeholder="Add Comment">
                                                    </MentionTextArea>
                                                    {this.state.smileDisplay && <Picker pickerStyle={{ width: '100%',height:'200px' }} onEmojiClick={this.onEmojiClick}/>}
                                                    <span className="login-feedback-error mt-1"
                                                          style={{display: "block"}}>{this.state.errors.postText}</span>
                                                </form>

                                                {
                                                    this.state.secondaryAttachments && this.state.secondaryAttachments.length > 0 &&
                                                    <div className="forumcommtvlistbott fattachview">
                                                        <a href="javascript:void(0)" className="fattach">
                                                            <i className="iLink"/>
                                                            {this.state.secondaryAttachments.length === 1
                                                                ? this.state.secondaryAttachments.length + ' File '
                                                                : this.state.secondaryAttachments.length + ' Files '} Attached
                                                        </a>
                                                        <a href="javascript:void(0)" onClick={e => {
                                                            e.preventDefault();
                                                            this.removeSecondaryAttachments();
                                                        }}>
                                                            <i className="fa fa-window-close"/>
                                                        </a>
                                                    </div>
                                                }
                                                {
                                                    this.state.error && this.state.error !== '' && this.state.error !== 'File Uploading...' &&
                                                    <span className={this.state.displayError ? 'login-feedback-error' : 'login-success-error'} style={{padding: '0 10px !important'}}>{this.state.error}</span>
                                                }
                                                <div className="toppostpopbott">
                                                    <div className="tagfield">
                                                        <AutoSuggest hashTags={this.state.hashTags}
                                                                     onDelete={this.onDelete}
                                                                     onAddition={this.onAddition}
                                                                     handleTagsChange={this.handleTagsChange}/>
                                                        {/*<TagsInput value={this.state.hashTags}
                                                                inputProps={{id: 'colon', placeholder: 'Add Hashtags'}}
                                                                ref={'TagsRef'}
                                                                onChange={this.handleTagsChange}
                                                                addKeys={[9, 13, 32, 62, 188]}
                                                        />*/}
                                                        {/* <span className="khastginfo">Press enter after each # tag</span> */}
                                                    </div>
                                                    <div className="toppostpopfeatures">
                                                        <input type="file" multiple
                                                            accept=".csv,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
                                                            id="secondaryAttachmentsFileUploader"
                                                            onChange={this.uploadSecondaryAttachments}
                                                            ref="secondaryAttachmentsUploader"
                                                            style={{display: "none"}}/>

                                                        <a href="javascript:void(0)"
                                                        onClick={this.secondaryAttachmentsUpload}
                                                        className="attchbtn">
                                                            <i className="iClip"/></a>
                                                        <a href="javascript:void(0)" className="attchbtn"
                                                           onClick={this.handleSmileDisplay}>
                                                            <i className="iEmoji"/>
                                                        </a>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            {this.state.validUrlLinkPreview && 
                                                <div className="linkpostpreview">
                                                    <div className="prevtitle">Preview</div>
                                                    <div className="postarticledesc">
                                                        {!_.isEmpty(this.state.og_data) &&
                                                            <CommonLinkPreview linkdata={this.state.validUrlLinkPreview} og_data={this.state.og_data} />
                                                        }
                                                    </div>
                                                </div>
                                            }                                          
                                        </div>
                                        <div className="bottpostpopbody">
                                            <div>
                                                {
                                                    this.state.userData && this.state.userData.type !== 'COMPANY' &&
                                                    this.state.hiddenPrivacy === false && (<div className="contxmenu">
                                                        <button type="button" className="btn dropdown-toggle"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true" aria-expanded="false"><i
                                                            className="iPrivacyLock"/>
                                                            {
                                                                this.state.postPrivacy === "ANYONE" ? "Anyone" :
                                                                    this.state.postPrivacy === "CONNECTED" ? "Connected Only"
                                                                        : "Selected Only"
                                                            }
                                                        </button>
                                                        <div className="dropdown-menu fadeIn animated"
                                                             onChange={this.changePostPrivacy}>
                                                            <label className="radio-container">Anyone
                                                                <input type="radio" value="ANYONE"
                                                                       onClick={this.handleCheckedPrivacy}
                                                                       checked={this.state.postPrivacy === "ANYONE"}/>
                                                                <span className="radio-checkmark"/>
                                                            </label>
                                                            <label className="radio-container">Connected Only
                                                                <input type="radio" value="CONNECTED"
                                                                       onClick={this.handleCheckedPrivacy}
                                                                       checked={this.state.postPrivacy === "CONNECTED"}/>
                                                                <span className="radio-checkmark"/>
                                                            </label>
                                                            <label className="radio-container">Selected Only
                                                                <input type="radio" value="SELECTED"
                                                                       onClick={this.handleSelectedOnly}
                                                                       checked={this.state.postPrivacy === "SELECTED"}/>
                                                                <span className="radio-checkmark"/>
                                                            </label>
                                                        </div>
                                                    </div>)
                                                }

                                            </div>

                                            <div className="bottpostpopright">
                                                {Stripbody > 400 ?
                                                    <></> :
                                                    <div
                                                        className="charechterinfo">{400 - Stripbody} {400 - Stripbody <= 1 ? 'word ' : 'words '}
                                                        left
                                                    </div>}
                                                {this.state.errors && this.state.errors.privacyText &&
                                                <span className="login-feedback-error mt-1"
                                                      style={{
                                                          display: "block",
                                                          width: "auto"
                                                      }}>{this.state.errors.privacyText}</span>}
                                                {this.state.errors && this.state.errors.connectionText &&
                                                <span className="login-feedback-error  post-error-tooltip"
                                                      style={{
                                                          display: "block",
                                                          width: "auto"
                                                      }}>{this.state.errors.connectionText}</span>}
                                                {((this.state.postText && this.state.postText !== '') || (this.state.urlLink && this.state.urlLink !== '') || (this.state.hashTags && this.state.hashTags.length > 0) || (this.state.secondaryAttachments && this.state.secondaryAttachments.length > 0)) ?
                                                    <a href="javascript:void(0)" className="btn cancel"
                                                       onClick={this.confirmModal}>Cancel</a> :
                                                    <a href="javascript:void(0)" className="btn cancel"
                                                       onClick={() => this.hideModal(true)}>Cancel</a>}
                                                <input type="submit" onClick={this.handleLinkPostSubmit} className="btn"
                                                       value="POST"
                                                       disabled={this.state.isPostSubmitted}/>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="linkSelectUserModal" className="chatlistpop modal fade dgpopup" tabIndex="-1"
                     data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                {
                                    this.state.isModalLoading ?
                                        <div style={{
                                            width: '100%', height: '100%', position: 'fixed', top: 0, left: 0,
                                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                                        }}>
                                            <Loader
                                                type="Grid"
                                                color="#dcde63"
                                                height={60}
                                                width={60}
                                                timeout={8000}
                                            />
                                        </div>
                                        : <div className="forwcontwrap">
                                            <div className="forwcontwraptop">
                                                <h4>Select Users</h4>
                                                <form action="" onSubmit="return false;">
                                                    <input type="text" className="form-control"
                                                           placeholder="Type Connect Name"
                                                           id="searchGroupByChars"
                                                           onKeyUp={(evt) => this.keyPressedBySearchGroupByChars(evt)}/>
                                                    <button className="btn btnsearch" type="button" onClick={(e) => {
                                                        e.preventDefault()
                                                    }}>
                                                        <i className="fa fa-search" aria-hidden="true"/>
                                                    </button>
                                                </form>

                                                {this.state.selectedList && this.state.selectedList.length > 0 ?
                                                    <HorizontalScroll reverseScroll>
                                                        <div className="srchlist">
                                                            {this.state.selectedList && this.state.selectedList.map((data, index) => {

                                                                    return <div key={index} className="srchlistblk">
                                                                        <figure className="img"><img
                                                                            src={data.personalInfo.profileImage != null ? getCDNUrl(this.getOptimizedImage(data.personalInfo)) : "https://cdn.dscovr.com/images/users1.png"}
                                                                            alt=""/>
                                                                        </figure>
                                                                        <span>{data.firstName} {data.lastName}{data.companyName}</span>
                                                                        {
                                                                            index !== this.state.userData.userId && (
                                                                                <i className="iCross"
                                                                                   onClick={(e) => this.removeUserToGroup(e, data)}/>
                                                                            )
                                                                        }
                                                                    </div>
                                                                }
                                                            )}
                                                        </div>
                                                    </HorizontalScroll> : null}
                                                <Scrollbars
                                                    className={'forwcontlistmain contactlist'}
                                                    autoHide
                                                    autoHeight
                                                    autoHeightMin={0}
                                                    autoHeightMax={322}
                                                    universal={true}
                                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                                                >
                                                    <ul>
                                                        {
                                                            this.state.userList && this.state.userList
                                                                .filter(data =>
                                                                    data.id !== this.state.userData.userId
                                                                    && (this.state.searchUserByChars === ''
                                                                    || (this.state.searchUserByChars && data.firstName && data.firstName.toLowerCase().includes(this.state.searchUserByChars.toLowerCase()))
                                                                    || (this.state.searchUserByChars && data.lastName && data.lastName.toLowerCase().includes(this.state.searchUserByChars.toLowerCase()))))
                                                                .map((data, index) => {

                                                                        return <li key={index}>
                                                                            <a href="javascript:void(0)"
                                                                               onClick={(e) => this.addUserToGroup(e, data)}>
                                                                                <figure className="users"><img
                                                                                    src={data.personalInfo.profileImage != null ? getCDNUrl(this.getOptimizedImage(data.personalInfo)) : "https://cdn.dscovr.com/images/users1.png"}

                                                                                    alt=""/></figure>
                                                                                <div>
                                                                                    <div
                                                                                        className="name">{data.firstName} {data.lastName}{data.companyName} </div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    }
                                                                )
                                                        }
                                                    </ul>
                                                </Scrollbars>
                                            </div>

                                            <div className="forwcontwrapbott">
                                                <div className="itemscount">{this.state.selectedList.length} Selected</div>
                                                <div>
                                                    <button className="butt btnsecondary close" data-dismiss="modal"
                                                            id="forwardMessageModalCloseBtn"
                                                            onClick={this.clearUsers.bind(this)}>Close
                                                    </button>
                                                    <button disabled={this.state.selectedList.length === 0}
                                                            className="btn"
                                                            onClick={this.handleUsersSelectSubmit}>Select
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/*Confirmation Modal Start*/}
                <div className="modal fade dgpopup confirmpop"
                     id={"cancelPopup1"} tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Cancel Confirmation</h5>                                
                            </div> */}
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true" onClick={this.ignoreModal}>&times;</span></button>
                            <div className="confirm-popup-modal-body">Are you sure you want to cancel this post?</div>
                            <div className="modal-footer confirm-popup-modal-footer">
                                <button type="button" className="btn btn-primary"
                                        onClick={this.hideModal}>Yes
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={this.ignoreModal}>No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Confirmation Modal End*/}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(LinkPopup);
