import React from "react";
import {authService} from "../auth";
import axios from "axios";
import httpService from "../AxiosInterceptors";
import mappingUtils from "../project/component/commons/utils/mappingUtils";
import ReactTooltip from "react-tooltip";
import CleverTapUtils from "./CleverTapUtils";

httpService.setupInterceptors();

class EntityFollow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            followed: props.followed
        }
    }


    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({'followed': nextProps.followed});
    }

    handleFollowUnfollow = () => {
        let newState = !this.state.followed;
        this.setState({'followed': newState})
        let postData={
            userId:this.props.userId,
            entityId:this.props.entityId,
            entityType:this.props.entityType
        }
        CleverTapUtils.eventClick({}, "Follow community");
        let url;
        if (newState === true) {
            url = process.env.REACT_APP_userServiceURL + '/backend/entity/follow'
        } else {
            url = process.env.REACT_APP_userServiceURL + '/backend/entity/unfollow'
        }
        axios({
            method: 'post',
            url: url,
            data:postData,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {
                if(this.props.updateCircleFollowerList) {
                    this.props.updateCircleFollowerList(this.props.entityId)
                }
                if (this.props.callback_fn) {
                    this.props.callback_fn();
                }
                if(this.props.getConnectionInfo){
                    this.props.getConnectionInfo(this.props.userId)
                }
                if(this.props.getFollow){
                    this.props.getFollow()
                }
                if(this.props.updateParentValue){
                    this.props.updateParentValue(this.props.entityId,newState);
                }
            } else {
                this.setState({'followed': !newState})
            }
        }).catch((err) => {
            this.setState({'followed': !newState})
            console.log(err);
        });
    }

    render() {
        return (

                <div className="socialico copyLnk"
                     >
                    {this.state.loginSuccess && this.state.followed === false ?
                        <>
                            {/*<span className="fdot">.</span>*/}
                            <span data-tip-disable={mappingUtils.getPrivacySettingDataForCircle(this.props.privacySetting, 'follower_tab')}
                                  data-tip="msg to show" data-for={'circleFollow'+this.props.userId} data-place='bottom'>
                            <button
                                disabled={!mappingUtils.getPrivacySettingDataForCircle(this.props.privacySetting, 'follower_tab')}
                                onClick={this.handleFollowUnfollow}
                                className={this.props.isCircle ? "follow follow-link " : "follow follow-link followBtn "}>
                                <i className="iAddRSS"/> Follow
                            </button></span>
                        </>
                        : this.state.followed === true ?
                            <>
                                {/*<span className="fdot">.</span>*/}
                                <button
                                   // disabled={!mappingUtils.getPrivacySettingDataForCircle(this.props.privacySetting, 'follower_tab')}
                                    onClick={this.handleFollowUnfollow}
                                    className={this.props.isCircle ? "follow follow-link active " : "follow follow-link followBtn active"}>
                                    <i className="iTickRSS"/> Following
                                </button>
                            </>
                            : ''}
                    <ReactTooltip
                        className="rtoolTip"
                        place="bottom"
                        id="registerTip"
                        id={'circleFollow'+this.props.userId}
                        type="dark"
                        textColor="#00394D"
                        effect="solid"
                        backgroundColor="#ffffff">Action Restricted due to privavcy setting</ReactTooltip>
                </div>


        );

    }
}

export default EntityFollow
