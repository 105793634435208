import React, { Component } from 'react';
import {authService} from "../auth";
import {Redirect} from "react-router-dom";

class DeactivationWithConfirmFinalPopUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            messages: null,
            isLoading: true,
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            data: {},
            message: '',
            success: false,
            redirectMainPage:false

        };

    }

    redirect = (event)=>{
        event.preventDefault();
        window.$('#finalPopUpAfterAdminRights').modal('hide');
        this.setState({'redirectMainPage':true});

    }

    render() {
        if(this.state.redirectMainPage){
            return  <Redirect
                to={{
                    pathname: "/security-and-login"
                }}
            />
        }

        return <>
            <div className="modal fade dgpopup confirmpop" id="finalPopUpAfterAdminRights" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="dltacntdesc">

                                <p>Your profile will be deactivated once all your Admin rights are transferred. Please note that your deletion requests are void if all the admin rights are not transferred within 7 days</p>
                                <p>Once deactivated, within 72 hours your profile will not be visible on WeNaturalists. </p>
                                <p>You may choose to reactivate your account within a period of 1 year by writing to us at <a href="mailto:support@wenaturalists.com" target="_blank" style={{color: "#BFC52E"}}>support@wenaturalists.com</a>. After this period, the profile will be permanently deleted</p>
                                <i className="iCaution"/>
                            </div>
                        </div>
                        <div className="modal-footer" style={{background: '#E7F3E3', borderTop: '0' }}>
                            <button type="button" className="btn btn-primary" onClick={this.redirect}>I understand</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade dgpopup confirmpop" id="updateAdminRights" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close"><span
                            aria-hidden="true">&times;</span></button>
                        <div className="confirm-popup-modal-body">                            
                            <p>Your request for admin rights has been submitted for <span>Project/community/business pages title</span></p>
                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary">OK</button>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modal fade dgpopup confirmpop" id="deleteCompanyAccount" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close"><span
                            aria-hidden="true">&times;</span></button>
                        <div className="confirm-popup-modal-body">                            
                            <p>No employee to transfer the admin rights. Please add an employee to proceed.</p>
                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary">Yes</button>
                            <button type="button" className="btn btn-secondary">No</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    }


}

export default DeactivationWithConfirmFinalPopUp


