import React, {Component} from "react";
import "../../../profile/container/profile/css/main-profile.min.css";
import "../../../project/container/Project/css/main-projects.min.css";
import "react-toastify/dist/ReactToastify.css";
import {Link} from "react-router-dom";
import axios from "axios";
import StaticHeader from "../../../StaticHeader";
import {authService} from "../../../auth";

class DeactivatedUnAuthProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData:authService.getUserDetailsFromLocalStorage(),
            data:{}
        };
    }

    componentDidMount() {
        if(this.props.location && this.props.location.state && this.props.location.state.customUrl){
            this.getUserDataByCustomUrl(this.props.location.state.customUrl)
        }

    }

    getUserDataByCustomUrl = () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_userServiceURL + '/unauth/userData?customUrl=' + this.props.location.state.customUrl,
            headers: {"Content-Type": "application/json"},
            withCredentials: true,
        })
            .then((response) => {
                let res = response.data;
                if (res && res.status === '200 OK') {
                    this.setState({
                        data: res.body
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }


    render() {

            return <div className="wrapper projectpage profilepage pabout ppersonal">
                <StaticHeader/>
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="bk_dactiveprflbx">
                                    <img src="https://cdn.dscovr.com/images/Deactivated.webp" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/Deactivated.png"}} alt=""/>
                                    {this.state.data.deactivatedByAdmin ?
                                        <p>This account is deactivated as per <br/><Link to="/CommunityGuidelines">Community Guidelines</Link>!</p>:
                                        <p>This account has been deactivated by the member!</p>
                                    }


                                    <Link to={"/network"} className="btn connect_btn">Explore More <i
                                        className="iArrow_Right"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        }


}

export default DeactivatedUnAuthProfile

