import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "../api/WpondYearAPI";

import { setWpondYearDataOnSuccess, SET_WPOND_YEAR_DATA} from "../../reducer/wpond/WpondReducer";

 function* loadWpondYearData(action) {
    try {
        let response = yield call(api.fetchWpondYearDetail,action.payload);
        yield put(setWpondYearDataOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export default function* watchYearData() {
    yield takeEvery(SET_WPOND_YEAR_DATA, loadWpondYearData);
}



