import React, { Component } from 'react';
import { authService } from '../auth';
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

require("highcharts/modules/map")(Highcharts);
class AnalticsBarViewData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            barChartData: {}
        }

        var easeOutBounce = function (pos) {
            if ((pos) < (1 / 2.75)) {
                return (7.5625 * pos * pos);
            }
            if (pos < (2 / 2.75)) {
                return (7.5625 * (pos -= (1.5 / 2.75)) * pos + 0.75);
            }
            if (pos < (2.5 / 2.75)) {
                return (7.5625 * (pos -= (2.25 / 2.75)) * pos + 0.9375);
            }
            return (7.5625 * (pos -= (2.625 / 2.75)) * pos + 0.984375);
        }

        Math.easeOutBounce = easeOutBounce;

        this.barChartDataOptions = {
            chart: {
                type: "bar",
                zoomType: "y",
                spacingTop: 20,
                spacingLeft: 20,
                spacingRight: 20,
                spacingBottom: 20,
                height: 500,
                style: {
                    fontFamily: 'Montserrat,sans-serif'
                }
            },
            title: {
                text: this.props.name,
            },
            xAxis: {
                categories: [],
                title: {
                    text: null
                },
                labels: {
                    style: {
                        // fontSize: '1.05em',
                        // fontWeight: '500',
                        // color: '#367681'
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                tickInterval: 10,
                title: {
                    text: null
                },
                labels: {
                    overflow: "justify",
                    format: "{value}%",
                    style: {
                        fontSize: '1em',
                        fontWeight: '600',
                        color: '#367681'
                    }
                }
            },
            plotOptions: {                
                bar: {
                    dataLabels: {
                        enabled: true,
                        format: "{y}%"
                    }
                },
                series: {
                    pointWidth: 16,
                    borderWidth: 0,
                    //colorByPoint: true
                }
            },            
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: "Activity Data",
                    color: {
                        linearGradient: {
                          x1: 0,
                          x2: 0,
                          y1: 0,
                          y2: 1
                        },
                        stops: [
                          [0, '#D0E8C8'],
                          [1, '#97A600']
                        ]
                      },
                    borderColor: '#60A465',
                    data: [],
                    tooltip: {
                        valueSuffix: "%",
                        pointFormatter: function () {
                            return (props.selectData === "DONATIONS" ? 'Amount : ' : 'Count : ') + (props.selectData === "DONATIONS" ? (props.entityConfig.indian ? "₹" : "$") : "") + (props.data && props.data.length > 0 && (Math.round(props.data[this.index].count * 100) / 100).toFixed(2).replace(/\.0+$/,'')) + '<br/>' + (props.data[this.index].user ? 'User'+ (props.data[this.index].user > 1 ? 's' : '') + ' : ' + props.data[this.index].user : "");
                        }
                    },
                    animation: {
                        duration: 2000,
                        // Uses Math.easeOutBounce
                        easing: 'easeOutBounce'
                    }
                }
            ]
        };
    }

    getBarChartData = () => {
        let axisData = [];
        let axisDataValue = [];
        let totalCount = this.props.data && this.props.data.map(item => Math.round(item.count)).reduce((prev, curr) => prev + curr, 0);
        this.props.data && this.props.data.map((item, index) => {
            axisData.push(item._id === true ? 'PAID' : item._id === false ? 'FREE' : item._id);
            let percenatageValue = parseFloat((item.count/totalCount)*100).toFixed(2);
            axisDataValue.push(parseFloat(percenatageValue));
        })
        this.barChartDataOptions.xAxis.categories = axisData;
        this.barChartDataOptions.series[0].data = axisDataValue;
        this.setState({
            barChartData: this.barChartDataOptions
        })
    }
 
    componentDidMount() {
        this.getBarChartData();
        
    }

    render() {
        const {barChartData} = this.state;
        return (
            <div className='chartBox'>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={barChartData}
                />
            </div>
            
        );
    }
}

export default (AnalticsBarViewData);