import React, {Component} from 'react';
import {authService} from "../auth";
import axios from "axios";
import * as _ from "lodash";
import {getCDNUrl, getOptimizedCauseCoverImage} from "../commons/CommonFunctions";
import {Link} from "react-router-dom";

class RecommendedCause extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            list: []
        }
    }

    componentDidMount() {            
        this.getRecommendedCause();
    }

    getRecommendedCause() {
        let specialisation = [];
        let persona = [];
        let skills = [];
        let interests = [];
        if (!_.isUndefined(this.state.userData.persona) && !_.isNull(this.state.userData.persona)) {
            persona.push(this.state.userData.persona);
        }
        if (!_.isUndefined(this.state.userData.specialities) && !_.isNull(this.state.userData.specialities)) {
            specialisation = this.state.userData.specialities;
        }
        if (!_.isUndefined(this.state.userData.interests) && !_.isNull(this.state.userData.interests)) {
            interests = this.state.userData.interests;
        }
        if (!_.isUndefined(this.state.userData.skills) && !_.isNull(this.state.userData.skills)) {
            skills = this.state.userData.skills;
        }


        let postBody = {
            "userId": this.state.userData.userId,
            "skill_set": skills,
            "specialisation": specialisation,
            "interest": interests,
            "persona": persona

        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/cause/recommended/?page=' + 0 + "&size=" + 3,
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.data.status === '200 OK') {
                this.setState({list: response.data.body.body});
            }
        }).catch((err) => {
            console.log(err);
        });
    };


    render() {
        return (
            <div className="dgwidget peoplefollow clearfix">
                <h6>Causes for you</h6>
                <div className="causeSidelist">
                    {this.state.list && this.state.list.length > 0 && this.state.list.map((data, index) => {
                        if (index < 2) {
                            return <div className="causeSideblk" key={index}>
                                <Link to={"/cause/" + data.slug}>
                                    <img src={getCDNUrl(getOptimizedCauseCoverImage(data))} alt=""/>
                                    <div className="causeSideblkcaption">
                                        <div className="title">{data.name}</div>
                                    </div>
                                </Link>
                            </div>
                        }
                    })}


                </div>
                {
                    this.state.list && this.state.list.length > 2 &&
                    <Link to={"/causes"} className="showmore">Show more</Link>
                }
            </div>
        );
    }
}


export default RecommendedCause;

