

const initialState = {
    clickProgress : false,
    data : {},
    error : {}
}

const blogPageClickReducer = (state = initialState, action)=>{
    switch (action.type){
        case "PAGE_CLICK_REQUEST" :
            return {
                ...state,
                clickProgress:true,
            };
        case "PAGE_CLICK_SUCCESS" :
            return {
                ...state,
                clickProgress:false,
                data: action.payload,
                error: {}
            };
        case "PAGE_CLICK_FAILURE" :
            return {
                ...state,
                clickProgress:false,
                data: {},
                error: action.error
            };
        default :
            return state;
    }
}

export default blogPageClickReducer;