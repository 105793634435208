import React from "react";
import { Link } from "react-router-dom";

const Breadcumbs = ({ crumbs, align }) => {

    if (crumbs?.length <= 1) {
        return null;
    }

    return (
        <nav aria-label="breadcrumb" maxItems={2} className={align === "Left" ? "justify-content-start px-0" : align === "Right" ? "justify-content-end px-0" : ""}>
            <ol className="breadcrumb">
                {crumbs?.length > 0 && crumbs.map(({ name, path, state, handleTabFunc }, key) =>
                    {return (
                        <>
                            {key + 1 === crumbs.length ? 
                                <li className="breadcrumb-item active" aria-current="page">{name}</li> :
                                <li className="breadcrumb-item"><Link key={key} to={{ pathname: path, state: state }} onClick={() => handleTabFunc ? handleTabFunc() : "javascript:void(0)"}>{name}</Link></li>
                            }
                        </>
                    )}
                )}
            </ol>
        </nav>
    );
};
export default Breadcumbs;