import React, { Component } from 'react'
import { commonOgSection, findSiteUrl, getEnviormentRegex, ogStaticImagePathFn } from './CommonFunctions';

class CommonLinkPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageWidth: null
        }
        this.checkImageSizeFn = this.checkImageSizeFn.bind(this);
    }

    checkImageSizeFn(src) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(img.width)
            img.onerror = reject
            img.src = src
        })
    }

    logImageData = async (imageUrl) => {
        this.setState({
            imageWidth: await this.checkImageSizeFn(imageUrl)
        })
    }

    componentDidMount() {
        this.logImageData(this.props.og_data?.image);
    }

    render() {
        const { imageWidth } = this.state;
        const { linkdata, og_data } = this.props;
        let regexSite = getEnviormentRegex();
        return (
            <div>
                {imageWidth &&
                    (
                        (findSiteUrl(linkdata, regexSite) || ogStaticImagePathFn.includes(og_data?.image) || imageWidth <= 300) ?
                            commonOgSection(linkdata, og_data, true) : commonOgSection(linkdata, og_data, false)
                    )
                }
            </div>

        )
    }
}

export default CommonLinkPreview;
