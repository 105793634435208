import React from "react";
import { Component } from "react";
import { authService } from "../../../auth";
import axios from "axios";
import { Link } from "react-router-dom";
import StudioPaidUserItem from "./StudioPaidUserItem";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ErorrMsgPopUp = withReactContent(alertPopUp);
class StudioPaidUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            paidUserList: {}
        }
    }
    
    getOptimizedImage(data) {
        if (data.resizedProfileImages && data.resizedProfileImages['200x200'] != null) {
            return data.resizedProfileImages['200x200'];
        }
        return data.originalProfileImage;
    }

    getPaidUserList = (type) => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/studioPayment/getUsers/${this.props.studioItemId}?userId=${this.state.userData.userId}&entityId=${this.props.entityId}&page=0&size=10`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data.message === "Success!") {
                let data = response.data.body;
                this.setState({
                    paidUserList: data,
                },() => {
                    window.$("#paidUserListPop").modal("show");
                })
            } 
        })
        .catch((err) => {
            console.log(err);
            ErorrMsgPopUp.fire({
                didOpen: () => {
                    ErorrMsgPopUp.clickConfirm();
                }
            }).then((result) => {
                return ErorrMsgPopUp.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    title: <><p>{err.response.data.message}</p></>,
                    didClose: () => {
                        this.props.triggerPaidUserPopUpClose()
                    }
                })
            })
        });
    }

    updatePaidUserCheckedData = (event, user) => {
        let paidUserList = this.state.paidUserList;
        const index = paidUserList && paidUserList.content.findIndex(p => p.userId === user.userId)
        paidUserList.content[index].payment = event.target.checked;
        this.setState({
            paidUserList: paidUserList
        })
        let postBody = {
            postId: this.props.studioItemId,
            paidUser: user.userId,
            addUser: event.target.checked
        }
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/studioPayment/updatePaidUserList',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            if(response && response.data && response.data.message === "Success!") {
                
            } 
        }).catch((err) => {
            console.log(err);
        });
    };

    componentDidMount() {
        this.getPaidUserList();
    }

    componentWillUnmount() {
        window.$("#paidUserListPop").modal("hide");
    }


    render() {
        const {paidUserList} = this.state;
        const { currencyType } = this.props;
        return (
            <div id="paidUserListPop" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="paidUserListPop">
                                <button type="button" className="close topCrossModal" data-dismiss="modal" aria-label="Close" onClick={this.props.triggerPaidUserPopUpClose}>
                                    <i className="iCross" /></button>
                                <h2>Manage Subscribers</h2>

                                <div className="paidUserListWrap">
                                    {paidUserList && paidUserList.content && paidUserList.content.map((user) => {
                                        return(
                                            <StudioPaidUserItem paidUserItem={user} key={user.id} updatePaidUserCheckedData={this.updatePaidUserCheckedData} />
                                        )
                                    })
                                    }
                                    {paidUserList && paidUserList.content && paidUserList.content.length === 0 &&
                                    <div className="zdata">
                                        <div className="desc">
                                            <h4>No data available.</h4>
                                        </div>
                                    </div>}

                                    {paidUserList && paidUserList.page && !paidUserList.page.last &&
                                    <div className="btnblock text-center">
                                        <Link className="btn morebtnsm" to={`/studio/manage-subscribed/list/${this.props.studioItemId}/${this.props.entityId}`}>View All</Link>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StudioPaidUserList;