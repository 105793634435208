import React from 'react'
import $ from 'jquery';
import {authService} from "../auth";
import Header from "../Header";
import FooterDiv from "../FooterDiv";
import StaticHeader from "../StaticHeader";
import {Link, NavLink} from "react-router-dom";
import LeftPanel from '../LeftPanel';
import AuthModalComponent from "../LoginExtra/AuthModalComponent";
import SignUpModal from "../SignUpModal/signUpModal";
import LeftPanelUnauth from "../commons/LeftPanelUnauth";
import { isWebView } from '../commons/CommonFunctions';

class CommunityGuidelines extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
        };
    }

    componentDidMount() {
        document.title = "Community Guidelines | WeNaturalists"
        window.scrollTo(0, 0);

        window.$(document).on('contextmenu', "img:not('.logotop')", function () {
            return false;
        });
        window.$('#header .iCross').hide();
        window.$("#header .iHamburger").on('click', function (event) {
            window.$('.leftpanel').addClass('navactive');
            window.$('.dgcontainer').addClass('navactive');
            window.$('.staticPage').addClass('navactive');
            window.$('#header .iHamburger').hide();            
            window.$('#header .iCross').show();
        });
        $("body, html").on('click', function (event) {
            if (!$(event.target).closest('#header .iHamburger, .read-more-less-btn').length) {
                $('.leftpanel').removeClass('navactive');
                $('.dgcontainer').removeClass('navactive');
                window.$('.staticPage').removeClass('navactive');
                $('.dropdown-menu.show').removeClass('show');
                window.$('#header .iHamburger').show();            
                window.$('#header .iCross').hide();
            }
        });
        window.$('.usermenu .dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.usermenu .dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.usermenu .dropdown-menu').addClass('show');
            e.stopPropagation();
        });
    }

    loginRequired=(slug)=> {
        this.setState({'loginRequired': true, 'pathname':slug})
        window.$('#loginModal').modal('show');
    }

    handleLoginClose = () => {
        this.setState({'loginRequired': false})
        window.$('#loginModal').modal('hide');
    }

    signUpRequired=()=>{
        this.setState({'signUpRequired':true})
        window.$('#signupModal').modal('show');
    }

    handleSignUpClose = () => {
        this.setState({'signUpRequired': false})
        window.$('#signupModal').modal('hide');
    }

    render() {
        return (
            <div className={"staticPage"/*   + (isWebView() ? " mobileView" : "") */}>
                {/* {!isWebView() ? (!this.state.loginSuccess ? <StaticHeader/> : <Header/>): null } */}
                {!this.state.loginSuccess ? <StaticHeader/> : <Header/>}
                <main className="dgcontainer hm3col">
                    <div className="container">

                        <section className="globalinn">
                            <div className="pagetitle bkstatcpgttl text-center">
                                <h1>Community Guidelines</h1>
                            </div>

                            <div className="globalPgCont">
                                <p>The WeNaturalists Community is your space to celebrate nature and create meaningful
                                    conversations and collaborations with your peers. We are a community that is trying to
                                    create a collective voice for nature. And, the community's way forward belongs in each
                                    one of our hands.</p>
                                <p>The community encourages everyone to engage in a responsibly and professionally. Just as
                                    nature calls for harmony, for this community to grow, we have to follow certain house
                                    rules to ensure a healthy and positive environment. </p>
                                <p>Here are some basic ‘Community Guidelines’ to help you understand what is expected to be
                                    a part of the WeNaturalists community.</p>
                                <p><em><strong>Warning:</strong> The community reserves the right to remove or suspend any
                                    account if it is found to violate the guidelines. If you notice any inappropriate
                                    behavior or content being posted on our platform, please report it to us. </em></p>

                                <div className="text-center"><h2>General rules:</h2></div>
                                <h5>1. Be real/authentic:</h5>
                                <ul>
                                    <li>Let the community know the real you - including your name, work, interests, and
                                        aspirations.
                                    </li>
                                    <li>Everyone values quality and engaging content, so we urge you to avoid fake or
                                        anonymous identities as well as sharing irrelevant, fake, or fraudulent information
                                        and news.
                                    </li>
                                    <li>Avoid spamming, scamming, and posting unreliable content.</li>
                                </ul>
                                <h5>2. Be respectful and professional:</h5>
                                <ul>
                                    <li>Put forth your opinions in an appropriate manner and only when it is relevant to our
                                        community’s ethos i.e. nature.
                                    </li>
                                    <li>If you disagree with anyone’s opinion, do so politely and with respect.</li>
                                    <li>As much as we appreciate a good social exchange, pleasantries like good morning,
                                        festive greetings and birthday wishes do not fall under the purview of our
                                        community’s primary interests.
                                    </li>
                                </ul>
                                <h5>3. Be open-minded:</h5>
                                <ul>
                                    <li>We are a global community with people from different backgrounds and cultures, all
                                        with the right to form their own opinions, and beliefs.
                                    </li>
                                    <li>Be open to healthy discussions, and if it comes to irreconcilable differences -
                                        agree to disagree in a civil manner.
                                    </li>
                                </ul>
                                <h5>4. Be encouraging:</h5>
                                <ul>
                                    <li>You are likely to meet experts, amateurs as well as nature enthusiasts. Here is your
                                        chance to encourage, inspire, find your voice, and become an integral part of the
                                        community.
                                    </li>
                                    <li>Avoid sarcasm or humor that may not be understood by everyone.</li>
                                </ul>

                                <div className="text-center"><h2>Grounds for suspension:</h2></div>
                                <p>The breach of below guidelines will be considered as a default of serious nature and can
                                    stand to be the grounds for suspending or terminating an account.</p>
                                <h5>1. Be appropriate:</h5>
                                <ul>
                                    <li>Comments that degrade any race, ethnicity, caste, religion, gender, sexual
                                        preference, age, or abilities are not acceptable. Any such comments will be
                                        moderated by our team and removed if found derogatory.
                                    </li>
                                </ul>
                                <h5>2. Encourage a safe space:</h5>
                                <ul>
                                    <li>We want this community to be a safe space for everyone. Trolling, sexually explicit
                                        content, nudity, online gambling, and phishing schemes are not acceptable.
                                    </li>
                                    <li>Nature is for everyone and in that spirit, we encourage you to refrain from making
                                        any political or religious statements, posting, or sharing any form of hate speech
                                        or any kind of violent or abusive content.
                                    </li>
                                    <li>Refrain from insults, harassment, cyberbullying, profanity, and defamatory remarks.
                                        Do not share illegal or prohibited content and information.
                                    </li>
                                </ul>
                                <h5>3. Be mindful:</h5>
                                <ul>
                                    <li>Do not promote harm to self or others - physical, emotional, financial, or legal.
                                    </li>
                                    <li>Any content or conversation which could propagate harm to nature, any member of the
                                        community, or the world at large, is discouraged.
                                    </li>
                                </ul>
                                <h5>4. Respect our rules:</h5>
                                <ul>
                                    <li>We encourage sharing of knowledge, but all exchanges need to be appropriate and
                                        moderated by the member posting it, or by the group administrator.
                                    </li>
                                    <li>Do not post or share any content that violates someone’s intellectual property
                                        rights.
                                    </li>
                                    <li>In general, any information containing mature or graphic content needs to be
                                        moderated appropriately and posted with member discretion.
                                    </li>
                                    <li>Do not distribute viruses, trojans, worms that could be potentially harmful.</li>
                                </ul>
                                <p>We thank you for being a part of the community and doing your bit for the environment.
                                    Our mission is to create an elite space for all nature lovers while making your
                                    experience immersive, informative, and convenient. You are free to share, post
                                    information, and engage with other members of the community, while abiding by our
                                    Community Guidelines and {!isWebView() ? <a href="/TermsConditions">Terms and Conditions</a> : "Terms and Conditions"}.</p>
                            </div>
                        </section>

                    </div>
                </main>
                {!this.state.loginSuccess ?
                    <LeftPanelUnauth signUpRequired={this.signUpRequired}/> :
                <LeftPanel/>}
                {!isWebView() && <FooterDiv/>}
                {this.state.loginRequired === true ? <AuthModalComponent handleLoginClose={this.handleLoginClose} pathname={this.state.pathname}/> : null}
                {this.state.signUpRequired === true ? <SignUpModal  handleSignUpClose={this.handleSignUpClose} history={this.props.history}/> : null}
            </div>
        );

    }

}

export default CommunityGuidelines
