import React from 'react'
import { authService } from '../auth';
import axios from "axios";
import FooterDiv from "../FooterDiv";
import * as _ from "lodash";
import CountryCode from '../country_code';
import CreatableSelect from 'react-select/creatable';
import httpService from '../AxiosInterceptors';
import Select from 'react-select'

httpService.setupInterceptors(); 

class OperatorInfo extends React.Component {

    constructor(props) {
        super(props);
        let validation = {
            firstName: {},
            lastName: {},
            address: {},
            country: {},
            state: {},
            city: {},
            phone: {},
            designation: {},
            countryCode: {}
        }

        CountryCode.sort((a, b) => (a.Dial > b.Dial) ? 1 : ((b.Dial > a.Dial) ? -1 : 0));

        this.state = {
            validation,
            isLoading: true,
            countries: [],
            states: [],
            cities: [],
            firstName: '',
            lastName: '',
            address: '',
            designation: '',
            country: '',
            state: '',
            city: '',
            phone: '',
            errors: {},
            userData: authService.getUserDetailsFromLocalStorage(),
            countryCodeInfo: [],
            countryCode: [],
            countryCodeList: [].concat(CountryCode),
            selectedOption: null,
            selectedOption1: null,
            selectedOption2: null,

        };
    }

    componentDidMount() {
        window.$('.selectpicker').selectpicker();
        window.$('#react-select-4-input').on('keypress', function (event) {
            var regex = new RegExp("^[a-zA-Z0-9]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });
        this.fetchCountries();
    }

    handleKeyDown = (e) => {
        if(e.keyCode === 32) {
            e.preventDefault();
            this.ref.onInputChange(this.ref.state.inputValue + " ", "set-value");
        }
    };


    handleAddress = (e) => {
        this.setState({ address: e.target.value });
    };

    fetchCountries = () => {
        //this.state.countries.push("Select Country *");
        axios({
            method: "get",
            url: process.env.REACT_APP_userServiceURL + "/backend/region/countries",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                this.setState({ countries: this.state.countries.concat(response.data.body) });
                this.setState({ state: 'Select State *' });
                this.setState({ city: 'Select City *' });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getCountryCode = (newValue, actionMeta) => {
        this.setState({ selectedOption4: newValue.value });
        this.setState({ 'countryCodeInfo': newValue });
    }


    getCountryCodeOptions = () => {
        let types = this.state.countryCodeList.map((option) => {
            return { value: option.Unicode+' +'+option.Dial+' '+option.Name,dial:option.Dial, label: option.Unicode+' +'+option.Dial+' '+option.Name, name: 'countrycode' }
        });
        return types;
    };

    countryDropDownMenu() {
        const { selectedOption4 } = this.state;
        return <Select
        options={this.getCountryCodeOptions()}
        onChange={this.getCountryCode}
        placeholder={'Code *'}
        name={'countrycode'}
        classNamePrefix='react-select'
        styles={{
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = '#cfe7c7';
                return {
                    ...styles,
                    backgroundColor: isDisabled
                        ? null
                        : isSelected
                            ? '#cfe7c7'
                            : isFocused
                                ? '#cfe7c7'
                                : null,
                    color: isDisabled
                        ? '#ccc'
                        : isSelected
                            ? color > 2
                                ? '#00374d'
                                : '#00374d'
                            : data.color,
                    cursor: isDisabled ? 'not-allowed' : 'default',

                    ':active': {
                        ...styles[':active'],
                        backgroundColor: !isDisabled && (isSelected ? '#cfe7c7' : '#cfe7c7'),
                    },
                };
            },
        }}
    />
    //    return <select className="selectpicker" data-width="fit"
    //         name="countryCode"
    //         onChange={this.onSelectCountryCode}
    //         style={this.state.validation.countryCode.style}
    //         required={true}>
    //         {this.getCountryCodeOptions()}
    //     </select>
    }


    getStateList = () => {
        const { selectedOption1 } = this.state;
        return (
            <div className="form-group">
                <CreatableSelect
                    value={selectedOption1}
                    options={this.getStatesOptions()}
                    onChange={this.getState}
                    styles={
                        this.state.validation &&
                        this.state.validation.state &&
                        this.state.validation.state.style
                        ? {
                            control: 
                                styles => ({ 
                                    ...styles, 
                                    borderColor: '#b34c4c',
                                    ":hover": {
                                        ...styles[":hover"],
                                        borderColor: '#b34c4c',
                                        }
                                }),
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                        : {                            
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                    }
                    onInputChange={this.handleCreate1}
                    placeholder={'Select State *'}
                    name={'state'}
                />
                <span className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors.state}</span>
                <span
                    className="login-feedback-error mt-1">{this.state.validation.state && this.state.validation.state.message}</span>

            </div>
        );
    };
    getCountryList = () => {
        const { selectedOption } = this.state;
        return (
            <div className="form-group">
                <Select
                    value={selectedOption}
                    options={this.getCountriesOptions()}
                    onChange={this.getCountry}
                    styles={
                        this.state.validation &&
                        this.state.validation.country &&
                        this.state.validation.country.style
                        ? {
                            control: 
                                styles => ({ 
                                    ...styles, 
                                    borderColor: '#b34c4c',
                                    ":hover": {
                                        ...styles[":hover"],
                                        borderColor: '#b34c4c',
                                        }
                                }),
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                        : {                            
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                    }
                    placeholder={'Select Country *'}
                    onBlur={(e) => this.validate("country", e.target.value)}
                    onInputChange={this.handleCreate}
                    name={'country'}
                />
                <span className="login-feedback-error mt-1"
                    style={{ display: "block" }}>{this.state.errors.country}</span>
                <span
                    className="login-feedback-error mt-1">{this.state.validation.country && this.state.validation.country.message}</span>
            </div>
        );
    };

    getCitiesList = () => {
        const { selectedOption2 } = this.state;
        return (
            <div className="form-group">
                <CreatableSelect
                    value={selectedOption2}
                    options={this.getCitiesOptions()}
                    onChange={this.getCity}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                    ref={ref => {
                        this.ref = ref;
                    }}
                    styles={
                        this.state.validation &&
                        this.state.validation.city &&
                        this.state.validation.city.style
                        ? {
                            control: 
                                styles => ({ 
                                    ...styles, 
                                    borderColor: '#b34c4c',
                                    ":hover": {
                                        ...styles[":hover"],
                                        borderColor: '#b34c4c',
                                        }
                                }),
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                        : {                            
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                    }
                    
                    onInputChange={this.handleCreate2}
                    placeholder={'Select City *'}
                    name={'city'}
                    classNamePrefix='react-select-other'
                />
                <span className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors.city}</span>
                <span
                    className="login-feedback-error mt-1">{this.state.validation.city && this.state.validation.city.message}</span>

            </div>
        );
    };

    getCountriesOptions = () => {
        let countriesList = this.state.countries.map((country) => {
            return { value: country, label: country, name: 'country' }
        });
        return countriesList;
    };

    getCitiesOptions = () => {
        let cities = this.state.cities.map((city) => {
            return { value: city, label: city, name: 'city' }
        });
        return cities;
    };

    getStatesOptions = () => {
        let states = this.state.states.map((state) => {
            return { value: state, label: state, name: 'state' }
        });
        return states;
    };

    handleCreate = (inputValue, actionMeta) => {

    };

    handleCreate1 = (inputValue1, actionMeta1) => {

    };
    handleCreate2 = (inputValue2, actionMeta2) => {

    };


    getCountry = (newValue, actionMeta) => {
        const fn = /^[a-zA-Z\s]+$/;
                this.state.validation.country = {};
                if (
                    _.isUndefined(newValue.value) ||
                    _.isEmpty((newValue.value || "").toString()) ||
                    _.isNull(newValue.value) ||
                    newValue.value.trim().replace(" ", "").length > 25 ||
                    newValue.value.trim().replace(" ", "").length < 2
                ) { 
                    this.state.validation.country = {
                        error: true,
                        message: 'Please select a country',
                        style: {
                            container: (provided, state) => ({
                                ...provided,
                                border: '1px solid #b34c4c',
                                color:"#00394d",
                                textAlign:'left',
                                fontFamily:'"Montserrat", sans-serif',
                                fontSize:"14px",
                                borderRadius:'5px'
                            }),
                        }
                    }

                }
        let value = newValue.value;
        //this.state.validation.country = {};
        this.setState({ country: value });
        this.setState({ states: [] });
        this.setState({ cities: [] });
        this.setState({ city: '' });
        this.setState({ state: '' });
        this.setState({ selectedOption: newValue });
        this.setState({ selectedOption1: null })
        this.setState({ selectedOption2: null })
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL +
                "/backend/region/states/" +
                value,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                this.setState({ states: response.data.body }, () => {
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getState = (newValue1, actionMeta1) => {
        const fn = /^[a-zA-Z\s]+$/;
                this.state.validation.state = {};
                if (
                    _.isUndefined(newValue1.value) ||
                    _.isEmpty((newValue1.value || "").toString()) ||
                    _.isNull(newValue1.value) ||
                    newValue1.value.trim().replace(" ", "").length > 100 ||
                    newValue1.value.trim().replace(" ", "").length < 2
                ) { 
                    this.state.validation.state = {
                        error: true,
                        message: 'Please select a state',
                        style: {
                            container: (provided, state) => ({
                                ...provided,
                                border: '1px solid #b34c4c',
                                color:"#00394d",
                                textAlign:'left',
                                fontFamily:'"Montserrat", sans-serif',
                                fontSize:"14px",
                                borderRadius:'5px'
                            }),
                        }
                    }

                }
        let name = newValue1.name
        let value = newValue1.value;
        //this.state.validation.state = {};
        this.setState({ cities: [] });
        this.setState({ city: '' });
        this.setState({ state: value });
        this.setState({ selectedOption1: newValue1 })
        this.setState({ selectedOption2: null })
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL +
                "/backend/region/cities/" +
                value,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                this.setState({ cities: response.data.body }, () => {

                });
            })
            .catch((err) => {
                console.log(err);
            });

    }

    getCity = (newValue2, actionMeta2) => {
        const fn = /^[a-zA-Z\s]+$/;
                this.state.validation.city = {};
                if (
                    _.isUndefined(newValue2.value) ||
                    _.isEmpty((newValue2.value || "").toString()) ||
                    _.isNull(newValue2.value) ||
                    newValue2.value.trim().replace(" ", "").length > 100 ||
                    newValue2.value.trim().replace(" ", "").length < 2
                ) { 
                    this.state.validation.city = {
                        error: true,
                        message: 'Please select a city',
                        style: {
                            container: (provided, state) => ({
                                ...provided,
                                border: '1px solid #b34c4c',
                                color:"#00394d",
                                textAlign:'left',
                                fontFamily:'"Montserrat", sans-serif',
                                fontSize:"14px",
                                borderRadius:'5px'
                            }),
                        }
                    }

                }
        let name = newValue2.name
        let value = newValue2.value;
        this.setState({ selectedOption2: newValue2 })
        this.setState({ city: value });
    }

    validate = (fieldName, value) => {
        let isValid = true;
        switch (fieldName) {
            case "firstName":
                const fn = /^[a-zA-Z\s]+$/;
                if (value === '' || fn.test(value)) {
                    this.setState({ firstName: value });
                }
                this.state.validation.firstName = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    value.trim().replace(" ", "").length > 25 ||
                    value.trim().replace(" ", "").length < 2
                ) {
                    this.state.validation.firstName = {
                        error: true,
                        message: 'First name must have minimum 2 characters',
                        style: { borderColor: "#b34c4c", padding: "10px" }
                    }

                }
                break;

            case "lastName":
                const ln = /^[a-zA-Z\s]+$/;
                if (value === '' || ln.test(value)) {
                    this.setState({ lastName: value });
                }
                this.state.validation.lastName = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    value.trim().replace(" ", "").length > 25 ||
                    value.trim().replace(" ", "").length < 2
                ) {
                    this.state.validation.lastName = {
                        error: true,
                        message: 'Last name must have minimum 2 characters',
                        style: { borderColor: "#b34c4c", padding: "10px" }
                    }

                }
                break;
            case "designation":
                const dn = /^[a-zA-Z\s\d\D\w\W]+$/;
                if (value === '' || dn.test(value)) {
                    this.setState({ designation: value });
                }
                this.state.validation.designation = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    value.trim().replace(" ", "").length > 25 ||
                    value.trim().replace(" ", "").length < 2
                ) {
                    this.state.validation.designation = {
                        error: true,
                        message: 'Designation must have minimum 2 characters',
                        style: { borderColor: "#b34c4c", padding: "10px" }
                    }

                }
                break;

            case "address":
                this.setState({ address: value });
                this.state.validation.address = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    value.trim().replace(" ", "").length > 100 ||
                    value.trim().replace(" ", "").length < 3
                ) {
                    this.state.validation.address = {
                        error: true,
                        message: 'Address should be 3 to 100 characters',
                        style: { borderColor: "#b34c4c", padding: "10px" }
                    }

                }
                break;

            case "phone":
                const re = /^[0-9\b]+$/;
                if (value === '' || re.test(value)) {
                    this.setState({ phone: value });
                }

                this.state.validation.phone = {};
                if (this.state.countryCodeInfo.dial === '91') {
                    const regExp = /^[6-9\b]+$/;
                    if (
                        _.isUndefined(value) ||
                        _.isEmpty((value || "").toString()) ||
                        _.isNull(value) ||
                        value.trim().replace(" ", "").length > 10 ||
                        value.trim().replace(" ", "").length < 10 || !regExp.test(this.state.phone[0])
                    ) {
                        this.state.validation.phone = {
                            error: true,
                            message: 'Please enter valid phone number',
                            style: { borderColor: "#b34c4c" }
                        }

                    }
                }
                break;
            /*case "pinCode":
                const pc = /^[0-9\b]+$/;
                if (value === '' || pc.test(value)) {
                    this.setState({pinCode: value});
                }

                this.state.validation.pinCode = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    value.trim().replace(" ", "").length > 6 ||
                    value.trim().replace(" ", "").length < 6
                ) {
                    this.state.validation.pinCode = {
                        error: true,
                        message: 'Please enter valid pincode',
                        style: {borderColor: "#b34c4c"}
                    }

                }
                break;*/


            default:
                isValid = true;
                break;
        }
        return isValid;
    };

    validateForm = () => {
        this.state.validation.firstName = {};
        this.state.validation.lastName = {};
        this.state.validation.designation = {};
        this.state.validation.country = {};
        this.state.validation.state = {};
        this.state.validation.city = {};
        // this.state.validation.pinCode = {};
        this.state.validation.phone = {};
        this.state.validation.countryCode = {};
        let validationRequired = false;
        let errors = {};
        let formIsValid = true;
        if (this.state.firstName === '') {
            if (_.isUndefined(this.state.firstName) ||
                _.isEmpty((this.state.firstName || "").toString()) ||
                _.isNull(this.state.firstName)) {
                errors["firstName"] = "Please enter the first name";
                this.state.validation.firstName = {
                    error: true,
                    style: { borderColor: "#b34c4c" }
                }
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            const fn = /^[a-zA-Z\s]+$/;
            if (this.state.firstName === '' || fn.test(this.state.firstName)) {
                this.setState({ firstName: this.state.firstName });
            }
            this.state.validation.firstName = {};
            if (
                _.isUndefined(this.state.firstName) ||
                _.isEmpty((this.state.firstName || "").toString()) ||
                _.isNull(this.state.firstName) ||
                this.state.firstName.trim().replace(" ", "").length > 25 ||
                this.state.firstName.trim().replace(" ", "").length < 2
            ) {
                this.state.validation.firstName = {
                    error: true,
                    message: 'First name must have minimum 2 characters',
                    style: { borderColor: "#b34c4c", padding: "10px" }
                }
                formIsValid = false;

            }
        }
        if (this.state.lastName === '') {
            if (_.isUndefined(this.state.lastName) ||
                _.isEmpty((this.state.lastName || "").toString()) ||
                _.isNull(this.state.lastName)) {
                errors["lastName"] = "Please enter the last name";
                this.state.validation.lastName = {
                    error: true,
                    style: { borderColor: "#b34c4c" }
                }
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            const ln = /^[a-zA-Z\s]+$/;
            if (this.state.lastName === '' || ln.test(this.state.lastName)) {
                this.setState({ lastName: this.state.lastName });
            }
            this.state.validation.lastName = {};
            if (
                _.isUndefined(this.state.lastName) ||
                _.isEmpty((this.state.lastName || "").toString()) ||
                _.isNull(this.state.lastName) ||
                this.state.lastName.trim().replace(" ", "").length > 25 ||
                this.state.lastName.trim().replace(" ", "").length < 2
            ) {
                this.state.validation.lastName = {
                    error: true,
                    message: 'Last name must have minimum 2 characters',
                    style: { borderColor: "#b34c4c", padding: "10px" }
                }
                formIsValid = false;

            }
        }
        if (this.state.designation === '') {
            if (_.isUndefined(this.state.designation) ||
                _.isEmpty((this.state.designation || "").toString()) ||
                _.isNull(this.state.designation)) {
                errors["designation"] = "Please enter the designation";
                this.state.validation.designation = {
                    error: true,
                    style: { borderColor: "#b34c4c" }
                }
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            const dn = /^[a-zA-Z\s\d\D\w\W]+$/;
            if (this.state.designation === '' || dn.test(this.state.designation)) {
                this.setState({ designation: this.state.designation });
            }
            this.state.validation.designation = {};
            if (
                _.isUndefined(this.state.designation) ||
                _.isEmpty((this.state.designation || "").toString()) ||
                _.isNull(this.state.designation) ||
                this.state.designation.trim().replace(" ", "").length > 25 ||
                this.state.designation.trim().replace(" ", "").length < 2
            ) {
                this.state.validation.designation = {
                    error: true,
                    message: 'Designation must have minimum 2 characters',
                    style: { borderColor: "#b34c4c", padding: "10px" }
                }
                formIsValid = false;

            }
        }
        /*if (this.state.pinCode === '') {
            if (_.isUndefined(this.state.pinCode) ||
                _.isEmpty((this.state.pinCode || "").toString()) ||
                _.isNull(this.state.pinCode)) {
                errors["pinCode"] = "Pin Code is mandatory";
                this.state.validation.pinCode = {
                    error: true,
                    style: {borderColor: "#b34c4c"}
                }
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            const pc = /^[0-9\b]+$/;
            if (this.state.pinCode === '' || pc.test(this.state.pinCode)) {
                this.setState({pinCode: this.state.pinCode});
            }

            this.state.validation.pinCode = {};
            if (
                _.isUndefined(this.state.pinCode) ||
                _.isEmpty((this.state.pinCode || "").toString()) ||
                _.isNull(this.state.pinCode) ||
                this.state.pinCode.trim().replace(" ", "").length > 6 ||
                this.state.pinCode.trim().replace(" ", "").length < 6
            ) {
                this.state.validation.pinCode = {
                    error: true,
                    message: 'Please enter valid pincode',
                    style: {borderColor: "#b34c4c"}
                }
                formIsValid = false;

            }
        }*/
        if (_.isUndefined(this.state.countryCodeInfo.dial) || _.isEmpty(this.state.countryCodeInfo.dial) || _.isNull(this.state.countryCodeInfo.dial)) {
            errors["countryCode"] = "Please select the country code"
            this.state.validation.countryCode = {
                error: true,
                style: { borderColor: "#b34c4c" }
            }
            formIsValid = false;
            validationRequired = true;
        }
        if (this.state.phone === '') {
            if (_.isUndefined(this.state.phone) ||
                _.isEmpty((this.state.phone || "").toString()) ||
                _.isNull(this.state.phone)) {
                errors["phone"] = "Please enter your phone number";
                this.state.validation.phone = {
                    error: true,
                    style: { borderColor: "#b34c4c" }
                }
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            const re = /^[0-9\b]+$/;
            if (this.state.phone === '' || re.test(this.state.phone)) {
                this.setState({ phone: this.state.phone });
            }

            this.state.validation.phone = {};
            if (this.state.countryCodeInfo.dial === '91') {
                const regExp = /^[6-9\b]+$/;
                if (
                    _.isUndefined(this.state.phone) ||
                    _.isEmpty((this.state.phone || "").toString()) ||
                    _.isNull(this.state.phone) ||
                    this.state.phone.trim().replace(" ", "").length > 10 ||
                    this.state.phone.trim().replace(" ", "").length < 10 || !regExp.test(this.state.phone[0])
                ) {
                    this.state.validation.phone = {
                        error: true,
                        message: 'Please enter valid phone number',
                        style: { borderColor: "#b34c4c" }
                    }
                    formIsValid = false;

                }
            }
        }
        if (this.state.country === '' || this.state.country === 'Select Country *') {
            errors["country"] = "Please select a country";
            this.state.validation.country = {
                error: true,
                style: {
                    container: (provided, state) => ({
                        ...provided,
                        border: '1px solid #b34c4c',
                        color:"#00394d",
                        textAlign:'left',
                        fontFamily:'"Montserrat", sans-serif',
                        fontSize:"14px",
                        borderRadius:'5px'
                    }),
                }
            }
            formIsValid = false;
            validationRequired = true;

        }
        if (this.state.state === '' || this.state.state === 'Select State *') {
            errors["state"] = "Please select a state";
            this.state.validation.state = {
                error: true,
                style: {
                    container: (provided, state) => ({
                        ...provided,
                        border: '1px solid #b34c4c',
                        color:"#00394d",
                        textAlign:'left',
                        fontFamily:'"Montserrat", sans-serif',
                        fontSize:"14px",
                        borderRadius:'5px'
                    }),
                }
            }
            formIsValid = false;
            validationRequired = true;

        }
        if (this.state.city === '' || this.state.city === 'Select City *') {
            errors["city"] = "Please select a city";
            this.state.validation.city = {
                error: true,
                style: {
                    container: (provided, state) => ({
                        ...provided,
                        border: '1px solid #b34c4c',
                        color:"#00394d",
                        textAlign:'left',
                        fontFamily:'"Montserrat", sans-serif',
                        fontSize:"14px",
                        borderRadius:'5px'
                    }),
                }
            }
            formIsValid = false;
            validationRequired = true;

        }
        this.setState({
            errors: errors
        });
        return formIsValid;

    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validateForm()) {
            let postBody = {
                "companyId": this.state.userData.userId,
                "firstName": this.state.firstName,
                "lastName": this.state.lastName,
                "designation": this.state.designation,
                "officeAddress": this.state.address,
                "country": this.state.country,
                "state": this.state.state,
                "city": this.state.city,
                // "pinCode": this.state.pinCode,
                "mobile": this.state.phone,
                "countryISDCode": this.state.countryCodeInfo.dial
            };
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/user/company/update/operator/info',
                headers: { 'Content-Type': 'application/json' },
                data: postBody,
                withCredentials: true
            }).then((response) => {
                let res = response.data;
                if (res.message === 'Success!') {
                    let data = {};
                    let authResult = this.state.userData;
                    authResult.phone = this.state.phone;
                    authService.updateUserData(authResult);
                    data.userId = res.body.userId;
                    data.transactionId = res.body.transactionId;
                    data.email = res.body.email
                    this.props.history.push({
                        pathname: '/otp',
                        state: {
                            signUpResponse: data
                        }

                    })
                }
            }).catch((err) => {
                console.log(err);
                if (err && err.response && err.response.data) {
                    this.setState({
                        isLoaded: true,
                        error: { message: err.response.data.message, err: err.response }
                    });

                }
            });
        }
        setTimeout(() => {
            this.setState({
                error: {}
            });
        }, 5000);
        setTimeout(() => {
            this.setState({
                errors: {}
            });
        }, 5000);
    };

    render() {
        const { error } = this.state;
        var maxLength;
        if (this.state.countryCodeInfo.dial === '91') {
            maxLength = 10;
        } else {
            maxLength = 15;
        }
        return <section className="signup-wrapper">
            <div className="signup-container pt-3">
                <a href="/login" className="goback"> Go Back</a>
                <div className="signup-box text-center acctoperator">
                    <div className="signup-boxinn">
                        <a href="/"><img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" alt="" className="logotop"/></a>
                        <h1 className="signup-header-text">Account Admin Info</h1>
                        <div className="signup-form-container">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 col-xs-12">
                                        <div className="form-group">
                                            <input type="text"
                                                className="default-caps custom-input form-control"
                                                style={this.state.validation.firstName.error ? this.state.validation.firstName.style : null}
                                                value={this.state.firstName}
                                                data-tip={
                                                    this.state.validation.firstName.error &&
                                                    this.state.validation.firstName.message
                                                }
                                                onBlur={(e) => this.validate("firstName", e.target.value)}
                                                onChange={(e) => this.validate("firstName", e.target.value)}
                                                autoComplete="given-name"
                                                maxLength="25"
                                                placeholder="First name *" />
                                            <span className="login-feedback-error mt-1"
                                                style={{ display: "block" }}>{this.state.errors.firstName}</span>
                                            <span
                                                className="login-feedback-error mt-1">{this.state.validation.firstName && this.state.validation.firstName.message}</span>

                                            {/* {this.state.validation.firstName &&
                                                this.state.validation.firstName.message && (
                                                    <ReactTooltip
                                                        data-multiline={true}
                                                        delayHide={5}
                                                        type={"error"}
                                                    />
                                                )} */}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                        <div className="form-group">
                                            <input type="text"
                                                className="default-caps custom-input form-control"
                                                style={this.state.validation.lastName.error ? this.state.validation.lastName.style : null}
                                                value={this.state.lastName}
                                                data-tip={
                                                    this.state.validation.lastName.error &&
                                                    this.state.validation.lastName.message
                                                }
                                                onBlur={(e) => this.validate("lastName", e.target.value)}
                                                onChange={(e) => this.validate("lastName", e.target.value)}
                                                autoComplete="family-name"
                                                maxLength="25"
                                                placeholder="Last name *" />
                                            <span className="login-feedback-error mt-1"
                                                style={{ display: "block" }}>{this.state.errors.lastName}</span>
                                            <span
                                                className="login-feedback-error mt-1">{this.state.validation.lastName && this.state.validation.lastName.message}</span>

                                            {/* {this.state.validation.lastName &&
                                                this.state.validation.lastName.message && (
                                                    <ReactTooltip
                                                        data-multiline={true}
                                                        delayHide={5}
                                                        type={"error"}
                                                    />
                                                )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text"
                                        className="custom-input form-control"
                                        style={this.state.validation.designation.error ? this.state.validation.designation.style : null}
                                        value={this.state.designation}
                                        data-tip={
                                            this.state.validation.designation.error &&
                                            this.state.validation.designation.message
                                        }
                                        onBlur={(e) => this.validate("designation", e.target.value)}
                                        onChange={(e) => this.validate("designation", e.target.value)}
                                        autoComplete="family-name"
                                        placeholder="Designation *" maxLength="25" />
                                    <span className="login-feedback-error mt-1"
                                        style={{ display: "block" }}>{this.state.errors.designation}</span>
                                    <span
                                        className="login-feedback-error mt-1">{this.state.validation.designation && this.state.validation.designation.message}</span>

                                    {/* {this.state.validation.designation &&
                                        this.state.validation.designation.message && (
                                            <ReactTooltip
                                                data-multiline={true}
                                                delayHide={5}
                                                type={"error"}
                                            />
                                        )} */}
                                </div>
                                <div className="form-group"><input type="text"
                                    value={this.state.address}
                                    onChange={this.handleAddress}
                                    className="custom-input form-control"
                                    placeholder="Office Address"
                                />
                                    <span className="login-feedback-error mt-1"
                                        style={{ display: "block" }}>{this.state.errors.address}</span>
                                    <span
                                        className="login-feedback-error mt-1">{this.state.validation.address && this.state.validation.address.message}</span>

                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        {this.getCountryList()}
                                    </div>

                                    <div className="col-lg-6">
                                        {this.getStateList()}
                                    </div>
                                </div>
                                {this.getCitiesList()}
                                <div className="row">
                                    {/*<div className="col-lg-5">*/}
                                    {/*    <div className="form-group">*/}
                                    {/*        <input type="text" className="custom-input form-control"*/}
                                    {/*               id="phone"*/}
                                    {/*               style={this.state.validation.pinCode.error ? this.state.validation.pinCode.style : null}*/}
                                    {/*               value={this.state.pinCode}*/}
                                    {/*               data-tip={*/}
                                    {/*                   this.state.validation.pinCode.error &&*/}
                                    {/*                   this.state.validation.pinCode.message*/}
                                    {/*               }*/}
                                    {/*               onBlur={(e) => this.validate("pinCode", e.target.value)}*/}
                                    {/*               onChange={(e) => this.validate("pinCode", e.target.value)}*/}
                                    {/*               minLength="6" maxLength="6"*/}
                                    {/*               placeholder="Pin Code *"/>*/}
                                    {/*        <span className="login-feedback-error mt-1"*/}
                                    {/*              style={{display: "block"}}>{this.state.errors.pinCode}</span>*/}
                                    {/*        <span*/}
                                    {/*            className="login-feedback-error mt-1">{this.state.validation.pinCode && this.state.validation.pinCode.message}</span>*/}

                                    {/*        /!* {this.state.validation.pinCode &&*/}
                                    {/*            this.state.validation.pinCode.message && (*/}
                                    {/*                <ReactTooltip*/}
                                    {/*                    data-multiline={true}*/}
                                    {/*                    delayHide={5}*/}
                                    {/*                    type={"error"}*/}
                                    {/*                />*/}
                                    {/*            )} *!/*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="form-group">                                    
                                        <div className="chphonefield">
                                            {this.countryDropDownMenu()}
                                            <input type="text"
                                                className="custom-input form-control"
                                                pattern="[0-9]*"
                                                id="phone"
                                                style={this.state.validation.phone.error ? this.state.validation.phone.style : null}
                                                value={this.state.phone}
                                                data-tip={
                                                    this.state.validation.phone.error &&
                                                    this.state.validation.phone.message
                                                }
                                                onBlur={(e) => this.validate("phone", e.target.value)}
                                                onChange={(e) => this.validate("phone", e.target.value)}
                                                maxLength={maxLength}
                                                placeholder="Phone No. *" />
                                            <span className="login-feedback-error mt-1"
                                                style={{ display: "block" }}>{this.state.errors.countryCode}</span>
                                            <span
                                                className="login-feedback-error mt-1">{this.state.validation.countryCode && this.state.validation.countryCode.message}</span>
                                        </div>
                                        <small className="login-feedback-error mt-1"
                                            style={{ display: "block" }}>{this.state.errors.phone}</small>
                                        <small
                                            className="login-feedback-error mt-1">{this.state.validation.phone && this.state.validation.phone.message}</small>

                                    </div>
                                </div>
                                {error &&
                                    <span className="login-feedback-error" style={{ display: "block" }}> {error.message} </span>}
                                <button type="submit" className="login-btn btn btn-primary mt-1">Next</button>
                            </form>
                        </div>
                    </div>
                </div>

                <FooterDiv />
            </div>
        </section>

    }
}

export default OperatorInfo
