import React from 'react'
import {Link, NavLink} from "react-router-dom";
import {authService} from "../auth";
import axios from 'axios';
class LeftPanelSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            managePermission: false,
            isValidSuperAdminFlag: false,
            userId: this.props.userId ? this.props.userId : authService.getUserDetailsFromLocalStorage().userId,
            userType: this.props.userId && this.props.userId !== authService.getUserDetailsFromLocalStorage().userId ? "COMPANY" : authService.getUserDetailsFromLocalStorage().type
        }
    }

    getManagePermissionConfig = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/subscription/get/manage-permission?userId=' + this.state.userId ,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data  && response.data.status === '200 OK') {
                this.setState({managePermission:response.data.body});
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    checkValidSuperAdmin = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_userServiceURL}/profile/isvalidsuperAdmin?requestingUserId=${this.state.userData.userId}&companyId=${this.state.userId}` ,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data  && response.data.status === '200 OK') {
                this.setState({isValidSuperAdminFlag:response.data.body});
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    componentDidMount() {
        if(this.props.userId && this.props.userId !== this.state.userData.userId) {
            this.checkValidSuperAdmin();
        }
        this.getManagePermissionConfig();
    }

    render() {
        const { userId, userType, managePermission, isValidSuperAdminFlag, userData } = this.state;
        return <>

            <div className="col-md-3">
                <div className="leftpanel">
                    <div className="sidemenu-profedit" id="mainNav">
                        <ul>
                            {(isValidSuperAdminFlag || (userId === userData.userId)) &&
                                <>
                                    <li>
                                        <NavLink to={{pathname: "/security-and-login", state: {userId: userId}}} activeClassName="active">
                                            <i className="iSheild"/>Account Settings
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={{pathname: userType === "COMPANY" ? "/organization-privacy-settings" : "/privacy-settings", state: {userId: userId}}} activeClassName="active">
                                            <i className="iPrivacyLock"/>Privacy
                                        </NavLink>
                                    </li>
                                    {managePermission && 
                                        <li>
                                            <NavLink to={{pathname: "/manage-permissions", state: {userId: userId}}} activeClassName="active">
                                                <i className="iManage-Permissions" style={{fontSize: '18px'}} />Manage Permissions
                                            </NavLink>
                                        </li>
                                    }
                                </> 
                            }
                            <li>
                                <NavLink to={{pathname: "/manage-invitation", state: {userId: userId}}} activeClassName="active">
                                    <i className="iManage-Invitations" /> Manage Invitations
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{pathname: "/notification-setting", state: {userId: userId}}} activeClassName="active">
                                    <i className="iBell_notify" style={{fontSize: '16px'}} /> Manage Notification
                                </NavLink>
                            </li>
                            {(isValidSuperAdminFlag || (userId === userData.userId)) && 
                                <>
                                    <li>
                                        <NavLink to={{pathname: `/${this.state.userData.customUrl}/studio`, state: {userId: userId}}} className={this.props.sectionName === "STUDIO" ? "active" : ""}>
                                            <i className="istudio" style={{fontSize: '16px'}} /> Manage Studio
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={{pathname: "/private-projects", state: {userId: userId}}} activeClassName="active">
                                            <i className="iSheild_Lock" style={{fontSize: '14px'}} /> Private Projects
                                        </NavLink>
                                    </li>
                                </>
                            }
                            <li>
                                <NavLink to={{pathname: "/forum-poll-draft", state: {userId: userId}}} className={this.props.pageSection === "DRAFT" ? "active" : ""}>
                                    <i className="iDraft_F" style={{fontSize: '16px'}} /> Drafts
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{pathname: "/hidden-content", state: {userId: userId}}} activeClassName="active">
                                    <i className="iHidden-Content" style={{fontSize: '16px'}} /> Hidden Content
                                </NavLink>
                            </li> 
                            <li>
                                <NavLink to={{pathname: "/transactions", state: {userId: userId}}} activeClassName="active">
                                    <i className="iWallet" style={{fontSize: '16px'}} />Transactions
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{pathname: "/analytics", state: {userId: userId}}} activeClassName="active">
                                    <i className="ianalytics" style={{fontSize: '16px'}} /> Analytics
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{pathname: "/fund-raise", state: {userId: userId}}} activeClassName="active">
                                    <i className="iDonation-stats" style={{fontSize: '16px'}} /> Fund Raise Stats
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{pathname: "/saved-posts", state: {userId: userId}}} activeClassName="active">
                                    <i className="iBookmark_F" style={{fontSize: '16px'}} /> Saved items
                                </NavLink>
                            </li>  
                            <li>
                                <NavLink to={{pathname: "/blocked-users", state: {userId: userId}}} activeClassName="active">
                                    <i className="ideactivated" style={{fontSize: '16px'}} /> Blocked Users
                                </NavLink>
                            </li>                          
                            <li>
                                <NavLink to={{pathname: "/my-forum", state: {userId: userId}}} activeClassName="active">
                                    <i className="iForum_F" style={{fontSize: '16px'}} /> My Forum
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{pathname: "/my-poll", state: {userId: userId}}} activeClassName="active">
                                    <i className="iPolls_FL" style={{fontSize: '16px'}} /> My Poll
                                </NavLink>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>

        </>

    }
}

export default LeftPanelSettings
