import React, { Component } from 'react'
import $ from "jquery";
import axios from 'axios';
import { authService } from '../auth';
import uuid from 'react-uuid';
import { pointCalculationUpdate } from '../commons/CommonFunctions';

export default class CommonDeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            modalEntityId: this.props.entityId + uuid(),
            processingDeleteRequest: false
        }
    }

    deletePost = () => {
        this.setState({processingDeleteRequest: true})
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_userServiceURL}/backend/post/delete/${this.props.entityId}`,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === "Success!") {
                this.props.removePostListItem(this.props.entityId);
                pointCalculationUpdate(this.state.userData.userId);
                this.setState({processingDeleteRequest: false});
            } else {
                this.setState({processingDeleteRequest: false})
            }
        }).catch((err) => {
            console.log(err);
            this.setState({processingDeleteRequest: false})
        });
    }

    hideModal = () => {
        window.$("#"+this.state.modalEntityId+"confirmpopForDeletePost").modal("hide");
        this.props.triggerDeleteClose();
    }

    componentDidMount() {  
        window.$("#"+this.state.modalEntityId+"confirmpopForDeletePost").modal("show");
    }

    componentWillUnmount() {
        this.hideModal();
    }

    render() {
        const { modalEntityId, processingDeleteRequest } = this.state;
        return (
            <div className="modal fade dgpopup confirmpop"
                id={modalEntityId + "confirmpopForDeletePost"}
                tabIndex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static"
                data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" aria-label="Close" onClick={() => this.hideModal()}><span
                            aria-hidden="true">&times;</span></button>
                        <div className="confirm-popup-modal-body">Are you sure you want to delete this post?</div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary"
                                disabled={processingDeleteRequest}
                                onClick={() => this.deletePost()}>Yes
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={() => this.hideModal()}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
