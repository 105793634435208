import {call, put, takeEvery} from "redux-saga/effects";
import * as api from "../../api/projectHomePageAPI.js";
import {fetchEventOrganizer} from "../../api/projectHomePageAPI.js";

import {
    SET_COMPLETED_PROJECT,
    SET_FILTER_TRENDING_EVENTS,
    SET_RUNNING_PROJECT,
    SET_TOP_ASSIGNMENT_OPPORTUNITY,
    SET_TOP_ASSIGNMENT_PERFORMER,
    SET_TOP_COMPANY,
    SET_TOP_EVENT_ORGANIZERS,
    SET_TOP_EVENTS,
    SET_TOP_JOB,
    SET_TOP_JOB_RECRUITER,
    SET_TOP_RECRUITER,
    SET_TRAINING_TOP_ORGANIZERS,
    SET_TRAINING_TOP_TUTORS,
    SET_TRAINING_TOP_WORKSHOPS,
    SET_TRENDING_ASSIGNMENT,
    SET_TRENDING_EVENTS,
    SET_TRENDING_JOB,
    SET_TRENDING_OPPORTUNITY,
    SET_UPCOMING_PROJECT,
    setCompletedProjectOnSuccess,
    setRunningProjectOnSuccess,
    setShowAllTrendingEventOnSuccess,
    setTopAssignmentOpportunityOnSuccess,
    setTopAssignmentPerformerOnSuccess,
    setTopCompanyOnSuccess,
    setTopEventOnSuccess,
    setTopEventOrganizersOnSuccess,
    setTopJobRecruiterOnSuccess,
    setTopJobsOnSuccess,
    setTopRecruiterOnSuccess,
    setTrainingTopOrganizersOnSuccess,
    setTrainingTopTutorsOnSuccess,
    setTrainingTopWorkshopsOnSuccess,
    setTreadingOpportunityOnSuccess,
    setTrendingAssignmentOnSuccess,
    setTrendingFilterEventOnSuccess,
    setTrendingJobsOnSuccess,
    setUpcomingProjectOnSuccess,
} from "../../reducer/project/homePageReducer.js";
import {
    SET_CANCELLED_PROJECT,
    SET_TRENDING_TRAINING, setCancelledProjectOnSuccess,
    setTrendingTrainingOnSuccess
} from "../../reducer/project/homePageReducer";
import {authService} from "../../auth";

/** venture started */

function* loadRunningProject(action) {
    let filter = action.payload.filter || "timeline";
    let userActivityType = action.payload.userActivityType || "ALL";
    let projectFilterType = action.payload.projectFilterType || "ALL";
    let userId = action.payload.userId || authService.getUserDetailsFromLocalStorage().userId;
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(
            api.fetchVentureRunningProject,
            filter,
            userActivityType,
            projectFilterType,
            pageNo,
            pageSize,
            userId
        );
        yield put(setRunningProjectOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadCancelledProject(action) {
    let projectFilterType = action.payload.projectFilterType || "ALL";
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let userId = action.payload.userId || authService.getUserDetailsFromLocalStorage().userId;
        let type = action.payload.type === undefined ? "" : action.payload.type;
        let circleId = action.payload.circleId === undefined ? "" : action.payload.circleId;
        let response = yield call(
            api.fetchVentureCancelledProject,
            pageNo,
            pageSize,
            projectFilterType,
            userId,
            type,
            circleId
        );
        yield put(setCancelledProjectOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadCompletedProject(action) {
    let filter = action.payload.filter || "timeline";
    let userActivityType = action.payload.userActivityType || "ALL";
    let projectFilterType = action.payload.projectFilterType || "ALL";
    let userId = action.payload.userId || authService.getUserDetailsFromLocalStorage().userId;
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(
            api.fetchVentureCompletedProject,
            filter,
            userActivityType,
            projectFilterType,
            pageNo,
            pageSize,
            userId
        );
        yield put(setCompletedProjectOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadUpComingProject(action) {
    let filter = action.payload.filter || "timeline";
    let userActivityType = action.payload.userActivityType || "ALL";
    let projectFilterType = action.payload.projectFilterType || "ALL";
    let userId = action.payload.userId;
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(
            api.fetchVentureUpComingProject,
            filter,
            userActivityType,
            projectFilterType,
            pageNo,
            pageSize,
            userId
        );

        yield put(setUpcomingProjectOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchUpComingProject() {
    yield takeEvery(SET_UPCOMING_PROJECT, loadUpComingProject);
}

export function* watchCompletedProject() {
    yield takeEvery(SET_COMPLETED_PROJECT, loadCompletedProject);
}

export function* watchRunningProject() {
    yield takeEvery(SET_RUNNING_PROJECT, loadRunningProject);
}

export function* watchCancelledProject() {
    yield takeEvery(SET_CANCELLED_PROJECT, loadCancelledProject);
}

/** end of my venture */
function* loadOpportunity(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTrendingOpportunity, pageNo, pageSize);

        yield put(setTreadingOpportunityOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadShowAllTrendingEvents(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTrendingEvents, pageNo, pageSize);

        yield put(setShowAllTrendingEventOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTopCompanies(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTopCompanies, pageNo, pageSize);

        yield put(setTopCompanyOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTopRecruiter(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTopRecruiters, pageNo, pageSize);
        yield put(setTopRecruiterOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export default function* watchAddTrendingOpportunity() {
    yield takeEvery(SET_TRENDING_OPPORTUNITY, loadOpportunity);
}

export function* showallWatchTrendingEvents() {
    yield takeEvery(SET_TRENDING_EVENTS, loadShowAllTrendingEvents);
}

export function* watchSetTopCompany() {
    yield takeEvery(SET_TOP_COMPANY, loadTopCompanies);
}

export function* watchTopRecruiter() {
    yield takeEvery(SET_TOP_RECRUITER, loadTopRecruiter);
}

function* loadJobOpportunity(action) {
    try {

        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTrendingJobs,pageNo, pageSize);
        yield put(setTrendingJobsOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTopJob() {
    try {
        let response = yield call(api.fetchTopJobs);
        yield put(setTopJobsOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTopJobRecruiter() {
    try {
        let response = yield call(api.fetchTopJobsRecruiter);
        yield put(setTopJobRecruiterOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchAddTrendingJobs() {
    yield takeEvery(SET_TRENDING_JOB, loadJobOpportunity);
}

export function* watchAddTopJobs() {
    yield takeEvery(SET_TOP_JOB, loadTopJob);
}

export function* watchAddTopJobRecruiter() {
    yield takeEvery(SET_TOP_JOB_RECRUITER, loadTopJobRecruiter);
}

/** Training Watcher Start */

function* loadTrainingWorkshops(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTopWorkshopsCamp, pageNo, pageSize);
        yield put(setTrainingTopWorkshopsOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTrainingTopTutors(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTrainingTopTutors, pageNo, pageSize);
        yield put(setTrainingTopTutorsOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTrainingTopOrganizers(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTopOrganizations, pageNo, pageSize);
        yield put(setTrainingTopOrganizersOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchTrainingTopWorkshops() {
    yield takeEvery(SET_TRAINING_TOP_WORKSHOPS, loadTrainingWorkshops);
}

export function* watchTrainingTopTutors() {
    yield takeEvery(SET_TRAINING_TOP_TUTORS, loadTrainingTopTutors);
}

export function* watchTrainingTopOrganizers() {
    yield takeEvery(SET_TRAINING_TOP_ORGANIZERS, loadTrainingTopOrganizers);
}

/** Assignment Watcher Start */

function* loadTopAssignmentOpportunity(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTopAssignmentOpportunity, pageNo, pageSize);
        yield put(setTopAssignmentOpportunityOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTrendingAssignment(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTrendingAssignment, pageNo, pageSize);
        yield put(setTrendingAssignmentOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTrendingTraining(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.trendingTrainings, pageNo, pageSize);
        yield put(setTrendingTrainingOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTopAssignmentPerformer(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let response = yield call(api.fetchTopAssignmentPerformer, pageNo, pageSize);
        yield put(setTopAssignmentPerformerOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchTopAssignmentOpportunity() {
    yield takeEvery(SET_TOP_ASSIGNMENT_OPPORTUNITY, loadTopAssignmentOpportunity);
}

export function* watchTopAssignmentPerformer() {
    yield takeEvery(SET_TOP_ASSIGNMENT_PERFORMER, loadTopAssignmentPerformer);
}

export function* watchTrendingAssignment() {
    yield takeEvery(SET_TRENDING_ASSIGNMENT, loadTrendingAssignment);
}

export function* watchTrendingTraining() {
    yield takeEvery(SET_TRENDING_TRAINING, loadTrendingTraining);
}

/** Event Watcher Start */

function* loadTopEvents() {
    try {
        let response = yield call(api.fetchTopEvents);
        yield put(setTopEventOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTrendingEvents() {
    try {
        let response = yield call(api.fetchTrendingEventsforFilter);
        yield put(setTrendingFilterEventOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadTopEventOrganizers() {
    try {
        let response = yield call(fetchEventOrganizer);
        yield put(setTopEventOrganizersOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchAddTopEvents() {
    yield takeEvery(SET_TOP_EVENTS, loadTopEvents);
}

export function* watchAddTrendingEvents() {
    yield takeEvery(SET_FILTER_TRENDING_EVENTS, loadTrendingEvents);
}

export function* watchAddTopEventOrganizers() {
    yield takeEvery(SET_TOP_EVENT_ORGANIZERS, loadTopEventOrganizers);
}

/** Event Watcher Event */
