import React from "react";
import { Component } from "react";
import { authService } from "../../../auth";
import axios from "axios";
import _ from "lodash";
import { Link } from "react-router-dom";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Select from 'react-select';
import SendMessageModal from "../../../commons/SendMessageModal";
import { setProfileBasicInfoDetails } from "../../../reducer/profile/ProfileReducer";
import { connect } from "react-redux";

const ErorrMsgPopUp = withReactContent(alertPopUp);

class StudioPostPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            postForFree: this.props.popUpDetails.publishionForFree, 
            amount: this.props.popUpDetails.publishionForFree ? "" : this.props.popUpDetails.amount,
            isPublishDisabled: false,
            currencyType: this.props.popUpDetails?.currencyType ? this.props.popUpDetails?.currencyType : "INR",
            userBankDetailsData: [],
            bankId: "",
            showBankSection: false,
            selectedBankData: {},
            errors: {},
            openSendMessageModal: null
        }
    }

    onChangePublishValue = (value) => {
        this.setState({
            postForFree: value,
            amount: ""
        })
    }   

    handleChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                amount: event.target.value.trimStart(),
            });
        }
    }

    handleBankChange = (value) => {
        this.setState({
            selectedBankData: value
        })
    }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if(!this.state.postForFree && (_.isUndefined(this.state.amount) || _.isEmpty((this.state.amount || "").toString()) || _.isNull(this.state.amount))) {
            errors["amount"] = "Please enter amount.";
            formIsValid = false;
        }
        if(!this.state.postForFree && this.state.amount && this.state.amount < 1) {
            errors["amount"] = "Amount should be greater than 0.";
            formIsValid = false;
        }
        if(!this.state.postForFree && !this.state.selectedBankData && this.state.showBankSection) {
            errors["selectedBankData"] = "Please select bank";
            formIsValid = false;
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    }

    sendMessageButtonClick = () => {
        ErorrMsgPopUp.close();
        window.$("#paidProjPublishPop").modal("hide");
        this.setState({openSendMessageModal: this.props.projectDetail?.superAdminId});
    }

    triggerMessageModalClose = () => {
        this.setState({
            openSendMessageModal: null
        })
        this.props.triggerPublishPostPopUpClose();
    }

    submitStudioPostDataDetails = () => {
        if(!this.state.postForFree && this.validateForm()) {
            if(this.state.userBankDetailsData && this.state.userBankDetailsData.length === 0) {
                this.commonErrorMessageDetails();
            } 
            if(this.state.userBankDetailsData && this.state.userBankDetailsData.length > 1) {
                this.setState({
                    showBankSection: true
                })
            }
        }
    }

    checkStudioPostData = () => {
        if((!this.state.postForFree && this.validateForm())) {
            if(this.state.userBankDetailsData && this.state.userBankDetailsData.length === 0) {
                this.commonErrorMessageDetails();
            } else {
                this.submitStudioPostData();
            }  
        }
        if(this.state.postForFree) {
            this.submitStudioPostData();
        }
    };

    commonErrorMessageDetails = () => {
        ErorrMsgPopUp.fire({
            didOpen: () => {
                ErorrMsgPopUp.clickConfirm();
            }
        }).then((result) => {
            return ErorrMsgPopUp.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                title: this.props.projectDetail?.partyType === "CIRCLE" && this.props.projectDetail?.superAdminId !== this.state.userData.userId ? 
                    <><p>Please contact super admin to update bank details.</p><a href="javascript:void(0)" onClick={(e) => { e.preventDefault();  this.sendMessageButtonClick(); }}>Send Message</a></> : 
                    <><p>Please update your bank details.</p><a class="styleBtn" href={'/security-and-login'}>Update now</a></>,
            })
        })
    }

    checkStudioPublishDataSubmit = () => {
        if(this.validateForm()) {
            this.submitStudioPostData();
        }
    }

    submitStudioPostData = () => {
        this.setState({
            isPublishDisabled: true
        })
        let data = {
            postId: this.props.popUpDetails.id,
            publishionForFree: this.state.postForFree,
            amount: !this.state.postForFree ? parseInt(this.state.amount) : 0,
            bankId: !_.isEmpty(this.state.selectedBankData) ? this.state.selectedBankData.value : this.props.popUpDetails?.bankAccountId ? this.props.popUpDetails?.bankAccountId  : ((this.state.userBankDetailsData && this.state.userBankDetailsData.length === 1) || this.state.postForFree) ? (this.state.userBankDetailsData.length === 1 ? this.state.userBankDetailsData[0].value : null) : null
        }
        if(!this.state.postForFree) {
            data.currencyType= this.state.currencyType
        }
        /* if(!this.state.postForFree) {
            data.bankId = this.props.popUpDetails?.bankAccountId ? this.props.popUpDetails?.bankAccountId  : this.state.userBankDetailsData && this.state.userBankDetailsData.length === 1 ? this.state.userBankDetailsData[0].id : this.state.selectedBankData.value
        } */
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/backend/paidContentPost/updateAmount`,
            method: "POST",
            data: data,
            withCredentials: true,
        }).then((response) => {
            if ( response && response.data && response.data.message === "Success!") {
                this.props.triggerPublishPopUpClose(this.props.popUpDetails.id, this.state.postForFree, this.state.amount, data?.bankId, this.state.currencyType);
                this.setState({
                    isPublishDisabled: false,
                    amount: ""
                })
                window.$("#paidProjPublishPop").modal("hide");
            }
        }).catch((error) => {
            console.log(error)
            this.setState({
                isPublishDisabled: false
            })
        });
    }

    getBankDetailsDataDetails = () => {
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/backend/userBankDetail/getUserBankDetails?userId=${this.props.userId}&metaDataRequired=false`,
            method: "GET",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.body && response.data.message === "Success!") {
                let data = response.data.body;
                let userBankData = [];
                data && data.map((item) => {userBankData.push({"value": item.id, "label": item.institutionName})})
                let selectedBankDataDetails = {};
                if(this.props.popUpDetails?.bankAccountId) {
                    selectedBankDataDetails = data && data.find((item) => item.id === this.props.popUpDetails?.bankAccountId);
                } else {
                    selectedBankDataDetails = data && data.find((item) => item.primary);
                }
                this.setState({
                    userBankDetailsData: userBankData,
                    selectedBankData: !_.isEmpty(selectedBankDataDetails) ? {"value": selectedBankDataDetails.id, "label": selectedBankDataDetails.institutionName} : {}
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    onChangeCurrencyType = (currencyType) => {
        this.setState({ currencyType });
    }

    componentWillUnmount() {
        window.$("#paidProjPublishPop").modal("hide");
    }

    componentDidMount() {
        this.getBankDetailsDataDetails();
        if(this.props.projectDetail?.partyType === "CIRCLE" && this.props.projectDetail?.superAdminId !== this.state.userData.userId) {
            this.props.profileBasicInfo({ userId: this.props.projectDetail?.superAdminId });
        }
    }


    render() {
        const { postForFree, isPublishDisabled, errors, amount, userBankDetailsData, selectedBankData, showBankSection, openSendMessageModal } = this.state;
        return (
            <>
                <div id="paidProjPublishPop" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="paidProjPublishPop">
                                    <button type="button" className="close topCrossModal" data-dismiss="modal" aria-label="Close" onClick={() => this.props.triggerPublishPostPopUpClose()}>
                                        <i className="iCross" /></button>
                                    <div class="paidProjPublishPopHeader"><h2>Assign cost to your content</h2></div>
                                    <div className="paidProjRadioWrap">
                                        {!showBankSection ?
                                            <div>
                                                <div className="warning"><p>Publish your content for a Paid or Free viewing. One can change the status later.</p></div>
                                                <label className="radio-container" onClick={(e) => this.onChangePublishValue(false)}>
                                                    <input type="radio" name="radio" checked={!postForFree} />
                                                    <span className="radio-checkmark"></span>
                                                    <h3>Cost of Content</h3>
                                                    <div className="amtInput">
                                                        <div className="amtSymDrop">
                                                            <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">{this.state.currencyType === 'INR' ? <>&#8377;</> : <>&#36;</>}</button>
                                                            <div className="dropdown-menu fadeIn animated">                                        
                                                                <div className="dropdown-item dropdown-submenu" onClick={() => this.onChangeCurrencyType("INR")}>₹</div>
                                                                <div className="dropdown-item dropdown-submenu" onClick={() => this.onChangeCurrencyType("USD")}>$</div>
                                                            </div>
                                                        </div>
                                                        <input type="text" className="form-control custom-input" placeholder="Add amount" value={amount} onChange={this.handleChange} disabled={postForFree} />
                                                        {errors["amount"] && <span className="login-feedback-error mt-1" style={{ display: "block" }}>{errors["amount"]}</span>}
                                                    </div>
                                                </label>
                                                <div className="orline"><span>Or</span></div>
                                                <label className="radio-container"  onClick={(e) => this.onChangePublishValue(true)}>
                                                    <input type="radio" name="radio" checked={postForFree} />
                                                    <span className="radio-checkmark"></span>
                                                    <h3>Publish as Free</h3>
                                                </label>
                                            </div> :
                                            <div className='form-group'>
                                                <label>Select Bank</label>
                                                <Select
                                                    placeholder={"Select"}
                                                    className="bankforSelect"
                                                    name={"bankforSelect"}
                                                    options={userBankDetailsData}
                                                    blurInputOnSelect={false}
                                                    value={selectedBankData}
                                                    onChange={this.handleBankChange}
                                                    styles={{
                                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                            const color = '#cfe7c7';
                                                            return {
                                                                ...styles,
                                                                backgroundColor: isDisabled
                                                                    ? null
                                                                    : isSelected
                                                                        ? '#cfe7c7'
                                                                        : isFocused
                                                                            ? '#cfe7c7'
                                                                            : null,
                                                                color: isDisabled
                                                                    ? '#ccc'
                                                                    : isSelected
                                                                        ? color > 2
                                                                            ? '#00374d'
                                                                            : '#00374d'
                                                                        : data.color,
                                                                cursor: isDisabled ? 'not-allowed' : 'default',

                                                                ':active': {
                                                                    ...styles[':active'],
                                                                    backgroundColor: !isDisabled && (isSelected ? '#cfe7c7' : '#cfe7c7'),
                                                                },
                                                            };
                                                        },
                                                    }}
                                                />
                                                {errors["selectedBankData"] && <span className="login-feedback-error mt-1" style={{ display: "block" }}>{errors["selectedBankData"]}</span>}
                                            </div>
                                        }
                                    </div>
                                    
                                    <div className="forwcontwrapbott">
                                        {userBankDetailsData && userBankDetailsData.length === 0 && 
                                            <p>Add your <Link to='/security-and-login'>Bank details</Link></p>
                                        }
                                        <div>
                                            {!showBankSection ? 
                                                <button className="btn" disabled={isPublishDisabled} onClick={() => (postForFree || userBankDetailsData.length === 1/*  || this.props.popUpDetails?.formType === "Update" */) ? this.checkStudioPostData() : this.submitStudioPostDataDetails()}>{(postForFree || userBankDetailsData.length === 1/*  || this.props.popUpDetails?.formType === "Update" */) ? "Publish" : "Next"}</button> : 
                                                <button className="btn" disabled={isPublishDisabled} onClick={() => this.checkStudioPublishDataSubmit()}>Publish</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {openSendMessageModal &&
                    <SendMessageModal profileId={this.props.projectDetail?.superAdminId} userName={this.props.profileBasicInfoData?.username} triggerMessageModalClose={this.triggerMessageModalClose}/>
                }
            </>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profileBasicInfoData: state.ProfileReducer.profileBasicInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileBasicInfo: (state) => dispatch(setProfileBasicInfoDetails(state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudioPostPopUp);