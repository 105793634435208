import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { getProjectDetails, setProjectDetails } from "../../../reducer/showAllReducer";
import axios from "axios";
import dateUtil from "date-and-time";
import mappingUtils from "../commons/utils/mappingUtils";
import * as _ from "lodash";
import { authService } from "../../../auth";
import Share from "../../../commons/Share";
import { Redirect, withRouter } from "react-router-dom";

import httpService from '../../../AxiosInterceptors';
import { setProjectAppliedOnSuccess } from "../../../reducer/project/homePageReducer";
import Pinned from "../../../commons/Pinned";
import { setPref } from "../../../reducer/userPref";
import Follow from "../../../commons/Follow";
import ReportAbuse from "../../../commons/ReportAbuse";
import * as FileSaver from "file-saver";
import Linkify from 'react-linkify';
import { blockUserMessage, convertUnicode, getCDNUrl, getFileIcon, getVolunterProjectName, isWebView, postMsg, redirectToProfilePage, renderTaggedUsers } from "../../../commons/CommonFunctions";
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import uuid from "react-uuid";
import { convertToFirstCapital } from "../../../utils/StringUtility";
import DeactivateErrorPopUp from "../../../commons/DeactivateErrorPopUp";
import { renderToString } from "react-dom/server";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { actionProjectDonationSwal } from "../../../Donation/DonationCommonFunction";
import SavePostButton from "../../../post/SavePostButton";
import CleverTapUtils from "../../../commons/CleverTapUtils";

const ErorrMsgPopUp = withReactContent(alertPopUp);

httpService.setupInterceptors();

const mapDispatchToProps = (dispatch) => {
    return {
        getProjectDetails: () => dispatch(getProjectDetails()),
        setProjectDetails: (details) => dispatch(setProjectDetails(details)),
        setProjectAppliedOnRefresh: (value) =>
            dispatch(setProjectAppliedOnSuccess(value)),
        getPref: () => dispatch(setPref()),
    };
};

const ProjectDetails = (props) => {
    const [lastDateOfApplication, setLastDateOfApplication] = useState("");
    const [startDate, setStartDate] = useState("");
    const [createdOn, setCreatedOn] = useState("");
    const [currency, setCurrency] = useState("");
    const [packageType, setPackageType] = useState("");
    const [coverImage, setCoverImage] = useState('https://cdn.dscovr.com/images/project-ico2.png');
    const [endDate, setEndDate] = useState("");
    const [applyErrorMessage, setApplyErrorMessage] = useState('Your profile is undergoing deactivation process. During this period you will not be able to apply a project');
    const [applyBtnActivated, setApplyBtnActivation] = useState(true);
    const [isAutoApproveApplicable, setAutoApproveApplicable] = useState(true);
    const [isUserAlreadyApplied, setUserAlreadyApplied] = useState(false);
    const [canWithDraw, setCanWithDraw] = useState(false);
    const [canApply, setCanApply] = useState(true);
    const [canHide, setCanHide] = useState(true);
    const [isApprover, setIsApprover] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [attach, setAttach] = useState([]);
    const [users, setUsers] = useState({});
    const [facultiesValue, setFaculty] = useState({});
    const [contactInfoValue, setContactInfo] = useState({});
    const [formValue, setFormValue] = useState({});
    const [apply, setApply] = useState(false);
    const [projectId, setProjectId] = useState({});
    const [seeAllApplicantView, setSeeAllApplicantView] = useState(false);
    const [openReportModal, setOpenReportModal] = useState(null);
    const close = useRef();

    const setProjectDetails = function (projectDetails) {
        props.setProjectDetails(projectDetails);
    };

    const nFormatter = (num, digits) => {
        if (!num) {
            return num
        }
        var si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "k" },
            { value: 1E6, symbol: "M" },
            { value: 1E9, symbol: "G" },
            { value: 1E12, symbol: "T" },
            { value: 1E15, symbol: "P" },
            { value: 1E18, symbol: "E" }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }

    const getOptimizedImage = (personalInfo, defaultImg) => {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return defaultImg;
    }


    useEffect(() => {
        let projectDetails = props.proj.projectDetails;
        let id = projectDetails && projectDetails.project && projectDetails.project.id
        setProjectId(id);
        projectDetails["id"] = id;
        if (
            projectDetails &&
            (projectDetails.lastDateOfApplication || projectDetails.lastDateOfApplication === 0) &&
            !_.isUndefined(projectDetails.endDate) &&
            projectDetails.startDate
        ) {
            if (lastDateOfApplication === '1 Jan 1970' || lastDateOfApplication === 'NA') {
                setLastDateOfApplication('NA');
            } else {
                setLastDateOfApplication(
                    dateUtil.format(
                        new Date(projectDetails.lastDateOfApplication),
                        "DD MMM YYYY"
                    )
                );
            }

            if (projectDetails.endDate > 0) {
                setEndDate(
                    dateUtil.format(new Date(projectDetails.endDate), "DD MMM YYYY")
                );
            } else {
                setEndDate('NA');
            }
            if (projectDetails.coverImage && !_.isNull(projectDetails.coverImage)) {
                setCoverImage(projectDetails.coverImage);
            }


            setStartDate(
                dateUtil.format(new Date(projectDetails.startDate), "DD MMM YYYY")
            );

            let project = projectDetails;

            if (project && project.project && !_.isNull(project && project.project.currency)) {
                setCurrency(project.project.currency);
            }

            if (project && project.project && !_.isNull(project && project.project.createTime)) {
                setCreatedOn(
                    dateUtil.format(new Date(project.project.createTime), "DD MMM YYYY")
                );
            }

            if (project && project.project && !_.isNull(project && project.project.packageType)) {
                setPackageType(project.project.packageType);
            }

            let creatorName = project.creatorName;
            let creatorCustomUrl = project.customUrl;

            let creatorImageUrl = project.imageUrl;
            if (project.resizedProfileImages && project.resizedProfileImages['200x200'] != null) {
                creatorImageUrl = project.resizedProfileImages['200x200'];
            }

            let demolished = project.demolished;
            let attachments = [];
            if (project && project.project && project.project.attachments && project.project.attachments.length > 0) {
                let attachedFiles = project.project.attachments;
                attachedFiles.map((data, index) => {
                    let value = {
                        name: data.substring(data.lastIndexOf('/') + 1),
                        url: data
                    }
                    attachments.push(value);

                })
            }


            if (project && project.faculties) {
                project.faculties.map((f) => fetchUserInfo(f));
            }

            setFaculty(project && project.faculties);
            setContactInfo(project && project.contactInfo);

            // setAttach(attachments);
            setAutoApproveApplicable((project && project.isAutoApproved) || (project && project.project && project.project.autoApproved));
            setUserAlreadyApplied(
                projectDetails.isUserAlreadyApplied || projectDetails.isApproved
            );
            setCanWithDraw(projectDetails.canWithdraw);
            setCanApply(project && project.canApply)
            setCanHide(project && project.canHide);
            setIsApprover(projectDetails.isApprover);
            setIsLoaded(props.proj.isLoaded);

            let proj = props.proj.projectDetails && props.proj.projectDetails.project;

            let location = proj && proj.location;
            let title = proj && proj.title;
            let fees = proj && proj.cost;
            let role = proj && proj.role;
            let shortBrief = proj && (proj.shortBrief);
            let organizationPostedFor = proj && proj.organizationPostedFor;
            let companyName = proj && proj.companyName;
            let isNegotiable = proj && proj.isNegotiable;
            let isDisclosed = proj && proj.isDisclosed;
            let websiteLink = proj && proj.websiteLink;
            let noOfOpening = projectDetails.noOfOpenings;
            let noOfParticipants = projectDetails.noOfParticipants;
            let deactivationRequestSent = projectDetails.deactivationRequestSent;
            let skills = proj && proj.skills;
            let isOffLine = projectDetails.isOffLine;
            let specialization = proj && proj.specialization;
            let inclusions = proj && proj.inclusions;
            let exclusions = proj && proj.exclusions;
            let faculties = proj && project.faculties;
            let qualifications = proj && (proj.qualifications || proj.qualificationRequired);
            let endTakeAway = proj && proj.endTakeAway;
            let mediaAttachments = proj && proj.mediaAttachments;
            let description = proj && proj.description;
            let showProjectType;
            let projectType = projectDetails && projectDetails.participationDetails && projectDetails.participationDetails.projectType;
            let secondaryEntityType = projectDetails && projectDetails.participationDetails && projectDetails.participationDetails.secondaryEntityType;
            if (secondaryEntityType && projectType && !_.isNull(secondaryEntityType) && !_.isNull(projectType)) {
                if (projectType.toUpperCase() === 'VOLUNTEER') {
                    showProjectType = getVolunterProjectName(secondaryEntityType);
                } else {
                    showProjectType = projectType;
                    if (projectType === 'FUNDRAISE') {
                        showProjectType = "FUND RAISE";
                    } else if (projectType === 'STORYBOOK') {
                        showProjectType = "STORY BOOK";
                    }

                }

            } else {
                showProjectType = projectType;
                if (projectType === 'FUNDRAISE') {
                    showProjectType = "FUND RAISE";
                } else if (projectType === 'STORYBOOK') {
                    showProjectType = "STORY BOOK";
                }
            }
            //let id = proj && proj.id;
            let participationCount = projectDetails && projectDetails.participationDetails &&
                projectDetails.participationDetails.noOfParticipant;
            let dateOfParticipation = dateUtil.format(new Date(projectDetails.dateOfPosting), "DD MMM YYYY");
            let noOfDaysPosted = mappingUtils.getNoOfDaysAfterPosted(
                dateOfParticipation
            );
            let formValue = {
                location,
                organizationPostedFor,
                description,
                mediaAttachments,
                deactivationRequestSent,
                fees,
                shortBrief,
                noOfDaysPosted,
                showProjectType,
                title,
                creatorName,
                creatorCustomUrl,
                creatorImageUrl,
                demolished,
                websiteLink,
                role,
                jobRole,
                attachments,
                companyName,
                isNegotiable,
                participationCount,
                isDisclosed,
                projectType,
                qualifications,
                inclusions,
                exclusions,
                endTakeAway,
                noOfOpening,
                noOfParticipants,
                isOffLine,
                skills,
                specialization,
                faculties,
                secondaryEntityType,
                projectDetails,
                id
            };

            setFormValue(formValue);
        }

        return () => {
            setAutoApproveApplicable(false);
            setUserAlreadyApplied(false);

            const getAppDates = function (type, projectId) {
                if (type && projectId) {
                    let url = null;

                    if (type == "VOLUNTEER") {
                        url =
                            process.env.REACT_APP_userServiceURL +
                            `/backend/job/volunteer-and-projectType/${projectId}`;
                    } else {
                        type = type.toLowerCase();
                        url =
                            process.env.REACT_APP_userServiceURL +
                            `/backend/${type}/volunteer-and-projectType/${projectId}?pType=${type}`;
                    }
                    axios({
                        method: "get",
                        url: url,
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    })
                        .then((response) => {
                            let proj = response.data.body;
                            let lastdate = new Date(
                                proj.lastDateOfApplication.substring(0, 10)
                            );
                            let startdate = new Date(proj.beginningDate.substring(0, 10));
                            setLastDateOfApplication(dateUtil.format(lastdate, "DD MMM YYYY"));
                            setStartDate(dateUtil.format(startdate, "DD MMM YYYY"));

                            if (proj.endingDate && !_.isNull(proj.endingDate)) {
                                let enddate = new Date(proj.endingDate.substring(0, 10));
                                setEndDate(dateUtil.format(enddate, "DD MMM YYYY"));
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            };


            props.getProjectDetails();

            let {
                location,
                organizationPostedFor,
                description,
                fees,
                shortBrief,
                noOfDaysPosted,
                title,
                websiteLink,
                creatorName,
                creatorCustomUrl,
                creatorImageUrl,
                demolished,
                attachments,
                role,
                jobRole,
                companyName,
                isNegotiable,
                participationCount,
                isDisclosed,
                projectType,
                qualifications,
                inclusions,
                exclusions,
                endTakeAway,
                noOfOpening,
                noOfParticipants,
                skills,
                specialization,
                faculties,
                secondaryEntityType,
                projectDetails,
                id,
            } = props.proj;

            if (websiteLink) {
                websiteLink[0] = websiteLink[0].replace("\\", "").replace("\\", "");
            }

            setIsApprover(false);
            setIsLoaded(false);
        };
    }, [props.proj.projectDetails]);


    const download_file_pdf = (evt, url) => {
        evt.preventDefault();
        //let filename = this.getFileNameFromUrl(url);
        var parsedUrl = url.substring(url.lastIndexOf("/") + 1)
        let filename = parsedUrl.substring(parsedUrl.indexOf("-") + 1);

        FileSaver.saveAs(url, filename);

    }


    const fetchUserInfo = (userId) => {
        if (userId && users[userId] == undefined) {
            axios({
                method: "get",
                url:
                    process.env.REACT_APP_userServiceURL + `/user/userDetails/${userId}`,
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
                .then((response) => {
                    let userInfo = response.data.body;
                    setUsers({
                        ...users,
                        [userId]: {
                            firstName: userInfo.firstName,
                            lastName: userInfo.lastName,
                        },
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        props.getProjectDetails();
    }, []);

    let {
        location,
        organizationPostedFor,
        description,
        fees,
        shortBrief,
        noOfDaysPosted,
        title,
        websiteLink,
        role,
        jobRole,
        companyName,
        creatorName,
        creatorCustomUrl,
        creatorImageUrl,
        demolished,
        attachments,
        mediaAttachments,
        deactivationRequestSent,
        isNegotiable,
        participationCount,
        isDisclosed,
        projectType,
        showProjectType,
        qualifications,
        inclusions,
        exclusions,
        endTakeAway,
        noOfOpening,
        noOfParticipants,
        isOffLine,
        skills,
        specialization,
        contactInfo,
        faculties,
        secondaryEntityType,
        projectDetails,
        id,
    } = props.proj;

    if (websiteLink) {
        websiteLink[0] = websiteLink[0].replace("\\", "").replace("\\", "");
    }


    const changeValuePinned = (item) => {
        let data = item.pinned;
        item.pinned = !data;
        props.setProjectDetails(item);
        props.setProjectAppliedOnRefresh({ apply: true });
    }

    const openConfirmpop = () => {
        setApply(false);
        CleverTapUtils.eventClick({}, "Apply for " + showProjectType);
        if (deactivationRequestSent) {
            window.$('#deactivationProcessError').modal('show')
        } else {
            window.$('#confirmpopApply' + projectDetails.projectId).modal('show')
        }
    }

    const redirectToProjectPage = (slug) => {
        window.location.href = `/project/feeds/${slug}`;
    }

    const openConfirmpopForWithDrawl = () => {
        if (deactivationRequestSent) {
            window.$('#deactivationProcessError').modal('show')
        } else {
            window.$('#confirmpopForWithdrawl' + projectDetails.projectId).modal('show')
        }

    }

    const hidemodal = () => {
        window.$('#confirmpopApply' + projectDetails.projectId).modal('hide')
    }

    const hideWithDrawlModal = () => {
        window.$('#confirmpopForWithdrawl' + projectDetails.projectId).modal('hide')
    }


    const applyInProject = (type) => {
        let body = {
            participantUserId: authService.getUserDetailsFromLocalStorage().userId,
            participantionPartyType: "INDIVIDUAL_OR_RECRUITER",
            projectId: projectId,
        };
        let url = "";
        type = type.toLowerCase();
        if (type == "volunteer") {
            url =
                process.env.REACT_APP_userServiceURL +
                `/backend/job/apply-in-project?isVolunteerProject=true`;
        } else {
            url =
                process.env.REACT_APP_userServiceURL +
                `/backend/${type}/apply-in-project?isVolunteerProject=false`;
        }

        axios({
            method: "post",
            data: body,
            url: url,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                //this.setState({'applyBtnActivated':false});
                let item = props.proj.projectDetails;
                item.applied = true;
                props.setProjectDetails(item);
                props.setProjectAppliedOnRefresh({ apply: true });
                props.getPref();
                setApply(true);
                if (isAutoApproveApplicable) {
                    window.$('#confirmpopApply' + projectDetails.projectId).modal('hide')
                }
            })
            .catch((error) => {
                setApplyBtnActivation(true);
                if (error?.response?.data?.status === "409 CONFLICT") {
                    window.$('#confirmpopApply' + projectDetails.projectId).modal('hide')
                    blockUserMessage("MESSAGE", error.response.data.message);
                }
                console.log(error);
            });
    };

    const getHideProject = (projectId, entityType, projectDetails) => {
        if (projectDetails && !_.isEmpty(projectDetails.donation) && (projectDetails.project?.creatorUserId === authService.getUserDetailsFromLocalStorage().userId)) {
            const privateConfirm = async () => {
                const swalval = await ErorrMsgPopUp.fire(actionProjectDonationSwal("HIDE"));
                if (swalval && swalval.value === true) {
                    user_own_hide_config(projectId, entityType);
                }
            };
            privateConfirm();
        } else {
            user_own_hide_config(projectId, entityType);
        }
    }

    const user_own_hide_config = (projectId, entityType) => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/participation/isparticipant-available?projectId=${projectId}`,
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response && response.data && response.data.status === '200 OK') {
                    if (projectDetails.creatorUserId === authService.getUserDetailsFromLocalStorage().userId && response.data.body) {
                        window.$('#confirm' + projectId).modal('show');
                    } else {
                        handleHideModal(projectId, entityType);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };




    const handleHideModal = function (id, entityType) {
        let data = {
            userId: authService.getUserDetailsFromLocalStorage().userId,
            activityId: id,
            entityType: entityType,
        }
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/hidden/hide',
            data: data,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 201) {
                window.location.href = 'project';
            } else {
                console.log(response);
            }
        }).catch((err) => {

            if (err && err.response && err.response.status === 409) {
                console.log(err);
            } else {
                console.log(err);
            }
        });
    }


    const refreshAllData = () => {
        //     this.props.refreshData();
    }



    const withDrawParticipantRequest = (type) => {
        let body = {
            otherParticipatingUserId: authService.getUserDetailsFromLocalStorage().userId,
            projectId: projectId,
        };

        let url =
            process.env.REACT_APP_userServiceURL +
            `/backend/participation/withdraw`;


        axios({
            method: "post",
            data: body,
            url: url,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                //this.setState({'applyBtnActivated':false});
                let item = props.proj.projectDetails;
                item.applied = false;
                item.canWithdraw = false;
                props.setProjectDetails(item);
                props.setProjectAppliedOnRefresh({ apply: true });
                props.getPref();
                window.$('#confirmpopForWithdrawl' + projectDetails.projectId).modal('hide')

            })
            .catch((error) => {
                setApplyBtnActivation(true);
                console.log(error);
            });
    };

    const triggerReportClose = () => {
        setOpenReportModal(null);
    }

    const redirectToDetailsPage = (slug) => {
        window.location.href = `/project/feeds/${slug}`
    }

    const getBody = () => {
        contactInfo = contactInfo || contactInfoValue;
        faculties = faculties || facultiesValue;
        location = location || formValue.location;
        organizationPostedFor =
            organizationPostedFor || formValue.organizationPostedFor;
        description = description || formValue.description;
        fees = fees || formValue.fees;
        shortBrief = shortBrief || formValue.shortBrief;
        noOfDaysPosted = noOfDaysPosted || formValue.noOfDaysPosted;
        title = title || formValue.title;

        websiteLink = websiteLink || formValue.websiteLink;
        if (websiteLink && websiteLink != null) {
            websiteLink[0] = websiteLink[0].replace("\\", "").replace("\\", "");
        }
        role = role || formValue.role;
        jobRole = jobRole || formValue.jobRole;
        companyName = companyName || formValue.companyName;
        isNegotiable = isNegotiable || formValue.isNegotiable;
        participationCount = participationCount || formValue.participationCount;
        isDisclosed = isDisclosed || formValue.isDisclosed;
        projectType = projectType || formValue.projectType;
        showProjectType = showProjectType || formValue.showProjectType;
        qualifications = qualifications || formValue.qualifications;
        inclusions = inclusions || formValue.inclusions;
        exclusions = exclusions || formValue.exclusions;
        endTakeAway = endTakeAway || formValue.endTakeAway;
        noOfOpening = noOfOpening || formValue.noOfOpening;
        noOfParticipants = noOfParticipants || formValue.noOfParticipants;
        isOffLine = isOffLine || formValue.isOffLine;
        skills = skills || formValue.skills;
        specialization = specialization || formValue.specialization;
        faculties = faculties || formValue.faculties;
        secondaryEntityType = secondaryEntityType || formValue.secondaryEntityType;
        projectDetails = projectDetails || formValue.projectDetails;
        id = id || formValue.id;
        creatorName = creatorName || formValue.creatorName;
        attachments = attachments || formValue.attachments;
        mediaAttachments = mediaAttachments || formValue.mediaAttachments;
        deactivationRequestSent = deactivationRequestSent || formValue.deactivationRequestSent;
        creatorCustomUrl = creatorCustomUrl || formValue.creatorCustomUrl;
        creatorImageUrl = creatorImageUrl || formValue.creatorImageUrl;
        demolished = demolished || formValue.demolished;
        let typeContent;
        if (secondaryEntityType === 'EVENT') {
            typeContent = "Mementos or Event Kits"
        } else if (secondaryEntityType === 'JOB') {
            typeContent = "Remarks"
        } else if (secondaryEntityType === 'ASSIGNMENT') {
            typeContent = "Certification or Accolades"
        } else if (secondaryEntityType === 'TRAINING') {
            typeContent = "Certification or Accolades"
        } else if (secondaryEntityType === 'EXPEDITION' || secondaryEntityType === 'FUNDRAISE') {
            typeContent = "Mementos or Event Kits"
        }

        let linkfiedHtml = "<p class=\"pdtlsLink\">" + renderToString(<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
            </a>
        )}>{location && location.address
            ? location.address.replace(/<br\/>/g, '\n').replace(/&nbsp;/g, ' ')
            : ''}</Linkify>).replace(/&nbsp;/g, ' ')
            .replace(/(\r\n|\r|\n)/g, '<br>')
            .replace(/(\\n)/g, '<br>') + "</p>";
        return (

            <div className="projectdescwrap">
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={close}
                    onClick={(e) => {
                        setAutoApproveApplicable(false);
                        setUserAlreadyApplied(false);
                        setIsApprover(false);
                        setIsLoaded(false);
                        setApplyBtnActivation(true);
                    }}
                >
                    <i className="iCross"></i>
                </button>
                <div className="projdestopbar">
                    <h1>{showProjectType}</h1>
                    <ul>
                        {demolished === false &&
                            <li className="posted">
                                <div className="contxmenu">
                                    <button type="button" className="btn" data-toggle="dropdown">
                                        <i className="iKebab" />
                                    </button>

                                    <div className="dropdown-menu fadeIn animated">
                                        {props.location?.pathname !== "/saved-posts" && <SavePostButton key={uuid()} id={projectDetails.projectId} bookMarkFlag={projectDetails.bookMark} entityType={"PROJECT"} />}
                                        {projectDetails.pinned !== true && canHide &&
                                            <a className="dropdown-item" href="javascript:void(0)"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    getHideProject(projectDetails.projectId, projectDetails.participationDetails.secondaryEntityType, projectDetails)
                                                }}><i className="iHide" style={{ fontSize: '13px' }} /> Hide</a>}
                                        {((projectDetails && projectDetails.participationDetails && projectDetails.participationDetails.creatorUserId != authService.getUserDetailsFromLocalStorage().userId
                                            && projectDetails.project && projectDetails.project.userId != authService.getUserDetailsFromLocalStorage().userId) &&
                                            ((projectDetails.companyIds && !projectDetails.companyIds.includes(projectDetails.project.userId)) || !projectDetails.companyIds)) &&
                                            <a className="dropdown-item" href="javascript:void(0)" onClick={(e) => {
                                                e.preventDefault();
                                                setOpenReportModal(projectDetails.projectId);
                                            }}><i className="iReportComment_OL" /> Report</a>
                                        }
                                    </div>
                                    {
                                        openReportModal && openReportModal === projectDetails.projectId && projectDetails.participationDetails && projectDetails.participationDetails.secondaryEntityType &&
                                        <ReportAbuse entityId={projectDetails.projectId}
                                            entityType={projectDetails.participationDetails.secondaryEntityType}
                                            refreshAllData={refreshAllData}
                                            triggerReportClose={triggerReportClose} />
                                    }
                                </div>

                                <span>Posted on  {createdOn}</span>

                            </li>
                        }
                        {demolished === false &&
                            <li>
                                {projectDetails && projectDetails.participationDetails && projectDetails.participationDetails.secondaryEntityType &&
                                    <Share shareUrl="/project/feeds/"
                                        entityId={projectDetails.projectId}
                                        sharingSetting={projectDetails.sharingSettingConfigDto}
                                        slug={projectDetails.slug}
                                        sharesCount={projectDetails.shareCount}
                                        entityType={projectDetails.participationDetails.secondaryEntityType}
                                        shareId={projectDetails.projectId} />
                                }
                            </li>
                        }
                        {demolished === false &&
                            <li>
                                <button type="button" className="btn btnpin">
                                    <li>
                                        {projectDetails && projectDetails.participationDetails &&
                                            <Pinned item={projectDetails}
                                                entityType={projectDetails.participationDetails.secondaryEntityType}
                                                callback={changeValuePinned} callbackRequired={true} />
                                        }
                                    </li>
                                </button>
                            </li>
                        }
                    </ul>
                </div>

                <div className="projdesheader">
                    <div className="row">
                        <div className="col-lg-4 jobsblk">
                            <span>Title</span>
                            <a href="javascript:void(0)" onClick={(e) => showProjectType !== "JOB" ? redirectToDetailsPage(formValue.projectDetails && formValue.projectDetails.slug) : "javascript:void(0)"}>
                                <h4>{unescape(title)}</h4>
                            </a>
                            <p className="addRss">
                                <i className="iLocation"></i>
                                <span>{location && location.city},{" "}
                                    {location && location.state}, {location && location.country}</span>

                            </p>

                            {/*<p>
                                <i className="iProjects_F"></i> Botanist, Trekker
                            </p>*/}
                            {isDisclosed == false && fees > 0 &&
                                <div className="price">
                                    <i className="iWallet"></i>{" "}
                                    {currency}{" "} {nFormatter(fees, 2)} {" "} {!_.isNull(packageType) && !_.isUndefined(packageType) && packageType.toLowerCase() !== 'custom' && packageType}
                                    <span>{isNegotiable && "(Negotiable)"}</span>
                                </div>
                            }
                        </div>
                        <div className="col-lg-4 recruiter">
                            <span>Creator</span>
                            <div className="recruitblk">
                                {creatorName &&
                                    <div className="recruit" onClick={(e) => {
                                        e.preventDefault();
                                        redirectToProfilePage(creatorCustomUrl, projectDetails.partyType)
                                    }}>
                                        <figure className="complogo" onClick={(e) => {
                                            e.preventDefault();
                                            redirectToProfilePage(creatorCustomUrl, projectDetails.partyType)
                                        }} style={{ cursor: 'pointer' }}>
                                            <img
                                                src={!_.isUndefined(creatorImageUrl) && !_.isNull(creatorImageUrl) ? getCDNUrl(creatorImageUrl) : (projectDetails.partyType == "CIRCLE" ? "https://cdn.dscovr.com/images/circleDefault.webp" : projectDetails.userType == "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                                onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/circleDefault.png" }} alt="" />
                                        </figure>
                                        <h5 className={"creatorname" + (projectDetails.userType === "COMPANY" ? " orgnzName" : "")} style={{ cursor: 'pointer' }}>{creatorName}</h5>
                                    </div>
                                }
                            </div>
                            {companyName && !_.isNull(companyName) && companyName !== 'null' && (
                                <>
                                    <span>Organization</span>
                                    <div className="recruit">
                                        {/*<figure className="complogo">
                                        <img src={coverImage} alt=""/>
                                    </figure>*/}
                                        <span>{unescape(companyName)}</span>
                                    </div>
                                </>
                            )}
                            {websiteLink && websiteLink[0] !== "" && (
                                <p>
                                    <a onClick={(event) => window.open(websiteLink[0] ? mappingUtils.sanitize(websiteLink[0]) : mappingUtils.sanitize(websiteLink[0]), "_blank")}
                                        href="javascript:void(0)">

                                        <i className="iGlobe" /> Website</a>
                                </p>
                            )}
                            {/*<p>
                                <i className="iProjects_F"></i> {participationCount} Jobs
                                Allotted
                            </p>*/}
                        </div>
                        {/* isOffLine === false &&  */demolished === false &&
                            <div className="col-lg-4 opening">
                                {(projectType !== "FUNDRAISE" || (projectType === "FUNDRAISE" && props.proj.projectDetails?.lastDateOfApplication !== 0)) &&
                                    ((props.proj.projectDetails?.endDate == 0 || ((props.proj.projectDetails?.endDate > new Date().getTime()) || props.proj.projectDetails?.project?.projectPostCompletion?.allowToJoinProject)) &&
                                        <>
                                            <span>Application</span>
                                            {lastDateOfApplication > 0 && <p>Last date: {lastDateOfApplication}</p>}
                                            {noOfOpening > 0 && <p>Openings : {noOfOpening && noOfOpening}</p>}
                                        </>
                                    )
                                }
                                <div className="buttblk">
                                    {projectType !== 'FUNDRAISE' &&
                                        <>
                                            {isApprover !== undefined &&
                                                isLoaded &&
                                                canApply &&
                                                applyBtnActivated &&
                                                (authService.getUserDetailsFromLocalStorage().type !== 'COMPANY' || (secondaryEntityType !== 'JOB' && authService.getUserDetailsFromLocalStorage().type === 'COMPANY')) &&

                                                !isApprover &&
                                                !isUserAlreadyApplied && (
                                                    <a
                                                        href="javascript:void(0)"
                                                        onClick={() => { openConfirmpop() }}
                                                        className="btn btn-primary"
                                                    >
                                                        {'Apply'}
                                                    </a>
                                                )}
                                            {isApprover && isLoaded && (props.proj.projectDetails?.endDate == 0 || props.proj.projectDetails?.endDate > new Date().getTime() || props.proj.projectDetails?.project?.projectPostCompletion?.allowToJoinProject) && (
                                                <a
                                                    href="javascript:void(0)"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        window.location.href = "/project/feeds/" + projectDetails.slug;
                                                    }}

                                                    className="btn btn-primary"
                                                >
                                                    {'Applicants'}
                                                </a>
                                            )}
                                            {!isApprover &&
                                                isUserAlreadyApplied &&
                                                !canWithDraw &&
                                                canApply &&
                                                !isAutoApproveApplicable && (
                                                    <a href="javascript:void(0)" onClick={(e) => {
                                                    }} className="btn btn-primary" style={{ cursor: 'default' }}>
                                                        Applied
                                                    </a>
                                                )}

                                            {!isApprover &&
                                                isUserAlreadyApplied &&
                                                canWithDraw &&
                                                !isAutoApproveApplicable && (
                                                    <a href="javascript:void(0)" onClick={(e) => {
                                                        openConfirmpopForWithDrawl();
                                                    }} className="btn btn-primary" >
                                                        Withdraw
                                                    </a>
                                                )}

                                            {!isApprover &&
                                                isUserAlreadyApplied &&
                                                canApply &&
                                                isAutoApproveApplicable && (
                                                    <a href="javascript:void(0)" onClick={(e) => {
                                                    }} className="btn btn-primary">
                                                        {'Applied'}
                                                    </a>
                                                )}
                                        </>
                                    }
                                    {projectType === "FUNDRAISE" && projectDetails.donation?.donateActive && (projectDetails.project?.creatorUserId !== authService.getUserDetailsFromLocalStorage().userId) &&
                                        <a
                                            href="javascript:void(0)"
                                            onClick={e => {
                                                e.preventDefault();
                                                window.location.href = "/project/feeds/" + projectDetails.slug;
                                            }}
                                            className="btn btn-primary"
                                        >
                                            Contribute
                                        </a>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="projdesbody">
                    <div className="projdesbodyinn">
                        <div className="largetext">
                            <h5>Brief Description</h5>
                            {/*{shortBrief &&
                            shortBrief.split("\\n").map((i) => <p>{unescape(i)}</p>)}*/}

                            {/* <p dangerouslySetInnerHTML={{
                                __html: shortBrief
                                    ? shortBrief
                                        .replace(/&nbsp;/g, ' ')
                                        .replace(/(\r\n|\r|\n)/g, '<br>')
                                    : ''
                            }}/> */}
                            <p><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}>{shortBrief && !_.isNull(shortBrief) && shortBrief.split("\n").map(brief => (<>{convertUnicode(brief)}<br /></>))}</Linkify>
                            </p>

                        </div>

                        {description && (
                            <>
                                <h5>Detailed Description</h5>
                                <div className="projDetailsDesc">
                                    <div className="editor-text" dangerouslySetInnerHTML={{ __html: description }} />
                                </div >
                            </>
                        )}


                        <div className="pdescattachwrap">
                            {attachments &&
                                attachments.map((value) => {
                                    //let file = JSON.parse(unescapeJs(value));
                                    return (
                                        <a href="javascript:void(0)" className="pdescattachfile" onClick={e => {
                                            isWebView() ? postMsg("download", value.url)
                                                : download_file_pdf(e, value.url);
                                        }}>
                                            <span>
                                                {getFileIcon(value.url)}
                                                {/* <i className="iDownloadFile1"/> */}
                                                {value.name && value.name.substring(value.name.indexOf("-") + 1)}
                                            </span>
                                        </a>
                                    );
                                })}
                        </div>

                        {role && role.trim() != "type text" && (
                            <>
                                <h5>Roles and Responsibilities</h5>
                                {/* <p dangerouslySetInnerHTML={{
                                    __html: role
                                        ? role
                                            .replace(/&nbsp;/g, ' ')
                                            .replace(/(\r\n|\r|\n)/g, '<br>')
                                        : ''
                                }}/> */}
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}><p>{role && role.split("\n").map(role => (<>{convertUnicode(role)}<br /></>))}</p>
                                </Linkify>

                            </>
                        )}

                        {faculties && faculties.length > 0 && (
                            <div className="pdkeyspeaker">
                                <h5>{!_.isNull(showProjectType) && showProjectType === 'TRAINING' ? 'Faculty / Trainer' :
                                    showProjectType === 'EXPEDITION' ? 'Expedition leader' : 'Key Speakers'}</h5>
                                <div className="projfollowscroll">
                                    <OwlCarousel
                                        id={"keyspeaker"}
                                        className={'owlbgk'}
                                        key={uuid()}
                                        //items = '3'
                                        rewind='true'
                                        nav='true'
                                        dots={false}
                                        navText={["<i class='iArrow_Left'></i>", "<i class='iArrow_Right'></i>"]}
                                        responsive={mappingUtils.getResponsiveness()}
                                    >
                                        {faculties && faculties.map((faculty) => {
                                            return <div className="item" style={{ cursor: 'pointer' }}>
                                                <div className="projfollowblk">
                                                    <figure className="img" onClick={(e) => {
                                                        e.preventDefault();
                                                        redirectToProfilePage(faculty.customUrl, null)
                                                    }} style={{ cursor: 'pointer' }}>
                                                        <img
                                                            src={!_.isNull(faculty.imageUrl) && !_.isUndefined(faculty.imageUrl) ? getCDNUrl(getOptimizedImage(faculty, faculty.imageUrl)) : faculty.userType === "COMPANY" ? "https://cdn.dscovr.com/images/DefaultBusiness.webp" : "https://cdn.dscovr.com/images/users1.webp"} onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/users1.png" }}
                                                            alt="" />
                                                    </figure>
                                                    <div>
                                                        <h4 onClick={(e) => {
                                                            e.preventDefault();
                                                            redirectToProfilePage(faculty.customUrl, null)
                                                        }}>

                                                            {faculty && faculty.username}
                                                        </h4>

                                                        {!faculty.deactivated && <Follow followed={faculty.followed}
                                                            userId={faculty.userId} />}

                                                    </div>
                                                </div>
                                            </div>
                                        })}

                                    </OwlCarousel>
                                </div>
                            </div>
                        )}

                        {qualifications && qualifications.length > 0 && (
                            <>
                                <h5>Qualification</h5>
                                <ul className="ulstyles">
                                    {qualifications &&
                                        qualifications.map((qual) => {
                                            return <li><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                <a target="blank" href={decoratedHref} key={key}>
                                                    {decoratedText}
                                                </a>
                                            )}>{qual && qual.split("\n").map(qual => (<>{convertUnicode(qual)}<br /></>))}</Linkify>
                                            </li>

                                        })}
                                </ul>
                            </>
                        )}

                        <div className="pdincluexclu">
                            <div className="row">
                                {inclusions && inclusions.length > 0 && inclusions[0] != "" && (
                                    <div className="col-md-6">
                                        <h5>Inclusion</h5>
                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}>{inclusions && inclusions.map((incl) =>
                                            <p>{incl && incl.split("\n").map(incl => (<>{convertUnicode(incl)}<br /></>))}</p>)}</Linkify>

                                    </div>
                                )}
                                {exclusions && exclusions.length > 0 && exclusions[0] != "" && (
                                    <div className="col-md-6">
                                        <h5>Exclusion</h5>
                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}>{exclusions && exclusions.map((excl) =>
                                            <p>{excl && excl.split("\n").map(excl => (<>{convertUnicode(excl)}<br /></>))}</p>)}</Linkify>

                                    </div>
                                )}
                            </div>
                        </div>

                        {endTakeAway && (
                            <>
                                <h5>
                                    {typeContent}
                                    <span>(Received by participant post completion)</span>
                                </h5>
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}>
                                    <p>{endTakeAway && endTakeAway.split("\n").map(endTakeAway => (<>{convertUnicode(endTakeAway)}<br /></>))}</p>
                                </Linkify>


                            </>
                        )}
                        {mediaAttachments && mediaAttachments.length > 0 && mediaAttachments[0] !== "" && (
                            <>
                                <h5>Media Coverage</h5>
                                <ul>
                                    {mediaAttachments &&
                                        mediaAttachments.map((media) => {
                                            return <li className="pdtlsLink"><Linkify
                                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                                    <a target="blank" href={decoratedHref} key={key}>
                                                        {decoratedText}
                                                    </a>
                                                )}>{media && media.split("\n").map(media => (<>{convertUnicode(media)}<br /></>))}</Linkify>
                                            </li>

                                        })}
                                </ul>
                            </>
                        )}

                        {specialization &&
                            skills &&
                            (skills.length > 0 ||
                                specialization.length > 0) && (
                                <div className="specialskills">
                                    <h5>{projectType === 'EXPEDITION' ? 'Skills' : 'Skills and Specialization'}</h5>
                                    <div className="specialskillsblk">
                                        {specialization && specialization.length > 0 && (
                                            <div className="spsktags">
                                                {" "}
                                                {specialization &&
                                                    specialization.map((sp) => <span>{convertUnicode(sp)}</span>)}{" "}
                                            </div>
                                        )}
                                        {skills && skills.length > 0 && (
                                            <div className="spsktags">
                                                {" "}
                                                {skills &&
                                                    skills.map((skill) => (
                                                        <span>{unescape(skill)}</span>
                                                    ))}{" "}
                                            </div>
                                        )}

                                    </div>
                                </div>
                            )}
                        <div className="pdincluexclu locaduration">
                            {location.address &&
                                <>
                                    <h5>Detailed Address </h5>
                                    <p className="addRss">
                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}>
                                            <span dangerouslySetInnerHTML={{
                                                __html: linkfiedHtml
                                            }} /></Linkify>
                                    </p>
                                </>}
                        </div>

                        <div className="pdincluexclu locaduration">
                            <div className="row">
                                {/*<div className="col-md-6">
                                    <h5>Location</h5>
                                    {location && location.address != null && (
                                        <p>{location && unescape(location.address)}</p>
                                    )}
                                    {location &&
                                    <p><i
                                        className="iLocation"></i> {location.city}, {location.state}, {location.country}
                                    </p>
                                    }
                                </div>*/}
                                <div className="col-md-6">
                                    <h5>Duration</h5>
                                    <ul>
                                        <li>
                                            <span>Start Date</span> {startDate}
                                        </li>
                                        {endDate !== "" &&
                                            <li>
                                                <span>End Date</span> {endDate}
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="projdesfoot">
                    {/*<div className="pdbottsocial">
                        <a href="javascript:void(0)">
                            <i className="iSocial_FB"/>
                        </a>
                        <a href="javascript:void(0)">
                            <i className="iSocial_Twitter"/>
                        </a>
                        <a href="javascript:void(0)">
                            <i className="iSocial_Pinterest"/>
                        </a>
                    </div>*/}
                    {/*<a href="javascript:void(0)" className="reportabuse">
                        <i className="iReportComment_FL"/> Report Abuse
                    </a>*/}
                </div>
                <div className="modal fade dgpopup confirmpop"
                    id={"confirmpopApply" + projectDetails.projectId} tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        {!apply ?
                            <div className="modal-content">

                                <button type="button" className="close" onClick={() => {
                                    hidemodal()
                                }
                                }><span
                                    aria-hidden="true">&times;</span></button>
                                <div className="confirm-popup-modal-body">Are you sure you want to {projectType === 'FUNDRAISE' ? 'contribute to ' : 'apply for '}
                                    the {projectDetails && projectDetails.participationDetails && convertToFirstCapital(mappingUtils.getProjectTypeValue(projectDetails.participationDetails.secondaryEntityType))}
                                    {projectType === 'FUNDRAISE' ? ' project ' : ' '} {unescape(title)}?
                                    {isAutoApproveApplicable ? ' Your application will be accepted as soon as you apply.' : ''}
                                </div>
                                <div className="modal-footer confirm-popup-modal-footer">
                                    <button type="button" className="btn btn-primary"
                                        disabled={!applyBtnActivated}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            applyInProject(projectType);
                                            setApplyBtnActivation(false);
                                        }}>Yes
                                    </button>
                                    <a
                                        href="javascript:void(0)"
                                        onClick={() => {
                                            hidemodal()
                                        }
                                        }
                                        className="btn btn-secondary"
                                    >
                                        No
                                    </a>
                                    {/* <button type="button" className="btn btn-secondary" onClick={hidemodal()}>No</button> */}
                                </div>
                            </div> : <>{!isAutoApproveApplicable && <div className="modal-content">
                                {/* <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Delete Comment Confirmation</h5>
                                </div> */}
                                <button type="button" className="close" onClick={() => {
                                    hidemodal()
                                }
                                }><span
                                    aria-hidden="true">&times;</span></button>
                                <div className="confirm-popup-modal-body">
                                    Your application is submitted for approval
                                </div>
                                <div className="modal-footer confirm-popup-modal-footer">
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => {
                                            hidemodal()
                                        }}
                                    >OK
                                    </button>

                                    {/* <button type="button" className="btn btn-secondary" onClick={hidemodal()}>No</button> */}
                                </div>
                            </div>}</>}
                    </div>
                </div>



                <div
                    className="modal fade dgpopup confirmpop"
                    id={"confirm" + projectId}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="close"
                                onClick={() =>
                                    window.$('#confirm' + projectId).modal('hide')
                                }
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="confirm-popup-modal-body">
                                The {projectDetails && projectDetails.participationDetails && convertToFirstCapital(projectDetails.participationDetails.secondaryEntityType)}, {unescape(title)} you wish to hide will continue to be visible to other
                                participants as before.
                            </div>
                            <div className="modal-footer confirm-popup-modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                        handleHideModal(projectId, (projectDetails.participationDetails.secondaryEntityType))
                                    }
                                >
                                    Yes
                                </button>
                                <button onClick={() =>
                                    window.$('#confirm' + projectId).modal('hide')
                                }
                                    type="button" className="btn btn-secondary">
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade dgpopup confirmpop"
                    id={"confirmpopForWithdrawl" + projectDetails.projectId} tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Delete Comment Confirmation</h5>
                                </div> */}
                            <button type="button" className="close" onClick={() => {
                                hideWithDrawlModal()
                            }
                            }><span
                                aria-hidden="true">&times;</span></button>
                            <div className="confirm-popup-modal-body">Are you sure you want to withdraw participant request for
                                the {projectDetails && projectDetails.participationDetails && convertToFirstCapital(projectDetails.participationDetails.secondaryEntityType)} {unescape(title)}?
                            </div>
                            <div className="modal-footer confirm-popup-modal-footer">
                                <button type="button" className="btn btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        withDrawParticipantRequest();
                                        setApplyBtnActivation(true);
                                    }}>Yes
                                </button>
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        hideWithDrawlModal()
                                    }
                                    }
                                    className="btn btn-secondary"
                                >
                                    No
                                </a>
                                {/* <button type="button" className="btn btn-secondary" onClick={hidemodal()}>No</button> */}
                            </div>
                        </div>
                    </div>
                </div>

                <DeactivateErrorPopUp message={applyErrorMessage} />

            </div>
        );
    };

    if (seeAllApplicantView) {
        return <Redirect to="/project-detail/participants" push />;
    }

    return (
        <div>
            <div id={"projectDetails"} className="modal dgpopup" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            {!isLoaded ? (
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            ) : (
                                getBody()
                            )}
                        </div>
                    </div>

                </div>
            </div>
            {/*<ContactInfo contactInfo={contactInfo}/>*/}
        </div>

    );
};

const mapStateToProps = (state) => {
    return {
        proj: state.showAllReducer,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectDetails));
