import React, { Component } from 'react';
import axios from "axios";
import * as _ from "lodash";
import { authService } from '../auth';
import { connect } from 'react-redux';
import { setProfileBasicInfoDetails } from '../reducer/profile/ProfileReducer';
class OrganizationAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: authService.getUserDetailsFromLocalStorage().userId,
            userDetails: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            userDetails: nextProps.profileBasicInfoData,
        })
    }

    componentDidMount() {
        if (this.state.userId !== this.props.userId) {
            if (this.props.profileBasicInfo) {
                this.props.profileBasicInfo({ userId: this.props.userId });
            }
        }
    }

    render() {
        const { userDetails } = this.state;
        return (
            <>                
                {!_.isEmpty(userDetails) && (this.state.userId !== this.props.userId) && 
                    <>
                        {!this.props.isNotTopSpace && <br />}
                        <div class="pagetitle-org text-center" style={{marginTop : this.props.isMarginTop ? '-8px' : ''}}>
                            <p>You are viewing {userDetails.name} as admin</p>
                        </div>
                        {this.props.isBottomSpace && <br />}
                    </>
                }
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        profileBasicInfoData: state.ProfileReducer.profileBasicInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileBasicInfo: (state) => dispatch(setProfileBasicInfoDetails(state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationAdmin);