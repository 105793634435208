import React from "react";
import {authService} from "../auth";
import axios from "axios";
import httpService from "../AxiosInterceptors";

httpService.setupInterceptors();

class MutualConnect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
        }
    }


    render() {
       return (
           <>
               {this.props.connectDepth ?
                   (this.props.connectDepth === 1 ?
                           <span className="userconnect first" style={{cursor:'default'}}>1st</span> :
                           this.props.connectDepth === 2 ?
                               <span className="userconnect" style={{cursor:'default'}}>2nd</span> :
                               (this.props.connectDepth === -1 || this.props.connectDepth === 0) ?'' : ""
                   ) : ""
               }
           </>
       )

    }
}

export default MutualConnect
