import React, { Component } from 'react';
import { authService } from '../auth';
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import analyticsHeatMapData from "../project/component/Studio/studioHeatMapData";
import { globalCountryData } from '../project/component/Studio/globalCountryData';

require("highcharts/modules/map")(Highcharts);
class AnalyticsHeatMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            analyticsHeatMapData: null,
            heatMapData: []
        }
        this.analyticsHeatMapData = new analyticsHeatMapData();
        this.heatMapOptions = {
            title: {
                text: this.props.name,
                style: {
                    color: "#000"
                }
            },
            chart: {
                backgroundColor: "transparent",
                type: "map",
                map: null,
                spacingTop: 20,
                spacingLeft: 20,
                spacingRight: 20,
                spacingBottom: 20,
                style: {
                    fontFamily: 'Montserrat,sans-serif'
                },
            },
            mapNavigation: {
                enabled: true,
                enableButtons: false
            },
            credits: {
                enabled: false
            },
            colorAxis: {
                dataClasses: [{
                    to: 3
                }, {
                    from: 3,
                    to: 10
                }, {
                    from: 10,
                    to: 30
                }, {
                    from: 30,
                    to: 100
                }, {
                    from: 100,
                    to: 300
                }, {
                    from: 300,
                    to: 1000
                }, {
                    from: 1000
                }]
            },
            legend: {
                align: "left",
                verticalAlign: "bottom",
                x: 0,
                y: 0,
                floating: true,
                layout: "vertical",
                valueDecimals: 0,
                backgroundColor:
                    (Highcharts.defaultOptions &&
                        Highcharts.defaultOptions.legend &&
                        Highcharts.defaultOptions.legend.backgroundColor) ||
                    "rgba(255, 255, 255, 0.85)"
            },
            series: [
                {
                    name: this.props.isProjectPage ? "Project Data" : "Global Data",
                    showInLegend: true,
                    dataLabels: {
                        enabled: true,
                        color: "#FFFFFF",
                        format: "{point.postal-code}",
                        style: {
                            textTransform: "uppercase"
                        }
                    },
                    tooltip: {
                        pointFormatter: function () {
                            // if(this.props.pageName === 'CircleAnalytics'){
                            //     var list = '<ul>';
                            //     list += this.name + " : "+ this.value;
                            //     list += this.uinique_user + " : "+ this.user;
                            //     list += '</ul>';
                            //     return list
                            // }else{
                            //     return this.name + " : "+ this.value + <br/> + this.user;
                            // }
                            // var list = '<ul>';
                            // list += this.name + " : "+ this.value + '<br/>';
                            // list += "Users" + " : " + this.uinique_user;
                            // list += '</ul>';
                            // return list;
                            return this.name + " : "+ (props.selectData === "DONATIONS" ? (props.entityConfig.indian ? "₹" : "$") : "") + (Math.round(this.value * 100) / 100).toFixed(2).replace(/\.0+$/,'') + '<br/>' + (this.uinique_user ? "User" + (this.uinique_user > 1 ? "s" : "") + ": " + this.uinique_user : "");
                        }
                    },
                    cursor: "pointer",
                    joinBy: ["iso-a2", "code"],
                    data: []
                }
            ]
        };

        this.analyticsHeatMapData.getWorld().then(r => {
            this.setState({ analyticsHeatMapData: r.data }, () => {
                //this.getStudioHeatMapData();
                let heatMapGlobalData = [];
                this.props.data && this.props.data.map((data) => {
                    const index = globalCountryData && globalCountryData.findIndex(p => p.name.toLowerCase() === (data._id === null ? "" : data._id.toLowerCase()));
                    heatMapGlobalData.push({"code": index === -1 ? "" : globalCountryData[index].country, "name": data._id, "value": data.count, "uinique_user": data.user});
                })
                this.setState({
                    heatMapData: heatMapGlobalData
                },() => {
                    this.heatMapOptions.series[0].data = this.state.heatMapData;
                    this.heatMapOptions["chart"]["map"] = this.state.analyticsHeatMapData;
                    this.setState({ analyticsMapOptions: this.heatMapOptions });
                })
            });

            
        });
    }

    render() {
        const {analyticsHeatMapData, heatMapData} = this.state;
        return (
            <div className='chartBox'>
                {analyticsHeatMapData && heatMapData && heatMapData.length > 0 ? (
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={"mapChart"}
                        options={this.state.analyticsMapOptions}
                    />
                ) : (
                    ""
                )}
            </div>            
        );
    }
}

export default (AnalyticsHeatMap);