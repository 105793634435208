export const SET_PROJECT_DETAILS="/project/SET_PROJECT_DETAILS";
const GET_PROJECT_DETAILS="/project/GET_PROJECT_DETAILS";
const SET_PROJECT_DESC="/project/SET_PROJECT_DESC";
const GET_PROJECT_DESC="/project/GET_PROJECT_DESC";
const SET_FORM_STATE="/draft/SET_FORM_STATE";
const RESET_FORM_STATE = "/draft/RESET_FORM_STATE";
export const SET_PROJECT_DETAILS_SUCCESS = "/project/SET_PROJECT_DETAILS_SUCCESS";
export const SET_COMPANY_LIST = "/project/SET_COMPANY_LIST";
export const SET_COMPANY_LIST_SUCCESS = "/project/SET_COMPANY_LIST_SUCCESS";
export const SET_ENTITY_LIST = "/project/SET_ENTITY_LIST";
export const SET_ENTITY_LIST_SUCCESS = "/project/SET_ENTITY_LIST_SUCCESS";

let initialState ={
    projectDetails:{},
    projectDescription:{},
    formState:{},
    companyNameList:{isLoaded:false},
    entityList:{isLoaded:false},
};

const showAllReducer = (state=initialState,action={})=>{
    switch (action.type) {
        case "/project/SET_PROJECT_DETAILS":
             state = {...state,projectDetails:action.payload};
             return state;
        case "/project/SET_PROJECT_DESC":
             state = {...state,...state.projectDescription,projectDescription:{...action.payload,isLoaded:false}};
             return state;
        case "/draft/SET_FORM_STATE":
              state = {...state,formState:action.payload};
              return state;
        case "/draft/RESET_FORM_STATE":
              state = {...state,formState:initialState.formState};
              return state;
        case "/project/SET_COMPANY_LIST":
                state = {...state,companyNameList:{...state.companyNameList,isLoaded:false}};
                return state;
        case "/project/SET_COMPANY_LIST_SUCCESS":
            state = {...state,companyNameList:action.payload,isLoaded:true};
            return state;
        case "/project/SET_ENTITY_LIST":
            state = {...state,entityList:{...state.entityList,isLoaded:false}};
            return state;
        case "/project/SET_ENTITY_LIST_SUCCESS":
            state = {...state,entityList:action.payload,isLoaded:true};
            return state;
        case "/project/SET_PROJECT_DETAILS_SUCCESS":
            state = {...state,projectDetails:action.payload,isLoaded:true};
             return state;
        default:
            return state;
    }
};

export default showAllReducer;

export function setProjectDetails(projectDetails){
    return {type:SET_PROJECT_DETAILS,payload:projectDetails};
}

export function setProjectDetailsOnSuccess(projectDetails){
    return {type:SET_PROJECT_DETAILS_SUCCESS,payload:projectDetails};
}

export function getProjectDetails(){
    return {type:GET_PROJECT_DETAILS};
}

export function setProjectDesc(desc){
    return {type:SET_PROJECT_DESC,payload:desc};
}

export function getProjectDesc(){
    return {type:GET_PROJECT_DESC};
}

export function setFormState(state){
    return {type:SET_FORM_STATE,payload:state};
}

export function resetFormState() {
    return {type:RESET_FORM_STATE};
}

export function setCompanyList(state){
    return {type:SET_COMPANY_LIST,payload:state};
}

export function loadCompanyList(state) {
    return {type:SET_COMPANY_LIST_SUCCESS,payload:state};
}

export function setEntityList(state){
    return {type:SET_ENTITY_LIST,payload:state};
}

export function loadEntityList(state) {
    return {type:SET_ENTITY_LIST_SUCCESS,payload:state};
}