import React, {Component} from "react";
import "../../profile/container/profile/css/main-profile.min.css";
import "../../project/container/Project/css/main-projects.min.css";
import "react-toastify/dist/ReactToastify.css";
import {Link} from "react-router-dom";
import {authService} from "../../auth";
import StaticHeader from "../../StaticHeader";

class DeletedUnAuthCircle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
        };
    }


    render() {

            return <div className="wrapper projectpage profilepage pabout ppersonal">
                <StaticHeader/>
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="bk_dactiveprflbx">
                                    <img src="https://cdn.dscovr.com/images/Deactivated.webp" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/Deactivated.png"}} alt=""/>
                                    <p>This circle has been deleted by the member!</p>
                                    <Link to={"/explore"} className="btn connect_btn">Explore More <i
                                        className="iArrow_Right"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        }


}

export default DeletedUnAuthCircle

