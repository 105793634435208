import React, { useState, useEffect } from 'react';
import { authService } from "../../auth";
import { Link, NavLink } from "react-router-dom";
import axios from 'axios';
import { getTotalOthers, numberShortForm } from './Common/HashtagsCommonFunction';

function HashTagsCategory(props) {
    const [userData, setUserData] = useState(authService.getUserDetailsFromLocalStorage());
    const [categoryCountList, setCategoryCountList] = useState([]);

    const hashTagsCategoryCount = () => {
       if(props.tagName != "") {
           axios({
               method: "get",
               url: `${process.env.REACT_APP_userServiceURL}/tags/hashtagcategorywisecount?userId=${userData.userId}&hashTag=${props.tagName}`,
               withCredentials: true,
           })
               .then((response) => {
                   if (response && response.data && response.data.status === "200 OK") {
                        setCategoryCountList(response.data.body);
                   }
               })
               .catch((error) => {
                   console.log(error);
               });
       }
    };

    useEffect(() => {
        hashTagsCategoryCount();
    }, [props.tagName]);

    return (
        <div className="expHashMainTop expHashSection">
            <div className="expHashMainTopDesc">

                <h1><a href={'/explore-hashtag/'+props.tagName}>#{props.tagName}</a></h1>
                <div className="tagCategory">
                    <div className="tagCategoryWrap">

                        {categoryCountList.image != 0 ? <span><Link to={'/explore-hashtag/photos/'+props.tagName} className={"tagCateBtns" + (props.tagType === 'photos' ? ' active' : '')}><i className="iPhotos" />
                            <span className="tCount">{numberShortForm(categoryCountList.image)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'photos' ? ' active' : '')}><i className="iPhotos" /></Link></span>
                        }
                        {categoryCountList.video != 0 ? <span><Link to={'/explore-hashtag/videos/'+props.tagName} className={"tagCateBtns" + (props.tagType === 'videos' ? ' active' : '')}><i className="iVid" />
                            <span className="tCount">{numberShortForm(categoryCountList.video)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'videos' ? ' active' : '')}><i className="iVid" /></Link></span>
                        }
                        {categoryCountList.article != 0 ? <span><Link to={'/explore-hashtag/blogs/'+props.tagName} className={"tagCateBtns" + (props.tagType === 'blogs' ? ' active' : '')}><i className="iArticle" />
                            <span className="tCount">{numberShortForm(categoryCountList.article)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'blogs' ? ' active' : '')}><i className="iArticle" /></Link></span>
                        }
                        {getTotalOthers(categoryCountList.ppt,categoryCountList.pdf) != 0 ? <span><Link to={'/explore-hashtag/documents/'+props.tagName} className={"tagCateBtns" + (props.tagType === 'documents' ? ' active' : '')}><i className="iClip" />
                            <span className="tCount">{getTotalOthers(categoryCountList.ppt,categoryCountList.pdf)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'documents' ? ' active' : '')}><i className="iClip" /></Link></span>
                        }
                        {categoryCountList.projects != 0 ? <span><Link to={'/explore-hashtag/projects/'+props.tagName} className={"tagCateBtns" + (props.tagType === 'projects' ? ' active' : '')}><i className="iProjects_OL" />
                            <span className="tCount">{numberShortForm(categoryCountList.projects)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'projects' ? ' active' : '')}><i className="iProjects_OL" /></Link></span>
                        }
                        {categoryCountList.studio != 0 ? <span><Link to={'/explore-hashtag/studios/'+props.tagName} className={"tagCateBtns" + (props.tagType === 'studios' ? ' active' : '')}><i className="iistudio_icon" />
                            <span className="tCount">{numberShortForm(categoryCountList.studio)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'studios' ? ' active' : '')}><i className="iistudio_icon" /></Link></span>
                        }
                        {categoryCountList.forum != 0 ? <span><Link to={'/explore-hashtag/forums/'+props.tagName} className={"tagCateBtns" + (props.tagType === 'forums' ? ' active' : '')}><i className="iForum_F" />
                            <span className="tCount">{numberShortForm(categoryCountList.forum)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'forums' ? ' active' : '')}><i className="iForum_F" /></Link></span>
                        }
                        {categoryCountList.poll != 0 ? <span><Link to={'/explore-hashtag/polls/'+props.tagName} className={"tagCateBtns" + (props.tagType === 'polls' ? ' active' : '')}><i className="iPolls_FL" />
                            <span className="tCount">{numberShortForm(categoryCountList.poll)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'polls' ? ' active' : '')}><i className="iPolls_FL" /></Link></span>
                        }
                        {getTotalOthers(categoryCountList.others,categoryCountList.audio,categoryCountList.cause,categoryCountList.circle,categoryCountList.feed,categoryCountList.link) != 0 ? <span><Link to={'/explore-hashtag/others/'+props.tagName} className={"tagCateBtns" + (props.tagType === 'others' ? ' active' : '')}><i className="iSelect_Multiple" />
                            <span className="tCount">{getTotalOthers(categoryCountList.others,categoryCountList.audio,categoryCountList.cause,categoryCountList.circle,categoryCountList.feed,categoryCountList.link)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'others' ? ' active' : '')}><i className="iSelect_Multiple" /></Link></span>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
} 

export default HashTagsCategory;