import dateUtil from "date-and-time";
import axios from "axios";
import {toast} from "react-toastify";
import uuid from "react-uuid";
import httpService from '../../../../AxiosInterceptors';
import {NavLink} from "react-router-dom";
import React from "react";
import * as _ from "lodash";
import {getCDNUrl} from "../../../../commons/CommonFunctions";

var dateFormat = require("dateformat");
let qualificationList = [];

httpService.setupInterceptors();

const formUtils = (function (namespace = {}) {
    namespace.parseFormConfigFromArray = function (config = []) {
        let formConfig = {
            title: "",
            placeholder: "",
            permission: "",
            editable: false,
        };
        if (config.length >= 2) {
            formConfig.permission = config[config.length - 1];
        }
        if (config.length >= 3) {
            formConfig.title = config[1];
            formConfig.permission = config[config.length - 1];
        }
        if (config.length >= 4) {
            formConfig.placeholder = config[config.length - 2];
        }
        return formConfig;
    };

    namespace.warn = (message, toastId) => {
        let id;
        if (toastId && !toast.isActive(toastId)) {
            id = toast.warn(message, {autoClose: 5000});
        } else {
            id = toast.warn(message, {autoClose: 5000});
        }
        return id;
    };

    namespace.error = (message, toastId) => {
        let id;
        if (toastId && !toast.isActive(toastId)) {
            id = toast.error(message, {autoClose: 5000});
        } else {
            id = toast.error(message, {autoClose: 5000});
        }
        return id;
    };

    namespace.success = (message, toastId) => {
        let id;
        if (toastId && !toast.isActive(toastId)) {
            id = toast.success(message, {autoClose: 5000});
        } else {
            id = toast.success(message, {autoClose: 5000});
        }
        return id;
    };

    namespace.getEntityType = (type) => {
       if(!type || type==='INDIVIDUAL'){
           return 'INDIVIDUAL_OR_RECRUITER';
       }else if(type==='COMPANY'){
            return 'BUSINESS_PAGES';
        }
       else{
           return  type;
       }

    };

    namespace.checkAvailableFields = (list,name)=>{
        if(!list){
            return false;
        }

        return list.includes(name);
    }

    namespace.getFieldName = (name,type)=>{
        if(!name){
            return null;
        }
        if(name === 'PRIVATE_PROJECT_BTN')
            return 'Private Project';
        if(name === 'PAYMENT_LINK')
            return 'Payment Link';
        if(name === 'SKILLS')
            return 'Skills';
        if(name === 'COVER_IMAGE')
            return 'Cover Image';
        if(name === 'CERTIFICATION'){
            if(type){
                if(type.toLowerCase() === 'job'){
                    return 'Remarks';
                }if(type.toLowerCase() === 'assignment' || type.toLowerCase() === 'training'){
                    return 'Certification or Accolades';
                }if(type.toLowerCase() === 'event' || type.toLowerCase() === 'expedition' || type.toLowerCase() === 'fundraise'){
                    return 'Mementos or Event Kits';
                }
            }
            return 'Certification';
        }

        if(name === 'ORGANIZATION_NAME')
            return 'Organization Name';
        if(name === 'VOLUNTEERING_PROJECT_BUTTON')
            return 'Volunteering Project';
        if(name === 'WEBSITE')
            return 'Website';
        if(name === 'END_DATE')
            return 'End Date';
        if(name === 'ATTACHMENTS')
            return 'Attach';
        if(name === 'DETAILED_DESCRIPTION')
            return 'Detailed Description';
        if(name === 'DETAILED_ADDRESS')
            return 'Detailed Address';
        if(name === 'ROLES_RESPONSIBILITIES')
            return 'Roles & Responsibilities';
        if(name === 'FEES') {
            if(type){
                if(type.toLowerCase() === 'event' || type.toLowerCase() === 'training' || type.toLowerCase() === 'expedition'){
                    return 'Enrollment Fee';
                }
                if(type.toLowerCase() === 'fundraise'){
                    return 'Amount to be Raised';
                }
                return 'Remuneration';
            }
        }
        if(name === 'SPECIALIZATION')
            return 'Specialization';
        if(name === 'HASH_TAGS')
            return 'Hash Tags';
        if(name === 'QUALIFICATION')
            return 'Qualification';
        if(name === 'INCLUSIONS')
            return 'Inclusions';
        if(name === 'EXCLUSIONS')
            return 'Exclusions';
        if(name === 'MEDIA_COVERAGE')
            return 'Media & Coverage';
        if(name === 'KEY_SPEAKERS'){
            if(type){
                if(type.toLowerCase() === 'training'){
                    return 'Faculty/Trainer';
                }
                if(type.toLowerCase() === 'expedition'){
                    return 'Expedition Leader';
                }
                return 'Key Speakers';
            }
        }
        if(name === "PARTICIPANTS") {
            return "Participants";
        }

        return name;
    }

    namespace.getEntityValue = (entityType)=>{
        if(entityType){
            if(entityType === 'BUSINESS_PAGES'){
                entityType = 'Organization page';
            } else if(entityType === 'CIRCLE') {
                entityType = "Community";
            }
        }
        return entityType;
    }

    namespace.getImageUrlForEntities = (entity) => {
        if(!entity || !entity.entityType){
            return '/images/users1.png';
        } else if(entity.entityType === 'CIRCLE'){
            if(!entity.entityImageUrl){
               return 'https://cdn.dscovr.com/images/circleDefault.png' ;
            }
            if (entity.resizedEntityImages && entity.resizedEntityImages['300x300'] != null) {
                return getCDNUrl(entity.resizedEntityImages['300x300']);
            }
            return getCDNUrl(entity.entityImageUrl);
        } else if(entity.entityType === 'BUSINESS_PAGES' || entity.entityType === 'COMPANY'){
            if(!entity.entityImageUrl){
                return 'https://cdn.dscovr.com/images/DefaultBusiness.png' ;
            }
            if (entity.resizedEntityImages && entity.resizedEntityImages['200x200'] != null) {
                return getCDNUrl(entity.resizedEntityImages['200x200']);
            }
            return getCDNUrl(entity.entityImageUrl);
        } else if(entity.entityType === 'INDIVIDUAL') {
            if(!entity.entityImageUrl){
                return 'https://cdn.dscovr.com/images/users1.png' ;
            }
            return getCDNUrl(entity.entityImageUrl);
        }


    };

    namespace.headings = (type) => {
        let obj = {};

        obj["title"] = "Create a Job";
        obj["editTitle"] = "Edit a Job";
        obj["type"] = "job";
        obj["offLineTitle"] = "Add Experience";
        obj["editOffLineTitle"] = "Edit Experience";
        obj["createdby"] = "auto";
        obj["companyName"] = "Organization Name";
        obj["website"] = "Website";
        obj["shortbrief"] = "Brief Description";
        obj["description"] = "Detailed Description";
        obj["attach"] = "Attach";
        obj["beginningDate"] = "Start date";
        obj["endingDate"] = "Ending date";
        obj["duration"] = "Duration";
        obj["location"] = "Job Location";
        obj["address"] = "Detailed Address";
        obj["role"] = "Roles and Responsibilities";
        obj["skills"] = "Skills ";
        obj["skills-followed"] =
            "(Add skills to make your job visible to the right candidates)";
        obj["specialization"] = "Specialization ";
        obj["specialization-followed"] =
            "(Add specialization to make your job visible to right candidates)";
        obj["remuneration"] = "Remuneration";
        obj["inclusions"] = "Inclusions";
        obj["exclusions"] = "Exclusions";
        obj["lastDateOfApplication"] = "Last date of application";
        obj["openings"] = "Number of openings";
        obj["endtakeaway"] = "Remarks";
        obj["media"] = "Media Coverage";
        obj["declaration"] =
            "I agree to the WeNaturalists "+ <NavLink to="/TermsConditions" className="privacy-link" target="_blank">Terms & Conditions</NavLink>+", "+<NavLink to="/UserAgreement" className="privacy-link" target="_blank">User Agreement</NavLink>+", "+<NavLink to="/PrivacyPolicy" className="privacy-link" target="_blank">Privacy Policy</NavLink>+" and "+<NavLink to="/CookiePolicy" className="privacy-link" target="_blank">Cookie Policy.</NavLink>;
        obj["hashtags"] = "Hashtags";
        obj["participant"] = "Participants";

        if (type == "assignment") {
            obj["title"] = "Create an Assignment";
            obj["editTitle"] = "Edit an Assignment";
            obj["location"] = "Assignment Location";
            obj["skills-followed"] =
                "(Add skills to make your assignment visible to the right candidates)";
            obj["specialization-followed"] =
                "(Add specialization to make your assignment visible to right candidates)";
            obj["endtakeaway"] = "Certification or Accolades";
            obj["endtakeaway-followed"] = "(Received by participant post completion)";
        }

        if (type == "training") {
            obj["title"] = "Create a Training";
            obj["editTitle"] = "Edit a Training";
            obj["location"] = "Training Location";
            obj["skills-followed"] =
                "(Add skills to make your training visible to the right audience)";
            obj["specialization-followed"] =
                "(Add specialization to make your training visible to right audience)";
            obj["endtakeaway"] = "Certification or Accolades";
            obj["endtakeaway-followed"] =
                "(Received by participant post completion)";
            obj["remuneration"] = "Enrollment Fee";
            obj["faculty"] = "Faculty / Trainer";
        }

        if (type == "event") {
            obj["title"] = "Create an Event";
            obj["editTitle"] = "Edit an Event";
            obj["location"] = "Event Location";
            obj["skills-followed"] =
                "(Add skills to make your event visible to the right audience)";
            obj["specialization-followed"] =
                "(Add specialization to make your event visible to right audience)";
            obj["endtakeaway"] = "Mementos or Event Kits";
            obj["endtakeaway-followed"] = "(Received by participants and guests)";
            obj["remuneration"] = "Enrollment Fee";
        }
        if (type == "expedition") {
            obj["title"] = "Create an Expedition";
            obj["editTitle"] = "Edit an Expedition";
            obj["location"] = "Expedition Location";
            obj["skills-followed"] =
                "(Add skills to make your event visible to the right audience)";
            obj["specialization-followed"] =
                "(Add specialization to make your event visible to right audience)";
            obj["endtakeaway"] = "Mementos or Event Kits";
            obj["endtakeaway-followed"] = "(Received by participants and guests)";
            obj["remuneration"] = "Enrollment Fee";
        }if (type == "storybook") {
            obj["title"] = "Create a Story Book";
            obj["editTitle"] = "Edit a Story Book";
            obj["location"] = "Story Book  Location";
            obj["skills-followed"] =
                "(Add skills to make your event visible to the right audience)";
            obj["specialization-followed"] =
                "(Add specialization to make your event visible to right audience)";
            obj["endtakeaway"] = "Mementos or Event Kits";
            obj["endtakeaway-followed"] = "(Received by participants and guests)";
            obj["remuneration"] = "Enrollment Fee";
        }if (type == "fundraise") {
            obj["title"] = "Create a Fund Raise";
            obj["editTitle"] = "Edit a Fund Raise";
            obj["location"] = "Fund Raise  Location";
            obj["skills-followed"] =
                "(Add skills to make your event visible to the right audience)";
            obj["specialization-followed"] =
                "(Add specialization to make your event visible to right audience)";
            obj["endtakeaway"] = "Mementos or Event Kits";
            obj["endtakeaway-followed"] = "(Received by participants and guests)";
            obj["remuneration"] = "Amount to be Raised";
            obj["openings"] = "Contribution expected from how many people";

        }

        return obj;
    };

    namespace.publishProject = async (type, body) => {
        return await axios({
            method: "post",
            data: body,
            url: process.env.REACT_APP_userServiceURL + "/project/" + type + "/posting/",
            //headers: {'Content-Type': 'multipart/form-data'},
            withCredentials: true,
        });
    };

    namespace.saveDraft = async (body) => {
        return await axios({
            method: "post",
            data: body,
            url: process.env.REACT_APP_userServiceURL + "/backend/drafts/create/project",
            headers: {"Content-Type": "application/json"},
            withCredentials: true,
        });
    };

    namespace.checkValidShortBrief = (brief) => {
        return (
            brief.trim().replace(" ", "").length > 10 &&
            brief.trim().replace(" ", "").length <= 30
        );
    };
    namespace.checkCompanyName = (name) => {
        return new RegExp(
            "^(?!s)(?!.*s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}$"
        ).test(name);
    };

    namespace.isFieldDisabled = (running) => {
        if(running){
            return running;
        }
        return false;
    };

    namespace.assignFormvalue = (formValue,entityType,initialFormValue) => {
        if(formValue && entityType && initialFormValue){
            initialFormValue.title.value = formValue.title;
            initialFormValue.creatorUserId = formValue.creatorUserId;
            initialFormValue.companyName.value = formValue.companyName;
            initialFormValue.paymentLink.value = formValue.paymentLink;
            initialFormValue.shortBrief.value = formValue.shortBrief;
            initialFormValue.detailedDescription.value = formValue.description;
            initialFormValue.attachments.value = formUtils.getAttachments(formValue.attachments);
            initialFormValue.coverImage.value = formValue.coverImage;
            initialFormValue.participants = formUtils.getParticipantList(formValue.participantList);
            initialFormValue.address.value = formUtils.getAddress(formValue,entityType);
            initialFormValue.durationStartDate.value = formValue.beginningTime;
            initialFormValue.durationEndDate.value = formValue.endingTime>0?formValue.endingTime:null;
            initialFormValue.originalEndDate = formValue.endingTime;
            initialFormValue.applnEndDate.value = formValue.lastTimeOfApplication;
            initialFormValue.originalApplnEndDate = formValue.lastTimeOfApplication;
            initialFormValue.relevantHashtags = formValue.hashTags;
            if(formValue.websiteLink){
                initialFormValue.website.value = formValue.websiteLink.toLocaleString();
            }

            initialFormValue.isAgree.value = true;

                initialFormValue.noOfOpenings.value = formValue.noOfOpening;

            initialFormValue.specialization = formUtils.getSpecialization(formValue.specialization);
            initialFormValue.skills = formUtils.getSkills(formValue.skills);
            initialFormValue.id = formValue.id;
            initialFormValue.qualifications = formValue.qualifications;
            if(formValue.exclusions){
                initialFormValue.exclusions.value = formValue.exclusions.toLocaleString();
            }
            if(formValue.inclusions){
                initialFormValue.inclusions.value = formValue.inclusions.toLocaleString();
            }


            initialFormValue.endTakeAway.value = formValue.endTakeAway;
            if(formValue.mediaAttachments){
                initialFormValue.medias.value = formValue.mediaAttachments.toLocaleString();
            }
            initialFormValue.role.value = formValue.role;
            initialFormValue.qualifications = formUtils.getQualifications(formValue);
            initialFormValue.qualificationList = qualificationList;
            initialFormValue.fees = formUtils.getFees(formValue);
            initialFormValue.currency = formValue.currency;
            initialFormValue.userId = formValue.userId;
            initialFormValue.participationPartyType = formValue.participationPartyType;
            initialFormValue.running = formValue.running;
            initialFormValue.privateProject = formValue.privateProject;

            formValue = initialFormValue;
        }
        return formValue;
    };



    namespace.getUTCTime = (time)=>{
        let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(time / 1000);
        return d;

    }

    namespace.getEndDate = (time,originalTime)=>{
        if(time === originalTime){
            return originalTime;
        }
        let d = new Date(time);
        d.setHours(0,0,0,0);
        return d.getTime();

    }

    namespace.getSpecialization = (specialization)=>{
        let specializations = [];
        if(specialization && specialization.length >0){
            for(let i = 0 ; i<specialization.length;i++){
                let data = {};
                data.id = i;
                data.text = specialization[i];
                specializations.push(data);
            }
        }
        return specializations;

    }



    namespace.getSkills = (skill)=>{
        let skills = [];
        if(skill && skill.length >0){
            for(let i = 0 ; i<skill.length;i++){
                let data = {};
                data.id = i;
                data.text = skill[i];
                skills.push(data);
            }
        }
        return skills;

    }

    namespace.getCountrySelectedValue = (formValue,entityType) => {

        let location = formUtils.getLocation(formValue,entityType);
        if(location && location.country){
            let data = {};
            data.name = 'country';
            data.label  = location.country;
            data.value = location.country;
            return data;
        }
        return '';
    };

    namespace.getStateSelectedValue = (formValue,entityType) => {
        let location = formUtils.getLocation(formValue,entityType);
        if(location && location.state){
            let data ={};
            data.name = 'state';
            data.label  = location.state;
            data.value = location.state
            return data;
        }
        return '';
    };


    namespace.getFees = (formValue) => {
        if(formValue ){
            let fees ={};
            fees.currency = formValue.currency;
            if(formValue.cost){
                fees.amount  = formValue.cost.toLocaleString().replace(/,/g, '');
            } else{
                fees.amount ='';
            }

            fees.value =formValue.cost;
            fees.packageType = formValue.packageType;
            fees.isNegotiable =formValue.isNegotiable;
            fees.isDiscloseble =formValue.isDisclosed
            return fees;
        }
        return {};
    };

    namespace.getInitialSpecializations = (formValue) => {
        if(formValue && formValue.specialization && formValue.specialization.length>0){
            let specialization  = []
            formValue.specialization && formValue.specialization.map((value)=>{
                specialization.push({value: value,label:value})
            });
            return specialization
        }
        return [];
    };

    namespace.getInitialSkills = (formValue) => {
        if(formValue && formValue.skills && formValue.skills.length>0){
            let skills = []
            formValue.skills && formValue.skills.map((value)=>{
                skills.push({value:value, label:value})
            })
            return skills;
        }
        return [];
    };
    namespace.getCitySelectedValue = (formValue,entityType) => {
        let location = formUtils.getLocation(formValue,entityType);
        if(location && location.city){
            let data = {};
            data.name = 'city';
            data.label  = location.city;
            data.value = location.city
            return data;
        }
        return '';
    };

    namespace.getQualifications = (formValue) => {
        let formValueQualifications = {};
         qualificationList = formUtils.getQualificationList(formValue);

        if(qualificationList.length>0){
            qualificationList.map((value,id)=>{
                let qualification = {
                    value: formValue.qualifications[id],
                    disabled: false,
                };
                formValueQualifications[value] = qualification;

            })
        }
        return formValueQualifications;
    };

    namespace.getQualificationList = (formValue) => {
        let qualifications = [];
        if(formValue && formValue.qualifications && formValue.qualifications.length>0){
            formValue.qualifications.map((value,index)=>{
                let id = Math.random() + "qualification" + qualifications.length;
                qualifications.push(id);
            })

        }
        return qualifications;
    };

    namespace.getCountry = (formValue,entityType) => {

        let location = formUtils.getLocation(formValue,entityType);
        if(location && location.country){
            return [{value:location.country, label:location.country}];
        }
        return '';
    };

    namespace.getState = (formValue,entityType) => {
        let location = formUtils.getLocation(formValue,entityType);
        if(location && location.state){
            return [{value:location.state,label:location.state}];
        }
        return '';
    };
    namespace.getCity = (formValue,entityType) => {
        let location = formUtils.getLocation(formValue,entityType);
        if(location && location.city){
            return [{value:location.city,label:location.city}];
        }
        return '';
    };

    namespace.getLocation = (formValue,entityType) => {
        let location = {};
        if(formValue && entityType){
            return  formValue.location;
        }
        return location;
    };

    namespace.getOptimizedImage = (personalInfo) => {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }



    namespace.formInitialData = () => {
      let  initialFormValue = {
          postedBy: {
              postedInBehalfOfOrganizationOrIndividual: {},
              value: "",
              type: "",
          },
          header: {},
          draftId: null,
          medias: {
              value: "",
              disabled: false,
          },
          paymentLink: {
              value: null,
              disabled: false,
          },
          endTakeAway: {
              value: "",
              disabled: false,
          },
          inclusions: {
              value: "",
              disabled: false,
          },
          exclusions: {
              value: "",
              disabled: false,
          },
          noOfDays: {
              disabled: false,
              value: 0,
          },
          durationStartDate: {
              value: null,
              disabled: false,
          },
          durationEndDate: {
              value: null,
              disabled: false,
          },
          startDate: {
              value: null,
              disabled: false,
          },
          endDate: {
              value: null,
              disabled: false,
          },
          applnEndDate: {
              value: null,
              disabled: false,
          },
          title: {
              value: null,
              disable: false,
          },
          faculty: {
              value: null,
              url: null,
              disable: false,
          },
          faculties: [],
          participant: {
              value: null,
              url: null,
              disable: false,
          },
          participants: [],
          companyName: {
              value: null,
              disabled: false,
          },
          website: {
              value: null,
              disabled: false,
          },
          shortBrief: {
              value: null,
              disabled: false,
          },
          detailedDescription: {
              value: null,
              disabled: false,
          },
          role: {
              value: null,
              disabled: false,
          },
          attachments: {
              value: [],
              disabled: false,
          },
          noOfOpenings: {
              value: null,
              disabled: false,
          },
          address: {
              value: null,
              disabled: false,
          },
          fees: {
              value: null,
              amount: null,
              currency: "INR",
              isNegotiable: false,
              isDiscloseble: false,
          },
          isAgree: {
              value: false,
              disabled: false,
          },
          coverImage: {
              value: null,
              disable: false,
          },
          qualifications: {},
          relevantHashtags: [],
          skills: [],
          specialization: [],
          isPreSubmissionValidated: false,
      };
      return initialFormValue;
    }

    namespace.getAttachments = (attachments) => {
        if(!attachments){
            return attachments;
        }
        if(attachments.length >0){
            let attachedFiles = [];
            attachments.map((attach)=>{
               let file = {};
               //file.name = attach.substring(attach.indexOf("-") + 1);
                file.name = attach.slice(attach.lastIndexOf("-")+1,attach.length);
                attachedFiles.push(file);
            })
            attachments = attachedFiles;
        }
        return attachments;

    }

    namespace.getParticipantList = (participantList) => {
        if(!participantList){
            return participantList;
        }
        let participants = [];
        if(participantList.length >0){
            participantList.map((userInfo)=>{
                let user = {
                    value: userInfo.username,
                    url: userInfo.personalInfo && userInfo.personalInfo.profileImage && !_.isNull(userInfo.personalInfo.profileImage) ? getCDNUrl(formUtils.getOptimizedImage(userInfo.personalInfo))  : "https://cdn.dscovr.com/images/users1.png",
                    id: userInfo.id,
                };
                participants.push(user);
            })

        }
        return participants;

    }


    namespace.getAddress = (formValue,entityType) => {
        let location = formUtils.getLocation(formValue,entityType);
        if(location && location.address){
            return location.address;
        }
        return '';
    }

    namespace.checkValidUrl = (url) => {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
    };

    namespace.formatDate = (date) => {
        return dateFormat(date, "isoDate");
    };

    namespace.submissionDate = (date) => {
        let dt = new Date(
            date.toDateString() +
            new Date().toString().substring(new Date().toString().indexOf(":") - 3)
        );
        return dateUtil.format(dt, "YYYY-MM-DD HH:mm:ss", true);
    };

    namespace.fromLocalDateTime = (input) => {
        var day = input.dayOfMonth;
        var month = input.monthValue - 1; // Month is 0-indexed
        var year = input.year;
        let hour = input.hour;
        let min = input.minute;
        let sec = input.second;
        var date = new Date(Date.UTC(year, month, day, hour, min, sec));

        return date;
    };


    namespace.handleJsonCharacter =(value)=>{
        return value.replace(/\\/g,'\\\\').replace(/"/g,'\\"')
    }

    namespace.handleChange = (e, setState) => {
        // get the files
        let files = e.target.files;
        // Process each file
        let allFiles = [];
        for (let i = 0; i < files.length; i++) {
            //multipart files
            let file = files[i];

            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);



            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + " kB",
                    base64: reader.result,
                    file: file,
                };

                // Push it to the state
                allFiles.push(fileInfo);

                // If all files have been proceed
                if (allFiles.length >= files.length) {
                    setState(allFiles);

                    return allFiles;
                }

                return false;
            };
        }
    };

    namespace.unique = (array, propertyName) => {
        return array.filter(
            (e, i) =>
                array.findIndex((a) => a[propertyName] === e[propertyName]) === i
        );
    };

    namespace.downloadFile = (name, type, base64EncodedString) => {
        // const linkSource = `data:application/pdf;base64,${base64EncodedString}`;
        const linkSource = `${type},${base64EncodedString}`;
        const downloadLink = document.createElement("a");
        let id = uuid();
        downloadLink.setAttribute("id", id);
        const fileName = name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        // downloadLink.remove();
    };

    namespace.defaultFormsConfig = () => {
        return {
            postedBy: {
                postedInBehalfOfOrganizationOrIndividual: {},
                value: "",
                type: "",
            },
            medias: {
                value: "",
                disabled: false,
            },
            endTakeAway: {
                value: "",
                disabled: false,
            },
            coverImage: {
                value: "",
            },
            inclusions: {
                value: "",
                disabled: false,
            },
            exclusions: {
                value: "",
                disabled: false,
            },
            state: {
                placeholder: "",
                value: "",
                disabled: false,
            },
            country: {
                placeholder: "",
                value: "India",
                disabled: false,
            },
            isNegotiable: false,
            isDiscloseble: false,
            city: {
                placeholder: "",
                value: "",
                disabled: true,
            },
            noOfDays: {
                disabled: false,
                value: 0,
            },
            durationStartDate: {
                value: null,
                disabled: false,
            },
            durationEndDate: {
                value: null,
                disabled: true,
            },
            startDate: {
                value: null,
                disabled: false,
            },
            endDate: {
                value: null,
                disabled: false,
            },
            applnEndDate: {
                value: null,
                disabled: false,
            },
            title: {
                value: null,
                disable: false,
            },
            companyName: {
                value: null,
                disabled: false,
            },
            website: {
                value: null,
                disabled: false,
            },
            shortBrief: {
                value: null,
                disabled: false,
            },
            detailedDescription: {
                value: null,
                disabled: false,
            },
            role: {
                value: null,
                disabled: false,
            },
            attachments: {
                value: null,
                disabled: false,
            },
            noOfOpenings: {
                value: null,
                disabled: false,
            },
            address: {
                value: null,
                disabled: false,
            },
            fees: {
                value: null,
                amount: null,
                currency: "INR",
                isNegotiable: false,
                isDiscloseble: false,
            },
            isAgree: {
                value: false,
                disabled: false,
            },
            faculty: {
                value: null,
                url: null,
                disable: false,
            },
            faculties: [],
            participants: [],
            qualifications: {},
            relevantHashtags: [],
            skills: [],
            specialization: [],
        };
    };
    return namespace;
})();

export default formUtils;
