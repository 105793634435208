import axios from "axios";
import { Apis } from "../config/api";
import { authService } from "../auth";

const loginSuccess = authService.isAuthenticated();

export const getPostMetatagsById = async (id) => {
    try {
        const response = await axios.get(Apis.PostMetaTagsById(id));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getScrappedData = async (postData) => {
    try {
        const response = await axios.post(Apis.UrlScrapping, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return "https://cdn.beegram.net/posts/1000x1000-64d36059a434de0d18f97f5d-1691574362481.png";
    }
}

export const pageClickHookPostMethod = async (postData) => {
    try {
        if(!loginSuccess) {
            postData.deviceToken = localStorage.getItem("deviceuuID");
        }
        const response = await axios.post(Apis.Pageclickhookpost, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getViewImpressionCount = async (id,entityType) => {
    try {
        const response = await axios.get(Apis.GetViewImpressionCount(id,entityType));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getViewUserRestriction = async (entityId) => {
    try {
        const response = await axios.get(Apis.GetViewUserRestriction(entityId));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}
