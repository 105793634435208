import React from "react";
import ReadMoreReadLess from "./ReadMoreReadLess";
import ReportAbuse from "./ReportAbuse";
import { authService } from "../auth";
import TimeAgo from "javascript-time-ago";
import * as FileSaver from "file-saver";
import uuid from "react-uuid";
import axios from "axios";
import AddReplyCommentBox from "./AddReplyCommentBox";
import { getCDNUrl, getFileIcon, isWebView, postMsg, redirectToProfile } from "./CommonFunctions";
// English.
import en from "javascript-time-ago/locale/en";
import ReplyCommentBox from "./ReplyCommentBox";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import MentionTextArea from "./MentionTextArea";
import MutualConnect from "./MutualConnect";
import Follow from "./Follow";
import UserReactionComponent from "../UserReaction/UserReactionComponent";
import EntityFollow from "./EntityFollow";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

class Comment extends React.PureComponent {
  constructor(props) {
    super(props);
    let validation = {
      commentText: {},
    };
    this.state = {
      userData: authService.getUserDetailsFromLocalStorage(),
      editedText: props.item.userComment,
      isEditDisable: true,
      disable: false,
      deleted: false,
      comments: "",
      validation,
      replyCount: props.item.replies.length,
      hideReplyBox: false,
      replies: props.item.replies,
      page: 0,
      size: 10000,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps &&
      nextProps.item &&
      nextProps.item.userComment &&
      nextProps.item.userComment !== this.state.editedText
    ) {
      this.setState({ editedText: nextProps.item.userComment });
    }
  }

  componentDidMount() {
    window
      .$(".commenttype .form-control, .commenttypeBtnsInn")
      .on("click", function (event) {
        window.$(".commenttype").addClass("active");
      });
    window.$(document).on("click", function (event) {
      if (!window.$(event.target).closest(".commenttype form").length) {
        window.$(".commenttype").removeClass("active");
      }
    });

    //window.$(".textarea-scrollbar").scrollbar();

    window
      .$(".forumcommtvlistwrap.scrollbar-primary .editbtn")
      .on("click", function (event) {
        window
          .$(this)
          .closest(".forumcommtvlistwrap.scrollbar-primary")
          .addClass("current");
      });
  }


  getCommentOptimizedImage(attachment) {
    if (attachment.resizedImages && attachment.resizedImages['300x300'] != null) {
      return attachment.resizedImages['300x300'];
    }
    return attachment.attachmentUrl;
  }

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.profileImage;
  }

  triggerReportClose = () => {
    this.setState({ openReportModal: null });
  };

  onCommentClose = () => {
    this.setState({
      isEditDisable: true,
      editedText: this.props.item.userComment,
    });
    window
      .$(".forumcommtvlistwrap.scrollbar-primary.current")
      .removeClass("current");
  };

  getAllComments = (page, size) => {
    axios({
      method: "get",
      url:
        process.env.REACT_APP_userServiceURL +
        "/comment/getCommentsByActivityId/" +
        this.props.item.id +
        "?userId=" +
        this.state.userData.userId +
        "&page=" +
        page +
        "&size=" +
        size,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.message === "Success!") {
          this.setState({
            replies: response.data.body.content.reverse(),
            replyCount: response.data.body.totalElements,
          });
          if (this.props.callback) {
            this.props.callback();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hideReplyBox = () => {
    if (this.state.hideReplyBox === false) {
      this.setState({
        hideReplyBox: true,
      });
    } else if (this.state.hideReplyBox === true) {
      this.setState({
        hideReplyBox: false,
      });
    }
  };

  download_file_pdf = (evt, url) => {
    evt.preventDefault();
    var parsedUrl = url.substring(url.lastIndexOf("/") + 1);
    let filename = parsedUrl.substring(parsedUrl.indexOf("-") + 1);
    FileSaver.saveAs(url, filename);
  };

  componentWillUnmount() {
    window.$("#confirmpopForDeleteComment" + this.props.item.id).modal("hide");
  }

  openDeleteModal = () => {
    window.$("#confirmpopForDeleteComment" + this.props.item.id).modal("show");
  };

  deleteComment = (id) => {
    axios({
      method: "get",
      url:
        process.env.REACT_APP_userServiceURL +
        "/backend/comment/delete?commentId=" +
        id,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        this.setState({ disable: false });
        let res = response.data;
        if (res.status === "202 ACCEPTED") {
          window
            .$("#confirmpopForDeleteComment" + this.props.item.id)
            .modal("hide");
          this.setState({ deleted: true });
          if (this.props.callback) {
            this.props.callback();
          }
        }
      })
      .catch((err) => {
        this.setState({ disable: false });
        this.setState({
          isLoaded: true,
          error: { message: err.response.data.message, err: err.response },
        });
      });
  };

  // validate comment
  validate_comment = () => {
    let errors = {};
    let formIsValid = true;
    if (
      this.state.disable ||
      !this.state.editedText ||
      this.state.editedText.trim("\n") === ""
    ) {
      errors["commentText"] = "Please write comment to publish";
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleCommentSubmit = () => {
    if (this.validate_comment()) {
      let formData = {
        commentId: this.props.item.id,
        description: this.state.editedText
          ? this.state.editedText.trim("\n")
          : this.state.editedText,
      };
      axios({
        method: "post",
        url: process.env.REACT_APP_userServiceURL + "/backend/comment/edit",
        data: formData,
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
        .then((response) => {
          let res = response.data;
          if (res.status === "202 ACCEPTED") {
            this.setState({ isEditDisable: true });
            if (this.props.refresh) {
              this.props.refresh();
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoaded: true,
            error: { message: err.response.data.message, err: err.response },
          });
        });
      window
        .$(".forumcommtvlistwrap.scrollbar-primary.current")
        .removeClass("current");
    }
    setTimeout(() => {
      this.setState({
        error: "",
        validation: {},
      });
    }, 5000);
  };
  handleCommentChange = (event) => {
    this.setState({ editedText: event.target.value });
    if (event.target.value.length > 0) {
      this.setState({ disable: false });
    } else {
      this.setState({ disable: true });
    }
  };

  scriptCall = (event) => {
    window.$(".commenttype.inn").addClass("active");
  };

  addHideReplyCommentBox = () => {
    this.setState({ hideReplyBox: false });
  };
  openUpdateModal = (id) => {
    this.setState({ isEditDisable: false }, () => {
      //window.$(".textarea-scrollbar").scrollbar();
    });
  };

  render() {
    var url = window.location.pathname;
    var utcSeconds = this.props.item.time;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds / 1000);
    let uniqueKey = uuid();
    return (
      <>
        {this.state.isEditDisable === false ? (
          <span className="overlay" />
        ) : null}
        <div className="forumcommtvlist">
          {!this.state.deleted && (
            <div className="frcommtlisttop">
              <div className={"forumcommtvlisttop" + (this.props.item.userType !== "INDIVIDUAL" ? " orgnz" : "")}>
                <div className="fuserblk">
                  <figure onClick={(e) => {
                        e.preventDefault();
                        redirectToProfile(this.props.item.customUrl, this.props.item.userId, this.props.item.userType, this.state.userData.userId)
                    }} style={{cursor: 'pointer'}}>
                      <img src={this.props.item.profileImage ? getCDNUrl(this.getOptimizedImage(this.props.item)) : this.props.item.userType == "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.png" : this.props.item.userType === "CIRCLE" ? "https://cdn.dscovr.com/images/circleDefault.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"} alt="" />
                  </figure>
                  <div>
                    <h3>
                      <a href="javascript:void(0)" onClick={(e) => redirectToProfile(this.props.item.customUrl, this.props.item.userId, this.props.item.userType, this.state.userData.userId)}
                       style={{ margin: "0" }}
                      >
                        {this.props.item.userName}
                      </a>
                      {this.props.item.connectDepth && !this.props.item.deactivated &&
                        this.props.item.userId != this.state.userData.userId ? (
                        <MutualConnect
                          connectDepth={this.props.item.connectDepth}
                        ></MutualConnect>
                      ) : null}
                      {!this.props.item?.postRestriction && 
                      (this.props.item.connectStatus === "PENDING_CONNECT" ? (
                        <>
                          <a
                            href="javascript:void(0)"
                            className="follow follow-link active"
                          >
                            <i className="iTickedUser" /> Pending
                          </a>
                        </>
                      ) : (
                        this.props.item && !this.props.item.deactivated &&
                        (!this.props.item.connectDepth ||
                          (this.props.item.connectDepth &&
                            this.props.item.connectDepth < 1)) && (
                          this.props.item.userType !== "CIRCLE" ? 
                          <Follow
                            followed={this.props.item.followed}
                            userId={this.props.item.userId}
                          /> :
                          <EntityFollow entityType={'CIRCLE'} 
                              entityId={this.props.item.userId}
                              followed={this.props.item.followed}
                              userId={this.state.userData.userId}
                          />
                        )
                      ))}
                    </h3>
                    <span>{timeAgo.format(d, "mini-now")}</span>
                  </div>
                </div>
                {!this.props.item.deactivated && 
                  <div className="fdate">
                    {/* {dateFormat(this.props.item.time, "mmmm d 'at' h:MM tt")} */}
                    <div className="contxmenu">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="dropdown"
                      >
                        <i className="iKebab" />
                      </button>
                      <div className="dropdown-menu fadeIn animated">
                        {
                          this.props.item.edit && (
                            <span
                              className="dropdown-item editbtn"
                              onClick={(e) => {
                                this.openUpdateModal(this.props.item.id);
                              }}
                            >
                              <i className="iEditBox" /> Edit
                            </span>
                          )}
                        {this.props.item.edit && (
                            <span
                              className="dropdown-item"
                              onClick={(e) => {
                                this.openDeleteModal(this.props.item.id);
                              }}
                            >
                              <i className="iTrashBin" /> Delete
                            </span>
                          )}
                        {!this.props.item.edit && !this.props.item?.postRestriction && (
                            <a
                              className="dropdown-item"
                              href="javascript:void(0)"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openReportModal: this.props.item.id,
                                });
                              }}
                            >
                              <i className="iReportComment_OL" /> Report
                            </a>
                          )}
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div
                className="modal fade dgpopup confirmpop"
                id={"confirmpopForDeleteComment" + this.props.item.id}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    {/* <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Delete Comment Confirmation</h5>                                    
                                </div> */}
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="confirm-popup-modal-body">
                      Are you sure you want to delete this comment?
                    </div>
                    <div className="modal-footer confirm-popup-modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.deleteComment(this.props.item.id)}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="forumcommtvlistdesc">
                {(this.state.isEditDisable && this.state.editedText != "") && (
                  <ReadMoreReadLess
                    id={this.props.item.id}
                    description={this.state.editedText.trim("\n")}
                  />
                )}
                {!this.state.isEditDisable &&
                  <div className="commenttype inn">
                    <form action="">
                      <MentionTextArea
                        className="form-control edit textarea-scrollbar scrollbar-outer"
                        value={this.state.editedText
                          .replace(/<br\/>/g, "\n")
                          .replace(/&nbsp;/g, " ")}
                        onChange={this.handleCommentChange}
                        onClick={this.scriptCall}
                        placeholder="Write a comment..."
                      ></MentionTextArea>
                      <span
                        className="login-feedback-error mt-1"
                        style={{ display: "block" }}
                      >
                        {this.state.errors.commentText}
                      </span>
                      <div className="commenttypeBtns">
                        <div className="commenttypeBtnsInn">
                          <button
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleCommentSubmit();
                            }}
                            disabled={
                              this.state.disable ||
                              !this.state.editedText ||
                              this.state.editedText.trim("\n") === ""
                            }
                            className="butt"
                          >
                            <i className="iSend_fl" />
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="text-right">
                      <button
                        className="btn cancel"
                        onClick={this.onCommentClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                }
                <div className="attachImgall">
                  {this.props.item.attachmentIds.map((attachment, index) => {
                    return (
                      attachment.attachmentType === "IMAGE" && (
                        <a
                          data-fancybox={this.props.item.id}
                          href={attachment.attachmentUrl}
                          className="pdescattachfile"
                          key={index}
                        >
                          <img src={getCDNUrl(this.getCommentOptimizedImage(attachment))} />
                        </a>
                      )
                    );
                  })}
                </div>
                <div className="forumcommtvlistbott">
                  {this.props.item.attachmentIds &&
                    this.props.item.attachmentIds.length > 0 ? (
                    <a
                      href={"#attachlistCommtpop" + uniqueKey}
                      className="fattach"
                      data-toggle="modal"
                    >
                      <i className="iLink" />
                      {this.props.item.attachmentIds.length === 1
                        ? this.props.item.attachmentIds.length + " File "
                        : this.props.item.attachmentIds.length + " Files "}{" "}
                      Attached
                    </a>
                  ) : (
                    <div className="fattach" />
                  )}
                  <div
                    id={"attachlistCommtpop" + uniqueKey}
                    className="modal fade dgpopup attachlistpop"
                    tabIndex="-1"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="forwcontwrap">
                            <div className="forwcontwraptop">
                              <h4>Attachments</h4>
                              <Scrollbars
                                className={"forwcontlistmain contactlist"}
                                autoHide
                                autoHeight
                                autoHeightMin={0}
                                autoHeightMax={322}
                                universal={true}
                                renderTrackHorizontal={(props) => (
                                  <div
                                    {...props}
                                    className="track-horizontal"
                                  />
                                )}
                                renderTrackVertical={(props) => (
                                  <div {...props} className="track-vertical" />
                                )}
                              >
                                <ul>
                                  {this.props.item.attachmentIds.map(
                                    (attachment) => {
                                      var url =
                                        attachment.attachmentUrl.substring(
                                          attachment.attachmentUrl.lastIndexOf(
                                            "/"
                                          ) + 1
                                        );
                                      return (
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="fattach"
                                            target="_blank"
                                            onClick={(e) => {
                                              isWebView()
                                                ? postMsg(
                                                  "download",
                                                  attachment.attachmentUrl
                                                )
                                                : this.download_file_pdf(
                                                  e,
                                                  attachment.attachmentUrl
                                                );
                                            }}
                                          >
                                            {getFileIcon(attachment.attachmentUrl)}
                                            {/* <i className="iLink" /> */}
                                            {/* {attachment.attachmentUrl.substring(attachment.attachmentUrl.lastIndexOf('/', attachment.attachmentUrl.indexOf("-") + 1)+1)} */}
                                            {url.substring(
                                              url.indexOf("-") + 1
                                            )}
                                          </a>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </Scrollbars>
                            </div>

                            <div className="forwcontwrapbott">
                              <button
                                className="butt btnsecondary close"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="forumrowbright">
                    <a
                      href="javascript:void(0)"
                      className="reply"
                      onClick={this.hideReplyBox}
                    >
                      {this.state.replyCount}{" "}
                      {this.state.replyCount > 1 ? "Replies" : "Reply"}
                    </a>
                    <UserReactionComponent item={this.props.item} key={this.props.item.id} />
                  </div>
                </div>
                {this.state.hideReplyBox === true ? (
                  <AddReplyCommentBox
                    commentId={this.props.item.id}
                    addHideReplyCommentBox={this.addHideReplyCommentBox}
                    getAllComments={this.getAllComments}
                  />
                ) : null}
                {this.state.replies &&
                  this.state.replies.map((reply) => {
                    return (
                      <ReplyCommentBox
                        hideReplyCommentBox={this.state.hideReplyBox}
                        item={reply}
                        key={reply.id}
                        callback={this.getAllComments}
                        hideReplyBox={this.hideReplyBox}
                        commentId={this.props.item.id}
                        addHideReplyCommentBox={this.addHideReplyCommentBox}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        {this.state.openReportModal &&
          this.state.openReportModal === this.props.item.id && (
            <ReportAbuse
              entityId={this.props.item.id}
              entityType={"COMMENT"}
              triggerReportClose={this.triggerReportClose}
            />
          )}
      </>
    );
  }
}

export default Comment;
