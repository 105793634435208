import React, {PureComponent} from 'react';
import {convertUnicode, getCDNUrl} from "../commons/CommonFunctions";
import * as _ from "lodash";
import {setProjectDetails} from "../reducer/showAllReducer";
import {connect} from "react-redux";

class ProjectSearchItem extends PureComponent {

    redirect = () => {
        document.body.classList.remove('searchDropActive');
        if (this.props.item && this.props.item.feedPageShow) {
            window.location.href = '/project/feeds/' + this.props.item.slug;
        } else{
            
                window.$('#projectDetails').modal('show');
                this.props.setProjectDetails(this.props.item);

        }

    }

    getOptimizedImage(data) {
        if (data.resizedImages && data.resizedImages['300x300'] != null) {
            return data.resizedImages['300x300'];
        }
        return data.coverImage;
    }

    render() {

        return (
            <li>
                <a href="javascript:void(0)" onClick={this.redirect}>
                    <div className="srchpostmain">
                        <figure className="users">
                            <img
                                src={this.props.item.coverImage ? getCDNUrl(this.getOptimizedImage(this.props.item)) : !_.isUndefined(this.props.item.coverImage) ? 'https://cdn.dscovr.com/images/project-default.jpg' : null}
                                alt=""/>
                        </figure>
                        <div>
                            <h5>{convertUnicode(this.props.item.title)}</h5>
                        </div>
                    </div>
                    <div className="srchposttime">
                        {
                            this.props.item.location
                        }
                    </div>
                </a>
            </li>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setProjectDetails: (projectDetails) =>
            dispatch(setProjectDetails(projectDetails)),
    };
};

export default connect(null, mapDispatchToProps)(ProjectSearchItem);

