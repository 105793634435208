import React, { Component } from 'react'

export default class PostRedirectionPop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postId: this.props.postId
        }
    }

    showUser = () => {
        window.location.href = "/post/" + this.state.postId;
    };
    
    render() {
        return (
            <div className="modal fade dgpopup confirmpop"
                id={"AcceptPopUp"}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="confirm-popup-modal-body">
                            Your post is {this.props.type === "UPDATE" ? "updated" : "posted"} Successfully
                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.showUser();
                                }}
                            >
                                View
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
