import React, { Component, useEffect, useState } from "react";
import { detailsPageRedirection, getCDNUrl, redirectToCircle } from "../commons/CommonFunctions";
import TimeAgo from "javascript-time-ago";
import Images from "../commons/Images";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { defaultProjectImage } from "../project/component/Studio/StudioContentCommonFunction";

const timeAgo = new TimeAgo("en-US");

class PhotosUnauthItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoListMap: [],
      photoListOriginalMap: [],
      paidContentPostLock: false,
    };
  }


  componentDidMount() {
    let photoListMap = [];
    let photoListOriginalMap = [];
    this.props.item && this.props.item.attachmentIds && this.props.item.attachmentIds.map((attachment, index) => {
        photoListOriginalMap.push(getCDNUrl(attachment.attachmentUrl));
        if (window.innerWidth > 1000 && attachment.resizedImages && attachment.resizedImages['1000x1000'] != null) {
            photoListMap.push(getCDNUrl(attachment.resizedImages['1000x1000']));
        } else if (window.innerWidth > 600 && window.innerWidth <= 1000 && attachment.resizedImages && attachment.resizedImages['600x600'] != null) {
            photoListMap.push(getCDNUrl(attachment.resizedImages['600x600']));
        } else if (window.innerWidth > 320 && window.innerWidth <= 600 && attachment.resizedImages && attachment.resizedImages['400x400'] != null) {
            photoListMap.push(getCDNUrl(attachment.resizedImages['400x400']));
        } else {
            photoListMap.push(getCDNUrl(attachment.attachmentUrl));
        }
    })
      if(this.props.item && this.props.item.attachmentIds.length === 0 && this.props.item.paidPost) {
        photoListMap.push(getCDNUrl(defaultProjectImage.attachmentUrl));
        photoListOriginalMap.push(getCDNUrl(defaultProjectImage.attachmentUrl));
        this.setState({
            paidContentPostLock: true
        })
    }
    this.setState({'photoListMap': photoListMap, 'photoListOriginalMap': photoListOriginalMap,});
  }

  render() {
    return (      
      <div className="hpostconttop">
        <a hreg="javascript:void(0)" onClick={(e) => detailsPageRedirection(this.props.item?.id)}>
          {this.props.item && (this.props.item.description || (this.props.item.hashTags && this.props.item.hashTags.length > 0)) &&
              <ReadMoreReadLess id={this.props.item.id} description={this.props.item.description} hashTags={this.props.item.hashTags} />
          }
          {this.state.photoListMap && this.state.photoListMap.length > 0 && (
            <div className="staticIMG">
              <span></span>
              <Images
                images={this.state.photoListMap}
                countFrom={3}
                photoListOriginalMap={this.state.photoListOriginalMap}
                paidContentPostLock={this.state.paidContentPostLock}
              />
            </div>
          )}
          </a>
        </div>
    )
  }
}

export default PhotosUnauthItem;
