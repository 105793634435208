import React from "react";
import { Component } from "react";
import { getResizedImage } from "./CommonFunctions";
import { ReactTinyLink } from "react-tiny-link";
import { authService } from "../auth";
import axios from "axios";
import SendMessageModal from "./SendMessageModal";

class PaymentPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            paymentLinkOgData: {},
            openSendMessageModal: false,
        }
    }

    componentWillUnmount() {
        window.$("#payNowPop").modal("hide");
    }

    paymentCloseScroll() {
        window.$('#payNowPop').modal('hide');
    }

    getLinkPreviewUrl = (text) => {
        let data = {
            url : text
        }
        axios({
            method: 'POST',
            url : `${process.env.REACT_APP_userServiceURL}/recommend/ogscrapper`,
            data: data,
            withCredentials: true
        }).then((response)=>{
            if(response?.data?.status === 200){
                this.setState({
                    paymentLinkOgData: response?.data?.data
                })
            }
        }).catch((error)=>{
            console.log("Error",error);
        })
    };

    sendMessageButtonClick = () => {
        this.setState({openSendMessageModal: true});
    }
    triggerMessageModalClose = () => {
        this.setState({openSendMessageModal: false});
    }

    componentDidMount() {
        if(this.props.paymentDetails?.paymentUrl) {
            this.getLinkPreviewUrl(this.props.paymentDetails?.paymentUrl)
        }
    }

    render() {
        const { paymentDetails } = this.props;
        const { openSendMessageModal } = this.state;
        return (
            <div id="payNowPop" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="payNowPop">
                                <button type="button" className="close topCrossModal" data-dismiss="modal" aria-label="Close" onClick={this.props.triggerPaymentClose}>
                                    <i className="iCross" /></button>
                                    <div className="disclaimer">
                                        {this.props.entityType !== "STUDIO" ?
                                            <p>Payments will be directly remitted to the selected {this.props.entityType.toLowerCase() ==='circle'?'community':this.props.entityType.toLowerCase()} account. You may contact the {this.props.entityType.toLowerCase() ==='circle' ? 'community' : this.props.entityType.toLowerCase()} super admin for payment related queries. 
                                                Please mention the Transaction ID generated after the payment to the super admin. 
                                                Your contribution will be reflected in the progress bar once the funds are received and acknowledged by the project super admin. <a href="javascript:void(0)" onClick={(e) => { e.preventDefault(); this.sendMessageButtonClick()}}> Contact Admin</a>
                                            </p>
                                            :
                                            <p>Select a payment option. Payments will be directly remitted to the selected project account. You may contact the project <a href="javascript:void(0)" onClick={this.paymentCloseScroll}>helpdesk</a> for payment related queries. Please mention the Transaction ID generated after
                                                the payment to the helpdesk. You can view the requested content once your payment is verified.</p>
                                        }
                                    </div>
                                    {openSendMessageModal &&
                                        <SendMessageModal profileId ={this.props.profileId} userName = {this.props.userName} triggerMessageModalClose={this.triggerMessageModalClose}/>
                                    }
                                {/* {(!paymentDetails.qrCode || !paymentDetails.paymentUrl) && <h2>Pay Now</h2>} */}
                                {paymentDetails.qrCode && 
                                    <div className="payNowPopRow qrPay">
                                        <h3>Pay via QR Scan</h3>
                                        <div className='qrBox'>
                                            <figure><img src={getResizedImage(paymentDetails.qrCode)} alt='' /></figure>
                                        </div>
                                    </div>
                                }
                                {(paymentDetails.qrCode && paymentDetails.paymentUrl) && <h3 className="payment-or-section">or</h3>}
                                <div className="payNowPopRow linkpay text-center">
                                    {paymentDetails.paymentUrl &&
                                        <>
                                            <button type="button" className="payNow" onClick={()=>{window.location.href = paymentDetails.paymentUrl}}>Proceed to Bank</button>
                                            {/* <div className="postarticledesc">
                                                <div className="ownLink">
                                                    <div className="customLinkPost" onClick={()=>{
                                                        window.location.href = paymentDetails.paymentUrl
                                                        }}>
                                                        <figure><img src={paymentLinkOgData?.image} alt={paymentLinkOgData?.title}/></figure>
                                                        <div className="customLinkPostDesc">
                                                            <h5>{paymentLinkOgData?.title}</h5>
                                                            <p>{paymentLinkOgData?.description}</p>
                                                            <p>{paymentDetails.paymentUrl}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </>
                                    }
                                    {/* <div className='payNowLinkRow'>
                                        <div className='payNowLinkGrid'>
                                            <a href='javascript:void(0)'><img src='https://cdn.dscovr.com/images/googlePay.png' alt='' /></a>
                                        </div>
                                        <div className='payNowLinkGrid'>
                                            <a href='javascript:void(0)'><img src='https://cdn.dscovr.com/images/phonePay.png' alt='' /></a>
                                        </div>
                                        <div className='payNowLinkGrid'>
                                            <a href='javascript:void(0)'><img src='https://cdn.dscovr.com/images/amazonPay.png' alt='' /></a>
                                        </div>
                                        <div className='payNowLinkGrid'>
                                            <a href='javascript:void(0)'><img src='https://cdn.dscovr.com/images/paytmPay.png' alt='' /></a>
                                        </div>
                                    </div> */}
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentPopUp;
