import React, { PureComponent } from "react";
import {
  convertUnicode,
  getCDNUrl,
  nFormatter,
  postMsg,
  isWebView,
} from "../commons/CommonFunctions";
import axios from "axios";

import * as _ from "lodash";
import { Link } from "react-router-dom";
import { authService } from "../auth";
import OwlCarousel from "react-owl-carousel";
import uuid from "react-uuid";
// import ProjectDescription from "../project/component/project-component/projectDescription";
// import ProjectDetails from "../project/component/commons/ProjectDetails";
import mappingUtils from "../project/component/commons/utils/mappingUtils";
import dateUtil from "date-and-time";
import Linkify from "react-linkify";
import { renderToString } from "react-dom/server";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");
class ProjectSharePreview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userData: authService.getUserDetailsFromLocalStorage(),
      projectDetail: {},
      isModalOpen: false,
    };
  }

  componentDidMount() {}

  getProjectDetailsById = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/participants/find-project-description-details-by-userId-and-projectId/${this.state.userData.userId}/${this.props.item.id}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.statusCode === 200) {
          this.setState({
            projectDetail: response.data.body,
            isModalOpen: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  openModalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  redirectToProfile = (customUrl, partyType) => {
    if (partyType === "CIRCLE") {
      window.location.href = "/community/" + customUrl;
    } else {
      window.location.href = "/profile/" + customUrl;
    }
  };

  getOptimizedImage = (personalInfo, defaultImg) => {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return defaultImg;
  };

  render() {
    var utcSeconds = this.state.projectDetail.dateOfPosting;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds / 1000);

    const { loginSuccess } = this.state;
    let noOfDaysPosted = mappingUtils.getNoOfDaysAfterPosted(
      this.state.projectDetail && this.state.projectDetail.dateOfPosting
    );
    let title =
      this.state.projectDetail &&
      this.state.projectDetail.project &&
      this.state.projectDetail.project.title;
    let location =
      this.state.projectDetail &&
      this.state.projectDetail.project &&
      this.state.projectDetail.project.location;
    let fees =
      this.state.projectDetail &&
      this.state.projectDetail.project &&
      this.state.projectDetail.project.cost;

    let attachments = [];
    if (
      this.state.projectDetail &&
      this.state.projectDetail.project &&
      this.state.projectDetail.project.attachments &&
      this.state.projectDetail.project.attachments.length > 0
    ) {
      let attachedFiles = this.state.projectDetail.project.attachments;
      attachedFiles.map((data, index) => {
        let value = {
          name: data.substring(data.lastIndexOf("/") + 1),
          url: data,
        };
        attachments.push(value);
      });
    }
    let showProjectType;
    let projectType =
      this.state.projectDetail &&
      this.state.projectDetail.participationDetails &&
      this.state.projectDetail.participationDetails.projectType;
    let secondaryEntityType =
      this.state.projectDetail &&
      this.state.projectDetail.participationDetails &&
      this.state.projectDetail.participationDetails.secondaryEntityType;
    if (
      secondaryEntityType &&
      projectType &&
      !_.isNull(secondaryEntityType) &&
      !_.isNull(projectType)
    ) {
      if (projectType.toUpperCase() === "VOLUNTEER") {
        if (secondaryEntityType.toUpperCase() === "JOB") {
          showProjectType = "VOLUNTEERING JOB";
        } else if (secondaryEntityType.toUpperCase() === "ASSIGNMENT") {
          showProjectType = "VOLUNTEERING ASSIGNMENT";
        } else if (secondaryEntityType.toUpperCase() === "EVENT") {
          showProjectType = "VOLUNTEERING EVENT";
        } else if (secondaryEntityType.toUpperCase() === "TRAINING") {
          showProjectType = "VOLUNTEERING TRAINING";
        } else if (secondaryEntityType.toUpperCase() === "EXPEDITION") {
          showProjectType = "VOLUNTEERING EXPEDITION";
        } else if (secondaryEntityType.toUpperCase() === "STORYBOOK") {
          showProjectType = "VOLUNTEERING STORYBOOK";
        } else if (secondaryEntityType.toUpperCase() === "FUNDRAISE") {
          showProjectType = "VOLUNTEERING FUNDRAISE";
        }
      } else {
        showProjectType = projectType;
      }
    } else {
      showProjectType = projectType;
    }

    let role =
      this.state.projectDetail &&
      this.state.projectDetail.project &&
      (this.state.projectDetail.project.jobRole ||
        this.state.projectDetail.project.assignmentRole);
    let qualifications =
      this.state.projectDetail &&
      this.state.projectDetail.project &&
      (this.state.projectDetail.project.qualifications ||
        this.state.projectDetail.project.qualificationRequired);
    let typeContent;
    if (
      this.state.projectDetail &&
      this.state.projectDetail.participationDetails &&
      (this.state.projectDetail.participationDetails.secondaryEntityType ===
        "EVENT" ||
        this.state.projectDetail.participationDetails.secondaryEntityType ===
          "STORYBOOK" ||
        this.state.projectDetail.participationDetails.secondaryEntityType ===
          "FUNDRAISE" ||
        this.state.projectDetail.participationDetails.secondaryEntityType ===
          "EXPEDITION")
    ) {
      typeContent = "Mementos or Event Kits";
    } else if (
      this.state.projectDetail &&
      this.state.projectDetail.participationDetails &&
      this.state.projectDetail.participationDetails.secondaryEntityType ===
        "JOB"
    ) {
      typeContent = "Remarks";
    } else if (
      this.state.projectDetail &&
      this.state.projectDetail.participationDetails &&
      this.state.projectDetail.participationDetails.secondaryEntityType ===
        "ASSIGNMENT"
    ) {
      typeContent = "Certification or Accolades";
    } else if (
      this.state.projectDetail &&
      this.state.projectDetail.participationDetails &&
      this.state.projectDetail.participationDetails.secondaryEntityType ===
        "TRAINING"
    ) {
      typeContent = "Certification or Accolades";
    }

    let endDate = "";

    if (this.state.projectDetail && this.state.projectDetail.endDate > 0) {
      endDate = dateUtil.format(
        new Date(this.state.projectDetail.endDate),
        "DD MMM YYYY"
      );
    } else {
      endDate = "NA";
    }
    let faculties =
      this.state.projectDetail &&
      this.state.projectDetail &&
      this.state.projectDetail.faculties;

    let linkfiedHtml1 =
      '<p class="pdtlsLink">' +
      renderToString(
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {location && location.address
            ? location.address.replace(/<br\/>/g, "\n").replace(/&nbsp;/g, " ")
            : ""}
        </Linkify>
      )
        .replace(/&nbsp;/g, " ")
        .replace(/(\r\n|\r|\n)/g, "<br>")
        .replace(/(\\n)/g, "<br>") +
      "</p>";
    return (
      <>
        <div className="sharepostpreview">
          <div key={this.props.item.id + "opportunity"}>
            <div className="projectlist">
              <div className="projectlistinn">
                <div className="projectlisttop">
                  <figure className="complogo">
                    <Link to={"/project/feeds/" + this.props.item.slug}>
                      <img
                        src={
                          this.props.item.coverImageUrl
                            ? getCDNUrl(this.props.item.coverImageUrl)
                            : !_.isUndefined(this.props.item.coverImageUrl)
                            ? "https://cdn.dscovr.com/images/project-default.jpg"
                            : null
                        }
                        alt=""
                      />
                    </Link>
                  </figure>
                  <div className="projectlisttopDesc">
                    <h4>
                      <Link to={"/project/feeds/" + this.props.item.slug}>
                        {this.props.item.title}
                      </Link>
                    </h4>
                    <div className="projhead">
                      <div>
                        {this.props.item.location &&
                          this.props.item.location.city && (
                            <span className="address">
                              {this.props.item.location.city + ", "}
                              {this.props.item.location.state + ", "}
                              {this.props.item.location.country}
                            </span>
                          )}
                      </div>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.props.item.shortBrief
                          ? this.props.item.shortBrief
                              .replace(/&nbsp;/g, " ")
                              .replace(/(\r\n|\r|\n)/g, "<br>")
                              .replace(/(\\n)/g, "<br>")
                          : "",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--Project Description Popup--> */}
        {this.state.isModalOpen === true ? (
          <div className="authModalBg loginwrap">
            <div
              id="projectDetails"
              className="modal fade dgpopup authLoginModal"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="projectdescwrap">
                      <button className="close" onClick={this.openModalClose}><i className="iCross"></i></button>
                      <div className="projdestopbar">
                        <h1>{showProjectType}</h1>
                        <ul>
                          {this.state.projectDetail &&
                            this.state.projectDetail.demolished === false && (
                              <li className="posted">
                                {noOfDaysPosted === 0 ||
                                noOfDaysPosted === "0" ? (
                                  <span>Posted Today</span>
                                ) : (
                                  <span>
                                    Posted {timeAgo.format(d, "mini-now")} ago
                                  </span>
                                )}
                              </li>
                            )}
                        </ul>
                      </div>
                      <div className="projdesheader">
                        <div className="row">
                          <div className="col-lg-4 jobsblk">
                            <span>Title</span>
                            <h4>{unescape(title)}</h4>
                            <p className="addRss">
                              <i className="iLocation"></i>
                              <span>
                                {location && location.city},{" "}
                                {location && location.state},{" "}
                                {location && location.country}
                              </span>
                            </p>
                            {this.state.projectDetail &&
                              this.state.projectDetail.project &&
                              this.state.projectDetail.project.isDisclosed ==
                                false &&
                              fees > 0 && (
                                <div className="price">
                                  <i className="iWallet"></i>{" "}
                                  {this.state.projectDetail.project.currency}{" "}
                                  {nFormatter(fees, 2)}{" "}
                                  {!_.isNull(
                                    this.state.projectDetail.project.packageType
                                  ) &&
                                    !_.isUndefined(
                                      this.state.projectDetail.project
                                        .packageType
                                    ) &&
                                    this.state.projectDetail.project.packageType.toLowerCase() !==
                                      "custom" &&
                                    this.state.projectDetail.project
                                      .packageType}
                                  <span>
                                    {this.state.projectDetail.project
                                      .isNegotiable && "(Negotiable)"}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="col-lg-4 recruiter">
                            <span>Creator</span>
                            <div className="recruitblk">
                              {this.state.projectDetail &&
                                this.state.projectDetail.creatorName && (
                                  <div
                                    className="recruit"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      this.handleApply(
                                        this.state.projectDetail.partyType &&
                                          this.state.projectDetail.partyType ==
                                            "CIRCLE"
                                          ? "/community/" +
                                              this.state.projectDetail.customUrl
                                          : "/profile/" +
                                              this.state.projectDetail.customUrl
                                      )
                                    }
                                  >
                                    <figure
                                      className="complogo"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        this.handleApply(
                                          this.state.projectDetail.partyType &&
                                            this.state.projectDetail
                                              .partyType == "CIRCLE"
                                            ? "/community/" +
                                                this.state.projectDetail
                                                  .customUrl
                                            : "/profile/" +
                                                this.state.projectDetail
                                                  .customUrl
                                        )
                                      }
                                    >
                                      <img
                                        src={
                                          !_.isUndefined(
                                            this.state.projectDetail &&
                                              this.state.projectDetail.imageUrl
                                          ) &&
                                          !_.isNull(
                                            this.state.projectDetail &&
                                              this.state.projectDetail.imageUrl
                                          )
                                            ? getCDNUrl(
                                                this.state.projectDetail &&
                                                  this.state.projectDetail
                                                    .imageUrl
                                              )
                                            : this.state.projectDetail &&
                                              this.state.projectDetail
                                                .userType == "INDIVIDUAL"
                                            ? "https://cdn.dscovr.com/images/users1.png"
                                            : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                        }
                                        alt=""
                                      />
                                    </figure>
                                    {this.state.projectDetail &&
                                      this.state.projectDetail.creatorName}
                                  </div>
                                )}
                            </div>
                            {this.state.projectDetail &&
                              this.state.projectDetail.project &&
                              this.state.projectDetail.project.companyName &&
                              !_.isNull(
                                this.state.projectDetail.project.companyName
                              ) &&
                              this.state.projectDetail.project.companyName !==
                                "null" && (
                                <>
                                  <span>Organization</span>
                                  <div className="recruit">
                                    {/*<figure className="complogo">
                                        <img src={coverImage} alt=""/>
                                    </figure>*/}
                                    <span>
                                      {unescape(
                                        this.state.projectDetail.project
                                          .companyName
                                      )}
                                    </span>
                                  </div>
                                </>
                              )}
                            {this.state.projectDetail &&
                              this.state.projectDetail.project &&
                              this.state.projectDetail.project.websiteLink &&
                              this.state.projectDetail.project
                                .websiteLink[0] !== "" && (
                                <p>
                                  <a
                                    onClick={(event) =>
                                      window.open(
                                        this.state.projectDetail.project
                                          .websiteLink[0]
                                          ? mappingUtils.sanitize(
                                              this.state.projectDetail.project
                                                .websiteLink[0]
                                            )
                                          : mappingUtils.sanitize(
                                              this.state.projectDetail.project
                                                .websiteLink[0]
                                            ),
                                        "_blank"
                                      )
                                    }
                                    href="javascript:void(0)"
                                  >
                                    <i className="iGlobe" /> Website
                                  </a>
                                </p>
                              )}
                          </div>
                          {this.state.projectDetail &&
                            this.state.projectDetail.isOffLine === false &&
                            this.state.projectDetail.demolished === false && (
                              <div className="col-lg-4 opening">
                                {(this.state.projectDetail.participationDetails.secondaryEntityType !== "FUNDRAISE" || (this.state.projectDetail.participationDetails.secondaryEntityType === "FUNDRAISE" && this.state.projectDetail.lastDateOfApplication !== 0)) &&
                                  <>
                                    <span>Application</span>
                                    <p>
                                      Last date:{" "}
                                      {dateUtil.format(
                                        new Date(
                                          this.state.projectDetail.lastDateOfApplication
                                        ),
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                    <p>
                                      Openings:{" "}
                                      {this.state.projectDetail &&
                                        this.state.projectDetail.noOfOpenings &&
                                        this.state.projectDetail.noOfOpenings}
                                    </p>
                                  </>
                                }
                                <div className="buttblk">
                                  <a
                                    className="btn btn-primary"
                                    href="javascript:void(0)"
                                    onClick={(e) =>
                                      this.handleApply(
                                        "/project/feeds/" +
                                          this.state.projectDetail.slug
                                      )
                                    }
                                  >
                                    Apply
                                  </a>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="projdesbody">
                        <div className="projdesbodyinn">
                          <div className="largetext">
                            <h5>Brief Description</h5>
                            <p>
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <a
                                    target="blank"
                                    href={decoratedHref}
                                    key={key}
                                  >
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                {this.state.projectDetail &&
                                  this.state.projectDetail.project &&
                                  this.state.projectDetail.project.shortBrief &&
                                  !_.isNull(
                                    this.state.projectDetail.project.shortBrief
                                  ) &&
                                  this.state.projectDetail.project.shortBrief
                                    .split("\n")
                                    .map((brief) => (
                                      <>
                                        {convertUnicode(brief)}
                                        <br />
                                      </>
                                    ))}
                              </Linkify>
                            </p>
                          </div>
                          {this.state.projectDetail &&
                            this.state.projectDetail.project &&
                            this.state.projectDetail.project.description && (
                              <>
                                <h5>Detailed Description</h5>
                                {/* <Linkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a
                                      target="blank"
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  <p>
                                    {this.state.projectDetail.project
                                      .description &&
                                      this.state.projectDetail.project.description
                                        .split("\n")
                                        .map((descrip) => (
                                          <>
                                            {convertUnicode(descrip)}
                                            <br />
                                          </>
                                        ))}
                                  </p>
                                </Linkify> */}
                                <div className="projDetailsDesc">
                                  <div className="editor-text" dangerouslySetInnerHTML={{__html: this.state.projectDetail.project.description}}/>
                                </div>
                              </>
                            )}

                          <div className="pdescattachwrap">
                            {attachments &&
                              attachments.map((value) => {
                                //let file = JSON.parse(unescapeJs(value));
                                return (
                                  <a
                                    href="javascript:void(0)"
                                    className="pdescattachfile"
                                    onClick={(e) => {
                                      isWebView()
                                        ? postMsg("download", value.url)
                                        : this.download_file_pdf(e, value.url);
                                    }}
                                    style={{ cursor: "default" }}
                                  >
                                    <span>
                                      <i className="iDownloadFile1" />
                                      {value.name &&
                                        value.name.substring(
                                          value.name.indexOf("-") + 1
                                        )}
                                    </span>
                                  </a>
                                );
                              })}
                          </div>

                          {role && role.trim() != "type text" && (
                            <>
                              <h5>Roles and Responsibilities</h5>
                              {/* <p dangerouslySetInnerHTML={{
                                    __html: role
                                        ? role
                                            .replace(/&nbsp;/g, ' ')
                                            .replace(/(\r\n|\r|\n)/g, '<br>')
                                        : ''
                                }}/> */}
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <a
                                    target="blank"
                                    href={decoratedHref}
                                    key={key}
                                  >
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                <p>
                                  {role &&
                                    role.split("\n").map((role) => (
                                      <>
                                        {convertUnicode(role)}
                                        <br />
                                      </>
                                    ))}
                                </p>
                              </Linkify>
                            </>
                          )}

                          {faculties && faculties.length > 0 && (
                            <div className="pdkeyspeaker">
                              <h5>
                                {!_.isNull(showProjectType) &&
                                showProjectType === "TRAINING"
                                  ? "Faculty / Trainer"
                                  : showProjectType === "EXPEDITION"
                                  ? "Expedition leader"
                                  : "Key Speakers"}
                              </h5>
                              <div className="projfollowscroll">
                                <OwlCarousel
                                  id={"keyspeaker"}
                                  className={"owlbgk"}
                                  key={uuid()}
                                  //items = '3'
                                  rewind="true"
                                  nav="true"
                                  dots={false}
                                  navText={[
                                    "<i class='iArrow_Left'></i>",
                                    "<i class='iArrow_Right'></i>",
                                  ]}
                                  responsive={mappingUtils.getResponsiveness()}
                                >
                                  {faculties &&
                                    faculties.map((faculty) => {
                                      return (
                                        <div
                                          className="item"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <div className="projfollowblk">
                                            <figure
                                              className="img"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.redirectToProfile(
                                                  faculty.customUrl,
                                                  null
                                                );
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <img
                                                src={
                                                  !_.isNull(faculty.imageUrl) &&
                                                  !_.isUndefined(
                                                    faculty.imageUrl
                                                  )
                                                    ? getCDNUrl(
                                                        this.getOptimizedImage(
                                                          faculty,
                                                          faculty.imageUrl
                                                        )
                                                      )
                                                    : faculty.userType === "COMPANY" ? "https://cdn.dscovr.com/images/DefaultBusiness.webp" : "https://cdn.dscovr.com/images/users1.webp"
                                                }
                                                onError={(e) => {
                                                  e.target.onerror = null;
                                                  e.target.src = "https://cdn.dscovr.com/images/users1.png";
                                                }}
                                                alt=""
                                              />
                                            </figure>
                                            <div>
                                              <h4
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.redirectToProfile(
                                                    faculty.customUrl,
                                                    null
                                                  );
                                                }}
                                              >
                                                {faculty && faculty.username}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </OwlCarousel>
                              </div>
                            </div>
                          )}

                          {qualifications && qualifications.length > 0 && (
                            <>
                              <h5>Qualification</h5>
                              <ul className="ulstyles">
                                {qualifications &&
                                  qualifications.map((qual) => {
                                    return (
                                      <li>
                                        <Linkify
                                          componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                          ) => (
                                            <a
                                              target="blank"
                                              href={decoratedHref}
                                              key={key}
                                            >
                                              {decoratedText}
                                            </a>
                                          )}
                                        >
                                          {qual &&
                                            qual.split("\n").map((qual) => (
                                              <>
                                                {convertUnicode(qual)}
                                                <br />
                                              </>
                                            ))}
                                        </Linkify>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </>
                          )}

                          <div className="pdincluexclu">
                            <div className="row">
                              {this.state.projectDetail &&
                                this.state.projectDetail.project &&
                                this.state.projectDetail.project.inclusions &&
                                this.state.projectDetail.project.inclusions
                                  .length > 0 &&
                                this.state.projectDetail.project
                                  .inclusions[0] != "" && (
                                  <div className="col-md-6">
                                    <h5>Inclusion</h5>
                                    <Linkify
                                      componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key
                                      ) => (
                                        <a
                                          target="blank"
                                          href={decoratedHref}
                                          key={key}
                                        >
                                          {decoratedText}
                                        </a>
                                      )}
                                    >
                                      {this.state.projectDetail.project
                                        .inclusions &&
                                        this.state.projectDetail.project.inclusions.map(
                                          (incl) => (
                                            <p>
                                              {incl &&
                                                incl.split("\n").map((incl) => (
                                                  <>
                                                    {convertUnicode(incl)}
                                                    <br />
                                                  </>
                                                ))}
                                            </p>
                                          )
                                        )}
                                    </Linkify>
                                  </div>
                                )}
                              {this.state.projectDetail &&
                                this.state.projectDetail.project &&
                                this.state.projectDetail.project.exclusions &&
                                this.state.projectDetail.project.exclusions
                                  .length > 0 &&
                                this.state.projectDetail.project
                                  .exclusions[0] != "" && (
                                  <div className="col-md-6">
                                    <h5>Exclusion</h5>
                                    <Linkify
                                      componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key
                                      ) => (
                                        <a
                                          target="blank"
                                          href={decoratedHref}
                                          key={key}
                                        >
                                          {decoratedText}
                                        </a>
                                      )}
                                    >
                                      {this.state.projectDetail.project
                                        .exclusions &&
                                        this.state.projectDetail.project.exclusions.map(
                                          (excl) => (
                                            <p>
                                              {excl &&
                                                excl.split("\n").map((excl) => (
                                                  <>
                                                    {convertUnicode(excl)}
                                                    <br />
                                                  </>
                                                ))}
                                            </p>
                                          )
                                        )}
                                    </Linkify>
                                  </div>
                                )}
                            </div>
                          </div>
                          {this.state.projectDetail &&
                            this.state.projectDetail.project &&
                            this.state.projectDetail.project.endTakeAway && (
                              <>
                                <h5>
                                  {typeContent}
                                  <span>
                                    (Received by participant post completion)
                                  </span>
                                </h5>
                                <Linkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a
                                      target="blank"
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  <p>
                                    {this.state.projectDetail.project
                                      .endTakeAway &&
                                      this.state.projectDetail.project.endTakeAway
                                        .split("\n")
                                        .map((endTakeAway) => (
                                          <>
                                            {convertUnicode(endTakeAway)}
                                            <br />
                                          </>
                                        ))}
                                  </p>
                                </Linkify>
                              </>
                            )}
                          {this.state.projectDetail &&
                            this.state.projectDetail.project &&
                            this.state.projectDetail.project.mediaAttachments &&
                            this.state.projectDetail.project.mediaAttachments
                              .length > 0 &&
                            this.state.projectDetail.project
                              .mediaAttachments[0] !== "" && (
                              <>
                                <h5>Media Coverage</h5>
                                <ul>
                                  {this.state.projectDetail.project
                                    .mediaAttachments &&
                                    this.state.projectDetail.project.mediaAttachments.map(
                                      (media) => {
                                        return (
                                          <li className="pdtlsLink">
                                            <Linkify
                                              componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key
                                              ) => (
                                                <a
                                                  target="blank"
                                                  href={decoratedHref}
                                                  key={key}
                                                >
                                                  {decoratedText}
                                                </a>
                                              )}
                                            >
                                              {media &&
                                                media
                                                  .split("\n")
                                                  .map((media) => (
                                                    <>
                                                      {convertUnicode(media)}
                                                      <br />
                                                    </>
                                                  ))}
                                            </Linkify>
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </>
                            )}

                          {this.state.projectDetail &&
                            this.state.projectDetail.project &&
                            this.state.projectDetail.project.specialization &&
                            this.state.projectDetail.project.skills &&
                            (this.state.projectDetail.project.skills.length >
                              0 ||
                              this.state.projectDetail.project.specialization
                                .length > 0) && (
                              <div className="specialskills">
                                <h5>Skills and Specialization</h5>
                                <div className="specialskillsblk">
                                  {this.state.projectDetail.project
                                    .specialization &&
                                    this.state.projectDetail.project
                                      .specialization.length > 0 && (
                                      <div className="spsktags">
                                        {" "}
                                        {this.state.projectDetail.project
                                          .specialization &&
                                          this.state.projectDetail.project.specialization.map(
                                            (sp) => (
                                              <span>{convertUnicode(sp)}</span>
                                            )
                                          )}{" "}
                                      </div>
                                    )}
                                  {this.state.projectDetail &&
                                    this.state.projectDetail.project &&
                                    this.state.projectDetail.project.skills &&
                                    this.state.projectDetail.project.skills
                                      .length > 0 && (
                                      <div className="spsktags">
                                        {" "}
                                        {this.state.projectDetail.project
                                          .skills &&
                                          this.state.projectDetail.project.skills.map(
                                            (skill) => (
                                              <span>{unescape(skill)}</span>
                                            )
                                          )}{" "}
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}
                          <div className="pdincluexclu locaduration">
                            {location && location.address && (
                              <>
                                <h5>Detailed Address </h5>
                                <p className="addRss">
                                  <Linkify
                                    componentDecorator={(
                                      decoratedHref,
                                      decoratedText,
                                      key
                                    ) => (
                                      <a
                                        target="blank"
                                        href={decoratedHref}
                                        key={key}
                                      >
                                        {decoratedText}
                                      </a>
                                    )}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: linkfiedHtml1,
                                      }}
                                    />
                                  </Linkify>
                                </p>
                              </>
                            )}
                          </div>
                          <div className="pdincluexclu locaduration">
                            <div className="row">
                              <div className="col-md-6">
                                <h5>Duration</h5>
                                <ul>
                                  <li>
                                    <span>Start Date</span>{" "}
                                    {dateUtil.format(
                                      new Date(
                                        this.state.projectDetail &&
                                          this.state.projectDetail.startDate
                                      ),
                                      "DD MMM YYYY"
                                    )}
                                  </li>
                                  {endDate !== "" && (
                                    <li>
                                      <span>End Date</span> {endDate}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default ProjectSharePreview;
