import React from "react";
import { Component } from "react";
import { authService } from "../../../auth";
import dateUtil from "date-and-time";
import { Link } from "react-router-dom";
import mappingUtils from "../commons/utils/mappingUtils";
import { getCDNUrl } from "../../../commons/CommonFunctions";

class StudioPaidUserItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
        }
    }
    
    getOptimizedImage(data) {
        if (data.resizedProfileImages && data.resizedProfileImages['200x200'] != null) {
            return data.resizedProfileImages['200x200'];
        }
        return data.originalProfileImage;
    }

    render() {
        const {paidUserItem} = this.props;
        return (
            <div className="paidUserListRow">
                <div className="paidUserListUsersBx" >
                    <figure>
                        <Link to={`/profile/${paidUserItem.userCustomUrl}`}><img src={paidUserItem.originalProfileImage ? getCDNUrl(this.getOptimizedImage(paidUserItem)) : mappingUtils.returnDefaultImage(paidUserItem.userType)} alt="" /></Link>
                    </figure>
                    <div>
                        <h5><Link to={`/profile/${paidUserItem.userCustomUrl}`}>{paidUserItem.userName}</Link></h5>
                        <div className="dateField">
                            {dateUtil.format(new Date(paidUserItem.updateTime), "DD MMM")}
                            <span>{dateUtil.format(new Date(paidUserItem.updateTime), "YYYY")}</span>
                        </div>
                    </div>
                </div>                
                <div className="amntField">{(!paidUserItem.currencyType || paidUserItem.currencyType) === 'USD' ? <>&#36;</> : <>&#8377;</>}{paidUserItem.amount}</div>
                <div className="paidUserListAct">
                    <label className="switch" style={{ marginRight: '0' }}>
                        <input type="checkbox" checked={paidUserItem.payment} onClick={(e) => this.props.updatePaidUserCheckedData(e, paidUserItem)} />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
        )
    }
}

export default StudioPaidUserItem;