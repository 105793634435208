import React, {PureComponent} from 'react';
import {getCDNUrl} from "../commons/CommonFunctions";
import {Link, Redirect} from "react-router-dom";
import * as _ from "lodash";
class PollSharePreview extends PureComponent {

    state = {

    }

    redirectToProfile = () => {
        this.setState({redirectToProfile: true});
    }

    render() {

        return (
            <>
                {this.state.redirectToProfile && <Redirect push to={"/profile/" + this.props.item.customUrl}/>}
                <div className="sharepostpreview">
                    <div className="forumrow">
                        {this.props.entityItem.userId !== this.props.item.userId &&
                        <div className="forumrowbott">
                            <div className="fuserfollow">
                                <figure className="userlogo" onClick={(e) => {
                                    e.preventDefault();
                                    this.redirectToProfile()
                                }} style={{cursor: 'pointer'}}><img
                                    src={this.props.item.profileImage != null ? getCDNUrl(this.props.item.profileImage) : (this.props.item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                    alt=""/></figure>
                                <a href="javascript:void(0)" onClick={(e) => {
                                    e.preventDefault();
                                    this.redirectToProfile()
                                }}>{this.props.item.username}</a>
                            </div>
                        </div>}
                        <h4><Link to={'/pollDetail/' + (!_.isEmpty(this.props.item.metaData) ? this.props.item.metaData?.slug : this.props.item.slug)}>{!_.isEmpty(this.props.item.metaData) ? this.props.item.metaData.question : this.props.item.question}</Link></h4>
                        {this.props.item && this.props.item.addressDetail && this.props.item.addressDetail.country && <span className="address"><i
                            className="iLocation"/> {this.props.item.addressDetail.country}</span>}                        
                    </div>
                </div>
            </>
        );
    }
}

export default PollSharePreview
