import React, { Component } from "react";
import { authService } from "../auth";
import { Link } from "react-router-dom";
import axios from "axios";
import * as _ from "lodash";

class HeaderUserBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            userBadgeData: {},
            coinValueUpdate: props?.coinValueUpdate,
            showPublic: false
        }
    }

    getBadgeDataByUserId = () => {
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/backend/badge/get_by_user_id?userId=${this.state.userData.userId}`,
            method: "GET",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response?.data?.message === "Success!") {
                this.setState({
                    userBadgeData: response.data.body
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    getBadgeShowInPublic = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_userServiceURL}/backend/badge/config/show/public`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response.data.message === 'Success!') {
                this.setState({
                    showPublic: response?.data?.body
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.coinValueUpdate && nextProps?.coinValueUpdate !== this.state.coinValueUpdate) {
            this.getBadgeDataByUserId();
        }
    }

    componentDidMount() {
        this.getBadgeDataByUserId();
        this.getBadgeShowInPublic();
    }

    render() {
        const { userBadgeData, showPublic } = this.state;
        return (
            <>
                {!_.isEmpty(userBadgeData) && showPublic &&
                    <div className="dropdown shpnwdrop" style={{ padding: '6px', lineHeight: '0' }}>
                        <a className="dropdown-toggle" data-toggle="dropdown">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1425_250)">
                                    <mask id="mask0_1425_250" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
                                        <path d="M0 1.90735e-06H22V22H0V1.90735e-06Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_1425_250)">
                                        <path d="M8.48238 2.34077V3.88155C8.48238 4.12127 8.28803 4.31558 8.04831 4.31558H1.38867C1.74046 4.83086 2.28857 5.18372 3.01328 5.18372H9.26368V3.01341C9.26368 3.01341 8.977 2.72685 8.48238 2.34077Z" fill="#FCDD2E" />
                                        <path d="M13.5176 2.34077V3.88155C13.5176 4.12127 13.712 4.31558 13.9517 4.31558H20.6113C20.2596 4.83086 19.7114 5.18372 18.9867 5.18372H12.7363V3.01341C12.7363 3.01341 13.023 2.72685 13.5176 2.34077Z" fill="#FCDD2E" />
                                        <path d="M12.7361 5.18365H9.26367V3.00729C9.26367 2.67505 9.533 2.40573 9.86523 2.40573H12.1345C12.4668 2.40573 12.7361 2.67505 12.7361 3.00729V5.18365Z" fill="#FBE77B" />
                                        <path d="M9.26367 5.18365H12.7361V4.31556H9.26367V5.18365Z" fill="#FCDD2E" />
                                        <path d="M21.6777 8.65613H0.322266V5.1837H21.6777V8.65613Z" fill="#00394D" />
                                        <path d="M20.5493 8.65613H21.6778V5.1837H20.5493V8.65613Z" fill="#022836" />
                                        <path d="M13.6042 8.65613H8.39551V5.1837H13.6042V8.65613Z" fill="#FBE77B" />
                                        <path d="M20.5491 21.6777H1.45068V8.6561H20.5491V21.6777Z" fill="#DADD21" />
                                        <path d="M1.45068 9.69781H20.5491V8.65608H1.45068V9.69781Z" fill="#022836" />
                                        <path d="M12.7361 21.6777H9.26367V8.6561H12.7361V21.6777Z" fill="#FBE77B" />
                                        <path d="M12.7361 8.65608H9.26367V9.69781H12.7361V8.65608Z" fill="#FCDD2E" />
                                        <path d="M18.9867 5.18365C20.3323 5.18365 21.0702 3.96828 21.0702 2.75296C21.0702 1.53759 20.3757 0.322257 18.5526 0.322257C15.4275 0.322257 12.7363 3.01339 12.7363 3.01339" stroke="black" />
                                        <path d="M9.26367 5.18365V3.01339C9.26367 2.67776 9.53575 2.40573 9.87138 2.40573H12.1284C12.464 2.40573 12.7361 2.67776 12.7361 3.01339V5.18365" stroke="black" />
                                        <path d="M21.6777 8.65613H0.322266V5.1837H21.6777V8.65613Z" stroke="black" />
                                        <path d="M8.39551 8.65613V5.18369" stroke="black" />
                                        <path d="M13.6045 5.18369V8.65613" stroke="black" />
                                        <path d="M20.5493 8.6561V12.9796" stroke="black" />
                                        <path d="M1.45068 14.5073V21.6777H10.9999H20.5491V14.5073" stroke="black" />
                                        <path d="M1.45068 8.6561V12.9796" stroke="black" />
                                        <path d="M9.26367 21.6777V8.6561" stroke="black" />
                                        <path d="M12.7363 14.5073V21.6777" stroke="black" />
                                        <path d="M12.7363 8.65608V12.9761" stroke="black" />
                                        <path d="M12.7363 4.0553C12.7363 4.0553 13.2303 3.78769 13.8215 3.6211" stroke="black" />
                                        <path d="M3.01316 5.18365C1.66759 5.18365 0.929688 3.96828 0.929688 2.75296C0.929688 1.53759 1.62419 0.322257 3.44723 0.322257C6.57239 0.322257 9.26352 3.01339 9.26352 3.01339" stroke="black" />
                                        <path d="M9.26384 4.0553C9.26384 4.0553 8.76983 3.78769 8.17871 3.6211" stroke="black" />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1425_250">
                                        <rect width="22" height="22" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <div className="dropdown-menu">
                            <div className='qxlfy'>
                                <div className='qxlfy-left'>
                                    <div className='qxlfy-point'>{userBadgeData?.pointRemaining}</div>
                                    <div className='qxlfy-text'>Total credits available</div>
                                    <Link to={"/wenaturalists-pro"} className='btn qxlfy-btn'>Avail now</Link>
                                </div>
                                <div className='qxlfy-right'>
                                    {userBadgeData?.type ?
                                        <>
                                            <div className='qxlfy-right-text'>You Earned</div>
                                            <figure>
                                                <img src={userBadgeData?.type === "SILVER" ? 'https://cdn.dscovr.com/images/silverbadge21.png' : userBadgeData?.type === "GOLD" ? 'https://cdn.dscovr.com/images/goldenbadges11.png' : 'https://cdn.dscovr.com/images/platinumbadge31.png'} alt='images' />
                                            </figure>
                                        </> :
                                        <div className="nopoint-user">
                                            <p>you haven't earned any badge yet</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default HeaderUserBadge;