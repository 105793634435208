import {put, takeEvery} from "redux-saga/effects";
import {authService} from '../auth';
import {
    SET_OTHER_USER_DATA,
    SET_PROFILE_DATA,
    SET_USER_DATA,
    setOtherUserDataOnSuccess,
    setUserDataOnSuccess, setUserProfilerDataOnSuccess
} from "../reducer/auth";
import {UserService} from "../UserData";

function* loadUserData() {
    yield put(setUserDataOnSuccess(authService.getUserDetailsFromLocalStorage()));
}

export function* watchUserDataOnLoad() {
    yield takeEvery(SET_USER_DATA, loadUserData);
}

function* loadOtherUserData() {
    yield put(setOtherUserDataOnSuccess(UserService.getOtherUserData()));
}

export function* watchOtherUserDataOnLoad() {
    yield takeEvery(SET_OTHER_USER_DATA, loadOtherUserData);
}

function* loadUserProfileData() {
    yield put(setUserProfilerDataOnSuccess(UserService.getUserProfileData()));
}

export function* watchUserProfileDataOnLoad() {
    yield takeEvery(SET_PROFILE_DATA, loadUserProfileData);
}

