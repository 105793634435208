import React, { Component } from "react";
import { get720pTranscodedVideo, getCDNUrl } from "../commons/CommonFunctions";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import AuthModalComponent from "../LoginExtra/AuthModalComponent";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");
class UnAuthVideoPostItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginRequired: false,
    };
  }
  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.userProfileImage;
  }

  handleLoginClose = () => {
    this.setState({ loginRequired: false });
    window.$("#loginModal").modal("hide");
  };

  openLoginModal = () => {
    this.setState({ loginRequired: true });
    window.$("#loginModal").modal("show");
  };

  render() {
    var utcSeconds = this.props.item.createTime;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds / 1000);
    return (
      <div className="hpostblock"> {/*pNominate*/}
        {!this.props.item.userEntityType ? (
          <figure
            className="userimg"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <img
              src={
                this.props.item.userProfileImage
                  ? getCDNUrl(this.getOptimizedImage(this.props.item))
                  : this.props.item.userType !== "COMPANY"
                  ? "https://cdn.dscovr.com/images/users1.png"
                  : "https://cdn.dscovr.com/images/DefaultBusiness.png"
              }
              alt=""
            />
          </figure>
        ) : (
          <figure
            className="userimg"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <img
              src={
                this.props.item &&
                this.props.item.params &&
                this.props.item.params["circleImage"] != null
                  ? getCDNUrl(
                      this.props.item.params &&
                        this.props.item.params["circleImage"]
                    )
                  : "https://cdn.dscovr.com/images/circleDefault.webp"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://cdn.dscovr.com/images/circleDefault.png";
              }}
              alt=""
            />
          </figure>
        )}
        <div className="hpostblockinn">
          <div className="hposthead">
            {!this.props.item.userEntityType ? (
              <div className="d-flex">
                <h3>{this.props.item.userName}</h3>
              </div>
            ) : (
              <div className="d-flex">
                <h3
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {this.props.item.params["circleTitle"]}
                </h3>
              </div>
            )}
            {this.props.item && this.props.item.persona &&
              <div className="hpostPersona">{this.props.item.persona}</div>}
            <div className="hpostdtls">
              {this.props.item && this.props.item.country && 
              <span className="address">
                <i className="iLocation" />
                {this.props.item && this.props.item.country}
              </span>}

              <span>{timeAgo.format(d, "mini-now")}</span>
            </div>
          </div>
          <div className="hpostcontent">
            <div className="hpostconttop">
              {this.props.item &&
                this.props.item.description &&
                this.props.item.description !== "" && (
                  <ReadMoreReadLess
                    id={this.props.item.id}
                    description={this.props.item.description}
                    hashTags={this.props.item.hashTags}
                  />
                )}

              {this.props.item.attachmentIds &&
                this.props.item.attachmentIds[0] &&
                this.props.item.attachmentIds[0].attachmentUrl && (
                  <figure className="hpostimggrid">
                    <div className="himggrid vidbox">
                      {/*<span className="tagtitle">Video No1 Title</span>*/}
                      <a
                        data-fancybox={this.props.item.id}
                        href={getCDNUrl(
                          get720pTranscodedVideo(
                            this.props.item.attachmentIds[0]
                          )
                        )}
                      >
                        {this.props.item.attachmentIds[0].thumbnails &&
                        this.props.item.attachmentIds[0].thumbnails[0] ? (
                          <img
                            src={getCDNUrl(
                              this.props.item.attachmentIds[0].thumbnails[0]
                            )}
                            alt=""
                          />
                        ) : (
                          <img
                            src="https://cdn.dscovr.com/images/video-blank2.webp"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://cdn.dscovr.com/images/video-blank2.png";
                            }}
                            alt=""
                          />
                        )}
                        <span className="playbutt" onContextMenu="return false;"><i className="iPlay" /></span>
                      </a>
                    </div>
                  </figure>
                )}

              {this.props.item.postLocation && (
                <span className="address">
                  <i className="iLocation" />
                  {this.props.item.postLocation}
                </span>
              )}
            </div>
            
              {this.props.item.secondaryAttachmentIds &&
              this.props.item.secondaryAttachmentIds.length > 0 && (
                <div className="hpostcontbott">
                  <a
                    href="javascript:void(0)"
                    onClick={this.openLoginModal}
                    className="fattach"
                    data-toggle="modal"
                  >
                    <i className="iLink" />
                    {this.props.item.secondaryAttachmentIds.length === 1
                      ? this.props.item.secondaryAttachmentIds.length + " File "
                      : this.props.item.secondaryAttachmentIds.length +
                        " Files "}{" "}
                    Attached
                  </a>
                </div>
              )}
          </div>
        </div>
        {this.state.loginRequired === true ? (
          <AuthModalComponent handleLoginClose={this.handleLoginClose} />
        ) : null}
      </div>
    );
  }
}

export default UnAuthVideoPostItem;
