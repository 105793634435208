

const initialState = {
    projectPageClickProgress : false,
    data : {},
    error : {}
}

const projectPageClickReducer = (state = initialState, action)=>{
    switch (action.type){
        case "PROJECT_PAGE_CLICK_REQUEST" :
            return {
                ...state,
                projectPageClickProgress:true,
            };
        case "PROJECT_PAGE_CLICK_SUCCESS" :
            return {
                ...state,
                projectPageClickProgress:false,
                data: action.payload,
                error: {}
            };
        case "PROJECT_PAGE_CLICK_FAILURE" :
            return {
                ...state,
                projectPageClickProgress:false,
                data: {},
                error: action.error
            };
        default :
            return state;
    }
}

export default projectPageClickReducer;