import React from 'react'

import {Link, NavLink, Redirect} from 'react-router-dom';

import {authService} from './auth';
import axios from 'axios';
import * as _ from "lodash";
import {getCDNUrl, isWebView, userAgentPostData} from "./commons/CommonFunctions";
import {CapitalizeFirstLetter} from "./utils/StringUtility";
import Select from "react-select";
import CountryCode from "./country_code.json";
import {cloneDeep} from "lodash";


class LoginComponent extends React.Component {

    constructor(props) {
        super(props);
        let validation = {
            username: {},
            password: {},
            phone: {},
            email: {}
        }

        CountryCode.sort((a, b) => (a.Dial > b.Dial) ? 1 : ((b.Dial > a.Dial) ? -1 : 0));

        this.state = {
            validation,
            error: null,
            isLoaded: true,
            loginSuccess: authService.isAuthenticated(),
            loggedinUser: null,
            isMigrating: false,
            email: '',
            phone: '',
            errors: {},
            countryCodeInfo: [],
            countryCode: [],
            countryCodeList: [].concat(CountryCode)
        };
    }


    handleUserNameChange = (e) => {
        this.state.validation.username = {};
        this.setState({username: e.target.value});
    };

    handlePasswordChange = (e) => {
        this.state.validation.password = {};
        this.setState({password: e.target.value});
    };

    checkValidation = () => {
        this.state.validation.username = {};
        this.state.validation.password = {};
        let validationRequired = false;
        if (_.isUndefined(this.state.username) ||
            _.isEmpty((this.state.username || "").toString()) ||
            _.isNull(this.state.username)) {
            this.setState({
                error: {message: 'Please enter an email address or phone number'}
            });
            this.state.validation.username = {
                error: true,
                style: {borderColor: "#b34c4c"}
            }
            validationRequired = true;

        } else if (_.isUndefined(this.state.password) ||
            _.isEmpty((this.state.password || "").toString()) ||
            _.isNull(this.state.password)) {
            validationRequired = true;
            this.setState({
                error: {message: 'Please enter password'}
            });
            this.state.validation.password = {
                error: true,
                style: {borderColor: "#b34c4c"}
            }
        }
        return validationRequired;
    };

    componentDidMount() {
        document.title = 'Login to stay updated | WeNaturalists';
    }

    loggedinSuccess = () => {
        this.connect();
    };

    connect = () => {
        const loginActivity = {
            "userId": this.state.loggedinUser,
            "loginStatus": "online"
        };

        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + "/messaging/message_service/api/updateLoginActivity",
            data: loginActivity,
            withCredentials: true
        }).then(response => {
            this.setState({isLoaded: true})
            //Dont remove this
            window.location.reload();
        }).catch((error) => {
            this.setState({isLoaded: true})
            //Dont remove this
            window.location.reload();
        });
    }

    handleSubmit = (event) => {
        this.setState({isLoaded: false})

        localStorage.removeItem('appDownloaded');
        event.preventDefault();

        if (!this.checkValidation()) {
            let postBody = {
                "username": this.state.username.trim(),
                "password": this.state.password.trim(),
                "userAgent": userAgentPostData()
            };

            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/user/login',
                headers: {'Content-Type': 'application/json'},
                data: postBody,
                withCredentials: true
            }).then((response) => {

                let res = response.data;

                if (res.message === 'Success!') {

                    if (res.body.companyOperatorMigrationNeeded) {
                        window.$('#CmpnyOpratrDataCllctn').modal('show');
                        this.setState({'companyLoginResponse': res.body});
                        return;
                    }

                    let authResult = {};
                    if (res.body.indianResidence) {
                        authResult.phone = res.body.mobile;
                    } else {
                        authResult.email = res.body.email;
                    }
                    authResult.isIndianResident = res.body.indianResidence;
                    authResult.firstName = res.body.firstName;
                    authResult.lastName = res.body.lastName;
                    authResult.companyName = res.body.companyName;
                    authResult.companyIds = res.body.companyIds;
                    authResult.customUrl = res.body.customUrl;
                    authResult.type = res.body.type;
                    authResult.userId = res.body.userId;
                    authResult.profileImage = res.body.profileImage;
                    authResult.userDataPending = res.body.userDataPending;

                    authResult.skills = res.body.skills;
                    authResult.interests = res.body.interests;
                    authResult.specialities = res.body.specialities;
                    authResult.persona = res.body.persona;
                    authResult.cookiesAccepted = res.body.cookiesAccepted;
                    authResult.resizedProfileImages = res.body.resizedProfileImages;

                    authService.setSession(authResult, response);
                    authService.setPromotionData(false);
                    authService.setGeoLocation();
                    if (res.body.userDataPending) {
                        this.props.redirect(res.body);
                    }

                    this.setState({"loggedinUser": authResult.userId});

                    if (window.ReactNativeWebView && authResult.userId) {
                        window.ReactNativeWebView.postMessage(JSON.stringify({
                            type: 'login',
                            userId: authResult.userId
                        }));
                    }

                    this.loggedinSuccess();
                } else {
                    authService.clearData();
                }
            }).catch((err) => {
                if (err && err.response) {
                    this.setState({
                        isLoaded: true,
                        error: {message: err.response.data.message, err: err.response}
                    });


                    authService.clearData();
                }
                console.log(err);
            });
        } else {
            this.setState({isLoaded: true})
        }
        setTimeout(() => {
            if (this.state.error && this.state.error.message !== 'Sorry! This account is deactivated. Please write at support@wenaturalists.com if you wish to reactivate this account.') {
                this.setState({
                    error: {}
                });
            }
        }, 5000);


    };

    handleCompanyMigrationSubmit = (event) => {
        this.setState({isMigrating: true})

        event.preventDefault();

        if (this.validateForm()) {
            let postBody = {
                "userId": this.state.companyLoginResponse.userId,
                "email": this.state.email.toLowerCase(),
                "mobile": this.state.phone,
                "country": this.state.countryCodeInfo.country,
                "countryISDCode": this.state.countryCodeInfo.dial,
                "password": this.state.password.trim()
            };

            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/user/company-operator-to-individual',
                headers: {'Content-Type': 'application/json'},
                data: postBody,
                withCredentials: true
            }).then((response) => {

                let res = response.data;

                if (res.message === 'Success!') {
                    let authResult = {};
                    if (this.state.isIndianResident) {
                        authResult.phone = this.state.phone;
                    } else {
                        this.setState({emailAvailable: true});
                        authResult.email = this.state.email;
                    }
                    authResult.isIndianResident = res.body.indianResidence;
                    authResult.type = res.body.type;
                    authResult.userDataPending = res.body.userDataPending;
                    authResult.userId = res.body.userId;
                    authResult.firstName = res.body.firstName;
                    authResult.lastName = res.body.lastName;
                    authResult.customUrl = res.body.customUrl;
                    authService.setSession(authResult, response);

                    if (window.ReactNativeWebView && authResult.userId) {
                        window.ReactNativeWebView.postMessage(JSON.stringify({
                            type: 'login',
                            userId: authResult.userId
                        }));
                    }

                    this.setState({signUpResponse: response, isLoading: false},()=>{
                        let data = cloneDeep(this.state.signUpResponse.data.body);
                        data.emailVerificationRequired = true;
                        this.props.redirect(data)
                    });

                }
            }).catch((err) => {
                if (err) {
                    if(err && err.response && err.response.status == 409){
                        this.setState({
                            error: {message: err.response.data.message},
                            isMigrating: false,
                        });
                    } else if(err.response && err.response.data){
                        this.setState({
                            isMigrating: false,
                            isLoaded: true,
                            error: {message: err.response.data.message, err: err.response}
                        });
                        authService.clearData();
                    }
                }
                console.log(err);
            });
        }
        setTimeout(() => {
            this.setState({
                error: {},
                errors:{},
                validation:{},
            });
        }, 5000);
    }

    componentWillUnmount() {
        window.$('#CmpnyOpratrDataCllctn').modal('hide');
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    getCountryCodeNew = (newValue, actionMeta) => {
        this.setState({selectedOption4: newValue.value});
        this.setState({'countryCodeInfo': newValue});
    }

    getCountryCodeOptions = () => {
        return this.state.countryCodeList.map((option) => {
            return {
                value: option.Unicode + ' +' + option.Dial,
                dial: option.Dial,
                country: option.Name,
                label: option.Unicode + ' +' + option.Dial + ' ' + option.Name,
                name: 'countrycode'
            }
        });
    };

    countryDropDownMenu() {
        const {selectedOption4} = this.state;
        return <Select
            options={this.getCountryCodeOptions()}
            onChange={this.getCountryCodeNew}
            components={{DropdownIndicator:() => null, IndicatorSeparator:() => null}}
            placeholder={'Code *'}
            name={'countrycode'}
            classNamePrefix='react-select'
            styles={{
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    const color = '#cfe7c7';
                    return {
                        ...styles,
                        backgroundColor: isDisabled
                            ? null
                            : isSelected
                                ? '#cfe7c7'
                                : isFocused
                                    ? '#cfe7c7'
                                    : null,
                        color: isDisabled
                            ? '#ccc'
                            : isSelected
                                ? color > 2
                                    ? '#00374d'
                                    : '#00374d'
                                : data.color,
                        cursor: isDisabled ? 'not-allowed' : 'default',

                        ':active': {
                            ...styles[':active'],
                            backgroundColor: !isDisabled && (isSelected ? '#cfe7c7' : '#cfe7c7'),
                        },
                    };
                },
            }}
        />
        //    return <select className="selectpicker" data-width="fit"
        //         name="countryCode"
        //         onChange={this.onSelectCountryCode}
        //         style={this.state.validation.countryCode.style}
        //         required={true}>
        //         {this.getCountryCodeOptions()}
        //     </select>
    }

    getCountryCode = () => {
        var maxLength;
        var minLength;
        if (this.state.countryCodeInfo && this.state.countryCodeInfo.dial === '91') {
            maxLength = 10;
            minLength = 10
        } else {
            maxLength = 15;
            minLength = 3
        }
        return (
            <div className="form-group">
                <div className="chphonefield">
                    {this.countryDropDownMenu()}
                    <input type="text"
                           className="custom-input form-control"
                           id="validationCustomUsername"
                           aria-describedby="inputGroupPrepend"
                           style={this.state.validation && this.state.validation.phone && this.state.validation.phone.error ? this.state.validation.phone.style : null}
                           value={this.state.phone}
                           data-tip={
                               this.state.validation && this.state.validation.phone && this.state.validation.phone.error &&
                               this.state.validation.phone.message
                           }
                           onBlur={(e) => this.validate("phone", e.target.value)}
                           onChange={(e) => this.validate("phone", e.target.value)}
                           minLength={minLength} maxLength={maxLength}
                           placeholder="Phone No.*"/>


                    <span className="login-feedback-error mt-1"
                          style={{display: "block"}}>{this.state.errors.countryCode}</span>
                    <span
                        className="login-feedback-error mt-1">{this.state.validation.countryCode && this.state.validation.countryCode.message}</span>
                </div>
                {this.state.errors && this.state.errors.phone ?
                    <span className="login-feedback-error mt-1"
                          style={{display: "block"}}>{this.state.errors.phone}</span>
                    : null}
                {this.state.validation && this.state.validation.phone ?
                    <span
                        className="login-feedback-error mt-1">{this.state.validation.phone && this.state.validation.phone.message}</span>
                    : null}

            </div>


        );
    };

    validateForm() {
        this.state.validation.phone = {};
        this.state.validation.email = {};
        let validationRequired = false;
        let errors = {};
        let formIsValid = true;

        if (_.isUndefined(this.state.phone) ||
            _.isEmpty((this.state.phone || "").toString()) ||
            _.isNull(this.state.phone)) {
            errors["phone"] = "Please enter your phone number";
            this.state.validation.phone = {
                error: true,
                style: {borderColor: "#b34c4c"}
            }
            this.setState({isLoading: false,isMigrating: false})
            formIsValid = false;
            validationRequired = true;
        } else {
            const re = /^[0-9\b]+$/;
            if (this.state.phone === '' || re.test(this.state.phone)) {
                this.setState({phone: this.state.phone});
            }

            this.state.validation.phone = {};
            if (this.state.countryCodeInfo.dial === '91') {
                const regExp = /^[6-9\b]+$/;
                if (this.state.phone === '' || regExp.test(this.state.phone)) {
                    this.setState({phone: this.state.phone});
                }
                if (this.state.phone.trim().replace(" ", "").length > 10 ||
                    this.state.phone.trim().replace(" ", "").length < 10 || !regExp.test(this.state.phone[0])
                ) {
                    this.state.validation.phone = {
                        error: true,
                        message: 'Please enter valid mobile number',
                        style: {borderColor: "#b34c4c"}
                    }
                    this.setState({isLoading: false,isMigrating: false})
                    formIsValid = false;
                }
            }
        }
        if (_.isUndefined(this.state.countryCodeInfo.dial) || _.isEmpty(this.state.countryCodeInfo.dial) || _.isNull(this.state.countryCodeInfo.dial)) {
            errors["countryCode"] = "Please select the country code";
            this.state.validation.countryCode = {
                error: true,
                style: {borderColor: "#b34c4c"}
            }
            this.setState({isLoading: false})
            formIsValid = false;
            validationRequired = true;
        }
        if (_.isUndefined(this.state.email) ||
            _.isEmpty((this.state.email || "").toString()) ||
            _.isNull(this.state.email)) {
            errors["email"] = "Please enter your email address";
            this.state.validation.email = {
                error: true,
                style: {borderColor: "#b34c4c", padding: "10px"}
            }
            this.setState({isLoading: false,isMigrating: false})
            formIsValid = false;
            validationRequired = true;
        } else {
            const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.setState({email: this.state.email});
            this.state.validation.email = {};
            if (!email.test(this.state.email)) {
                this.state.validation.email = {
                    error: true,
                    message: 'Please enter a valid email address',
                    style: {borderColor: "#b34c4c"}
                }
                this.setState({isLoading: false,isMigrating: false})
                formIsValid = false;
            }
        }

        this.setState({
            errors: errors
        });
        return formIsValid;

    }

    validate = (fieldName, value) => {
        let isValid = true;
        switch (fieldName) {

            case "phone":
                const re = /^[0-9\b]+$/;
                if (value === '' || re.test(value)) {
                    this.setState({phone: value});
                }

                this.state.validation.phone = {};
                if (this.state.countryCodeInfo.dial === '91') {
                    const regExp = /^[6-9\b]+$/;
                    if (
                        _.isUndefined(value) ||
                        _.isEmpty((value || "").toString()) ||
                        _.isNull(value) ||
                        value.trim().replace(" ", "").length > 10 ||
                        value.trim().replace(" ", "").length < 10
                    ) {
                        this.state.validation.phone = {
                            error: true,
                            message: 'Please enter your phone number',
                            style: {borderColor: "#b34c4c"}
                        }

                    }else if (!regExp.test(value[0])) {
                        this.state.validation.phone = {
                            error: true,
                            message: 'Please enter valid phone number',
                            style: {borderColor: "#b34c4c"}
                        }

                    }
                }
                break;

            case "email":
                const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.setState({email: value});
                this.state.validation.email = {};
                if (
                    _.isUndefined(value) ||
                    _.isEmpty((value || "").toString()) ||
                    _.isNull(value) ||
                    !email.test(value)
                ) {
                    this.state.validation.email = {
                        error: true,
                        message: 'Please enter a valid email address',
                        style: {borderColor: "#b34c4c", padding: "10px"}
                    }
                }
                break;

            default:
                isValid = true;
                break;
        }
        return isValid;
    };

    render() {
        const {error, loginSuccess} = this.state;
        if (loginSuccess) {
            return <Redirect to={{pathname: '/', state: {creditUserFlag: true}}}/>
        } else {
            return <section className="loginwrap">
                <div className="container-fluid">

                    <div className="login-box text-center">
                        {!isWebView() ?
                            <Link to={"/"}>
                                <img className="logotop"
                                     src='https://cdn.dscovr.com/images/logoWeNaturalists.svg'
                                     alt=""/>
                            </Link>
                            :
                            <Link to={"/login"}>
                                <img className="logotop"
                                     src='https://cdn.dscovr.com/images/logoWeNaturalists.svg'
                                     alt=""/>
                            </Link>
                        }
                        <h1 className="login-header-text">Welcome Back!</h1>
                        <p>Sign in to keep updated with the community</p>
                        <div className="login-form">
                            <form onSubmit={this.handleSubmit} id="user-login-form" name="user-login-form">
                                <div className="form-group">
                                    <input type="text" className="custom-input form-control" id="email" name="email"
                                           style={this.state.validation && this.state.validation.username && this.state.validation.username.style}
                                           value={this.state.username || ''} onChange={this.handleUserNameChange}
                                           aria-describedby="emailHelp" placeholder="Email or Phone"/>
                                </div>
                                <div className="form-group mb-2 passfield">
                                    <input type="password" className="custom-input form-control" id="passwrd"
                                           name="passwrd" value={this.state.password || ''}
                                           style={this.state.validation && this.state.validation.password && this.state.validation.password.style}
                                           onChange={this.handlePasswordChange}
                                           aria-describedby="inputGroupPrepend" placeholder="Password" /*maxLength="15"*//>
                                    <span className="passtoggle" style={{
                                        display: 'block' }}>Show</span>
                                </div>
                                {error &&
                                <>{error.message === 'Sorry! This account is deactivated. Please write at support@wenaturalists.com if you wish to reactivate this account.' ?
                                    <span className="login-feedback-error" style={{
                                        display: "block",
                                        background: error.message ? 'antiquewhite' : 'none'
                                    }}> Sorry! This account is deactivated. <br/><span style={{color: "#5e5e5e"}}>Please write at <a
                                        href="mailto:support@wenaturalists.com" target="_blank" style={{
                                        color: "#768203",
                                        textDecoration: "underline"
                                    }}>support@wenaturalists.com</a> if you wish to reactivate this account.</span> </span> :
                                    <span className="login-feedback-error" style={{
                                        display: "block",
                                        background: error.message ? 'antiquewhite' : 'none'
                                    }}> {error.message} </span>}</>}
                                <button type="submit" className="login-btn btn btn-primary mt-3"
                                        disabled={this.state.isLoaded === false}>Log in
                                </button>

                            </form>
                            <p className="login-paragraph-text">Not a part of WeNaturalists? &nbsp;
                                <NavLink to="/signup" className="signup-link">Join Us</NavLink></p>
                            <NavLink to="/reset-password" className="forgot-link">Forgot Password?</NavLink>
                        </div>
                    </div>
                </div>

                <div className="modal fade dgpopup CmpnyOpratrDataCllctnPopup" id="CmpnyOpratrDataCllctn" tabIndex="-1"
                     role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="CmpnyOpratrDataCllctnPopWrap">
                                <div className="modal-header">
                                    {this.state.companyLoginResponse &&
                                    <div className="cmpnyopartrhdrbx">
                                        <div className="cmpnyopartrbxtop">
                                            <figure>
                                                {this.state.companyLoginResponse &&
                                                this.state.companyLoginResponse.profileImage ?
                                                    <img
                                                        src={getCDNUrl(this.getOptimizedImage(this.state.companyLoginResponse))}
                                                        alt=""/> :
                                                    <img src="https://cdn.dscovr.com/images/DefaultBusiness.webp"
                                                         onError={(e) => {
                                                             e.target.onerror = null;
                                                             e.target.src = "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                                         }} alt=""/>}
                                            </figure>
                                            <div className="cmpnyopartrttle">
                                                <h3>{this.state.companyLoginResponse && this.state.companyLoginResponse.companyName}</h3>
                                                {this.state.companyLoginResponse && this.state.companyLoginResponse.country &&
                                                <p><i
                                                    className="iLocation"/>
                                                    <span>{this.state.companyLoginResponse && this.state.companyLoginResponse.country}</span>
                                                </p>
                                                }
                                            </div>
                                        </div>
                                        <div className="cmpnyopartrbxbtm">
                                            <p>Admin of this page is</p>
                                            <h4>{CapitalizeFirstLetter(this.state.companyLoginResponse && this.state.companyLoginResponse.firstName) + " " + CapitalizeFirstLetter(this.state.companyLoginResponse && this.state.companyLoginResponse.lastName)}</h4>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="modal-body">
                                    <div className="cmpnyopartrbdybx">
                                        <h4>Now you can</h4>
                                        <h2>Do a lot more</h2>
                                        <h4>with your Organization page</h4>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="connectinvite">
                                                    <div class="cmpnyopartriconbx">
                                                        <i class="iWN_Network_OL"></i>
                                                    </div>
                                                    <p>Invite members in your network</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="createwider">
                                                    <div class="cmpnyopartriconbx">
                                                        <i class="iGlobe2"></i>
                                                    </div>
                                                    <p>Create a wider reach for your Organization</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cmpnyopartrftrbx">	
                                        <h5>Provide details of <span>{this.state.companyLoginResponse && this.state.companyLoginResponse.firstName} {" "} {this.state.companyLoginResponse && this.state.companyLoginResponse.lastName}</span></h5>
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                {this.getCountryCode()}
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group">
                                                    <div className="chphonefield email">
                                                        <input type="text" className="custom-input form-control"
                                                               id="email"
                                                               style={this.state.validation.email ? this.state.validation.email.style : null}
                                                               value={this.state.email}
                                                               data-tip={
                                                                   this.state.validation && this.state.validation.email && this.state.validation.email.error &&
                                                                   this.state.validation.email.message
                                                               }
                                                               onBlur={(e) => this.validate("email", e.target.value)}
                                                               onChange={(e) => this.validate("email", e.target.value)}
                                                               aria-describedby="inputGroupPrepend" placeholder="Email *"/>
                                                        <span className="login-feedback-error mt-1"
                                                              style={{display: "block"}}>{this.state.errors.email}</span>
                                                        <span
                                                            className="login-feedback-error mt-1">{this.state.validation.email && this.state.validation.email.message}</span>

                                                    </div>
                                                </div>
                                            </div>
                                            <span
                                                className="login-feedback-error mt-1">{this.state.error && this.state.error.message}</span>
                                        </div>
                                         <p>This action will align your organization page to the profile of {this.state.companyLoginResponse && this.state.companyLoginResponse.firstName} {" "} {this.state.companyLoginResponse && this.state.companyLoginResponse.lastName} for an enhanced experience {/*<span>Company page will cease to exist.</span>*/} </p>
                                        <div className="text-center">
                                            <button className="submit-btn btn btn-primary"
                                                    onClick={this.handleCompanyMigrationSubmit}
                                                    disabled={this.state.isMigrating}>Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
    }
}

export default LoginComponent
