import React, {Component} from 'react';
import {authService} from "../../../auth";
import axios from "axios";
import {getCDNUrl, getOptimizedCauseCoverImage} from "../../../commons/CommonFunctions";
import {Link} from "react-router-dom";


class OtherCauses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            causeList:[],
            causeListViewMore:false,
            item:{},
            successMessge:false,
        }
    }

    componentDidMount() {
        this.getCauseList();
    }

        getCauseList() {

            let postBody = {
                "causeId": this.props.causeId,
                "userId":this.state.userData.userId,
                "cause_supported": this.props.supportedUserIds

            };
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/cause/similar/?page=' + 0 + "&size=" + 2,
                headers: {'Content-Type': 'application/json'},
                data: postBody,
                withCredentials: true
            }).then(response => {
                if (response && response.data && response.data.body && response.data.body.causeDetailList && response.data.status === '200 OK') {
                    this.setState({causeList: response.data.body.causeDetailList, showMore:response.data.body.flag});
                }
            }).catch((err) => {
                console.log(err);
            });
        };
    

    render() {
        return (
            <div className="col-md-4">
                <div className="rightpanel">
                    <div className="rightpanelinn">

                        <div className="dgwidget peoplefollow clearfix">
                            <h6>Other Causes</h6>
                            <div className="sideothercause">
                                {this.state.causeList && this.state.causeList.map((data,index)=>{
                                if(data.slug !== this.props.slug) {

                                    return <div key={index} className="caussupportblk">
                                        <Link to={"/cause/" + data.slug}>
                                            <img src={getCDNUrl(getOptimizedCauseCoverImage(data))} alt=""/>
                                            <div className="caussupportcaption">
                                                <div
                                                    className="title">{data.name}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                }
                                })}


                            </div>
                            {this.state.showMore &&
                            <Link to={"/causes"} className="showmore">Show more</Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default OtherCauses;
