import React, {Component} from 'react';
import {getCDNUrl} from "../commons/CommonFunctions";

var dateFormat = require('dateformat');

class ArticleSearchItem extends Component {  

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.userProfileImage;
    }

    redirect = () => {
        document.body.classList.remove('searchDropActive');
        if (this.props.item.postType === 'ARTICLE') {
            window.location.href = '/explore-detail/article/' + this.props.item.slug
        } else if (this.props.item.postType === 'EXPLORE_BLOG') {
            window.location.href = '/explore-detail/blog/' + this.props.item.slug
        } else if (this.props.item.postType === 'STORY') {
            window.open(this.props.item.postLinkTypeUrl, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
        }
        window.$('.topsearch').removeClass('active');
        window.$(".search_block_top1").removeClass("active-search");
        window.$("#header").removeClass("searchact");
    }

    render() {

        return (
            <li>
                <a href="javascript:void(0)" onClick={this.redirect} >
                    <div className="srchpostmain">
                        <figure className="users"><i className="iArticle"/>
                        </figure>
                        <div>
                            <h5>{this.props.item.title && this.props.item.title.length <= 30
                                ? this.props.item.title
                                : (this.props.item.title.substring(0, 30) + "...")}</h5>
                            <div className="userblk">
                                {!this.props.item.userEntityType ?
                                    <>
                                    <figure className="userimg"><img
                                        src={this.props.item.userProfileImage ?
                                            getCDNUrl(this.getOptimizedImage(this.props.item)) : (this.props.item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                        alt=""/>
                                    </figure>
                                    <div>{this.props.item.userName}</div>
                                    </>
                                    :
                                    this.props.item && this.props.item.params ?
                                    <>
                                        <figure className="userimg"><img
                                            src={this.props.item.params['circleImage'] != null
                                                ? getCDNUrl(this.props.item.params && this.props.item.params['circleImage'])
                                                : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                            onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/circleDefault.png"}}
                                            alt=""/>
                                        </figure>
                                        <div>{this.props.item.params['circleTitle']}</div>
                                    </> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="srchposttime">
                        {dateFormat(this.props.item.createTime, "dd mmm yyyy")}
                    </div>
                </a>
            </li>
        );
    }
}

export default ArticleSearchItem;
