import React from 'react'
import {Redirect} from 'react-router-dom';

import {authService} from './auth';
import axios from 'axios';

import httpService from './AxiosInterceptors';

httpService.setupInterceptors();

//var stompClient = null;
//var lastLogoutTime = null;

class Signout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loginSuccess: authService.isAuthenticated(),
            localStorageData: authService.getUserDetailsFromLocalStorage()
        };
    }

    componentDidMount() {
        this.loggedOutSuccess();
    }

    loggedOutSuccess = () => {
        //const Stomp = require('stompjs')

        if (this.state.localStorageData && this.state.localStorageData.userId) {
            var loginActivity = {
                "userId": this.state.localStorageData.userId,
                "loginStatus": "offline"
            }

            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + "/messaging/message_service/api/updateLoginActivity",
                data: loginActivity,
                withCredentials: true
            }).then(response => {
                this.successCallback();
                /*if (response && response.data.body !== null) {
                    lastLogoutTime = response.data.body.lastLogoutTime;
                    if (Stomp) {
                        stompClient = Stomp.client(process.env.REACT_APP_socketURL);
                        if (stompClient) {
                            stompClient.debug = null;
                            stompClient.connect({}, this.onConnected, this.onError);
                        } else {
                            this.successCallback();
                        }
                    }
                } else {
                    this.successCallback();
                }*/
            }).catch(() => {
                this.successCallback();
            });
        }
    }

    /*disconnectCallback = () => {
        this.successCallback();
    };

    onConnected = () => {
        var chatMessage = {
            "requestType": "loggedOut",
            "senderId": this.state.localStorageData.userId,
            "requestDetails": {"lastLogoutTime": lastLogoutTime}
        };
        stompClient.send("/app/chat.sendMessage", {}, JSON.stringify(chatMessage));
        stompClient.disconnect(this.disconnectCallback, {});
    };

    onError = () => {
        this.successCallback();
    };*/

    successCallback = () => {
        let userId = this.state.localStorageData.userId;
        authService.logout().then(() => {
            this.setState({"loginSuccess": false});
            window.location.reload();
            if(window.ReactNativeWebView && userId)
            {
                window.ReactNativeWebView.postMessage(JSON.stringify({type:'logout', userId: userId})); 
            }
        }).catch((e) => {
            console.log(e)
        });
    }


    render() {
        if (!this.state.loginSuccess) {
            // return <Redirect to='/'/>
            let userAgent = navigator.userAgent
            let mobileandroid = /wenat_android/.test(userAgent)
            let mobileios = /wenat_ios/.test(userAgent)

            if (mobileios || mobileandroid) {
                return <Redirect to='/login'/>
            } else {
                return <Redirect to='/'/>
            }

        }

        return null;
    }
}

export default Signout 
