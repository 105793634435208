import React from "react";
import {authService} from "../auth";
import axios from "axios";
import UserReactionComponent from "../UserReaction/UserReactionComponent";
import ReadMoreReadLess from "./ReadMoreReadLess";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Follow from "./Follow";
import * as _ from "lodash";
import { getCDNUrl, getOptimizedImage } from "./CommonFunctions";

class FeedbackCard extends React.Component {

    constructor(props) {
        super(props);
        let validation = {
            title: {},
            description: {}
        }
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            value: props.data?.rating,
            validation,
            submitted: false,
            title: '',
            description: '',
            messages: null,
            disable: true,
            editFeedBackId: '',
            isEditFeedBack: false,
            flagged: props.data?.flagged,
            data: props?.data
        }
    }

    redirectToProfile = (customUrl) => {
        window.location.href = "/profile/" + customUrl;
    };

    handleTitleAndDescriptionChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            disable: e.target.value.length > 0 ? false : true
        }) 
    };

    setValue = (event, value) => {
        this.setState({'value': value});
        this.setState({
            disable: value > 0 ? false : true
        })
    };

    handleFeedbackEdit = (data) => {
        this.setState({
           isEditFeedBack: true,
           editFeedBackId: data?.id,
           title: data?.title,
           description: data?.description,
           value: data?.rating,
        },() => {
            window.$("#feedbackViewpop").modal('show');
        })
    };

    handleCloseFeedbackEditPopUp = () => {
        window.$("#feedbackViewpop").modal('hide');
        this.setState({
            isEditFeedBack: false,
        })
    }

    validateForm() {
        this.state.validation.description = {};
        let validationRequired = false;
        let errors = {};
        let formIsValid = true;
        if (_.isUndefined(this.state.description) || _.isEmpty((this.state.description || "").toString()) ||  _.isNull(this.state.description)) {
            this.setState({
                error: {message: 'Please provide a valid description'}
            });
            formIsValid = false;
            validationRequired = true;
        }
        if (_.isUndefined(this.state.title) ||  _.isEmpty((this.state.title || "").toString().trimStart()) || _.isNull(this.state.title)) {
            this.setState({
                error: {message: 'Please enter a valid title'}
            });
            formIsValid = false;
            validationRequired = true;
        }
        if (_.isUndefined(this.state.value) || _.isEmpty((this.state.value || "").toString())) {
            this.setState({
                error: {message: 'Please enter a valid feedback'}
            });
            formIsValid = false;
            validationRequired = true;
        }
        this.setState({
            errors: errors
        });
        setTimeout(() => {
            this.setState({
                error: {},
            });
        }, 5000);
        return formIsValid;

    }

    handleFeedbackFlag = (data) => {
        let postData = {
            userId: this.state.userData?.userId,
            feedbackId: data?.id,
            flag: !this.state.flagged
        };

        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/feedback/flag/update/',
            headers: {'Content-Type': 'application/json'},
            data: postData,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.setState({
                    flagged: !this.state.flagged
                })
            }
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                this.setState({
                    error: {message: err.response.data.message, err: err.response}
                });
            }
        });
    };

    handleEditSubmit = (event) => {
        event.preventDefault();
        this.setState({isLoading: true})

        if (this.validateForm()) {
            this.setState({'submitted': true});

            let PostData = {
                "userId": this.state.userData.userId,
                "title": this.state.title,
                "description": this.state.description,
                "rating": this.state.value,
                "feedbackId": this.state.editFeedBackId 
            };

            axios({
                method: 'post',
                url: `${process.env.REACT_APP_userServiceURL}/backend/feedback/edit/`,
                headers: {'Content-Type': 'application/json'},
                data: PostData,
                withCredentials: true
            }).then((response) => {
                let res = response.data;
                    if (res.message === 'Success!') {
                        this.setState({
                            data : { ...this.state.data, title: this.state.title, description: this.state.description, rating: this.state.value}
                        })
                        window.$("#feedbackViewpop").modal('hide')
                        this.setState({isEditFeedBack: false, editFeedBackId: "", title: '', description: "", value: ''})  
                    }
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    this.setState({
                        isLoaded: true,
                        isSubmitted: false,
                        error: {message: err.response.data.message, err: err.response}
                    });
                }
            });
            setTimeout(() => {
                this.setState({
                    error: {},
                    isLoading: false
                });
            }, 5000);
        } 
    }

    render() {
        const {data} = this.state;
        return (
            <>
                <div className="feedbreviewrow" >
                    <div className={"forumcommtvlisttop" + (data.userType === "COMPANY" ? " orgnz" : "")}>
                        <div className="fuserblk">
                            <figure onClick={(e) => {e.preventDefault(); this.redirectToProfile(data?.customUrl)}}>
                                <a href="">
                                    <img src={!_.isNull(data.profileImage) && !_.isUndefined(data.profileImage) ? getCDNUrl(data.profileImage) : (data.userType != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")} alt=""/>
                                </a>
                            </figure>
                            <div>
                                <h3>
                                    <a href="javascript:void(0)" onClick={(e) => {e.preventDefault(); this.redirectToProfile(data.customUrl)}}>
                                        {data.userName}
                                    </a>
                                    {!data.deactivated  && <Follow followed={data.followed} userId={data.userId} />}
                                </h3>
                                <span>{new Intl.DateTimeFormat("en", { hour: "numeric",minute: "numeric",year: "numeric",month: "short",day: "2-digit"}).format(data.time)}</span>
                            </div>
                        </div>

                        <div className="fdate">
                            <UserReactionComponent item={data} key={data.id} />
                            {(data.canEdit || data.canFlag) &&
                                <div className="contxmenu" style={{marginLeft:"3px"}}>
                                    <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="iKebab"/></button>
                                    <div className="dropdown-menu fadeIn animated">
                                        {(data.canEdit && !data?.archived) && <a className="dropdown-item"  onClick={e => {this.handleFeedbackEdit(data)}}><i className="iEditBox" style={{fontSize: '13px'}}/>Edit</a>}
                                        {data.canFlag && <a className="dropdown-item" href="javascript:void(0)" onClick={() => !this.state.flagged && this.handleFeedbackFlag(data)} ><i className={!this.state.flagged ? "iPost-Flag" : "iPost-Flagged"} style={{ fontSize: '14px', verticalAlign: '-2px' }} /> {!this.state.flagged ? "Flag" : "Flagged"} </a>}
                                    </div>
                                </div>
                            }
                        </div>
                        

                    </div>
                    <div className="forumcommtvlistdesc">
                        <div className="gbrating">
                            <Box component="fieldset" mb={4} borderColor="transparent">
                                <Rating name="simple-controlled" value={data.rating} readOnly={true} />
                            </Box>
                        </div>
                        <h4>{data.title}</h4>
                        {data.description && <ReadMoreReadLess key={data.id}  id={data.id} description={data.description} />}
                    </div>
                </div>

                 {/* feedback edit & Flag implementation */}
                {this.state.isEditFeedBack &&
                     <div id="feedbackViewpop" className="modal fade dgpopup reqendorse feedbackViewpop">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="addfeedbackform">
                                        <button type="button" className="close topCrossModal" aria-label="Close" onClick={() => {this.handleCloseFeedbackEditPopUp()}}>
                                            <i className="iCross" />
                                        </button>
                                        <div className="addfeedbformbox">
                                            <form  name="feedbackEditForm" id="feedbackEditForm" onSubmit={this.handleEditSubmit}>
                                                <h4>Update your Feedback</h4>
                                                <div className="forumcommtvlisttop">
                                                    <div className="fuserblk">
                                                        
                                                        <figure>
                                                            <img  src={this.state.data?.profileImage != null ? getCDNUrl(getOptimizedImage(this.state.data,"profileImage")) : (this.state.data?.userType != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")} alt=""/>
                                                        </figure>
                                                        <div>
                                                            <h3>
                                                                <a href="javascript:void(0)" onClick={(e) => { e.preventDefault(); this.redirectToProfile(this.state.data?.customUrl)}}>
                                                                    {this.state.data?.userName}
                                                                </a>
                                                                {!this.state.data?.deactivated  && <Follow followed={this.state.data?.followed} userId={this.state.data?.userId}/>}
                                                            </h3>
                                                        </div>                                                   
                                                    </div>
                                                </div>
                                                <div>
                                                    <Box component="fieldset" mb={4} borderColor="transparent">
                                                        <Rating
                                                            name="simple-controlled2"
                                                            value={this.state.value}
                                                            onChange={(event, newValue) => {event.preventDefault();this.setValue(event, newValue)}}
                                                        />
                                                    </Box>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="title" className="form-control dginput" value={this.state?.title} onChange={this.handleTitleAndDescriptionChange} placeholder="Add title..."/>
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="description" id=""  onChange={this.handleTitleAndDescriptionChange} value={this.state?.description} className="form-control dginput" placeholder="Write feedback here..."/>
                                                </div>
                                                <div className="bottbtnwrap">
                                                    {this.state.error && this.state.error.message !== "" && this.state.error.message !== undefined && <div className="login-feedback-error"> {this.state.error.message}</div>}
                                                    {this.state.success &&<div className="login-feedback-error">{this.state.messages}</div>}
                                                    <input type="submit" disabled={this.state.disable} value={"Update"} className="btn btnsubmit"/>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </>
        );
    }
}

export default FeedbackCard;