export const SET_MESSAGE_COUNT = "SET_MESSAGE_COUNT";
export const SET_MESSAGE_COUNT_SUCCESS = "SET_MESSAGE_COUNT_SUCCESS";

let initialState = {
    messageCountResult: {}
};

const messageCountReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_MESSAGE_COUNT_SUCCESS:
            state = {messageCountResult: {value: action.payload}, isLoaded: true};
            return state;
        case SET_MESSAGE_COUNT:
            state = {messageCountResult: {value: action.payload}, isLoaded: false};
            return state;
        default:
            return state;
    }
};

export default messageCountReducer;

export function setMessageCount(response) {
    return {type: SET_MESSAGE_COUNT, payload: response};
}

export function setMessageCountOnSuccess(response) {
    return {type: SET_MESSAGE_COUNT_SUCCESS, payload: response};
}
