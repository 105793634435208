import React, { Component } from "react";
import { authService } from "../auth";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import LikeMemberItem from "./LikeMemberItem";
import { getShareCheckRestriction } from "../services/ShareApi";
import { getViewUserRestriction } from "../services/PostApi";

class PostViewsListPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            userListData: {},
            restrictionUserFlag: false,
        }
    }

    getCategorywiseAnalyticsViewData = () => {
        let url = `analytics/getViewsList?postId=${this.props?.entityId}&entityType=${this.props.entityType}&`;
        if(this.props.popupType !== "VIEW"){
            url = `share/getUsers/${this.props?.entityId}?`;
        }
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/${url}userId=${this.state.userData?.userId}&page=0&size=10`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data.message === "Success!") {
                let data = response.data.body;
                this.setState({
                    userListData: data
                })
            } 
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                userListData: {}
            })
        });
    }

    componentWillUnmount() {
        window.$("#postViewsPopUp").modal("hide");
    }

    componentDidMount() {
        this.getCategorywiseAnalyticsViewData();
        this.checkRestrictionUserFlag();
    }

    /*  checking restriction */
    checkRestrictionUserFlag = async () => {
        let postData = {
            entityId: this.props?.entityId,
            userId: this.state.userData?.userId
        }
        let response = {};
        if(this.props.popupType !== "VIEW") {
            response = await getShareCheckRestriction(postData);
        } else {
            response = await getViewUserRestriction(this.props?.entityId);
        }
        if(response?.message === "Success!" || response?.status === 200) {
            this.setState({
                restrictionUserFlag: response.data || response.body
            })
        }
    }

    render() {
        const { userListData } = this.state;
        const { popupType } = this.props;
        return (
            <div id={"postViewsPopUp"} className="modal fade dgpopup postViewsPopUp shareModal reqendorse" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="similarAllpopWrap forwcontwrap" style={{ maxWidth: "424px" }}>
                                <div>
                                    <div className="similarAllpopHeader">
                                        <h2>{popupType !== "VIEW" ? "Member Share" : `${this.props.entityType === "USER" ? "Profile": this.props.entityType === "CIRCLE" ? "Community" : "Post"} Views`}</h2>
                                    </div>
                                    <div className={"similarAllpopBody"}>
                                        <Scrollbars
                                            className={"forwcontlistmain"}
                                            autoHide
                                            autoHeight
                                            autoHeightMin={0}
                                            autoHeightMax={322}
                                            universal={true}
                                            renderTrackHorizontal={(props) => (
                                                <div {...props} className="track-horizontal" />
                                            )}
                                            renderTrackVertical={(props) => (
                                                <div {...props} className="track-vertical" />
                                            )}
                                        >
                                            {" "}
                                            {userListData && userListData.content && userListData.content.length > 0 && (
                                                <>
                                                    {userListData.content.map((data, index) => {
                                                        return <LikeMemberItem item={data} key={data.userId} pageType={"ANALYTICS_VIEW"} />
                                                    })}
                                                </>
                                            )}

                                            {userListData && userListData.content && userListData.content.length === 0 &&
                                                <div className="zdata">
                                                    <div className="desc">
                                                        <h4>No data available.</h4>
                                                    </div>
                                                </div>}

                                            {userListData && userListData.page && !userListData.page.last &&
                                                <div className="btnblock text-center">
                                                    <Link className="btn morebtnsm" to={{pathname: `/post/${popupType !== "VIEW" ? "share" : "views"}/list/${this.props.entityId}`, state: { entityType: this.props.entityType }}}>View All</Link>
                                                </div>
                                            }
                                        </Scrollbars>
                                    </div>
                                </div>
                                <div className="forwcontwrapbott">
                                    {this.state.restrictionUserFlag && <div className="itemscount">{popupType !== "VIEW" ? "Some shared posts are not displayed because of their visibility settings." : "Visibility restricted for others"}</div>}
                                    <div style={{marginLeft: 'auto'}}>
                                        <button className="butt btnsecondary close" onClick={(e) => { this.props.triggerViewClickCLose() }}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PostViewsListPopUp;