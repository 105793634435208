export const SET_DRAFTS = "/project/SET_DRAFTS";
export const GET_DRAFTS = "/project/GET_DRAFTS";
export const SET_DRAFTS_SUCCESS = "/project/SET_DRAFTS_SUCCESS";

let initialState = {
  drafts: [],
  other:{},
  isLoaded:false
};

const draftReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "/project/SET_DRAFTS_SUCCESS":
         state = {drafts:[...action.payload],other:state.other,isLoaded:true};
         return state;
      case "/project/SET_DRAFTS": state = {other:action.payload,drafts:state.drafts,isLoaded:false};
          return state;
      default:
          return state;
  }
  return state;
};

export default draftReducer;

export function setDraft(response){
    return {type:SET_DRAFTS,payload:response};
}

export function setDraftOnSuccess(response){
    return {type:SET_DRAFTS_SUCCESS,payload:response};
}
