import React from 'react'

import FooterDiv from "../FooterDiv";

import httpService from '../AxiosInterceptors';
import axios from "axios";
import * as _ from "lodash";
import {authService} from "../auth";
import { isWebView, userAgentPostData } from '../commons/CommonFunctions';

httpService.setupInterceptors();

class OtpNew extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email:props.location && props.location.state && props.location.state.signUpResponse && props.location.state.signUpResponse.email,
            newEmail:'',
            emailChangeError:false,
            emailChangeErrorMessage:'',
            emailResendError:false,
            emailResendErrorMessage:'',
            errors: {},
            showResendButton:props.location.state.signUpResponse.emailResend
        };

    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        if (_.isUndefined(this.state.newEmail) ||
            _.isEmpty((this.state.newEmail || "").toString()) ||
            _.isNull(this.state.newEmail)) {
            errors["newEmail"] = "Please enter your email address";
            formIsValid = false;
        } else {
            const newEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.setState({ newEmail: this.state.newEmail });
            if (!newEmail.test(this.state.newEmail)) {
                errors["newEmail"] = "Please enter a valid email address";
                formIsValid = false;
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid;

    }


    onHideModal = () =>{
        this.setState({'newEmail':''})
        window.$("#editEmailIDPop").modal("hide");
    }

    hideResendPopUp = () =>{
        window.$("#emailSentSuccessPopUp").modal("hide");
    }

    handleEmailChange = (e) => {
        this.setState({newEmail: e.target.value.trim()});
    };



    emailChangeRequest = (event) => {
        event.preventDefault();
        if(this.validateForm()) {
            let postBody = {
                "userId": this.props.location.state.signUpResponse.userId,
                "existingEmailId":this.state.email,
                "newEmailId":this.state.newEmail,
                "userAgent": userAgentPostData()
            };
    
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/profile/update/email-id',
                headers: {'Content-Type': 'application/json'},
                data: postBody
            }).then((response) => {
                let res = response.data;
                if (res.message === 'Success!') {
                    this.setState({
                        'email':this.state.newEmail});
                    this.onHideModal();
    
    
                }
            }).catch((err) => {
                if (err && err.response) {
                    this.setState({
                        emailChangeError: true,
                        emailChangeErrorMessage:  err.response.data.message
                    });
                }
                setTimeout(() => {
    
                        this.setState({
                            emailChangeError: false,
                            emailChangeErrorMessage:''
                        });
    
                }, 5000);
                console.log(err);
            });
        }

        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 5000);

        }
    };

    emailResendRequest = (event) => {
        event.preventDefault();
            let postBody = {
                "userId": this.props.location.state.signUpResponse.userId
            };

            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/email/resend',
                headers: {'Content-Type': 'application/json'},
                data: postBody
            }).then((response) => {
                let res = response.data;
                if (res.message === 'Success!') {

                    this.setState({showResendButton:false});
                    window.$("#emailSentSuccessPopUp").modal("show");

                }
            }).catch((err) => {
                if (err && err.response) {
                    this.setState({
                        emailResendError: true,
                        emailResendErrorMessage:  err.response.data.message
                    });
                }
                setTimeout(() => {

                    this.setState({
                        emailResendError: false,
                        emailResendErrorMessage:''
                    });

                }, 5000);
                console.log(err);
            });



    };
    
    render() {
        return <section className="signup-wrapper">
            <div className="signup-container pt-3">
                <a href={!isWebView() ? "/login" : "/login-mob"} className="goback"> Go Back</a>
                <div className="signup-box text-center">
                    <div className="signup-boxinn otpwrap">

                        <a href="/"><img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" alt="" className="logotop" /></a>
                        <h1 className="signup-header-text"></h1>

                        <div className='otpNewText'>
                            <p>To proceed, please verify using the verification link <br />sent to your email <span>{this.state.email}</span> <a href="#editEmailIDPop" data-toggle="modal" ><i class="iEditBox"></i></a></p>
                            {this.state.showResendButton &&
                            <p>If you didn't receive email Please <a href="javascript:void(0)" onClick={this.emailResendRequest} className="clickTxt">click here</a> to resend</p>
                            }
                            {this.state.emailResendError &&
                            <span className="login-feedback-error mt-1"
                                  style={{display: "block"}}>
                                   {this.state.emailResendErrorMessage}
                                </span>
                            }

                            <div className='emailVeriPartImg'>
                                <img
                                    src="https://cdn.dscovr.com/images/emailNeedVerify1.png"
                                    width="160"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "https://cdn.dscovr.com/images/emailNeedVerify1.png"
                                    }} alt=""/>
                            </div>
                        </div>
                        <div id="editEmailIDPop" className="modal fade dgpopup editEmailPop" tabIndex="-1"
                             data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="similarAllpopWrap forwcontwrap"
                                             style={{maxWidth: "460px", position: 'relative'}}>
                                            <button type="button" className="close topCrossModal" data-dismiss="modal"
                                                    aria-label="Close" onClick={(e) => this.onHideModal()}
                                                    style={{top: '13px'}}>
                                                <i className="iCross"/>
                                            </button>
                                            <div className="similarAllpopHeader">
                                                <h2>Edit Email Id</h2>
                                            </div>
                                            <div className="similarAllpopBody">
                                                <div>
                                                    <div className="form-group">
                                                        <input  id="newEmail" type="text"
                                                                onChange={this.handleEmailChange}
                                                               className="form-control custom-input" placeholder="Enter Email"
                                                               value={this.state.newEmail}/>
                                                        {this.state.errors["newEmail"] && <span
                                                            className="login-feedback-error mt-1">{this.state.errors["newEmail"]}</span> }
                                                        {this.state.emailChangeError &&
                                                        <span className="login-feedback-error mt-1"
                                                              style={{display: "block"}}>
                                                            {this.state.emailChangeErrorMessage}
                                </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="forwcontwrapbott justify-content-end">
                                                <div>
                                                    <button className="btn" type="submit" onClick={this.emailChangeRequest} style={{marginRight: '5px', padding: '9px 15px'}}>Submit</button>
                                                    <button className="butt btnsecondary close"
                                                            data-dismiss="modal" onClick={(e) => this.onHideModal()}>Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="emailSentSuccessPopUp" className="modal fade dgpopup confirmpop" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => this.hideResendPopUp()}>
                                        <span aria-hidden="true">&times;</span></button>
                                    <div className="confirm-popup-modal-body">
                                        Email has been sent Successfully
                                    </div>
                                    <div className="modal-footer confirm-popup-modal-footer">
                                        <button className="confirm-pop-btn-secondary" data-dismiss="modal" onClick={(e) => this.hideResendPopUp()}>Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <FooterDiv />
            </div>

        </section>

    }
}

export default OtpNew
