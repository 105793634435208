import React, { Component } from "react";
import { convertCurrencySystem, nFormatter } from "../commons/CommonFunctions";

class DonationProgressbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progressBarPercentage: 0
        }
    }

    render() {
        const { donationBar } = this.props;
        const progressBarPercentage = ((this.props.donationBar?.minValue / this.props.donationBar?.maxValue) * 100).toFixed(2);
        return (
            <div className="donationProgressBar">
                <div className="progress active">
                    <div className="progress-bar" role="progressbar" aria-valuenow={donationBar.minValue} aria-valuemin="0" aria-valuemax={donationBar.maxValue} style={{width : `${progressBarPercentage}%`}} />
                </div>
                <span className="progress-completed">{donationBar.indian ? <>&#8377; </> : <>&#36; </>}{convertCurrencySystem(donationBar.minValue)}/{convertCurrencySystem(donationBar.maxValue)}</span>
            </div> 
        )
    }
}

export default DonationProgressbar;