import React, {Component} from 'react';

class GroupSearchItem extends Component {

    render() {

        return (
            <li>
                <a href="/#">
                    <div className="srchpostmain">
                        <figure className="users group">
                            <div className="usersgroup"><img
                                src="https://cdn.dscovr.com/images/users1.webp" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/users1.png"}} alt=""/></div>
                            <div className="usersgroup"><img
                                src="https://cdn.dscovr.com/images/users1.webp" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/users1.png"}} alt=""/></div>
                            <div className="usersgroup"><img
                                src="https://cdn.dscovr.com/images/users1.webp" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/users1.png"}} alt=""/></div>
                        </figure>
                        <div>
                            <h5>Issues Regarding Ladakh</h5>
                            This chat group consist of people...
                        </div>
                    </div>
                    <div className="srchposttime">
                        Connected
                    </div>
                </a>
            </li>
        );
    }
}

export default GroupSearchItem;
