import React from "react";
import axios from "axios";
import {authService} from "../auth";
import uuid from "react-uuid";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const ErorrMsgPopUp = withReactContent(alertPopUp)

class ProfileVisibility extends React.Component {

    constructor(props) {
        super(props);
        let userData = authService.getUserDetailsFromLocalStorage();
        this.state = {
            userData: userData,
            visibleToProfileChecked:props.visibleToProfileChecked || false,
            visibleToProfile:props.visibleToProfileChecked || false,
            error: '',
            modalEntityId: this.props.entityId + uuid()
        }
    }

    handleVisibleToProfile = (event)=>{
        this.setState({'visibleToProfileChecked': event.target.checked});
    };

    handleVisibleToProfileSubmit = ()=>{
        let visibleToProfile = this.state.visibleToProfile;
        let postBody = {
            "projectId":this.props.entityId,
            "visibleToProfile": this.state.visibleToProfileChecked,
            "userId":this.state.userData.userId,
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/participants/update/visible-to-profile',
            headers: { 'Content-Type': 'application/json' },
            data: postBody,
            withCredentials: true
        }).then((response, dispatch) => {
            let res = response.data;
            if (response.status === 202) {
                this.setState({'visibleToProfile':!this.state.visibleToProfile});
                this.hideModal();
                this.props.triggeredProfileVisibilityCloseOnSuccess(!visibleToProfile);
            }
        }).catch((err) => {
            if(err?.response?.data?.status === "409") {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm();
                    }
                }).then((result) => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <p>This project is added to your nomination, cannot remove from your profile visibility.</p>,
                    })
                })
            }
        });

    };


    componentWillUnmount() {
        this.hideModal();
    }

    componentDidMount() {
        window.$('#profileVisibility' + this.state.modalEntityId).modal('show');

    }

    hideModal = () => {
        this.setState({
            isSubmitted: false,
            details: '',
            reason: '',
            error: ''
        });
        window.$('#profileVisibility' + this.state.modalEntityId).modal('hide');
        this.props.triggerProfileVisibilityClose();
    }

    render() {
        return (
            <div id={"profileVisibility" + this.state.modalEntityId} className="modal fade dgpopup reportPop" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop={'static'}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="forwcontwrap">
                                <div className="formwrap">
                                    <div className="custom-control custom-checkbox text-left">
                                        <input type="checkbox" className="custom-control-input" ref="visibleToProfile" name="visibleToProfile" checked={this.state.visibleToProfileChecked} onChange={this.handleVisibleToProfile}
                                               id="projectFormVisibleToProfile" />
                                        <label className="custom-control-label"
                                               htmlFor="projectFormVisibleToProfile" style={{cursor:'pointer'}}>
                                            Display in Profile
                                            <span style={{display: 'block'}}>Ongoing or the Completed projects will reflect in your Profile</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="formbottom text-center">
                                    <button type="button" className="btn btn-cancel"
                                            onClick={this.handleVisibleToProfileSubmit}>OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default ProfileVisibility;
