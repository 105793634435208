import React, {PureComponent} from 'react';
import {CapitalizeFirstLetter} from "../utils/StringUtility";
import * as _ from "lodash";
import {convertUnicode, getCDNUrl} from "../commons/CommonFunctions";

class ProjectPostItem extends PureComponent {

    render() {

        return (
            <li className="dropdown-item">
                <a href="javascript:void(0)"  className={this.props.item.restrictionDueToSubscriptionExpiration ? "private-project" : ""} onClick={this.props.item.restrictionDueToSubscriptionExpiration ? "javascript:void(0)": this.props.setAsSource} style={{alignItems: 'flex-start'}}>
                    <figure className="usimg">
                        <img
                            src={!_.isUndefined(this.props.item.imageUrl) && !_.isNull(this.props.item.imageUrl) ? getCDNUrl(this.props.item.imageUrl) : "https://cdn.dscovr.com/images/project-default.webp"}
                            onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/project-default.jpg"}}
                            alt=""/>
                    </figure>
                    <div>
                        <h5>{convertUnicode(this.props.item.title)}</h5>
                        <span>{CapitalizeFirstLetter(this.props.item.projectType.toLowerCase())}</span>
                    </div>
                </a>
            </li>
        );
    }
}

export default ProjectPostItem;
