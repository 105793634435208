import React, { memo } from "react";
import { Component } from "react";
import { authService } from "../../../auth";
import axios from "axios";
import StudioViewClickPopUp from "./StudioViewClickPopUp";
import { numFormatter } from "./StudioContentCommonFunction";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { withRouter } from "react-router-dom";

const ErorrMsgPopUp = withReactContent(alertPopUp);

class StudioViewClickSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            viewClickData: {},
            viewClickModalPop: false,
            viewClickModalType: "",
            loginRequired: false,
            userId: authService.isAuthenticated() && (this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId)
        }
    }

    getViewClickData = (type) => {
        let actionType = type.replace(" ","").toUpperCase();
        let url = `${process.env.REACT_APP_userServiceURL}/paidContentPost/getUsers/${this.props.studioId}?userId=${this.state.userId}&entityId=${this.props.studioPostData.entityId}&actionType=${actionType}&page=0&size=10`;
        if(type === "Subscriber") {
            url = `${process.env.REACT_APP_userServiceURL}/studioPayment/getPaidUsers/${this.props.studioId}?userId=${this.state.userId}&entityId=${this.props.studioPostData.entityId}&page=0&size=10`;
        }
        axios({
            method: "GET",
            url: url,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data.message === "Success!") {
                let data = response.data.body;
                this.setState({
                    viewClickModalType : type,
                    viewClickModalPop: true,
                    viewClickData: data,
                },() => {
                    window.$("#studioViewClickPopUp").modal("show");
                })
            } 
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                viewClickModalType : "",
                viewClickData: [],
            })
            ErorrMsgPopUp.fire({
                didOpen: () => {
                    ErorrMsgPopUp.clickConfirm();
                }
            }).then((result) => {
                return ErorrMsgPopUp.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    title: <><p>{err.response.data.message}</p></>,
                })
            })
        });
    }

    triggerViewClickCLose = () => {
        this.setState({
            viewClickData: {},
            viewClickModalPop: false
        })
        window.$("#studioViewClickPopUp").modal("hide");
    }

    render() {
        const { viewClickModalPop, viewClickData, viewClickModalType, loginRequired, loginSuccess, userId } = this.state;
        const { studioPostData, studioId, pageName } = this.props;
        return (
            <>
                {pageName === "details" ? 
                    <div className="paidProjInfo">
                        <div style={{ "cursor": loginSuccess ? (studioPostData.userId === userId || studioPostData.permissionAllowed) ? "pointer" : "default" : "pointer" }} onClick={(e) => loginSuccess ? (studioPostData.userId === userId || studioPostData.permissionAllowed) ? this.getViewClickData("View") : "javascript:void(0)" : this.props.loginRequiredFunc()}><i className="iEye_F" style={{fontSize: '17px'}} /> Views <span>{numFormatter(studioPostData.viewCount)}</span></div>
                        <div style={{ "cursor": loginSuccess ? (studioPostData.userId === userId || studioPostData.permissionAllowed) ? "pointer" : "default" : "pointer" }} onClick={(e) => loginSuccess ? (studioPostData.userId === userId || studioPostData.permissionAllowed) ? this.getViewClickData("Click") : "javascript:void(0)" : this.props.loginRequiredFunc()}><i className="iclick" style={{fontSize: '18px'}} /> Clicks <span>{numFormatter(studioPostData.clickCount)}</span></div>
                        <div style={{ "cursor": loginSuccess ? (studioPostData.userId === userId || studioPostData.permissionAllowed) ? "pointer" : "default" : "pointer" }} onClick={(e) => loginSuccess ? (studioPostData.userId === userId || studioPostData.permissionAllowed) ? this.getViewClickData("Subscriber") : "javascript:void(0)" : this.props.loginRequiredFunc()}><i className="iEye_paid-View" /> Subscribers <span>{numFormatter(studioPostData.paidViewCount)}</span></div>
                    </div> :
                pageName === "settings" ? 
                    <div className="paidProjInfo">
                        <div data-tip="Views" data-place="bottom" style={{ "cursor": (studioPostData.userId === userId || studioPostData.permissionAllowed) ? "pointer" : "default" }} onClick={(e) => (studioPostData.userId === userId || studioPostData.permissionAllowed) ? this.getViewClickData("View") : "javascript:void(0)"}><i className="iEye_F" style={{fontSize: '17px'}} /> <span>{numFormatter(studioPostData.viewCount)}</span></div>                                                        
                        <div data-tip="Clicks" data-place="bottom" style={{ "cursor": (studioPostData.userId === userId || studioPostData.permissionAllowed) ? "pointer" : "default" }} onClick={(e) => (studioPostData.userId === userId || studioPostData.permissionAllowed) ? this.getViewClickData("Click") : "javascript:void(0)"}><i className="iclick" style={{fontSize: '18px'}} /> <span>{numFormatter(studioPostData.clickCount)}</span></div>
                        <div data-tip="Subscribers" data-place="bottom" style={{ "cursor": (studioPostData.userId === userId || studioPostData.permissionAllowed) ? "pointer" : "default" }} onClick={(e) => (studioPostData.userId === userId || studioPostData.permissionAllowed) ? this.getViewClickData("Subscriber") : "javascript:void(0)"}><i className="iEye_paid-View" /> <span>{numFormatter(studioPostData.paidViewCount)}</span></div>
                    </div> :
                    <div className="paidProjBlksBottInfo">
                        <div style={{ "cursor": loginSuccess ? ((studioPostData.userId === userId || studioPostData.permissionAllowed) ? "pointer" : "default") : "pointer"}} onClick={(e) => loginSuccess ? (studioPostData.userId === userId || studioPostData.permissionAllowed) ? this.getViewClickData("View") : "javascript:void(0)" : this.props.loginRequiredFunc()}>
                            <span>{numFormatter(studioPostData.viewCount)}</span> Views
                        </div>
                        <div className="text-center" style={{ "cursor": loginSuccess ? ((studioPostData.userId === userId || studioPostData.permissionAllowed) ? "pointer" : "default") : "pointer" }} onClick={(e) => loginSuccess ? (studioPostData.userId === userId || studioPostData.permissionAllowed) ? this.getViewClickData("Click") : "javascript:void(0)" : this.props.loginRequiredFunc()}>
                            <span>{numFormatter(studioPostData.clickCount)}</span> Clicks
                        </div>
                        <div className="text-right" style={{ "cursor": loginSuccess ? ((studioPostData.userId === userId || studioPostData.permissionAllowed) ? "pointer" : "default") : "pointer" }} onClick={(e) => loginSuccess ? (studioPostData.userId === userId || studioPostData.permissionAllowed) ? this.getViewClickData("Subscriber") : "javascript:void(0)" : this.props.loginRequiredFunc()}>
                            <span>{numFormatter(studioPostData.paidViewCount)}</span> Subscribers
                        </div>
                    </div>
                }

                {viewClickModalPop && <StudioViewClickPopUp viewClickUserData={viewClickData} modalType={viewClickModalType} triggerViewClickCLose={this.triggerViewClickCLose} studioId={studioId} entityId={studioPostData.entityId} />}

            </>
        )
    }
}

export default withRouter(memo(StudioViewClickSection));