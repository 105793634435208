import React, { Component } from 'react';
import { authService } from '../../../auth';


class StudioContentHelpDeskInfoPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        window.$('#studioHepdeshInfoPop').modal('hide');
    }

    render() {
        const { helpDeskDataItem } = this.props;
        return (
            <div id={"studioHepdeshInfoPop"} className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="studioHepdeshInfoPop">
                                <button type="button" className="close topCrossModal" data-dismiss="modal">
                                    <i className="iCross" />
                                </button>

                                <p className="email">{helpDeskDataItem.email}</p>
                                <p className="phone">{helpDeskDataItem.phoneNumber}</p>
                                <hr />
                                <p dangerouslySetInnerHTML={{
                                    __html: helpDeskDataItem.shortBrief,
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default (StudioContentHelpDeskInfoPopup);
