import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import { authService } from "../auth";
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { setWpondYearData } from '../reducer/wpond/WpondReducer';

class LeftPanelUnauth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            year: ""
        }
    }

    componentDidMount() {
        setTimeout(() => {
            window.$(".ltWPONDLogo").addClass('hide');
        }, 15000)
        this.props.setWpondYearData(false);
    }


    render() {
        return (
            <div className="col-md-3">
                <div className="leftpanel nologin">
                    <div className="sidemenu">
                        <div className="toptext"><a href="javascript:void(0)"
                            onClick={(e) => this.props.signUpRequired()}>Join
                            us</a> to Explore more
                        </div>
                        <ul>
                            <li className="feeds"><NavLink to={"/feeds"} activeClassName="active">Feeds</NavLink></li>
                            <li className="explore"><NavLink to={"/explore"} activeClassName="active">Explore</NavLink></li>
                            <li className="studio"><NavLink to={"/studio"} activeClassName="active">Studio</NavLink></li>
                            <li className="message"><NavLink to={"/message"} activeClassName="active">Message</NavLink></li>
                            <li className="network"><NavLink to={"/network"} activeClassName="active">Network</NavLink></li>
                            <li className="circle"><NavLink to="/communities" activeClassName="active">Community</NavLink></li>
                            <li className="project"><NavLink to={"/project"} activeClassName="active">Projects</NavLink></li>
                            {/* <li className="causes"><NavLink to={"/causes"} activeClassName="active">Causes</NavLink></li>
                            <li className="forum"><NavLink to="/forum" activeClassName="active">Forum</NavLink></li>
                            <li className="poll"><NavLink to="/poll" activeClassName="active">Poll</NavLink></li> */}
                        </ul>
                        {this.props.yearData && (this.props.yearData.status === "UPCOMING" ||  (this.props.yearData.status === "COMPLETED" && this.props.yearData.showWpondLogo)) && 
                        <div className="ltWPONDLogo">
                            <Link className="dropdown-item" to={`/people-of-nature-award/${this.props.yearData.status === "UPCOMING" ? "upcoming" : "event"}/${this.props.yearData.year}`}>
                                <LazyLoadImage
                                    effect="blur"
                                    src="https://cdn.dscovr.com/images/wikiPageAward-logo-new.png"
                                    onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/wikiPageAward-logo-new.png" }}
                                    alt="" />
                                <div>Awards {this.state.year} 
                                    {this.props.yearData.status === "UPCOMING" && 
                                        <span>
                                            <span className="btn">Nominate</span>
                                        </span>
                                    }
                                </div>
                            </Link>
                        </div>}
                        <div className="topDropdownMob">
                        {this.props.yearData && (this.props.yearData.status === "UPCOMING" ||  (this.props.yearData.status === "COMPLETED" && this.props.yearData.showInThreeDot)) && <Link className="dropdown-item" to={`/people-of-nature-award/${this.props.yearData.status === "UPCOMING" ? "upcoming" : "event"}/${this.props.yearData.year}`}>People of Nature Awards</Link>}
                            {this.state.loginSuccess ? 
                                <Link className="dropdown-item" to="/dashboard-settings">Dashboard and Settings</Link>
                            // <div className="dropdown">
                            //     <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Manage</a>
                            //     <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                            //         {this.state.loginSuccess ? <Link className="dropdown-item" to="/manage-invitation">Manage Invitations</Link> : null}
                            //         {this.state.loginSuccess ? <Link className="dropdown-item" to="/forum-poll-draft">Drafts</Link> : null}
                            //     </div>
                            // </div>
                             : null}
                            <div className="dropdown">
                                <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About WeNaturalists</a>
                                <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                                    <a className="dropdown-item" href="/about-us">About Us</a>
                                    <Link className="dropdown-item" to="/how-we-impacts">How We Impacts</Link>
                                    <Link className="dropdown-item" to="/how-it-works">How It Works</Link>
                                    <Link className="dropdown-item" to="/world-people-of-nature-day">People of Nature Day</Link>
                                </div>
                            </div>
                            <div className="dropdown">
                                <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Terms of Use</a>
                                <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">
                                    <Link className="dropdown-item" to="/UserAgreement">User agreement</Link>
                                    <Link className="dropdown-item" to="/PrivacyPolicy">Privacy Policy</Link>
                                    <Link className="dropdown-item" to="/TermsConditions">Terms & conditions</Link>
                                    <Link className="dropdown-item" to="/CookiePolicy">Cookie Policy</Link>
                                    <Link className="dropdown-item" to="/CommunityGuidelines">Community Guidelines</Link>
                                    <Link className="dropdown-item" to="/refund-policy">Refund Policy</Link>
                                    <Link className="dropdown-item" to="/studio-terms-conditions">Studio Terms & conditions</Link>
                                </div>
                            </div>
                            <div className="dropdown">
                                <a href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Support</a>
                                <div className="dropdown-menu fadeIn animated" aria-labelledby="navbarDropdownMenuLink">                                        
                                    <Link className="dropdown-item" to="/FaQ">FAQ & Support</Link>
                                    {this.state.loginSuccess ? <Link className="dropdown-item" to="/Feedback">Feedback</Link> : null}                                    
                                    {this.state.loginSuccess ? <Link className="dropdown-item" to="/signout">Sign Out</Link> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        yearData: state.WpondReducer?.yearData

    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        setWpondYearData: (state) => dispatch(setWpondYearData(state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanelUnauth);