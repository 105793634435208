import React, {PureComponent} from 'react';
import {getCDNUrl, redirectToProfile} from "./CommonFunctions";
import {Link, Redirect} from "react-router-dom";
import MutualConnect from "./MutualConnect";
import Follow from "./Follow";
import {authService} from "../auth";
import ProfileConnectButton from "./ProfileConnectButton";
import SendMessageModal from "./SendMessageModal";
import { userReactionIcon } from '../UserReaction/UserReactionCommonFunction';
import EntityFollow from './EntityFollow';


class LikeMemberItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
        }
    }

    triggerMessageModalClose = () => {
        this.setState({openSendMessageModal: null});
        window.$('.modal').on('hidden.bs.modal', function () {
            if (window.$(".modal:visible").length > 0) {
                setTimeout(function () {
                    window.$('body').addClass('modal-open');
                }, 0)
            }
        });
    }


    /*getConnectStatus = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + "/graph/users/" + this.state.userData.userId + "/connectionStatus/" + this.props.item.userId,
            withCredentials: true,
            headers: {"Content-Type": "application/json"},
        }).then((response) => {

            if (response && response.status === 200) {
                if (response.data && response.data.body) {
                    this.setState({connectStatusInfo: response.data.body});
                }

            }
        }).catch((e) => {
            console.log(e);
        });
    }*/

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        
        return personalInfo.originalProfileImage || personalInfo.userProfileImage;
    }

    redirectioUrlLink = (url) => {
        window.location.href = url;
    }

    render() {
        return (
            <>
                {this.props.item && this.props.item.userType != "CIRCLE" ?
                    this.state.redirectToProfile && <Redirect push to={"/profile/" + this.state.redirectToProfile}/> :
                    this.state.redirectToProfile && <Redirect push to={"/community/" + this.state.redirectToProfile}/>}
                {/* <ReactTooltip
                    className="rtoolTip"
                    place="bottom"
                    type="dark"
                    id={'sendMEssage'+this.props.item.userId}
                    textColor="#00394D"
                    backgroundColor="#ffffff">Chat</ReactTooltip> */}



                <li>{this.state.userData.userId != this.props.item.userId ?
                        <>
                            <figure className="users" onClick={(e) => {
                                e.preventDefault();
                                redirectToProfile(this.props.item.userCustomUrl, this.props.item.userId, this.props.item.userType, this.state.userData.userId)
                            }} style={{cursor:'pointer'}}>
                                <img src={(this.props.item.originalProfileImage || this.props.item.userProfileImage) ?
                                    getCDNUrl(this.getOptimizedImage(this.props.item)) : ((this.props.item.userType === "COMPANY" ? "https://cdn.dscovr.com/images/DefaultBusiness.png" : (this.props.item.userType === "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/circleDefault.webp")))}
                                    alt=""/>
                                {this.props.userReactionIcon && 
                                    <div className='reactionIcosSmall'>
                                        <i className={userReactionIcon(this.props.item.likeEvent)} />
                                    </div>
                                }
                            </figure>
                            <div>
                                <h5 className= {"name" + (this.props.item.userType !== "INDIVIDUAL" ? " orgnzName" : "")}>
                                    <span onClick={(e) => {
                                        e.preventDefault();
                                        redirectToProfile(this.props.item.userCustomUrl, this.props.item.userId, this.props.item.userType, this.state.userData.userId)
                                    }} style={{cursor:'pointer'}}>
                                    {this.props.item.userName}
                                    </span>                           

                                    {(this.props.item.connectDepth && this.props.item.userId != this.state.userData.userId) ?
                                    <MutualConnect  connectDepth = {this.props.item.connectDepth}></MutualConnect>:null}
                                </h5>
                                <span class="persona">{this.props.item.userType !== "COMPANY" ? this.props.item.persona : this.props.item.organizationType}</span>
                                <span className="mutual">{this.props.item.addressDetail && this.props.item.addressDetail.country}</span>
                                <div className='shareCountRedirection'>
                                    {this.props.item.showCount ?
                                        <span className="shareCount">+{this.props.item.showCount}</span> : null
                                    }
                                    {/* {this.props.item.redirectionUrl && <a href={this.props.item.redirectionUrl} className='persona'>Redirection URL</a>} */}
                                    {!this.props.userReactionIcon && this.props?.item?.activityId && 
                                        <Link className="viewPOst" to={`/post/${this.props?.item?.activityId}`}>
                                            <i className="iEye_OL" />
                                        </Link>
                                    }
                                </div>
                            </div>
                            {this.props.item  && !this.props.item.deactivated &&
                            <div className="buttonsWrap">                            
                                <div className="contxmenu">
                                    <button type="button" className="btn"
                                            data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false"><i
                                        className="iKebab"/></button>
                                    <div className="dropdown-menu fadeIn animated">
                                        {/*{this.props.item.connectDepth  && this.props.item.connectDepth != 1  ?
                                            <a href="javascript:void(0)" onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({openSendMessageModal: this.props.item.userId});
                                        }} className="dropdown-item"><i
                                            className="iMesseges_F"/> Send Message
                                        </a> :
                                            <Link
                                            to={"/message-user/" + this.props.item.userId}
                                            className="dropdown-item"><i
                                            className="iMesseges_F"/> Chat</Link>
                                        }*/}
                                        {this.props.item.userType != "CIRCLE" && 
                                            <>
                                                <a href="javascript:void(0)" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({openSendMessageModal: this.props.item.userId});
                                                }} className="dropdown-item"><i
                                                    className="iMesseges_F"/> Send Message
                                                </a>

                                                { this.props.item && this.props.item.userConnectStatus &&  this.props.item.userType !== "COMPANY" && (this.props.item.connectDepth && this.props.item.connectDepth != 1 ) &&
                                                    <ProfileConnectButton userId={this.props.item.userId}
                                                                        popup = {true}
                                                                        customUrl={this.props.item.customUrl}
                                                                        userName={this.props.item.userName}
                                                                        connectStatusInfo={this.props.item.userConnectStatus}
                                                    />
                                                }
                                            </>
                                        }
                                        {(this.props.pageType !== "ANALYTICS_VIEW"  &&  this.props?.pageType !== "PROFILE_SIMILAR_USERLIST") &&  
                                            (this.props.item.userType != "CIRCLE" ? 
                                                <Follow followed={this.props.item.followed}
                                                    popup = {true}
                                                    userId={this.props.item.userId}
                                                /> : 
                                                <EntityFollow entityType={'CIRCLE'} 
                                                    entityId={this.props.item.userId}
                                                    followed={this.props.item.followed}
                                                    userId={this.state.userData.userId}
                                                />
                                            )
                                        }
                                    </div>
                                </div>                   
                            </div>}  
                            {!this.props.item.deactivated &&
                                this.state.openSendMessageModal && this.state.openSendMessageModal === this.props.item.userId &&
                                <SendMessageModal profileId={this.props.item.userId}
                                                userName={this.props.item.userName}
                                                triggerMessageModalClose={this.triggerMessageModalClose}/>
                            }
                        </>  
                    : 
                        <>
                            <figure className="users" onClick={(e) => {
                                e.preventDefault();
                                redirectToProfile(this.props.item.userCustomUrl, this.props.item.userId, this.props.item.userType, this.state.userData.userId)
                            }} style={{cursor:'pointer'}}>
                                <img src={(this.props.item.originalProfileImage || this.props.item.userProfileImage) ?
                                    getCDNUrl(this.getOptimizedImage(this.props.item)) : this.props.item.userType == "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.png" : this.props.item.userType === "CIRCLE" ? "https://cdn.dscovr.com/images/circleDefault.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"}
                                    alt=""/>
                                {this.props.userReactionIcon && 
                                    <div className='reactionIcosSmall'>
                                        <i className={userReactionIcon(this.props.item.likeEvent)} />
                                    </div>
                                }
                            </figure>
                            
                            <div>
                                <h5>
                                    <div className="name" onClick={(e) => {
                                        e.preventDefault();
                                        redirectToProfile(this.props.item.userCustomUrl, this.props.item.userId, this.props.item.userType, this.state.userData.userId)}}>You</div>
                                </h5>
                                <div className='shareCountRedirection'>
                                    {this.props.item.showCount ?
                                        <span className="shareCount">{this.props.item.showCount && '+'}{this.props.item.showCount}</span> : null
                                    }
                                    {/* {this.props.item.redirectionUrl && <a href={this.props.item.redirectionUrl} className='persona'>Redirection URL</a>} */}
                                    {!this.props.userReactionIcon && this.props?.item?.activityId && 
                                        <Link className="viewPOst" to={`/post/${this.props?.item?.activityId}`}>
                                            <i className="iEye_OL" />
                                        </Link>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </li>
            </>
        );
    }
}

export default LikeMemberItem;
