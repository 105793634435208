import React, { Component } from 'react'
import RecommendedCause from "./cause/RecommendedCause";
import RecommendedProject from "./project/component/recommendation/RecommendedProject";
import BadgeLeaderBoard from './Badge/BadgeLeaderBoard';

class RightPanel extends Component {

    render() {
        return <div className="col-md-4">
            <div className="rightpanel">
                <div className="rightpanelinn">
                    {/* <RecommendedCause/> */}
                    <RecommendedProject/>
                </div>
                {this.props.pageType === "FEEDS" && <BadgeLeaderBoard showPublic={this.props?.showPublic} />}
            </div>

        </div>
    }
}

export default RightPanel;