import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { authService } from "../auth";
import axios from "axios";
import * as _ from "lodash";
import { userReactionEventCount, userReactionIcon, userReactionTypeList } from "./UserReactionCommonFunction";
import LikeMemberItem from "../commons/LikeMemberItem";
import InfiniteScroll from "react-infinite-scroller";
import HorizontalScroll from "react-scroll-horizontal";

class UserReactionListPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            activeTab: "ALL",
            userEventCountData: {},
            reactionUserList: {},
            reactionDataSuccess: false,
            pageSize: 12
        }
    }

    getActiveTabData = (value) => {
        this.setState({
            activeTab: value,
            reactionUserList: {}
        }, () => {
            this.getUserReactionUserList(0, this.state.pageSize, false);
        })
    }

    getUserEventTypeContent = (userEventType) => {
        return (!_.isEmpty(this.state.userEventCountData) && ((userEventType.value === "ALL" && this.state.userEventCountData?.all > 0) || (userEventType.value === "LIKE" && this.state.userEventCountData?.like > 0) || (userEventType.value === "CELEBRATE" && this.state.userEventCountData?.celebrate > 0) || (userEventType.value === "LOVE" && this.state.userEventCountData?.love > 0) || (userEventType.value === "SUPPORT" && this.state.userEventCountData?.support > 0) || (userEventType.value === "INSIGHTFUL" && this.state.userEventCountData?.insightful > 0) || (userEventType.value === "SAD" && this.state.userEventCountData?.sad > 0)) &&
            <li className="nav-item">
                <a href="javascript:void(0)" className={"nav-link reactionIcosSmall" + (this.state.activeTab === userEventType.value ? " active" : "")} onClick={(e) => this.getActiveTabData(userEventType.value)}>
                    <>{userEventType.value !== "ALL" ? <i className={userReactionIcon(userEventType.value)} /> : userEventType.label}</> {userReactionEventCount(userEventType.value, this.state.userEventCountData)}
                </a>
            </li>
        )
    }

    getUserEventCountData = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/like/likeEventCount/${this.props.activityId}`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    userEventCountData: response.data.body
                }, () => {
                    if (!_.isEmpty(this.state.userEventCountData)) {
                        this.getActiveTabData(this.state.userEventCountData?.all > 0 ? "ALL" : this.state.userEventCountData?.celebrate > 0 ? "CELEBRATE" : this.state.userEventCountData?.love > 0 ? "LOVE" : this.state.userEventCountData?.support > 0 ? "SUPPORT" : this.state.userEventCountData?.insightful > 0 ? "INSIGHTFUL" : "SAD");
                    }
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getUserReactionUserList = (page, size, concatWithPrevious) => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/like/getUsers/${this.props.activityId}?userId=${this.state.userData.userId}&type=${this.state.activeTab}&page=${page}&size=${size}`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let pageData = response.data.body;
                if (concatWithPrevious && this.state.reactionUserList && this.state.reactionUserList.content) {
                    pageData.content = this.state.reactionUserList.content.concat(pageData.content);
                }
                this.setState({
                    reactionUserList: pageData,
                    reactionDataSuccess: false
                })
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                reactionDataSuccess: false
            })
        });
    }

    fetchMoreReactionUserList = () => {
        if (this.state.reactionUserList && this.state.reactionUserList.content && !this.state.reactionUserList.page?.last) {
            this.setState({
                reactionDataSuccess: true
            })
            let pageable = this.state.reactionUserList?.page?.pageable;
            this.getUserReactionUserList(pageable.pageNumber + 1, this.state.pageSize, true);
        }
    };

    componentDidMount() {
        this.getUserEventCountData();
    }

    render() {
        const { reactionUserList, reactionDataSuccess } = this.state;
        const parentnav = { height: '45px' }
        return (
            <div id={"userReactionListPopUp"} className="modal fade dgpopup reqendorse userReactionListPopUp" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div
                                className="similarAllpopWrap forwcontwrap"
                                style={{ maxWidth: "510px" }}
                            >
                                <div>
                                    <div className="similarAllpopHeader">
                                        <h2>Reactions</h2>
                                    </div>
                                    <div className="projtopbar reactiontopbar">
                                        <div className="projtopbarleft">
                                            <HorizontalScroll style={parentnav} reverseScroll className="mcustHorizMenu">
                                                <div className="mcustHorizinn">
                                                    <ul id="tabs" className="nav">
                                                        {userReactionTypeList && userReactionTypeList.map((item) => {
                                                            return (
                                                                this.getUserEventTypeContent(item)
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </HorizontalScroll>
                                        </div>
                                    </div>
                                    <div className={"similarAllpopBody"}>                                        
                                        <Scrollbars
                                            onScroll={!reactionDataSuccess && !reactionUserList?.page?.last && this.fetchMoreReactionUserList}
                                            className={"forwcontlistmain"}
                                            autoHide
                                            autoHeight
                                            autoHeightMin={0}
                                            autoHeightMax={322}
                                            universal={true}
                                            renderTrackHorizontal={(props) => (
                                                <div {...props} className="track-horizontal" />
                                            )}
                                            renderTrackVertical={(props) => (
                                                <div {...props} className="track-vertical" />
                                            )}
                                        >
                                            {reactionUserList &&
                                                reactionUserList.content &&
                                                reactionUserList.content.length > 0 && (
                                                    <InfiniteScroll
                                                        dataLength={reactionUserList.content.length}
                                                        loadMore={this.fetchMoreReactionUserList.bind(this)}
                                                        hasMore={!reactionDataSuccess && !reactionUserList?.page?.last}
                                                        height={322}
                                                    >
                                                        {reactionUserList.content.map((data, index) => {
                                                            return (
                                                                <LikeMemberItem item={data} key={data.userId} userReactionIcon={true} />
                                                            );
                                                        })}
                                                    </InfiniteScroll>
                                                )}

                                        </Scrollbars>
                                    </div>
                                </div>
                                <div className="forwcontwrapbott">
                                    <div>
                                        <button className="butt btnsecondary close" onClick={(e) => { this.props.closeUserReactionPopUp() }}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserReactionListPopUp;