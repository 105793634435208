import React, { useEffect, useState, useRef } from "react";
import { authService } from "../auth";
import axios from "axios";
import LikeMemberItem from "./LikeMemberItem";
import { Scrollbars } from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";

function Like(props) {
  const [userData, setUserData] = useState(
    authService.getUserDetailsFromLocalStorage()
  );
  const [count, setCount] = useState(10);
  const [liked, setLiked] = useState(props.item.liked);
  const [hasMore, setHasMore] = useState(true);
  const [numberCount, setNumberCount] = useState(0);
  const [likesCount, setLikesCount] = useState(props.item.likesCount);
  const [likeSuccess, setLikeSuccess] = useState(true);
  const [likeUserData, setLikeUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prefixId, setPrefixId] = useState(
    props.specificKey ? props.specificKey : ""
  );

  //in style css
  const style = {
    similarAllpopWrap: { maxWidth: "424px" },
  };

  //onClick like icon
  const handleLike = () => {
    let newState = !liked;
    setLiked(newState);
    setLikesCount(newState ? likesCount + 1 : Math.max(0, likesCount - 1));
    setLikeSuccess(false);
    const data = {
      userId: userData.userId,
      activityId: props.item.id,
      liked: newState,
      likeEvent: "LIKE"
    };
    axios({
      method: "post",
      url: process.env.REACT_APP_userServiceURL + "/backend/like/create/",
      data: data,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.message === "Success!") {
          setLikeSuccess(true);
          setLikesCount(
            newState ? likesCount + 1 : Math.max(0, likesCount - 1)
          );
        } else {
          setLiked(!newState);
        }
      })
      .catch((err) => {
        setLiked(!newState);
        setLikeSuccess(true);
        console.log(err);
      });
  };

  //like count format into k,m
  const nFormatter = (num, digits) => {
    if (!num) {
      return num;
    }
    let si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  };

  // click on like members
  const likeMemberClick = () => {
    fetchUsers(0, 2, false);
    window.$("#likeuser" + props.item.id + prefixId).modal("show");
  };

  // close like member modal
  const closeModal = () =>{
    setLikeUserData([])
    window.$("#likeuser" + props.item.id + prefixId).modal("hide");
  }
  useEffect(()=>{
    return ()=> {
      window.$("#likeuser" + props.item.id + prefixId).modal("hide");
    }
  },[])
  //   fetch user
  const fetchUsers = (page, size, concatWithPrevious) => {
    axios({
      url: `${process.env.REACT_APP_userServiceURL}/like/getUsers/${props.item.id}?userId=${userData.userId}&page=${page}&size=${count}`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.status === "200 OK"
        ) {
          let pageData = response.data.body.page;
          if (concatWithPrevious) {
            pageData.content = likeUserData.content.concat(pageData.content);
          }
          setNumberCount(pageData.totalElements);
          setLikeUserData(pageData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  //   fetch more user
  const fetchMoreUser = () => {
    if (
      likeUserData &&
      likeUserData.content &&
      likeUserData.content.length === numberCount
    ) {
      setHasMore(false);
    } else {
      if(likeUserData && likeUserData.pageable){
        let likedUserPagedData = likeUserData;
        let pageable = likedUserPagedData.pageable;
        fetchUsers(pageable.pageNumber + 1, count, true);
      }
    }
  };

  return (
    <div className="likesblk">
      <a
        href="javascript:void(0)"
        onClick={(e) => handleLike()}
        className={
          likeSuccess === false
            ? "like disabled" + (liked ? " active" : "")
            : "like" + (liked ? " active" : "")
        }
      />

      <a
        href="javascript:void(0)"
        onClick={(e) => likeMemberClick()}
        data-toggle="modal"
      >
        {nFormatter(likesCount, 1)}&nbsp;
        <span className="art_btn_txt">
          {likesCount <= 1 ? "Like" : "Likes"}
        </span>
      </a>

      {/*like modal start*/}
      <div
        id={"likeuser" + props.item.id + prefixId}
        className="modal fade dgpopup reqendorse"
        tabIndex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className="similarAllpopWrap forwcontwrap"
                style={{ maxWidth: "424px" }}
              >
                <div>
                  <div className="similarAllpopHeader">
                    <h2>Likes</h2>
                  </div>
                  <div className={"similarAllpopBody"}>
                    <Scrollbars
                      onScroll={fetchMoreUser}
                      className={"forwcontlistmain"}
                      autoHide
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={322}
                      universal={true}
                      renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                      )}
                      renderTrackVertical={(props) => (
                        <div {...props} className="track-vertical" />
                      )}
                    >
                      {" "}
                      {likeUserData &&
                        likeUserData.content &&
                        likeUserData.content.length > 0 && (
                          <InfiniteScroll
                            dataLength={likeUserData.content.length}
                            next={fetchMoreUser}
                            hasMore={hasMore}
                            loader={
                              <div className="loader" key={0}>
                                Loading ...
                              </div>
                            }
                            height={322}
                          >
                            {likeUserData.content.map((data, index) => {
                              return (
                                <LikeMemberItem item={data} key={data.userId} />
                              );
                            })}
                          </InfiniteScroll>
                        )}
                    </Scrollbars>
                  </div>
                </div>
                <div className="forwcontwrapbott">
                  <div>
                    <button
                      className="butt btnsecondary close"
                      onClick={(e)=>{closeModal()}}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*like modal end*/}
    </div>
  );
}

export default Like;
