import React, { memo, useEffect, useState } from "react";
import { authService } from "../../auth";
import Header from "../../Header";
import LeftPanel from "../../LeftPanel";
import ExploreTopBar from '../ExploreTopBar';
import axios from "axios";
import IndividualHashTagsCategory from './IndividualHashTagsCategory'
import InfiniteScroll from "react-infinite-scroller";
import { userAgentGetData } from "../../commons/CommonFunctions";
import NotFound from "../../404";
import CommonHashTagsDocuments from "./Common/CommonHashTagsDocuments";
import Breadcumbs from "../../Breadcumbs";

const AllIndividualHashTagsDocuments = (props) => {

    const [userData, setUserData] = useState(authService.getUserDetailsFromLocalStorage());
    const [tagName, setTagName] = useState('');
    const [hashTagsDocuments, setHashTagsDocuments] = useState([]);
    const [hashTagsDocumentsPageNo, setHashTagsDocumentsPageNo] = useState(0)
    const [documentSuccess, setDocumentSuccess] = useState(false)
    const [thresholdHeight, setThresholdHeight] = useState(1000);
    const [customUrl, setCustomUrl] = useState('');
    const [userDetails, setUserDetails] = useState('');
    const [pageNotExist, setPageNotExist] = useState(false);

    useEffect(() => {
        tagNameValue();
        customUrlValue();
        getHashtagsDetails()
        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
    }, []);

   
    const tagNameValue = () => {
        setTagName(props.match.params.slug);
    }

    const customUrlValue = () => {
        setCustomUrl(props.match.params.customurl);
    }

    const getUserDetailsByCustomUrl = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/profile/get_by_custom_url?customUrl=' + props.match.params.customurl+'&otherUserId='+userData.userId+userAgentGetData(),
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                setUserDetails(response.data.body);
                hashTagsDocument(response.data.body.userId, 0, 24, false);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    let cancelToken;
    const hashTagsDocument = (userid, page, limit, concatWithPrevious) => {
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request")
        }
        cancelToken = axios.CancelToken.source()

        setDocumentSuccess(false)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_userServiceURL}/tags/getUserNewsFeed/hashTag/viewAll?userId=${userid}&otherUserId=${userData.userId}&hashTag=${props.match.params.slug}&category=POST&newsFeedType=DOCUMENT&page=${page}&size=${limit}`,
            withCredentials: true,
            cancelToken: cancelToken.token
        }).then((response) => {
            if (response && response.data && response.data.status === '200 OK') {
                let pageData = response.data.body
                if (concatWithPrevious && hashTagsDocuments && hashTagsDocuments.content) {
                    pageData.content = hashTagsDocuments.content.concat(pageData.content)
                }
                setHashTagsDocuments(pageData)
                setDocumentSuccess(false)
            }
        }).catch((error) => {
            console.log(error)
            setDocumentSuccess(false)
        })
    }

    const loadmoreDocuments = () => {
        if (hashTagsDocuments && hashTagsDocuments.content) {
            setDocumentSuccess(true)
            let pageable = hashTagsDocuments.page.pageable;
            setHashTagsDocumentsPageNo(pageable.pageNumber + 1)
            hashTagsDocument(userDetails.userId, pageable.pageNumber + 1, 24, true)
        }
    }

    const getHashtagsDetails = () => {
        let postData= {
            hashtag: props.match.params.slug
        }
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/recommend/statushashtag/`,
            headers: { 'Content-Type': 'application/json' },
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.status === 200) {
              getUserDetailsByCustomUrl();
            }
            if (response && response.data && response.data.status === 410) {
                setPageNotExist(true);
            }
        }).catch((err) => {
            console.log(err);
            setPageNotExist(err.response && err.response.status === 410 ? true : false)
        });
    }

    return (
        <div className="wrapper projectpage explorepage">
            <Header />
            <main className="dgcontainer hm3col">
                <div className="container">
                    {pageNotExist ? 
                        <NotFound /> :
                        <div className="row">
                            <LeftPanel />
                            <div className="col-md-9">
                                <div className={"projectinn explore trending exploreExtra" + (userDetails.type === "COMPANY" ? ' orgnzHash' : '')}>
                                    {props.match.params.slug && userDetails?.customUrl && <Breadcumbs crumbs={[{ "name": `${userDetails?.userName}`, "path": `/profile/${userDetails?.customUrl}` }, { "name": `Hashtag`, "path": `/profile-hashtag/${userDetails?.customUrl}` }, { "name": `${props.match.params.slug}`, "path": `/profile-hashtag/${props.match.params.slug}/${userDetails?.customUrl}` }, { "name": `Documents`, "path": null } ]} />}
                                    <ExploreTopBar tagName={"Documents"} isIndividualHashtag={true} key={userDetails} userDetails={userDetails} />
                                    <IndividualHashTagsCategory tagName={tagName} tagType="documents" customUrl={customUrl} profileInfo={userDetails} hashTagType="PROFILE"/>

                                    <div className="dailyeditionblk explLatarticle trndBlgs alltrndBlgs expladventwrap trnd others" style={{paddingTop: '0px'}}>
                                        {hashTagsDocuments && hashTagsDocuments.page && (
                                            <InfiniteScroll
                                                loadMore={loadmoreDocuments.bind(this)}
                                                hasMore={!documentSuccess && !hashTagsDocuments.page.last}
                                                threshold={thresholdHeight}
                                            >
                                                <div className="row all">
                                                    {hashTagsDocuments &&
                                                        hashTagsDocuments.content &&
                                                        hashTagsDocuments.content.map((item, index) => {
                                                            return (
                                                                <div className="col-md-4">
                                                                   <CommonHashTagsDocuments item={item} key={item.id} />
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </InfiniteScroll>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </div>
            </main>
        </div>
    )
}

export default memo(AllIndividualHashTagsDocuments);