import React, {Component} from "react";
import "../../profile/container/profile/css/main-profile.min.css";
import "../../project/container/Project/css/main-projects.min.css";
import "react-toastify/dist/ReactToastify.css";
import {Redirect} from "react-router-dom";
import {authService} from "../../auth";
import Header from "../../Header";
import {Link} from "react-router-dom";
import axios from "axios";
import { userAgentGetData } from "../../commons/CommonFunctions";
import LeftPanel from "../../LeftPanel";

class DeactivatedProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData:authService.getUserDetailsFromLocalStorage(),
            data:{}
        };
    }

    componentDidMount() {
        if(this.props.location && this.props.location.state && this.props.location.state.customUrl){
            this.getUserDataByCustomUrl(this.props.location.state.customUrl)
        }

    }

    getUserDataByCustomUrl(customUrl) {
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL +
                "/profile/get_by_custom_url?customUrl=" +
                customUrl+'&otherUserId='+this.state.userData.userId+userAgentGetData(),
            headers: {"Content-Type": "application/json"},
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.setState({data: response.data.body});
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    this.setState({
                        notFoundStatusCode: err.response.status,
                    });
                }
            });
    }


    render() {
        if (!this.state.loginSuccess) {
            return <Redirect to='/login'/>
        } else {
            return <div className="staticPage wrapper projectpage profilepage pabout ppersonal">
                <Header />
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="bk_dactiveprflbx">
                                    <img src="https://cdn.dscovr.com/images/Deactivated.webp" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/Deactivated.png"}} alt=""/>
                                    {this.state.data.deactivatedByAdmin ?
                                        <p>This account is deactivated as per <br/><Link to="/CommunityGuidelines">Community Guidelines</Link>!</p>:
                                        <p>This account has been deactivated by the member!</p>
                                    }


                                    <Link to={"/network"} className="btn connect_btn">Explore More <i
                                        className="iArrow_Right"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <LeftPanel />
            </div>
        }
    }

}

export default DeactivatedProfile

