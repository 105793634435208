import React, { Component } from "react";
import axios from "axios";
import { authService } from "../../../auth";
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CommonHashTagsBlog from "../../../explore/HashTags/Common/CommonHashTagsBlog";
import CommonHashTagsVideo from "../../../explore/HashTags/Common/CommonHashTagsVideo";
import CommonHashTagsPhoto from "../../../explore/HashTags/Common/CommonHashTagsPhoto";
import CommonHashTagsOthers from "../../../explore/HashTags/Common/CommonHashTagsOther";

class FeaturePostSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            featurePostList: []
        }
    }

    getFeaturePostList = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_userServiceURL}/featured/post/list?userId=${this.props.userId}&requestingUserId=${this.state.userData.userId}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                this.setState({
                    featurePostList: response.data.body
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    onSuccessFeaturePost = (id) => {
        let featureItemList = this.state.featurePostList;
        const index = featureItemList && featureItemList.findIndex(data => data.id === id);
        if (index > -1) {
            featureItemList.splice(index, 1);
        }
        this.setState({
            featurePostList: featureItemList
        });
        this.forceUpdate();
    }

    componentDidMount() {
        this.getFeaturePostList();
    }

    render() {
        const { featurePostList } = this.state;
        return (
            <>
                {featurePostList && featurePostList.length > 0 &&
                    <div id="featurePostBlogs" className="expHashSection expHashBlogs expHashPhotos expHashVideos explorepage featurePostWrap">
                        <div className="titlewrp">
                            <h2>Featured</h2>
                        </div>
                        <div className="explLatarticle">
                            <OwlCarousel
                                id={"projSummaryCarousel"}
                                className={"projSummaryCarousel"}
                                autoWidth={true}
                                dots={false}
                                margin={12}
                                items={3}
                                loop={false}
                                rewind={true}
                                nav={featurePostList && featurePostList.length > 3 ? true : false}
                                navText= {["<i class='iArrow_Left'></i>", "<i class='iArrow_Right'></i>"]}>
                                {featurePostList && featurePostList.map((item, index) => {
                                    return <div className="item bookmarkWrap">
                                        {item.postType === 'ARTICLE' ?
                                            <CommonHashTagsBlog item={item} key={item.id} pageType={"FEATURE_POST"} onSuccessFeaturePost={this.onSuccessFeaturePost} /> :
                                                item.attachmentIds && item.attachmentIds.length > 0
                                                ? item.attachmentIds[0].attachmentType === 'VIDEO' ?
                                                    <CommonHashTagsVideo item={item} key={item.id} pageType={"FEATURE_POST"} onSuccessFeaturePost={this.onSuccessFeaturePost} /> :
                                                    item.attachmentType === "IMAGE" ?
                                                        <CommonHashTagsPhoto item={item} key={item.id} idx={index} pageType={"FEATURE_POST"} onSuccessFeaturePost={this.onSuccessFeaturePost} /> :
                                                        <CommonHashTagsOthers item={item} key={item.id} pageType={"FEATURE_POST"} onSuccessFeaturePost={this.onSuccessFeaturePost} />
                                                    :  (item && item.attachmentIds.length === 0 && item.paidPost && (item.attachmentType === "IMAGE" || item.attachmentType === "VIDEO"))
                                                        ? item.attachmentType === "IMAGE" ?
                                                            <CommonHashTagsPhoto item={item} key={item.id} idx={index} pageType={"FEATURE_POST"} onSuccessFeaturePost={this.onSuccessFeaturePost} /> : 
                                                            item.attachmentType === "VIDEO" ? 
                                                            <CommonHashTagsVideo item={item} key={item.id} pageType={"FEATURE_POST"} onSuccessFeaturePost={this.onSuccessFeaturePost} /> : 
                                                            <></>
                                                : <CommonHashTagsOthers item={item} key={item.id} pageType={"FEATURE_POST"} onSuccessFeaturePost={this.onSuccessFeaturePost} />
                                        }
                                    </div>
                                })}
                            </OwlCarousel>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default FeaturePostSection;