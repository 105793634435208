import React, { Component } from "react";
import { authService } from "../auth";
import Pinned from "../commons/Pinned";
import ReportAbuse from "../commons/ReportAbuse";
import Follow from "../commons/Follow";
import { getCDNUrl } from "../commons/CommonFunctions";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import MutualConnect from "../commons/MutualConnect";
import Share from "../commons/Share";
import { NavLink } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import UserReactionComponent from "../UserReaction/UserReactionComponent";
import SavePostButton from "../post/SavePostButton";
import CleverTapUtils from "../commons/CleverTapUtils";
const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

function matchHashTags(text) {
  if (!text || text.length === 0) {
    return text;
  }
  var string = text;
  var regex = /(#[a-z\d-_]+)/gi;
  var matches = string.matchAll(regex);
  for (var match of matches) {
    if (match && match.length > 1) {
      string = string.replace(
        match[0],
        '<a class="hashLinkPost" href="/explore-hashtag/' +
          match[0].substr(1) +
          '">' +
          match[0].substr(1) +
          "</a>"
      );
    }
  }
  return string;
}

function transform(node, index) {
  if (node.type === "text") {
    let modifiedString = matchHashTags(node.data);
    if (modifiedString !== node.data) {
      node.type = "tag";
      node.name = "div";
      return <>{ReactHtmlParser(modifiedString)}</>;
    }
  }
}

const options = {
  decodeEntities: true,
  transform,
};

class ForumItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: authService.getUserDetailsFromLocalStorage(),
      like: true,
      prefixId: this.props.specificKey ? this.props.specificKey : "",
      userId: this.props.userId ? this.props.userId : authService.getUserDetailsFromLocalStorage().userId
    };
  }

  renderTaggedUsers = (text) => {
    if (!text || text.length === 0) {
      return text;
    }
    text = text.split("@@@__").join('<a href="/profile/');
    text = text.split("^^__").join('">');
    text = text.split("&&__").join('">');
    text = text.split("&amp;&amp;__").join('">');
    text = text.split("&amp;&amp;__").join('">');
    text = text.split("@@@^^^").join("</a>");
    text = text
      .split("###__")
      .join(
        '<a class="hashLinkPost" style="cursor:pointer" href="/explore-hashtag/'
      );
    text = text
      .split("&&__")
      .join(
        '<a class="hashLinkPost" style="cursor:pointer" href="/explore-hashtag/'
      );
    text = text.split("###^^^").join("</a>");
    text = text.replace(/#\s/g, "");
    return text;
  };

  redirectToProfile = (customUrl) => {
    this.setState({ redirectToProfile: customUrl });
    CleverTapUtils.eventClick({}, "View Author Profile") ;
  };

  triggerReportClose = () => {
    this.setState({ openReportModal: null });
  };

  componentWillUnmount() {
    window
      .$("#confirmpopForDeleteForum" + this.props.item.id + this.state.prefixId)
      .modal("hide");
  }

  handleDetailPage(evt, news_url) {
    evt.preventDefault();

    let userAgent = navigator.userAgent;
    let mobileandroid = /wenat_android/.test(userAgent);
    let mobileios = /wenat_ios/.test(userAgent);

    if (news_url !== undefined && news_url !== null) {
      if (mobileios || mobileandroid) {
        window.location.href = news_url;
      } else {
        window.open(
          news_url,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000"
        );
      }
    }
  }

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.profileImage;
  }

  openDeleteModal = (id) => {
    window
      .$("#confirmpopForDeleteForum" + this.props.item.id + this.state.prefixId)
      .modal("show");
  };

  deleteForum = (id) => {
    axios({
      method: "get",
      url:
        process.env.REACT_APP_userServiceURL +
        "/backend/public/forum/delete?forumId=" +
        id,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        let res = response.data;
        if (res.status === "202 ACCEPTED") {
          CleverTapUtils.eventClick({}, "Delete Forum");
          this.props.refreshData(id);
        }
      })
      .catch((err) => {
        this.setState({
          isLoaded: true,
          error: { message: err.response.data.message, err: err.response },
        });
      });
  };

  handleHideModal = (id) => {
    let data = {
      userId: this.state.userId,
      activityId: id,
      entityType: "FORUM",
    };
    axios({
      method: "post",
      url: process.env.REACT_APP_userServiceURL + "/hidden/hide",
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.status === 201) {
          this.props.refreshData(id);
          CleverTapUtils.eventClick({}, "Hide Forum") ;

        } else {
          this.setState({ isSubmitted: false });
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 409) {
          this.setState({
            error: "Entity has already been hidden",
            isSubmitted: false,
          });
        } else {
          this.setState({ isSubmitted: false });
        }
      });
  };

  trimDescription = (item) => {
    var trimmed = item.split("^^__").join(" ").indexOf("@@@__");
    var str = item
      .split("^^__")
      .join(" ")
      .indexOf(" ", item.split("^^__").join(" ").indexOf("@@@__"));
    var sub = item.substring(trimmed, str);
    item = item.replace(" " + sub, "");
    item = item.replace("@@@^^^", " ");
    item = item.replace("@@@__", " ");
    item = item.replace("  ", "");
    item = item.replace(/&nbsp;/g, " ");
    item = item.replace(/<br\s*[\/]?>/gi, "\n");
    const regex = /(<([^>]+)>)/gi;
    item = item.replace(regex, "");
    return item.split("^^__").join(" ");
  };

  handleUnHideModal = (id) => {
    let data = {
      userId: this.state.userId,
      activityId: id,
    };

    axios({
      method: "post",
      url: process.env.REACT_APP_userServiceURL + "/hidden/unhide",
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.status === 202) {
          this.props.refreshData();
        } else {
          this.setState({ isSubmitted: false });
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 409) {
          this.setState({
            error: "Entity has already been unhidden",
            isSubmitted: false,
          });
        } else {
          this.setState({ isSubmitted: false });
        }
      });
  };
  refreshAllData = () => {
    this.props.refreshData();
  };
  render() {
    let linkfiedHtml =
      '<p class="pdtlsLink forum-pdtlsLink">' +
      this.renderTaggedUsers(
        this.props.item.comment ? this.props.item.comment : ""
      )
        .replace(/<img[^>]*>/g, "")
        .replace(/<h2[^>]*>/g, "")
        .replace(/<blockquote[^>]*>/g, "")
        .replace(/&nbsp;/g, " ")
        .replace(/(\r\n|\r|\n)/g, "<br>")
        .replace(/(\\n)/g, "<br>") +
      "</p>";
    let maxLine = "5";
    return (
      <>
        {this.state.redirectToProfile && (
          <Redirect push to={"/profile/" + this.state.redirectToProfile} />
        )}
        <div className="forumrow">
          {!this.props.pageType && 
            <Pinned
              item={this.props.item}
              entityType={"FORUM"}
              callbackRequired={true}
            />
          }
          {this.props.pageType === "BOOKMARK" && 
            <SavePostButton key={this.props.item.id} id={this.props.item.id} bookMarkFlag={true} entityType={"FORUM"} onSuccessBookmarkPost={this.props.onSuccessUnsaveBookmarkPost} pageType={this.props.pageType} />
          }
          <h4>
            <Link to={{pathname: "/forumDetail/" + this.props.item.slug, state: {userId: this.state.userId}}} onClick={() => this.props.pageType ? CleverTapUtils.eventClick({}, "Display Forum") : "javascript:void(0)" }>
              {this.props.item.title}
            </Link>
          </h4>

          <ResponsiveEllipsis
              unsafeHTML={linkfiedHtml}
              maxLine="3"
              ellipsis='...'
              trimRight
              basedOn='words'
          />

          <div className="forumrowbott">
            <div className={"fuserfollow" + (this.props.item.userType === "COMPANY" ? " orgnz" : "")}>
              <figure
                className="userlogo"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.redirectToProfile(this.props.item.customUrl);
                }}
              >
                <img
                  src={
                    this.props.item.profileImage != null
                      ? getCDNUrl(this.getOptimizedImage(this.props.item))
                      : this.props.item.userType !== "COMPANY"
                      ? "https://cdn.dscovr.com/images/users1.png"
                      : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                  }
                  alt=""
                />
              </figure>
              <a
                href="javascript:void(0)"
                onClick={(e) => {
                  e.preventDefault();
                  this.redirectToProfile(this.props.item.customUrl);
                }}
              >
                {this.props.item.userName}
              </a>
              {this.props.item.connectDepth &&
              this.props.item.userId != this.state.userId ? (
                <MutualConnect
                  connectDepth={this.props.item.connectDepth}
                ></MutualConnect>
              ) : null}
              {(this.props.item.connectDepth !== 1 && this.props.item.connectDepth !== 2) ? (
                  this.props.item && ( !this.props.item.connectDepth  ||( this.props.item.connectDepth  &&this.props.item.connectDepth < 1 )) &&
                  <Follow followed={this.props.item.followed} userId={this.props.item.userId}
                          deactivated={this.props.deactivated}/>) : null}
            </div>
            <div className="forumrowbright">
              {new Date(this.props.item.createTime)
                .toLocaleDateString("en-GB", {
                  year: "numeric",
                })
                .replace(/ /g, "-") === new Date().getFullYear().toString() ? (
                <span className="pstdate">
                  Published on{" "}
                  {new Date(this.props.item.createTime)
                    .toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                    })
                    .replace(/ /g, "-")}
                </span>
              ) : (
                <span className="pstdate">
                  Published on{" "}
                  {new Date(this.props.item.createTime)
                    .toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })
                    .replace(/ /g, "-")}
                </span>
              )}
              {this.props.item && this.props.item.likesCount != null && (
                <UserReactionComponent item={this.props.item} key={this.props.item.id} type={'FORUM'} />
              )}
              <Link
                to={{pathname: "/forumDetail/" + this.props.item.slug, state: {userId: this.state.userId}}}
                className="comments"
              >
                <span>
                  {this.props.item.commentCount}&nbsp;
                  <span>
                    {this.props.item.commentCount <= 1 ? "Comment" : "Comments"}
                  </span>
                </span>
              </Link>

              <div>
                {/*<a href="javascript:void(0)" className="share"
                                   onClick={(e) => this.handleDetailPage(e, process.env.REACT_APP_domainUrl + '/forumDetail/' + this.props.item.slug + '/#share-open')}>
                                    {this.props.item.sharesCount  &&
                                    <span>{this.props.item.sharesCount}{"  "}Share</span>
                                    }
                                </a>*/}
                <Share
                  shareUrl="/forumDetail/"
                  entityId={this.props.item.id}
                  slug={this.props.item.slug}
                  entityType={"FORUM"}
                  shareId={this.props.item.id}
                  sharesCount={this.props.item.sharesCount}
                />
              </div>
            </div>
          </div>
          <div
            className="modal fade dgpopup confirmpop"
            id={
              "confirmpopForDeleteForum" +
              this.props.item.id +
              this.state.prefixId
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                {/* <div className="modal-header">
                                 <h5 className="modal-title" id="exampleModalLabel">Delete Confirmation</h5>                                 
                             </div> */}
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="confirm-popup-modal-body">
                  Are you sure you want to delete this forum?
                </div>
                <div className="modal-footer confirm-popup-modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.deleteForum(this.props.item.id)}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="contxmenu">
            {!this.props.pageType && 
              <button
                type="button"
                className="btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="iKebab" />
              </button>
            }
            <div className="dropdown-menu fadeIn animated">
              {this.props.item.userId === this.state.userId && (
                <span
                  className="dropdown-item"
                  onClick={(e) => {
                    this.openDeleteModal(this.props.item.id);
                  }}
                >
                  <i className="iTrashBin" /> Delete
                </span>
              )}
              {this.props.hideShow && !this.props.pin && (
                <span
                  className="dropdown-item"
                  onClick={(e) => {
                    this.handleHideModal(this.props.item.id);
                  }}
                >
                  <i className="iHide" style={{ fontSize: "13px" }} /> Hide
                </span>
              )}
              {this.props.unHideShow && (
                <span
                  className="dropdown-item"
                  onClick={(e) => {
                    this.handleUnHideModal(this.props.item.id);
                  }}
                >
                  <i className="iEye_F" style={{ fontSize: "13px" }} /> UnHide
                </span>
              )}
              {this.props.item.userId !== this.state.userId && (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ openReportModal: this.props.item.id });
                  }}
                >
                  <i className="iReportComment_OL" /> Report
                </a>
              )}
            </div>

            {this.state.openReportModal &&
              this.state.openReportModal === this.props.item.id && (
                <ReportAbuse
                  entityId={this.props.item.id}
                  entityType={"FORUM"}
                  removePostListItem={this.props.removeForumListItem}
                  //refreshAllData={this.refreshAllData}
                  triggerReportClose={this.triggerReportClose}
                />
              )}
          </div>
        </div>
      </>
    );
  }
}

export default ForumItem;
