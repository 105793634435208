import React, {PureComponent} from 'react';
import axios from "axios";
import httpService from '../AxiosInterceptors';
import {authService} from "../auth";
import {requestPostCreationFulfilled} from "../reducer/post";
import {connect} from "react-redux";
import ReportAbuse from "../commons/ReportAbuse";
import {getCDNUrl, getOptimizedArticleImage, pointCalculationUpdate} from "../commons/CommonFunctions";
// English.
import EditArticlePopUp from '../EditArticlePopUp';
import EventMessageSection from '../commons/EventMessageSection';
import SavePostButton from './SavePostButton';
import PinPostButton from './PinPostButton';
import FeaturePostButton from './FeaturePostButton';
import CommonPostHeaderSection from './CommonPostHeaderSection';
import CommonPostBottomSection from './CommonPostBottomSection';
import CleverTapUtils from '../commons/CleverTapUtils';

httpService.setupInterceptors();
class ArticlePostItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            editArticleFlag: false,
            articleData: {},
            description: this.props.item.description,
            title: this.props.item.title,
            hashTags: this.props.item.hashTags
        }
    }

    componentDidMount() {
        
    }

    deletePost = () => {
        this.setState({processingDeleteRequest: true})
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/post/delete/' + this.props.item.id,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {
                this.props.requestPostCreationFulfilled({success: true});
                pointCalculationUpdate(this.state.userData.userId);
                window.$('#' + this.props.item.id + "confirmpopForDeletePost").modal('hide');
                this.setState({processingDeleteRequest: false})
                if (this.props.callbackFunc) {
                    this.props.callbackFunc();
                }
            } else {
                this.setState({processingDeleteRequest: false})
            }
        }).catch((err) => {
            console.log(err);
            this.setState({processingDeleteRequest: false})
        });
    }

    redirectToArticleDetail(url) {
        let userAgent = navigator.userAgent
        let mobileandroid = /wenat_android/.test(userAgent)
        let mobileios = /wenat_ios/.test(userAgent)
        CleverTapUtils.eventClick({}, "Display blog article")
        if (url !== undefined && url !== null) {
            if (window.ReactNativeWebView) {
                window.location.href = url;
            } else {
                window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
            }
        }
    }

    openDeleteModal = () => {
        window.$('#' + this.props.item.id + "confirmpopForDeletePost").modal('show');
    }

    openEditModal = (data) => {
        data.title = this.state.title;
        data.description = this.state.description;
        data.hashTags = this.state.hashTags;
        this.setState({
            articleData: data,
            editArticleFlag: true
        }, () => {
            window.$("#editArticlePopUp").modal("show");
        })
    }

    closeEditModal = (data) => {
        this.setState({
            articleData: {},
            editArticleFlag: false,
            description: data.description,
            title: data.title,
            hashTags: data.hashTags
        })
    }

    componentWillUnmount() {
        window.$('#' + this.props.item.id + "confirmpopForDeletePost").modal('hide');
    }

    triggerReportClose = () => {
        this.setState({openReportModal: null});
    }

    handleHideModal = (id) => {
        let data = {
            userId: this.state.userData.userId,
            activityId: id,
            entityType: 'POST',
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/hidden/hide',
            data: data,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 201) {
                //this.props.requestPostCreationFulfilled({success: true, hideFlag: true});
                this.props.removePostListItem(id);
                if(this.props.callbackFunc){
                    this.props.callbackFunc()
                }
            } else {
                this.setState({'isSubmitted': false});
            }
        }).catch((err) => {
            if (err && err.response && err.response.status === 409) {
                this.setState({'error': 'Entity has already been hidden', 'isSubmitted': false});
            } else {
                this.setState({'isSubmitted': false});
            }
        });
    }

    refreshAllData=()=>{
        this.props.requestPostCreationFulfilled({success: true});
    }

    render() {
        return (
            <>
                <div className={`hpostblock article` + (this.props.item.nominated && this.props.postSection === "FEED" ? ' pNominate' : '')}>                    
                    <div className="hpostblockinn">
                        {
                            this.props.item && this.props.item.eventMessage &&
                            <EventMessageSection item={this.props.item} />
                        }
                        {this.props.item.pinned && <span className='pinnedPost'><i className="iPin" style={{ fontSize: '14px' }} /></span>}

                        <CommonPostHeaderSection item={this.props.item} callback_fn={() => this.props.callback_fn(this.props.item.userId)} />

                        <div className="contxmenu">
                            <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false"><i className="iKebab"/></button>
                            <div className="dropdown-menu fadeIn animated">
                                <SavePostButton key={this.props.item.id} id={this.props.item.id} bookMarkFlag={this.props.item.bookMark} entityType={"POST"} />
                                <PinPostButton key={this.props.item.id} item={this.props.item} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} />
                                <FeaturePostButton key={this.props.item.id} item={this.props.item} />
                                {this.props.item.canEdit &&
                                    <a className="dropdown-item" onClick={(e) => {
                                        e.preventDefault();
                                        this.openEditModal(this.props.item)
                                    }} href="javascript:void(0)"><i className="iEditBox" /> Edit Post</a>
                                }
                                {this.props.item.canEdit &&
                                    <a className="dropdown-item" onClick={(e) => {
                                        e.preventDefault();
                                        this.openDeleteModal()
                                    }} href="javascript:void(0)"><i className="iTrashBin" /> Delete Post</a>
                                }                                    
                                <span className="dropdown-item" onClick={e => {
                                    this.handleHideModal(this.props.item.id)
                                }}><i className="iHide" style={{fontSize: '13px'}} /> Hide</span>
                                {(this.state.userData.userId !== this.props.item.userId) && this.props.item.canReport &&
                                    <a className="dropdown-item" href="javascript:void(0)" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({openReportModal: this.props.item.id});
                                    }}><i className="iReportComment_OL" /> Report</a>
                                }
                            </div>

                            {
                                this.state.openReportModal && this.state.openReportModal === this.props.item.id &&
                                <ReportAbuse entityId={this.props.item.id}
                                                entityType={"POST"}
                                                postSection={this.props.postSection}
                                                currentTab={this.props.currentTab}
                                                //refreshAllData={this.refreshAllData}
                                                removePostListItem={this.props.removePostListItem}
                                                triggerReportClose={this.triggerReportClose}/>
                            }
                        </div>
                        <div className="hpostcontent">
                            <div className="hpostconttop">
                                <div className="postarticleblock">
                                    <div className="postarticleimg">
                                        {
                                            this.props.item.attachmentIds && this.props.item.attachmentIds[0] && this.props.item.attachmentIds[0].attachmentUrl ?
                                                <img
                                                    src={getCDNUrl(getOptimizedArticleImage(this.props.item.attachmentIds[0]))}
                                                    alt=""/> :
                                                <img src="https://cdn.dscovr.com/images/banner-explore-blog-small.webp"
                                                onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/banner-explore-blog-small-new.png"}}
                                                alt=""/>
                                        }
                                    </div>
                                    <div className="postarticledesc">
                                        {/*<div className="pacategory">Animals</div>*/}
                                        {this.props.item.userId === this.state.userData.userId ? null :
                                            <div className="paauthor">{this.props.item.userName}</div>}
                                        <h2 className='articlePostTitle'>{this.state.title}</h2>
                                        <div className="padesctxt"
                                             dangerouslySetInnerHTML={{
                                                 __html: this.state.description
                                                     ? this.state.description
                                                         .replace(/&nbsp;/g, ' ')
                                                         .replace(/(\r\n|\r|\n)/g, '<br>')
                                                     : ''
                                             }}/>
                                        <div className="text-center"><a href="javascript:void(0)"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            this.redirectToArticleDetail('/explore-detail/article/' + this.props.item.slug)
                                                                        }}
                                                                        className="more btn">read more</a></div>
                                    </div>
                                </div>

                                {
                                    this.state.hashTags && this.state.hashTags.length > 0
                                    && <div className="hposthash" style={{padding: '0 15px'}}>
                                        {
                                            this.state.hashTags.map((item) => {
                                                return <div key={item} className="hposthashitem"><a href={'/explore-hashtag/'+item}><i
                                                    className="iHashtag"/>{item}</a></div>
                                            })
                                        }
                                    </div>
                                }
                                {this.props.item.postLocation &&
                                <span className="address"><i
                                    className="iLocation"/>{this.props.item.postLocation}</span>
                                }
                            </div>
                            
                            <CommonPostBottomSection key={this.props.item.id} item={this.props.item} enablePostSharing={this.props.enablePostSharing}  postSection={this.props.postSection} />

                        </div>
                    </div>
                </div>
                <div className="modal fade dgpopup confirmpop"
                     id={this.props.item.id + "confirmpopForDeletePost"} tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static"
                     data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Delete Post Confirmation</h5>                                
                            </div> */}
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">&times;</span></button>
                            <div className="confirm-popup-modal-body">Are you sure you want to delete this blog?</div>
                            <div className="modal-footer confirm-popup-modal-footer">
                                <button type="button" className="btn btn-primary"
                                        disabled={this.state.processingDeleteRequest}
                                        onClick={() => this.deletePost()}>Yes
                                </button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.editArticleFlag && <EditArticlePopUp item={this.state.articleData} closeEditModal={this.closeEditModal} />}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(ArticlePostItem);
