import React from 'react'

class Aasa extends React.PureComponent {

    render() {
        return `{
            "applinks": {
              "apps": [],
              "details": [
                {
                  "appID": "LD68YKQMCF.com.wenaturalists.wenaturalists",
                  "paths": ["*"]
                }
              ]
            }
        }`
    }
}

export default Aasa 

