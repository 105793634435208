import React, { Component } from "react";
import { authService } from "../auth";
import withReactContent from "sweetalert2-react-content";
import alertPopUp from "sweetalert2";
import axios from "axios";
import * as _ from "lodash";
import { blockUserMessage } from "./CommonFunctions";
import { withRouter } from "react-router-dom";
import CleverTapUtils from "./CleverTapUtils";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const blockUserSwal = (userDetails, page) => {
    return ({
        title: `Are you sure you want to ${!userDetails.blocked && page !== "BLOCK_LIST" ? "block" : "unblock"} ${page === "PROFILE" ? userDetails.userName : userDetails.username}?`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
    })
};

class UserBlockComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            userDetails: this.props.userDetails,
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    clickBlockUser = () => {
        const blockConfirm = async () => {
            const swalval = await ErorrMsgPopUp.fire(blockUserSwal(this.state.userDetails, this.props.pageName));
            if (swalval && swalval.value === true) {
                this.onClickBlockUserBtn();
            }
        };
        blockConfirm();
    }

    onClickBlockUserBtn = () => {
        let postData = {
            userId: this.state.userId,
            blockUserId: this.props.pageName === "PROFILE"  ? this.state.userDetails.userId : this.state.userDetails.id,
            block: this.props.pageName !== "BLOCK_LIST" ? !this.state.userDetails.blocked : false
        }
        if(this.state.userDetails.blocked) {
            CleverTapUtils.eventClick({}, "Block User");
        }
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/block/user/add`,
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                let data = this.state.userDetails;
                data.blocked = !this.state.userDetails.blocked;
                this.setState({
                    userDetails: data
                })
                if(this.props.pageName !== "PROFILE") {
                    this.props.onSuccessBlockUnblockUser(this.state.userDetails.id);
                }
            }
        }).catch((err) => {
            console.log(err);
            if(err?.response?.data?.status === '409' || err?.response?.data?.status === "409 CONFLICT") {
                blockUserMessage("MESSAGE", err.response.data.message);
            }
        });
    }

    render() {
        const { userDetails } = this.state;
        const { pageName } = this.props;
        return (
            <>
                {!_.isEmpty(userDetails) && 
                    <a href="javascript:void(0)" onClick={(e) => {e.preventDefault(); this.clickBlockUser()}} className="dropdown-item">
                        <i className={(!userDetails.blocked && pageName !== "BLOCK_LIST") ? "ideactivated" : "iReactivate"}/><span>{(!userDetails.blocked && pageName !== "BLOCK_LIST") ? "Block" : "Unblock"}</span>
                    </a>
                }
            </>
        )
    }
}

export default withRouter(UserBlockComponent);