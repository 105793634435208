import React, {Component} from 'react';
import "../../forum/main-forum.min.css";
import axios from "axios";
import {authService} from "../../auth";
import ForumItem from "../../forum/ForumItem";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import PollItem from "../../forum/PollItem";
import {convertToFirstCapital} from "../../utils/StringUtility";
import HideContent from "../HideContent";
import {getCDNUrl, isMobileDevice} from "../../commons/CommonFunctions";
import Follow from "../../commons/Follow";
import {Link} from "react-router-dom";
import OrganizationAdmin from '../../commons/OrganizationAdmin';

if (isMobileDevice()) {
    import("../../commons/styles/responsive-useragent.css").then(() => {
      console.log("Imported mobile css");
    });
}

class ForumAndPoll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url:null,
            localStorageData: authService.getUserDetailsFromLocalStorage(),
            forumpollData:{},
            dataToBeLoaded:'forum',
            showOnlyMyPost:false,
            pageNo: 0,
            pageSize:10,
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    componentDidMount() {
        this.handleStatusChange(this.state.dataToBeLoaded)
    }

    handleStatusChange=(dataToBeLoaded)=>{
        let url = null;
        if(dataToBeLoaded === 'forum'){
            url = '/hidden/forum/list'
        }else{
            url = '/hidden/poll/list'
        }
        this.setState({
            url:url
        },()=>{
            this.ForumAndPollList(this.state.url);
        })
    }

    ForumAndPollList=(url)=>{
        this.setState({'contentLength': 0, 'forumpollData': {}, 'responseMessage': ''});
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + url+"?userId="+this.state.userId+'&showOnlyMyPost='+this.state.showOnlyMyPost+"&page=" + 0 + "&size=" + 6,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response)=>{
            let res = response.data;
            if (res.body && res.message === 'Success!') {
                this.setState({'forumpollData': res.body});
                this.setState({'pagination': res.body.page.last});
                this.setState({'contentLength': res.body.content.length});
            }
        })
    }


    getAnotherPageOfForum() {
        let url;
        this.setState({'pageNo': this.state.pageNo + 1}, () => {
            url = '/hidden/forum/list' + "?userId="+this.state.userId+'&showOnlyMyPost='+this.state.showOnlyMyPost + "&page=" + this.state.pageNo + "&size=" + 6;
            axios({
                method: 'get',
                url: process.env.REACT_APP_userServiceURL + url,
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }).then((response) => {
                let res = response.data;
                if (res.message === 'Success!') {
                    response.data.body.page.content = this.state.forumpollData.content.concat(response.data.body.page.content)
                    this.state.forumpollData.content = response.data.body.page.content;
                    this.setState({'pagination': res.body.page.last});
                    this.setState({'contentLength': res.body.page.content.length});
                }
            });
        })

    }

    getAnotherPageOfPoll() {
        let url;
        this.setState({'pageNo': this.state.pageNo + 1}, () => {
            url = '/hidden/poll/list' + "?userId="+this.state.userId+'&showOnlyMyPost='+this.state.showOnlyMyPost + "&page=" + this.state.pageNo + "&size=" + 6;
            axios({
                method: 'get',
                url: process.env.REACT_APP_userServiceURL + url,
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }).then((response) => {
                let res = response.data;
                if (res.message === 'Success!') {
                    response.data.body.page.content = this.state.forumpollData.content.concat(response.data.body.page.content)
                    this.state.forumpollData.content = response.data.body.page.content;
                    this.setState({'pagination': res.body.page.last});
                    this.setState({'ContentLength': res.body.page.content.length});
                }
            });
        })


    }

    handleUnHideModal = (id) => {
        let data = {
            userId: this.state.userId,
            activityId: id
        }
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/hidden/unhide',
            data: data,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {
                this.refreshData();

            } else {
                this.setState({'isSubmitted': false});
            }
        }).catch((err) => {
            if (err && err.response && err.response.status === 409) {
                this.setState({'error': 'Entity has already been unhidden', 'isSubmitted': false});
            } else {
                this.setState({'isSubmitted': false});
            }
        });
    }

    redirectToProfile = (customUrl) => {
        this.setState({redirectToProfile: customUrl});
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    getForum = () =>{
        return this.state.forumpollData !== undefined ?
            this.state.forumpollData && this.state.forumpollData.content && this.state.forumpollData.content.map((item) => {
                    return item.activityType === 'FORUM' ?                        
                        <div className="col-md-6">
                            <div className="forumrow">
                                <h4><Link to={{pathname: '/forumDetail/' + item.slug, state: {userId: this.state.userId}}}>{item.title}</Link></h4>
                                <div className="forumrowbott">
                                    <div className={"fuserfollow" + (item.userType === "COMPANY"  ? " orgnz" : "")}>
                                        <figure className="userlogo" style={{cursor: 'pointer'}} >
                                            <Link to={{pathname: '/forumDetail/' + item.slug, state: {userId: this.state.userId}}}><img
                                            src={item.profileImage != null ? getCDNUrl(this.getOptimizedImage(item)) : (item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                            alt=""/></Link></figure>
                                        <Link to={{pathname: '/forumDetail/' + item.slug, state: {userId: this.state.userId}}}>{item.userName}</Link>
                                        {item && <Follow followed={item.followed} userId={item.userId}
                                                            deactivated={this.state.deactivated}/>}
                                    </div>
                                </div>
                                <a className="unhidebtn" href="javascript:void(0)" onClick={(e) => {
                                    e.preventDefault();
                                    this.handleUnHideModal(item.id)
                                }}><i className="iEye_OL"/></a>
                            </div>
                        </div>: null
                }
            ) : <section>
                {Array(9)
                    .fill()
                    .map((item, index) => (
                        <SkeletonTheme>
                            <div className="hpostblock" key={index}>
                                <figure className="userimg">
                                    <Skeleton circle={true} height={50} width={50}/>
                                </figure>
                                <div className="hpostblockinn">
                                    <div className="hposthead">
                                        <h3>
                                            <Skeleton height={36} width={`80%`}/>
                                        </h3>
                                        <div className="hpostdtls">
                                            <Skeleton height={36} width={`30%`}/>
                                        </div>
                                        <div className="hpostcontent">
                                            <div className="hpostconttop">
                                                <p><Skeleton height={120} width={`100%`}/></p>
                                            </div>
                                        </div>
                                        <div className="card-metrics">
                                            <Skeleton width={`90%`}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </SkeletonTheme>
                    ))}
            </section>

    }

    getPageFilterType(value) {
        this.setState({
            dataToBeLoaded: value,
            pageNo: 0,
            contentLength:0,
            pagination:true
        })
        let url = null;
        if(value === 'forum'){
            url = '/hidden/forum/list'
        }else{
            url = '/hidden/poll/list'
        }
        this.ForumAndPollList(url);

    };

    getCheckedData = (event) => {
        event.preventDefault();
        this.setState({
            pageNo: 0,
            contentLength:0,
            pagination:true,
            showOnlyMyPost:!this.state.showOnlyMyPost
        },()=>{
            let url = null;
            if(this.state.dataToBeLoaded === 'forum'){
                url = '/hidden/forum/list'
            }else{
                url = '/hidden/poll/list'
            }
            this.ForumAndPollList(url);
        })


    };

    refreshData = () => {
        this.setState({
            pageNo: 0,
            contentLength:0,
            pagination:true
        },()=>{
            let url = null;
            if(this.state.dataToBeLoaded === 'forum'){
                url = '/hidden/forum/list'
            }else{
                url = '/hidden/poll/list'
            }
            this.ForumAndPollList(url);
        })


    };

    getPoll = (section) => {
        return this.state.forumpollData.content !== undefined ?
            this.state.forumpollData && this.state.forumpollData.content && this.state.forumpollData.content.map((item) => {
                    if ((this.state.type === 'PINNED' && item.pinned) || this.state.type !== 'PINNED') {
                        return item.activityType === 'POLL' ?
                            <div className="col-md-6">
                                <div className="forumrow">
                                    <h4><Link to={{pathname: '/pollDetail/' + item.slug, state: {userId: this.state.userId}}}><i
                                        className="iPolls"/>{item.question}
                                    </Link>
                                    </h4>
                                    <div className="forumrowbott">
                                        <div className="fuserfollow">
                                            <figure className="userlogo" style={{cursor: 'pointer'}}>
                                                <Link to={{pathname: '/pollDetail/' + item.slug, state: {userId: this.state.userId}}}><img
                                                src={item.profileImage != null ? getCDNUrl(this.getOptimizedImage(item)) : (item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                                alt=""/></Link></figure>
                                            <Link to={{pathname: '/pollDetail/' + item.slug, state: {userId: this.state.userId}}}>{item.userName}</Link>
                                            {item && <Follow followed={item.followed} userId={item.userId}
                                                                deactivated={this.state.deactivated}/>}
                                        </div>
                                    </div>
                                    <a className="unhidebtn" href="javascript:void(0)" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleUnHideModal(item.id)
                                    }}><i className="iEye_OL"/></a>
                                </div>
                            </div> : null
                    }
                }
            ) : <section>
                {Array(9)
                    .fill()
                    .map((item, index) => (
                        <SkeletonTheme>
                            <div className="hpostblock" key={index}>
                                <figure className="userimg">
                                    <Skeleton circle={true} height={50} width={50}/>
                                </figure>
                                <div className="hpostblockinn">
                                    <div className="hposthead">
                                        <h3>
                                            <Skeleton height={36} width={`80%`}/>
                                        </h3>
                                        <div className="hpostdtls">
                                            <Skeleton height={36} width={`30%`}/>
                                        </div>
                                        <div className="hpostcontent">
                                            <div className="hpostconttop">
                                                <p><Skeleton height={120} width={`100%`}/></p>
                                            </div>
                                        </div>
                                        <div className="card-metrics">
                                            <Skeleton width={`90%`}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </SkeletonTheme>
                    ))}
            </section>
    }

    render() {
        const { userId } = this.state;
        return (
                <HideContent userId={userId}>
                    <div className="forumtopfilter" style={{marginBottom: '-25px'}}>
                        <div className="showmewrap" style={{marginTop: '5px'}}>
                            <label className="switch">
                                Show only my posts
                                <input type="checkbox" checked={this.state.showOnlyMyPost} onClick={this.getCheckedData} />
                                <span className="slider round"></span>										
                            </label>
                        </div>
                        <div className="shortwrap">
                            <div className="projtopshortby">
                                <span>Sort by</span>
                                <div className="shortbydrop">
                                    <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">{convertToFirstCapital(this.state.dataToBeLoaded)}</button>
                                    <div className="dropdown-menu fadeIn animated">
                                        {this.state.dataToBeLoaded !== 'forum' ?
                                        <a className="dropdown-item"
                                           id="filter-Forum"
                                           onClick={e => {
                                               e.preventDefault();
                                               this.getPageFilterType('forum')
                                           }} href="javascript:void(0)">Forum</a> : null
                                    }{this.state.dataToBeLoaded !== 'poll' ?
                                        <a className="dropdown-item" onClick={e => {
                                            e.preventDefault();
                                            this.getPageFilterType('poll')
                                        }} href="javascript:void(0)">Poll</a> : null
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="forumsection frtreding">
                        {this.state.dataToBeLoaded === "forum" && <>
                            {this.state.forumpollData && this.state.forumpollData.content && this.state.forumpollData.content.length === 0 && 
                                <div className="zdata col-12" style={{marginTop: '50px'}}>
                                    <div class="imgbox">
                                        <img src="https://cdn.dscovr.com/images/zroForumAll.webp"
                                        onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/zroForumAll.png"}} alt="" width={500} />
                                    </div>
                                    <div className="desc">
                                        <p style={{margin: '0px'}}>You have no Hidden Activities</p>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                {this.getForum()}


                                {!this.state.pagination && this.state.contentLength > 0 &&
                                <div className="text-center col-12"><a href="#!" className="btn morebtnsm" onClick={e => {
                                    e.preventDefault();
                                    this.getAnotherPageOfForum()
                                }}>View more</a></div>}
                            </div>
                        </>}
                        {this.state.dataToBeLoaded === "poll" && <>
                            {this.state.forumpollData && this.state.forumpollData.content && this.state.forumpollData.content.length === 0 && 
                                <div className="zdata col-12" style={{marginTop: '50px'}}>
                                    <div class="imgbox">
                                        <img src="https://cdn.dscovr.com/images/zroPollAll.webp"
                                        onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/zroPollAll.png"}} alt="" width={400} />
                                    </div>
                                    <div className="desc">
                                        <p style={{margin: '0px'}}>You have no Hidden Activities</p>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                {this.getPoll()}

                                        {!this.state.pagination && this.state.contentLength > 0 &&
                                <div className="text-center col-12"><a href="#!" className="btn morebtnsm" onClick={e => {
                                    e.preventDefault();
                                    this.getAnotherPageOfPoll()
                                }}>View more</a></div>}
                            </div>
                        </>}
                    </div>
                </HideContent>
        );
    }
}

export default ForumAndPoll;