import React from 'react'
import $ from 'jquery';
import {authService} from "../auth";
import Header from "../Header";
import FooterDiv from "../FooterDiv";
import StaticHeader from "../StaticHeader";
import {Link, NavLink} from "react-router-dom";
import LeftPanel from '../LeftPanel';
import AuthModalComponent from "../LoginExtra/AuthModalComponent";
import SignUpModal from "../SignUpModal/signUpModal";
import LeftPanelUnauth from "../commons/LeftPanelUnauth";
import { isWebView } from '../commons/CommonFunctions';
import StaticPagesCommonFunction from '../commons/StaticPagesCommonFunction';

class RefundPolicy extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
        };
    }

    componentDidMount() {
        document.title = "Refund Policy | WeNaturalists"
        window.scrollTo(0, 0);

        window.$(document).on('contextmenu', "img:not('.logotop')", function () {
            return false;
        });
        window.$('#header .iCross').hide();
        window.$("#header .iHamburger").on('click', function (event) {
            window.$('.leftpanel').addClass('navactive');
            window.$('.dgcontainer').addClass('navactive');
            window.$('.staticPage').addClass('navactive');
            window.$('#header .iHamburger').hide();            
            window.$('#header .iCross').show();
        });
        $("body, html").on('click', function (event) {
            if (!$(event.target).closest('#header .iHamburger, .read-more-less-btn').length) {
                $('.leftpanel').removeClass('navactive');
                $('.dgcontainer').removeClass('navactive');
                window.$('.staticPage').removeClass('navactive');
                $('.dropdown-menu.show').removeClass('show');
                window.$('#header .iHamburger').show();            
                window.$('#header .iCross').hide();
            }
        });
        window.$('.usermenu .dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.usermenu .dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.usermenu .dropdown-menu').addClass('show');
            e.stopPropagation();
        });
    }

    loginRequired=(slug)=> {
        this.setState({'loginRequired': true, 'pathname':slug})
        window.$('#loginModal').modal('show');
    }

    handleLoginClose = () => {
        this.setState({'loginRequired': false})
        window.$('#loginModal').modal('hide');
    }

    signUpRequired=()=>{
        this.setState({'signUpRequired':true})
        window.$('#signupModal').modal('show');
    }

    handleSignUpClose = () => {
        this.setState({'signUpRequired': false})
        window.$('#signupModal').modal('hide');
    }

    render() {
        return (
            <div className={"staticPage"/*   + (isWebView() ? " mobileView" : "") */}>
                {/* {!isWebView() ? (!this.state.loginSuccess ? <StaticHeader/> : <Header/>) : null} */}
                {!this.state.loginSuccess ? <StaticHeader/> : <Header/>}

                {StaticPagesCommonFunction.refundPolicy()}
                
                {!this.state.loginSuccess ?
                    <LeftPanelUnauth signUpRequired={this.signUpRequired}/> :
                <LeftPanel/>}
                {!isWebView() && <FooterDiv/>}
                {this.state.loginRequired === true ? <AuthModalComponent handleLoginClose={this.handleLoginClose} pathname={this.state.pathname}/> : null}
                {this.state.signUpRequired === true ? <SignUpModal  handleSignUpClose={this.handleSignUpClose} history={this.props.history}/> : null}
            </div>
        );

    }

}

export default RefundPolicy
