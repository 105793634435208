export const pageClickRequest = (data) =>{
    return {
        type : "PAGE_CLICK_REQUEST",
        payload: data
    }
}

export const pageClickSuccess = (data) =>{
    return {
        type : "PAGE_CLICK_SUCCESS",
        payload : data,
        error : {}
    }
}

export const pageClickFailure = (error) =>{
    return {
        type : "PAGE_CLICK_FAILURE",
        payload : {},
        error : error
    }
}