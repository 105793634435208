import React, { PureComponent } from 'react';
import { authService } from "../auth";
import httpService from "../AxiosInterceptors";
import { requestPostCreationFulfilled } from "../reducer/post";
import { connect } from "react-redux";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import { detailsPageRedirection, get720pTranscodedVideo, getCDNUrl, videoThumbnailFunction } from "../commons/CommonFunctions";
import { defaultProjectImage } from '../project/component/Studio/StudioContentCommonFunction';
import EventMessageSection from '../commons/EventMessageSection';
import CommonPostHeaderSection from './CommonPostHeaderSection';
import CommonPostMenuSection from './CommonPostMenuSection';
import CommonPostBottomSection from './CommonPostBottomSection';
import { pageClickHookPostMethod } from '../services/PostApi';

httpService.setupInterceptors();

class VideoPostItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            description: props.item.description,
            editedText: props.item.description,
            hashTags: [],
            tags: [],
            smileDisplay: false
        }
    }

    componentDidMount() {
        let tags = [];
        this.props.item && this.props.item.hashTags && this.props.item.hashTags.map((data, index) => {
            tags.push(data);
            this.setState({ tags: tags });
        })
        window.$('.playbutt').bind('contextmenu', function (e) {
            return false;
        });

    }

    onSuccessUpdatePost = (data) => {
        this.setState({
            description: data.description,
            tags: data.tags
        })
    }

    handlePageClickHookPost = async () => {
        let postData = {
          entityId: this.props.item?.id,
          userId: this.state.userData?.userId,
          entityType: "POST"
        }
        await pageClickHookPostMethod(postData);
    }

    render() {
        return (
            <div className={`hpostblock` + (this.props.item.nominated && this.props.postSection === "FEED" ? ' pNominate' : '')}>
                <div className="hpostblockinn">
                    {
                        this.props.item && this.props.item.eventMessage &&
                        <EventMessageSection item={this.props.item} />
                    }
                    {this.props.item.pinned && <span className='pinnedPost'><i className="iPin" style={{ fontSize: '14px' }} /></span>}

                    <CommonPostHeaderSection item={this.props.item} callback_fn={() => this.props.callback_fn(this.props.item.userId)} />

                    <CommonPostMenuSection key={this.props.item.id} item={this.props.item} removePostListItem={this.props.removePostListItem} description={this.state.description} tags={this.state.tags} onSuccessUpdatePost={this.onSuccessUpdatePost} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} postType={"VIDEO"} postSection={this.props.postSection} currentTab={this.props.currentTab} />

                    <div className="hpostcontent">
                        <div className="hpostconttop">
                            {((this.state.description && this.state.description !== '') || this.state.tags) &&
                                <ReadMoreReadLess id={this.props.item.id} description={this.state.description} hashTags={this.state.tags} item={this.props.item} />
                            }

                            {this.props.item.attachmentIds && this.props.item.attachmentIds[0]
                                && this.props.item.attachmentIds[0].attachmentUrl &&
                                <figure className="hpostimggrid">
                                    <div className="himggrid vidbox">
                                        {/*<span className="tagtitle">Video No1 Title</span>*/}
                                        <a data-fancybox={this.props.item.id} href={getCDNUrl(get720pTranscodedVideo(this.props.item.attachmentIds[0]))} onClick={() => this.handlePageClickHookPost()}>
                                            {((this.props.item?.thumbnail) || (this.props.item.attachmentIds[0].thumbnails && this.props.item.attachmentIds[0].thumbnails[0])) ?
                                                <img src={getCDNUrl(videoThumbnailFunction(this.props.item))}
                                                    alt="" />
                                                : <img src="https://cdn.dscovr.com/images/video-blank2.webp" onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/video-blank2.png" }} alt="" />}
                                            <span className="playbutt" onContextMenu="return false;"><i className="iPlay" /></span>
                                        </a>
                                    </div>
                                </figure>
                            }

                            {this.props.item.attachmentIds && this.props.item.attachmentIds.length === 0 && this.props.item.paidPost &&
                                <figure className="hpostimggrid ppLock">
                                    <div className="himggrid vidbox">
                                        <a onClick={(e) => detailsPageRedirection(this.props.item.id)}
                                            href='javascript:void(0)'>
                                            <img src={getCDNUrl(defaultProjectImage.attachmentUrl)} onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/video-blank2.png" }} alt="" />
                                            <span className="playbutt" onContextMenu="return false;"><i className="iPlay" /></span>
                                        </a>
                                    </div>
                                </figure>
                            }

                            {this.props.item.postLocation &&
                                <span className="address"><i
                                    className="iLocation" />{this.props.item.postLocation}</span>
                            }
                        </div>

                        <CommonPostBottomSection key={this.props.item.id} item={this.props.item} enablePostSharing={this.props.enablePostSharing} postSection={this.props.postSection} />

                    </div>

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(VideoPostItem);
