import React, {PureComponent} from 'react';
import {authService} from "../auth";
import $ from 'jquery';
import CirclePostItem from "./CirclePostItem";
import * as _ from "lodash";
import {getCDNUrl} from "../commons/CommonFunctions";
import Scrollbars from 'react-custom-scrollbars';

class CirclePostList extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            projectList: props.projectList?props.projectList:[]
        }
    }

    componentDidMount() {
        window.$('.dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.dropdown-menu').addClass('show');
            e.stopPropagation();
        });
    };

    getOptimizedImage = (item) => {
        if (item.resizedImages && item.resizedImages['300x300'] != null) {
            return item.resizedImages['300x300'];
        }
        return item.profileImage;
    }


    render() {

        return (
            <>
            {this.props.circleList && this.props.circleList.length > 0 &&
            <div className="postCardBlk">
                <div className='postCardHeading' id={'heading-circle'}>
                    <a role="button" data-toggle="collapse" href={'#circleList'} aria-expanded="false">
                        <figure className="ico">
                            <i className="iCircle_Default" style={{fontSize: '15px'}} />
                        </figure>
                        <div>
                            <h5>Community</h5>
                        </div>
                    </a>
                </div>                    
                <div id={'circleList'} className="collapse" data-parent="#accordion" aria-labelledby={'heading-circle'}>
                    <div className="postCardbody">
                        <Scrollbars
                            className={'forwcontlistmain'}
                            autoHide
                            autoHeight
                            autoHeightMin={0}
                            autoHeightMax={230}
                            universal={true}
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                            renderTrackVertical={props => <div {...props} style={{display: 'none'}} className="track-vertical"/>}
                        >
                        {
                            this.props.circleList.map((item) =>
                                <CirclePostItem key={item.id} item={item} setAsUser={()=>this.props.setAsUser(item.id,'Circle',item.title,item.id,!_.isNull(item.profileImage) && !_.isUndefined(item.profileImage) ? getCDNUrl(this.getOptimizedImage(item)) : 'https://cdn.dscovr.com/images/circleDefault.png')}/>
                            )
                        }
                        </Scrollbars>
                    </div>
                </div>
            </div>
            }
            </>
        );
    }
}

export default CirclePostList;
