
import axios from "axios";

import httpService from '../../AxiosInterceptors';
import { userAgentGetData } from "../../commons/CommonFunctions";

httpService.setupInterceptors();



export const fetchWpondYearDetail = function (data) {
    let url = `${process.env.REACT_APP_userServiceURL}/wpondAbout/getUpcomingYear`;
    if(!data) {
        url = `${process.env.REACT_APP_userServiceURL}/unauth/wpondAbout/getUpcomingYear${userAgentGetData(true)}`
    }
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + '/wpondAbout/getUpcomingYear',
        cache: true,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => {
        if (response && response.status === 200) {
            return response.data.body;
        } else return {};
    }).catch((e) => {
        console.log(e);
    });
};