export const SET_RENEWED_ACCESS_TOKEN = "SET_RENEWED_ACCESS_TOKEN";
export const SET_RENEWED_ACCESS_TOKEN_SUCCESS = "SET_RENEWED_ACCESS_TOKEN_SUCCESS";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_DATA_SUCCESS = "SET_USER_DATA_SUCCESS";
export const SET_OTHER_USER_DATA = "SET_OTHER_USER_DATA";
export const SET_OTHER_USER_DATA_SUCCESS = "SET_OTHER_USER_DATA_SUCCESS";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_PROFILE_DATA_SUCCESS = "SET_PROFILE_DATA_SUCCESS";

let initialState = {
    renewAccessTokenResult: {},
    networkCountResult: {},
    otherUserData: {},
    userData: {},
    profileData: {}
};

const authReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_RENEWED_ACCESS_TOKEN_SUCCESS:
            state = {
                ...state,
                networkCountResult: action.payload, isLoaded: true
            };
            return state;
        case SET_RENEWED_ACCESS_TOKEN:
            state = {
                ...state,
                networkCountResult: action.payload, isLoaded: false
            };
            return state;
        case SET_USER_DATA_SUCCESS:
            state = {
                ...state,
                userData: action.payload, isLoaded: true
            };
            return state;
        case SET_USER_DATA:
            state = {
                ...state,
                userData: action.payload, isLoaded: false
            };
            return state;
        case SET_OTHER_USER_DATA_SUCCESS:
            state = {
                ...state,
                otherUserData: action.payload, isLoaded: true
            };
            return state;
        case SET_OTHER_USER_DATA:
            state = {
                ...state,
                otherUserData: action.payload, isLoaded: false
            };
            return state;
        case SET_PROFILE_DATA_SUCCESS:
            state = {
                ...state,
                profileData: action.payload, isLoaded: true
            };
            return state;
        case SET_PROFILE_DATA:
            state = {
                ...state,
                profileData: action.payload, isLoaded: false
            };
            return state;
        default:
            return state;
    }
};

export default authReducer;

export function setUserData(response) {
    return {type: SET_USER_DATA, payload: response};
}

export function setUserDataOnSuccess(response) {
    return {type: SET_USER_DATA_SUCCESS, payload: response};
}

export function setOtherUserData(response) {
    return {type: SET_OTHER_USER_DATA, payload: response};
}

export function setOtherUserDataOnSuccess(response) {
    return {type: SET_OTHER_USER_DATA_SUCCESS, payload: response};
}
export function setUserProfilerData(response) {
    return {type: SET_PROFILE_DATA, payload: response};
}

export function setUserProfilerDataOnSuccess(response) {
    return {type: SET_PROFILE_DATA_SUCCESS, payload: response};
}
