import React, {PureComponent} from 'react';
import {getCDNUrl} from "./CommonFunctions";
import {Link, NavLink, Redirect} from "react-router-dom";
import MutualConnect from "./MutualConnect";
import Follow from "./Follow";
import {authService} from "../auth";
import ProfileConnectButton from "./ProfileConnectButton";
import SendMessageModal from "./SendMessageModal";
import ReactTooltip from "react-tooltip";


class MemberItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
        }
    }

    redirectToProfile = (customUrl) => {
        this.setState({redirectToProfile: customUrl});
    }

    triggerMessageModalClose = () => {
        this.setState({openSendMessageModal: null});
    }


    /*getConnectStatus = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + "/graph/users/" + this.state.userData.userId + "/connectionStatus/" + this.props.item.userId,
            withCredentials: true,
            headers: {"Content-Type": "application/json"},
        }).then((response) => {

            if (response && response.status === 200) {
                if (response.data && response.data.body) {
                    this.setState({connectStatusInfo: response.data.body});
                }

            }
        }).catch((e) => {
            console.log(e);
        });
    }*/

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo?.originalProfileImage || personalInfo?.profileImage;
    }

    render() {
        let id ;
        if(this.props.item.id){
            id =  this.props.item.id;
        }else{
            id = this.props.item.userId
        }
        return (
            <>
                {this.state.redirectToProfile && <Redirect push to={"/profile/" + this.state.redirectToProfile}/>}

                <li className={this.props.pageType === "COMMUNITY_FOLLOWERS" ? "newOrgnzFollowBlk" : ""}>{this.state.userData.userId != id ?
                    <>
                        <figure className="users" onClick={(e) => {
                            e.preventDefault();
                            this.redirectToProfile(this.props.item.customUrl?this.props.item.customUrl: this.props.item.userCustomUrl)
                        }} style={{cursor:'pointer'}}>
                            {this.props.item.personalInfo ?
                            <img src={(this.props.item?.personalInfo?.originalProfileImage || this.props.item?.personalInfo?.profileImage) ?
                                getCDNUrl(this.getOptimizedImage(this.props.item.personalInfo)) : "https://cdn.dscovr.com/images/users1.png"}
                                 alt=""/> :
                                <img src={this.props.item.originalProfileImage ? getCDNUrl(this.props.item.originalProfileImage) :
                                this.props.item.userProfileImage ? getCDNUrl(this.props.item.userProfileImage) :
                                    this.props.item.type !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"}
                                     alt=""/>}
                                </figure>
                        <div>
                            <h5 className= {"name" + (this.props.item.type === "COMPANY" ? " orgnzName" : "")}>
                                <span onClick={(e) => {
                                    e.preventDefault();
                                    this.redirectToProfile(this.props.item.customUrl?this.props.item.customUrl: this.props.item.userCustomUrl)
                                }} style={{cursor:'pointer'}}>{this.props.item.username? this.props.item.username : this.props.item.userName}</span>
                                {(this.props.item.connectDepth && id !== this.state.userData.userId) ?
                                <MutualConnect  connectDepth = {this.props.item.connectDepth}/>:null}
                            </h5>
                            <span class="persona">{(this.props.item.type !== 'COMPANY' ? this.props.item.persona : (this.props.item.persona ? this.props.item.persona : this.props.item.organizationType))}</span>
                            <span className="mutual">{this.props.item.addressDetail && this.props.item.addressDetail.country}</span>
                        </div>
                        <div className="buttonsWrap">
                            {!this.props.item.deactivated &&
                            <div className="contxmenu">
                                <button type="button" className="btn"
                                        data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"><i
                                    className="iKebab"/></button>
                                <div className="dropdown-menu fadeIn animated">
                                    {/*{this.props.item.connectDepth  && this.props.item.connectDepth !== 1 ?
                                        <a href="javascript:void(0)" onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({openSendMessageModal: id});
                                        }} className="dropdown-item"><i
                                            className="iMesseges_F"/> Send Message
                                        </a> :
                                        <Link
                                            to={"/message-user/" + id}
                                            className="dropdown-item"><i
                                            className="iMesseges_F"/> Chat</Link>
                                    }*/}
                                    <a href="javascript:void(0)" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({openSendMessageModal: id});
                                    }} className="dropdown-item"><i
                                        className="iMesseges_F"/> Send Message
                                    </a>

                                    {this.props.pageType !== "COMMUNITY_FOLLOWERS" && this.props.item && this.props.item.connectStatus &&  this.props.item.type !== "COMPANY" &&
                                    (this.props.item.connectDepth && this.props.item.connectDepth != 1 )&&
                                    <ProfileConnectButton userId={id}
                                                        popup = {true}
                                                        customUrl={this.props.item.customUrl?this.props.item.customUrl: this.props.item.userCustomUrl}
                                                        userName={this.props.item.username? this.props.item.username : this.props.item.userName}
                                                        connectStatusInfo={this.props.item.connectStatus}
                                    />}
                                        <Follow followed={this.props.item.followed}
                                                popup = {true}
                                                userId={id}/>
                                </div>
                            </div>}
                        </div>
                        {!this.props.item.deactivated &&
                            this.state.openSendMessageModal && this.state.openSendMessageModal === (id) &&
                            <SendMessageModal profileId={id}
                                              userName={this.props.item.username? this.props.item.username : this.props.item.userName}
                                              triggerMessageModalClose={this.triggerMessageModalClose}/>
                        }
                    </>:
                    <>
                        <figure className="users" onClick={(e) => {
                            e.preventDefault();
                            this.redirectToProfile(this.props.item.customUrl?this.props.item.customUrl: this.props.item.userCustomUrl)
                        }} style={{cursor:'pointer'}}>
                            {this.props.item.personalInfo ?
                            <img src={(this.props.item?.personalInfo?.originalProfileImage || this.props.item?.personalInfo?.profileImage) ?
                                getCDNUrl(this.getOptimizedImage(this.props.item.personalInfo)) : "https://cdn.dscovr.com/images/users1.png"}
                                 alt=""/> :
                                <img src={this.props.item.originalProfileImage ? getCDNUrl(this.props.item.originalProfileImage) : this.props.item.userProfileImage ? getCDNUrl(this.props.item.userProfileImage) : this.props.item.type !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"}
                                     alt=""/>}
                                </figure>
                        <div>
                            <h5>
                                <div className="name" onClick={(e) => {
                                    e.preventDefault();
                                    this.redirectToProfile(this.props.item.customUrl?this.props.item.customUrl: this.props.item.userCustomUrl )}}>You
                                </div>
                            </h5>
                        </div>
                    </>}
                </li>
            </>
        );
    }
}

export default MemberItem;
