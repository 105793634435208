import React, { Component } from "react";
import { authService } from "../auth";
import { totalVoteCount, userAgentGetData } from "./CommonFunctions";
import Linkify from "react-linkify";
import axios from "axios";
import PollViewVoterPopUp from "../forum/PollViewVoterPopUp";
import { withRouter } from "react-router-dom";
class PollVoteSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            pollDataDetail: props.pollDataDetail,
            checkAnswerId: "",
            submitDisable: true,
            pollAnswerList: [],
            pollUserVoteMap: [],
            pollViewVoterFlag: false,
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    onChange(id) {
        this.setState({
            checkAnswerId: id,
            submitDisable: false
        });
    }

    submitPoll = (id, answerId) => {
        let pollBody = {
            "userId": this.state.userId,
            "activityId": id,
            "answerId": answerId
        };
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_userServiceURL}/backend/public/poll/vote`,
            headers: { 'Content-Type': 'application/json' },
            data: pollBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.getPollDetails(id);
            }
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    error: { message: err.response.data.message, err: err.response }
                });
            }
        });
    };

    getPollDetails = (id) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_userServiceURL}/poll/get?id=${id}&userId=${this.state.userId}${userAgentGetData()}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response) => {
            if (response && response.data && response.data.message === 'Success!') {
                this.setState({
                    pollDataDetail: response.data.body
                })
            }
        }).catch((err) => {
        });
    };

    progressDetails = (items, prop) => {
        return items.reduce(function (a, b) {
            return a + b[prop];
        }, 0);
    };

    pollVoterResult = (answerList, userVoteMap) => {
        this.setState({
            pollAnswerList: answerList,
            pollUserVoteMap: userVoteMap,
            pollViewVoterFlag: true
        }, () => {
            window.$("#pollViewVoterPopUp").modal("show");
        })
    }

    closePollViewVoter = () => {
        window.$("#pollViewVoterPopUp").modal("hide");
        this.setState({
            pollViewVoterFlag: false
        })
    }

    componentDidMount(){
        window.$('.forumdescmain .postVStats').click(function (event) {
            window.$(".potViewGlobal").collapse('toggle');
        });
    };

    render() {
        const { pollDataDetail, checkAnswerId, submitDisable, loginSuccess, userId, pollViewVoterFlag, pollAnswerList } = this.state;
        const { pageType } = this.props;
        const currentTime = new Date().getTime();
        return (
            <>
                {(pollDataDetail.votePoll || (pollDataDetail.endDate < currentTime) || (currentTime < pollDataDetail.startDate)) ?
                    <div className="fselectedarea">
                        {pollDataDetail.answerList && pollDataDetail.answerList.map((answer) =>
                            <div key={answer.id} className={answer.vote ? 'progress active' : 'progress'} style={{ marginBottom: "10px" }}>
                                <div className="progress-bar" role="progressbar" aria-valuenow='20'
                                    aria-valuemin='0'
                                    aria-valuemax='60'
                                    style={{ width: ((answer.totalVote / this.progressDetails(pollDataDetail.answerList, 'totalVote')) * 100) + '%' }} />
                                <span className="progress-type">{answer.name}</span>
                                {currentTime > pollDataDetail.startDate && answer.totalVote > 1 &&
                                    <span className="progress-completed">
                                        <span>{answer.totalVote}</span> votes </span>}
                                {currentTime > pollDataDetail.startDate && answer.totalVote < 2 &&
                                    <span className="progress-completed">
                                        <span>{answer.totalVote}</span> vote</span>}
                            </div>
                        )}
                    </div> :
                    <div className="fselectoption">
                        <label>Select an option:</label>
                        <div className="foptions">
                            {pollDataDetail.answerList && pollDataDetail.answerList.map((answer) =>
                                <label className="radio-container">
                                    <input type="radio"
                                        value={answer.id}
                                        checked={checkAnswerId === answer.id}
                                        onChange={this.onChange.bind(this, answer.id)} />
                                    <span><Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}>{answer.name}</Linkify></span>
                                </label>
                            )}
                        </div>
                        <div className='pollBtnDaysleftblk'>
                            <input type="submit" onClick={e => { e.preventDefault(); this.submitPoll(pollDataDetail.id, checkAnswerId) }} disabled={submitDisable} value="Submit" className="btn btnsubmit" />
                        </div>
                    </div>
                }
                <div className="d-flex pollBottInfo">
                    {(pollDataDetail.startDate < currentTime) &&  
                        <div className='pollBottInfo' style={{ marginTop: '0' }}>
                            <div className="pollVotesCntInfo">
                                {pollDataDetail.answerList && (totalVoteCount(pollDataDetail.answerList) > 0) && <span>{totalVoteCount(pollDataDetail.answerList)} {totalVoteCount(pollDataDetail.answerList) > 1 ? "Votes" : "Vote"}</span>}
                                {loginSuccess && (pollDataDetail.userId === userId) && (totalVoteCount(pollDataDetail.answerList) > 0) && <button type="button" className="btn" onClick={(e) => this.pollVoterResult(pollDataDetail.answerList, pollDataDetail.userVoteMap)}>View Result</button>}
                                {pollDataDetail.daysLeft > 0 && 
                                    <span className='polldaysLeft'>{pollDataDetail.daysLeft} day{pollDataDetail.daysLeft > 1 && "s"} left</span>}
                            </div>
                        </div>
                    }
                    {pollDataDetail.hashTags && pageType === "DETAILS"  && (
                        <div className="frdtagslist">
                            {pollDataDetail.hashTags.map((item) => {
                                return (
                                    <span key={item}>
                                        <a href={"/explore-hashtag/" + item}>
                                            {item}
                                        </a>
                                    </span>
                                );
                            })}
                        </div>
                    )}
                </div>

                {pollViewVoterFlag &&
                    <PollViewVoterPopUp pollAnswerList={pollAnswerList} closePollViewVoter={this.closePollViewVoter} pollId={pollDataDetail.id} />
                }
            </>
        )
    }
}

export default withRouter(PollVoteSection);