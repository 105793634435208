import {call, put, takeEvery} from "redux-saga/effects";
import * as api from "../../api/participantDetailsAPI.js";

import {SET_APPLICATIONS, SET_PARTICIPANTS, setApplicationsOnSuccess,} from "../../reducer/project/applicantReducer.js";

function* loadApplications(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let projectId = action.payload == undefined ? "" : action.payload.projectId;
        let isPublished = false;
        let response = yield call(
            api.fetchAppliedParticipants,
            projectId,
            isPublished,
            pageNo,
            pageSize
        );
        yield put(setApplicationsOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadParticipants(action) {
    try {
        let pageNo = action.payload == undefined ? 0 : action.payload.pageNo;
        let pageSize = action.payload == undefined ? 6 : action.payload.pageSize;
        let projectId = action.payload == undefined ? "" : action.payload.projectId;
        let isPublished = true;
        let response = yield call(
            api.fetchAppliedParticipants,
            projectId,
            isPublished,
            pageNo,
            pageSize
        );
        yield put(setApplicationsOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export default function* watchParticipants() {
    yield takeEvery(SET_PARTICIPANTS, loadParticipants);
}

export function* watchApplicants() {
    yield takeEvery(SET_APPLICATIONS, loadApplications);
}
