import React, { useState, useEffect } from 'react';
import { authService } from "../../auth";
import { Link, NavLink } from "react-router-dom";
import uuid from "react-uuid";
import axios from 'axios';
import { getCDNUrl, userAgentGetData } from "../../commons/CommonFunctions";
import mappingUtils from "../../project/component/commons/utils/mappingUtils";
import { getTotalOthers, numberShortForm } from './Common/HashtagsCommonFunction';


function IndividualHashTagsCategory(props) {
    const [userData, setUserData] = useState(authService.getUserDetailsFromLocalStorage());
    const [userDetails, setUserDetails] = useState({});
    const [categoryCountList, setCategoryCountList] = useState([]);
    const [userDataFetched, setUserDataFetched] = useState(false);

    const getOptimizedImage = (personalInfo) => {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200']; 
        }else if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['300x300'] != null) {
            return personalInfo.resizedProfileImages['300x300'];
        }
        return personalInfo.profileImage;
    }


    // const getUserDetailsByCustomUrl = () => {
    const getEntityDetailsByCustomUrl = () => {
        
        let url;
        if(props?.hashTagType === "COMMUNITY"){
            url = `circle/get-by-slug/${props?.customUrl}?userId=${userData?.userId}`
        }else{
            url = `profile/get_by_custom_url?customUrl=${props?.customUrl}&otherUserId=${userData.userId+userAgentGetData()}`
        }

        axios({
            method: 'get',
            // url: process.env.REACT_APP_userServiceURL + '/profile/get_by_custom_url?customUrl=' + props.customUrl+'&otherUserId='+userData.userId+userAgentGetData(),
            url: `${process.env.REACT_APP_userServiceURL}/${url}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                setUserDetails(response.data.body);
                setUserDataFetched(true);
                hashTagsCategoryCount(props?.hashTagType === "COMMUNITY" ? response.data.body?.id : response.data.body.userId);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    
    const hashTagsCategoryCount = (userId) => {
       if(props.tagName != "") {
           axios({
               method: "get",
               url: `${process.env.REACT_APP_userServiceURL}/tags/hashtagcategorywisecountbyuser?userId=${userId}&otherUserId=${userData.userId}&hashTag=${props.tagName}`,
               withCredentials: true,
           })
               .then((response) => {
                   if (response && response.data && response.data.status === "200 OK") {
                        setCategoryCountList(response.data.body);
                   }
               })
               .catch((error) => {
                   console.log(error);
               });
       }
    };

    useEffect(() => {
        //hashTagsCategoryCount();
        if(props.customUrl && props.customUrl !== "" && !userDataFetched){
            // getUserDetailsByCustomUrl();
            getEntityDetailsByCustomUrl()
        }
    }, [props.tagName, props.customUrl]);


    return (
        <div className="expHashMainTop expHashSection topCatePart">
            <div className="profpic">
                {props?.hashTagType === "COMMUNITY" ? 

                     <div className="profpicinn">
                        <Link to={`/community/${props?.profileInfo?.slug}`}>
                            {props.profileInfo ?
                                <img
                                    src={(props.profileInfo && props.profileInfo.profileImage) ? getCDNUrl(getOptimizedImage(props.profileInfo, 'profileImage')) : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "https://cdn.dscovr.com/images/circleDefault.png"
                                    }} alt="" /> : null
                            }
                        </Link>
                    </div> 
                    :
                    <div className="profpicinn">
                        <a data-fancybox={uuid()}
                            href={props.profileInfo && props.profileInfo.originalProfileImage && mappingUtils.getPrivacySettingData(props.profileInfo.privacySettingConfigDto, 'profile_picture') ? getCDNUrl(props.profileInfo.originalProfileImage) : props.profileInfo && props.profileInfo.type === 'INDIVIDUAL' ? 'https://cdn.dscovr.com/images/users1.png' : 'https://cdn.dscovr.com/images/DefaultBusiness.png'}>
                            {props.profileInfo ?
                                <img
                                src={props.profileInfo && mappingUtils.getPrivacySettingData(props.profileInfo.privacySettingConfigDto, 'profile_picture') && props.profileInfo.profileImage ? getCDNUrl(getOptimizedImage(props.profileInfo)) : props.profileInfo && props.profileInfo.type === 'INDIVIDUAL' ? 'https://cdn.dscovr.com/images/users1.webp' : 'https://cdn.dscovr.com/images/DefaultBusiness.webp'} onError={(e) => { e.target.onerror = null; e.target.src = props.profileInfo && props.profileInfo.type === 'INDIVIDUAL' ? "https://cdn.dscovr.com/images/users1.png" : 'https://cdn.dscovr.com/images/DefaultBusiness.png' }}
                                alt="" />
                                : null
                            }
                        </a>
                    </div>

                }
                
            </div>
            <div className="expHashMainTopDesc">
                {/* <h1><a href={'/profile-hashtag/'+props.tagName+'/'+props.customUrl}>#{props.tagName}</a></h1> */}
                <h1><a href={`${props?.hashTagType === "COMMUNITY" ? "/community/hashtag" : "/profile-hashtag"}/${props.tagName}/${props.customUrl}`}>#{props.tagName}</a></h1>
                    <div className="tagCategory">
                        <div className="tagCategoryWrap">
                            {categoryCountList.image != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/photos/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'photos' ? ' active' : '')}><i className="iPhotos" />
                                <span className="tCount">{numberShortForm(categoryCountList.image)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'photos' ? ' active' : '')}><i className="iPhotos" /></Link></span>
                            }
                            {categoryCountList.video != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/videos/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'videos' ? ' active' : '')}><i className="iVid" />
                                <span className="tCount">{numberShortForm(categoryCountList.video)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'videos' ? ' active' : '')}><i className="iVid" /></Link></span>
                            }
                            {categoryCountList.article != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/blogs/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'blogs' ? ' active' : '')}><i className="iArticle" />
                                <span className="tCount">{numberShortForm(categoryCountList.article)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'blogs' ? ' active' : '')}><i className="iArticle" /></Link></span>
                            }
                            {getTotalOthers(categoryCountList.ppt,categoryCountList.pdf) != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/documents/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'documents' ? ' active' : '')}><i className="iClip" />
                                <span className="tCount">{getTotalOthers(categoryCountList.ppt,categoryCountList.pdf)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'documents' ? ' active' : '')}><i className="iClip" /></Link></span>
                            }
                            {categoryCountList.projects != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/projects/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'projects' ? ' active' : '')}><i className="iProjects_OL" />
                                <span className="tCount">{numberShortForm(categoryCountList.projects)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'projects' ? ' active' : '')}><i className="iProjects_OL" /></Link></span>
                            }
                            {categoryCountList.studio != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/studios/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'studio' ? ' active' : '')}><i className="iistudio_icon" />
                                <span className="tCount">{numberShortForm(categoryCountList.studio)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'studio' ? ' active' : '')}><i className="iistudio_icon" /></Link></span>
                            }
                            {props?.hashTagType !== "COMMUNITY" && 
                                <>
                                    {/* {categoryCountList.projects != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/projects/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'projects' ? ' active' : '')}><i className="iProjects_OL" />
                                        <span className="tCount">{numberShortForm(categoryCountList.projects)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'projects' ? ' active' : '')}><i className="iProjects_OL" /></Link></span>
                                    }
                                    {categoryCountList.studio != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/studios/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'studio' ? ' active' : '')}><i className="iistudio_icon" />
                                        <span className="tCount">{numberShortForm(categoryCountList.studio)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'studio' ? ' active' : '')}><i className="iistudio_icon" /></Link></span>
                                    } */}
                                    {categoryCountList.forum != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/forums/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'forums' ? ' active' : '')}><i className="iForum_F" />
                                        <span className="tCount">{numberShortForm(categoryCountList.forum)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'forums' ? ' active' : '')}><i className="iForum_F" /></Link></span>
                                    }
                                    {categoryCountList.poll != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/polls/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'polls' ? ' active' : '')}><i className="iPolls_FL" />
                                        <span className="tCount">{numberShortForm(categoryCountList.poll)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'polls' ? ' active' : '')}><i className="iPolls_FL" /></Link></span>
                                    }
                                </>
                            }
                            {getTotalOthers(categoryCountList.others,categoryCountList.audio,categoryCountList.cause,categoryCountList.circle,categoryCountList.feed,categoryCountList.link) != 0 ? <span><Link to={`/${props?.hashTagType !== "COMMUNITY" ? "profile-hashtag" : "community/hashtag"}/others/${props.tagName}/${props.customUrl}`} className={"tagCateBtns" + (props.tagType === 'others' ? ' active' : '')}><i className="iSelect_Multiple" />
                                <span className="tCount">{getTotalOthers(categoryCountList.others,categoryCountList.audio,categoryCountList.cause,categoryCountList.circle,categoryCountList.feed,categoryCountList.link)}</span></Link></span> : <span className="disable"><Link to='javascript:void(0)' className={"tagCateBtns" + (props.tagType === 'others' ? ' active' : '')}><i className="iSelect_Multiple" /></Link></span>
                            }
                        </div>
                    </div>
                </div>
            </div>
    )
} 

export default IndividualHashTagsCategory;