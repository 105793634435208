import React, {PureComponent} from 'react';
import {CapitalizeFirstLetter} from "../utils/StringUtility";
import {getCDNUrl} from "../commons/CommonFunctions";
import {NavLink, Redirect} from "react-router-dom";

class UserSearchItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {}
    }

    redirect = () => {
        document.body.classList.remove('searchDropActive');
        this.setState({redirectToProfile: this.props.item.customUrl});
    }
    
    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    render() {

        return (
            <>
                {this.state.redirectToProfile && <Redirect push to={"/profile/" + this.state.redirectToProfile}/>}
                <li>
                    <a onClick={this.redirect}>
                        <div className="srchpostmain">
                            <figure className="users">
                                <img src={this.props.item.profileImage ?
                                    getCDNUrl(this.getOptimizedImage(this.props.item)) : "https://cdn.dscovr.com/images/users1.webp"}
                                     onError={(e) => {
                                         e.target.onerror = null;
                                         e.target.src = "https://cdn.dscovr.com/images/users1.png"
                                     }}
                                     alt=""/></figure>
                            <div>
                                <h5>{CapitalizeFirstLetter(this.props.item.firstName) + " " + CapitalizeFirstLetter(this.props.item.lastName)}</h5>
                                {this.props.item.persona}
                            </div>
                        </div>
                        {
                            this.props.item.connected &&
                            <div className="srchposttime">
                                Connected
                            </div>
                        }
                    </a>
                </li>
            </>
        );
    }
}

export default UserSearchItem;
