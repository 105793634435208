import React from "react";
import {authService} from "../auth";
import axios from "axios";
import httpService from "../AxiosInterceptors";
import ReactTooltip from "react-tooltip";
import { blockUserMessage } from "./CommonFunctions";
import CleverTapUtils from "./CleverTapUtils";

httpService.setupInterceptors();

class Follow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            followed: props.followed
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({'followed': nextProps.followed});
    }


    updateStatus = (userId, requestingUserId) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/user/purge-cache?userId='+userId+'&requestingUserId='+requestingUserId,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {

             CleverTapUtils.eventClick({}, this.state.followed? "Follow":'UnFollow') ;

        }).catch((err) => {
            console.log(err);
        });
    }

    handleFollowUnfollow = () => {
        let newState = !this.state.followed;
        this.setState({'followed': newState})

        let url;
        if (newState === true) {
            url = process.env.REACT_APP_userServiceURL + '/graph/users/' + this.state.userData.userId + '/follows/' + this.props.userId
        } else {
            url = process.env.REACT_APP_userServiceURL + '/graph/users/' + this.state.userData.userId + '/unfollows/' + this.props.userId
        }
        axios({
            method: 'post',
            url: url,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {
                if (this.props.callback_fn) {
                    this.props.callback_fn();
                }
                if(this.props.getFollow){
                    this.props.getFollow()
                }
                this.updateStatus(this.props.userId,this.state.userData.userId);
            } else {
                this.setState({'followed': !newState},()=>{
                    if(this.props.getConnectionInfo){
                        this.props.getConnectionInfo()
                    }
                })
            }
        }).catch((err) => {
            if(err?.response?.data?.status === '400 BAD_REQUEST' && newState) {
                blockUserMessage("MESSAGE", err.response.data.message);
            }
            this.setState({'followed': !newState})
            console.log(err);
        });
    }

    render() {
        return (
            this.state.loginSuccess && this.state.userData.type === 'INDIVIDUAL' && this.state.userData.userId !== this.props.userId && !this.props.deactivated &&  this.state.followed === false ?
                <a href="javascript:void(0)" className={"user-follow " + (!this.props.popup ? "follow follow-link" : "dropdown-item")} onClick={this.handleFollowUnfollow}> Follow</a>
            : this.state.userData.userId !== this.props.userId && (!this.props.type || this.props.type !== 'FEED') && this.state.followed === true && !this.props.deactivated ?
                <a href="javascript:void(0)" className={"user-following " + (!this.props.popup ? "follow follow-link active" : "dropdown-item")} onClick={this.handleFollowUnfollow}> Following</a>
            : ''
        );

    }
}

export default Follow
