import React, { Component } from 'react';
import axios from "axios";
import {authService} from "../auth";
import {getCDNUrl, userAgentGetData} from "../commons/CommonFunctions";
import DeactivationWithoutConfirmFinalPopup from "./DeactivationWithoutConfirmFinalPopup";
import {Redirect} from "react-router-dom";
import mappingUtils from "../project/component/commons/utils/mappingUtils";


class SelectNamePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            messages: null,
            isLoading: true,
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            data: {},
            message: '',
            success: false,
            config:{},
            userRelatedData:{},
            showProfileImage:null,
            showusername : null,
            previousActivityOriginal:true
        };

    }

    componentDidMount() {
        this.getUserDataByUserId();
    }

    getUserDataByUserId() {
        axios({
            method: "get",
            url: process.env.REACT_APP_userServiceURL + "/profile/get?id=" + this.state.userData.userId+'&otherUserId='+this.state.userData.userId+userAgentGetData(),
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.setState({ userRelatedData: response.data.body },()=>{
                        let profileImage = null;
                        if(this.state.userRelatedData.profileImage){
                           profileImage = getCDNUrl(this.getOptimizedImage(this.state.userRelatedData));
                        }
                        this.setState({showProfileImage:profileImage,showusername:this.state.userRelatedData.userName})
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getDeactivationConfig=()=>{
        this.setState({'isSubmitted': true});
        axios({
            method:'get',
            url:process.env.REACT_APP_userServiceURL + '/deactivation/get/config/'+this.state.userData.userId,
            headers:{ 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response)=>{
            let res = response.data
            if(res && res.status === '200 OK'){
                this.setState({'config': res.body},()=>{
                    if(this.state.config.adminRightRequired){

                    } else{
                        this.handleDeactivationSubmit();
                    }
                });


            } else {
                this.setState({'isSubmitted': false});

            }
        }).catch((err)=>{
            console.log(err)
            this.setState({'isSubmitted': false});
        })
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    handleUsernameSubmit = (event) => {
        event.preventDefault();
       this.getDeactivationConfig();
    };

    handleDeactivationSubmit = () => {
        let postBody = {
            "userId": this.state.userData.userId,
            "reason": this.props.reason,
            "description":this.props.details,
            "previousActivityOriginal": this.state.previousActivityOriginal,
            "adminRights":{}
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/deactivation/deactivate',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                window.$('#deactivationFinalPopUp').modal('show');
            }
        }).catch((err) => {

            if (err && err.response.data) {
                this.setState({
                    isLoaded: true,
                    error: {message: err.response.data.message, err: err.response}
                });

            }
        });

    };

    setUserPreferances = (flag) => {
        let showProfileImage = null;
        let  showusername = null;
        if(flag === true){
            if(this.state.userRelatedData.profileImage){
                showProfileImage = getCDNUrl(this.getOptimizedImage(this.state.userRelatedData));
            }
            showusername = this.state.userRelatedData.userName;
        } else{
            showProfileImage = 'https://cdn.dscovr.com/images/welogo-notificNew.png';
             showusername = 'WeNaturalists member';
        }
        this.setState({'previousActivityOriginal':flag,showProfileImage:showProfileImage,showusername:showusername});
    };



    render() {
        if(this.state.config.adminRightRequired){
            let data = {
                "reason": this.props.reason,
                "description":this.props.details,
                "previousActivityOriginal": this.state.previousActivityOriginal,
            };

            return <Redirect
                to={{
                    pathname: "/profile-deactivate-admin-rights",
                    state: { data:data}
                }}
            />
        } else {
            return <>
                <DeactivationWithoutConfirmFinalPopup/>
                <div id="dltslctnamepop" className="modal fade dgpopup" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="dltprfNameSlctbx">
                                    <p>
                                        On deleting your profile, other members may continue to have access to your past activities on WeNaturalists. But the members will not be able to view your profile. You can choose the name under which you wish to display your activities
                                    </p>
                                    <h4>Please choose a display name</h4>
                                    <form>
                                        <div className="dltnameselect">
                                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown"
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        this.setUserPreferances(true);
                                                    }}>
                                                <figure className="usimg"><img
                                                    src={this.state.showProfileImage ?this.state.showProfileImage : mappingUtils.returnDefaultImage(this.state.userData.type)}
                                                    alt=""/></figure>
                                                <h5>{this.state.showusername}</h5>
                                            </button>
                                            <div className="dropdown-menu fadeIn animated" role="navigation"
                                                 data-mcs-theme="minimal-dark">
                                                {this.state.previousActivityOriginal &&
                                                <a className="dropdown-item" onClick={async (e) => {
                                                    e.preventDefault();
                                                    this.setUserPreferances(false);
                                                }}>
                                                    <figure className="usimg" style={{border:"0",borderRadius:"0"}}>
                                                        <img src="https://cdn.dscovr.com/images/welogo-notificNew.webp" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/welogo-notificNew.png"}} alt=""/>
                                                    </figure>
                                                    <h5>WeNaturalists member</h5>
                                                </a>
                                                }
                                                {!this.state.previousActivityOriginal &&
                                                <a className="dropdown-item" onClick={async (e) => {
                                                    e.preventDefault();
                                                    this.setUserPreferances(true);
                                                }}>
                                                    <figure className="usimg"><img
                                                        src={this.state.userRelatedData.profileImage ? getCDNUrl(this.getOptimizedImage(this.state.userRelatedData)) : mappingUtils.returnDefaultImage(this.state.userData.type)}
                                                        alt=""/></figure>
                                                    <h5>{this.state.userRelatedData && this.state.userRelatedData.userName}</h5>
                                                </a>
                                                }
                                            </div>


                                        </div>
                                        <div className="formbottom">
                                            <button type="button" className="btn btn-primary" data-dismiss="modal"
                                                    onClick={this.handleUsernameSubmit}
                                                    data-toggle="modal">Proceed
                                            </button>
                                            <button type="button" className="btn btn-cancel" data-dismiss="modal" onClick={this.props.refreshState}>Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        }




}

export default SelectNamePopup


