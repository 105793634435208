import React from 'react'
import {authService} from '../auth';
import Header from "../Header";
import StaticHeader from "../StaticHeader";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, loginSuccess: authService.isAuthenticated(),};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }
    }

    render() {
        if (this.state.hasError) {
            return <div className="wrapper projectpage profilepage pabout ppersonal">
                {!this.state.loginSuccess ? <StaticHeader/> : <Header/> }
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="err404contbx bksomethingWrng">
                            <span className="cauton_icon"><i className="iCaution"/></span>
                            <h2>Something went wrong!</h2>
                            <p>Please try after sometime</p>
                            <a href="/" className="bkgobackbtn"><i className="iArrow-Left"/>Go to Home</a><br/>
                            <img src="https://cdn.dscovr.com/images/something-went-wrong.webp"
                                 onError="this.onerror=null;this.src='https://cdn.dscovr.com/images/something-went-wrong.png';"
                                 alt=""/>
                        </div>
                    </div>
                </main>
            </div>
        }

        return this.props.children;
    }
}

export default ErrorBoundary
