import axios from "axios";
import { Apis } from "../config/api";

export const getFundRaiseRestriction = async (data) => {
    try {
        const response = await axios.get(Apis.GetFundRaiseRestriction(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const changeFundRaiseRestrictionPermission = async (postData) => {
    try {
        const response = await axios.post(Apis.ChangeFundRaiseRestrictionPermission, postData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const checkFundRaiseValidPermission = async (data) => {
    try {
        const response = await axios.get(Apis.CheckFundRaiseValidPermission(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const fundRaiseRestrictionIntimationAdmin = async (data) => {
    try {
        const response = await axios.get(Apis.FundRaiseRestrictionIntimationAdmin(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const fundRaiseRestrictionIntimationMember = async (data) => {
    try {
        const response = await axios.get(Apis.FundRaiseRestrictionIntimationMember(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}