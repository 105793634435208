import React, {Component} from 'react';
import {convertUnicode, getCDNUrl} from "../commons/CommonFunctions";

class CircleSearchItem extends Component {

    getOptimizedImage(circleInfo) {
        if (circleInfo.resizedProfileImages && circleInfo.resizedProfileImages['300x300'] != null) {
            return circleInfo.resizedProfileImages['300x300'];
        }
        return circleInfo.profileImage;
    }

    redirect = () => {
        window.location.href = '/community/' + this.props.item.slug
    }

    render() {

        return (
            <li>
                <a href="javascript:void(0)" onClick={this.redirect}>
                    <div className="srchpostmain">
                        <figure className="users">
                            <img
                                src={this.props.item.profileImage ? getCDNUrl(this.getOptimizedImage(this.props.item)) : 'https://cdn.dscovr.com/images/circleDefault.webp'} onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/circleDefault.png"}}
                                alt=""/>
                        </figure>
                        <div>
                            <h5>{convertUnicode(this.props.item.title)}</h5>
                        </div>
                    </div>
                    <div className="srchposttime">
                        {
                            this.props.item.location
                        }
                    </div>
                </a>
            </li>
        );
    }
}

export default CircleSearchItem;
