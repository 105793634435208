import React from "react";
import {authService} from "../auth";
import axios from "axios";
import httpService from "../AxiosInterceptors";
import cloneDeep from "lodash/cloneDeep";
import {CapitalizeFirstLetter} from "../utils/StringUtility";
import AesUtil from "./AesUtil";
import MentionTextArea from "./MentionTextArea";
import * as _ from "lodash";
import { blockUserMessage } from "./CommonFunctions";

httpService.setupInterceptors();
let aesUtil = new AesUtil();
let messageEncryptKey = process.env.REACT_APP_messageEncryptKey;
let stompClientMessage = null;
let connectionEstablished = false;

class SendMessageModal extends React.Component {

    constructor(props) {
        super(props);
        let validation = {
            messageDescription:{}
        }
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            messageDescription: '',
            validation,
            errors:{}
        }
        this.webSocketConnect();
    }

    componentDidMount() {
        window.$('#messageModal' + this.props.profileId).modal('show');
    }

    componentWillUnmount() {
        this.hideMessageModal();
    }

    hideMessageModal = () => {
        window.$('#messageModal' + this.props.profileId).modal('hide');
        this.setState({messageDescription: '', validation:{}, errors:{}});
        if (this.props.triggerMessageModalClose) {
            this.props.triggerMessageModalClose();
        }
    }

    handleMessageTextChange = (event) => {
        this.setState({messageDescription: event.target.value});
    }

    webSocketConnect = () => {
        if (stompClientMessage !== undefined && stompClientMessage !== null) {
            return false;
        }

        this.socketServerConnect('');

    };

    socketServerConnect(token) {
        const Stomp = require('stompjs')
        stompClientMessage = Stomp.client(process.env.REACT_APP_socketURL);
        stompClientMessage.debug = null;

        stompClientMessage.reconnect_delay = 5000;
        stompClientMessage.heartbeat.outgoing = 20000; // default 10000ms
        stompClientMessage.heartbeat.incoming = 20000;

        if (token !== null) {
            var headers = {"X-Authorization": token};
            stompClientMessage.connect(headers, this.onConnected, this.onError);
        }
    }

    onConnected = () => {
        connectionEstablished = true;
    }

    onError = (e) => {
        console.log(e);
        if (typeof this.reConnectWebSocket === 'function') {
            if (stompClientMessage !== undefined && stompClientMessage !== null) {
                stompClientMessage.disconnect(function () {
                    console.log("Existing connection disconnected");
                });
            }
            stompClientMessage = null;
            this.reConnectWebSocket();
        }
    }

    reConnectWebSocket() {
        console.log('Reconnecting.....');

        if (typeof this.webSocketConnect === 'function') {
            setTimeout(() => {
                this.webSocketConnect();
            }, 5000);
        }
    }
    validateForm() {
        this.state.validation.messageDescription = {};
        let validationRequired = false;
        let errors = {};
        let formIsValid = true;
        if (this.state.messageDescription.trim() === '') {
            if (_.isUndefined(this.state.messageDescription.trim()) ||
                _.isEmpty((this.state.messageDescription.trim() || "").toString()) ||
                _.isNull(this.state.messageDescription.trim())) {
                errors["messageDescription"] = "Please enter a message";
                this.state.validation.messageDescription = {
                    error: true,
                    style: {borderColor: "#b34c4c"}
                }
                this.setState({isLoading: false})
                formIsValid = false;
                validationRequired = true;
            }
        } else {
            this.state.validation.messageDescription = {};
            if (
                _.isUndefined(this.state.messageDescription.trim()) ||
                _.isEmpty((this.state.messageDescription.trim() || "").toString()) ||
                _.isNull(this.state.messageDescription.trim()) ||
                this.state.messageDescription.trim().split(/\s+/).length > 100
            ) {
                errors["messageDescription"] = "Please enter message less than 100 words";
                this.state.validation.messageDescription = {
                    error: true,
                    message: 'Please enter message less than 100 words',
                    style: {borderColor: "#b34c4c", padding: "10px"}
                }
                this.setState({isLoading: false})
                formIsValid = false;
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }


    sendMessage = (event) => {
        event.preventDefault();
        if(this.validateForm()){
            if (this.state.messageDescription && this.state.messageDescription.trim() !== '') {

                let chatMessage = {
                    "id": new Date().getTime() + '' + Math.floor(Math.random() * 10),
                    "message": this.state.messageDescription,
                    "senderId": this.state.userData.userId,
                    "messageGroupType": 'Private',
                    "receiverId": this.props.profileId,
                    "createTime": new Date().getTime(),
                    "star": [],
                    "attachment": false,
                    "msgSentStatus": 1
                };

                let messageObj = cloneDeep(chatMessage);
                //messageObj.message = aesUtil.encrypt(messageEncryptKey, messageObj.message);

                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_userServiceURL}/messages/post`,
                    data: messageObj,
                    withCredentials: true
                }).then(response => {
                    if (response && response.data.body !== null) {
                        this.hideMessageModal();
                        if (stompClientMessage) {
                            stompClientMessage.send("/app/" + this.props.profileId + "/chat.sendMessage", {}, JSON
                                .stringify(chatMessage));
                        }
                    } else {
                        if(response.data.message && response.data.message === 'User is Blocked'){
                            this.setState({
                                validation: {
                                    message : { 
                                       messageDescription: "Unblock "+CapitalizeFirstLetter(this.props.userName)+" to send a message"
                                    }
                                }
                            })
                        }
                        
                    }
                }).catch((err) => {
                    console.log(err);
                    if(err?.response?.data?.status === '401 UNAUTHORIZED') {
                        this.hideMessageModal();
                        blockUserMessage("MESSAGE", err.response.data.message);
                    }
                });

            }
        }
        setTimeout(() => {
            this.setState({
                errors:{},
                validation:{}
            })
        }, 5000);
    }

    render() {

        return (<div id={"messageModal" + this.props.profileId} className="modal fade dgpopup messageModal reqendorse"
                     tabIndex="-1" data-backdrop="static">
            <div className="modal-dialog likesblk">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="forwcontwrap">
                            <div className="forwcontwraptop">
                                <h4>Message to {CapitalizeFirstLetter(this.props.userName)}</h4>
                                <MentionTextArea
                                    className="form-control custom-input"
                                    value={this.state.messageDescription}
                                    onChange={this.handleMessageTextChange}
                                    placeholder="Write a message...">
                                </MentionTextArea>
                                <span className="login-feedback-error mt-1"
                                      style={{display: "block"}}>{this.state.errors.messageDescription}</span>
                                <span
                                    className="login-feedback-error mt-1">{this.state.validation.message && this.state.validation.message.messageDescription}</span>
                            </div>
                            <div className="forwcontwrapbott">
                                <div className="d-flex">
                                    <button type="button" className="btn btn-primary"
                                            onClick={this.sendMessage}>Send
                                    </button>
                                    <button className="butt btnsecondary close"
                                            id="forwardMessageModalCloseBtn"
                                            onClick={this.hideMessageModal}>Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);

    }
}

export default SendMessageModal
