import React, { Component } from "react";
import { detailsPageRedirection, getEnviormentRegex } from "../commons/CommonFunctions";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from "react-player";
import axios from "axios";
import * as _ from "lodash";
import CommonLinkPreview from "../commons/CommonLinkPreview";
import { Waypoint } from "react-waypoint";

class LinkUnauthItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linkData: {},
            shouldPlay: false
        }
    }

    handleEnterViewport = () => {
        this.setState({
            shouldPlay: true,
        })
    };
    handleExitViewport = () => {
        this.setState({
            shouldPlay: false
        })
    };

    getLinkPreviewUrl = (text) => {
        let data = {
            url: text
        }
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/recommend/ogscrapper`,
            data: data,
            withCredentials: true
        }).then((response) => {
            if (response?.data?.status === 200) {
                this.setState({
                    linkData: response?.data?.data
                })
            }
        }).catch((error) => {
            console.log("Error", error);
        })
    };

    isValidUrl = (str) => {
        let regexp =
            /^(https?|ftp):\/\/(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        return regexp.test(str);
    };

    componentDidMount() {
        this.getLinkPreviewUrl(this.props.item.postLinkTypeUrl);
    }

    render() {
        return (
            <a href="javascript:void(0)" onClick={(e) => detailsPageRedirection(this.props.item?.id)}>
                <div className="hpostconttop">
                    {this.props.item && (this.props.item.description || (this.props.item.hashTags && this.props.item.hashTags.length > 0)) &&
                        <ReadMoreReadLess id={this.props.item.id} description={this.props.item.description} hashTags={this.props.item.hashTags} />
                    }
                    {this.props.item.hashTags && this.props.item.hashTags.length > 0 && (
                        <div className="hposthash" style={{ padding: '0 15px' }}>
                            {this.props.item.hashTags.map((item) => {
                                return (
                                    <div key={item} className="hposthashitem">
                                        <a href={"/explore-hashtag/" + item}>
                                            <i className="iHashtag" />
                                            {item}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {this.props.item.postLinkTypeUrl &&
                        this.isValidUrl(this.props.item.postLinkTypeUrl) && (
                            <>
                                <div className="linkpostpreview">
                                    <div className="postarticledesc">
                                        {this.props.item.postLinkTypeUrl.indexOf(
                                            "https://youtu.be"
                                        ) !== -1 ||
                                            this.props.item.postLinkTypeUrl.indexOf(
                                                "www.youtube.com/watch"
                                            ) !== -1 ? (
                                            <>
                                                <div className="vidPlayerOverWrap">
                                                    <Waypoint onEnter={() => this.handleEnterViewport()} onLeave={() => this.handleExitViewport()} scrollableAncestor='window'>
                                                        <div>
                                                            <ReactPlayer
                                                                url={this.props.item.postLinkTypeUrl}
                                                                width={"100%"}
                                                                playing={this.state.shouldPlay}
                                                                controls={false}
                                                                muted={true}
                                                            />
                                                        </div>
                                                    </Waypoint>
                                                    <span
                                                        className="vidOverlay"
                                                        onClick={(e) => this.redirectToPost()}
                                                    ></span>
                                                </div>
                                                <div className="customLinkPost" onClick={() => {
                                                    window.location.href = this.props.item.postLinkTypeUrl
                                                }}>
                                                    <div className="customLinkPostDesc">
                                                        <h5>{this.state.linkData?.title}</h5>
                                                        <p>{this.state.linkData?.description}</p>
                                                        {/* <p>{this.props.item.postLinkTypeUrl}</p> */}
                                                    </div>
                                                </div>
                                                <a
                                                    href={this.props.item.postLinkTypeUrl}
                                                    className="btn playYoutube"
                                                    target="_blank"
                                                >
                                                    <i className="iYouTube_Logo" /> Play on
                                                    Youtube
                                                </a>
                                            </>
                                        ) : (!_.isEmpty(this.state.linkData) &&  
                                            <CommonLinkPreview linkdata={this.props.item.postLinkTypeUrl} og_data={this.state.linkData} />
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                </div>
            </a>
        );
    }
}

export default LinkUnauthItem;