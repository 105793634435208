import React, { PureComponent } from "react";
import { authService } from "../auth";
import axios from "axios";
import { NavLink, Redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import httpService from "../AxiosInterceptors";
import { requestPostCreationFulfilled } from "../reducer/post";
import { connect } from "react-redux";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import ReportAbuse from "../commons/ReportAbuse";
import TimeAgo from "javascript-time-ago";
import { getCDNUrl, pointCalculationUpdate, postNotFoundFunction, getEnviormentRegex } from "../commons/CommonFunctions";

import Follow from "../commons/Follow";
import stripHtml from "string-strip-html";
// English.
import * as _ from "lodash";
import en from "javascript-time-ago/locale/en";
import MentionTextArea from "../commons/MentionTextArea";
import ReactPlayer from "react-player";
import AutoSuggest from "../AutoSuggest";
import EventMessageSection from "../commons/EventMessageSection";
import SavePostButton from "./SavePostButton";
import PinPostButton from "./PinPostButton";
import FeaturePostButton from "./FeaturePostButton";
import CommonLinkPreview from "../commons/CommonLinkPreview";
import CommonPostBottomSection from "./CommonPostBottomSection";
import { Waypoint } from "react-waypoint";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

httpService.setupInterceptors();

class LinkPostItem extends PureComponent {
  constructor(props) {
    super(props);
    let validation = {
      urlLink: {},
      postText: {},
    };
    this.state = {
      userData: authService.getUserDetailsFromLocalStorage(),
      description: props.item.description,
      editedText: props.item.description,
      postLinkTypeUrl: props.item.postLinkTypeUrl,
      editedUrl: props.item.postLinkTypeUrl,
      validUrlLinkPreview: props.item.postLinkTypeUrl,
      canNotUpdate: false,
      hashTags: this.props.item.hashTags ? this.props.item.hashTags : [],
      tags: [],
      validation,
      errors: {},
      og_data1: {},
      og_data2:{},
      editLinkPopUpShow: false,
      shouldPlay: true
    };
  }

  handleEnterViewport = () => {
    this.setState({
        shouldPlay: true,
    })
  };
  handleExitViewport = () => {
      this.setState({
          shouldPlay: false
      })
  };

  getLinkPreviewUrl = (text, type) => {
        let data = {
            url : text
        }
        axios({
            method: 'POST',
            url : `${process.env.REACT_APP_userServiceURL}/recommend/ogscrapper`,
            data: data,
            withCredentials: true
        }).then((response)=>{
            if(response?.data?.status === 200){
                this.setState({
                  [type === "PREVIEWLINK1" ? "og_data1" : "og_data2"]: response?.data?.data
                })
            }
        }).catch((error)=>{
            console.log("Error",error);
        })
  };

  componentDidMount() {
    let tags = [];
    this.props.item && this.props.item.hashTags && this.props.item.hashTags.map((data, index) => {tags.push(data)});
    this.setState({ tags: tags })
    this.getLinkPreviewUrl(this.state.postLinkTypeUrl, "PREVIEWLINK2");
  }

  deletePost = () => {
    this.setState({ processingDeleteRequest: true });
    axios({
      method: "post",
      url:
        process.env.REACT_APP_userServiceURL +
        "/backend/post/delete/" +
        this.props.item.id,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.status === 202) {
          this.props.requestPostCreationFulfilled({ success: true });
          pointCalculationUpdate(this.state.userData.userId);
          window
            .$("#" + this.props.item.id + "confirmpopForDeletePost")
            .modal("hide");
          this.setState({ processingDeleteRequest: false });
          if (this.props.callbackFunc) {
            this.props.callbackFunc();
          }
        } else {
          this.setState({ processingDeleteRequest: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ processingDeleteRequest: false });
      });
  };

  redirect(id) {
    window.location = "/post/" + id;
  }

  openDeleteModal = () => {
    window
      .$("#" + this.props.item.id + "confirmpopForDeletePost")
      .modal("show");
  };

  componentWillUnmount() {
    window.$("#" + this.props.item.id + "confirmpopForDeletePost").modal("hide");
    window.$("#" + this.props.item.id + "toppostwrapLink").modal("hide");
    window.$("#AcceptLink"+ this.props.item.id).modal("hide");
  }

  triggerReportClose = () => {
    this.setState({ openReportModal: null });
  };

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.userProfileImage;
  }

  openEditLinkPost = () => {
    this.setState({
      editLinkPopUpShow: true
    },() => {
      this.getLinkPreviewUrl(this.state.postLinkTypeUrl, "PREVIEWLINK1");
      window.$("#" + this.props.item.id + "toppostwrapLink").modal("show");
    })
  };

  hideUpdateModal = () => {
    this.setState({
      editedText: this.props.item.description,
      editedUrl: this.props.item.postLinkTypeUrl,
      canNotUpdate: false,
      editLinkPopUpShow: false,
      validUrlLinkPreview: this.props.item.postLinkTypeUrl
    });
    window.$("#" + this.props.item.id + "toppostwrapLink").modal("hide");
  };

  handleUrlUpdate = (event) => {
    this.setState({
      validUrlLinkPreview: "",
      editedUrl: event.target.value,
      canNotUpdate: true,
    });
    let value = event.target.value;
    if (this.isValidUrl(value)) {
      setTimeout(
        (value) => {
          this.setState({
            validUrlLinkPreview: value,
            canNotUpdate: false,
          },()=>{
            this.getLinkPreviewUrl(this.state.validUrlLinkPreview, "PREVIEWLINK1")
          });
        },
        2000,
        value
      );
    }
  };
  isValidUrl = (str) => {
    let regexp =
      /^(https?|ftp):\/\/(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(str);
  };

  check_words = (e) => {
    const BACKSPACE = 8;
    const DELETE = 46;
    const MAX_WORDS = 400;
    const valid_keys = [BACKSPACE, DELETE];
    const words = this.value && this.value.split(" ");

    if (
      words &&
      words.length >= MAX_WORDS &&
      valid_keys.indexOf(e.keyCode) === -1
    ) {
      e.preventDefault();
      words.length = MAX_WORDS;
      this.value = words.join(" ");
    }
  };

  validateForm = () => {
    this.state.validation.postText = {};
    let errors = {};
    let formIsValid = true;
    var regex = /\s+/gi;
    if (
      stripHtml(this.state.editedText).trim().replace(regex, " ").split(" ")
        .length > 400
    ) {
      errors["postText"] = "Please enter upto 400 words";
      this.state.validation.postText = {
        error: true,
        message: "Please enter upto 400 words",
        style: { borderColor: "#b34c4c", padding: "10px" },
      };
      formIsValid = false;
    }
    if (
      !this.state.editedUrl ||
      this.state.editedUrl === "" ||
      !this.isValidUrl(this.state.editedUrl)
    ) {
      errors["urlLink"] = "Please enter a valid url";
      formIsValid = false;
    }
    this.setState(
      {
        errors: errors,
      },
      () => {
        setTimeout(() => {
          this.setState({
            error: "",
            errors: {},
          });
        }, 5000);
      }
    );
    return formIsValid;
  };

  handlePostTextUpdate = (event) => {
    this.setState({ editedText: event.target.value });
    this.check_words(event);
  };

  handlePostUpdate = () => {
    if (this.validateForm()) {
      this.setState({ isPostUpdating: true });
      const formData = new FormData();
      formData.append("postId", this.props.item.id);
      formData.append("description", this.state.editedText && this.state.editedText.replace(/(http(s))/g, " $1").trim("\n"));
      formData.append(
        "hashTags",
        this.state.tags
          ? this.state.tags.map((item) => {
              return item.replace(/#/g, "");
            })
          : []
      );
      formData.append("postLinkTypeUrl", this.state.editedUrl);
      axios({
        method: "post",
        url: process.env.REACT_APP_userServiceURL + "/backend/post/edit",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (response && response.status === 202) {
            window.$("#" + this.props.item.id + "toppostwrapLink").modal("hide");
            this.setState({
              description: this.state.editedText,
              postLinkTypeUrl: this.state.editedUrl,
              isPostUpdating: false,
              hashTags: this.state.tags,
              editLinkPopUpShow: false
            },()=>{
                this.getLinkPreviewUrl(this.state.postLinkTypeUrl, "PREVIEWLINK2")
            });
            window.$("#AcceptLink"+ this.props.item.id).modal("show");
            setTimeout(() => {
              window.$("#AcceptLink"+ this.props.item.id).modal("hide");
            }, 60000);
            //                    window.$('#' + this.props.item.id + "toppostwrapLink").modal('hide');
          } else {
            this.setState({ isPostUpdating: false });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isPostUpdating: false });
          if(err.response.data.status === "404 NOT_FOUND") {
            postNotFoundFunction(this.props.item.id);
          }
        });
    }
    setTimeout(() => {
      this.setState({
        error: "",
        errors: {},
      });
    }, 5000);
  };
  handleTagsChange = (tags) => {
    let hashTagsList = [];
    if (tags && tags.length > 0) {
      tags &&
        tags.map(function (data) {
          data = data.replace(
            /[`~!@$%^&*()|+\=?;:'",.#<>\{\}\[\]\\\/\s]/gi,
            ""
          );
          if (data != "") {
            hashTagsList.push(data);
          }
        });
    }
    this.setState({ tags: hashTagsList });
  };

  onDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  onAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  }

  redirectToProfile = () => {
    this.setState({ redirectToProfile: true });
  };

  redirectToCircle = (customUrl) => {
    this.setState({ redirectToCircle: customUrl });
  };

  handleHideModal = (id) => {
    let data = {
      userId: this.state.userData.userId,
      activityId: id,
      entityType: "POST",
    };

    axios({
      method: "post",
      url: process.env.REACT_APP_userServiceURL + "/hidden/hide",
      data: data,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.status === 201) {
          //this.props.requestPostCreationFulfilled({ success: true, hideFlag: true });
          this.props.removePostListItem(id);
          if (this.props.callbackFunc) {
            this.props.callbackFunc();
          }
        } else {
          this.setState({ isSubmitted: false });
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 409) {
          this.setState({
            error: "Entity has already been hidden",
            isSubmitted: false,
          });
        } else {
          this.setState({ isSubmitted: false });
        }
      });
  };

  showUser = () => {
    window.location.href = "/post/" + this.props.item.id;
  };

  redirectToPost = () => {
    window.location.href = "/post/" + this.props.item.id;
  };

  refreshAllData = () => {
    this.props.requestPostCreationFulfilled({ success: true });
  };

  render() {
    var utcSeconds = this.props.item.createTime;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds / 1000);
    const linkTarget = {
      pathname: "/profile/" + this.props.item.userCustomUrl,
      key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
      state: {
        applied: true,
      },
    };
    const eventLinkTarget = {
      pathname: "/profile/" + this.props.item.eventCustomUrl,
      key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
      state: {
        applied: true,
      },
    };
    const postTarget = {
      pathname: "/post/" + this.props.item.id,
      key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
      state: {
        applied: true,
      },
    };
    var regex = /\s+/gi;
    let Stripbody = stripHtml(this.state.editedText)
      .trim()
      .replace(regex, " ")
      .split(" ").length;
    return (
      <>
        {this.state.redirectToProfile && (
          <Redirect push to={"/profile/" + this.props.item.userCustomUrl} />
        )}
        {this.state.redirectToCircle && (
          <Redirect push to={"/community/" + this.state.redirectToCircle} />
        )}
        <div className={`hpostblock` + (this.props.item.nominated && this.props.postSection === "FEED" ? ' pNominate' : '')}>
          <div className="hpostblockinn">
            {this.props.item && this.props.item.eventMessage && (
              <EventMessageSection item={this.props.item} />
            )}
            {this.props.item.pinned && <span className='pinnedPost'><i className="iPin" style={{ fontSize: '14px' }} /></span>}
            <div className="hposthead">
              {!this.props.item.userEntityType ? (
                <figure
                  className="userimg"
                  onClick={(e) => {
                    this.redirectToProfile();
                  }}
                >
                  <img
                    src={
                      this.props.item.userProfileImage
                        ? getCDNUrl(this.getOptimizedImage(this.props.item))
                        : this.props.item.userType !== "COMPANY"
                        ? "https://cdn.dscovr.com/images/users1.png"
                        : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                    }
                    alt=""
                  />
                </figure>
              ) : (
                <figure
                  className="userimg"
                  onClick={(e) => {
                    e.preventDefault();
                    this.redirectToCircle(
                      this.props.item &&
                        this.props.item.params &&
                        this.props.item.params["circleSlug"]
                    );
                  }}
                >
                  <img
                    src={
                      this.props.item &&
                      this.props.item.params &&
                      this.props.item.params["circleImage"] != null
                        ? getCDNUrl(
                            this.props.item.params &&
                              this.props.item.params["circleImage"]
                          )
                        : "https://cdn.dscovr.com/images/circleDefault.webp"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://cdn.dscovr.com/images/circleDefault.png";
                    }}
                    alt=""
                  />
                </figure>
              )}
              {!this.props.item.userEntityType ? (
                <div className="d-flex">
                  <h3>
                    <NavLink style={{ textDecoration: "none" }} to={linkTarget}>
                      {this.props.item.userName}
                    </NavLink>
                    {this.props.item.connectDepth ? (
                      this.props.item.connectDepth === 1 ? (
                        <span className="userconnect first">1st</span>
                      ) : this.props.item.connectDepth === 2 ? (
                        <span className="userconnect">2nd</span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </h3>

                  {this.props.item && !this.props.item.deactivated && 
                    (!this.props.item.connectDepth ||
                      (this.props.item.connectDepth &&
                        this.props.item.connectDepth < 1)) && (
                      <Follow
                        followed={this.props.item.followed}
                        userId={this.props.item.userId}
                        callback_fn={() =>
                          this.props.callback_fn(this.props.item.userId)
                        }
                      />
                    )}
                </div>
              ) : (
                <div className="d-flex">
                  <h3
                    onClick={(e) => {
                      e.preventDefault();
                      this.redirectToCircle(
                        this.props.item.params &&
                          this.props.item.params["circleSlug"]
                      );
                    }}
                  >
                    {this.props.item.params["circleTitle"]}
                  </h3>
                </div>
              )}
              {this.props.item && this.props.item.persona &&
                <div className="hpostPersona">{this.props.item.persona}</div>}
              <div className="hpostdtls">
                {this.props.item && this.props.item.country && (
                  <span className="address">
                    <i className="iLocation" />
                    {this.props.item && this.props.item.country}
                  </span>
                )}

                <span>
                  <NavLink to={postTarget}>
                    {timeAgo.format(d, "mini-now")}
                  </NavLink>
                </span>
              </div>
            </div>

            <div className="contxmenu">
              <button
                type="button"
                className="btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="iKebab" />
              </button>
              <div className="dropdown-menu fadeIn animated">
                <SavePostButton key={this.props.item.id} id={this.props.item.id} bookMarkFlag={this.props.item.bookMark} entityType={"POST"} />
                <PinPostButton key={this.props.item.id} item={this.props.item} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} />
                <FeaturePostButton key={this.props.item.id} item={this.props.item} />
                {this.props.item.canEdit && (
                  <>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        this.openEditLinkPost();
                      }}
                      href="javascript:void(0)"
                    >
                      <i className="iEditBox" /> Edit Post
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        this.openDeleteModal();
                      }}
                      href="javascript:void(0)"
                    >
                      <i className="iTrashBin" /> Delete Post
                    </a>
                  </>
                )}
                <span
                  className="dropdown-item"
                  onClick={(e) => {
                    this.handleHideModal(this.props.item.id);
                  }}
                >
                  <i className="iHide" style={{ fontSize: "13px" }} /> Hide
                </span>
                {this.state.userData.userId !== this.props.item.userId &&
                  this.props.item.canReport && (
                    <a
                      className="dropdown-item"
                      href="javascript:void(0)"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ openReportModal: this.props.item.id });
                      }}
                    >
                      <i className="iReportComment_OL" /> Report
                    </a>
                  )}
              </div>

              {this.state.openReportModal &&
                this.state.openReportModal === this.props.item.id && (
                  <ReportAbuse
                    entityId={this.props.item.id}
                    entityType={"POST"}
                    postSection={this.props.postSection}
                    currentTab={this.props.currentTab}
                    removePostListItem={this.props.removePostListItem}
                    //refreshAllData={this.refreshAllData}
                    triggerReportClose={this.triggerReportClose}
                  />
                )}
            </div>

            <div className="hpostcontent link">
              {this.props.item.postLinkTypeUrl &&
                this.props.item.postLinkTypeUrl !== "" && (
                  <div className="hpostconttop">
                    {((this.state.description &&
                      this.state.description !== "") ||
                      this.state.hashTags) && (
                      <ReadMoreReadLess
                        id={this.props.item.id}
                        description={this.state.description}
                        hashTags={this.state.hashTags}
                        item={this.props.item}
                      />
                    )}

                    {this.state.postLinkTypeUrl && !this.state.editLinkPopUpShow &&
                      this.isValidUrl(this.state.postLinkTypeUrl) && (
                        <>
                          <div className="linkpostpreview">
                            {/*<div className="prevtitle">
                                                Preview
                                            </div>*/}
                            <div className="postarticledesc">
                              {this.state.postLinkTypeUrl.indexOf(
                                "https://youtu.be"
                              ) !== -1 ||
                              this.state.postLinkTypeUrl.indexOf(
                                "www.youtube.com/watch"
                              ) !== -1 ? (
                                <>
                                  <div className="vidPlayerOverWrap">
                                    <Waypoint onEnter={() => this.handleEnterViewport()} onLeave={() => this.handleExitViewport()} scrollableAncestor='window'>
                                      <div>
                                        <ReactPlayer
                                          url={this.state.postLinkTypeUrl}
                                          width={"100%"}
                                          playing={this.state.shouldPlay}
                                          controls={true}
                                          muted={true}
                                        />
                                      </div>
                                    </Waypoint>
                                    <span
                                      className="vidOverlay"
                                    ></span>
                                  </div>
                                  <div className="customLinkPost" onClick={()=>{ window.open(this.state.postLinkTypeUrl, "_blank")}}>
                                      <div className="customLinkPostDesc">
                                        <h5>{this.state.og_data2?.title}</h5>
                                        <p>{this.state.og_data2?.description}</p>
                                        {/* <p>{this.state.postLinkTypeUrl}</p> */}
                                      </div>
                                  </div>
                                  <a
                                    href={this.state.postLinkTypeUrl}
                                    className="btn playYoutube"
                                    target="_blank"
                                  >
                                    <i className="iYouTube_Logo" /> Play on
                                    Youtube
                                  </a>
                                </>
                              ) : (!_.isEmpty(this.state.og_data2) &&  
                                <CommonLinkPreview linkdata={this.state.postLinkTypeUrl} og_data={this.state.og_data2} />
                              )}
                            </div>
                          </div>
                        </>
                      )}

                    {this.props.item.postLocation && (
                      <span className="address">
                        <i className="iLocation" />
                        {this.props.item.postLocation}
                      </span>
                    )}
                  </div>
                )}

              <CommonPostBottomSection key={this.props.item.id} item={this.props.item} enablePostSharing={this.props.enablePostSharing} postSection={this.props.postSection} />

            </div>
            <div
              className="modal fade dgpopup confirmpop"
              id={"AcceptLink"+ this.props.item.id}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="confirm-popup-modal-body">
                    Your post is updated Successfully
                  </div>
                  <div className="modal-footer confirm-popup-modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        this.showUser();
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div
          className="modal fade dgpopup confirmpop"
          id={this.props.item.id + "confirmpopForDeletePost"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Delete Post Confirmation</h5>                                
                            </div> */}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="confirm-popup-modal-body">
                Are you sure you want to delete this post?
              </div>
              <div className="modal-footer confirm-popup-modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.processingDeleteRequest}
                  onClick={() => this.deletePost()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.editLinkPopUpShow && 
          <div id={this.props.item.id + "toppostwrapLink"} className="modal fade dgpopup openPostbox" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  {
                    <section className="linkpostwrap">
                      <div className="toppostinn">
                        <div className="topposthead">
                          <i className="ilinkico">
                            <img
                              src="https://cdn.dscovr.com/images/link-post-ico.webp"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://cdn.dscovr.com/images/link-post-ico.png";
                              }}
                              alt=""
                            />
                          </i>
                        </div>
                        <div className="blogpostbody">
                          <div className="linkpostcontwrap">
                            <form action="">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control linkfield"
                                  value={this.state.editedUrl}
                                  onChange={this.handleUrlUpdate}
                                  placeholder="type or paste your URL here..."
                                />
                                <span
                                  className="login-feedback-error mt-1"
                                  style={{ display: "block" }}
                                >
                                  {this.state.errors.urlLink}
                                </span>
                              </div>
                              <MentionTextArea
                                value={this.state.editedText}
                                onChange={this.handlePostTextUpdate}
                                placeholder="Add Comment"
                              ></MentionTextArea>
                              <span
                                className="login-feedback-error mt-1"
                                style={{ display: "block" }}
                              >
                                {this.state.errors && this.state.errors.postText}
                              </span>
                              <div className="toppostpopbott">
                                <div className="tagfield">
                                  <AutoSuggest
                                    hashTags={this.state.tags}
                                    onDelete={this.onDelete}
                                    onAddition={this.onAddition}
                                    handleTagsChange={this.handleTagsChange}
                                  />
                                </div>
                              </div>
                              <div className="linkpostpreview">
                                <div className="prevtitle">Preview</div>
                                <div className="postarticledesc">
                                  {(!_.isEmpty(this.state.og_data1) &&  
                                    <CommonLinkPreview linkdata={this.state.validUrlLinkPreview} og_data={this.state.og_data1} />
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="bottpostpopbody">
                          <div className="bottpostpopright">
                            {Stripbody > 400 ? (
                              <></>
                            ) : (
                              <div className="charechterinfo">
                                {400 - Stripbody}{" "}
                                {400 - Stripbody <= 1 ? "word " : "words "}
                                left
                              </div>
                            )}
                            <a
                              href="javascript:void(0)"
                              className="btn cancel"
                              onClick={this.hideUpdateModal}
                            >
                              Cancel
                            </a>
                            <input
                              type="submit"
                              onClick={this.handlePostUpdate}
                              className="btn"
                              value={
                                this.state.isPostUpdating
                                  ? "Loading..."
                                  : "UPDATE"
                              }
                              disabled={this.state.canNotUpdate}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestPostCreationFulfilled: (value) =>
      dispatch(requestPostCreationFulfilled(value)),
  };
};

export default connect(null, mapDispatchToProps)(LinkPostItem);
