import React, {Component} from 'react';

class UnAuthSharePostItem extends Component {
    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default UnAuthSharePostItem;