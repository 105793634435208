import React, { Component } from "react";
import { detailsPageRedirection, getCDNUrl } from "../commons/CommonFunctions";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

class AudioUnauthItem extends Component {
    constructor(props) {
        super(props);
        this.state={

        }
    }
    
    render() {
        return (
            <a href="javascript:void(0)" onClick={(e) => detailsPageRedirection(this.props.item?.id)}>
                <div className="hpostconttop">
                    {this.props.item && (this.props.item.description || (this.props.item.hashTags && this.props.item.hashTags.length > 0)) &&
                        <ReadMoreReadLess id={this.props.item.id} description={this.props.item.description} hashTags={this.props.item.hashTags} />
                    }
                    {this.props.item.attachmentIds && this.props.item.attachmentIds[0] && this.props.item.attachmentIds[0].attachmentUrl &&
                        <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                            <AudioPlayer
                                showJumpControls={false}
                                customVolumeControls={[]}
                                customAdditionalControls={[]}
                                showDownloadProgress={false}
                                src={getCDNUrl(this.props.item.attachmentIds[0].attachmentUrl)}
                            />
                        </div>
                    }
                </div>
            </a>
        );
    }
}

export default AudioUnauthItem;