import React, { Component } from "react";
import { authService } from "../auth";
import axios from "axios";
import { blockUserMessage, nFormatter } from "../commons/CommonFunctions";
import ReactTooltip from "react-tooltip";
import { userReactionClassName, userReactionIcon, userReactionTypeList } from "./UserReactionCommonFunction";
import UserReactionListPopUp from "./UserReactionListPopUp";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import CleverTapUtils from "../commons/CleverTapUtils";
class UserReactionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            count: 10,
            userEventFlag: this.props.item.liked,
            userEventCount: this.props.item.likesCount,
            userEventType: this.props.item.likeEvent || "LIKE",
            userEventSuccess: true,
            userReactionPopUpFlag: false,
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    clickUserReaction = (userEventType, userEventFlag) => {
        this.setState({
            userEventSuccess: false
        })
        const data = {
            userId: !_.isEmpty(this.props.currentUserDetails) ? this.props.currentUserDetails.userId : this.state.userId,
            activityId: this.props.item.id,
            liked: userEventFlag,
            likeEvent: userEventType
        };
        axios({
            method: "post",
            url: `${process.env.REACT_APP_userServiceURL}/backend/like/create/`,
            data: data,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    userEventCount: (this.state.userEventFlag !== userEventFlag && userEventFlag) ? this.state.userEventCount + 1 : userEventFlag ? this.state.userEventCount : Math.max(0, this.state.userEventCount - 1),
                    userEventType: userEventFlag ? userEventType : "LIKE"
                }, () => {
                    this.setState({
                        userEventFlag: userEventFlag,
                        userEventSuccess: true
                    })
                })

                if(this.props.type && userEventFlag){
                    CleverTapUtils.eventClick({"Type": userEventType}, "Like "+this.props.type) ;
                }
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                userEventSuccess: true
            })
            if(err?.response?.data?.status === "409 CONFLICT") {
                blockUserMessage("MESSAGE", err.response.data.message);
            }
        });
    }

    openUserReactList = () => {
        this.setState({
            userReactionPopUpFlag: true
        }, () => {
            window.$("#userReactionListPopUp").modal("show");
        })
    }

    closeUserReactionPopUp = () => {
        window.$("#userReactionListPopUp").modal("hide");
        this.setState({
            userReactionPopUpFlag: false
        })
    }

    getLikeEventCheck = (userId) => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/like/check?activityId=${this.props.item.id}&userId=${userId}`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let data = response.data.body;
                this.setState({
                    userEventFlag: data ? data.liked : false,
                    userEventType: data ? data.likeEvent : "LIKE",
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    componentWillReceiveProps(nextProps) {
        if(!_.isEmpty(this.props.currentUserDetails) && nextProps.currentUserDetails?.userId !== this.props.currentUserDetails?.userId) {
           this.getLikeEventCheck(nextProps.currentUserDetails?.userId);
        }
    }

    componentWillUnmount() {
        this.closeUserReactionPopUp();
    }

    render() {
        const { userEventFlag, userEventCount, userEventSuccess, userEventType, userReactionPopUpFlag } = this.state;
        return (
            <div className="likesblk">
                <a href="javascript:void(0)" onClick={(e) => this.clickUserReaction(userEventType, !userEventFlag)} className={"like " + (userEventFlag ? userReactionClassName(userEventType) : "") + (!userEventSuccess ? " disabled" : "")} data-for={this.props.item.id} data-tip="UserReactionComponent" data-place={this.props.pathType === 'postDetails' ? "bottom" : "top"} />

                <a href="javascript:void(0)" onClick={(e) => userEventCount > 0 ? this.openUserReactList() : "javascript:void(0)"} style={{cursor : userEventCount > 0 ? "pointer" : "default"}}>{nFormatter(userEventCount, 1)}{" "}
                    <span className="art_btn_txt">
                        {userEventFlag ? "Reaction" : "Like"}{userEventCount > 1 ? "s" : ""}
                    </span>
                </a>

                <ReactTooltip
                    className="rtoolTip reactionIcos"
                    place="top"
                    type="dark"
                    textColor="#00394D"
                    effect="solid"
                    backgroundColor="#ffffff"
                    delayHide={1000}
                    clickable
                    id={this.props.item.id}
                >
                    {userReactionTypeList && userReactionTypeList.map((item) => {
                        return (
                            item.value !== "ALL" && <i className={userReactionIcon(item.value)} data-title={item.label} onClick={(e) => this.clickUserReaction(item.value, true)} />
                        )
                    })}
                </ReactTooltip>

                {userReactionPopUpFlag && <UserReactionListPopUp closeUserReactionPopUp={this.closeUserReactionPopUp} activityId={this.props.item.id}  />}

            </div>
        )
    }
}

export default withRouter(UserReactionComponent);
