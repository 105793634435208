export const SET_TRENDING_OPPORTUNITY = "/project/SET_TRENDING_OPPORTUNITY";
export const SET_TRENDING_OPPORTUNITY_SUCCESS = "/project/SET_TRENDING_OPPORTUNITY_SUCCESS";
export const SET_TRENDING_TRAINING = "/project/SET_TRENDING_TRAINING";
export const SET_TRENDING_TRAINING_SUCCESS = "/project/SET_TRENDING_TRAINING_SUCCESS";
export const SET_TOP_PERFORMER = "/project/SET_TOP_PERFORMER";
export const SET_TOP_PERFORMER_SUCCESS = "/project/SET_TOP_PERFORMER_SUCCESS";
export const SET_TOP_COMPANY = "/project/TOP_COMPANY";
export const SET_TOP_COMPANY_SUCCESS = "/project/TOP_COMPANY_SUCCESS";
export const SET_TOP_RECRUITER = "/project/SET_TOP_RECRUITER";
export const SET_TOP_RECRUITER_SUCCESS = "/project/SET_TOP_RECRUITER_SUCCESS";
export const SET_TRENDING_EVENTS = "/project/SET_TRENDING_EVENTS";
export const SET_TRENDING_EVENTS_SUCCESS =
  "/project/SET_TRENDING_EVENTS_SUCCESS";


//training filter api

export const SET_TRAINING_TOP_TUTORS = "/project/training/SET_TRAINING_TOP_TUTORS";
export const SET_TRAINING_TOP_TUTORS_SUCCESS = "/project/training/SET_TRAINING_TOP_TUTORS_SUCCESS";

export const SET_TRAINING_TOP_WORKSHOPS = "/project/training/SET_TRAINING_TOP_WORKSHOPS";
export const SET_TRAINING_TOP_WORKSHOPS_SUCCESS = "/project/training/SET_TRAINING_TOP_WORKSHOPS_SUCCESS";

export const SET_TRAINING_TOP_ORGANIZERS = "/project/training/SET_TRAINING_TOP_ORGANIZERS";
export const SET_TRAINING_TOP_ORGANIZERS_SUCCESS = "/project/training/SET_TRAINING_TOP_ORGANIZERS_SUCCESS";

// jobs filter api

export const SET_TRENDING_JOB = "/project/SET_TRENDING_JOB";
export const SET_TRENDING_JOB_SUCCESS = "/project/SET_TRENDING_JOB_SUCCESS";

export const SET_TOP_JOB = "/project/SET_TOP_JOB";
export const SET_TOP_JOB_SUCCESS = "/project/SET_TOP_JOB_SUCCESS";

export const SET_TOP_JOB_RECRUITER = "/project/SET_TOP_JOB";
export const SET_TOP_JOB_RECRUITER_SUCCESS =
  "/project/SET_TOP_JOB_RECRUITER_SUCCESS";

// events filter api

export const SET_TOP_EVENTS = "/project/SET_TOP_EVENTS";
export const SET_TOP_EVENTS_SUCCESS = "/project/SET_TOP_EVENTS_SUCCESS";

export const SET_FILTER_TRENDING_EVENTS = "/project/SET_FILTER_TRENDING_EVENTS";
export const SET_FILTER_TRENDING_EVENTS_SUCCESS =
  "/project/SET_FILTER_TRENDING_EVENTS_SUCCESS";

export const SET_TOP_EVENT_ORGANIZERS = "/project/SET_TOP_EVENT_ORGANIZERS";
export const SET_TOP_EVENT_ORGANIZERS_SUCCESS =
  "/project/SET_TOP_EVENT_ORGANIZERS_SUCCESS";


export const SET_COMPLETED_PROJECT = "/project/SET_COMPLETED_PROJECT";
export const SET_COMPLETED_PROJECT_SUCCESS =
    "/project/SET_COMPLETED_PROJECT_SUCCESS";

    
export const SET_UPCOMING_PROJECT = "/project/SET_UPCOMING_PROJECT";
export const SET_UPCOMING_PROJECT_SUCCESS =
  "/project/SET_UPCOMING_PROJECT_SUCCESS";

export const SET_RUNNING_PROJECT = "/project/SET_RUNNING_PROJECT";
export const SET_RUNNING_PROJECT_SUCCESS =
  "/project/SET_RUNNING_PROJECT_SUCCESS";

export const SET_CANCELLED_PROJECT = "/project/SET_CANCELLED_PROJECT";
export const SET_CANCELLED_PROJECT_SUCCESS = "/project/SET_CANCELLED_PROJECT_SUCCESS";

  //assignment api filter

export const SET_TOP_ASSIGNMENT_OPPORTUNITY = "/project/SET_TOP_ASSIGNMENT_OPPORTUNITY";
export const SET_TOP_ASSIGNMENT_OPPORTUNITY_SUCCESS =
    "/project/SET_TOP_ASSIGNMENT_OPPORTUNITY_SUCCESS";

    
export const SET_TOP_ASSIGNMENT_PERFORMER = "/project/SET_TOP_ASSIGNMENT_PERFORMER";
export const SET_TOP_ASSIGNMENT_PERFORMER_SUCCESS =
  "/project/SET_TOP_ASSIGNMENT_PERFORMER_SUCCESS";

export const SET_TRENDING_ASSIGNMENT= "/project/SET_TRENDING_ASSIGNMENT";
export const SET_TRENDING_ASSIGNMENT_SUCCESS =
  "/project/SET_TRENDING_ASSIGNMENT_SUCCESS";

  //volunteer api filter
  export const SET_TOP_CAUSES_VOLUNTEER = "/project/SET_TOP_CAUSES_VOLUNTEER";
  export const SET_TOP_CAUSES_VOLUNTEER_SUCCESS =
      "/project/SET_TOP_CAUSES_VOLUNTEER_SUCCESS";

  export const SET_TOP_ORGANIZATION_VOLUNTEER = "/project/SET_TOP_ORGANIZATION_VOLUNTEER";
  export const SET_TOP_ORGANIZATION_VOLUNTEER_SUCCESS = "/project/SET_TOP_ORGANIZATION_VOLUNTEER_SUCCESS";

export const PROJECT_APPLIED = "/project/PROJECT_APPLIED";
export const PROJECT_APPLIED_ON_SUCCESS = "/project/PROJECT_APPLIED_ON_SUCCESS";

let initialState = {
  trendingOpportunity: {},
  trendingTraining: {},
  trendingTrainingsPage:{},
  topPerformer: {},
  applied:{},
  topCompany: {},
  topCompanyPage: {},
  topRecruiter: {},
  showAlltrendingEvents: {},
  initiated: {},
  trendingJobs: {},
  trendingJobsPage: {},
  topJobs: {},
  topJobRecruiters: {},
  topEvents: {},
  trendingEvents: {},
  topEventOrganizers: {},
  showAlltrendingEventsPage:{},
  upcomingProject:{},
  completedProject:{},
  upcomingProjectPage:{},
  completedProjectPage:{},
  runningProject:{},
  runningProjectPage:{},
  cancelledProject:{},
  cancelledProjectPage:{},
  topTrainingTutors:{},
  topTrainingTutorsPage:{},
  topTrainingWorkShops:{},
  topTrainingWorkShopsPage:{},
  topTrainingOrganizers:{},
  topTrainingOrganizersPage:{},
  topAssignmentOpportunity:{},
  topAssignmentOpportunityPage:{},
  topAssignmentPerformers:{},
  topAssignmentPerformersPage:{},
  trendingAssignments:{},
  trendingAssignmentsPage:{},
  topVolunteerCauses:{},
  topVolunteerCausesPage:{},
  topVolunteerOrganization:{},
  topVolunteerOrganizationPage:{}
};

const homePageReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "/project/SET_TOP_CAUSES_VOLUNTEER":
      state = {...state, initiated: {[action.type]:false}};
      return state;
    case "/project/SET_TOP_CAUSES_VOLUNTEER_SUCCESS":
      state ={
        ...state,
        topVolunteerCauses: action.payload.content,
        initiated: { [action.type]: true },
        topVolunteerCausesPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
      }
    };
    return state;
    case "/project/SET_TOP_ORGANIZATION_VOLUNTEER":
      state = {...state, initiated: {[action.type]:false}};
      return state;
    case "/project/SET_TOP_ORGANIZATION_VOLUNTEER_SUCCESS":
      state ={
        ...state,
        topVolunteerOrganization: action.payload.content,
        initiated: { [action.type]: true },
        topVolunteerOrganizationPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
      }
    };
    return state;
    case "/project/SET_TOP_ASSIGNMENT_OPPORTUNITY":
      state = {...state, initiated: {[action.type]:false}};
      return state;
    case "/project/SET_TOP_ASSIGNMENT_OPPORTUNITY_SUCCESS":
      state = {
        ...state,
        topAssignmentOpportunity: action.payload.content,
        initiated: { [action.type]: true },
        topAssignmentOpportunityPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
        }
      };
      return state;
      case "/project/SET_TOP_ASSIGNMENT_PERFORMER":
        state = {...state, initiated: {[action.type]:false}};
        return state;
      case "/project/SET_TOP_ASSIGNMENT_PERFORMER_SUCCESS":
        state = {
          ...state,
          topAssignmentPerformers: action.payload.content,
          initiated: { [action.type]: true },
          topAssignmentPerformersPage:{
          seeMore: action.payload.seeMore,
          currentPage: action.payload.currentPage,
          size: action.payload.size,
          }
        };
        return state;
        case "/project/SET_TRENDING_ASSIGNMENT":
          state = {...state, initiated: {[action.type]:false}};
          return state;
        case "/project/SET_TRENDING_ASSIGNMENT_SUCCESS":
          state = {
            ...state,
            trendingAssignments: action.payload.content,
            initiated: { [action.type]: true },
            trendingAssignmentsPage:{
            seeMore: action.payload.seeMore,
            currentPage: action.payload.currentPage,
            size: action.payload.size,
            }
          };
          return state;
    case "/project/training/SET_TRAINING_TOP_ORGANIZERS":
      state = {...state, initiated: {[action.type]: false}};
      return state;
    case "/project/training/SET_TRAINING_TOP_ORGANIZERS_SUCCESS":
      state = {
        ...state,
        topTrainingOrganizers: action.payload.content,
        initiated: { [action.type]: true },
        topTrainingOrganizersPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
        }
      };
      return state;
    case "/project/training/SET_TRAINING_TOP_WORKSHOPS":
      state = { ...state, initiated: { [action.type]: false } };
      return state;
    case "/project/training/SET_TRAINING_TOP_WORKSHOPS_SUCCESS":
      state = {
        ...state,
        topTrainingWorkShops: action.payload.content,
        initiated: { [action.type]: true },
        topTrainingWorkShopsPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
        }
      };
      return state;
    case "/project/training/SET_TRAINING_TOP_TUTORS":
      state = { ...state, initiated: { [action.type]: false } };
      return state;
    case "/project/training/SET_TRAINING_TOP_TUTORS_SUCCESS":
      state = {
        ...state,
        topTrainingTutors: action.payload.content,
        initiated: { [action.type]: true },
        topTrainingTutorsPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
        }
      };
      return state;
    case "/project/SET_TRENDING_OPPORTUNITY":
      state = { ...state, initiated: { [action.type]: false } };
      return state;
    case "/project/SET_TRENDING_OPPORTUNITY_SUCCESS":
      state = {
        ...state,
        trendingOpportunity: action.payload.content,
        initiated: { [action.type]: true },
        trendingOpportunityPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
        }
      };

    case "/project/PROJECT_APPLIED":
      state = { ...state, initiated: { [action.type]: false,[action.apply]: false } };
      return state;
    case "/project/PROJECT_APPLIED_ON_SUCCESS":
      state = {
        ...state,
        applied: action.payload,
        initiated: { [action.type]: true },

      };
      return state;

    case "/project/SET_TRENDING_TRAINING":
      state = { ...state, initiated: { [action.type]: false } };
      return state;
    case "/project/SET_TRENDING_TRAINING_SUCCESS":
      state = {
        ...state,
        trendingTraining: action.payload.content,
        initiated: { [action.type]: true },
        trendingTrainingsPage:{
          seeMore: action.payload.seeMore,
          currentPage: action.payload.currentPage,
          size: action.payload.size,
        }
      };


      return state;
    case "/project/SET_COMPLETED_PROJECT":
      state = {
        ...state, initiated: {[action.type]: false},
        completedProjectPage: {
          seeMore: false
        }
      };
      return state;
    case "/project/SET_COMPLETED_PROJECT_SUCCESS":
      state = {
        ...state,
        completedProject: action.payload.content,
        initiated: { [action.type]: true },
        completedProjectPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
        }
      };
      return state;
      case "/project/SET_RUNNING_PROJECT":
        state = { ...state, initiated: { [action.type]: false },
          runningProjectPage:{
            seeMore: false
          }
        };
        return state;
      case "/project/SET_RUNNING_PROJECT_SUCCESS":
        state = {
          ...state,
          runningProject: action.payload.content,
          initiated: { [action.type]: true },
          runningProjectPage:{
          seeMore: action.payload.seeMore,
          currentPage: action.payload.currentPage,
          size: action.payload.size,
          }
        };
        return state;

    case "/project/SET_CANCELLED_PROJECT":
      state = { ...state, initiated: { [action.type]: false },
        cancelledProjectPage:{
          seeMore: false
        }
      };
      return state;
    case "/project/SET_CANCELLED_PROJECT_SUCCESS":
      state = {
        ...state,
        cancelledProject: action.payload.content,
        initiated: { [action.type]: true },
        cancelledProjectPage:{
          seeMore: action.payload.seeMore,
          currentPage: action.payload.currentPage,
          size: action.payload.size,
        }
      };
      return state;


    case "/project/SET_UPCOMING_PROJECT":
      state = { ...state, initiated: { [action.type]: false },
        upcomingProjectPage: {
          seeMore: false
        }
      };
      return state;
    case "/project/SET_UPCOMING_PROJECT_SUCCESS":
      state = {
        ...state,
        upcomingProject: action.payload.content,
        initiated: { [action.type]: true },
        upcomingProjectPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
        }
      };
      return state;
    case "/project/SET_TOP_PERFORMER":
      state = {
        ...state,
        initiated: { [action.type]: false },
      };
      return state;
    case "/project/TOP_COMPANY":
      state = {
        ...state,
        initiated: { [action.type]: false },
      };
      return state;
    case "/project/SET_TOP_RECRUITER":
      state = {
        ...state,
        initiated: { [action.type]: false },
      };
      return state;
    case "/project/SET_TOP_JOB":
      state = {
        ...state,
        initiated: { [action.type]: false },
      };
      return state;
    case "/project/SET_TRENDING_EVENTS":
      state = {
        ...state,
        initiated: { [action.type]: false },
      };
      return state;
    case "/project/SET_TRENDING_JOB":
      state = {
        ...state,
        initiated: { [action.type]: false },
      };
      return state;
    case "/project/SET_TOP_JOB":
      state = {
        ...state,
        initiated: { [action.type]: false },
      };
      return state;
    case "/project/SET_TOP_EVENTS":
      state = {
        ...state,
        initiated: { [action.type]: false },
      };
      return state;
    case "/project/SET_FILTER_TRENDING_EVENTS":
      state = {
        ...state,
        initiated: { [action.type]: false },
      };
      return state;
    case "/project/SET_TOP_EVENT_ORGANIZERS":
      state = {
        ...state,
        initiated: { [action.type]: false },
      };
      return state;
    case "/project/SET_TOP_PERFORMER_SUCCESS":
      state = {
        ...state,
        topPerformer: action.payload,
        initiated: { [action.type]: true },
      };
      return state;
    case "/project/TOP_COMPANY_SUCCESS":
      state = {
        ...state,
        topCompany: action.payload.content,
        initiated: { [action.type]: true },
        topCompanyPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
        }
      };
      
      return state;
    case "/project/SET_TOP_RECRUITER_SUCCESS":
      state = {
        ...state,
        topRecruiter: action.payload.content,
        initiated: { [action.type]: true },
        topRecruiterPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
        }
      };

      return state;
    case "/project/SET_FILTER_TRENDING_EVENTS_SUCCESS":
      state = {
        ...state,
        trendingEvents: action.payload,
        initiated: { [action.type]: true },
      };
      return state;
    case "/project/SET_TRENDING_JOB_SUCCESS":
      state = {
        ...state,
        trendingJobs: action.payload.content,
        trendingJobsPage:{
          seeMore: action.payload.seeMore,
          currentPage: action.payload.currentPage,
          size: action.payload.size,
        },
        initiated: { [action.type]: true },
      };
      return state;
    case "/project/SET_TOP_JOB_SUCCESS":
      state = {
        ...state,
        topJobs: action.payload,
        initiated: { [action.type]: true },
      };
      return state;
    case "/project/SET_TOP_JOB_RECRUITER_SUCCESS":
      state = {
        ...state,
        topJobRecruiters: action.payload,
        initiated: { [action.type]: true },
      };
      return state;
    case "/project/SET_TOP_EVENTS_SUCCESS":
      state = {
        ...state,
        topEvents: action.payload,
        initiated: { [action.type]: true },
      };
      return state;
    case "/project/SET_TRENDING_EVENTS_SUCCESS":
      state = {
        ...state,
        showAlltrendingEvents: action.payload.content,
        initiated: { [action.type]: true },
        showAlltrendingEventsPage:{
        seeMore: action.payload.seeMore,
        currentPage: action.payload.currentPage,
        size: action.payload.size,
        }
      };
      return state;
    case "/project/SET_TOP_EVENT_ORGANIZERS_SUCCESS":
      state = {
        ...state,
        topEventOrganizers: action.payload,
        initiated: { [action.type]: true },
      };
    default:
      return state;
  }
  return state;
};

export default homePageReducer;

export function setShowAllTrendingEvent(state){
  return { type: SET_TRENDING_EVENTS,payload:state}
}

export function setShowAllTrendingEventOnSuccess(state) {
  return { type: SET_TRENDING_EVENTS_SUCCESS, payload: state };
}

export function setProjectApplied(state){
  return { type: PROJECT_APPLIED,payload:state};
}

export function setProjectAppliedOnSuccess(state) {
  return { type: PROJECT_APPLIED_ON_SUCCESS, payload: state };
}

export function setUpcomingProject(state){
  return {type: SET_UPCOMING_PROJECT,payload:state};
}

export function setUpcomingProjectOnSuccess(state){
  return {type: SET_UPCOMING_PROJECT_SUCCESS,payload:state};
}

export function setTrainingTopTutors(state){
  return {type: SET_TRAINING_TOP_TUTORS,payload:state};
}

export function setTrainingTopTutorsOnSuccess(state){
  return {type: SET_TRAINING_TOP_TUTORS_SUCCESS,payload:state};
}

export function setTopCausesForVolunteer(state){
  return {type: SET_TOP_CAUSES_VOLUNTEER,payload:state};
}

export function setTopCausesForVolunteerOnSuccess(state){
  return {type: SET_TOP_CAUSES_VOLUNTEER_SUCCESS,payload:state};
}

export function setTopOrganizationForVolunteerOnSuccess(state){
  return {type: SET_TOP_ORGANIZATION_VOLUNTEER_SUCCESS,payload:state};
}

export function setTopOrganizationForVolunteer(state){
  return {type: SET_TOP_ORGANIZATION_VOLUNTEER,payload:state};
}

export function setTrainingTopOrganizers(state){
  return {type: SET_TRAINING_TOP_ORGANIZERS,payload:state};
}

export function setTrainingTopOrganizersOnSuccess(state){
  return {type: SET_TRAINING_TOP_ORGANIZERS_SUCCESS,payload:state};
}

export function setTrainingTopWorkshops(state){
  return {type: SET_TRAINING_TOP_WORKSHOPS,payload:state};
}

export function setTrainingTopWorkshopsOnSuccess(state){
  return {type: SET_TRAINING_TOP_WORKSHOPS_SUCCESS,payload:state};
}



export function setTopAssignmentOpportunity(state){
  return {type: SET_TOP_ASSIGNMENT_OPPORTUNITY,payload:state};
}

export function setTopAssignmentOpportunityOnSuccess(state){
  return {type: SET_TOP_ASSIGNMENT_OPPORTUNITY_SUCCESS,payload:state};
}

export function setTopAssignmentPerformer(state){
  return {type: SET_TOP_ASSIGNMENT_PERFORMER,payload:state};
}

export function setTopAssignmentPerformerOnSuccess(state){
  return {type: SET_TOP_ASSIGNMENT_PERFORMER_SUCCESS,payload:state};
}


export function setCompletedProject(state){
  return {type: SET_COMPLETED_PROJECT,payload:state};
}

export function setCompletedProjectOnSuccess(state){
  return {type: SET_COMPLETED_PROJECT_SUCCESS,payload:state};
}

export function setRunningProject(state){
  return {type: SET_RUNNING_PROJECT,payload:state};
}

export function setRunningProjectOnSuccess(state){
  return {type: SET_RUNNING_PROJECT_SUCCESS,payload:state};
}

export function setCancelledProject(state){
  return {type: SET_CANCELLED_PROJECT,payload:state};
}

export function setCancelledProjectOnSuccess(state){
  return {type: SET_CANCELLED_PROJECT_SUCCESS,payload:state};
}

export function setTopCompany(state){
  return {type : SET_TOP_COMPANY, payload: state };
}

export function setTopCompanyOnSuccess(state){
  return { type: SET_TOP_COMPANY_SUCCESS, payload: state };
}

export function setTopRecruiter(state){
  return {type : SET_TOP_RECRUITER, payload : state};
}

export function setTopRecruiterOnSuccess(state) {
  return {type: SET_TOP_RECRUITER_SUCCESS, payload : state};
}

export function setTopEvent() {
  return { type: SET_TOP_EVENTS };
}

export function setTopEventOnSuccess(state) {
  return { type: SET_TOP_EVENTS_SUCCESS, payload: state };
}


export function setTrendingFilterEvent() {
  return { type: SET_FILTER_TRENDING_EVENTS };
}

export function setTrendingFilterEventOnSuccess(state) {
  return { type: SET_FILTER_TRENDING_EVENTS_SUCCESS, payload: state };
}


export function setTopEventOrganizers() {
  return { type: SET_TOP_EVENT_ORGANIZERS };
}

export function setTopEventOrganizersOnSuccess(state) {
  return { type: SET_TOP_EVENT_ORGANIZERS_SUCCESS, payload: state };
}



export function setTopJobRecruiter() {
  return { type: SET_TOP_JOB_RECRUITER };
}

export function setTopJobRecruiterOnSuccess(state) {
  return { type: SET_TOP_JOB_RECRUITER_SUCCESS, payload: state };
}

export function setTreadingOpportunity(state) {
  return { type: SET_TRENDING_OPPORTUNITY, payload: state };
}

export function setTreadingOpportunityOnSuccess(state) {
  return { type: SET_TRENDING_OPPORTUNITY_SUCCESS, payload: state };
}

export function setTrendingTraining(state) {
  return { type: SET_TRENDING_TRAINING, payload: state };
}

export function setTrendingTrainingOnSuccess(state) {
  return { type: SET_TRENDING_TRAINING_SUCCESS, payload: state };
}

export function setTrendingAssignment(state){
  return {type: SET_TRENDING_ASSIGNMENT,payload:state};
}

export function setTrendingAssignmentOnSuccess(state){
  return {type: SET_TRENDING_ASSIGNMENT_SUCCESS,payload:state};
}


export function setTrendingJobs(state) {
  return { type: SET_TRENDING_JOB,payload:state };
}

export function setTrendingJobsOnSuccess(state) {
  return { type: SET_TRENDING_JOB_SUCCESS, payload: state };
}

export function setTopJobs() {
  return { type: SET_TOP_JOB };
}

export function setTopJobsOnSuccess(state) {
  return { type: SET_TOP_JOB_SUCCESS, payload: state };
}
