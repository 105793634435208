import React from 'react';
import { bool, func } from 'prop-types';

const PresentationFullscreen = React.memo(({isFullscreen, onClick, handlePageClickHookPost}) => {

    if (isFullscreen) {
        handlePageClickHookPost();
        window.$('body').addClass('modalPreview-open');
    } else {
        window.$('body').removeClass('modalPreview-open');
    }
    return (
        <button type="button" className="image-gallery-icon image-gallery-fullscreen-button" onClick={onClick} aria-label="Open Fullscreen">
            <svg className="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d={!isFullscreen ? "M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" : "M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"}></path>
            </svg>
        </button>
    );
});

PresentationFullscreen.displayName = 'Fullscreen';

PresentationFullscreen.propTypes = {
    isFullscreen: bool.isRequired,
    onClick: func.isRequired,
    handlePageClickHookPost: func.handlePageClickHookPost
};


export default PresentationFullscreen;