import React, {PureComponent} from 'react';
import {getCDNUrl} from "../commons/CommonFunctions";
import {NavLink} from "react-router-dom";

class ExploreBlogSharePreview extends PureComponent {

    handleDetailPage(evt, news_url) {
        evt.preventDefault();

        if (news_url !== undefined && news_url !== null) {
            window.open(news_url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
        }
    }

    redirectToArticleDetail(slug) {
        var url = '/explore-detail/blog/' + slug;
        if (url !== undefined && url !== null) {
            window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
        }
    }

    getElementsFromList(list) {

        var str = "";
        for (var each in list) {
            str += "#" + list[each] + " ";
        }
        return str;
    }

    render() {
        var utcSeconds = this.props.item.createdTime;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);

        return (
            <div className="sharepostpreview">
                <div className="postarticleblock">
                    <figure className="postarticleimg">
                        <a onClick={(e) => this.handleDetailPage(e, "/explore-detail/blog/" + this.props.item.slug)}
                           href="javascript:void(0)">
                            <img src={getCDNUrl(this.props.item.coverImageUrl)} alt=""/>
                        </a>
                    </figure>
                    <div className="postarticledesc">
                        <div className="projhead">
                            <figure className="complogo welogo">
                                <img src="https://cdn.dscovr.com/images/welogoNew.webp"
                                onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/welogoNew.png"}}
                                alt=""/>
                            </figure>
                            <div>{this.props.item.source} <span/></div>
                        </div>
                        {/* <h4>    
                            {this.props.item.hashTags && this.props.item.hashTags.map((hashTag, index) => {
                                return <span>
                                    <a href={'/explore-hashtag/'+hashTag}>#{hashTag}</a>
                                </span>
                                })
                            }
                        </h4> */}
                        <h2 className='articlePostTitle'>

                            <a onClick={(e) => this.handleDetailPage(e, "/explore-detail/blog/" + this.props.item.slug)}
                               href="javascript:void(0)">{this.props.item.title}</a>
                            <div className="hpostdtls">
                                {this.props.item && this.props.item.addressDetail && this.props.item.addressDetail.country && <span className="address"><i
                                    className="iLocation"/> {this.props.item.addressDetail.country}</span>}
                            </div>
                        </h2>

                        <div className="padesctxt"
                             dangerouslySetInnerHTML={{
                                 __html: this.props.item.content
                                     ? this.props.item.content
                                         .replace(/&nbsp;/g, ' ')
                                         .replace(/(\r\n|\r|\n)/g, '<br>')
                                     : ''
                             }}/>
                        <div className="text-center">
                            <a href="javascript:void(0)"
                               onClick={e => {
                                   e.preventDefault();
                                   this.redirectToArticleDetail(this.props.item.slug)
                               }}
                               className="more btn">read more</a>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default ExploreBlogSharePreview
