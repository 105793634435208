import React, {PureComponent} from 'react';
import CausePostItem from "./CausePostItem";
import {authService} from "../auth";
import $ from 'jquery';
import Scrollbars from 'react-custom-scrollbars';

class CausePostList extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            causeList: props.causeList ? props.causeList : []
        }
    }

    componentDidMount() {
        window.$('.dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.dropdown-menu').addClass('show');
            e.stopPropagation();
        });
    };


    render() {

        return (            
            <>
            {this.props.causeList && this.props.causeList.length > 0 &&
                <div className="postCardBlk">
                    <div className='postCardHeading' id={'heading-cause'}>
                        <a role="button" data-toggle="collapse" href={'#causeList'} aria-expanded="false">
                            <figure className="ico"><i className="iCauses_OL" style={{fontSize: '16px'}}/></figure>
                            <div>
                                <h5>Causes</h5>
                            </div>
                        </a>
                    </div>                    
                    <div id={'causeList'} className="collapse" data-parent="#accordion" aria-labelledby={'heading-cause'}>
                        <div className="postCardbody">
                            <Scrollbars
                                className={'forwcontlistmain'}
                                autoHide
                                autoHeight
                                autoHeightMin={0}
                                autoHeightMax={230}
                                universal={true}
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                renderTrackVertical={props => <div {...props} style={{display: 'none'}} className="track-vertical"/>}
                            >
                            {
                                this.props.causeList.map((item) =>
                                    <CausePostItem key={item.id} item={item}
                                                setAsSource={() => this.props.setAsEntity(item.id, 'Cause', item.name)}/>
                                )
                            }
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            }
            </>
        );
    }
}

export default CausePostList;
