import React, {PureComponent} from 'react';

class StorySharePreview extends PureComponent {

    handleDetailPage(evt, news_url) {
        evt.preventDefault();

        if (news_url !== undefined && news_url !== null) {
            window.open(news_url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
        }
    }

    getElementsFromList(list) {

        let str = "";
        if (list !== undefined) {
            for (let index = 0; index <= list.length; index++) {
                if (index <= 2 && list[index] !== undefined) {
                    str += "#" + list[index].replace(/\s/g, '') + " ";
                }
            }
        }
        return str;
    }

    render() {

        return (
            <div className="sharepostpreview">
                <div className="explstorieslist">
                    <figure className="explstoriesimg">
                        <a onClick={(e) => this.handleDetailPage(e, this.props.item.news_url)}
                           href="javascript:void(0)">
                            <img src={this.props.item.image_url} alt=""/>
                        </a>
                    </figure>

                    <div className="explstoriesdesc">
                        <div className="projhead">
                            <div>{this.props.item.name === undefined ? '' : this.props.item.name}</div>
                        </div>
                        <h4>
                            {this.props.item.hash_tags && this.props.item.hash_tags.map((hashTag, index) => {
                                return <span>
                                    <a href={'/explore-hashtag/'+hashTag}>#{hashTag}</a>
                                </span>
                                })
                            }
                        </h4>
                        <h3>
                            <a onClick={(e) => this.handleDetailPage(e, this.props.item.news_url)}
                               href="javascript:void(0)">{this.props.item.header}</a>
                        </h3>
                        <div className="descinn"><p>{this.props.item.news}</p></div>
                    </div>

                </div>
            </div>
        );
    }
}

export default StorySharePreview
