import axios from "axios";
import { Apis } from "../config/api";

export const getShareCheckRestriction = async (data) => {
    try {
        const response = await axios.get(Apis.ShareCheckRestriction(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}