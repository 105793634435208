import React, { Component } from "react";
import HideContent from "../HideContent";
import "../../project/container/Project/css/main-projects.min.css";
import RunningProject from "./Projects/runningProject";
import UpcomingProject from "./Projects/upcomingProject";
import CompletedProject from "./Projects/completedProject";
import axios from "axios";
import { authService } from "../../auth";
import { setEntityList, setProjectDetails } from "../../reducer/showAllReducer";
import { connect } from "react-redux";
import {isMobileDevice, userAgentGetData} from "../../commons/CommonFunctions";
import ProjectVisibilitySection from "./Projects/ProjectVisibilitySection";

if (isMobileDevice()) {
    import("../../commons/styles/responsive-useragent.css").then(() => {
      console.log("Imported mobile css");
    });
}
class ProjectHidden extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "running",
      runningTabKey: "running",
      upComingTabKey: "upcoming",
      completedTabKey: "completed",
      runningProjectSize: 0,
      upcomingProjectSize: 0,
      completeProjectSize: 0,
      showOnlyMyPost: false,
      userData: authService.getUserDetailsFromLocalStorage(),
      projectDataUpcoming: [],
      projectDataRunning: [],
      projectDataCompleted: [],
      projectDataUpcomingViewMore: false,
      projectDataRunningViewMore: false,
      projectDataCompletedViewMore: false,
      actionType: {label: "Hidden", value: "HIDDEN"},
      filterData: [{label: "Hidden", value: "HIDDEN"}, {label: "Visibility Restricted", value: "PROFILE_VISIBILITY"}],
      userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId,
      userType: authService.getUserDetailsFromLocalStorage()?.type
    };
  }

  getUserDataByUserId = () => {
    axios({
        method: "get",
        url: `${process.env.REACT_APP_userServiceURL}/profile/get?id=${this.state.userId}&otherUserId=${authService.getUserDetailsFromLocalStorage().userId}${userAgentGetData()}`,
        headers: {"Content-Type": "application/json"},
        withCredentials: true,
    }).then((response) => {
          if (response && response.data && response.data.message === "Success!") {
              this.setState({userType: response.data.body?.type});
          }
      })
      .catch((err) => {
          console.log(err);
      });
}

  componentDidMount() {
    window.$(".posthnavtab").sticky({ topSpacing: 65, responsiveWidth: true });
    this.getUpcomingProject(0,false);
    this.getRunningProject(0,false);
    this.getCompletedProject(0,false);
    this.getUserDataByUserId();
  }

  getProjectFilterData = (data) => {
    this.setState({
      actionType: data
    })
    if(data.value === "HIDDEN") {
      this.refresh_upcoming_project();
      this.refresh_running_project();
      this.refresh_completed_project();
    } else {
      
    }
  }

  getCheckedData = (event) => {
    this.setState({
        showOnlyMyPost: event.target.checked
    });
    if(this.state.actionType?.value === "HIDDEN") {
      this.refresh_upcoming_project();
      this.refresh_running_project();
      this.refresh_completed_project();
    }
  };

  refresh_upcoming_project = () => {
    this.setState(
      {
        upcomingProjectSize: 0,
      },
      () => {
        this.getUpcomingProject(this.state.upcomingProjectSize, false);
      }
    );
  };

  refresh_running_project = () => {
    this.setState(
      {
        runningProjectSize: 0,
      },
      () => {
        this.getRunningProject(this.state.runningProjectSize, false);
      }
    );
  };

  refresh_completed_project = () => {
    this.setState(
      {
        completeProjectSize: 0,
      },
      () => {
        this.getCompletedProject(this.state.completeProjectSize, false);
      }
    );
  };

  getUpcomingProject = (currentPageNo, concatWithPrevious) => {
    if (currentPageNo === undefined) {
      this.setState(
        {
          upcomingProjectSize: 0,
        },
        () => {
          currentPageNo = 0;
        }
      );
    }
    axios({
      method: "get",
      url:
        process.env.REACT_APP_userServiceURL +
        "/hidden/upcoming-projects/" +
        this.state.userId +
        "/" +
        this.state.userId +
        "/?showOnlyMyPost=" +
        this.state.showOnlyMyPost +
        "&filterType=timeline&isPinned=false&userActivityType=ALL&projectFilterType=ALL" +
        "&page=" +
        currentPageNo +
        "&size=6",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }).then((response) => {
      if (response.status === 200 && response.data && response.data.body) {
        if (concatWithPrevious) {
          this.state.projectDataUpcoming.push(...response.data.body.content);
          this.setState({
            projectDataUpcoming: this.state.projectDataUpcoming,
          });
        } else {
          this.setState({
            projectDataUpcoming: response.data.body.content,
          });
        }
        this.setState({
          projectDataUpcomingViewMore: !response.data.body.last,
        });
      }
    });
  };

  getRunningProject = (currentPageNo, concatWithPrevious) => {
    if (currentPageNo === undefined) {
      this.setState(
        {
          getRunningProject: 0,
        },
        () => {
          currentPageNo = 0;
        }
      );
    }
    axios({
      method: "get",
      url:
        process.env.REACT_APP_userServiceURL +
        "/hidden/running-project/" +
        this.state.userId +
        "/" +
        this.state.userId +
        "/?showOnlyMyPost=" +
        this.state.showOnlyMyPost +
        "&filterType=timeline&isPinned=false&userActivityType=ALL&projectFilterType=ALL" +
        "&page=" +
        currentPageNo +
        "&size=6",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }).then((response) => {
      if (response.status === 200 && response.data && response.data.body) {
        if (concatWithPrevious) {
          this.state.projectDataRunning.push(...response.data.body.content);
          this.setState({
            projectDataRunning: this.state.projectDataRunning,
          });
        } else {
          this.setState({
            projectDataRunning: response.data.body.content,
          });
        }

        this.setState({ projectDataRunningViewMore: !response.data.body.last });
      }
    });
  };

  getCompletedProject = (currentPageNo, concatWithPrevious) => {
    if (currentPageNo === undefined) {
      this.setState(
        {
          completeProjectSize: 0,
        },
        () => {
          currentPageNo = 0;
        }
      );
    }
    axios({
      method: "get",
      url:
        process.env.REACT_APP_userServiceURL +
        "/hidden/completed-projects/" +
        this.state.userId +
        "/" +
        this.state.userId +
        "/?showOnlyMyPost=" +
        this.state.showOnlyMyPost +
        "&filterType=timeline&isPinned=false&userActivityType=ALL&projectFilterType=ALL" +
        "&page=" +
        currentPageNo +
        "&size=6",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }).then((response) => {
      if (response.status === 200 && response.data && response.data.body) {
        if (concatWithPrevious) {
          this.state.projectDataCompleted.push(...response.data.body.content);
          this.setState({
            projectDataCompleted: this.state.projectDataCompleted,
          });
        } else {
          this.setState({
            projectDataCompleted: response.data.body.content,
          });
        }

        this.setState({
          projectDataCompletedViewMore: !response.data.body.last,
        });
      }
    });
  };

  viewMoreFunction(event, type) {
    event.preventDefault();
    if (type === "upcoming") {
      let pageNo = this.state.upcomingProjectSize + 1;
      this.setState({
        upcomingProjectSize: pageNo,
      });
      this.getUpcomingProject(pageNo, true);
    } else if (type === "running") {
      let pageNo = this.state.runningProjectSize + 1;
      this.setState({
        runningProjectSize: pageNo,
      });
      this.getRunningProject(pageNo, true);
    } else if (type === "completed") {
      let pageNo = this.state.completeProjectSize + 1;
      this.setState({
        completeProjectSize: pageNo,
      });
      this.getCompletedProject(pageNo, true);
    }
  }

  render() {
    const { userId, userType } = this.state;
    return (
      <HideContent userId={userId}>
        <div className="forumtopfilter" style={{ marginBottom: "12px" }}>            
            <div className="showmewrap" style={{ marginTop: "5px" }}>
              <label className="switch">
                Show only my posts
                <input
                  type="checkbox"
                  checked={this.state.showOnlyMyPost}
                  onClick={this.getCheckedData}
                />
                <span className="slider round"></span>
              </label>
            </div>
          {userType !=="COMPANY" && 
            <div className="shortwrap">
                <div className="projtopshortby">
                    <span>Sort by</span>
                    <div className="shortbydrop">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" style={{maxWidth: "155px", width: "155px"}}>{this.state.actionType?.label}</button>
                        <div className="dropdown-menu fadeIn animated">
                          {this.state.filterData && this.state.filterData.map((item) => {
                            return (
                              item?.value !== this.state.actionType?.value && <a className="dropdown-item" id="filter-Forum" onClick={e => {e.preventDefault(); this.getProjectFilterData(item)}} href="javascript:void(0)">{item?.label}</a>
                            )
                          })}
                        </div>
                    </div>
                </div>
            </div>
          }
        </div>
        <div className="forumtopfilterRelatedTxt">
          <p>
              {this.state.actionType?.value === "HIDDEN" ? 
                "Projects hidden by you. The one's created by you will be hidden from everyone" :
                "Visibility of projects restricted for others"
              }
          </p>
        </div>
        <div id="proj-ventures">
          {this.state.projectDataUpcoming?.length === 0 && this.state.projectDataRunning?.length === 0 && this.state.projectDataCompleted?.length === 0 && this.state.actionType?.value === "HIDDEN" && (
              <div className="zdata col-12" style={{ marginTop: "50px" }}>
                <div class="imgbox">
                    <img src="https://cdn.dscovr.com/images/zroProjectsOpportunity-new.webp"
                    onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/zroProjectsOpportunity-new.png"}} alt="" width={350} />
                </div>
                <div className="desc">
                  <p style={{ margin: "0px" }}>You have no Hidden Activities</p>
                </div>
              </div>
            )}
            {this.state.actionType?.value === "HIDDEN" ? 
              <>
                {this.state.projectDataUpcoming &&
                  this.state.projectDataUpcoming.length > 0 && (
                    <UpcomingProject
                      getUpcomingProject={this.refresh_upcoming_project}
                      setProjectDetails={this.props.setProjectDetails}
                      showOnlyMyPost={this.state.showOnlyMyPost}
                      projectDataUpcoming={this.state.projectDataUpcoming}
                    >
                      {this.state.projectDataUpcomingViewMore && (
                        <div className="btnblock">
                          <a
                            href="javascript:void(0)"
                            onClick={(evt) => this.viewMoreFunction(evt, "upcoming")}
                            className="btn"
                          >
                            View more
                          </a>
                        </div>
                      )}
                    </UpcomingProject>
                )}
                {this.state.projectDataRunning &&
                  this.state.projectDataRunning.length > 0 && (
                    <RunningProject
                      projectDataRunning={this.state.projectDataRunning}
                      setProjectDetails={this.props.setProjectDetails}
                      getRunningProject={this.refresh_running_project}
                      showOnlyMyPost={this.state.showOnlyMyPost}
                    >
                      {this.state.projectDataRunningViewMore && (
                        <div className="btnblock">
                          <a
                            href="javascript:void(0)"
                            onClick={(evt) => this.viewMoreFunction(evt, "running")}
                            className="btn"
                          >
                            View more
                          </a>
                        </div>
                      )}
                    </RunningProject>
                  )}
                {this.state.projectDataCompleted &&
                  this.state.projectDataCompleted.length > 0 && (
                    <CompletedProject
                      projectDataCompleted={this.state.projectDataCompleted}
                      setProjectDetails={this.props.setProjectDetails}
                      getCompletedProject={this.refresh_completed_project}
                      showOnlyMyPost={this.state.showOnlyMyPost}
                    >
                      {this.state.projectDataCompletedViewMore && (
                        <div className="btnblock">
                          <a
                            href="javascript:void(0)"
                            onClick={(evt) => this.viewMoreFunction(evt, "completed")}
                            className="btn"
                          >
                            View more
                          </a>
                        </div>
                      )}
                    </CompletedProject>
                  )}
              </> : 
              <ProjectVisibilitySection userId={this.state.userId} showOnlyMyPost={this.state.showOnlyMyPost} key={this.state.showOnlyMyPost} />
            }
        </div>
      </HideContent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    applied: state.home,
    projectDetails: state.projectDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEntityList: (mode) => dispatch(setEntityList(mode)),
    setProjectDetails: (projectDetails) =>
      dispatch(setProjectDetails(projectDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHidden);
