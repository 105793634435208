import React, { memo } from 'react';
import { authService } from '../auth';
import PresentationViewGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { download_file_pdf, getCDNUrl } from '../commons/CommonFunctions';
import PresentationFullscreen from './PresentationFullscreen';
import { pageClickHookPostMethod } from '../services/PostApi';

class PresentationPostView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            loginRequired: false,
            presentationViewImages: [],
            pdfDownloadableImageUrl: "",
            documentTitle: this.props.documentTitle ? this.props.documentTitle : "",
            triggerHookPost: false
        }
    }

    customControlsPresentation = (props) => {
        return (
            <>
                <h4 className='tresentation-title'>{this.props.documentTitle ? this.props.documentTitle : this.state.documentTitle}</h4>
                {this.state.loginSuccess && this.state.pdfDownloadableImageUrl && !this.props.isStudioPage ? 
                    <button type="button" className="btn download-attachment" onClick={(e) => download_file_pdf(e, this.state.pdfDownloadableImageUrl, this.props.documentTitle ? this.props.documentTitle : this.state.documentTitle)}>
                        <i className="iDownloadFile" />
                    </button> :
                    null
                }
            </>
        );
    };

    getPresentationViewImages = (attachments) => {
        let presentationViewImages = [];
        if (attachments) {
            this.setState({ showPresentationSection: true })
            attachments && attachments.relatedImages && attachments.relatedImages.map((data) => {
                presentationViewImages.push({ original: data, thumbnail: data })
            })
        }
        this.setState({
            presentationViewImages: presentationViewImages,
            showPresentationSection: presentationViewImages.length > 0 ? true : false,
            pdfDownloadableImageUrl: attachments.pdfDownloadableImage,
            documentTitle: attachments?.title
        });
    }

    componentDidMount() {
        this.getPresentationViewImages(this.props.attachments);
    }

    renderFullscreenButton = (onClick, isFullscreen) => (
        <PresentationFullscreen onClick={onClick} isFullscreen={isFullscreen} handlePageClickHookPost={this.handlePageClickHookPost} />
    )

    getCurrentIndex = (event) => {
        var currentIndex = this.presentationView.getCurrentIndex();
        if(currentIndex > 0 && this.props.postSection == "FEEDS" && !this.state.triggerHookPost) {
            this.setState({triggerHookPost: true})
            this.handlePageClickHookPost();
        }
    }

    handlePageClickHookPost = async () => {
        let postData = {
          entityId: this.props.entityItem?.description ? this.props.entityItem?.id : this.props.entityId,
          userId: this.state.loginSuccess ? this.state.userData?.userId : null,
          entityType: "POST"
        }
        await pageClickHookPostMethod(postData);
        if(this.props.entityItem?.id && !this.props.entityItem?.description) {
            postData.entityId = this.props.entityItem?.id;
            await pageClickHookPostMethod(postData);
        }
    }

    render() {
        const {presentationViewImages, showPresentationSection, pdfDownloadableImageUrl, loginSuccess} = this.state;
        return (
            showPresentationSection ? 
                <div className={(presentationViewImages.length < 2) ? "presentation-view-gallery fullClick" : "presentation-view-gallery"}>
                    <PresentationViewGallery 
                        ref={i => this.presentationView = i}
                        items={presentationViewImages} 
                        showIndex={true} 
                        autoPlay={false} 
                        showThumbnails={false} 
                        showPlayButton={false} 
                        disableKeyDown={true} 
                        infinite={false} 
                        renderCustomControls={this.customControlsPresentation}
                        renderFullscreenButton={this.renderFullscreenButton}
                        onSlide={this.getCurrentIndex.bind(this)}
                     />
                </div> :
            null
        )
    }
}

export default memo(PresentationPostView);