import React, { Component, memo } from "react";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import { authService } from "../auth";
import DonationViewDonorsPopUp from "./DonationViewDonorsPopUp";
import uuid from "react-uuid";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "react-loader-spinner";
import { convertCurrencySystem, getCDNUrl } from "../commons/CommonFunctions";
import { buttonShowDetails } from "./DonationCommonFunction";
import { Link } from "react-router-dom";

class DonationStatsDataItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            donatedStatsDataList: {},
            popUpData: {},
            donatedStatsPageNo: 0,
            donatedStatsSuccess: false,
            thresholdHeight: 1000,
            viewDonorFlag: false
        }
    }

    fetchDonationStatsDataList = (page, size, concatWithPrevious) => {
        let cancelToken;
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request")
        }
        cancelToken = axios.CancelToken.source();
        this.setState({
            donatedStatsSuccess: true
        })
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/donation/dashboard/stats?userId=${this.props.userId}&entityType=${this.props.entityType}&page=${page}&size=${size}`,
            method: "GET",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
            cancelToken: cancelToken.token
        }).then((response) => {
            if (response &&
                response.data &&
                response.data.body &&
                response.data.status === "200 OK"
            ) {
                let pageData = response.data.body;
                if (concatWithPrevious) {
                    pageData.content = this.state.donatedStatsDataList.content.concat(pageData.content);
                }
                this.setState({
                    donatedStatsDataList: pageData,
                    donatedStatsSuccess: false
                })
            }
        }).catch((error) => {
            console.log(error)
            this.setState({
                donatedStatsSuccess: false
            })
        });
    };

    fetchMoreDonationStatsDataList() {
        if (this.state.donatedStatsDataList && this.state.donatedStatsDataList.content && !this.state.donatedStatsDataList?.last) {
            let pageable = this.state.donatedStatsDataList?.pageable;
            this.setState({
                donatedStatsSuccess: true,
                donatedStatsPageNo: pageable.pageNumber + 1
            }, () => {
                this.fetchDonationStatsDataList(this.state.donatedStatsPageNo, 100, true)
            });
        }
    }

    onClickDonationViewDonorPopUp = (id, entity, type) => {
        this.setState({
            popUpData: { "modalType": type, "id": id, "entityType": this.props.entityType, "entityId": entity.entityId, "entitySlug": entity.slug},
            viewDonorFlag: true
        }, () => {
            window.$("#donationViewDonorsPopUp").modal("show");
        })
    }

    onCloseDonationViewDonorPopUp = () => {
        window.$("#donationViewDonorsPopUp").modal("hide");
        this.setState({
            viewDonorFlag: false
        })
    }

    componentDidMount() {
        this.fetchDonationStatsDataList(0, 100, false);
    }

    render() {
        const { donatedStatsDataList, viewDonorFlag, thresholdHeight, popUpData, donatedStatsSuccess } = this.state;
        const { entityType } = this.props;
        return (
            <>
                {donatedStatsDataList && donatedStatsDataList.content &&
                    <div id="accordion">
                        <InfiniteScroll                        
                            loadMore={this.fetchMoreDonationStatsDataList.bind(this)}
                            hasMore={!donatedStatsSuccess && !donatedStatsDataList?.last}
                            threshold={thresholdHeight}
                            loader={<span style={{ textAlign: "center", display: "block" }}
                            >
                                <div style={{ display: "inline-block", marginTop: "100px" }}>
                                    <Loader
                                        type="Grid"
                                        color="#dcde63"
                                        height={60}
                                        width={60}
                                        timeout={8000}
                                    />
                                </div>
                            </span>}
                        >
                            {(donatedStatsDataList && donatedStatsDataList?.content?.length === 0) && 
                                <div className="zdata zfeed col-12">
                                    <div className="desc">
                                        <p>There is no data to display</p>
                                        {/* {this.props.entityType === "PROJECT" ?
                                            <p>There are no donation project present.</p> :
                                            <p>There are no donation circle present.</p>
                                        } */}
                                    </div>
                                </div>
                            }
                            {donatedStatsDataList && donatedStatsDataList?.content.length > 0 && donatedStatsDataList?.content.map((statsData, index) => {
                                return (
                                    <div className="card" key={index}>
                                        <div className="card-header" id={'heading-' + index}>
                                            <div className="left">
                                                <div className='donatIndivProj'>
                                                    <figure>
                                                        <img src={statsData.imageUrl ? getCDNUrl(statsData.imageUrl) : this.props.entityType === "PROJECT" ? "https://cdn.dscovr.com/images/project-default.webp" : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = this.props.entityType === "PROJECT" ? "https://cdn.dscovr.com/images/project-default.jpg" : "https://cdn.dscovr.com/images/circleDefault.png" }} alt="" />
                                                    </figure>
                                                    <div>
                                                        <Link to={{pathname: entityType == "PROJECT" ? `/project/feeds/${statsData.slug}` : `/community/${statsData.slug}`, state: {userId: this.props.userId}}}><h5>{statsData.entityName}</h5></Link>
                                                        <p className="country">{statsData.country}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right"><a role="button" data-toggle="collapse"
                                                                    href={'#collapse-' + index} aria-expanded="false"
                                                                    className="btn"></a></div>
                                        </div>
                                        <div id={'collapse-' + index} className="collapse" data-parent="#accordion" aria-labelledby={'heading-' + index}>
                                            <div className="card-body">                                                
                                                {statsData.stats && statsData.stats.map((item) => {
                                                    return (
                                                        <div className='donationIndivInfoBlk'>
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <div className="selectedBank">
                                                                        <label>Bank Name :</label>
                                                                        <h4><span>{item.bankDetail?.nickName ? item.bankDetail?.nickName : item.bankDetail?.institutionName}</span></h4>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-8'>
                                                                    <div className="donationIndivInfoRow">
                                                                        <div className="donationStatsCol">
                                                                            <button className="btn" onClick={(e) => item.viewsCount > 0 ? this.onClickDonationViewDonorPopUp(item.donationId, statsData, "VIEW") : e.preventDefault()}>
                                                                                <span>{item.viewsCount}</span>
                                                                                <h5><i className="iEye_F" /> {item.viewsCount <= 1 ? 'View' : 'Views'}</h5>
                                                                            </button>
                                                                            {buttonShowDetails(item.donationId, "VIEW", entityType)}
                                                                        </div>
                                                                        <div className="donationStatsCol">
                                                                            {/* <button className="btn" onClick={(e) => item.donatedCount > 0 ? this.onClickDonationViewDonorPopUp(item.donationId, statsData, "DONORS") : e.preventDefault()}> */}
                                                                            <button className="btn" onClick={(e) => this.onClickDonationViewDonorPopUp(item.donationId, statsData, "DONORS")}>
                                                                                <span>{item.donatedCount}</span>
                                                                                <h5><i className="iNetwork_F" /> {item.donatedCount <= 1 ? 'Donor' : 'Donors'}</h5>
                                                                            </button>
                                                                            {buttonShowDetails(item.donationId, "DONORS", entityType)}
                                                                        </div>
                                                                        <div className="donationStatsCol">
                                                                            <button className="btn" onClick={(e) => item.donatedAmount > 0 ? this.onClickDonationViewDonorPopUp(item.donationId, statsData, "DONORS_AMOUNT") : e.preventDefault()}>
                                                                                <span>{item.indian ? <>&#8377;</> : <>&#36;</>}{convertCurrencySystem(item.donatedAmount)}</span>
                                                                                <h5><i className="iWallet" /> Amount Collected</h5>
                                                                            </button>
                                                                            {buttonShowDetails(item.donationId, "DONORS_AMOUNT", entityType)}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <ReactTooltip
                                                                    className="rtoolTip donationInfoTexts"
                                                                    id={"donationInfoTexts" + item.donationId}
                                                                    place="top"
                                                                    type="dark"
                                                                    textColor="#00394D"
                                                                    effect="solid"
                                                                    backgroundColor="#ffffff"
                                                                    clickable
                                                                    globalEventOff="click"
                                                                    getContent={(dataTip) => <>{dataTip}</>} />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>                                        
                                    </div>
                                )
                            })}
                        </InfiniteScroll>
                    </div>
                }
                {viewDonorFlag && <DonationViewDonorsPopUp popUpData={popUpData} onCloseDonationViewDonorPopUp={this.onCloseDonationViewDonorPopUp} key={uuid()} />}

            </>

        );
    }
}

export default memo(DonationStatsDataItem);