import React from "react";
import {authService} from "../auth";
import axios from "axios";
import httpService from "../AxiosInterceptors";
import cloneDeep from 'lodash/cloneDeep';

httpService.setupInterceptors();

class CookieAcceptance extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
        }
    }

    handleSubmit = () => {

        if (this.state.userData.userId) {
            const data = {
                userId: this.state.userData.userId
            }

            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/user/acceptCookies',
                data: data,
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }).then(response => {
                if (response && response.status === 202) {
                    let userData = cloneDeep(this.state.userData);
                    userData.cookiesAccepted = true;
                    authService.updateUserData(userData);
                    this.setState({userData: userData})
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    render() {
        return (this.state.userData && this.state.userData.userId && !this.state.userData.cookiesAccepted ?
                <div className="cookiespop">
                    <i className="iInfo_I"/> We use cookies to personalize and enhance your experience on our site.
                    Visit
                    our Privacy Policy to learn more or manage your personal preferences in our Cookie Policy
                    <a href="javascript:void(0)" onClick={this.handleSubmit} className="btn">Got It</a>
                </div> : null
        );
    }
}

export default CookieAcceptance
