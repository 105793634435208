import React, {PureComponent} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {getCDNUrl} from "../commons/CommonFunctions";
import * as _ from "lodash";
class ForumSharePreview extends PureComponent {

    state = {}

    redirectToProfile = () => {
        this.setState({redirectToProfile: true});
    }

    render() {
        return (
            <>
                {this.state.redirectToProfile && <Redirect push to={"/profile/" + this.props.item.customUrl}/>}
                <div className="sharepostpreview">
                    <div className="forumrow">
                        {this.props.entityItem.userId !== this.props.item.userId && !this.props.isHashtagPage &&
                        <div className="forumrowbott">
                            <div className="fuserfollow">
                                <figure className="userlogo" onClick={(e) => {
                                    e.preventDefault();
                                    this.redirectToProfile()
                                }} style={{cursor: 'pointer'}}><img
                                    src={this.props.item.profileImage != null ? getCDNUrl(this.props.item.profileImage) : (this.props.item.userType != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                    alt=""/></figure>
                                <a href="javascript:void(0)" onClick={(e) => {
                                    e.preventDefault();
                                    this.redirectToProfile()
                                }}>{this.props.item.username}</a>
                            </div>
                        </div>}

                        <h4><Link to={'/forumDetail/' + (!_.isEmpty(this.props.item.metaData) ? this.props.item.metaData?.slug : this.props.item.slug)}>{!_.isEmpty(this.props.item.metaData) ? this.props.item.metaData?.title : this.props.item.title}</Link></h4>
                        {this.props.item && this.props.item.addressDetail && this.props.item.addressDetail.country && <span className="address"><i
                            className="iLocation"/> {this.props.item.addressDetail.country}</span>}
                        {/* {
                            this.props.item.hashTags && this.props.item.hashTags.length > 0
                            && <div className="hposthash">
                                {
                                    this.props.item.hashTags.map((item) => {
                                        return <div key={item} className="hposthashitem"><a href={'/explore-hashtag/'+item}><i
                                        className="iHashtag"/>{item}</a></div>
                                    })
                                }
                            </div>
                        } */}
                    </div>
                </div>
            </>
        );
    }

}

export default ForumSharePreview
