import React, { Component } from 'react'
import { authService } from '../auth';
import { setEntityList } from '../reducer/showAllReducer';
import { connect } from 'react-redux';
import formUtils from '../project/component/commons/utils/formUtils';
import { CapitalizeFirstLetter } from '../utils/StringUtility';
import Scrollbars from 'react-custom-scrollbars';

class CommentUserListPop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            usersList: [],
            checkedUser: false
        }
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedEntityImages && personalInfo.resizedEntityImages["200x200"] != null) {
            return personalInfo.resizedEntityImages["200x200"];
        }
        return personalInfo.entityImageUrl;
    }

    componentWillReceiveProps(nextProps) {
        let data = this.state.userData;
        let userData = [{"entityId": data.userId, "entityName": data.type === "COMPANY" ? data.companyName : CapitalizeFirstLetter(data.firstName) + " " + CapitalizeFirstLetter(data.lastName), "entityImageUrl": data.profileImage, "entityType": data.type}];
        if(nextProps.entities && nextProps.entities.length > 0) {
            userData = userData.concat(nextProps.entities);
        }
        this.setState({
            usersList: userData
        })
    }

    onChangeCommentUser = (data) => {
        let userList = this.state.usersList;
        let selectUserData = userList && userList.find((user) => user.entityId === data.entityId);
        this.props.onSuccessCommentUser(selectUserData);
    }

    componentDidMount() {
        if(this.props.setEntityList) {
            this.props.setEntityList({userId: this.state.userData.userId});
        }
        window.$("#commentUserListPop").modal("show");
    }

    render() {
        const { usersList } = this.state;
        const { currentUserDetails, pageType } = this.props;
        return (
            <div  id="commentUserListPop" className="modal fade dgpopup reqendorse" tabIndex="-1" data-backdrop="static">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="similarAllpopWrap forwcontwrap" style={{maxWidth: '450px'}}>
                                <div>
                                    <div className="similarAllpopHeader">
                                        <h2>{pageType === "FEED" ? "React, Comment, Share" : "Comment"} as</h2>                                                    
                                    </div>
                                    <div className={"similarAllpopBody commentListBody"}>
                                        <Scrollbars
                                            className={'forwcontlistmain userRadioWrap'}
                                            autoHide
                                            autoHeight
                                            autoHeightMin={0}
                                            autoHeightMax={322}
                                            universal={true}
                                            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                            renderTrackVertical={props => <div {...props} style={{display: 'none'}} className="track-vertical"/>}
                                        >
                                            {usersList && usersList.length > 0 && usersList.map((data) => {
                                                return (
                                                    <label className="radio-container" onClick={(e) => this.onChangeCommentUser(data)}>
                                                        <input type="radio" name="radio" checked={currentUserDetails.userId === data.entityId} />
                                                        <span className="radio-checkmark"></span>
                                                        <div className='userRow'>
                                                            <figure className="userlogo">
                                                                <img src={formUtils.getImageUrlForEntities(data)} alt="" />
                                                            </figure>
                                                            <div>
                                                                <h3>{data.entityName}</h3>
                                                                <span>{data.entityType === 'CIRCLE' ? "Community" : data.entityType === 'BUSINESS_PAGES' ? "Organization" : ""}</span>
                                                            </div>
                                                        </div>
                                                    </label>
                                                )
                                            })}
                                        </Scrollbars>
                                    </div>
                                </div>
                                <div className="forwcontwrapbott justify-content-end">
                                    <div>
                                        <button className="butt btnsecondary close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.triggerCloseCommentUserList()}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setEntityList: (mode) => dispatch(setEntityList(mode))
    };
};

const mapStateToProps = (state) => {
    return {
        entities: state.showAllReducer.entityList
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(CommentUserListPop);
