import React, { Component } from 'react'
import { authService } from '../auth';
import { setEntityList } from '../reducer/showAllReducer';
import { connect } from 'react-redux';
import $ from "jquery";
import * as _ from "lodash";
import { CapitalizeFirstLetter } from '../utils/StringUtility';
import { getCDNUrl } from './CommonFunctions';

class CommonPostAsForumPoll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            usersList: [],
            entityDetails: {}
        }
    }

    getOptimizedImage(personalInfo) {
        if (
            personalInfo.resizedProfileImages &&
            personalInfo.resizedProfileImages["200x200"] != null
        ) {
            return personalInfo.resizedProfileImages["200x200"];
        }
        return personalInfo.profileImage;
    }

    setEntity = (user) => {
        this.setState({
            entityDetails: user
        })
        this.props.setEntity(user);
    }

    componentWillReceiveProps(nextProps) {
        let data = this.state.userData;
        let entityDetails = {};
        let userData = [{"entityId": data.userId, "entityName": data.type === "COMPANY" ? data.companyName : data.firstName, "entityImageUrl": data.profileImage, "entityType": data.type}];
        if(nextProps.entities && nextProps.entities.length > 0) {
            userData = userData.concat(nextProps.entities && nextProps.entities.filter((data) => data.entityType !== "CIRCLE"));
        }
        if(nextProps.draftUserId) {
            entityDetails = userData && userData.find((data) => data.entityId === nextProps.draftUserId);
            this.props.setEntity(entityDetails);
        }
        this.setState({
            usersList: userData,
            entityDetails: !_.isEmpty(entityDetails) ? entityDetails : {"entityId": data.userId, "entityName": data.type === "COMPANY" ? data.companyName : data.firstName, "entityImageUrl": data.profileImage, "entityType": data.type}
        })
    }

    componentDidMount() {
        // window.$('.dropdown > button').on("click", function (e) {
        //     var submenu = $(this);
        //     $('.dropdown ul .dropdown-menu').removeClass('show');
        //     submenu.next('.dropdown-menu > ul').addClass('show');
        //     e.stopPropagation();
        // });
        window.$('.dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.dropdown-menu').addClass('show');
            e.stopPropagation();
        });
        if(this.props.setEntityList) {
            this.props.setEntityList({userId: this.state.userData.userId});
        }
    }

    render() {
        const { usersList, entityDetails } = this.state;
        return (
            <div className="topposthead clearfix">
                <div className="dropdown">
                    <span>You are posting as{" "}</span>
                    <button type="button" className={"btn" + (usersList?.length > 1 ? " dropdown-toggle" : "")} data-toggle={usersList?.length > 1 ? "dropdown" : ""} aria-haspopup="true" aria-expanded="false" disabled={usersList?.length > 1 ? false : true}>
                        {entityDetails.entityType === "INDIVIDUAL" ? CapitalizeFirstLetter(entityDetails.entityName) : entityDetails.entityName}
                    </button>
                    <div className="dropdown-menu fadeIn animated">
                        <div className="demohead">
                            You are posting as{" "}
                            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {entityDetails.entityType === "INDIVIDUAL" ? CapitalizeFirstLetter(entityDetails.entityName) : entityDetails.entityName}
                            </button>
                        </div>
                        {usersList && usersList.length > 0 && usersList.map((data) => {
                            return (
                                <li className="dropdown-item">
                                    <a href="javascript:void(0)" onClick={(e) => this.setEntity(data)}>
                                        <figure className="usimg">
                                            <img src={!_.isUndefined(data.entityImageUrl) && !_.isNull(data.entityImageUrl) ? getCDNUrl(data.entityImageUrl) : data.entityType === "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.webp" : "https://cdn.dscovr.com/images/DefaultBusiness.webp"}
                                                onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/DefaultBusiness.png"}}
                                                alt=""/>
                                        </figure>
                                        <div>
                                            <h5>{data.entityName}</h5>
                                        </div>
                                    </a>
                                </li>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setEntityList: (mode) => dispatch(setEntityList(mode))
    };
};

const mapStateToProps = (state) => {
    return {
        entities: state.showAllReducer.entityList
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(CommonPostAsForumPoll);
