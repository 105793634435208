import React from 'react'
import $ from 'jquery';
import Header from '../Header';
import LeftPanel from '../LeftPanel';
import './main-userdashboard.min.css';
import {Link, Redirect} from 'react-router-dom';
import {authService} from '../auth';
import OrganizationAdmin from '../commons/OrganizationAdmin';
import axios from 'axios';
class UserDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            userId: "",
            userType: this.props?.location?.state?.userId && this.props?.location?.state?.userId !== authService.getUserDetailsFromLocalStorage().userId ? "COMPANY" : authService.getUserDetailsFromLocalStorage().type,
            isValidSuperAdminFlag: false
        };
    }

    checkValidSuperAdmin = (userId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_userServiceURL}/profile/isvalidsuperAdmin?requestingUserId=${this.state.userData.userId}&companyId=${userId}` ,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data  && response.data.status === '200 OK') {
                this.setState({isValidSuperAdminFlag:response.data.body});
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    componentDidMount() {
        document.title = "User Dashboard | WeNaturalists";
        this.setState({
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        })
        if(this.props?.location?.state?.userId && this.props?.location?.state?.userId !== this.state.userData.userId) {
            this.checkValidSuperAdmin(this.props?.location?.state?.userId);
        }
    }

    render() {
        const { userId, isValidSuperAdminFlag, userData } = this.state;
        return <div className="wrapper projectpage networkpage">
            
            <Header/>
            <main className="dgcontainer hm3col">
                <div className="container">
                    <div className="row">

                        <LeftPanel/>

                        <div className="col-md-9">                            
                            <div className="projectinn userDashbrdPage">

                                <h1 className="h1ttl">User Dashboard</h1>
                                {userId && <OrganizationAdmin userId={userId} isNotTopSpace={true} isMarginTop={true} />}
                                <div className="userDashbrdWrap">
                                    <div className="userDashbrdLinkTab">
                                        <Link className="userDashbrdLinkTabgrid dgcard iDonation-stats" to={{pathname: "/fund-raise", state: {userId: userId}}}>
                                            <i className="iDonation-stats" />
                                            <p>Fund Raise Stats</p>
                                        </Link>
                                        <Link className="userDashbrdLinkTabgrid dgcard ianalytics" to={{pathname: "/analytics", state: {userId: userId}}}>
                                            <i className="ianalytics" />
                                            <p>Analytics</p>
                                        </Link>
                                        <Link className="userDashbrdLinkTabgrid dgcard iDraft_F" to={{pathname: "/forum-poll-draft", state: {userId: userId}}}>
                                            <i className="iDraft_F" />
                                            <p>Drafts</p>
                                        </Link>
                                        <Link className="userDashbrdLinkTabgrid dgcard iHidden-Content" to={{pathname: "/hidden-content", state: {userId: userId}}}>
                                            <i className="iHidden-Content" />
                                            <p>Hidden Content</p>
                                        </Link>
                                    </div>
                                </div>

                                <div className="othuserDashbrdLinks">
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/manage-invitation", state: {userId: userId}}}>
                                                <i className="iManage-Invitations" /> Manage Invitations
                                            </Link>
                                        </div>
                                        {(isValidSuperAdminFlag || (userId === userData.userId)) && 
                                            <> 
                                               <div className='col-sm-6'>
                                                    <Link className="userDashbrdLinkRow dgcard" to={{pathname: `/${this.state.userData.customUrl}/studio`, state: {userId: userId}}}>
                                                        <i className="istudio" /> Manage Studio
                                                    </Link>
                                                </div> 
                                                <div className='col-sm-6'>
                                                    <Link className="userDashbrdLinkRow dgcard" to={{pathname: this.state.userType === 'COMPANY' ? "/organization-privacy-settings" : "/privacy-settings", state: {userId: userId}}}>
                                                        <i className="iSettings-Privacy" /> Settings & Privacy</Link>
                                                </div>
                                            </>
                                        }
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/saved-posts", state: {userId: userId}}}>
                                                <i className="iBookmark_F" style={{fontSize: '30px'}} /> Saved Items
                                            </Link>
                                        </div>
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/blocked-users", state: {userId: userId}}}>
                                                <i className="ideactivated" style={{fontSize: '30px'}} /> Blocked Users
                                            </Link>
                                        </div>
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/my-forum", state: {userId: userId}}}>
                                                <i className="iForum_F" style={{fontSize: '30px'}} /> My Forum
                                            </Link>
                                        </div>
                                        <div className='col-sm-6'>
                                            <Link className="userDashbrdLinkRow dgcard" to={{pathname: "/my-poll", state: {userId: userId}}}>
                                                <i className="iPolls_FL" style={{fontSize: '30px'}} /> My Poll
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </main>

        </div>
    }
}

export default UserDashboard;
