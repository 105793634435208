import React, {Component} from "react";
import "../../profile/container/profile/css/main-profile.min.css";
import "../../project/container/Project/css/main-projects.min.css";
import "react-toastify/dist/ReactToastify.css";
import {Link,Redirect} from "react-router-dom";
import axios from "axios";
import {authService} from "../../auth";
import Header from "../../Header";
import NotFound from "../../404";
import { userAgentGetData } from "../../commons/CommonFunctions";
import LeftPanel from "../../LeftPanel";

class DeletedCircle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData:authService.getUserDetailsFromLocalStorage(),
            notFoundStatusCode: '',
        };
    }

    componentDidMount() {
        if(this.props.location && this.props.location.state && this.props.location.state.customUrl){
            this.getUserDataByCustomUrl(this.props.location.state.customUrl)
        }

    }

    getUserDataByCustomUrl(customUrl) {
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL +
                "/profile/get_by_custom_url?customUrl=" +
                customUrl+'&otherUserId='+this.state.userData.userId+userAgentGetData(),
            headers: {"Content-Type": "application/json"},
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.setState({data: response.data.body},()=>{
                        if(this.state.data.deactivatedByAdmin){
                            this.setState({text:'This account is deactivated as per Community Guidelines!'})
                        }
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    this.setState({
                        notFoundStatusCode: err.response.status,
                    });
                }
            });
    }


    render() {
        if (!this.state.loginSuccess) {
            return <Redirect to='/login'/>
        } else if(this.state.notFoundstatusCode === 404){
            return <><Header EditedText={true}/>
            <main className="dgcontainer hm3col">
               <div className="container">
                   <NotFound/>
               </div>
            </main></>
        } else {
            return <div className="wrapper projectpage profilepage pabout ppersonal staticPage">
                <Header /* hamburgerVisibility={false} *//>
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="bk_dactiveprflbx">
                                    <img src="https://cdn.dscovr.com/images/Deactivated.webp" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/Deactivated.png"}} alt=""/>
                                        <p>This community has been deleted by the member!</p>
                                    <Link to={"/explore"} className="btn connect_btn">Explore More <i
                                        className="iArrow_Right"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <LeftPanel />
            </div>
        }
    }

}

export default DeletedCircle

