import {call, put, takeEvery} from "redux-saga/effects";
import axios from 'axios';
import _ from 'lodash';
import {pageClickFailure, pageClickSuccess} from "../../reducer/blogPageClick/blogPageClick"
import httpService from "../../AxiosInterceptors";
import { projectPageClickFailure, projectPageClickSuccess } from "../../reducer/projectPageClick/projectPageClick";

httpService.setupInterceptors();

function* asyncUserClick(action) {
    const body = {
        "entityId": action.payload.entityId,
        "userId": action.payload.userId,
        "timestamp": action.payload.timestamp,
        "entityType": action.payload.entityType
    };
    try {
        const response = yield call(() =>
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/recommend/pageclickhook/',
                headers: {'Content-Type': 'application/json'},
                data: body,
                withCredentials: true
            })
        );
        console.log(response.data)
        yield put(pageClickSuccess(response.data));

    } catch (err) {

        let errorResponse = _.get(
            err,
            'response.data',
            'Unable to get data, Please try after sometime',
        );
        yield put(pageClickFailure(errorResponse));
    }
}

function* asyncProjectPageClick(action) {
    const body = {
        "projectId": action.payload.projectId,
        "userId": action.payload.userId, 
        "type": action.payload.type
    };
    try {
        const response = yield call(() =>
            axios({
                method: 'POST',
                url: process.env.REACT_APP_userServiceURL + '/recommend/pageclickhookproject',
                headers: {'Content-Type': 'application/json'},
                data: body,
                withCredentials: true
            })
        );
        console.log(response.data)
        yield put(projectPageClickSuccess(response.data));

    } catch (err) {

        let errorResponse = _.get(
            err,
            'response.data',
            'Unable to get data, Please try after sometime',
        );
        yield put(projectPageClickFailure(errorResponse));
    }
}

export function* watchUserClick() {
    yield takeEvery("PAGE_CLICK_REQUEST", asyncUserClick);
}

export function* watchProjectUserClick() {
    yield takeEvery("PROJECT_PAGE_CLICK_REQUEST", asyncProjectPageClick);
}