import React from 'react'
import Header from "../Header";
import {authService} from "../auth";
import axios from "axios";
import '../FaQ/main-faq.min.css';
import LeftPanel from '../LeftPanel';
import RightPanel from '../RightPanel';
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import * as _ from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import Follow from "../commons/Follow";
import Loader from "react-loader-spinner";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import {getCDNUrl} from "../commons/CommonFunctions";
import httpService from "../AxiosInterceptors";
import MutualConnect from "../commons/MutualConnect";
import {NavLink} from "react-router-dom";
import UserReactionComponent from '../UserReaction/UserReactionComponent';
import FeedbackCard from '../commons/FeedbackCard';
import uuid from 'react-uuid';

httpService.setupInterceptors();

class Feedback extends React.Component {
    constructor(props) {
        super(props);
        let validation = {
            title: {},
            description: {}
        }
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            value: 0,
            error: false,
            messages: null,
            validation,
            submitted: false,
            feedbackGiven: true,
            averageRating: 0,
            averageRatingForLastThirtyDays: 0,
            title: '',
            description: '',
            feedbacks: [],
            feedbacksCount: 0,
            pageSize: 5,
            pageNo: 0,
            pagination: false,
            active: 'iStar staractive',
            inActive: 'iStar',
            uniqueIdOfWeNat: '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed',
            errorMessage: '',
            disable: true
        }

    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    componentDidMount() {
        document.title = "Explore a new world of Naturalists | WeNaturalists";
        this.checkConfigFeedback();
        this.getAllFeedbacks(0, this.state.pageSize, false);
    }

    fetchMoreData() {
        let feedbacks = this.state.feedbacks;

        if (this.state.feedbacks) {
            this.setState({isResultLoading: true});
            let pageable = feedbacks.pageable;
            this.getAllFeedbacks(pageable.pageNumber + 1, this.state.pageSize, true);
        }
    }


    getAllFeedbacks(page, size, concatWithPrevious) {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/feedback/getFeedbacksByActivityId/'
                + this.state.uniqueIdOfWeNat + '?userId=' + this.state.userData.userId + "&page=" + page + "&size=" + size,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.status === 200) {
                let pagedData = response.data.body;
                if (concatWithPrevious === true && this.state.feedbacks && this.state.feedbacks.content) {
                    pagedData.content = this.state.feedbacks.content.concat(pagedData.content);
                }
                this.setState({
                    'feedbacks': pagedData,
                    'responseMessage': response.data.message,
                    'isResultLoading': false,
                    'pagination': response.data.body.last
                });
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                isResultLoading: false
            });
        });
    }


    checkConfigFeedback() {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/feedback/getFeedbacksConfigByActivityId/' + this.state.uniqueIdOfWeNat + '?userId=' + this.state.userData.userId,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.setState({'feedbackGiven': res.body.feedbackGiven});
                this.setState({'averageRating': res.body.averageRating});
                this.setState({'averageRatingForLastThirtyDays': res.body.averageRatingForLastThirtyDays});
            }
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    error: {message: err.response.data.message, err: err.response}
                });
            }
        });
    };


    handleTitleChange = (e) => {
        if (e.target.value.length > 0) {
            this.setState({disable: false});
        } else {
            this.setState({disable: true});
        }
    };

    handleFeedbackChange = (e) => {
        if (e.target.value.length > 0) {
            this.setState({disable: false});
        } else {
            this.setState({disable: true});
        }
    };
    setValue = (event, value) => {
        this.setState({'value': value});
        if (value > 0) {
            this.setState({disable: false});
        } else {
            this.setState({disable: true});
        }
    };

    redirectToProfile = (customUrl) => {
        window.location.href = "/profile/" + customUrl;
    }


    validateForm() {
        this.state.validation.description = {};
        let validationRequired = false;
        let errors = {};
        let formIsValid = true;
        if (_.isUndefined(this.state.description) ||
            _.isEmpty((this.state.description || "").toString()) ||
            _.isNull(this.state.description)) {
            this.setState({
                error: {message: 'Please provide a valid description'}
            });
            formIsValid = false;
            validationRequired = true;
        }
        if (_.isUndefined(this.state.title) ||
            _.isEmpty((this.state.title || "").toString()) ||
            _.isNull(this.state.title)) {
            this.setState({
                error: {message: 'Please enter a valid title'}
            });
            formIsValid = false;
            validationRequired = true;
        }
        if (_.isUndefined(this.state.value) ||
            _.isEmpty((this.state.value || "").toString())) {
            this.setState({
                error: {message: 'Please enter a valid feedback'}
            });
            formIsValid = false;
            validationRequired = true;
        }
        this.setState({
            errors: errors
        });
        setTimeout(() => {
            this.setState({
                error: {},
            });
        }, 5000);
        return formIsValid;

    }


    handleSubmit = (event) => {
        event.preventDefault();
        this.state.title = this.title.value;
        this.state.description = this.description.value;
        this.setState({isLoading: true})
        if (this.validateForm()) {
            this.setState({'submitted': true});
            let body = {
                "userId": this.state.userData.userId,
                "entityId": this.state.uniqueIdOfWeNat,
                "title": this.state.title,
                "description": this.state.description,
                "rating": this.state.value
            };
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/backend/feedback/create/',
                headers: {'Content-Type': 'application/json'},
                data: body,
                withCredentials: true
            }).then((response) => {
                let res = response.data;
                if (res.status === '201 CREATED') {
                    this.title.value = '';
                    this.description.value = '';
                    this.state.value = '';
                    this.getAllFeedbacks(0, this.state.pageSize, false);
                    this.checkConfigFeedback();
                    this.setState({'title': '', 'description': ''});
                    this.state.isSubmitted = true;
                    this.setState({isLoading: false});
                    this.setState({disable: true})
                }
            }).catch((err) => {
                this.title.value = '';
                this.description.value = '';
                this.state.value = '';
                if (err && err.response && err.response.data) {
                    this.setState({
                        isLoaded: true,
                        isSubmitted: false,
                        error: {message: err.response.data.message, err: err.response}
                    });
                    if (this.state.error.message === 'you have already given feedback for this topic') {
                        this.setState({
                            error: {message: 'You have already provided a feedback'},
                            disable: true
                        });
                    }
                }
            });
            setTimeout(() => {
                this.setState({
                    error: {},
                    isLoading: false
                });
            }, 5000);
        }
    };

    render() {
        return <div className='wrapper'>
            <Header/>
            <main className="dgcontainer hm3col">
                <div className="container">
                    <div className="row">
                        <LeftPanel/>

                        <div className="col-md-5">
                            <div className="projectinn projdetailscont pdfeedback">

                                <div className="projdetlswrap">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="pdfeedbackwrap">

                                                <div className="pdftopratingview">
                                                    <div>
                                                        <span>All Reviews</span>
                                                        <div className="gbrating">
                                                            <i className={this.state.averageRating > 0 ? this.state.active : this.state.inActive}/>
                                                            <i className={this.state.averageRating > 1 ? this.state.active : this.state.inActive}/>
                                                            <i className={this.state.averageRating > 2 ? this.state.active : this.state.inActive}/>
                                                            <i className={this.state.averageRating > 3 ? this.state.active : this.state.inActive}/>
                                                            <i className={this.state.averageRating > 4 ? this.state.active : this.state.inActive}/>
                                                        </div>
                                                        <div
                                                            className="ratingresult">{/*this.state.averageRating*/} Average
                                                            Rating
                                                        </div>
                                                    </div>
                                                    <div className="last30">
                                                        <span>Recent Reviews</span>
                                                        <div className="gbrating">
                                                            <i className={this.state.averageRatingForLastThirtyDays > 0 ? this.state.active : this.state.inActive}/>
                                                            <i className={this.state.averageRatingForLastThirtyDays > 1 ? this.state.active : this.state.inActive}/>
                                                            <i className={this.state.averageRatingForLastThirtyDays > 2 ? this.state.active : this.state.inActive}/>
                                                            <i className={this.state.averageRatingForLastThirtyDays > 3 ? this.state.active : this.state.inActive}/>
                                                            <i className={this.state.averageRatingForLastThirtyDays > 4 ? this.state.active : this.state.inActive}/>
                                                        </div>
                                                        <div
                                                            className="ratingresult">{/*this.state.averageRatingForLastThirtyDays*/} Average
                                                            Rating in previous 30 days
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="addfeedbackform">
                                                    <figure className="user"><img
                                                        src={this.state.userData.profileImage != null ? getCDNUrl(this.getOptimizedImage(this.state.userData))
                                                            : (this.state.userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                                        alt=""/></figure>
                                                    <div className="addfeedbformbox">
                                                        <form onSubmit={this.handleSubmit}>
                                                            <h4>Share your Feedback</h4>
                                                            <div className="gbrating">
                                                                <Box component="fieldset" mb={4}
                                                                     borderColor="transparent">
                                                                    <Rating
                                                                        name="simple-controlled"
                                                                        value={this.state.value}
                                                                        onChange={(event, newValue) => {
                                                                            this.setValue(event, newValue);
                                                                        }}
                                                                    />
                                                                </Box>

                                                            </div>
                                                            <div className="form-group">
                                                                <input type="text" className="form-control dginput"
                                                                       ref={input => this.title = input}
                                                                       onChange={this.handleTitleChange}
                                                                       placeholder="Add title..."/>
                                                            </div>
                                                            <div className="form-group">
                                                                <textarea name="" id=""
                                                                          ref={input => this.description = input}
                                                                          className="form-control dginput"
                                                                          onChange={this.handleFeedbackChange}
                                                                          placeholder="Write feedback here..."/>
                                                            </div>

                                                            <div className="bottbtnwrap">
                                                                {this.state.error.message !== "" && this.state.error.message !== undefined &&
                                                                <div className="login-feedback-error">
                                                                    {this.state.error.message}
                                                                </div>
                                                                }
                                                                {this.state.success &&
                                                                <div className="login-feedback-error">
                                                                    {this.state.messages}
                                                                </div>}
                                                                <input type="submit" value="Submit"
                                                                       disabled={this.state.disable}
                                                                       className="btn btnsubmit"/>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="usernotifwrap"
                                                     data-mcs-theme="minimal-dark">
                                                    <div id="scrollableDiv">
                                                        {
                                                            this.state.feedbacks && this.state.feedbacks.content && this.state.feedbacks.content.length > 0
                                                            && <InfiniteScroll
                                                                loadMore={this.fetchMoreData.bind(this)}
                                                                hasMore={!this.state.isResultLoading && !this.state.pagination}
                                                                loader={<Loader
                                                                    type="ThreeDots"
                                                                    color="#00394D"
                                                                    height={50}
                                                                    width={60}
                                                                    timeout={6000}
                                                                />}
                                                            >
                                                                <div className="feedbreviewlist">
                                                                    {this.state.feedbacks && this.state.feedbacks.content && this.state.feedbacks.content.length > 0 && this.state.feedbacks.content.map((data, index) => {
                                                                        return (
                                                                        // <div className="feedbreviewrow"
                                                                        //             key={index}>
                                                                        //     <div className={"forumcommtvlisttop" + (data.userType === "COMPANY" ? " orgnz" : "")}>
                                                                        //         <div className="fuserblk">
                                                                        //             <figure onClick={(e) => {
                                                                        //                 e.preventDefault();
                                                                        //                 this.redirectToProfile(data.customUrl)
                                                                        //             }}><a href=""><img
                                                                        //                 src={!_.isNull(data.profileImage) && !_.isUndefined(data.profileImage) ? getCDNUrl(this.getOptimizedImage(data)) : data.userType != "COMPANY" ? 'https://cdn.dscovr.com/images/users1.png' :  "https://cdn.dscovr.com/images/DefaultBusiness.png"}
                                                                        //                 alt=""/></a></figure>
                                                                        //             <div style={{flex: "1"}}>

                                                                                        
                                                                        //                 <div onClick={(e) => {
                                                                        //                     e.preventDefault();
                                                                        //                     this.redirectToProfile(data.customUrl)
                                                                        //                 }}
                                                                        //                 style={{display: "inline-block", cursor:"pointer"}}>
                                                                        //                 {data.userName}
                                                                                        
                                                                        //                 </div>
                                                                        //                 {(data.connectDepth && data.userId != this.state.userData.userId) ?
                                                                        //                     <MutualConnect  connectDepth = {data.connectDepth}></MutualConnect>:null}
                                                                        //                     {data.userConnectStatus && data.userConnectStatus.connectStatus === "PENDING_CONNECT" ?
                                                                        //                     <>
                                                                        //                         <NavLink className="follow follow-link active" to="/connected" data-tip="msg to show"  data-place='bottom' data-for={'Pending'+data.userId}><i className="iTickedUser" /> Pending</NavLink>
                                                                        //                     </>
                                                                        //                     :data.userConnectStatus && data.userConnectStatus.connectStatus === 'REQUEST_RECEIVED' ?
                                                                        //                         <>
                                                                        //                             <NavLink className="follow follow-link active" to="/manage-invitation" data-tip="msg to show"  data-place='bottom' data-for={'Pending'+data.userId}><i className="iTickedUser" /> Pending</NavLink>
                                                                        //                         </>
                                                                        //                     : data.userConnectStatus && data.userConnectStatus.connectStatus === 'DEADLOCK_PENDING_STATUS' ?
                                                                        //                     <></>                
                                                                        //                     : data && ( !data.connectDepth  ||( data.connectDepth  && data.connectDepth < 1 ))&&
                                                                        //                         <Follow followed={data.followed} userId={data.userId}
                                                                        //                             deactivated={data.deactivated}/>
                                                                        //                         }
                                                                        //                 {/* <Follow followed={data.followed}
                                                                        //                         userId={data.userId}/> */}
                                                                        //                 {/*<span>3:40 PM 20th Nov 2019</span>*/}
                                                                        //                 <span>{new Intl.DateTimeFormat("en", {
                                                                        //                     hour: "numeric",
                                                                        //                     minute: "numeric",
                                                                        //                     year: "numeric",
                                                                        //                     month: "short",
                                                                        //                     day: "2-digit"
                                                                        //                 }).format(data.time)}</span>
                                                                        //             </div>
                                                                        //         </div>
                                                                        //         <div className="fdate">
                                                                        //             <UserReactionComponent item={data} key={data.id} />
                                                                        //         </div>
                                                                        //     </div>
                                                                        //     <div className="forumcommtvlistdesc">
                                                                        //         <div className="gbrating">
                                                                        //             <Box component="fieldset" mb={4}
                                                                        //                  borderColor="transparent">
                                                                        //                 <Rating
                                                                        //                     name="simple-controlled"
                                                                        //                     value={data.rating}
                                                                        //                     readOnly={true}
                                                                        //                 />
                                                                        //             </Box>
                                                                        //         </div>
                                                                        //         <h4>{data.title}</h4>
                                                                        //         <ReadMoreReadLess
                                                                        //             key={data.id}
                                                                        //             id={data.id}
                                                                        //             description={data.description}/>
                                                                        //     </div>
                                                                        // </div>

                                                                        <FeedbackCard data={data} key={uuid()} />
                                                                        )
                                                                    })}
                                                                </div>
                                                            </InfiniteScroll>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <RightPanel {...this.props}/>
                    </div>
                </div>


            </main>
        </div>
    }
}

export default Feedback
