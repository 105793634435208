import React, { Component } from "react";
import { authService } from "../auth";
import PostViewsListPopUp from "../commons/PostViewsListPopUp";
import { getViewImpressionCount } from "../services/PostApi";
class CommonPostViews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            viewCount: props?.viewCount ? props?.viewCount : 0,
            impressionsCount: props?.impressionsCount ? props?.impressionsCount : 0,
            viewkModalPop: false,
            popupType: "",
            shareCount: props?.shareCount ? props?.shareCount : 0,
            interactionsCount: props?.interactionsCount ? props?.interactionsCount : 0,
            successMessage: ""
        }
    }

    triggerViewClickCLose = () => {
        this.setState({
            viewkModalPop: false,
            popupType: ""
        })
        window.$("#postViewsPopUp").modal("hide");
    }

    openPostViews = (type) => {
        this.setState({
            viewkModalPop: true,
            popupType: type
        }, () => {
            window.$("#postViewsPopUp").modal("show");
        })
    }

    getPostViewImpressionCount = async () => {
        let response = await getViewImpressionCount(this.props.entityId, this.props?.entityType);
        if (response) {
            this.setState({
                viewCount: response?.data?.viewsCount,
                impressionsCount: response?.data?.impressionsCount,
                successMessage: "Success!"
                //shareCount : response?.data?.shareCount
            })
            if(this.props.showStatsFunction && (response?.data?.viewsCount > 0 || response?.data?.impressionsCount > 0 || this.props?.shareCount > 0)) {
                this.props.showStatsFunction();
            }
        }
    }

    componentDidMount() {
        if(this.props.analyticsPanelEnabled || this.props.entityType === "USER" || this.props.entityType === "CIRCLE" || this.props.details) {
            this.getPostViewImpressionCount();
        }
    }

    render() {
        const { viewCount, impressionsCount, viewkModalPop, shareCount, popupType, interactionsCount, successMessage } = this.state;
        const { entityId, entityType } = this.props;
        return (
            <>
                {(entityType === "USER" || entityType === "CIRCLE") ? 
                    <span className='viewCountUi'>
                        {entityType === "CIRCLE" ? 
                            <a href="javascript:void(0)" onClick={() => this.openPostViews("VIEW")}><strong>{viewCount}</strong> View{viewCount > 1 && "s"}</a> :
                            <button class="bkModalBtn" onClick={() => this.openPostViews("VIEW")}><strong>{viewCount}</strong> View{viewCount > 1 && "s"}</button>
                        }
                    </span> :
                    ((successMessage === "Success!") && 
                        <div className="potViewGlobalBox">
                            <div className="allCards">
                                <div className="pstVwCol">
                                    <button type="button" className={"btn"} style={{cursor: "default"}}>
                                        <h6>{impressionsCount}</h6>
                                        <span>Impression{impressionsCount > 1 && 's'}</span>
                                    </button>
                                </div>
                                <div className="pstVwCol">
                                    <button type="button" className={"btn"} onClick={() => this.openPostViews("VIEW")}>
                                        <h6>{viewCount}</h6>
                                        <span>View{viewCount > 1 && 's'}</span>
                                    </button>
                                </div>
                                <div className="pstVwCol">
                                    <button type="button" className={"btn"}  onClick={() => this.openPostViews("REPOST")}>
                                        <h6>{shareCount}</h6>
                                        <span>Repost{shareCount > 1 && "s"}</span>
                                    </button>
                                </div>
                                <div className="pstVwCol">
                                    <button type="button" className={"btn"} style={{cursor: "default"}}>
                                        <h6>{interactionsCount}</h6>
                                        <span>Interaction{interactionsCount > 1 && "s"}</span>
                                    </button>
                                </div>                            
                            </div>
                            <p className="statsTxtinfo">Impression data is from 10th November 2023</p>
                        </div>
                    )
                }
                {viewkModalPop && <PostViewsListPopUp entityId={entityId} triggerViewClickCLose={this.triggerViewClickCLose} popupType={popupType} entityType={entityType} />}
            </>
        )
    }
}

export default CommonPostViews;