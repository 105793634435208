import React from 'react'
import {authService} from "../auth";
import httpService from '../AxiosInterceptors';
import axios from 'axios'
import {UserService} from "../UserData";
import {setOtherUserData, setOtherUserDataOnSuccess} from "../reducer/auth";
import {connect} from "react-redux";
import Image from "../commons/Image";
import OfflineParticipantRequestPopUp from "../commons/OfflineParticipantRequestPopUp";
import $ from "jquery";
import {Link} from 'react-router-dom';
import {convertUnicode, getCDNUrl} from "../commons/CommonFunctions";
import MembershipRequestPopupforMember from "../commons/MembershipRequestPopupforMember";
import MemberShipRequestPopupforAdmin from "../commons/MemberShipRequestPopupforAdmin";
import ProfileVisibility from "../commons/ProfileVisibility";
import TransferAdmin from "../commons/TransferAdmin";
import DeactivationErrorPopUpNotification from "../commons/DeactivationErrorPopUpNotification";
import MembershipRequestPopupForAdminApproval from "../commons/MembershipRequestPopupForAdminApproval";
import ProjectParticipantAdminApprovalPopup from "../commons/ProjectParticipantAdminApprovalPopup";
import InviteeRequestPopUp from "../commons/InviteeRequestpopup";
import OperatorRequest from "../commons/OperatorRequest";
import Admin from "../commons/Admin";
import AdminTransferModel from "../commons/AdminTransferModel";
import * as constants from "constants";

httpService.setupInterceptors();

class Notification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            pageSize: 6,
            pageNo: 0,
            thresholdHeight: 500,
            pagination: false,
            notifications: [],
            contentLength: 0,
            allRead: false,
            notiCount: 0,
            bellDisplay: false,
            participantRequestTitle: '',
            participantNotificationId: '',
            entityId: '',
            participantRequestId: '',
            projectType: '',
            OpenInviteeRequestModal: null,
            OpenParticipantRequestModal: null,
            OpenTransferAdminRequestModal: null,
            OpenAdminChangeStatusRequestModal: null,
            openAdminTransferRequestModal:null,
            OpenOperatorRequestModal:null,
            openMembershipRequestForMemberPopUp: null,
            openMembershipRequestForAdminPopUp: null,
            openMembershipApprovalForAdminPopUp: null,
            openProjectParticipantsAdminApprovalPopUp: null,
            notificationId: '',
            entityTitle: '',
            requestedUserId: '',
            senderUserId: '',
            username: '',
            profileImage: '',
            customUrl: '',
            userType: '',
            userCountry: '',
            adminId: null,
            openProfileVisibilityModal: false,
            adminRightQueueId: null,
            applyErrorMessage: 'Your profile is undergoing deactivation process. During this period you will not be able to apply a project',
        };
        this.updateNotificationDataForOfflineRequest = this.updateNotificationDataForOfflineRequest.bind(this);

    }

    componentDidMount() {

        this.getNotificationsCount();
    }

    componentWillReceiveProps(props) {
        if (props.notificationReceivedResponse !== undefined) {

            if (props.notificationReceivedResponse && props.notificationReceivedResponse.payload &&
                props.notificationReceivedResponse.payload.body) {
                let notificationBody = JSON.parse(props.notificationReceivedResponse.payload.body);
                if (notificationBody.userEvent === 'CHANGE_PASSWORD') {
                    authService.clearData();
                    window.location = "/login";
                } else {
                    this.getNotificationsCount();
                }
            } else {
                this.getNotificationsCount();
            }

        }
    }

    triggerProfileVisibilityClose = () => {
        this.setState({'openProfileVisibilityModal': false});
    }

    triggeredProfileVisibilityCloseOnSuccess = () => {
        this.setState({'openProfileVisibilityModal': false});
    }

    triggerInviteeRequestPopUpClose = () => {
        this.setState({'OpenInviteeRequestModal': null});
    }


    triggerMembershipForMemberPopUpClose = () => {
        this.setState({'openMembershipRequestForMemberPopUp': null});
    }

    triggerMembershipForAdminPopUpClose = () => {
        this.setState({'openMembershipRequestForAdminPopUp': null});
    }

    setOpenInviteeRequestModal = (projectId) => {
        this.setState({'OpenInviteeRequestModal': projectId});
    }

    setOpenMembershipRequestForMemberModal = (entityId) => {
        this.setState({'openMembershipRequestForMemberPopUp': entityId});
    }

    setOpenMembershipApprovalForAdminModal = (entityId) => {
        this.setState({'openMembershipApprovalForAdminPopUp': entityId});
    }

    triggerMembershipApprovalForAdminPopUpClose = () => {
        this.setState({'openMembershipApprovalForAdminPopUp': null});
    }


    setOpenProjectParticipantsAdminApprovalModal = (entityId) => {
        this.setState({'openProjectParticipantsAdminApprovalPopUp': entityId});
    }

    triggerProjectParticipantsAdminApprovalPopUpClose = () => {
        this.setState({'openProjectParticipantsAdminApprovalPopUp': null});
    }

    setOpenMembershipRequestFoAdminModal = (entityId) => {
        this.setState({'openMembershipRequestForAdminPopUp': entityId});
    }

    setOpenParticipantRequestModal = (projectId) => {
        this.setState({'OpenParticipantRequestModal': projectId});
    }


    triggerParticipantRequestPopUpClose = () => {
        this.setState({'OpenParticipantRequestModal': null});
    }

    setTransferAdminRequestModal = (entityId) => {
        this.setState({'OpenTransferAdminRequestModal': entityId});
    }


    triggerTransferAdminRequestPopUpClose = () => {
        this.setState({'OpenTransferAdminRequestModal': null});
    }

    setAdminChangeStatusRequestModal = (entityId) => {
        this.setState({'OpenAdminChangeStatusRequestModal': entityId});
    }

    triggerAdminChangeStatusRequestPopUpClose = () => {
        this.setState({'OpenAdminChangeStatusRequestModal': null});
    }

    setOpenAdminTransferRequestModal = (entityId) => {
        this.setState({'openAdminTransferRequestModal': entityId});
    }

    triggerOpenAdminTransferRequestPopUpClose = () => {
        this.setState({'openAdminTransferRequestModal': null});
    }

    setOperatorRequestModal = (entityId) => {
        this.setState({'OpenOperatorRequestModal': entityId});
    }

    triggerOperatorRequestPopUpClose = () => {
        this.setState({'OpenOperatorRequestModal': null});
    }

    fetchMoreData = () => {
        this.setState({isResultLoading: true, pageNo: this.state.pageNo + 1}, () => {
            this.getNotifications(this.state.pageNo, this.state.pageSize);
        })

    };

    updateNotificationDataForOfflineRequest = (id) => {
        let notifications = this.state.notifications;
        notifications.forEach((data) => {
            if (data.id === id) {
                data.params["changeRequired"] = 'no';
            }
        })

    }

    updateNotificationDataForInviteeRequest = (id) => {
        let notifications = this.state.notifications;
        notifications.forEach((data) => {
            if (data.id === id) {
                data.params["changeRequired"] = 'no';
            }
        })

    }

    getCauseDetails(causeId) {
        let url;
        url = '/backend/public/cause/get?id=' + causeId + '&userId=' + this.state.userData.userId

        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + url,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.data.message === 'Success!') {
                window.location.href = "/cause/" + response.data.body.slug;
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    getProjectDetails(projectId) {

        axios({
            method: 'get',
            url:
                process.env.REACT_APP_userServiceURL +
                "/participants/" +
                "find-project-description-details-by-userId-and-projectId/" +
                this.state.userData.userId +
                "/" +
                projectId,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.data.message === 'Success!') {
                window.location.href = "/project/feeds/" + response.data.body.slug;
            }
        }).catch((err) => {
            console.log(err);
        });
    };


    componentWillUnmount() {
        window.$("#addendorsepop").modal("hide");
    }

    updateNotificationData = (id) => {
        let notifications = this.state.notifications;
        notifications.forEach((data) => {
            if (data.id === id) {
                data.params["changeRequired"] = 'no';
            }
        })

    }

    removeNotification = (entityId) => {
        let notifications = this.state.notifications;
        this.setState({'notifications': notifications.filter((tag, index) => tag.entityId !== entityId)});
    }


    redirect = (data) => {
        switch (data.entityType) {
            case "ENDORSEMENT":
                if (data.userEvent === 'SEND') {
                    UserService.fetchOtherUserData(data.params['otherUserId']).then(r =>
                        this.props.setOtherUserDataOnSuccess(),
                    );
                    window.$('#addendorsepop').modal('show');

                } else if (data.userEvent === "GIVEN") {
                    window.location.href = "/profile/" + data.params['customUrl'];
                } else if (data.userEvent === "ADD") {
                    window.location.href = "/profile/" + data.params['customUrl'];
                } else if (data.userEvent === "REQUEST") {
                    window.location.href = "/profile/" + data.params['customUrl'];
                }
                break;

            case "OFFLINE_PARTICIPANT_REQUEST":
                if (data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                    if (data.params['deactivationRequestSent'] && data.params['deactivationRequestSent'] === 'true') {
                        window.$('#deactivationProcessErrorNotification').modal('show')
                    } else {
                        this.setState({
                            'participantRequestTitle': data.params['title'],
                            'participantRequestId': data.params['requestId'],
                            'creatorUserId': data.params['otherUserId'],
                            'participantNotificationId': data.id,
                            'entityId': data.params['projectId'],
                            'projectType': data.params['projectType'],
                        }, () => {
                            this.setOpenParticipantRequestModal(data.params["projectId"]);
                        })
                    }


                }
                break;
            case "OFFLINE_PARTICIPANT_REQUEST_EDIT":
                if (data.params && data.params['projectType'] && data.params['projectType'].toLowerCase() !== 'job') {
                    if (data.params['slug']) {
                        window.location.href = "/project/feeds/" + data.params["slug"];
                    } else {
                        this.getProjectDetails(data.params["projectId"]);
                    }
                }
                break;
            case "POST":
                if (data.params['shareObject'] && data.params['shareObject'] === 'CAUSE') {
                    if (data.params["postId"]) {
                        window.location.href = "/post/" + data.params['postId'];
                    } else {
                        this.getCauseDetails(data.entityId);
                    }
                } else if (data.params['shareObject'] && data.params['shareObject'] === 'PROJECT') {
                    if (data.params["postId"]) {
                        window.location.href = "/post/" + data.params['postId'];
                    } else {
                        this.getProjectDetails(data.entityId);
                    }
                } else if (data.params["shareObject"] !== undefined && data.params["shareObject"] === "CIRCLE") {
                    if (data.params["postId"]) {
                        window.location.href = "/post/" + data.params['postId'];
                    } else {
                        window.location.href = "/community/" + data.params['slug'];
                    }
                } else if (data.userEvent === 'POSTSHARE') {
                    if (data.params['entityType'] === 'FORUM') {
                        window.location.href = "/forumDetail/" + data.params['slug'];
                    } else if (data.params['entityType'] === 'POLL') {
                        window.location.href = "/pollDetail/" + data.params['slug'];
                    } else if (data.params['entityType'] === 'ASSIGNMENT' ||
                        data.params['entityType'] === 'EVENT' || data.params['entityType'] === 'TRAINING') {
                        if (data.slug) {
                            window.location.href = "/project/feeds/" + data.slug;
                        } else {
                            this.getProjectDetails(data.entityId);
                        }
                    } else {
                        window.location.href = "/post/" + data.entityId;
                    }
                } else if (data.userEvent === 'POSTSHARE_AS_CAUSE' || data.userEvent === 'POSTSHARE_AS_PROJECT'
                    || data.userEvent === 'POSTSHARE_AS_CIRCLE') {
                    if (data.params['postId']) {
                        window.location.href = "/post/" + data.params["postId"];
                    }
                } else if (data.userEvent === 'MENTION' || data.userEvent === 'POSTLIKEMENTION'
                    || data.userEvent === 'POSTMENTION' || data.userEvent === 'POSTCOMMENTMENTION') {
                    if (data.params['postId']) {
                        window.location.href = "/post/" + data.params["postId"];
                    }
                }else if (data.userEvent === 'REPORTEDABUSE'){
                    if (data.params['entitySlug']) {
                         window.location.href = "/post/" + data.params["entitySlug"];
                        }
                 }
                else if (data.userEvent === 'TRENDINGPOST'){
                    window.location.href = "/post/" + data.params["postId"];
                }
                else if (data.userEvent === 'SELFTRENDINGPOST'){
                    window.location.href = "/post/" + data.params["postId"];
                }
                break;
            case "FORUM":
                if (data.params['slug']) {
                    window.location.href = "/forumDetail/" + data.params['slug'];
                } else if (data.userEvent === 'FORUM_POLL_CREATE') {
                    window.location.href = data.params['title'];
                }else if (data.userEvent === 'REPORTEDABUSE'){
                    if (data.params['entitySlug']) {
                        window.location.href = "/forumDetail/" + data.params["entitySlug"];
                    }
                }
                break;

            case "POLL":
                if (data.params['slug']) {
                    window.location.href = "/pollDetail/" + data.params['slug'];
                } else if (data.userEvent === 'FORUM_POLL_CREATE') {
                    window.location.href = data.params['title'];
                }else if (data.userEvent === 'REPORTEDABUSE'){
                    if (data.params['entitySlug']) {
                        window.location.href = "/pollDetail/" + data.params["entitySlug"];
                    }
                }
                break;
            case "EMPLOYEE":
                if (data.userEvent === 'REQUEST_RECEIVED') {
                    window.location.href = "/organization-employee/" + this.state.userData.customUrl;
                }else  if (data.userEvent === 'REQUEST_RECEIVED_ADMIN') {
                    window.location.href = "/organization-employee/" + data.params["companyCustomUrl"];
                }else if (data.userEvent === 'REQUEST_SENT') {
                    window.location.href = "/profile/" + data.params['customUrl'];
                } else if (data.userEvent === 'REQUEST_ACCEPTED') {
                    window.location.href = "/profile/" + data.params['customUrl'];
                } else if (data.userEvent === '"REQUEST_SEND_ACCEPTED"') {
                    window.location.href = "/profile/" + data.params['customUrl'];
                }
                break;

            case "CIRCLE":
                if (data.userEvent === 'MEMBER_REQUEST_SENT') {
                    if (data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                        this.setState({
                            'entityTitle': data.params['title'],
                            'notificationId': data.id,
                            'entityId': data.entityId
                        }, () => {
                            this.setOpenMembershipRequestForMemberModal(data.entityId);
                        })

                    }
                } else if (data.userEvent === 'MEMBER_REQUEST_SENT_FOR_ADMIN_APPROVAL') {
                    if (data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                        this.setState({
                            'entityTitle': data.params['title'],
                            'notificationId': data.id,
                            'entityId': data.entityId,
                            'requestedUserId': data.params['otherUserId'],
                            'senderUserId': data.params['senderUserId'],
                            'username': data.params['approverUserName'],
                            'profileImage': data.params['approverProfileImage'],
                            'userType': data.params['approverUserType'],
                            'userCountry': data.params['approverCountry'],
                            'customUrl': data.params['approverCustomUrl']
                        }, () => {
                            this.setOpenMembershipApprovalForAdminModal(data.entityId);
                        })

                    }
                } else if (data.userEvent === 'MEMBER_REQUEST_RECEIVED') {
                    if (data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                        window.location.href = "/community/members/" + data.params['slug'];
                    }
                }else if (data.userEvent === 'CIRCLE_DELETED' || data.userEvent === 'CIRCLE_DELETED_FOR_PENDING_MEMBER') {

                } else {
                    window.location.href = "/community/" + data.params['slug'];
                }
                break;

            case "PROFILE_DEACTIVATION":
                if (data.userEvent === "DEACTIVATION_MAKE_ADMIN_REQUEST") {
                    if (data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                        this.setState({
                            'entityTitle': data.params['title'],
                            'notificationId': data.id,
                            'adminRightQueueId': data.params['adminRightQueueId'],
                            'entityId': data.params['entityId'],
                            'entityType': data.params['entityType'],
                        }, () => {
                            this.setTransferAdminRequestModal(data.params["entityId"]);
                        })

                    }
                } else if (data.userEvent === "DEACTIVATION_MAKE_ADMIN_REQUEST_REJECTED") {
                    window.location.href = "/profile-deactivate-update-admin-rights"
                }

                break;

            case "OPERATOR":
                if (data.userEvent === "OPERATOR_REQUEST_SENT") {
                    if (data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                        this.setState({
                            'entityTitle': data.params['businessPageName'],
                            'notificationId': data.id,
                            'entityId': data.params['userId'],
                        }, () => {
                            this.setOperatorRequestModal(data.params["userId"]);
                        })

                    }
                }

                break;

            case "ADMIN":
             if (data.userEvent === "ADMIN_REQUEST_SENT" && data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                if (data.params['deactivationRequestSent'] && data.params['deactivationRequestSent'] === 'true') {
                    window.$('#deactivationProcessErrorNotification').modal('show')
                } else {
                    this.setState({
                        'entityTitle': data.params['title'],
                        'superAdminId': data.params['otherUserId'],
                        'notificationId': data.id,
                        'entityId': data.entityId,
                        'projectType': data.params['projectType'],
                    }, () => {
                        this.setAdminChangeStatusRequestModal(data.entityId);
                    })
                }


            }else if (data.userEvent === "ADMIN_TRANSFER_REQUEST_SENT" && data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                if (data.params['deactivationRequestSent'] && data.params['deactivationRequestSent'] === 'true') {
                    window.$('#deactivationProcessErrorNotification').modal('show')
                } else {
                    this.setState({
                        'entityTitle': data.params['title'],
                        'superAdminId': data.params['otherUserId'],
                        'notificationId': data.id,
                        'entityId': data.entityId,
                        'projectType': data.params['projectType'],
                    }, () => {
                        this.setOpenAdminTransferRequestModal(data.entityId);
                    })
                }


            }
             break;


            case "PROJECT":
                if (data.userEvent === "PROJECTAPPLY_APPLIERNOTIFICATION") {
                    if (data.params['slug']) {
                        window.location.href = "/project/feeds/" + data.params["slug"];
                    } else {
                        this.getProjectDetails(data.params["projectId"]);
                    }
                } else if (data.userEvent === "PROJECTAPPLY_CREATERNOTIFICATION") {
                    if (data.params['slug']) {
                        window.location.href = "/project/feeds/" + data.params["slug"];
                    } else {
                        this.getProjectDetails(data.params["projectId"]);
                    }
                } else if (data.userEvent === "PROJECT_CREATE" || data.userEvent === "PARTICIPANTS" || data.userEvent === "PROJECT_CANCELLED") {
                    if (data.slug) {
                        window.location.href = "/project/feeds/" + data.slug;
                    } else {
                        this.getProjectDetails(data.entityId);
                    }
                }
                else if (data.userEvent === "OFFLINE_PARTICIPANT_REQUEST" && data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                    if (data.params['deactivationRequestSent'] && data.params['deactivationRequestSent'] === 'true') {
                        window.$('#deactivationProcessErrorNotification').modal('show')
                    } else {
                        this.setState({
                            'participantRequestTitle': data.params['title'],
                            'participantRequestId': data.params['requestId'],
                            'participantNotificationId': data.id,
                            'creatorUserId': data.params['otherUserId'],
                            'entityId': data.params['projectId'],
                            'projectType': data.params['projectType'],
                        }, () => {
                            this.setOpenParticipantRequestModal(data.params["projectId"]);
                        })
                    }


                }
                else if (data.userEvent === "ADD_FACULTIES_OR_KEY_SPEAKERS") {
                    if (data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                        if (data.params['deactivationRequestSent'] && data.params['deactivationRequestSent'] === 'true') {
                            window.$('#deactivationProcessErrorNotification').modal('show')
                        } else {
                            this.setState({
                                'participantRequestTitle': data.params['title'],
                                'participantRequestId': data.params['requestId'],
                                'participantNotificationId': data.id,
                                'entityId': data.params['projectId'],
                                'projectType': data.params['projectType'],
                            }, () => {
                                this.setOpenInviteeRequestModal(data.params['projectId']);
                            })
                        }

                    }
                } else if (data.userEvent === 'PARTICIPANT_REQUEST_ADMIN_APPROVAL') {
                    if (data.params['changeRequired'] && data.params['changeRequired'] === 'yes') {
                        this.setState({
                            'entityTitle': data.params['title'],
                            'notificationId': data.id,
                            'entityId': data.entityId,
                            'requestedUserId': data.params['otherUserId'],
                            'participantRequestId': data.params['requestId'],
                            'senderUserId': data.params['senderUserId'],
                            'username': data.params['approverUserName'],
                            'profileImage': data.params['approverProfileImage'],
                            'userType': data.params['approverUserType'],
                            'userCountry': data.params['approverCountry'],
                            'customUrl': data.params['approverCustomUrl']
                        }, () => {
                            this.setOpenProjectParticipantsAdminApprovalModal(data.entityId);
                        })

                    }
                } else if (data.userEvent === "POSTSHARE") {
                    if (data.params['slug']) {
                        window.location.href = "/project/feeds/" + data.params["slug"];
                    } else {
                        this.getProjectDetails(data.params["entityId"]);
                    }
                } else if (data.userEvent === "PARTICIPANT_APPLICATION_ACCEPTED") {
                    if (data.params && data.params['projectType'] && data.params['projectType'].toLowerCase() !== 'job') {
                        window.location.href = "/project/feeds/" + data.params["slug"];
                    }

                }else if (data.userEvent === 'REPORTEDABUSE'){
                    if (data.params['entitySlug']) {
                        window.location.href = "/project/feeds/"  + data.params["entitySlug"];
                    }
                }else if (data.userEvent === 'PARTICIPANT_OFFLINE_PARTICIPANT_REQUEST_ACCEPTED' ||
                    data.userEvent === 'FACULTIES_OR_KEY_SPEAKERS_REQUEST_ACCEPTED' ||
                    data.userEvent === 'FACULTIES_OR_KEY_SPEAKERS_REQUEST_REJECTED'){
                    if (data.params['slug']) {
                        window.location.href = "/project/feeds/"  + data.params["slug"];
                    }
                }
                break;
            case "FEEDBACK":
                if (data.userEvent === "LIKE") {
                    if (data.params && data.params['projectType'] && (data.params['projectType'] === 'JOB' ||
                        data.params['projectType'] === 'ASSIGNMENT' || data.params['projectType'] === 'TRAINING' || data.params['projectType'] === 'EVENT')) {
                        window.location.href = "/project/feeds/" + data.params["slug"];
                    } else {
                        window.location.href = "/Feedback";
                    }

                }
                break;
            case "COMMENT":
             if(data.userEvent === 'MENTION'  && data.params['entityId']) {
                    window.location.href = "/post/" + data.params["entityId"];

            }
                break;
            default:
                break;
        }

        if (data.userEvent === 'MAKE_ADMIN') {
            window.location.href = "/message?selectedType=group";
        } else if (data.userEvent === 'CONNECT_INVITE_ACCEPTED') {
            window.location.href = "/profile/" + data.params['customUrl'];
        } else if (data.userEvent === 'CONNECT_INVITATION') {
            window.location.href = "/network";
        } else if (data.userEvent === "FEEDBACK") {
            if (data.params['slug']) {
                window.location.href = "/project/feeds/" + data.params["slug"];
            } else {
                this.getProjectDetails(data.params["projectId"]);
            }
        } else if (data.userEvent === 'MESSAGE_SENT') {
            window.location.href = "/message-user/"+ data.params["otherUserId"];
        }
        else {
            if (data.params && data.params['entityType']) {
                if (data.params['entityType'] === 'POST') {
                    if (data.params['entityId']) {
                        window.location.href = "/post/" + data.params['entityId'];
                    }
                } else if (data.params['entityType'] === 'COMMENT') {
                    if (data.userEvent === 'LIKE' && data.params['parentEntityType'] === 'POST') {
                        window.location.href = "/post/" + data.params['parentEntityId'];
                    } else if (data.userEvent === 'LIKE' && data.params['parentEntityType'] === 'FORUM') {
                        if (data.params['slug']) {
                            window.location.href = "/forumDetail/" + data.params['slug'];
                        }
                    } else if (data.userEvent === 'LIKE_REPLY') {
                        if (data.params['parentEntityType'] === 'POST') {
                            window.location.href = "/post/" + data.params['parentEntityId'];
                        } else if (data.params['parentEntityType'] === 'FORUM') {
                            window.location.href = "/forumDetail/" + data.params['slug'];
                        } else if (data.params['parentEntityType'] === 'EXPLOREBLOG') {
                            window.location.href = "explore-detail/blog/" + data.params['slug'];
                        }
                    }
                } else if (data.params['entityType'] === 'FORUM') {
                    if (data.params['slug']) {
                        window.location.href = "/forumDetail/" + data.params['slug'];
                    }
                } else if (data.params['entityType'] === 'POLL') {
                    if (data.params['slug']) {
                        window.location.href = "/pollDetail/" + data.params['slug'];
                    }
                } else if (data.params['entityType'] === "EXPLOREBLOG") {
                    if (data.params['slug']) {
                        window.location.href = "explore-detail/blog/" + data.params['slug'];
                    }
                }
            }
        }
    };

    toggleBellButton = () => {
        this.setState({bellDisplay: !this.state.bellDisplay}, () => {
            if (this.state.bellDisplay) {
                this.getNotifications(0, this.state.pageSize);
/*
                this.markAllNotificationAsRead();
*/
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({type: 'notizero'}));
                }
            } else {
                this.setState({
                    pageNo: 0,
                    pagination: false,
                    notifications: [],
                    contentLength: 0
                });
            }
        });
    }

    markAllNotificationAsRead = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/notification/mark-all-as-read?userId=' + this.state.userData.userId,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {

            if (response && response.data && response.data.message === 'Success!') {
                //this.setState({'allRead': true});
                //this.getNotificationsCount();
                window.location.href = "/notifications";
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getNotifications = (page, size) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/notification/list?userId=' + this.state.userData.userId
                +"&notificationCategory=ALL"+ "&page=" + page + "&size=" + size,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            let notifications = this.state.notifications;
            if (response && response.data && response.data.message === 'Success!') {
                notifications = response.data.body.content;
                this.setState({
                    notifications: notifications,
                    pagination: response.data.body.page.last,
                    contentLength: response.data.body.page.content.length,
                    isResultLoading: false
                }, () => {
                    let notificationListHeight = $('#scrollableDiv').height();
                    if (notificationListHeight && notificationListHeight > 0) {
                        if (!this.state.initialThresholdHeight) {
                            this.setState({
                                'initialThresholdHeight': notificationListHeight,
                                'thresholdHeight': notificationListHeight * 0.5
                            });
                        }
                    }
                });
            }
        }).catch((err) => {
            console.log(err);
            this.setState({isResultLoading: false});
        });
    }

    getNotificationsCount() {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/notification/notificationCount?userId=' + this.state.userData.userId,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            this.setState({'notiCount': response.data.body});
        }).catch((err) => {
            console.log(err);
        });

    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages != null) {
            return personalInfo.resizedProfileImages;
        }
        return personalInfo.imageUrl;
    }

    goBack = () => {
        window.history.back()
    }

    showNotifications(){
        this.markAllNotificationAsRead();
        window.location.href = "/notifications";
    }


    render() {
        return <div className="usernotification">
            {this.state.OpenParticipantRequestModal && this.state.OpenParticipantRequestModal === this.state.entityId &&
            <OfflineParticipantRequestPopUp participantRequestId={this.state.participantRequestId}
                                            creatorUserId={this.state.creatorUserId}
                                            participantNotificationId={this.state.participantNotificationId}
                                            entityId={this.state.entityId}
                                            triggerParticipantRequestPopUpClose={this.triggerParticipantRequestPopUpClose}
                                            projectType={this.state.projectType}
                                            participantRequestTitle={this.state.participantRequestTitle}
                                            updateNotificationDataForOfflineRequest={this.updateNotificationDataForOfflineRequest}/>
            }

            {this.state.OpenTransferAdminRequestModal && this.state.OpenTransferAdminRequestModal === this.state.entityId &&
            <TransferAdmin notificationId={this.state.notificationId}
                           entityId={this.state.entityId}
                           triggerTransferAdminRequestPopUpClose={this.triggerTransferAdminRequestPopUpClose}
                           entityTitle={this.state.entityTitle}
                           adminRightQueueId={this.state.adminRightQueueId}
                           entityType={this.state.entityType}
                           updateNotificationData={this.updateNotificationData}/>
            }

            {this.state.OpenAdminChangeStatusRequestModal && this.state.OpenAdminChangeStatusRequestModal === this.state.entityId &&
            <Admin notificationId={this.state.notificationId}
                           entityId={this.state.entityId}
                            triggerAdminChangeStatusRequestPopUpClose={this.triggerAdminChangeStatusRequestPopUpClose}
                           entityTitle={this.state.entityTitle}
                            superAdminId={this.state.superAdminId}
                        projectType={this.state.projectType}
                           updateNotificationData={this.updateNotificationData}/>
            }

            {this.state.openAdminTransferRequestModal && this.state.openAdminTransferRequestModal === this.state.entityId &&
            <AdminTransferModel notificationId={this.state.notificationId}
                   entityId={this.state.entityId}
                    triggerOpenAdminTransferRequestPopUpClose={this.triggerOpenAdminTransferRequestPopUpClose}
                   entityTitle={this.state.entityTitle}
                   superAdminId={this.state.superAdminId}
                   projectType={this.state.projectType}
                   updateNotificationData={this.updateNotificationData}/>
            }

            {this.state.OpenOperatorRequestModal && this.state.OpenOperatorRequestModal === this.state.entityId &&
            <OperatorRequest notificationId={this.state.notificationId}
                           entityId={this.state.entityId}
                             triggerOperatorRequestPopUpClose={this.triggerOperatorRequestPopUpClose}
                           entityTitle={this.state.entityTitle}
                           updateNotificationData={this.updateNotificationData}/>
            }

            {this.state.OpenInviteeRequestModal && this.state.OpenInviteeRequestModal === this.state.entityId &&
            <InviteeRequestPopUp participantRequestId={this.state.participantRequestId}
                                 participantNotificationId={this.state.participantNotificationId}
                                 entityId={this.state.entityId}
                                 projectType={this.state.projectType}
                                 triggerInviteeRequestPopUpClose={this.triggerInviteeRequestPopUpClose}
                                 participantRequestTitle={this.state.participantRequestTitle}
                                 updateNotificationDataForInviteeRequest={this.updateNotificationDataForInviteeRequest}/>
            }

            {

                this.state.openProfileVisibilityModal === true && this.state.userData.type !== 'COMPANY' && this.state.projectType.toLowerCase() !== 'job'
                && <ProfileVisibility entityId={this.state.entityId}
                                      triggerProfileVisibilityClose={this.triggerProfileVisibilityClose}
                                      visibleToProfileChecked={true}
                                      triggeredProfileVisibilityCloseOnSuccess={this.triggeredProfileVisibilityCloseOnSuccess}/>
            }

            {this.state.openMembershipApprovalForAdminPopUp && this.state.openMembershipApprovalForAdminPopUp === this.state.entityId &&
            <MembershipRequestPopupForAdminApproval
                notificationId={this.state.notificationId}
                entityId={this.state.entityId}
                requestedUserId={this.state.requestedUserId}
                senderUserId={this.state.senderUserId}
                username={this.state.username}
                userType={this.state.userType}
                userCountry={this.state.userCountry}
                profileImage={this.state.profileImage}
                customurl={this.state.customUrl}
                triggerMembershipApprovalForAdminPopUpClose={this.triggerMembershipApprovalForAdminPopUpClose}
                entityTitle={this.state.entityTitle}
                removeNotification={this.removeNotification}/>
            }

            {this.state.openProjectParticipantsAdminApprovalPopUp && this.state.openProjectParticipantsAdminApprovalPopUp === this.state.entityId &&
            <ProjectParticipantAdminApprovalPopup
                notificationId={this.state.notificationId}
                entityId={this.state.entityId}
                requestedUserId={this.state.requestedUserId}
                participantRequestId={this.state.participantRequestId}
                senderUserId={this.state.senderUserId}
                username={this.state.username}
                userType={this.state.userType}
                userCountry={this.state.userCountry}
                profileImage={this.state.profileImage}
                customurl={this.state.customUrl}
                triggerProjectParticipantsAdminApprovalPopUpClose={this.triggerProjectParticipantsAdminApprovalPopUpClose}
                entityTitle={this.state.entityTitle}
                removeNotification={this.removeNotification}/>
            }

            {this.state.openMembershipRequestForMemberPopUp && this.state.openMembershipRequestForMemberPopUp === this.state.entityId &&
            <MembershipRequestPopupforMember
                notificationId={this.state.notificationId}
                entityId={this.state.entityId}
                triggerMembershipForMemberPopUpClose={this.triggerMembershipForMemberPopUpClose}
                entityTitle={this.state.entityTitle}
                updateNotificationData={this.updateNotificationData}/>
            }

            {this.state.openMembershipRequestForAdminPopUp && this.state.openMembershipRequestForAdminPopUp === this.state.entityId &&
            <MemberShipRequestPopupforAdmin notificationId={this.state.notificationId}
                                            entityId={this.state.entityId}
                                            adminId={this.state.adminId}
                                            triggerMembershipApprovalForAdminPopUpClose={this.triggerMembershipApprovalForAdminPopUpClose}
                                            entityTitle={this.state.entityTitle}
                                            updateNotificationData={this.updateNotificationData}/>
            }
            <DeactivationErrorPopUpNotification message={this.state.applyErrorMessage}/>


            {window.location.href === process.env.REACT_APP_domainUrl + '/notifications' ?
                <a href="javascript:void(0)" id="dropdown-toggle" onClick={this.goBack} className="btn point active">
                    <i className="iBell"/>
                    {this.state.notiCount && this.state.notiCount > 0
                        ? <span
                            className="notiCount">{this.state.notiCount > 99 ? '99+' : this.state.notiCount}</span> : ''}
                </a>
                :
                <a href="javascript:void(0)" id="dropdown-toggle" onClick={this.markAllNotificationAsRead} className="btn point">
                    <i className="iBell"/>
                    {this.state.notiCount && this.state.notiCount > 0
                        ? <span
                            className="notiCount">{this.state.notiCount > 99 ? '99+' : this.state.notiCount}</span> : ''}
                </a>}
            {
                this.state.bellDisplay && <div className="dropdown-menu fadeIn animated">
                    <div className="dropinner">
                        <div className="drophead">Notifications</div>
                        <div className="usernotifwrap"
                             ref={(ref) => this.scrollParentRef = ref}>
                            <div id="scrollableDiv" style={{overflow: 'auto'}}>
                                {
                                    this.state.contentLength > 0
                                    &&
                                    <ul>

                                        {this.state.notifications && this.state.notifications.map((data, index) => {
                                            return <li style={{paddingRight: '13px'}} key={index}>
                                                <div className="npostmain">
                                                    <Image
                                                        circleSlug={data.params['circleSlug'] ? data.params['circleSlug'] : null}
                                                        projectSlug={data.params['slug'] ? data.params['slug'] : null}
                                                        userEvent={data.userEvent ? data.userEvent : ""}
                                                        projectId={data.params['projectId'] ? data.params['projectId'] : ""}
                                                        projectType={data.params['projectType'] ? data.params['projectType'] : null}
                                                        userId={data.params['otherUserId'] ? data.params['otherUserId'] : data.userId}
                                                        imageUrl={(data.userEvent && (data.userEvent === "SELFTRENDINGPOST"  || data.userEvent === "TRENDINGPOST" || data.userEvent === "REPORTABUSE" ||  data.userEvent === "MAXTHRESHOLDREPORTEDABUSE" || data.userEvent === "REPORTEDABUSE")) ?  "https://cdn.dscovr.com/images/welogo-notificNew.png"  : data.params && data.params["imageUrl"] && data.params["imageUrl"] != "" ? getCDNUrl(this.getOptimizedImage(data.params)) :
                                                            data.userEvent === 'DEACTIVATION_ALERT'?(data.params["profileImage"]?data.params["profileImage"]:'https://cdn.dscovr.com/images/circleDefault.png'):
                                                            ((data.userEvent === "PROJECTAPPLY_APPLIERNOTIFICATION" || data.userEvent === "PARTICIPANT_APPLICATION_ACCEPTED" || data.userEvent === "EDIT") ? "https://cdn.dscovr.com/images/project-default.jpg" : (data.userEvent === 'FIRSTSIGNUP' || data.userEvent === 'PROFILE_DEACTIVATION_REJECTED' ? "https://cdn.dscovr.com/images/welogo-notificNew.png" : ((data.params["userType"] != "COMPANY" && data.params["userType"] != 'circle') ? "https://cdn.dscovr.com/images/users1.png" : (data.params["userType"] == 'circle' ? "https://cdn.dscovr.com/images/circleDefault.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"))))}/>
                                                    <div>
                                                        <div className="" onClick={e => {
                                                            this.redirect(data)
                                                        }}
                                                             style={{cursor: data.userEvent && (data.userEvent === '' || data.userEvent === 'FIRSTSIGNUP' || data.userEvent === 'PROFILE_DEACTIVATION_REJECTED') ? 'default' : 'pointer'}}>
                                                            {convertUnicode(data.message)}

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="nposttime" onClick={e => {
                                                    this.redirect(data)
                                                }}
                                                     style={{cursor: data.userEvent && (data.userEvent === '' || data.userEvent === 'FIRSTSIGNUP' || data.userEvent === 'PROFILE_DEACTIVATION_REJECTED') ? 'default' : 'pointer'}}>

                                                    <time>{data.time}</time>
                                                </div>
                                                <div className="contxmenu notidot" style={{display: 'none'}}>
                                                    <button type="button" className="btn" data-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false"><i
                                                        className="iKebab"/></button>
                                                    <div className="dropdown-menu fadeIn animated">
                                                        <a className="dropdown-item" href="#!"><i className="iTrashBin"/> Delete</a>
                                                    </div>
                                                </div>
                                                <div className="extbtnWrap">
                                                    { ((data.userEvent === 'PARTICIPANTS' ||  data.userEvent === 'OFFLINE_PARTICIPANT_REQUEST'||  data.userEvent === 'ADD_FACULTIES_OR_KEY_SPEAKERS' || data.userEvent === 'MEMBER_REQUEST_SENT' || data.userEvent === 'ADMIN_REQUEST_SENT' || data.userEvent === 'ADMIN_TRANSFER_REQUEST_SENT' ||  data.userEvent === 'ADMIN_TRANSFER_REQUEST_ACCEPTED'
                                                        ||  data.userEvent === 'ADMIN_REQUEST_REJECTED' ||  data.userEvent === 'ADMIN_REQUEST_ACCEPTED' ||  data.userEvent === 'ADMIN_TRANSFER_REQUEST_REJECTED' ||  data.userEvent === 'ADMIN_ACCESS_REVOKED') &&
                                                        ( !data.params['projectType'] || (data.params && data.params['projectType'] && data.params['projectType'].toLowerCase() !== 'job' )) )&&
                                                    <button className="btn extbtn"   onClick={e => {
                                                            this.redirectToProject(data)
                                                        }}>
                                                        {data.entityType === 'CIRCLE' ? "View Community" : "View Project"}
                                                    </button>}
                                                    { data.userEvent === 'POLLENDNOTIFICATION' &&
                                                    <button className="btn extbtn"   onClick={e => {
                                                        this.redirectToPoll(data)
                                                    }}>
                                                        View Results
                                                    </button>}
                                                </div>
                                            </li>
                                        })}
                                    </ul>
                                }

                            </div>

                        </div>
                        {/*{this.state.notifications && this.state.notifications.length > 5 && this.state.notiCount > 0 ?
                            < div className="dropfoot">
                                <a href="javascript:void(0)" onClick={this.markAllNotificationAsRead}>Mark All as
                                    read</a>
                            </div> : null
                        }*/}
                        {this.state.contentLength > 5 ? <div className="dropfoot">
                            <Link to="/notifications">View all notifications</Link>
                        </div> : null}
                    </div>
                </div>
            }


        </div>

    }

    redirectToProject(data) {
        if( data.entityType === 'CIRCLE'){
            window.location.href = "/community/" + data.params["slug"];
        }else if (data.params && data.params['projectType'] && data.params['projectType'].toLowerCase() !== 'job' && data.params['slug']) {
            window.location.href = "/project/feeds/" + data.params["slug"];
        }
    }
    redirectToPoll(data) {
        window.location.href = "/pollDetail/" + data.params["slug"];
    }
}

const mapStateToProps = (state) => {
    if (state.authReducer.otherUserData) {
        return {
            otherUserData: state.authReducer.otherUserData,
            notificationReceivedResponse:
                state.notificationsReducer &&
                state.notificationsReducer.notificationReceivedResult &&
                state.notificationsReducer.notificationReceivedResult.value
        };
    } else {
        return {
            notificationReceivedResponse:
                state.notificationsReducer &&
                state.notificationsReducer.notificationReceivedResult &&
                state.notificationsReducer.notificationReceivedResult.value
        };
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOtherUserData: dispatch(setOtherUserData(UserService.getOtherUserData())),
        setOtherUserDataOnSuccess: () =>
            dispatch(setOtherUserDataOnSuccess(UserService.getOtherUserData()))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

