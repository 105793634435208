import React, { Component } from 'react'
import { authService } from '../auth';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import CleverTapUtils from '../commons/CleverTapUtils';

class SavePostButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            bookMarkFlag: false,
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    onClickSaveUnsavePost = () => {
        let data = {
            userId: this.state.userId,
            entityId: this.props.id,
            entityType: this.props.entityType
        };
        let url = `bookmark/${!this.state.bookMarkFlag ? "create" : "delete"}`;
        if(this.props.entityType === "FORUM" || this.props.entityType === "POLL") {
            data.pinned = false;
            url = `pinned/create`;
        }
        if(!this.state.bookMarkFlag) {
            CleverTapUtils.eventClick({}, "Save");
        }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_userServiceURL}/backend/${url}`,
            data: data,
            withCredentials: true,
        }).then((response) => {
            if(response && response.data && response.data.message === "Success!") {
                if(this.props.pageType !== "BOOKMARK") {
                    this.onSuccessBookmarkPost(this.state.bookMarkFlag);
                } else {
                    this.props.onSuccessBookmarkPost(this.props.id);
                }
            }
        }).catch((err) => {
            console.log('err', err)
        });
    }

    onSuccessBookmarkPost = (bookMarkFlag) => {
        this.setState({
            bookMarkFlag: !bookMarkFlag
        })
    }

    componentDidMount() {
        this.setState({
            bookMarkFlag: this.props.bookMarkFlag
        })
    }

    render() {
        const { pageType } = this.props;
        const { bookMarkFlag } = this.state;
        return (
            <>
                {pageType !== "BOOKMARK" ? 
                    <a className="dropdown-item" href="javascript:void(0)" onClick={this.onClickSaveUnsavePost} >
                        <i className={!bookMarkFlag ? "iBookmark_OL" : "iBookmark_RM_OL"} style={{ fontSize: '14px' }} /> {!bookMarkFlag ? "Save" : "Unsave"}
                    </a> :
                    <a className="bookmarkbtn" onClick={this.onClickSaveUnsavePost} style={{cursor: "pointer"}}><i className="iBookmark_RM"></i></a>}
            </>
        )
    }
}
export default withRouter(SavePostButton);
