import React, {Component} from 'react';
import {authService} from "../auth";

class DeactivationWithoutConfirmFinalPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            messages: null,
            isLoading: true,
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            data: {},
            message: '',
            success: false

        };

    }

    logout = (event) => {
        event.preventDefault();
        window.$('#deactivationFinalPopUp').modal('hide');
        window.location.href = "/signout";
    }

    render() {

        return <>
            <div className="modal fade dgpopup confirmpop" id="deactivationFinalPopUp" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" onClick={this.logout}><span
                            aria-hidden="true">&times;</span></button>
                        <div className="confirm-popup-modal-body text-left">
                            <h5>Deactivation Successful</h5>
                            <p>Your account has been deactivated. Once deactivated, within 72 hours your profile will not be visible on WeNaturalists.</p>
                            <p className="mt-2">You may choose to reactivate your account within a period of 1 year by writing to us at <a href="mailto:support@wenaturalists.com" target="_blank" style={{color: "#BFC52E"}}>support@wenaturalists.com</a>. After this period, the profile will be permanently deleted</p>
                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.logout}>I understand</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }


}

export default DeactivationWithoutConfirmFinalPopup


