import React from "react";
import {TwitterShareButton} from "react-share";
import FacebookShareButton from "react-share/es/FacebookShareButton";
import WhatsappShareButton from "react-share/es/WhatsappShareButton";
import LinkedinShareButton from "react-share/es/LinkedinShareButton";
import TumblrShareButton from 'react-share/es/TumblrShareButton'
import $ from 'jquery';
import RedditShareButton from "react-share/es/RedditShareButton";
import TelegramShareButton from "react-share/es/TelegramShareButton";


export default class UnauthShare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    onCopy = () => {
        this.setState({copied: true});
        setTimeout(() => {
            this.setState({
                copied: false
            });
            $(".dropdown-menu").removeClass('show');
            $(".dropdown-menu").removeAttr("style");
            $(".dropdown-menu").removeAttr("x-placement");
        }, 5000);
    };

    componentDidMount() {
        var that = this;
        $('#copyLnk' + this.props.shareId).on('click', function (event) {
            event.stopPropagation();
            const url = (that.props.absoluteShareUrl && that.props.absoluteShareUrl !== '') ? that.props.absoluteShareUrl : process.env.REACT_APP_domainUrl + that.props.shareUrl + that.props.shareId;
            const textField = document.createElement('textarea');
            textField.innerText = url;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            that.onCopy();
        });
    };

    render() {
        let shareContent;
        if (this.props.shareOpen === 'true') {
            shareContent = <>
                <a href="javascript:void(0)" className="share" data-toggle="dropdown"
                   aria-expanded="true"><span>Share</span></a>
                <div className="dropdown-menu fadeIn animated allsocialshare show" id="share-open">
                    <div className="allsocialsharetop">
                        <a href="javascript:void(0)" className="socialico"
                           style={{opacity: ".3", cursor: "default !important", pointerEvents: "none"}}><i
                            className="iLogo_Fitted"/></a>
                        {
                            this.state.copied && <span>Copied</span>
                        }
                        <a id={"copyLnk" + this.props.shareId} href="javascript:void(0)"
                           className="socialico copyLnk"><i className="iTxEdi_AddLink"/></a>
                        {
                            (this.props.absoluteShareUrl && this.props.absoluteShareUrl !== '')
                                ? <a href={"mailto:?subject=Wenaturalist&body=" + this.props.absoluteShareUrl}
                                     target="_blank" className="socialico"><i className="fa fa-envelope"/></a>
                                :
                                <a href={"mailto:?subject=Wenaturalist&body=" + process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                   target="_blank" className="socialico"><i className="fa fa-envelope"/></a>
                        }
                    </div>
                    <div className="allsocialsharebott">
                        <FacebookShareButton className="socialico"
                                             url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                             >
                            <i className="iSocial_FB"/>
                        </FacebookShareButton>
                        <TwitterShareButton className="socialico"
                                            url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                            >
                            <i className="iSocial_Twitter"/>
                        </TwitterShareButton>
                        <LinkedinShareButton className="socialico"
                                             url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                             >
                            <i className="iSocial_LinkedIn"/>
                        </LinkedinShareButton>
                        <WhatsappShareButton className="socialico"
                                             url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}>
                            <i className="iSocial_WhatsApp"/>
                        </WhatsappShareButton>
                        <TumblrShareButton className="socialico"
                                           url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                           >
                            <i className="iSocial_Tumblr"/>
                        </TumblrShareButton>
                        <RedditShareButton className="socialico"
                                           url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                           >
                            <i className={"iReddit_Logo"}/>
                        </RedditShareButton>
                        <TelegramShareButton className="socialico"
                                             url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                             >
                            <i className={"iTelegram_Logo"}/>
                        </TelegramShareButton>
                    </div>
                </div>
            </>
        } else {
            shareContent = <>
                <a href="javascript:void(0)" className="share" data-toggle="dropdown"><span>Share</span></a>
                <div className="dropdown-menu fadeIn animated allsocialshare" id="share-open">
                    <div className="allsocialsharetop">

                        <a href="javascript:void(0)" className="socialico"
                           style={{opacity: ".3", cursor: "default !important", pointerEvents: "none"}}><i
                            className="iLogo_Fitted"/></a>
                        {
                            this.state.copied && <span>Copied</span>
                        }
                        <a id={"copyLnk" + this.props.shareId} href="javascript:void(0)"
                           className="socialico copyLnk"><i className="iTxEdi_AddLink"/></a>
                        {
                            (this.props.absoluteShareUrl && this.props.absoluteShareUrl !== '')
                                ? <a href={"mailto:?subject=Wenaturalist&body=" + this.props.absoluteShareUrl}
                                     target="_blank" className="socialico"><i className="fa fa-envelope"/></a>
                                :
                                <a href={"mailto:?subject=Wenaturalist&body=" + process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                   target="_blank" className="socialico"><i className="fa fa-envelope"/></a>
                        }
                    </div>
                    <div className="allsocialsharebott">
                        <FacebookShareButton className="socialico"
                                             url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                             >
                            <i className="iSocial_FB"/>
                        </FacebookShareButton>
                        <TwitterShareButton className="socialico"
                                            url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                            >
                            <i className="iSocial_Twitter"/>
                        </TwitterShareButton>
                        <LinkedinShareButton className="socialico"
                                             url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                             title={"Instagram"}>
                            <i className="iSocial_LinkedIn"/>
                        </LinkedinShareButton>
                        <WhatsappShareButton className="socialico"
                                             url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                             >
                            <i className="iSocial_WhatsApp"/>
                        </WhatsappShareButton>
                        <TumblrShareButton className="socialico"
                                           url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                           >
                            <i className="iSocial_Tumblr"/>
                        </TumblrShareButton>
                        <RedditShareButton className="socialico"
                                           url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                           >
                            <i className={"iReddit_Logo"}/>
                        </RedditShareButton>
                        <TelegramShareButton className="socialico"
                                             url={process.env.REACT_APP_domainUrl + this.props.shareUrl + this.props.shareId}
                                             >
                            <i className={"iTelegram_Logo"}/>
                        </TelegramShareButton>
                    </div>
                </div>
            </>
        }
        return (

            <div className="sharedrop">
                {shareContent}
            </div>
        )
    }
}
