import React from "react";
import { NavLink } from "react-router-dom";
import { authService } from "../auth";
import ReactTooltip from "react-tooltip";
import axios from "axios";

class ExploreTopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: authService.getUserDetailsFromLocalStorage(),
      loginSuccess: authService.isAuthenticated(),
      trendingCategories: [],
      categories: [],
      selectedCategory: null,
    };
  }
  componentDidMount() {
    var navPos = window.$(".projtopbar").offset().top - 64;
    window.$(window).scroll(function () {
      var windscroll = window.$(window).scrollTop();
      if (windscroll >= navPos) {
        window.$(".projtopbar").addClass("is-sticky");
      } else {
        window.$(".projtopbar").removeClass("is-sticky");
      }
    });
    if (this.state.loginSuccess) {
      this.trendingCategory();
    } else {
      this.getCategories()
    }
  }


  getCategories = () => {
    this.setState({ 'categorySuccess': false })
    axios({
      method: 'get',
      url: process.env.REACT_APP_userServiceURL + '/backend/explore_service/api/explore_category/getAllExplorePublishedCategory',
      withCredentials: true
    }).then(response => {

      if (response && response.data.body !== null && response.data.body.length > 0) {
        this.setState({
          "categories": response.data.body,
          "categorySuccess": true,
          "selectedCategory": response.data.body[0]['id']
        });
        // this.getBlogs(response.data.body[0]['id']);
      } else if (response && response.data.body !== null && response.data.body.length === 0) {
        this.setState({
          "categories": response.data.body,
          "categorySuccess": true
        });
      }
    });
  }

  //Trending Category
  trendingCategory = () => {
    let cancelTokenCategory;
    if (typeof cancelTokenCategory !== typeof undefined) {
      cancelTokenCategory.cancel("Cancelling the previous request");
    }
    cancelTokenCategory = axios.CancelToken.source();
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/explore/explore_blog/trendingcategory?userId=${this.state.userData.userId}`,
      withCredentials: true,
      cancelToken: cancelTokenCategory.token,
    }).then((response) => {
      if (response && response.data && response.data.status === "200 OK") {
        this.setState({
          trendingCategories: response.data.body,
        });
      }
    });
  };

  componentWillUnmount() {
    window.$("#addpostpop").modal("hide");
    window.$("#toppostwrap").modal("hide");
    window.$("#postblog").modal("hide");
    window.$("#postlink").modal("hide");
    window.$("#blogpostpreviewwrap").modal("hide");
  }

  render() {
    const { tagName } = this.props;
    return (
      <>
        <div className="projtopbar">
          <div className="projtopbarleft">
            <ul id="tabs" className="nav">
                <li>
                  <a href="javascript:void(0)" className="nav-link active">
                    <h1>{tagName}</h1>
                  </a>
                </li>
            </ul>
            {!this.props.isIndividualHashtag &&
              <ul className="ntwLinkIcoTab">
                <li>
                  <NavLink
                    to={"/explore-daily-edition"}
                    data-tip="Daily Edition"
                    data-place="bottom"
                    activeClassName="active"
                  >
                    <i className="iEdition2" />
                  </NavLink>
                </li>
                <li>
                  {this.state.loginSuccess ? 
                    (this.state.trendingCategories && this.state.trendingCategories[0] && 
                      <>
                        <NavLink
                          to={`/explore-curated/${this.state.trendingCategories[0].id}/${this.state.trendingCategories[0].slug}`}
                          data-tip="Curated For You"
                          data-place="bottom"
                          activeClassName="active"
                          data-for={"onlyForCuratedTip"}
                        >
                        <i className="iCurated1" />
                        </NavLink>
                        <ReactTooltip
                          className="rtoolTip"
                          place="bottom"
                          type="dark"
                          effect="solid"
                          textColor="#00394D"
                          backgroundColor="#ffffff"
                          id={"onlyForCuratedTip"}
                        />
                     </>
                    ) :
                    <NavLink
                      to={`/explore-curated`}
                      data-tip="Curated For You"
                      data-place="bottom"
                      activeClassName="active"
                    >
                      <i className="iCurated1" />
                    </NavLink>
                  }
                </li>
                <li>
                  <NavLink
                    to={"/explore-trending"}
                    data-tip="Trending"
                    data-place="bottom"
                    activeClassName="active"
                  >
                    <i className="iTrending1" />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/explore-hashtag"}
                    data-tip="Hashtag"
                    data-place="bottom"
                    activeClassName="active"
                  >
                    <i className="iHashtag" />
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to={"/forum"}
                    data-tip="Forum"
                    data-place="bottom"
                    activeClassName="active"
                  >
                    <i className="iWN_Forum_OL" />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/poll"}
                    data-tip="Poll"
                    data-place="bottom"
                    activeClassName="active"
                  >
                    <i className="iPolls" />
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    to={"/causes"}
                    data-tip="Causes"
                    data-place="bottom"
                    activeClassName="active"
                  >
                    <i className="iCauses_OL" />
                  </NavLink>
                </li>
              </ul>
            }
          </div>
        </div>
        <ReactTooltip
          className="rtoolTip"
          place="bottom"
          type="dark"
          effect="solid"
          textColor="#00394D"
          backgroundColor="#ffffff"
        />
      </>
    );
  }
}

export default ExploreTopBar;
