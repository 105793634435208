export const SET_WPOND_YEAR_DATA= "/wpond/SET_WPOND_YEAR_DATA";
export const SET_WPOND_YEAR_DATA_SUCCESS= "/wpond/SET_WPOND_YEAR_DATA_SUCCESS";


let initialState = {
    yearData: {},
};

const WpondReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case "/wpond/SET_WPOND_YEAR_DATA":
            state = { ...state, yearData:{} };
            return state;
        case "/wpond/SET_WPOND_YEAR_DATA_SUCCESS":
            state = {
                ...state,
                yearData: action.payload
            };
            return state;
        default:
            return state;
    }
    return state;
};

export default WpondReducer;

export function setWpondYearData(response) {
    return { type: SET_WPOND_YEAR_DATA,payload:response };
}

export function setWpondYearDataOnSuccess(state) {
    return { type: SET_WPOND_YEAR_DATA_SUCCESS, payload: state };
}

