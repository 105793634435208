import React from "react";
import $ from 'jquery';
import { NavLink, withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { authService } from "./auth";
import axios from "axios";
import CleverTapUtils from "./commons/CleverTapUtils";
import { communityPattern, projectPattern } from "./commons/CommonFunctions";

class ProfileOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            leftPanelItems: [],
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    activeNetworkTabsUrls = () => {
        return this.props.location?.state?.groupSection !== "COMMUNITY_FOLLOWERS" ? 
            ["/network", "/popular-users", "/connect-requests", "/connected", "/following", "/pages", "/network-groups", "/followers", "/youfollow", "/community-page", "/organization", "/groups", "/people-you-may-know"] :
            ["/network", "/popular-users", "/connect-requests", "/connected", "/following", "/pages", "/network-groups", "/youfollow", "/community-page", "/organization", "/groups", "/people-you-may-know"]
    }

    getItems = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/order/get/' + this.state.userData.userId,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data.body !== null) {

                this.setState({ leftPanelItems: response.data.body.sequenceOrder });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    reorder = (list, startIndex, endIndex) => {
        let finalResult = {};
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        for (let i = 0; i < result.length; i++) {
            finalResult[result[i].name] = i + 1;
        }
        let postBody = {
            "userId": this.state.userData.userId,
            "profileOrder": finalResult
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/order/update',
            headers: { 'Content-Type': 'application/json' },
            data: postBody,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                if ($('.read-more-less-btn').text() == "Read more...") {
                    $('.more-menu').css('display', 'none');
                    $('.less-menu').css('display', 'list-item');
                } else {
                    $('.more-menu').css('display', 'list-item');
                    $('.less-menu').css('display', 'list-item');
                }
            }
        }).catch((err) => {
            console.log(err);
        })
        return result;
    };

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const leftPanelItems = this.reorder(
            this.state.leftPanelItems,
            result.source.index,
            result.destination.index
        );
        this.setState({
            leftPanelItems
        });
    }

    componentDidMount() {
        this.getItems()

    }
    render() {
        const { communityActive } = this.props;
        return (
            <>
                {<DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {this.state.leftPanelItems.map((item, index) => (
                                    <Draggable key={item.value} draggableId={item.value} index={index}>
                                        {(provided, snapshot) => (index < 4 ?
                                            <li className={"less-menu" + (((item.name === "NETWORK" && this.state.userData.type === 'COMPANY') || item.name === "FORUM" || item.name === "POLL") ? " netword-tab" : "")}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {/* {item.name === 'CAUSE' &&
                                                    <NavLink to="/causes" className="causes" activeClassName="active">
                                                        Causes
                                                    </NavLink>
                                                    } */}
                                                {item.name === 'CIRCLE' &&
                                                    <NavLink to="/communities" className={"circle" + ((communityActive || this.props.location?.state?.groupSection === "COMMUNITY_FOLLOWERS") ? " active" : "")} activeClassName="active"
                                                        isActive={(_, { pathname }) => ["/communities"].includes(pathname) || communityPattern.test(pathname.substring(1))}>
                                                        Community
                                                    </NavLink>
                                                }
                                                {item.name === 'EXPLORE' &&
                                                    <NavLink to="/explore" className="explore" activeClassName="active"
                                                        isActive={(_, { pathname }) => ["/explore", "/explore-curated", "/explore-curated/:id", "/explore-trending", "/explore-daily-edition", "/explore-trending/community", "/explore-trending/videos", "/explore-trending/photos", "/explore-trending/blogs", "/explore-hashtag"].includes(pathname)}>
                                                        Explore
                                                    </NavLink>
                                                }
                                                {item.name === 'FEED' &&
                                                    <NavLink exact to="/" className="feeds" activeClassName="active">
                                                        Feeds {/*<span className="new">new</span>*/}
                                                    </NavLink>
                                                }
                                                {item.name === 'FORUM' &&
                                                    <NavLink to="/forum" className="forum" activeClassName="active" onClick={(e) => { this.props.seenAllForum() }}>
                                                        Forum
                                                        {this.props.userForumCount && this.props.userForumCount > 0 ?
                                                            <span>{this.props.userForumCount}</span> : ''
                                                        }
                                                    </NavLink>
                                                }
                                                {item.name === 'MESSAGE' &&
                                                    <NavLink to="/message" className="message" activeClassName="active">
                                                        Message
                                                        {this.props.newUserChatCnt && this.props.newUserChatCnt > 0 ?
                                                            <span>{this.props.newUserChatCnt}</span> : ''}
                                                    </NavLink>
                                                }
                                                {this.state.loginSuccess && this.state.userData.type === 'INDIVIDUAL' && item.name === 'NETWORK' &&
                                                    <NavLink to="/network" className={"network"} activeClassName="active"
                                                        isActive={(_, { pathname }) => this.activeNetworkTabsUrls().includes(pathname)}>
                                                        Network
                                                        {this.props.networkCount && this.props.networkCount > 0 ?
                                                            <span>{this.props.networkCount}</span> : ''}
                                                    </NavLink>
                                                }
                                                {item.name === 'POLL' &&
                                                    <NavLink to="/poll" className="poll" activeClassName="active">
                                                        Poll
                                                        {this.props.userPollCount && this.props.userPollCount > 0 ?
                                                            <span>{this.props.userPollCount}</span> : ''}
                                                    </NavLink>
                                                }
                                                {item.name === 'PROJECT' &&
                                                    <NavLink to="/project" className="project" activeClassName="active"
                                                        isActive={(_, { pathname }) => (["/showMore/runningProject", "/project", "/showMore/completedProject"].includes(pathname) || projectPattern.test(pathname.substring(1)) && !this.props.communityActive)}>
                                                        Projects
                                                        {this.props.projectCount && this.props.projectCount > 0 ?
                                                            <span>{this.props.projectCount}</span> : ''}
                                                    </NavLink>
                                                }
                                                {item.name === 'STUDIO' &&
                                                    <NavLink to="/studio" className="studio" activeClassName="active"
                                                        isActive={(_, { pathname }) => ["/studio"].includes(pathname)}>
                                                        Studio
                                                    </NavLink>
                                                    }
                                                </li> : <li className={"more-menu" + (((item.name === "NETWORK" && this.state.userData.type === 'COMPANY') || item.name === "FORUM" || item.name === "POLL") ? " netword-tab" : "")}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                >
                                                    {/* {item.name === 'CAUSE' &&
                                                    <NavLink to="/causes" className="causes" activeClassName="active">
                                                        Causes
                                                    </NavLink>
                                                    } */}
                                                {item.name === 'CIRCLE' &&
                                                    <NavLink to="/communities" className={"circle" + ((communityActive || this.props.location?.state?.groupSection === "COMMUNITY_FOLLOWERS") ? " active" : "")} activeClassName="active"
                                                        isActive={(_, { pathname }) => ["/communities", "/communities/:slug"].includes(pathname) || communityPattern.test(pathname.substring(1))}>
                                                        Community
                                                    </NavLink>
                                                }
                                                {item.name === 'EXPLORE' &&
                                                    <NavLink to="/explore" className="explore" activeClassName="active"
                                                        isActive={(_, { pathname }) => ["/explore", "/explore-curated", "/explore-trending", "/explore-daily-edition", "/explore-trending/community", "/explore-trending/videos", "/explore-trending/photos", "/explore-trending/blogs", "/explore-hashtag"].includes(pathname)}>
                                                        Explore
                                                    </NavLink>
                                                }
                                                {item.name === 'FEED' &&
                                                    <NavLink exact to="/" className="feeds" activeClassName="active">
                                                        Feeds {/*<span className="new">new</span>*/}
                                                    </NavLink>
                                                }
                                                {/* {item.name === 'FORUM' &&
                                                    <NavLink to="/forum" className="forum" activeClassName="active" onClick={(e) => { this.props.seenAllForum() }}>
                                                        Forum
                                                        {this.props.userForumCount && this.props.userForumCount > 0 ?
                                                            <span>{this.props.userForumCount}</span> : ''
                                                        }
                                                    </NavLink>
                                                } */}
                                                {item.name === 'MESSAGE' &&
                                                    <NavLink to="/message" className="message" activeClassName="active">
                                                        Message
                                                        {this.props.newUserChatCnt && this.props.newUserChatCnt > 0 ?
                                                            <span>{this.props.newUserChatCnt}</span> : ''}
                                                    </NavLink>
                                                }
                                                {this.state.loginSuccess && this.state.userData.type === 'INDIVIDUAL' && item.name === 'NETWORK' &&
                                                    <NavLink to="/network" className="network" activeClassName="active"
                                                        isActive={(_, { pathname }) => this.activeNetworkTabsUrls().includes(pathname)}>
                                                        Network
                                                        {this.props.networkCount && this.props.networkCount > 0 ?
                                                            <span>{this.props.networkCount}</span> : ''}
                                                    </NavLink>
                                                }
                                                {/* {item.name === 'POLL' &&
                                                    <NavLink to="/poll" className="poll" activeClassName="active">
                                                        Poll
                                                        {this.props.userPollCount && this.props.userPollCount > 0 ?
                                                            <span>{this.props.userPollCount}</span> : ''}
                                                    </NavLink>
                                                } */}
                                                {item.name === 'PROJECT' &&
                                                    <NavLink to="/project" className="project" activeClassName="active" onClick={() => {CleverTapUtils.eventClick({}, "View Opportunity");}}
                                                        isActive={(_, { pathname }) => (["/showMore/runningProject", "/project", "/showMore/completedProject"].includes(pathname) || projectPattern.test(pathname.substring(1)) && !this.props.communityActive)}>
                                                        Projects
                                                        {this.props.projectCount && this.props.projectCount > 0 ?
                                                            <span>{this.props.projectCount}</span> : ''}
                                                    </NavLink>
                                                }
                                                {item.name === 'STUDIO' &&
                                                    <NavLink to="/studio" className="studio" activeClassName="active"
                                                        isActive={(_, { pathname }) => ["/studio"].includes(pathname)}>
                                                        Studio
                                                    </NavLink>
                                                }
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>}
            </>
        );
    }
}


export default React.memo(withRouter(ProfileOrder));
