import React, { Component } from "react";
import { Link, NavLink, Redirect, withRouter } from "react-router-dom";
import { authService } from "../../auth";
import { getCDNUrl, getOptimizedImage, userAgentGetData } from "../../commons/CommonFunctions";
import HorizontalScroll from "react-scroll-horizontal";
import mappingUtils from "../../project/component/commons/utils/mappingUtils";
import Share from "../../commons/Share";
import EntityFollow from "../../commons/EntityFollow";
import axios from "axios";

class CircleTopNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            circleData: props.circleData,
            privacySetting: {},
            sharingSetting: {},
            circleFollowerList: [],
            superAdminUserData: {},
            clickViewStats: false,
        };
    }

    getUserDataByUserId() {
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL +
                "/profile/get?id=" +
                this.state.circleData.superAdminId + '&otherUserId=' + this.state.userData.userId + userAgentGetData(),
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.setState({ superAdminUserData: response.data.body });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getFollowingCircleList() {
        axios({
            method: "get",
            url: process.env.REACT_APP_userServiceURL + '/circle/getCircleFollower/?id=' + this.state.circleData.id + '&userId=' + this.state.userData.userId,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === 'Success!') {
                    this.setState({
                        circleFollowerList: response.data.body.circleFollowerDto,
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    
    getDataByCircleId = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL +
                "/circle/get/config/" + this.state.userData.userId + "/" + "?circleId=" + this.state.circleData.id,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                this.setState({
                    privacySetting: response.data.body?.privacySettingConfigDto,
                    sharingSetting: response.data.body?.sharingSettingConfigDto
                })
                this.props.getCircleConfigData(response.data.body?.privacySettingConfigDto); 
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getUserConfig = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/circle/get/user/config/' + this.state.circleData.id + '/' + this.state.userData.userId,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res && res.status === '200 OK') {
                this.setState({
                    userConfigData: res.body
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    componentDidMount() {
        if(this.state.loginSuccess){
            //this.getProfileDeactivationConfig();
            //this.getUserConfig();
            this.getDataByCircleId();
            //this.getFollowingCircleList();
            this.getUserDataByUserId();
        }

        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
    }

    render() {
        const parentnav = { height: '45px' }

        return ( <div className={"projtopbar" + (this.props.aboutSection === true ? " about" : "")} style={{ marginBottom: '0px' }}>
                <div className={this.props.aboutSection === true ? "proftopuserinfo about" : "proftopuserinfo"}>
                    <div className="proftopusersm">
                        <figure className="userlogo">
                            <img
                                src={(this.state.circleData && this.state.circleData.profileImage) ? getCDNUrl(getOptimizedImage(this.state.circleData, 'profileImage')) : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "https://cdn.dscovr.com/images/circleDefault.png"
                                }} alt="" />
                        </figure>
                        <h1><strong>{this.state.circleData && this.state.circleData.title}</strong></h1>
                    </div>
                </div>
                <div className={"projtopbarleft" + (this.props.aboutSection === true ? " about" : "")}>
                    <HorizontalScroll style={parentnav} reverseScroll className="mcustHorizMenu">
                        <ul id="tabs" className="nav">
                            {this.state.circleData && 
                                <li className="nav-item">
                                    {this.state.circleData.slug &&
                                        <NavLink to={"/community/" + this.state.circleData.slug}
                                            activeClassName={this.props.aboutSection ? "active" : ""}>Activity</NavLink>}
                                </li>
                            }

                            {/* {this.state.circleData && (!this.state.circleData.inactiveFlagForMemberSubscription) && mappingUtils.getPrivacySettingData(this.state.privacySetting, 'activity_tab') && this.state.circleData.active === true &&
                                <li className="nav-item">
                                    {(this.state.circleData.slug) &&
                                        <NavLink to={"/community/activity/" + this.state.circleData.slug}
                                            activeClassName="active">Activity</NavLink>}
                                </li>}  */}

                            {(this.state.circleData && (!this.state.circleData.inactiveFlagForMemberSubscription || (this.state.circleData.memberType && (this.state.circleData.memberType === 'Admin' || this.state.circleData.memberType === 'Super Admin'))) && mappingUtils.getPrivacySettingData(this.state.privacySetting, 'project_tab') && this.state.circleData.slug) &&
                                <li className="nav-item">
                                    <NavLink to={"/community/projects/" + this.state.circleData.slug}
                                        activeClassName="active">Projects</NavLink>
                            </li>}
                            

                            {/* <li className="nav-item">
                                {(this.state.circleData && (!this.state.circleData.inactiveFlagForMemberSubscription || (this.state.circleData.memberType && (this.state.circleData.memberType === 'Admin' || this.state.circleData.memberType === 'Super Admin'))) && mappingUtils.getPrivacySettingData(this.state.privacySetting, 'member_tab') && this.state.circleData.slug) &&
                                    <NavLink to={"/community/members/" + this.state.circleData.slug}
                                        activeClassName="active">Members</NavLink>}
                            </li>
                            {this.state.circleData && !this.state.circleData.inactiveFlagForMemberSubscription && this.state.awardDetailsCount > 0 &&
                                <li className="nav-item">
                                    {this.state.circleData.slug &&
                                        <NavLink to={"/community/award/" + this.state.circleData.slug}
                                            activeClassName="active">Awards</NavLink>}
                                </li>
                            } */}
                        
                            {(this.state.userPostCountData?.myPost > 0 &&(mappingUtils.getPrivacySettingData(this.state.privacySetting, 'activity_tab') || this.state.circleData?.activityTabShow)) && this.state.circleData?.active &&
                                <li className="nav-item">
                                        <NavLink to={"/community/studio/" + this.state.circleData.slug} activeClassName="active">Studio</NavLink>
                                </li>
                            }
                            {(this.state.circleData && (!this.state.circleData.inactiveFlagForMemberSubscription || (this.state.circleData.memberType && (this.state.circleData.memberType === 'Admin' || this.state.circleData.memberType === 'Super Admin'))) && mappingUtils.getPrivacySettingData(this.state.privacySetting, 'member_tab') && this.state.circleData.slug) &&
                                <li className="nav-item">
                                    <NavLink to={"/community/members/" + this.state.circleData.slug}
                                        activeClassName="active">Members</NavLink>
                            </li>}
                            {this.state.userPostCountData?.articleCount > 0 && this.state.circleData?.active && (mappingUtils.getPrivacySettingData(this.state.privacySetting, 'activity_tab') || this.state.circleData?.activityTabShow) && 
                                <li className="nav-item">
                                    {(this.state.circleData.slug ) &&
                                        <NavLink to={"/community/article/" + this.state.circleData.slug} activeClassName="active">Articles</NavLink>}
                                </li>
                            }
                            {this.state.loginSuccess && this.state.circleData && !this.state.circleData.inactiveFlagForMemberSubscription && this.state.awardDetailsCount > 0 &&
                                <li className="nav-item">
                                    {this.state.circleData.slug &&
                                        <NavLink to={"/community/award/" + this.state.circleData.slug}
                                            activeClassName="active">Awards</NavLink>}
                                </li>
                            }

                            {mappingUtils.getPrivacySettingData(this.state.privacySetting, 'activity_tab') && this.state.hashtagsDetails?.content && this.state.hashtagsDetails?.content.length > 0 && this.state.circleData?.active &&
                                <li className="nav-item">
                                    {this.state.circleData?.slug &&
                                        <NavLink to={`/community/hashtag/${this.state.circleData?.slug}`} activeClassName="active">HashTags</NavLink>
                                    } 
                                </li>
                            }
                            
                                                        
                            {(this.state.circleData?.memberType === 'Super Admin' || this.state.circleData?.memberType === 'Admin') &&
                                <li className="nav-item">
                                    <NavLink to={"/community/stats/" + this.state.circleData.slug}
                                        activeClassName="active"><i className="iBarGraphs" /></NavLink>
                                </li>
                            }
                        </ul>
                    </HorizontalScroll>   

                    <div className="projtopbarright">
                        {this.state.loginSuccess && 
                            this.state.circleData && this.state.circleData.slug &&
                            <Share shareUrl={"/community/"}
                                hideShareOnPortalBtn={true}
                                sharingSetting={this.state.sharingSetting}
                                shareId={this.state.circleData.slug} />
                        }
                        {this.state.userConfigData && this.state.circleData && this.state.circleData.id &&
                            <EntityFollow entityType={'CIRCLE'} entityId={this.state.circleData.id}
                                followed={this.state.userConfigData.followed}
                                privacySetting={this.state.privacySetting}
                                userId={this.state.userData.userId} />
                        }
                    </div>                     
                </div>                    
            </div>
        );
    }
}
export default withRouter(CircleTopNavigation);