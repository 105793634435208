import React from "react";
import { authService } from "../auth";
import { convertCurrencySystem, redirectToProfile, showErrorMessage } from "../commons/CommonFunctions";
import { getFormattedDate, getOptimizedImage } from "./DonationCommonFunction";
import MutualConnect from "../commons/MutualConnect";
import axios from "axios";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(alertPopUp)

const removeAttachment = () => {
    return ({
        title: `Are you sure you want to delete this image?`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
    })
};
class DonationViewDonorsItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            donationStatus: props?.data?.donationStatus || "INITIAL",
            attachment: props?.data?.attachment,
            attachmentFlag: false,
            disabledButton: false
        }
    }

    updateDonationStatus = (donationStatus) => {
        let postData = {
            id: this.props?.data?.id,
            entitytId: this.props?.entityData?.id,
            entityType: this.props?.entityData?.entityType,
            donationStatus: donationStatus
        }
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_userServiceURL}/backend/donation/update/donation/status`,
            data: postData,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response?.data?.message === "Success!") {
                this.setState({
                    donationStatus
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    attachmentImageUpload = () => {
        this.refs.attachmentFileUploader.click();
    };

    handleUploadAttachment = async (event, type) => {
        let maxFileSize = 2 * 1024 * 1024;
        event.preventDefault();
        try {
            const file = event.target.files[0];
            let fileSize = event.target.files[0].size;
            if (fileSize > maxFileSize) {
                showErrorMessage(`Please upload the file less than or equal to 2 MB`);
                document.getElementById("attachmentFileUploader").value = null;
            } else {
                const fileName = event.target.files[0].name;
                const idxDot = fileName.lastIndexOf(".") + 1;
                const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                const allowedAttachmentsTypeArr = ['jpeg', 'jpg', 'png'];
                if (allowedAttachmentsTypeArr.includes(extFile)) {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('attachmentType', "IMAGE");
                    formData.append('userId', this.state?.userData?.userId);
                    axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_userServiceURL}/backend/post/uploadAttachment`,
                        data: formData,
                        withCredentials: true
                    }).then((response) => {
                        if (response && response.data && response.data.body) {
                            this.setState({
                                attachmentFlag: true
                            })
                            this.updateDonationAttachment(response.data.body);
                        }
                    }).catch((e) => {
                        console.log(e);
                    });
                } else {
                    showErrorMessage(`Only ${allowedAttachmentsTypeArr.join("/")} files are allowed!`);
                    document.getElementById("attachmentFileUploader").value = null;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    updateDonationAttachment = (attachment) => {
        let postData = {
            id: this.props?.data?.id,
            entityType: this.props?.entityData?.entityType,
            attachmentId: attachment?.id
        }
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_userServiceURL}/backend/donation/upload/attachment`,
            data: postData,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response?.data?.message === "Success!") {
                this.setState({ attachment, attachmentFlag: false });
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                attachmentFlag: false
            })
        });
    }

    removeDonationAttachment = async () => {
        const swalval = await MySwal.fire(removeAttachment());
        if(swalval && swalval.value === true) {
            let postData = {
                id: this.props?.data?.id,
                entityType: this.props?.entityData?.entityType
            }
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/backend/donation/remove/attachment`,
                data: postData,
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }).then((response) => {
                if (response?.data?.message === "Success!") {
                    this.setState({ attachment: {} });
                }
            }).catch((err) => {
                console.log(err);
            });
        }
        
    }

    updateUserIntimation = () => {
        let postData = {
            id: this.props?.data?.id,
            entityId: this.props?.entityData?.id,
            entityType: this.props?.entityData?.entityType
        }
        this.setState({
            disabledButton: true
        })
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_userServiceURL}/backend/donation/user/intimation`,
            data: postData,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response?.data?.message === "Success!") {
                showErrorMessage(`Payment reminder sent successfully.`);
                this.setState({ disabledButton: false });
            }
        }).catch((err) => {
            console.log(err);
            this.setState({ disabledButton: false });
        });
    }

    render() {
        const { type, data, index } = this.props;
        const { donationStatus, attachment, attachmentFlag, disabledButton } = this.state;
        return (
            <li>
                <div className="card" key={index}>
                    <div className="card-header" id={'donationViewheading-' + index}>
                        <div className="userBlk" onClick={() => redirectToProfile(data.userCustomUrl)} style={{ cursor: 'pointer' }}>
                            <figure className="users"><img src={data.userProfileImage ? getOptimizedImage(data, "userProfileImage") : data.userType === "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.webp" : "https://cdn.dscovr.com/images/DefaultBusiness.webp"} alt="" /></figure>
                            <div>
                                <h5 className="name">{data.userId !== this.state.userData.userId ? <><span>{data.userName}</span>{data.connectDepth && <MutualConnect connectDepth={data.connectDepth}></MutualConnect>}</> : <><span>You</span></>}</h5>
                                {/* <span className="persona">{data.persona}</span> */}
                                {data.addressDetail && <span className="mutual">{data.addressDetail?.country}</span>}
                            </div>
                        </div>
                        <div className="userDonateInfo date">
                            <div className="donateDate">{getFormattedDate(data.date)}</div>
                        </div>
                        {type === "donors" &&
                            <div className="userDonateInfo status">
                                {donationStatus !== "APPROVED" ?
                                    <div className="shortbydrop">
                                        <button type="button" className="btn dropdown-toggle text-left" data-toggle="dropdown">{donationStatus === "APPROVED" ? "Received" : "Initiated"}
                                        </button>
                                        <div className="dropdown-menu fadeIn animated">
                                            <a className="dropdown-item" id="filter-Forum" onClick={e => { this.updateDonationStatus('APPROVED') }}>Receive</a>
                                        </div>
                                    </div> :
                                    <div className="donateDate status">{"Received"}</div>
                                }
                            </div>
                        }
                        {(type === "donors" || type === "donors_amount") ?
                            <div className="userDonateInfo bankName">
                                {data.bankDetail?.institutionName && <div className="donateBankName" title={data.bankDetail?.institutionName}>{data.bankDetail?.institutionName}</div>}
                            </div> : null
                        }
                        <div className="userDonateInfo fundreveive">
                            {type === "donors" || type === "donors_amount" || (type === "view" && data.totalAmount > 0) ?
                                <>
                                    <div className="donateStatus amount"><strong>{data.currencyType === "INR" ? <>&#8377;</> : <>&#36;</>}{convertCurrencySystem(type === "view" ? data.totalAmount : data.amount)}</strong></div>
                                </> :
                                <div className="donateDate">{"-"}</div>
                            }
                        </div>                        
                        {type === "donors" &&
                            <>                                
                                <div className="userDonateInfo upload">
                                    <>
                                        {!attachment?.id ?
                                            (!attachmentFlag ? 
                                                <a href="javascript:void(0)" onClick={this.attachmentImageUpload} className={"btn attchbtn upload" + (attachmentFlag ? " " : "")}>
                                                    <input
                                                        type="file"
                                                        multiple
                                                        accept=".jpg, .jpeg, .png"
                                                        id="attachmentFileUploader"
                                                        onChange={this.handleUploadAttachment}
                                                        ref="attachmentFileUploader"
                                                        style={{ display: "none" }}
                                                    />
                                                    <i className={"iUploadPhoto"}></i>
                                                </a> :
                                                <img src={"/assets/images/spinner.gif"} width={30} height={30} />
                                            ) :
                                            <figure className="upImgBlk">
                                                <a data-fancybox={data?.id} href={attachment?.attachmentUrl}
                                                   className="youtubrlink"><img src={attachment?.attachmentUrl} width={60} height={60} />
                                                </a>
                                                <i class="iCross_Rounded closebtn" onClick={(e) => this.removeDonationAttachment()}></i>
                                            </figure>
                                        }
                                    </>
                                </div>
                                <div className="userDonateInfo reminder">
                                    <div class="btnblock p-0">
                                        <button className="btn morebtnsm" type="button" onClick={() => this.updateUserIntimation()} disabled={donationStatus === "APPROVED" || disabledButton}>Send</button>
                                    </div>
                                </div>
                            </>
                        }
                        {type === "view" && data.seenList.length > 1 &&
                            <a role="button" data-toggle="collapse" href={'#donationViewcollapse-' + index} aria-expanded="false" className="btn viewBtnAcc"></a>
                        } 
                    </div>
                    {type === "view" && data.seenList.length > 1 &&
                        <div id={'donationViewcollapse-' + index} className="collapse" data-parent="#donationViewAccordion" aria-labelledby={'donationViewheading-' + index}>
                            <div className="card-body">
                                {data.seenList && data.seenList.length > 0 && data.seenList.map((seenData) => {
                                    return (
                                        <li>                                            
                                            <div className="userDonateInfo date">
                                                <div className="donateDate">{getFormattedDate(seenData.time)}</div>
                                            </div>
                                            <div className="userDonateInfo bankName">
                                                {seenData.amount > 0 ?
                                                    <>
                                                        <div className="donateBankName" title={seenData.bankDetail?.institutionName}>{seenData.bankDetail?.institutionName}</div>
                                                    </> :
                                                    <div className="donateDate">{"-"}</div>
                                                }
                                            </div>
                                            <div className="userDonateInfo fundreveive">
                                                {seenData.amount > 0 ?
                                                    <>
                                                        <div className="donateStatus amount"><strong>{seenData.currencyType === "INR" ? <>&#8377;</> : <>&#36;</>}{convertCurrencySystem(seenData.amount)}</strong></div>
                                                    </> :
                                                    <div className="donateDate">{"-"}</div>
                                                }
                                            </div>
                                        </li>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </li>
        );
    }
}

export default DonationViewDonorsItem;