import React, { Component } from 'react';
import { authService } from "../auth";
import axios from "axios";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en';
import $ from "jquery";
import uuid from "react-uuid";
import cloneDeep from "lodash/cloneDeep";
import _ from 'lodash';
import Linkify from "react-linkify";
import { ReactTinyLink } from "react-tiny-link";
import { DeviceUUID } from 'device-uuid';
import { getDeviceId } from '../commons/CommonFunctions';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')


const style = {
    textCenter: { textAlign: 'center' }
}

class Chat extends Component {
    constructor(props) {
        super(props);
        this.privateRef = React.createRef();
        let validation = {
            username: {},
            email: {},
        };
        this.state = {
            message: [],
            sendMessage: "",
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            userMessage: "",
            displayHelp: false,
            question: "",
            answer: "",
            tryAgain: false,
            page: 0,
            size: 10,
            thresholdHeight: 250,
            faq_name: "",
            faq_email: "",
            displayYes: false,
            displayStartAgain: false,
            validation,
            localUserName: "",
            link: "",
            showZeroData: true,
        };
    }

    sendQuery = (message, messageType) => {
        let userId = "";
        if (this.state.userData && !_.isNull(this.state.userData) && this.state.userData.userId && !_.isNull(this.state.userData.userId)) {
            userId = this.state.userData.userId;
        } else {
            userId = localStorage.getItem("deviceId");
        }
        let date = Date.now();
        let epochDate = new Date(date).getTime() / 1000;
        let userText = {
            userId: userId,
            suggestion: null,
            title: message,
            message_type: "USER_TEXT",
            createTime: epochDate,
        };
        this.setState({
            message: [...this.state.message, userText],
            displayHelp: false,
        });
        let emailId = null;
        let userName = null;
        if (this.state.userData && this.state.userData.firstName) {
            userName = this.state.userData.firstName;
        } else if (
            localStorage.getItem("faq_username") &&
            localStorage.getItem("faq_email")
        ) {
            userName = localStorage.getItem("faq_username");
            emailId = localStorage.getItem("faq_email");
        }
        let url = "";
        let domains = window.location.href;
        let path = domains.split("/")[3] || "";
        let results = path === "index.html" ? "" : path;

        if (
            [
                "post",
                "profile",
                "circle",
                "forumDetail",
                "cause",
                "profile-edit",
                "pollDetail",
                "project",
                "forum-poll-draft",
                "project-draft",
                "article-draft",
                "people-you-may-know",
                "popular-users",
                "connect-requests",
                "youfollow",
                "profile-activity",
                "organization-activity",
                "organization-other-activity",
                "organization-employee",
                "organization-other-employee",
                "organization-opportunities",
                "organization-other-opportunities",
                "organization-other-opportunities",
                "organization-followers",
                "organization-other-followers",
                "following-organization-pages",
                "organization-pages",
                "following-circles",
                "suggestion-circles",
                "manage-invitation",
                "explore-latest",
                "explore-hashtag",
                "explore-trending",
                "explore-detail",
                "explore-curated",
                "FaQDetail",
                "message-user",
                "notification-setting",
                "security-and-login",
                "privacy-settings",
                "organization-privacy-settings",
                "circle-privacy-settings",
            ].includes(results)
        ) {
            url = results;
            if (results === "forumDetail") {
                url = "forum";
            } else if (results === "pollDetail") {
                url = "poll";
            } else if (
                ["forum-poll-draft", "project-draft", "article-draft"].includes(results)
            ) {
                url = "draft";
            } else if (
                [
                    "people-you-may-know",
                    "popular-users",
                    "connect-requests",
                    "youfollow",
                    "",
                ].includes(results)
            ) {
                url = "network";
            } else if (results === "profile-activity") {
                url = "profile";
            } else if (
                [
                    "organization-activity",
                    "organization-other-activity",
                    "organization-employee",
                    "organization-other-employee",
                    "organization-opportunities",
                    "organization-other-opportunities",
                    "organization-other-opportunities",
                    "organization-followers",
                    "organization-other-followers",
                    "following-organization-pages",
                    "organization-pages",
                ].includes(results)
            ) {
                url = "organization";
            } else if (
                ["following-circles", "suggestion-circles"].includes(results)
            ) {
                url = "circle";
            } else if (results === "manage-invitation") {
                url = "manage invitation";
            } else if (
                [
                    "explore-latest",
                    "explore-hashtag",
                    "explore-trending",
                    "explore-detail",
                    "explore-curated",
                ].includes(results)
            ) {
                url = "explore";
            } else if (["FaQDetail"].includes(results)) {
                url = "faq";
            } else if (["message-user"].includes(results)) {
                url = "message";
            } else if (
                [
                    "notification-setting",
                    "security-and-login",
                    "privacy-settings",
                    "organization-privacy-settings",
                    "circle-privacy-settings",
                ].includes(results)
            ) {
                url = "privacy and settings";
            }
        } else {
            url = window.location.href.substring(
                window.location.href.lastIndexOf("/") + 1
            );
        }
        let postBody = {
            userId: userId,
            message: message,
            message_type: messageType,
            user_name: userName,
            faq_email: emailId,
            page_name:
                url !== ""
                    ? url
                    : !this.state.loginSuccess
                        ? "help assistance"
                        : "feed",
            type: this.state.loginSuccess ? "AUTH" : "UNAUTH",
        };
        if (messageType === "NOT_HELPFUL") {
            this.setState({
                displayHelp: false,
                tryAgain: true,
            });
        } else if (messageType === "TRY_AGAIN") {
            this.setState({
                displayHelp: false,
                tryAgain: false,
            });
        }
        axios({
            method: "post",
            url:
                process.env.REACT_APP_userServiceURL +
                "/recommend/unauth/faqchatservice/",
            headers: { "Content-Type": "application/json" },
            data: postBody,
            withCredentials: true,
        })
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.status === 200 &&
                    response.data.data
                ) {
                    let newMessage = response.data.data;
                    this.setState(
                        {
                            message: [...this.state.message, newMessage],
                            sendMessage: "",
                        },
                        () => {
                            this.state.message &&
                                this.state.message.map((data) => {
                                    if (data && data.message_type === "DEFAULT_RECOMMENDATION") {
                                        this.setState(
                                            {
                                                tryAgain: true,
                                                displayHelp: false,
                                                displayYes: false,
                                                displayStartAgain: false,
                                            },
                                            () => {
                                                window.$(".extChatContainer")
                                                    .stop()
                                                    .animate(
                                                        {
                                                            scrollTop: window.$(".extChatContainer")[0].scrollHeight,
                                                        },
                                                        300
                                                    );
                                            }
                                        );
                                    }
                                    if (data && data.message_type === "TRY_AGAIN") {
                                        this.setState(
                                            {
                                                tryAgain: false,
                                                displayHelp: false,
                                                displayYes: false,
                                                displayStartAgain: false,
                                            },
                                            () => {
                                                window.$(".extChatContainer")
                                                    .stop()
                                                    .animate(
                                                        {
                                                            scrollTop: window.$(".extChatContainer")[0].scrollHeight,
                                                        },
                                                        300
                                                    );
                                            }
                                        );
                                    }
                                    if (data && data.message_type === "RECOMMENDATION") {
                                        this.setState(
                                            {
                                                displayHelp: true,
                                                displayYes: false,
                                                tryAgain: false,
                                                displayStartAgain: false,
                                            },
                                            () => {
                                                window.$(".extChatContainer")
                                                    .stop()
                                                    .animate(
                                                        {
                                                            scrollTop: window.$(".extChatContainer")[0].scrollHeight,
                                                        },
                                                        300
                                                    );
                                            }
                                        );
                                    }
                                    if (data && data.message_type === "HELPFUL") {
                                        this.setState(
                                            {
                                                displayHelp: false,
                                                displayYes: true,
                                                tryAgain: false,
                                                displayStartAgain: false,
                                            },
                                            () => {
                                                window.$(".extChatContainer")
                                                    .stop()
                                                    .animate(
                                                        {
                                                            scrollTop: $(".extChatContainer")[0].scrollHeight,
                                                        },
                                                        300
                                                    );
                                                $(".extChatPanel").removeClass("sideOpen");
                                            }
                                        );
                                    }
                                    if (data && data.message_type === "NOT_HELPFUL") {
                                        this.setState(
                                            {
                                                displayHelp: false,
                                                displayYes: false,
                                                tryAgain: true,
                                                displayStartAgain: false,
                                            },
                                            () => {
                                                $(".extChatContainer")
                                                    .stop()
                                                    .animate(
                                                        {
                                                            scrollTop: $(".extChatContainer")[0].scrollHeight,
                                                        },
                                                        300
                                                    );
                                                window.$(".extChatPanel").removeClass("sideOpen");
                                            }
                                        );
                                    }
                                    if (data && data.message_type === "MORE_QUESTION") {
                                        this.setState(
                                            {
                                                displayHelp: false,
                                                displayYes: false,
                                                tryAgain: false,
                                                displayStartAgain: false,
                                            },
                                            () => {
                                                window.$(".extChatContainer")
                                                    .stop()
                                                    .animate(
                                                        {
                                                            scrollTop: window.$(".extChatContainer")[0].scrollHeight,
                                                        },
                                                        300
                                                    );
                                            }
                                        );
                                    }
                                    if (data && data.message_type === "NO_QUESTION") {
                                        this.setState(
                                            {
                                                displayHelp: false,
                                                displayYes: false,
                                                tryAgain: false,
                                                displayStartAgain: true,
                                            },
                                            () => {
                                                window.$(".extChatContainer")
                                                    .stop()
                                                    .animate(
                                                        {
                                                            scrollTop: window.$(".extChatContainer")[0].scrollHeight,
                                                        },
                                                        300
                                                    );
                                            }
                                        );
                                    }
                                    if (data && data.message_type === "START_AGAIN") {
                                        this.setState(
                                            {
                                                displayHelp: false,
                                                displayYes: false,
                                                tryAgain: false,
                                                displayStartAgain: false,
                                            },
                                            () => {
                                                window.$(".extChatContainer")
                                                    .stop()
                                                    .animate(
                                                        {
                                                            scrollTop: window.$(".extChatContainer")[0].scrollHeight,
                                                        },
                                                        300
                                                    );
                                            }
                                        );
                                    }
                                    /*$(".extChatContainer")
                                      .stop()
                                      .animate(
                                        {
                                          scrollTop: $(".extChatContainer")[0].scrollHeight,
                                        },
    
                                        300
                                      );*/

                                });
                        }
                    );
                }
            })
            .catch((error) => {
                console.log("Error", error);
            });
    };

    onChangeMessage = (e) => {
        this.setState({
            sendMessage: e.target.value,
        });
    };

    sendYes = () => {
        this.sendQuery("Yes", "HELPFUL");
    };

    sendNo = () => {
        this.sendQuery("No", "NOT_HELPFUL");
    };

    sendTryAgain = () => {
        this.sendQuery("Try Again", "TRY_AGAIN");
    };

    sendlogicYes = () => {
        this.sendQuery("Yes", "MORE_QUESTION");
    };

    sendlogicNo = () => {
        this.sendQuery("No", "NO_QUESTION");
    };

    sendStartAgain = () => {
        this.sendQuery("Start Again", "START_AGAIN");
    };

    fetchMoreMessageData = () => {
        const scrollTop = this.privateRef.current.scrollTop;
        if (scrollTop === 0) {
            this.setState(
                { isResultLoading: true, page: this.state.page + 1 },
                () => {
                    this.getMessages(this.state.page, this.state.size, true);
                }
            );
        }
    };

    initialMessage = () => {
        this.setState(
            {
                page: 0,
                size: 10,
            },
            () => {
                this.getMessages(this.state.page, this.state.size, false);
            }
        );
        window.$(".extChatContainer")
            .stop()
            .animate({ scrollTop: window.$(".extChatContainer")[0].scrollHeight, }, 300);
    };


    getMessages = (page, size, concatWithPrevious, username, email) => {
        let userName = null;
        let emailId = null;
        let url = "";
        let domains = window.location.href;
        let path = domains.split("/")[3] || "";
        let results = path === "index.html" ? "" : path;
        if (
            results === "profile" ||
            results === "post" ||
            results === "circle" ||
            results === "forumDetail" ||
            results === "cause" ||
            results === "profile-edit" ||
            results === "pollDetail" ||
            results === "project"
        ) {
            url = results;
        } else {
            url = window.location.href.substring(
                window.location.href.lastIndexOf("/") + 1
            );
        }
        let userId = "";
        if (this.state.userData) {
            userId = this.state.userData.userId;
        } else {
            userId = localStorage.getItem("deviceId");
        }

        if (this.state.loginSuccess) {
            userName =
                this.state.userData.type === "INDIVIDUAL"
                    ? this.state.userData.firstName
                    : this.state.userData.companyName;
        } else if (
            localStorage.getItem("faq_username") &&
            localStorage.getItem("faq_email")
        ) {
            userName = localStorage.getItem("faq_username");
            emailId = localStorage.getItem("faq_email");
        }

        let postBody = {
            userId: userId,
            page_no: page,
            limit: size,
            user_name: userName,
            faq_email: emailId,
            page_name:
                url !== ""
                    ? url
                    : !this.state.loginSuccess
                        ? "help assistance"
                        : "feed",
            type: this.state.loginSuccess ? "AUTH" : "UNAUTH",
        };
        axios({
            method: "post",
            url:
                process.env.REACT_APP_userServiceURL + "/recommend/unauth/faqchatbot/",
            headers: { "Content-Type": "application/json" },
            data: postBody,
            withCredentials: true,
        })
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.status === 200 &&
                    response.data.data
                ) {
                    let messageData = [];
                    if (
                        response.data.data &&
                        response.data.data.message_type === "WELCOME_MESSAGE"
                    ) {
                        messageData.push(response.data.data);
                    } else {
                        messageData = response.data.data.reverse();
                        if (concatWithPrevious) {
                            let message = cloneDeep(this.state.message);
                            messageData = messageData.concat(message);
                        }
                    }
                    this.setState({
                        message: messageData,
                    });
                }
            })
            .catch((error) => {
                console.log("Error", error);
            });
    };

    componentDidMount() {
        const Deviceuuid = new DeviceUUID().get() + '-' + uuid();
        // let DeviceUuid = '';
        // let ID = "";
        // if (localStorage.getItem('rzp_device_id')) {
        //     DeviceUuid = localStorage.getItem('rzp_device_id');
        //     ID = DeviceUuid.split('.')[1];
        // } else {
        //     DeviceUuid = getDeviceId();
        //     ID = DeviceUuid;
        // }
        window.$(".extChatIco").on("click", function () {
            window.$(this).hide();
            window.$(".extChatPanel").show();
        });
        window.$(".extChat-header .close").on("click", function () {
            window.$(".extChatPanel").hide();
            window.$(".extChatIco").show();
        });
        window.$(".extChatSuggestFull .back").on("click", function () {
            window.$(".extChatPanel").removeClass("sideOpen");
        });
        if (!localStorage.getItem("deviceuuID")) {
            localStorage.setItem("deviceuuID", Deviceuuid);
            localStorage.removeItem("faq_email");
            localStorage.removeItem("faq_username");
        }
        if (this.state.loginSuccess) {
            if (this.state.userData.type === "INDIVIDUAL") {
                this.setState({
                    localUserName: this.state.userData.firstName,
                });
            } else {
                this.setState({
                    localUserName: this.state.userData.companyName,
                });
            }
        } else {
            this.setState({
                localUserName: localStorage.getItem("faq_username"),
            });
        }
    }

    keyPressed = (event) => {
        if (
            event.charCode === 13 &&
            event.key === "Enter" &&
            event.shiftKey === false
        ) {
            this.sendQuery(this.state.sendMessage, "USER_TEXT");
        }
    };

    dateFormat = (time) => {
        var utcSeconds = time;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 300);
        return d;
    };

    onTitleClick = (question, answer, link) => {
        this.setState(
            {
                question: question,
                answer: answer,
                link: link,
                id: uuid(),
            },
            () => {
                window.$(".extChatPanel").addClass("sideOpen");
            }
        );
    };

    onChangeName = (e) => {
        this.setState({
            faq_name: e.target.value,
        });
    };

    onChangeEmail = (e) => {
        this.setState({
            faq_email: e.target.value,
        });
    };

    validateUserDetails = () => {
        this.state.validation.username = "";
        this.state.validation.email = "";
        let formIsValid = true;
        if (
            _.isUndefined(this.state.faq_name) ||
            _.isEmpty(this.state.faq_name) ||
            _.isNull(this.state.faq_name) ||
            this.state.faq_name.trim().replace(" ", "").length > 20 ||
            this.state.faq_name.trim().replace(" ", "").length < 2
        ) {
            this.state.validation.username = {
                error: true,
                style: { borderColor: "#b34c4c" },
                message: "Name must have minimum 2 characters",
            };
            this.setState({ isLoading: false });
            formIsValid = false;
        } else {
            const fn = /^[a-zA-Z\s]+$/;
            this.state.validation.username = {};
            if (!fn.test(this.state.faq_name)) {
                this.state.validation.username = {
                    error: true,
                    style: { borderColor: "#b34c4c" },
                    message: "Please enter a valid username",
                };
                this.setState({ isLoading: false });
                formIsValid = false;
            }
        }
        if (
            _.isUndefined(this.state.faq_email) ||
            _.isEmpty(this.state.faq_email) ||
            _.isNull(this.state.faq_email)
        ) {
            this.state.validation.email = {
                error: true,
                style: { borderColor: "#b34c4c" },
                message: "Please enter email",
            };
            this.setState({ isLoading: false });
            formIsValid = false;
        } else {
            const email =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.state.validation.email = {};
            if (!email.test(this.state.faq_email)) {
                this.state.validation.email = {
                    error: true,
                    message: "Please enter a valid email address",
                    style: { borderColor: "#b34c4c" },
                };
                this.setState({ isLoading: false });
                formIsValid = false;
            }
        }
        return formIsValid;
    };

    sendUserDetails = () => {
        if (!this.state.faq_email || !this.state.faq_name) {
            this.setState(
                {
                    faq_email: "user@email.com",
                    faq_name: "user",
                },
                () => {
                    localStorage.setItem("faq_email", this.state.faq_email);
                    localStorage.setItem("faq_username", this.state.faq_name);
                    this.getMessages(0, 8, false);
                    this.setState(
                        {
                            localUserName: localStorage.getItem("faq_username"),
                            showZeroData: false,
                        },
                        () => {
                            console.log(this.state.showZeroData);
                        }
                    );
                    {
                        setTimeout(() => {
                            this.setState({
                                errors: {},
                                validation: {},
                            });
                        }, 5000);
                    }
                }
            );
        } else {
            localStorage.setItem("faq_email", this.state.faq_email);
            localStorage.setItem("faq_username", this.state.faq_name);
            this.getMessages(0, 8, false);
            this.setState(
                {
                    localUserName: localStorage.getItem("faq_username"),
                    showZeroData: false,
                },
                () => {
                    console.log(this.state.showZeroData);
                }
            );
            {
                setTimeout(() => {
                    this.setState({
                        errors: {},
                        validation: {},
                    });
                }, 5000);
            }
        }
    };

    render() {
        return (
            <div>
                <div className="extChatIco" onClick={this.initialMessage}>
                    <i className="iDoubt" />
                </div>
                <div className={"extChatPanel"}>
                    <div className="extChat-header">
                        <h4>
                            <i className="iDoubt" /> I am here to help you!
                        </h4>
                        <button className="close">
                            <i className="iCross" />
                        </button>
                    </div>
                    <div className="extChat-body">
                        <div
                            className="extChatContainer"
                            ref={this.privateRef}
                            onScroll={this.fetchMoreMessageData}
                        >
                            {this.state.message &&
                                this.state.message.map((data) => {
                                    var utcSeconds = data.createTime;
                                    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                                    d.setUTCSeconds(utcSeconds);
                                    return (
                                        <div id={data.id}>
                                            {data.message_type !== "USER_TEXT" && (
                                                <div className="msghistlist incoming_msg">
                                                    <figure className="incoming_msg_img welogo">
                                                        <img src="https://cdn.dscovr.com/images/welogoNew.webp"
                                                            onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/welogoNew.png" }} alt="" />
                                                    </figure>
                                                    <div className="received_msg">
                                                        <div className="msgboxpanel">
                                                            <div className="msgbox received_msginn">
                                                                {data.title}
                                                                {data &&
                                                                    data.message_type === "RECOMMENDATION" &&
                                                                    data.suggestion &&
                                                                    data.suggestion.map((data) => {
                                                                        return (
                                                                            <div className="extChatSuggest">
                                                                                <div
                                                                                    className="faq-items"
                                                                                    onClick={(e) => {
                                                                                        this.onTitleClick(
                                                                                            data.question,
                                                                                            data.answer,
                                                                                            data.link
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {data.question}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                {data &&
                                                                    (data.message_type === "WELCOME_MESSAGE" ||
                                                                        data.message_type === "HELPFUL" ||
                                                                        data.message_type === "NOT_HELPFUL") &&
                                                                    data.suggestion && (
                                                                        <div className="extChatSuggest">
                                                                            <Linkify>{data.suggestion}</Linkify>
                                                                        </div>
                                                                    )}
                                                                <span className="msgdtime">
                                                                    {timeAgo.format(d, "mini-now")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {data.title !== "Try Again" &&
                                                data.title !== "Yes" &&
                                                data.title !== "No" &&
                                                data.message_type === "USER_TEXT" && (
                                                    <div className="msghistlist outgoing_msg">
                                                        <div className="sent_msg">
                                                            <div className="msgboxpanel">
                                                                <div className="msgbox received_msginn">
                                                                    {data.title}
                                                                    <span className="msgdtime">
                                                                        {timeAgo.format(d, "mini-now")}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    );
                                })}

                            <div
                                className="d-flex justify-content-center"
                                style={{ marginBottom: "15px" }}
                            >
                                {!this.state.localUserName && !this.state.loginSuccess && (
                                    <span className="encryptMsg">
                                        Please share your name and email to assist you and get to
                                        know more about WeNaturalists.
                                    </span>
                                )}
                            </div>

                            {!this.state.localUserName && !this.state.loginSuccess && (
                                <div className="msghistlist outgoing_msg">
                                    <div className="sent_msg">
                                        <div className="msgboxpanel">
                                            <div className="msgbox received_msginn">
                                                <div className="extChatForm">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Name"
                                                            onChange={this.onChangeName}
                                                            maxLength={20}
                                                        />
                                                        <span
                                                            style={{ display: "block" }}
                                                            className="login-feedback-error mt-1"
                                                        >
                                                            {this.state.validation.username &&
                                                                this.state.validation.username.message}
                                                        </span>
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Email Address"
                                                            onChange={this.onChangeEmail}
                                                        />
                                                        <span
                                                            style={{ display: "block" }}
                                                            className="login-feedback-error mt-1"
                                                        >
                                                            {this.state.validation.email &&
                                                                this.state.validation.email.message}
                                                        </span>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <button
                                                            className="extChatFormSend"
                                                            type="button"
                                                            onClick={this.sendUserDetails}
                                                        >
                                                            <i className="iSend_fl" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="extChat-footer">
                        {this.state.displayStartAgain === true && (
                            <div className="extChatHelpfull">
                                <div className="d-flex forwcontwrapbott">
                                    <button className="btn" onClick={this.sendStartAgain}>
                                        Start Again
                                    </button>
                                </div>
                            </div>
                        )}
                        {this.state.tryAgain === true && (
                            <div className="extChatHelpfull">
                                <div className="d-flex forwcontwrapbott">
                                    <button className="btn" onClick={this.sendTryAgain}>
                                        Try Again
                                    </button>
                                </div>
                            </div>
                        )}
                        {this.state.displayHelp === true && (
                            <div className="extChatHelpfull">
                                <p>Was this helpful?</p>
                                <div className="d-flex forwcontwrapbott">
                                    <button className="btn" onClick={this.sendYes}>
                                        Yes
                                    </button>
                                    <button className="butt btnsecondary" onClick={this.sendNo}>
                                        No
                                    </button>
                                </div>
                            </div>
                        )}
                        {this.state.displayYes === true && (
                            <div className="extChatHelpfull">
                                <div className="d-flex forwcontwrapbott">
                                    <button className="btn" onClick={this.sendlogicYes}>
                                        Yes
                                    </button>
                                    <button
                                        className="butt btnsecondary"
                                        onClick={this.sendlogicNo}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        )}
                        {this.state.localUserName &&
                            this.state.displayStartAgain === false &&
                            this.state.tryAgain === false &&
                            this.state.displayHelp === false &&
                            this.state.displayYes === false && (
                                <div className="msgtypeleft">
                                    <div className="form-group">
                                        <textarea
                                            id="chatMessage_Private"
                                            className="form-control"
                                            placeholder="Write a message..."
                                            onChange={this.onChangeMessage}
                                            value={this.state.sendMessage}
                                            onKeyPress={(e) => {
                                                this.keyPressed(e);
                                            }}
                                        />
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.sendQuery(this.state.sendMessage, "USER_TEXT")
                                            }
                                            className="butt"
                                        >
                                            <i className="iSend_fl" />
                                        </button>
                                    </div>
                                </div>
                            )}
                    </div>
                    <div className={"extChatSuggestFull"}>
                        <div className="extChatSuggestFullHeader">
                            <div className="btnsblk">
                                <button className="back">Back</button>
                            </div>
                            <h4>{this.state.question}</h4>
                        </div>
                        <div className="extChatSuggestFullbody">
                            <div
                                dangerouslySetInnerHTML={{ __html: this.state.answer }}
                            ></div>
                            {this.state.link && (
                                <ReactTinyLink
                                    cardSize="small"
                                    showGraphic={true}
                                    maxLine={2}
                                    minLine={1}
                                    url={this.state.link}
                                    proxyUrl={"https://cors-anywhere.beegram.net"}
                                />
                            )}
                            <div className="extChatHelpfull">
                                <p>Was this helpful?</p>
                                <div className="d-flex forwcontwrapbott">
                                    <button className="btn" onClick={this.sendYes}>
                                        Yes
                                    </button>
                                    <button className="butt btnsecondary" onClick={this.sendNo}>
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Chat;
