import React, {Component} from 'react';
import {authService} from "../auth";
import axios from "axios";
import "./styles/EndorsementPopUp.min.css";
import mappingUtils from "../project/component/commons/utils/mappingUtils";
import uuid from "react-uuid";
import {convertUnicode} from "../commons/CommonFunctions";


class InviteeRequestPopUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            skillsSuggestions: [],
            skills: [],
            isSubmitted: false,
            inviteeBody :true,
            errors: {},
            loadClass :'modal fade dgpopup confirmpop',
            isLoading: false,
            modalEntityId: this.props.entityId + uuid(),
            visibleToProfileChecked:true,
            visibleToProfile:true
        }

    }

    componentDidMount() {
        window.$('#inviteeConfirmpop' + this.state.modalEntityId).modal('show');
    }

    componentWillUnmount() {
        window.$('#inviteeConfirmpop' + this.state.modalEntityId).modal('hide');
    }


    hideModal = () => {
        window.$('#inviteeConfirmpop' + this.state.modalEntityId).modal('hide');
        this.props.triggerInviteeRequestPopUpClose();
    }

    handleVisibleToProfile = (event)=>{
        event.preventDefault();
        this.setState({'visibleToProfileChecked':event.target.checked});
        let visibleToProfile = this.state.visibleToProfile;
        let postBody = {
            "projectId":this.props.entityId,
            "visibleToProfile": !this.state.visibleToProfile,
            "userId":this.state.userData.userId,
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/participants/update/visible-to-profile',
            headers: { 'Content-Type': 'application/json' },
            data: postBody,
            withCredentials: true
        }).then((response, dispatch) => {
            let res = response.data;
            if (response.status === 202) {
                this.setState({'visibleToProfile':!this.state.visibleToProfile});
                //this.props.triggerParticipantRequestPopUpClose();
            }
        }).catch((err) => {

        });

    };


    acceptRequest = (event) => {
        event.preventDefault();
        this.setState({isLoading: true, isSubmitted: false})
        let postBody = {
            "id": this.props.participantRequestId,
            "participationPartyType": 'KEY_SPEAKERS',
            "notificationId": this.props.participantNotificationId
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/invitee/accept/request',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (response.status === 202) {
                this.props.updateNotificationDataForInviteeRequest(this.props.participantNotificationId,true);
                if(this.props.projectType.toLowerCase() !== 'job' && this.state.userData.type !== 'COMPANY'){
                    this.setState({loadClass:'modal fade dgpopup reportPop show','inviteeBody':false});
                } else{
                    this.props.triggerInviteeRequestPopUpClose();
                }

            }
            this.setState({isLoading: false});
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    isSubmitted: false,
                    error: {message: err.response.data.message, err: err.response}
                });
            }
        });
        setTimeout(() => {
            this.setState({
                error: {},
                isLoading: false
            });
        }, 5000);
    };

    triggerProfileVisibilityClose = ()=>{

    }

    triggeredProfileVisibilityCloseOnSuccess = ()=>{

    }

    cancelRequest = (event) => {
        event.preventDefault();
        this.setState({isLoading: true, isSubmitted: false})
        let postBody = {
            "id": this.props.participantRequestId,
            "notificationId": this.props.participantNotificationId
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/invitee/cancel/request',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (response.status === 202) {
                this.hideModal();
            }
            this.setState({isLoading: false});
            this.props.updateNotificationDataForInviteeRequest(this.props.participantNotificationId,false);
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    isSubmitted: false,
                    error: {message: err.response.data.message, err: err.response}
                });
            }
        });
        setTimeout(() => {
            this.setState({
                error: {},
                isLoading: false
            });
        }, 5000);
    };

    render() {
        const {error} = this.state;
        return <>
            <div className={this.state.loadClass} id={'inviteeConfirmpop'+this.state.modalEntityId} tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop={'static'}>
                <div className="modal-dialog" role="document">
                    {this.state.inviteeBody &&
                    <div className="modal-content">
                        <button type="button" className="close"  aria-label="Close" onClick={this.hideModal}><span
                            aria-hidden="true">&times;</span></button>
                        {this.props.projectType === 'TRAINING' ?
                            <div className="confirm-popup-modal-body">Are you sure you want to accept invitation request
                                as a Faculty/Trainer
                                for {mappingUtils.capitalizeFirstLetter(this.props.projectType)} {convertUnicode(this.props.participantRequestTitle)}?
                            </div> :
                            <div className="confirm-popup-modal-body">Are you sure you want to accept invitation request
                                as {this.props.projectType && (this.props.projectType.toLowerCase() === 'expedition'? 'an Expedition leader' : 'a key speaker')}{' '}
                                for {mappingUtils.capitalizeFirstLetter(this.props.projectType)} {convertUnicode(this.props.participantRequestTitle)}?
                            </div>}
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.acceptRequest}
                                    disabled={this.state.isLoading}>Yes
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={this.cancelRequest}>No</button>
                        </div>
                    </div>
                    }
                    {!this.state.inviteeBody &&
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="forwcontwrap">
                                <div className="formwrap">
                                    <div className="custom-control custom-checkbox text-left">
                                        <input type="checkbox" className="custom-control-input"
                                               checked={this.state.visibleToProfileChecked}
                                               onChange={this.handleVisibleToProfile}
                                               id="projectFormVisibleToProfile"/>
                                        <label className="custom-control-label"
                                               htmlFor="projectFormVisibleToProfile" style={{cursor: 'pointer'}}>
                                            Display in Profile
                                            <span style={{display: 'block'}}>Ongoing or the Completed projects will reflect in your Profile under the experience section</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="formbottom text-center">
                                    <button type="button" className="btn btn-cancel"
                                            onClick={this.hideModal}>OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>

        </>


    }
}



export default InviteeRequestPopUp;


