import React, {Component} from 'react';
import axios from "axios";
import {connect} from "react-redux";
import OtpInput from "react-otp-input";
import {authService} from "../auth";


class ProfileDeactivateOtp extends Component {

    constructor(props) {
        super(props);
        let validation = {
            description: {},
        }
        this.multiselectRef = React.createRef();
        this.state = {
            error: false,
            messages: null,
            isLoading: true,
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            signUpSuccess: false,
            emailAvailable: false,
            otp: '',
            numInputs: 6,
            separator: '',
            isDisabled: false,
            hasErrored: false,
            isInputNum: false,
            OTP: '',
            setOTP: '',
            data: this.props.data,
            resendOTP: false,
            timer: 0,
            value: 0,
            message: '',
            success: false,
            textOtp: '',
            transactionId: this.props.transactionId,
            minutes: 10,
            seconds: 0

        };

    }


    handleOtpChange = otp => {
        this.setState({otp});
    };

    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    componentDidMount() {
        let os = this.getMobileOperatingSystem()
        if (os !== 'iOS') {
            window.$(".OTP").keydown(function (event) {
                // Allow only backspace and delete
                if (event.keyCode == 46 || event.keyCode == 8) {
                    // let it happen, don't do anything
                } else {
                    // Ensure that it is a number and stop the keypress
                    if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                        event.preventDefault();
                    }
                }
            });
        }
        this.resendButtonWait();

    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.optrefresh === true){
            this.setState({otp: ''});
        }
        if(nextProps.transactionId !== this.props.transactionId){
            this.setState({
                transactionId: nextProps.transactionId
            })
        }
    }


    resendButtonWait = (event) => {
        this.myInterval = setInterval(() => {
            const {seconds, minutes} = this.state

            if (seconds > 0) {
                this.setState(({seconds}) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({minutes}) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let postBody = {
            "userId": this.props.userId,
            "transactionId": this.state.transactionId,
            "otp": this.state.otp
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/otp/verify',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                window.$('#deactivateProfileOTP').modal('hide');
                if (this.props.OtpVerifiedSuccess) {
                    this.props.OtpVerifiedSuccess();
                }
            }
        }).catch((err) => {

            if (err && err.response.data) {
                setTimeout(() => {
                    this.revokeError();
                }, 5000);
                this.setState({
                    isLoaded: true,
                    error: {message: err.response.data.message, err: err.response}
                });

            }
            this.clearOtp();
        });

    };


    resendOtp = (event) => {
        event.preventDefault();
        let otpSource = "EMAIL";
        let mediaData = this.props.data.email;
        // if (this.props.data.countryISDCode && this.props.data.countryISDCode === '91') {
        //     otpSource = 'MOBILE';
        //     mediaData = this.props.data.phone;
        // } else {
        //     otpSource = 'EMAIL';
        //     mediaData = this.props.data.email;
        // }
        let postBody = {
            "userId": this.props.userId,
            "mediaData": mediaData,
            "otpType": 'PROFILE_DEACTIVATION_REQUEST',
            "otpSource": otpSource
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/otp/re-send',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.setState({
                    transactionId: res.body.transactionId,
                    message: 'OTP resent successfully',
                    minutes: 10,
                    seconds: 0,
                    success: true,
                    resendOTP: false
                });
                this.resendButtonWait()
                setTimeout(() => {
                    this.setState({message: '', success: false});
                    this.resendButtonWait();
                }, 5000);

            }
        }).catch((err) => {
            console.log(err);
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    error: {message: err.response.data.message, err: err.response}
                });

            }

        });
    };

    clearOtp = () => {
        this.setState({otp: ''});
    };

    revokeError() {
        this.setState({messages: '', error: false});
    }

    render() {
        const {otp, numInputs, separator, isDisabled, hasErrored, minutes, seconds} = this.state;
        let textOtp = ''
        if (this.props.data && this.props.data.countryISDCode === '91' && this.props.data.maskedPhone !== null) {
            textOtp = <p>Enter the 6 digit OTP sent on your registered phone <a href="javascript:void(0);"
                                                                                className="otp-link"
                                                                                style={{cursor: 'default'}}>+{this.props.data.countryISDCode} &nbsp;
                {this.props.data.maskedPhone}</a></p>
        } else {
            textOtp = <p>Enter the 6 digit OTP sent on your email <a href="javascript:void(0);"
                                                                                className="otp-link"
                                                                                style={{cursor: 'default'}}>{this.props.data.maskedEmail}</a>
            </p>
        }

        return <>

            <div id="deactivateProfileOTP" className="modal fade dgpopup otppopk" data-backdrop="static" tabIndex="-1"
                 role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="addendorsepop addEmailpop otpwrap">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true" onClick={this.clearOtp}>×</span></button>
                                <div className="signup-box text-center">
                                    <div className="signup-boxinn otpwrap">
                                        {textOtp}
                                        <div className="signup-form-container">
                                            <form className="otp-form" onSubmit={this.handleSubmit}>
                                                <div className="optfield">
                                                    <OtpInput
                                                        numInputs={numInputs}
                                                        isDisabled={isDisabled}
                                                        hasErrored={hasErrored}
                                                        errorStyle="error"
                                                        onChange={this.handleOtpChange}
                                                        separator={<span>{separator}</span>}
                                                        isInputNum={true}
                                                        inputStyle="OTP"
                                                        shouldAutoFocus
                                                        value={otp}
                                                    />

                                                    {this.state.error &&
                                                    <div className="login-feedback-error">
                                                        <strong></strong> {this.state.error.message}.
                                                    </div>
                                                    }
                                                </div>

                                                <div>
                                                    <button
                                                        className="login-btn btn btn-primary mt-3"
                                                        disabled={otp.length < numInputs}>
                                                        Submit
                                                    </button>
                                                    {/* <button type="button" class="btn btn-cancel" data-dismiss="modal" style={{ marginLeft: "6px" }}>Cancel</button> */}
                                                    <p className="resend">
                                                        {minutes === 0 && seconds === 0
                                                            ? <button type="button"
                                                                      onClick={this.resendOtp}
                                                                      className="counter_Btn btn mt-1">
                                                                Resend
                                                            </button>
                                                            : <p>OTP will expire in {" "}
                                                                 {minutes + ' min '}:{' ' + seconds < 10 ? `0${seconds} sec` : ' ' + seconds + ' sec'}</p>
                                                        }
                                                        {
                                                            this.state.success ? this.state.message : null
                                                        }
                                                    </p>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>


    }
}

const mapStateToProps = (state) => {
    return {
        personalProfileData: state.ProfileReducer.personalProfileData
    };
};


export default connect(mapStateToProps)(ProfileDeactivateOtp);


