import React from 'react'
import { authService } from '../auth';

class SearchZeroDataSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
        }
    }

    render() {
        return (
            <div className="zdata zfeed">
                <div className="imgbox">
                    <img src="https://cdn.dscovr.com/images/zeroNodateNew1.webp"
                        onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/zeroNodateNew1.png" }}
                        width={520}
                        alt="" />
                </div>
                <div className="desc">
                    <p>No data matches your search criteria.</p>
                </div>
            </div>
        )
    }
}

export default SearchZeroDataSection;