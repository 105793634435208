import React, {Component} from "react";
import {authService} from "../auth";
import axios from "axios";
import {UserService} from "../UserData";
import {getCDNUrl} from "./CommonFunctions";

class Image extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated()
        };

    }

    getUserInfo = (data) => {
        if(data.projectId && data.userEvent === "PROJECTAPPLY_APPLIERNOTIFICATION"){
            window.location.href = "/project/feeds/" + data.slug;
        }else if(data.projectSlug && (data.userEvent === "PARTICIPANT_APPLICATION_ACCEPTED" || data.userEvent === "EDIT")){
            if(data.projectType != "JOB") {
                window.location.href = "/project/feeds/" + data.projectSlug;
            }
        }else if(data.userEvent === "DEACTIVATION_ALERT" && data.projectSlug){
            window.location.href = "/community/" + data.projectSlug;
        }else if(data.userEvent === "TRENDINGPOST" || data.userEvent === "FIRSTSIGNUP" || data.userEvent === "REPORTABUSE" || data.userEvent === "MAXTHRESHOLDREPORTEDABUSE" || data.userEvent === "REPORTEDABUSE"){
            return false;
        } else if (data.userEvent === 'PROFILE_DEACTIVATION_REJECTED'|| data.userEvent === "NOMINEE_IGNORED"|| data.userEvent === "NOMINATION_RQ_IGNORED"|| data.userEvent === "NOMINEE_ACCEPT"||
             data.userEvent === "NOMINATION_RQ_ACCEPT"|| data.userEvent === " NOMINATION_RQ_RECEIVED" || data.userEvent === "MASTERDATA_REVOKE_IGNORED" || data.user === "MASTERDATA_REVOKE_ACCEPTED") {
            return false;
        } else if (data.circleSlug != null) {
            window.location.href = "/community/" + data.circleSlug;
        } else {
            axios({
                method: 'get',
                url: process.env.REACT_APP_userServiceURL + `/user/userDetails/${data.userId}`,
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }).then(response => {
                if (response && response.data && response.data.message === 'Success!') {
                    UserService.setUserProfileData(response.data.body);
                    window.location.href = "/profile/" + response.data.body.customUrl;
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    };


    render() {
        return <a href="" onClick={e => {
            e.preventDefault();
            this.getUserInfo(this.props);
        }}
                  style={(this.props.userEvent === "TRENDINGPOST" || this.props.userEvent === "FIRSTSIGNUP" || this.props.userEvent === 'PROFILE_DEACTIVATION_REJECTED' ||
                      this.props.userEvent === "REPORTABUSE" || this.props.userEvent === "MAXTHRESHOLDREPORTEDABUSE" || this.props.userEvent === "REPORTEDABUSE") ? {cursor: 'default'} : {cursor: 'pointer'}}>
            <figure className='users'>
                {this.props.imageUrl ? <img style={(this.props.userEvent === "NOMINEE_IGNORED"|| this.props.userEvent === "NOMINATION_RQ_IGNORED"|| this.props.userEvent === "NOMINEE_ACCEPT"|| this.props.userEvent === "NOMINATION_RQ_ACCEPT") ? {objectFit : "contain"} : {objectFit : "cover"}}
                        src={getCDNUrl(this.props.imageUrl)} alt=""/> :
                    ''}</figure>
        </a>
    }
}


export default Image;

