import React, { PureComponent } from 'react';
import { getCDNUrl } from "../commons/CommonFunctions";
import { Redirect } from "react-router-dom";

class CompanySearchItem extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {}
    }

    redirect = () => {
        document.body.classList.remove('searchDropActive');
        this.setState({ redirectToProfile: true });
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    render() {

        return (
            <>
                {this.state.redirectToProfile && <Redirect push to={"/profile/" + this.props.item.customUrl} />}

                <li>
                    <a href="javascript:void(0)" onClick={this.redirect}>
                        <div className="srchpostmain">
                            <figure className="users">
                                <img src={this.props.item.profileImage ?
                                    getCDNUrl(this.getOptimizedImage(this.props.item)) : "https://cdn.dscovr.com/images/DefaultBusiness.webp"}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                    }} alt="" />
                            </figure>
                            <div>
                                <h5 className= "orgnzName">{this.props.item.companyName}</h5> {/*Botanist*/}
                            </div>
                        </div>
                    </a>
                </li>
            </>
        );
    }
}

export default CompanySearchItem;
