import React from 'react'
import $ from 'jquery';
import { authService } from "../auth";
import Header from "../Header";
import FooterDiv from "../FooterDiv";
import StaticHeader from "../StaticHeader";
import { Link, NavLink } from "react-router-dom";
import LeftPanel from '../LeftPanel';
import AuthModalComponent from "../LoginExtra/AuthModalComponent";
import SignUpModal from "../SignUpModal/signUpModal";
import LeftPanelUnauth from "../commons/LeftPanelUnauth";

class PostAsDetails extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            postType: this.props.location.pathname === '/post-help' ? 'Post' : this.props.location.pathname === '/share-help' ? 'Share' : ''
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        window.$('#header .iCross').hide();
        window.$("#header .iHamburger").on('click', function (event) {
            window.$('.leftpanel').addClass('navactive');
            window.$('.dgcontainer').addClass('navactive');
            window.$('.staticPage').addClass('navactive');
            window.$('#header .iHamburger').hide();
            window.$('#header .iCross').show();
        });
        $("body, html").on('click', function (event) {
            if (!$(event.target).closest('#header .iHamburger, .read-more-less-btn').length) {
                $('.leftpanel').removeClass('navactive');
                $('.dgcontainer').removeClass('navactive');
                window.$('.staticPage').removeClass('navactive');
                $('.dropdown-menu.show').removeClass('show');
                window.$('#header .iHamburger').show();
                window.$('#header .iCross').hide();
            }
        });
    }

    loginRequired = (slug) => {
        this.setState({ 'loginRequired': true, 'pathname': slug })
        window.$('#loginModal').modal('show');
    }
    handleLoginClose = () => {
        this.setState({ 'loginRequired': false })
        window.$('#loginModal').modal('hide');
    }

    signUpRequired = () => {
        this.setState({ 'signUpRequired': true })
        window.$('#signupModal').modal('show');
    }

    handleSignUpClose = () => {
        this.setState({ 'signUpRequired': false })
        window.$('#signupModal').modal('hide');
    }

    render() {
        return (
            <div className="staticPage">
                {!this.state.loginSuccess ? <StaticHeader /> : <Header />}
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <section className="globalinn">
                            <div className="pagetitle bkstatcpgttl text-center">
                                <h1>Do more with posts</h1>
                            </div>

                            <div className="globalPgCont doMoreRow">
                                <div className="doTopHead">
                                    <div className="doTopHeadinn">
                                        <i className="iSingleUser" />
                                        <div>
                                            <h2>{this.state.postType} as <strong>Profile</strong></h2>
                                            <p>{this.state.postType} as an individual from your profile.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="doDescWrap">
                                    <h4>Post in</h4>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iWN_Feeds_OL" /></div>
                                                <div className="desc">
                                                    <h4>Feeds</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>User Activity & Feeds</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iProjects_F" /></div>
                                                <div className="desc">
                                                    <h4>Projects</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>Project Feeds</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iCauses_F" /></div>
                                                <div className="desc">
                                                    <h4>Causes</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>Causes Feed</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iCircle_Default" /></div>
                                                <div className="desc">
                                                    <h4>Community</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>Community Activity</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="globalPgCont doMoreRow">
                                <div className="doTopHead">
                                    <div className="doTopHeadinn">
                                        <i className="iBusiness" />
                                        <div>
                                            <h2>{this.state.postType} as <strong>Organization</strong></h2>
                                            <p>{this.state.postType} as organization from your profile. Organization operator or admin will be able
                                                to use this feature.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="doDescWrap">
                                    <h4>Post in</h4>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iWN_Feeds_OL" /></div>
                                                <div className="desc">
                                                    <h4>Feeds</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>Organization Activity & Feeds</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iProjects_F" /></div>
                                                <div className="desc">
                                                    <h4>Projects</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>Project Feeds</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iCauses_F" /></div>
                                                <div className="desc">
                                                    <h4>Causes</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>Causes Feed</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iCircle_Default" /></div>
                                                <div className="desc">
                                                    <h4>Community</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>Community Activity</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="globalPgCont doMoreRow">
                                <div className="doTopHead">
                                    <div className="doTopHeadinn">
                                        <i className="iCircle_Ol" />
                                        <div>
                                            <h2>{this.state.postType} as <strong>Community</strong></h2>
                                            <p>{this.state.postType} as a community from your profile. Community super admin & admin will be able
                                                to use this feature.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="doDescWrap">
                                    <h4>Post in</h4>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iWN_Feeds_OL" /></div>
                                                <div className="desc">
                                                    <h4>Feeds</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>Community Activity</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iProjects_F" /></div>
                                                <div className="desc">
                                                    <h4>Projects</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>Project Feeds</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="doDescBlk">
                                                <div className="icoblk"><i className="iCauses_F" /></div>
                                                <div className="desc">
                                                    <h4>Causes</h4>
                                                    <div className="visib">Visible In</div>
                                                    <p>Causes Feed</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>

                {!this.state.loginSuccess ?
                    <LeftPanelUnauth signUpRequired={this.signUpRequired} /> :
                    <LeftPanel />}
                <FooterDiv />
                {this.state.loginRequired === true ? <AuthModalComponent handleLoginClose={this.handleLoginClose} pathname={this.state.pathname} /> : null}
                {this.state.signUpRequired === true ? <SignUpModal handleSignUpClose={this.handleSignUpClose} history={this.props.history} /> : null}
            </div>
        );

    }

}

export default PostAsDetails
