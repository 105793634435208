import React, { Component } from 'react';
import './login.min.css';
import * as _ from "lodash";
import axios from "axios";
import { authService } from "../auth";
import CountryCode from "../country_code.json";
import { userAgentPostData } from '../commons/CommonFunctions';

class MobileLogin extends Component {
    constructor(props) {
        super(props);
        let validation = {
            username: {},
            password: {},
            phone: {},
            email: {}
        }
        this.state = {
            validation,
            error: null,
            isLoaded: true,
            loginSuccess: authService.isAuthenticated(),
            loggedinUser: null,
            isMigrating: false,
            email: '',
            phone: '',
            errors: {},
            countryCodeInfo: [],
            countryCode: [],
            countryCodeList: [].concat(CountryCode)
        }
    }

    redirect = (data) => {
        if (data.mobileVerificationRequired) {
            this.props.history.push({
                pathname: '/otp',
                state: {
                    signUpResponse: data
                }
            })
        }
        if (data.emailVerificationRequired) {
            this.props.history.push({
                pathname: '/signup-verify',
                state: {
                    signUpResponse: data
                }
            })
        } else if (data.addressRequired) {
            this.props.history.push({
                pathname: '/address',
                state: {
                    signUpResponse: data
                }
            })
        } else if (data.personalProfileRequired) {
            this.props.history.push({
                pathname: '/personal'
            })
        } else if (data.operatorInfoRequired) {
            this.props.history.push({
                pathname: '/operatorinfo'
            })
        } else if (data.companyProfileRequired) {
            this.props.history.push({
                pathname: '/companyprofile'
            })
        }
    }

    handleUserNameChange = (e) => {
        this.state.validation.username = {};
        this.setState({ username: e.target.value });
    };

    handlePasswordChange = (e) => {
        this.state.validation.password = {};
        this.setState({ password: e.target.value });
    };

    checkValidation = () => {
        this.state.validation.username = {};
        this.state.validation.password = {};
        let validationRequired = false;
        if (_.isUndefined(this.state.username) ||
            _.isEmpty((this.state.username || "").toString()) ||
            _.isNull(this.state.username)) {
            this.setState({
                error: { message: 'Please enter an email address or phone number' }
            });
            this.state.validation.username = {
                error: true,
                style: { borderColor: "#b34c4c" }
            }
            validationRequired = true;

        } else if (_.isUndefined(this.state.password) ||
            _.isEmpty((this.state.password || "").toString()) ||
            _.isNull(this.state.password)) {
            validationRequired = true;
            this.setState({
                error: { message: 'Please enter password' }
            });
            this.state.validation.password = {
                error: true,
                style: { borderColor: "#b34c4c" }
            }
        }
        return validationRequired;
    };

    componentDidMount() {
        document.title = 'Login to stay updated | WeNaturalists';
    }

    connect = () => {
        const loginActivity = {
            "userId": this.state.loggedinUser,
            "loginStatus": "online"
        };

        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + "/messaging/message_service/api/updateLoginActivity",
            data: loginActivity,
            withCredentials: true
        }).then(response => {
            this.setState({ isLoaded: true })
            //Dont remove this
            window.location.reload();
        }).catch((error) => {
            this.setState({ isLoaded: true })
            //Dont remove this
            window.location.reload();
        });
    }

    loggedinSuccess = () => {
        this.connect();
    };

    handleSubmit = (event) => {
        this.setState({ isLoaded: false })

        localStorage.removeItem('appDownloaded');
        event.preventDefault();

        if (!this.checkValidation()) {
            let postBody = {
                "username": this.state.username.trim(),
                "password": this.state.password.trim(),
                "userAgent": userAgentPostData()
            };

            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/user/login',
                headers: { 'Content-Type': 'application/json' },
                data: postBody,
                withCredentials: true
            }).then((response) => {

                let res = response.data;

                if (res.message === 'Success!') {
                    let authResult = {};
                    if (res.body.indianResidence) {
                        authResult.phone = res.body.mobile;
                    } else {
                        authResult.email = res.body.email;
                    }
                    authResult.isIndianResident = res.body.indianResidence;
                    authResult.firstName = res.body.firstName;
                    authResult.lastName = res.body.lastName;
                    authResult.companyName = res.body.companyName;
                    authResult.customUrl = res.body.customUrl;
                    authResult.type = res.body.type;
                    authResult.userId = res.body.userId;
                    authResult.profileImage = res.body.profileImage;
                    authResult.userDataPending = res.body.userDataPending;

                    authResult.skills = res.body.skills;
                    authResult.interests = res.body.interests;
                    authResult.specialities = res.body.specialities;
                    authResult.persona = res.body.persona;
                    authResult.cookiesAccepted = res.body.cookiesAccepted;
                    authResult.resizedProfileImages = res.body.resizedProfileImages;

                    authService.setSession(authResult, response);
                    authService.setPromotionData(false);
                    authService.setGeoLocation();
                    if (res.body.userDataPending) {
                        this.redirect(res.body);
                    } else {
                        if (window.ReactNativeWebView && authResult.userId) {
                            window.ReactNativeWebView.postMessage(JSON.stringify({
                                type: 'login',
                                userId: authResult.userId,
                                userData: res.body,
                                token: response.headers['authorization']
                            }));
                        }
                    }
                    this.setState({ "loggedinUser": authResult.userId });
                    
                    this.loggedinSuccess();
                } else {
                    authService.clearData();
                }
            }).catch((err) => {
                if (err && err.response) {
                    this.setState({
                        isLoaded: true,
                        error: { message: err.response.data.message, err: err.response }
                    });


                    authService.clearData();
                }
                console.log(err);
            });
        } else {
            this.setState({ isLoaded: true })
        }
        setTimeout(() => {
            if (this.state.error && this.state.error.message !== 'Sorry! This account is deactivated. Please write at support@wenaturalists.com if you wish to reactivate this account.') {
                this.setState({
                    error: {}
                });
            }
        }, 5000);


    };

    render() {
        const { error, loginSuccess } = this.state;
        return (
            <section className="loginMobwrap text-center">
                <div className="loginMobbox">
                    <div className="logoMob"><img src="https://cdn.dscovr.com/images/welogoNew.png" width="90" alt="" />
                    </div>
                    <h1>Welcome Back!</h1>
                    <p className="tags">Sign in to keep updated with the community</p>
                    <div className="loginMobform">
                        <form action="">
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="Email or Phone" style={this.state.validation && this.state.validation.username && this.state.validation.username.style}
                                    value={this.state.username || ''} onChange={this.handleUserNameChange} />
                            </div>
                            <div className="form-group passfield" style={{ marginBottom: '.5rem' }}>
                                <input type="password" className="form-control" placeholder="Password" value={this.state.password || ''}
                                    style={this.state.validation && this.state.validation.password && this.state.validation.password.style}
                                    onChange={this.handlePasswordChange} maxLength="15" />
                                <span className="passtoggle" style={{
                                        display: 'block' }}>Show</span>
                            </div>
                            {error &&
                                <>{error.message === 'Sorry! This account is deactivated. Please write at support@wenaturalists.com if you wish to reactivate this account.' ?
                                    <span className="login-feedback-error mobSignupError" style={{
                                        display: error.message ? 'block' : 'none',
                                        background: error.message ? '#D0E8C8' : 'none'
                                    }}> Sorry! This account is deactivated. <br /><span style={{ color: "#5e5e5e" }}>Please write at <a
                                        href="mailto:support@wenaturalists.com" target="_blank" style={{
                                            color: "#768203",
                                            textDecoration: "underline"
                                        }}>support@wenaturalists.com</a> if you wish to reactivate this account.</span> </span> :
                                    <span className="login-feedback-error mobSignupError" style={{
                                        display: error.message ? 'block' : 'none',
                                        background: error.message ? '#D0E8C8' : 'none'
                                    }}> {error.message} </span>}</>}
                            <button type="button" className="mobloginBtn btn" disabled={this.state.isLoaded === false} onClick={this.handleSubmit}>Log in
                            </button>
                        </form>

                        <div onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'forgetPassword' }))}><a href="#" className="mobForgot"><i
                            className="iPrivacyLock"></i> Forgot Password?</a></div>
                        <div className='mobSignUpBlk'>Not a part of WeNaturalists? <a href="javascript:void(0)" onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'signUp' }))}>JOIN US</a></div>
                        <div onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'customerSupport' }))}><a href="#" className="supportMob"><i className="iHelp"></i> Customer Support</a></div>

                    </div>
                </div>
            </section>
        );
    }
}

export default MobileLogin;