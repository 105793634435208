import React, { Component } from "react";
import { authService } from "../../auth";
import Select from 'react-select';
import axios from "axios";
import "../../Donation/donation-subscription.min.css";
import { getCDNUrl } from "../../commons/CommonFunctions";

class ViewBankDetailsPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            entityList: [],
            availableBankList: [],
            entitySearchData: "",
            activeTab: "",
            defaultProjectBank: {"label": this.props.entityDetailsList?.institutionName, "value": this.props.entityDetailsList?.id},
            defaultCircleBank: {"label": this.props.entityDetailsList?.institutionName, "value": this.props.entityDetailsList?.id},
            defaultStudioBank: {"label": this.props.entityDetailsList?.institutionName, "value": this.props.entityDetailsList?.id},
            editBankDataFlag: false,
            bankEntityList: []
        }
    }

    onChangeBankDetails = (entity, bankdata) => {
        let postData = {
            userId: this.props.userId,
            bankId: bankdata.value
        }
        if(entity.entityType === "STUDIO") {
            postData.postId = entity.id;
        } else {
            postData.entityId= entity.entityId;
            postData.entityType= entity.entityType;
            postData.id= entity.id;
        }
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_userServiceURL}/backend/${entity.entityType === "STUDIO" ? 'paidContentPost/update/bank/detail' : 'donation/update/bank'}`,
            headers: { "Content-Type": "application/json" },
            data: postData,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    editBankDataFlag: true
                })
                this.displayBankSection(entity.entityId, false, bankdata);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    onChangeAllBank = (bankData) => {
        let postData = {
            userId: this.props.userId,
            bankId: bankData.value,
            entityType: this.state.activeTab,
            existingBankId: this.state.activeTab === "PROJECT" ? this.state.defaultProjectBank?.value : this.state.activeTab === "CIRCLE" ? this.state.defaultCircleBank?.value : this.state.defaultStudioBank?.value
        }
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_userServiceURL}/backend/userBankDetail/update/bank/entity`,
            headers: { "Content-Type": "application/json" },
            data: postData,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                if(this.state.activeTab === "PROJECT") {
                    this.setState({
                        defaultProjectBank: bankData
                    })
                } else if (this.state.activeTab === "CIRCLE") {
                    this.setState({
                        defaultCircleBank: bankData
                    })
                } else {
                    this.setState({
                        defaultStudioBank: bankData
                    })
                }
                this.setState({
                    editBankDataFlag: true
                })
                this.changeAllBankSection(false, bankData);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    redirectToEntityDetailsPage = (slug) => {
        if(this.state.activeTab === "PROJECT") {
            window.location.href = `/project/feeds/${slug}`;
        } else if(this.state.activeTab === "CIRCLE") {
            window.location.href = `/community/${slug}`;
        } else {
            window.location.href = `/studio/${slug}`;
        }
    }

    getEntitySection = (entity) => {
        return (
            <div className="sProjectListUsersBx" onClick={(e) => this.redirectToEntityDetailsPage(entity.slug)} style={{cursor: "pointer"}}>
                <figure className="users group">
                    <img src={entity.imageUrl ? getCDNUrl(entity.imageUrl) : entity.entityType === "PROJECT" ? "https://cdn.dscovr.com/images/project-default.webp" : entity.entityType === "CIRCLE" ? "https://cdn.dscovr.com/images/circleDefault.webp" : "https://cdn.dscovr.com/images/studio-default.webp"}
                        onError={(e) => { e.target.onerror = null; e.target.src = entity.entityType === "PROJECT" ? "https://cdn.dscovr.com/images/project-default.jpg" : entity.entityType === "CIRCLE" ? "https://cdn.dscovr.com/images/circleDefault.png" : "https://cdn.dscovr.com/images/studio-default.png" }} alt="" />
                </figure>
                <div>
                    <h5>{entity.title}</h5>
                </div>
            </div>
        )
    }

    handelSearchChange = (event) => {
        this.setState({
            entitySearchData: event.target.value.replace(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?-_]/g, '')
        }, () => {
            let entityList = [];
            let filterData = this.state.entityList && this.state.entityList.filter((item) => item.title && item.title.toLowerCase().includes(this.state.entitySearchData && this.state.entitySearchData.toLowerCase()));
            this.state.entityList && this.state.entityList.map((item, key) => {
                let index = filterData && filterData.findIndex(a => a.title === item.title);
                if (index > -1) {
                    item.display = true;
                } else {
                    item.display = false;
                }
                entityList.push(item);
            })
            this.setState({
                entityList: entityList
            })
        })
    }

    getActiveTabData = (type) => {
        this.getEntityDataList(type);
        this.setState({
            activeTab: type
        })
    }

    getEntityDataList = (type) => {
        let data = [];
        let entityList = (type === "PROJECT" ? this.state.bankEntityList.projectList : type === "CIRCLE" ? this.state.bankEntityList.circleList : this.state.bankEntityList.studioList);
        let bankDataDetails = type === "PROJECT" ? this.state.defaultProjectBank : type === "CIRCLE" ? this.state.defaultCircleBank : this.state.defaultStudioBank;
        entityList && entityList.map((item) => {
            item.bankEdit = false;
            item.bankDetail = (!item.selectedBankData || (item.selectedBankData?.value === bankDataDetails?.value)) ? { "institutionName": bankDataDetails?.label, "id": bankDataDetails?.value } : item.bankDetail;
            item.selectedBankData = (!item.selectedBankData || (item.selectedBankData?.value === bankDataDetails?.value)) ? bankDataDetails : item.selectedBankData;
            item.display = true;
            data.push(item);
        })
        this.setState({
            entityList: data
        })
    }

    displayBankSection = (id, flag, bankData) => {
        let entityDataList = this.state.entityList;
        let index = entityDataList && entityDataList.findIndex(data => data.entityId === id);
        if (index > -1) {
            entityDataList[index].bankEdit = flag;
            if (!flag && bankData) {
                entityDataList[index].selectedBankData = bankData;
                entityDataList[index].bankDetail = { "institutionName": bankData.label, "id": bankData.value };
            }
        }
        this.setState({
            entityList: entityDataList
        })
    }

    changeAllBankSection = (flag, bankData) => {
        this.setState({
            changeAllBankFlag: flag
        })
        if(!flag && bankData) {
            let entityList = [];
            this.state.entityList && this.state.entityList.map((item) => {
                item.bankDetail = { "institutionName": bankData?.label, "id": bankData?.value };
                item.selectedBankData = bankData;
                entityList.push(item);
            })
            this.setState({
                entityList: entityList
            })
        }
    }

    componentDidMount() {
        this.setState({
            bankEntityList: this.props.entityDetailsList
        }, () => {
            this.getEntityDataList(this.props.entityDetailsList?.projectList?.length > 0 ? "PROJECT" : this.props.entityDetailsList?.circleList?.length > 0 ? "CIRCLE" : "STUDIO");
        })
        let bankData = [{ "label": this.props.entityDetailsList.institutionName, "value": this.props.entityDetailsList.id }];
        this.props.entityDetailsList && this.props.entityDetailsList.availableList && this.props.entityDetailsList.availableList.map((item) => {
            bankData.push({ "label": item.nickName ? item.nickName : item.institutionName, "value": item.id })
        })
        this.setState({
            availableBankList: bankData,
            activeTab: this.props.entityDetailsList?.projectList?.length > 0 ? "PROJECT" : this.props.entityDetailsList?.circleList?.length > 0 ? "CIRCLE" : "STUDIO"
        })
    }

    render() {
        const { entityList, availableBankList, entitySearchData, activeTab, changeAllBankFlag, editBankDataFlag, defaultProjectBank, defaultCircleBank, defaultStudioBank } = this.state;
        const { entityDetailsList } = this.props;
        return (
            <div id="viewBankDetailsPopUp" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{ margin: '0' }}>
                        <div className="modal-body" style={{ padding: '0 15px' }}>
                            <div className="sProjectListPop projDonatBankDtlsWrap">
                                <button type="button" className="close topCrossModal" aria-label="Close" onClick={() => this.props.onCloseViewBankListDetails(editBankDataFlag)}>
                                    <i className="iCross" />
                                </button>
                                <div className="projtopbar">
                                    <div className="projtopbarleft">
                                        <div className="mcustHorizMenu">
                                            <div className="mcustHorizinn">
                                                <ul id="tabs" className="nav">
                                                    {entityDetailsList?.projectList?.length > 0 &&
                                                        <li className="nav-item">
                                                            <a href="javascript:void(0)" className={"nav-link" + (activeTab === "PROJECT" ? " active" : "")} onClick={(e) => this.getActiveTabData("PROJECT")}>
                                                                Project
                                                            </a>
                                                        </li>
                                                    }
                                                    {entityDetailsList?.circleList?.length > 0 &&
                                                        <li className="nav-item">
                                                            <a href="javascript:void(0)" className={"nav-link" + (activeTab === "CIRCLE" ? " active" : "")} onClick={(e) => this.getActiveTabData("CIRCLE")}>
                                                            Community
                                                            </a>
                                                        </li>
                                                    }
                                                    {entityDetailsList?.studioList?.length > 0 &&
                                                        <li className="nav-item">
                                                            <a href="javascript:void(0)" className={"nav-link" + (activeTab === "STUDIO" ? " active" : "")} onClick={(e) => this.getActiveTabData("STUDIO")}>
                                                                Studio
                                                            </a>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={!changeAllBankFlag ? 'selectedBank' : 'selectedBank openDrop'}>
                                        {availableBankList && availableBankList.length > 1 && 
                                            (!changeAllBankFlag ?
                                                <h4>Change All Bank <a href="javascript:void(0)" className="edtbtn btn" onClick={(e) => this.changeAllBankSection(true)}><i className="iEditBox" /></a></h4> :
                                                <div className="d-flex align-items-center">
                                                    <Select
                                                        placeholder={"Select Bank"}
                                                        className="wnforSelect"
                                                        name={"userFilterOption"}
                                                        options={availableBankList}
                                                        value={activeTab === "PROJECT" ? defaultProjectBank : activeTab === "CIRCLE" ? defaultCircleBank : defaultStudioBank}
                                                        onChange={(e) => this.onChangeAllBank(e)}
                                                        styles={{
                                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                                const color = '#cfe7c7';
                                                                return {
                                                                    ...styles,
                                                                    backgroundColor: isDisabled
                                                                        ? null
                                                                        : isSelected
                                                                            ? '#cfe7c7'
                                                                            : isFocused
                                                                                ? '#cfe7c7'
                                                                                : null,
                                                                    color: isDisabled
                                                                        ? '#ccc'
                                                                        : isSelected
                                                                            ? color > 2
                                                                                ? '#00374d'
                                                                                : '#00374d'
                                                                            : data.color,
                                                                    cursor: isDisabled ? 'not-allowed' : 'default',

                                                                    ':active': {
                                                                        ...styles[':active'],
                                                                        backgroundColor: !isDisabled && (isSelected ? '#cfe7c7' : '#cfe7c7'),
                                                                    },
                                                                };
                                                            },
                                                        }}
                                                    />
                                                    <a href="javascript:void(0)" onClick={(e) => this.changeAllBankSection(false)} className="okBtn"><i className="iCross" /></a>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                {entityList && entityList.length > 5 &&
                                    <div className="searchByCard" style={{marginTop: "15px"}}>
                                        <form className="searchbox" onSubmit={(e) => {e.preventDefault();}}>
                                            <input type="text" className="search_query form-control" id="search" name="search" value={entitySearchData} placeholder="Search" onChange={this.handelSearchChange} autoComplete="off" />
                                        </form>
                                    </div>
                                }
                                <div>
                                    {entityList && entityList.length > 0 && entityList.map((entity) => {
                                        return (
                                            <div className={"sProjectListRow" + (entity.display ? "" : " not-show")}>
                                                <div className='row align-items-center'>
                                                    <div className="col-sm-8">
                                                        {this.getEntitySection(entity)}
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className={!entity.bankEdit ? 'selectedBank' : 'selectedBank openDrop'}>
                                                            {availableBankList && availableBankList.length > 1 &&
                                                                (!entity.bankEdit ? 
                                                                    <h4><span>{entity.bankDetail.institutionName}</span> <a href="javascript:void(0)" className="edtbtn btn" onClick={(e) => this.displayBankSection(entity.entityId, true)} ><i className="iEditBox" /></a></h4> :
                                                                    <div className="d-flex align-items-center">
                                                                        <Select
                                                                            placeholder={"Select Bank"}
                                                                            className="wnforSelect"
                                                                            name={"userFilterOption"}
                                                                            options={availableBankList}
                                                                            value={entity.selectedBankData}
                                                                            onChange={(e) => this.onChangeBankDetails(entity, e)}
                                                                            styles={{
                                                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                                                    const color = '#cfe7c7';
                                                                                    return {
                                                                                        ...styles,
                                                                                        backgroundColor: isDisabled
                                                                                            ? null
                                                                                            : isSelected
                                                                                                ? '#cfe7c7'
                                                                                                : isFocused
                                                                                                    ? '#cfe7c7'
                                                                                                    : null,
                                                                                        color: isDisabled
                                                                                            ? '#ccc'
                                                                                            : isSelected
                                                                                                ? color > 2
                                                                                                    ? '#00374d'
                                                                                                    : '#00374d'
                                                                                                : data.color,
                                                                                        cursor: isDisabled ? 'not-allowed' : 'default',

                                                                                        ':active': {
                                                                                            ...styles[':active'],
                                                                                            backgroundColor: !isDisabled && (isSelected ? '#cfe7c7' : '#cfe7c7'),
                                                                                        },
                                                                                    };
                                                                                },
                                                                            }}
                                                                        />
                                                                        <a href="javascript:void(0)" onClick={(e) => this.displayBankSection(entity.entityId, false)} className="okBtn"><i className="iCross" /></a>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='sProjectListPopBott'>
                                    <button type="button" class="btn btn-primary" onClick={(e) => this.props.onCloseViewBankListDetails(editBankDataFlag)}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewBankDetailsPopUp;