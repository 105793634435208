import React from "react";
import {LazyLoadImage} from 'react-lazy-load-image-component';

const NotFound = () => (
    <div className="err404contbx">
        <LazyLoadImage
            effect="blur"
            src={"https://cdn.dscovr.com/images/error-img.webp"}
            onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/error-img.png"}}
            className="img-fluid" alt="Error image"/>
        <span className="divider_1"/>
        <h1>We're Sorry!</h1>
        <h2>The page you requested could not be found.</h2>
        <p>
            WeNaturalists was born from the need to create a space for people and communities associated with nature, to
            unite, learn and grow. Together.
        </p>
        <span className="divider_2"/>
    </div>
);

export default React.memo(NotFound);
