import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";

class EventMessageSection extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const eventLinkTarget = {
            pathname: "/profile/" + this.props.item.eventCustomUrl,
            key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
            state: {
              applied: true,
            },
        };
        return (
            <>
                <div className="hpostTopExt">
                    {this.props.item.event === "CIRCLE" ? 
                        <>Post is shared in <Link to={`/community/${(this.props.item.circleMetaData && this.props.item.circleMetaData.circleSlug) || (this.props.item.params && this.props.item.params.circleSlug)}`}>{(this.props.item.circleMetaData && this.props.item.circleMetaData.circleTitle) || (this.props.item.params && this.props.item.params.circleTitle)} </Link>community.</>
                        : 
                        <>
                            <NavLink to={eventLinkTarget}>
                            {   this.props.item.eventUsername}
                            </NavLink>{" "} {this.props.item.eventMessage}
                        </>
                    }
                </div>
            </>
        )
    }
}

export default (EventMessageSection);