import React, { PureComponent } from "react";
import { authService } from "../auth";
import { requestPostCreationFulfilled } from "../reducer/post";
import { connect } from "react-redux";
import httpService from "../AxiosInterceptors";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import EventMessageSection from "../commons/EventMessageSection";
import PresentationPostView from "./PresentationPostView";
import CommonPostHeaderSection from "./CommonPostHeaderSection";
import CommonPostMenuSection from "./CommonPostMenuSection";
import CommonPostBottomSection from "./CommonPostBottomSection";

httpService.setupInterceptors();
class PresentationPostItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userData: authService.getUserDetailsFromLocalStorage(),
      description: props.item.description,
      editedText: props.item.description,
      hashTags: [],
      errors: {},
      error: "",
      tags: [],
      documentTitle: props.item.attachmentIds && props.item.attachmentIds.length > 0 && props.item.attachmentIds[0].title,
      attachmentDetails: props.item && props.item.attachmentIds && props.item.attachmentIds[0] ? props.item.attachmentIds[0] : {},
      smileDisplay: false,
      og_data: {}
    };
  }

  componentDidMount() {
    let tags = [];
    this.props.item &&
      this.props.item.hashTags &&
      this.props.item.hashTags.map((data, index) => {
        tags.push(data);
        this.setState({ tags: tags });
      });
  }

  onSuccessUpdatePost = (data) => {
    this.setState({
      description: data.description,
      tags: data.tags,
      documentTitle: data.documentTitle
    })
    this.forceUpdate();
  }

  render() {
    return (
      <div className={`hpostblock` + (this.props.item.nominated && this.props.postSection === "FEED" ? ' pNominate' : '')}>
        <div className="hpostblockinn">
          {this.props.item && this.props.item.eventMessage && (
            <EventMessageSection item={this.props.item} />
          )}
          {this.props.item.pinned && <span className='pinnedPost'><i className="iPin" style={{ fontSize: '14px' }} /></span>}

          <CommonPostHeaderSection item={this.props.item} callback_fn={() => this.props.callback_fn(this.props.item.userId)} />

          <CommonPostMenuSection key={this.props.item.id} item={this.props.item} removePostListItem={this.props.removePostListItem} description={this.state.description} tags={this.state.tags} documentTitle={this.state.documentTitle} onSuccessUpdatePost={this.onSuccessUpdatePost} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} postType={'PPT'} postSection={this.props.postSection} currentTab={this.props.currentTab} />

          <div className="hpostcontent">
            <div className="hpostconttop">
              {((this.state.description && this.state.description !== "") || this.state.tags || this.state.documentTitle) && (
                <>
                  <ReadMoreReadLess
                    id={this.props.item.id}
                    description={this.state.description}
                    hashTags={this.state.tags}
                    item={this.props.item}
                  />
                  {this.state.attachmentDetails && this.state.documentTitle &&
                    <PresentationPostView attachments={this.state.attachmentDetails} documentTitle={this.state.documentTitle} entityId={this.props.item?.id} postSection={"FEEDS"} />
                  }
                </>
              )}

              {this.props.item.postLocation && (
                <span className="address">
                  <i className="iLocation" />
                  {this.props.item.postLocation}
                </span>
              )}
            </div>

            <CommonPostBottomSection key={this.props.item.id} item={this.props.item} enablePostSharing={this.props.enablePostSharing} postSection={this.props.postSection} />

          </div>

        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestPostCreationFulfilled: (value) =>
      dispatch(requestPostCreationFulfilled(value)),
  };
};

export default connect(null, mapDispatchToProps)(PresentationPostItem);