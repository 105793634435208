import React, { Component } from 'react';
import { authService } from '../auth';
import Header from '../Header';
import LeftPanelSettings from '../settings/LeftPanelSettings';
import DonationStatsDataItem from '../Donation/DonationStatsDataItem';
import uuid from 'react-uuid';
import OrganizationAdmin from '../commons/OrganizationAdmin';
import axios from 'axios';
import CleverTapUtils from '../commons/CleverTapUtils';
import Breadcumbs from '../Breadcumbs';

class DonationIndividual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            activeTab: "PROJECT",
            managePermission: false,
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
        this.donationStats = uuid();
    }

    getActiveDonationTabData = (type) => {
        this.setState({
            activeTab: type
        })
        this.donationStats = uuid();
    }

    componentDidMount() {
        document.title = "Donations | WeNaturalists";
        CleverTapUtils.eventClick({}, "Display Fund Raise Stats");
        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
    }

    render() {
        const { activeTab, userId, managePermission } = this.state;
        return (
            <div className="wrapper projectpage settingspage">
                <Header />
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <LeftPanelSettings managePermission={managePermission} userId={userId} />

                            <div className="col-md-9">                                
                                <div className="projectinn">
                                    <Breadcumbs crumbs={[{ "name": "Dashboard Settings", "path": "/dashboard-settings" }, { "name": "Fund Raise Stats", "path": "/fund-raise", handleTabFunc: () => this.getActiveDonationTabData("PROJECT") }, { "name": `${activeTab === "PROJECT" ? "Projects" : "Communities"}`, "path": null } ]} />
                                    <div className="projtopbar">
                                        <div className="projtopbarleft">
                                            <div className="mcustHorizMenu">
                                                <div className="mcustHorizinn">
                                                    <ul id="tabs" className="nav">
                                                        <li className="nav-item">
                                                            <a href="javascript:void(0)" className={"nav-link" + (activeTab === "PROJECT" ? " active" : "")} onClick={(e) => this.getActiveDonationTabData("PROJECT")}>
                                                                Project
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a href="javascript:void(0)" className={"nav-link" + (activeTab !== "PROJECT" ? " active" : "")} onClick={(e) => this.getActiveDonationTabData("CIRCLE")}>
                                                            Community
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {userId && <OrganizationAdmin userId={userId} />}

                                    <div className='analyticsPg'>
                                        <div class="pagetitle">
                                            <h1>{activeTab === "PROJECT" ? "FUND RAISE STATS - PROJECTS" : "FUNDING STATS - COMMUNITIES"}</h1>
                                        </div>
                                        <div className='chartBox donationIndividualWrap' style={{padding: '0', background: 'transparent', boxShadow: 'none'}}>
                                            <div className='donationIndivInfoWrap'>
                                                <DonationStatsDataItem entityType={activeTab} key={this.donationStats} userId={userId} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        );
    }
}

export default (DonationIndividual);