import React, {PureComponent} from 'react';
import Comment from "../commons/Comment";
import axios from "axios";
import AddCommentBox from "../commons/AddCommentBox";
import httpService from '../AxiosInterceptors';
import {authService} from "../auth";
import cloneDeep from "lodash/cloneDeep";
import {v4 as uuidv4} from 'uuid';
import { NavLink } from 'react-router-dom';

httpService.setupInterceptors();

class ExploreCommentSection extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            comments: [],
            commentBtnActive: false,
            commentCount: (props.pageType === 'curatedBlog' ? props.item.commentCount : props.item.commentCount),
            page:0,
            size:3
        }
    }

    componentDidMount() {
        window.$('.collapser').click(function () {
            window.$(this).next().collapse('toggle');
        });

    }

    commentsCollapse = () => {
        if (!this.state.commentBtnActive) {
            this.setState({
                page: 0
            },()=>{
                this.getAllComments(this.state.page, this.state.size,false);
            })
        }
        this.setState({'commentBtnActive': !this.state.commentBtnActive});
    }

    fetchMoreCommentData = () => {
        this.setState(
            { isResultLoading: true, page: this.state.page + 1 },
            () => {
                this.getAllComments(this.state.page, this.state.size, true);
            }
        );
    };

    getAllComments = (page,size,concatWithPrevious) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/comment/getCommentsByActivityId/'
                + this.props.item.id + '?userId=' + this.state.userData.userId + "&page=" + page + "&size=" + size,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                let pagedData = response.data.body;
                if(concatWithPrevious){
                    if (this.state.comments.content && this.state.comments.content.length > 0) {
                        let comments = cloneDeep(this.state.comments.content);
                        pagedData.content = comments.concat(pagedData.content);
                    }
                }
                this.setState({
                    'comments': pagedData,
                    'commentCount': response.data.body.content.length > 0 ? response.data.body.content[0].commentsCount : 0,
                    'pagination': response.data.body.last
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    redirect(id) {
        window.location = '/post/' + id;
    };

    redirectToArticleDetail(id) {
        var url = '/explore-detail/article/' + id;
        if (url !== undefined && url !== null) {
            window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
        }
    }

    redirectToProfile = (customUrl) => {
        window.location.href = "/profile/" + customUrl;
    }

    refresh_comment = () =>{
        this.setState({
            page:0,
            size:3
        },()=>{
            this.getAllComments(this.state.page, this.state.size,false)
        })
    }

    render() {
        const postTarget = {
            pathname: `/post/${this.props.item.id}`,
            key: uuidv4(),
            state: {
                applied: true
            }
        };
        return (
            <div className="expCommnt">
                <a href="javascript:void(0)" onClick={this.commentsCollapse}
                   className="comments collapser"
                   data-toggle="collapse"><span className="art_btn_bx">{this.state.commentCount}&nbsp;
                    {this.state.commentCount && this.state.commentCount > 1 ? <span
                        className="art_btn_txt">Comments</span>: <span
                    className="art_btn_txt">Comment</span>}</span></a>
                <div className="fadeIn animated commentglobal collapse">
                    <AddCommentBox item={this.props.item} getAllComments={this.getAllComments} userEntityCount={this.props.userEntityCount} pageType={"EXPLORE"} />
                    {
                        this.state.comments && this.state.comments.content  && this.state.comments.content.length > 0 &&
                        <div className="forumcommtvlistwrap scrollbar-primary">
                            {
                                this.state.comments.content.map((comment) => {
                                    return <Comment item={comment} key={comment.id} callback={this.getAllComments} refresh={this.refresh_comment}/>
                                })
                            }
                        </div>
                    }
                    {!this.state.pagination &&
                        <div className='text-center' style={{paddingBottom:'8px', paddingTop:'8px'}}>
                            {this.props.pageType !== "curatedBlog" ?
                                <NavLink to={postTarget} className='btn cLoadmore'>View All</NavLink> :
                                <a href="javascript:void(0)"  onClick={(e)=>this.props.handleDetailPage(e, `/explore-detail/blog/${this.props.item.slug}`, this.props.item.id)} className='btn cLoadmore'>Load More</a>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ExploreCommentSection;
