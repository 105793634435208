import React from 'react';

import './styles/imagePreview.min.css';

class ImagePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rotationDegree: 0,
            imageUrl: this.props.dataUri
        }
    }

    rotateWebViewImage = () => {
        let rotationDegree = this.state.rotationDegree + 90;
        rotationDegree = rotationDegree >= 360 ? -360 : rotationDegree;
        this.setState({
            rotationDegree: rotationDegree
        })
        this.props.rotateWebViewImage(rotationDegree);
    };

    render() {
        const { rotationDegree, imageUrl } = this.state;
        return (
            <div className={'demo-image-preview ' + (this.props.isFullscreen ? 'demo-image-preview-fullscreen' : '')}>
                <a href="javascript:void(0)" className='closeimg' id="image-preview-close" onClick={e => {
                    e.preventDefault();
                    this.props.onCancelImage();
                }}>
                    <i className="iCross" />
                </a>
                <img src={imageUrl} style={{ transform: `rotate(${rotationDegree}deg)` }} alt="" crossorigin="anonymous" />
                <button onClick={(e) => { e.preventDefault(); this.rotateWebViewImage() }} type="button" className="rotateImg"><i className="irotate" /></button>
            </div>
        );
    }
}

export default ImagePreview
