import React, {Component} from 'react';
import axios from "axios";
import {authService} from "../../../auth";
import * as _ from "lodash";
import date from "date-and-time";
import {HashLink as Link} from 'react-router-hash-link';
import mappingUtils from "../commons/utils/mappingUtils";
import {convertUnicode} from "../../../commons/CommonFunctions";
import {Redirect} from "react-router-dom";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import CleverTapUtils from '../../../commons/CleverTapUtils';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

class RecommendedProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            list: [],
            message: false
        }
    }

    redirect = (customUrl) => {
        this.setState({redirectToProfile: customUrl});
    }

    nFormatter = (num, digits) => {
        if (!num) {
            return num
        }
        var si = [
            {value: 1, symbol: ""},
            {value: 1E3, symbol: "k"},
            {value: 1E6, symbol: "M"},
            {value: 1E9, symbol: "G"},
            {value: 1E12, symbol: "T"},
            {value: 1E15, symbol: "P"},
            {value: 1E18, symbol: "E"}
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }


    opportunityBlock = ({

                            location,
                            title,
                            creatorName,
                            shortBrief,
                            fees,
                            currency,
                            packageType,
                            isDisclosed,
                            noOfDaysPosted,
                            projectDetails,
                            projectId,
                            customUrl,
                            params
                        }) => {

        var utcSeconds = projectDetails.createTime;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);

        return (
            <>
                {this.state.redirectToProfile && <Redirect push to={params && params['userType'] && params['userType'] === "circle" ? "/community/" + this.state.redirectToProfile : "/profile/" + this.state.redirectToProfile}/>}

                <div className="projectlist" key={projectId + "opportunity"}>
                    <div className="projectlistinn">
                        <div className="projectlisttop">
                            <div className="projheadOuter">
                                <div className="projhead">
                                <figure className="complogo" onClick={(e) => this.getValue(e, projectDetails)}
                                        style={{cursor: 'pointer'}}>
                                    <img
                                        src={projectDetails && !_.isNull(projectDetails.coverImage) && !_.isUndefined(projectDetails.coverImage) ? projectDetails.coverImage : 'https://cdn.dscovr.com/images/project-default.webp'}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = "https://cdn.dscovr.com/images/project-default.jpg"
                                        }}
                                        alt=""/>
                                </figure>

                                <div>
                                    <a onClick={() => {
                                        this.redirect(customUrl)
                                    }} href="javascript:void(0)">
                                        <h5> {!_.isNull(creatorName) &&
                                        !_.isUndefined(creatorName) &&
                                        unescape(creatorName)}
                                        </h5></a>

                                    {location && location.city &&
                                    <span className="address">
                                    {location && location.country}
                                </span>
                                    }
                                </div>
                            </div>
                            </div> 
                            <div className="projectpricetime">
                                {fees > 0 && isDisclosed === false &&
                                <span
                                    className="price"> {currency} {this.nFormatter(fees, 2)} {" "}{!_.isNull(packageType) && !_.isUndefined(packageType) && packageType.toLowerCase() !== 'custom' && packageType}</span>
                                }
                            </div>                           
                        </div>
                        <div className="projectlistmid">
                            <h4>
                                <a onClick={(e) => this.getValue(e, projectDetails)}>

                                    <span>{title && title.split("\\n").map(title => (<>{convertUnicode(title)}<br/></>))}</span>
                                    {/*{title}*/}
                                </a>
                            </h4>

                            <a onClick={(e) => this.getValue(e, projectDetails)} style={{cursor: 'pointer'}}>
                                <p style={{cursor: 'pointer'}}>{shortBrief && shortBrief.split("\\n").map(shortBrief => (<>{convertUnicode(shortBrief)}<br/></>))}</p>
                            </a>
                        </div>                        

                        {(noOfDaysPosted === 0 || noOfDaysPosted === '0') ?
                            <div className="projposttime">
                                Posted Today
                            </div> :

                            <div className="projposttime">
                                Posted {timeAgo.format(d, 'mini-now')} ago
                            </div>
                        }

                    </div>
                </div>
            </>

        );
    };

    eventsAndTrainingBlock = ({
                                  location,
                                  title,
                                  shortBrief,
                                  fees,
                                  packageType,
                                  currency,
                                  isDisclosed,
                                  startDateDay,
                                  startDateMonth,
                                  startDateYear,
                                  projectDetails,
                                  projectId
                              }) => {

        return (

            <div key={projectId + "opportunity"}>
                <div className="projeventlist">
                    <div className="projeventlistinn">

                        <figure className="eventimg" onClick={(e) => this.getValue(e, projectDetails)}
                                style={{cursor: 'pointer'}}>
                            <img
                                src={projectDetails && !_.isNull(projectDetails.coverImage) && !_.isUndefined(projectDetails.coverImage) ? projectDetails.coverImage : 'https://cdn.dscovr.com/images/project-default.webp'}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "https://cdn.dscovr.com/images/project-default.jpg"
                                }}
                                alt=""/>
                            {fees > 0 && isDisclosed === false &&
                            <span
                                className="price"> {currency} {this.nFormatter(fees, 2)} {" "}{!_.isNull(packageType) && !_.isUndefined(packageType) && packageType.toLowerCase() !== 'custom' && packageType}</span>
                            }
                        </figure>

                        <div className="projeventdesc">
                            <h4><a href="javascript:void(0)" onClick={(e) => this.getValue(e, projectDetails)}
                                   style={{cursor: 'pointer'}}>
                                <p style={{cursor: 'pointer'}}>
                                    <strong>{title && title.split("\\n").map(title => (<>{convertUnicode(title)}<br/></>))}</strong>
                                </p>
                                {/*{title}*/}
                            </a></h4>
                            <div className="d-flex">

                                <div className="date">{startDateDay}
                                    <span>{!_.isUndefined(startDateMonth) && !_.isNull(startDateMonth) && startDateMonth.toUpperCase()}</span>
                                    <span>{!_.isUndefined(startDateYear) && !_.isNull(startDateYear) && startDateYear.toUpperCase()}</span>
                                </div>

                                <div className="projeventdesctxt">
                                    <a href="javascript:void(0)"><p onClick={(e) => this.getValue(e, projectDetails)}
                                                                    style={{cursor: 'pointer'}}>{shortBrief && shortBrief.split("\\n").map(shortBrief => (<>{convertUnicode(shortBrief)}<br/></>))}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="projeventpriceloc">
                            {location && location.city &&
                            <span className="address">
                                {location && location.country}
                                    </span>
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    };

    componentDidMount() {
        this.getRecommendedProject();

    }

    getNoOfDaysAfterPosted = function (postedDate) {
        if (!postedDate) return 0;
        let dateOfPosting = new Date(postedDate);
        let noOfDays = date.subtract(new Date(), dateOfPosting).toDays();
        return Math.trunc(noOfDays);
    }

    getValue = (e, projectDetails) => {
        e.preventDefault();
        window.location.href = '/project/feeds/' + projectDetails.slug;
        CleverTapUtils.eventClick({}, "View Projects Recommendation") ;

    }

    getRecommendedProject() {
        let specialisation = [];
        let persona = [];
        let skills = [];
        let interests = [];
        let userType = this.state.userData.type;
        if (!_.isUndefined(this.state.userData.persona) && !_.isNull(this.state.userData.persona)) {
            persona.push(this.state.userData.persona);
        }
        if (!_.isUndefined(this.state.userData.specialities) && !_.isNull(this.state.userData.specialities)) {
            specialisation = this.state.userData.specialities;
        }
        if (!_.isUndefined(this.state.userData.interests) && !_.isNull(this.state.userData.interests)) {
            interests = this.state.userData.interests;
        }
        if (!_.isUndefined(this.state.userData.skills) && !_.isNull(this.state.userData.skills)) {
            skills = this.state.userData.skills;
        }


        let postBody = {
            "userId": this.state.userData.userId,
            "skill_set": skills,
            "specialisation": specialisation,
            "interest": interests,
            "persona": persona,
            "userType": userType

        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/project/recommended/?page=' + 0 + "&size=" + 1,
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.body && response.data.status === '200 OK') {
                this.setState({
                    list: response.data.body,
                    message: true
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    };


    render() {
        return (
            <div className="dgwidget peoplefollow clearfix">
                <h6>Projects for you</h6>
                <div className="projlistwrap">
                    {this.state.message && this.state.list && this.state.list.length === 0 &&
                    <div class="zdata zfeed1">
                        <div class="imgbox"><i class="iProjects_F"/></div>
                        <div class="desc">
                            <h4>Unfold New Opportunities</h4>
                            <p><Link to="/project">Proceed</Link> to view available projects.</p>
                            <h4>Help us suggest relevant projects for you</h4>
                            <p><Link to={"/profile-edit/" + this.state.userData.customUrl + '/#skills-specialities'}>Proceed
                                to update</Link> your skills and interests</p>
                        </div>
                    </div>}


                    {this.state.list && this.state.list.length > 0 && this.state.list.map((item) =>
                        (item && (item.projectType === 'EVENT' || item.projectType === 'TRAINING' || item.projectType === 'EXPEDITION' || item.projectType === 'STORYBOOK' || item.projectType === 'FUNDRAISE')) ?
                            this.eventsAndTrainingBlock(mappingUtils.mapTrendingOpportunity({...item})) : this.opportunityBlock(mappingUtils.mapTrendingOpportunity({...item}))
                    )}
                </div>
            </div>
        );
    }
}


export default RecommendedProject;

