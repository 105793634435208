import React, { Component } from "react";
import { detailsPageRedirection, getCDNUrl } from "../commons/CommonFunctions";
import ReadMoreReadLess from "../commons/ReadMoreReadLess";
import PresentationPostView from "../post/PresentationPostView";

class PresentationUnauthItem extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <a href="javascript:void(0)" onClick={(e) => detailsPageRedirection(this.props.item?.id)}>
                <div className="hpostconttop">
                    {this.props.item && (this.props.item.description || (this.props.item.hashTags && this.props.item.hashTags.length > 0)) &&
                        <ReadMoreReadLess id={this.props.item.id} description={this.props.item.description} hashTags={this.props.item.hashTags} />
                    }
                    {this.props.item && this.props.item.attachmentIds && this.props.item.attachmentIds[0] &&
                        <PresentationPostView attachments={this.props.item.attachmentIds[0]} />
                    }
                </div>
            </a>
        );
    }
}

export default PresentationUnauthItem;