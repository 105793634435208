export const CapitalizeFirstLetter = (str) => {
    return str && str.length ? str.charAt(0).toUpperCase() + str.slice(1) : ''
};

export const convertToFirstCapital = (s) => {
    if (s) {
        if (s.length <= 1) {
            return s.toUpperCase();
        }
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    }
    return s;
}

export const GetMessageForNotification = (userEvent, category) => {
    if (category === "ALL") {
        return "All"
    } else if (userEvent === "COMMENT") {
        if (category === "FORUM") {
            return "Someone comments on your posted Forum"
        } else if (category === "POST") {
            return "Someone comments on your posts in Feed"
        }
    } else if (userEvent === "LIKE") {
        if (category === "FORUM") {
            return "Someone likes your posted Forum"
        } else if (category === "POLL") {
            return "Someone likes your posted Poll"
        } else if (category === "PROJECT") {
            return "Someone likes your posted Project"
        } else if (category === "POST") {
            return "Someone likes on your posts in Feed"
        } else if (category === "COMMENT") {
            return "Someone likes your Comment"
        }
    } else if (userEvent === "POSTSHARE") {
        if (category === "FORUM") {
            return "Someone shares your posted Forum"
        } else if (category === "POST") {
            return "Someone shares your posted Feed"
        } else if (category === "POLL") {
            return "Someone shares your posted Poll"
        } else if (category === "PROJECT") {
            return "Someone shares your posted Project"
        }
    } else if (userEvent === "LIKE_REPLY") {
        return "Someone likes your Reply"
    } else if (userEvent === "COMMENT_REPLY") {
        return "Someone replies on your Comment"
    } else if (userEvent === "POST_AS_CAUSE") {
        return "Someone posts a feed in Cause you support"
    } else if (userEvent === "POST_AS_PROJECT") {
        return "Someone posts a feed in Project you participated"
    } else if (userEvent === "REQUEST") {
        return "Endorsement request sent by you"
    } else if (userEvent === "VOTE") {
        return "Someone Votes"
    } else if (userEvent === "MAKE_ADMIN") {
        return "Someone adds you as an Admin for the chat group"
    } else if (userEvent === "REQUEST_RECEIVED") {
        return "Someone sends you an Employee request"
    }else if (userEvent === "REQUEST_RECEIVED_ADMIN") {
        return "Someone sends an employee request for your organization"
    } else if (userEvent === "REQUEST_ACCEPTED") {
        return "Your employee request is accepted"
    } else if (userEvent === "REQUEST") {
        return "Endorsement request sent by you"
    } else if (userEvent === "SEND") {
        return "Someone sends you an Endorsement request"
    } else if (userEvent === "ADD") {
        return "Someone Endorses you"
    } else if (userEvent === "GIVEN") {
        return "You have endorsed a member"
    } else if (userEvent === "POLL_CREATE" && category === "POLL") {
        return "A Poll is posted"
    } else if (userEvent === "FORUM_CREATE" && category === "FORUM") {
        return "A Forum is posted"
    } else if (userEvent === "PROJECT_CREATE" && category === "PROJECT") {
        return "A Project is posted"
    }else if (userEvent === "MENTION") {
        return  "Someone tags you in the post"
    }else if (userEvent === "POSTCOMMENTMENTION") {
        return "Someone commented the tagged post"
    }else if (userEvent === "POSTLIKEMENTION") {
        return "Someone likes the tagged post"
    }

};

export const GetMessageForEvent = (userEvent) => {
    if (userEvent === "LIKE") {
        return "You will receive notifications when a member likes your activity"
    } else if (userEvent === "COMMENT") {
        return "You will receive notifications when a member comments on your activity"
    } else if (userEvent === "ADD") {
        return "You will receive a notification when your connects, members you follow posts a Poll, a Forum or a Project"
    } else if (userEvent === "POSTSHARE") {
        return "You will receive notifications when a member shares your Post"
    } else if (userEvent === "PROJECT_CREATE") {
        return "You will receive notifications when a member has posted a Project"
    }
};

export const GetMessageForCategory = (category) => {
    if (category === "NETWORK") {
        return "You will receive an email when someone sends you a Connection request"
    } else if (category === "POLL") {
        return "You will receive an email when your connect and member you follow posts a Poll or shares an opinion"
    }
    else if (category === "FORUM") {
        return "You will receive an email when your connect and member you follow posts a Forum or shares an opinion"
    }


};

export const GetMessageForEmail = (userEvent, category) => {
    if (userEvent === "CONNECT_INVITATION") {
        return "Someone sends a connection request"
    } else if (userEvent === "CONNECT_INVITATION_RESEND") {
        return "Reminder for a connection request"
    } else if (userEvent === "FORUM_POLL_CREATE" && category === "POLL") {
        return "Someone posts a Poll"
    } else if (userEvent === "FORUM_POLL_CREATE" && category === "FORUM_POLL") {
        return "Someone posts a Forum or Poll"
    } else if (userEvent === "REQUEST_RECEIVED_ADMIN") {
        return "Someone sends an Employee request"
    }
};
