export const projectPageClickRequest = (data) =>{
    return {
        type : "PROJECT_PAGE_CLICK_REQUEST",
        payload: data
    }
}

export const projectPageClickSuccess = (data) =>{
    return {
        type : "PROJECT_PAGE_CLICK_SUCCESS",
        payload : data,
        error : {}
    }
}

export const projectPageClickFailure = (error) =>{
    return {
        type : "PROJECT_PAGE_CLICK_FAILURE",
        payload : {},
        error : error
    }
}