import React from "react";

const StaticPagesCommonFunction = (function (namespace = {}) {

    namespace.userAgreement = () => {
        return <main className="dgcontainer hm3col">
            <div className="container">

                <section className="globalinn">
                    <div className="pagetitle bkstatcpgttl text-center">
                        <h1>USER AGREEMENT</h1>
                    </div>

                    <div className="globalPgCont">
                        <p>This User Agreement ("<strong>Agreement</strong>") is an agreement between Dscovr Journeys
                            Private Limited ("<strong>Company</strong>" or "<strong>we</strong>" or "<strong>us</strong>"
                            or "<strong>our</strong>") and you ("<strong>you</strong>" or "<strong>your</strong>" or
                            "<strong>user</strong>"). This Agreement explains our obligations to you, and your
                            obligations to us with regard to your use of www.wenaturalists.com
                            ("<strong>Website</strong>") in respect of the Services. By agreeing to comply with the
                            terms of this Agreement, you agree to be bound by the <a href="/TermsConditions">Terms and
                                Conditions</a>, the <a href="/PrivacyPolicy">Privacy Policy</a> and any other legal
                            notices or conditions or guidelines posted on the Website, which shall be binding on you,
                            your successors and/or assigns. The Company reserves all rights to change, modify, update,
                            or alter ("<strong>update</strong>") this Agreement at any point of time with or without
                            notifying you. Your continued use of the Website means that you accept any updated terms and
                            conditions that we come up with. If you do not agree to any of the terms and conditions
                            mentioned herein or if you do not wish to accept any update to this Agreement, you are
                            advised not to use this Website for the Services. Please re-visit the User Agreement on our
                            Website from time to time to stay abreast of any changes that we may introduce.</p>

                        <div className="text-center"><h2>1. DEFINITIONS AND INTERPRETATION</h2></div>
                        <p>In this Agreement, unless the context otherwise requires, the following expressions have the
                            following meanings:</p>
                        <p>"<strong>Associate Websites</strong>" means and includes all other websites, platforms, and
                            applications owned and operated by the Company;</p>
                        <p>"<strong>Content</strong>" means and includes the content and information provided on the
                            Website in any form and capable of being stored on a computer that appears on, or forms part
                            of, including its any trademarks, information related to any services, contact details,
                            graphics, functions and other materials available, whether publicly posted or privately
                            transmitted as well as all derivative works thereof; including the Website's design
                            structure and compilation and all content such as text, images, audio, video, scripts, code,
                            documents, databases, the mascot and the source code of the Website and any other form of
                            information made available on the Website by us and capable of being stored on a computer
                            that appears on, or forms part of, our Website;</p>
                        <p>"<strong>Information</strong>" shall mean any information you provide to the Website and
                            shall include your picture, name, contact details, skills, specializations, interests,
                            causes, experience, qualifications, email address(es), data, text, software, music, sound,
                            photographs, graphics, video, messages or other materials uploaded, posted or stored in
                            connection with your use of the Services;</p>
                        <p>"<strong>Members</strong>" shall mean and include those users of this Website who have
                            created an account on this Website and can access the Services;</p>
                        <p>"<strong>Premium Services</strong>" shall mean and include certain Services that Members may
                            purchase in consideration for a Fee on the Website and includes subscription of certain
                            Services and/or purchasing certain products offered on the Website.</p>
                        <p>"<strong>Services</strong>" means all features provided on the Website and the Associate
                            Websites including but not limited to all applications, communications, software, feeds,
                            networking, posting and any other services that are offered and available on this Website
                            and the Associate Websites to the Members from time to time, including any Premium Services.
                        </p>
                        <p>"<strong>Fee</strong>" shall mean the fee paid by a Member in consideration for the
                            subscription and access to the Premium Services on the Website and/or purchase of products
                            as per the rates assigned to each product under the Premium Services.</p>
                        <p>In this Agreement: (a) headings are for convenience only and do not affect interpretation;
                            (b) words in the singular include the plural; and (c) including means including but not
                            limited to.</p>

                        <div className="text-center"><h2>2. SCOPE AND REGISTRATION</h2></div>
                        <p>If you choose to register as a Member on this Website, you shall be bound by this Agreement
                            and the Agreement shall at all times be read together with the <a href="/TermsConditions">Terms
                                and Conditions</a> and the <a href="/PrivacyPolicy">Privacy Policy</a>. In the event of
                            any conflict in the terms or interpretation, this Agreement shall prevail over the <a
                                href="/TermsConditions">Terms and Conditions</a>. The <a href="/PrivacyPolicy">Privacy
                                Policy</a> and <a href="/TermsConditions">Terms and Conditions</a> are incorporated into
                            this Agreement by reference.</p>
                        <p>The Company reserves the right, in its sole discretion, to deny you access to the Services or
                            any portion thereof without notice for the following reasons, immediately (a) for any
                            unauthorized access or use by you (b) if you assign or transfer (or attempt the same) any
                            rights granted to you under this Agreement; (c) if you violate any of the other terms and
                            conditions of this Agreement.</p>
                        <p>The Company reserves the right to limit your use of the Services (including any Premium
                            Services), including the number of your connections and your ability to contact other
                            Members. </p>
                        <p>The Company reserves the right, in its sole discretion, to amend, alter, modify, change or
                            discontinue the Services (including the Premium Services) or a portion thereof at any time,
                            without penalty and without notice, for any reason whatsoever.</p>

                        <div className="text-center"><h2>3. YOUR ACCOUNT</h2></div>
                        <p>When you create an account on the Website you register yourself as a Member. By creating an
                            account on the Website and registering as a Member, you certify that all Information you
                            provide, now or in the future, is accurate. You shall not misrepresent any Information that
                            you disclosed to us.</p>
                        <p>You agree to fully and accurately provide the Information requested by us when setting up
                            your account and to regularly update such Information. Your failure to do so may affect your
                            experience using this Website and may limit your ability to use your account and/or the
                            Services (including any Premium Services).</p>
                        <p>You hereby agree and confirm that you are legally responsible for all Information you share
                            in connection with your use of the Services and/or the Premium Services. You agree to only
                            provide Information that does not violate the law nor anyone's rights (including
                            intellectual property rights). You hereby agree and acknowledge that the Company may be
                            required by law to remove certain Information in certain countries or under certain
                            circumstances. The Company is not responsible for your Information in any manner
                            whatsoever.</p>
                        <p>You acknowledge that your account shall be set to default privacy settings at the time of
                            creation which allows your Information to be viewed by all users of the Website. </p>
                        <p>Should you wish to change the privacy settings of your account, you may do so under the <a
                            href="/settings">Settings and Privacy</a> tab on the Website. Of course, if you choose not
                            to disclose certain information, your experience using this Website may be affected and your
                            ability to use your account and/or the Services (including any Premium Services) may become
                            limited.</p>
                        <p>You hereby grant the Company a worldwide, royalty-free, transferable, sub-licensable and
                            non-exclusive license to use, reproduce, display, perform, copy, modify, edit, distribute,
                            publish, exhibit and process your Information for any and all non-commercial purposes and in
                            accordance with the terms of this Agreement, the <a href="/TermsConditions">Terms and
                                Conditions</a> and the <a href="/PrivacyPolicy">Privacy Policy</a> and without us
                            requiring any further consent from you and/or providing any notice and/or compensation to
                            you or other. This license is limited in the following ways:</p>
                        <ul className="listloweralpha">
                            <li>You may terminate this license for specific Information by deleting such Information
                                from your account or by closing your account. However, this license may not be
                                terminated by you with respect to Information that (a) you may have shared with other
                                users as part of the Website and they have copied, re-shared it or stored it and (b) for
                                the reasonable time it takes to remove such Information from the Website backup and
                                other such systems.
                            </li>
                            <li>We will not include your Information in advertisements for the products and services of
                                third-party advertisers or ad networks or to others without your separate permission.
                                However, we may, without any compensation to you or others, use your Information to show
                                you ads similar to your Information and your social actions may be visible and included
                                with ads, as more specifically described in the Privacy Policy. If you use a Website
                                feature, we may mention that with your name or photo to promote that feature within our
                                Website, subject to your settings.
                            </li>
                            <li>While we may edit and format the Information you share, however, we shall not change the
                                meaning of your expression of such Information.
                            </li>
                            <li>You may choose to make your Information available to other Users of the Website.</li>
                        </ul>
                        <p>You agree and acknowledge that we may access, store, process, and use your Information in
                            accordance with the terms of the Privacy Policy and your choices including your account
                            settings.</p>

                        <div className="text-center"><h2>4. LOGIN ID AND PASSWORD DISCLOSURE</h2></div>
                        <p>To ensure security of access to the Information you share with us while registering as a
                            Member, your use of the Services on our Website is restricted by the unique login ID and
                            password selected by you. You should be careful in handling the login ID and password and
                            you should ensure that you do not reveal it to anybody. You should keep changing your
                            password periodically.</p>
                        <p>You are entirely responsible for maintaining the confidentiality of your login ID and
                            password. In the event you believe that there is unauthorized use of your account, you shall
                            immediately notify the Company in writing at <a href="mailto:support@wenaturalists.com"
                                                                            target="_blank">support@wenaturalists.com</a>.
                            You are responsible for anything that happens through your account unless you close it or
                            report misuse.</p>
                        <p>The login ID provided to you when you register as a Member permits you simultaneous access to
                            the Services on multiple devices (including computers and mobile phones) at a time through
                            the Website. Further, we provide you an option to enable an additional security feature
                            two-factor authentication ("<strong>2FA</strong>") which requires you to provide two means
                            of authentication when you access your account, including your password and security code.
                            2FA provides you with: (1) a security code when you access your account for the first time,
                            (2) a security code when you access your account for the first time from a new device. The
                            2FA feature may require you to provide the security code sent to you when accessing your
                            account for the first time or when accessing your account for the first time from a new
                            device, as the case may be. </p>
                        <p>Sharing of your login ID and password by any Member to a third party is prohibited and in
                            contravention of this Agreement. Upon breach, the Company reserves the right, in its sole
                            discretion, to terminate your access to the Website and the related Services (including any
                            Premium Services) or any portion thereof at any time, without notice.</p>

                        <div className="text-center"><h2>5. LICENSE</h2></div>
                        <p>We hereby grant you a limited, revocable, non-assignable, non-transferable and non-exclusive
                            license to access the Website and use the Content for the purpose of availing the Services
                            and/or Premium Services, provided and expressly conditioned upon your agreement that all
                            such access and use shall be governed by all of the terms and conditions set forth in this
                            Agreement.</p>

                        <div className="text-center"><h2>6. PREMIUM SERVICES</h2></div>
                        <p>In consideration of the Fee, the Company shall provide certain Premium Services to its
                            Members. The Fee will be charged to you in United States Dollars (USD) for such Premium
                            Services. The Fee, any promotional offers, discounts, etc. and the scope of the Premium
                            Services shall be as provided on the Website from time to time.</p>
                        <p>Certain portions of the Website are only accessible to Members who have paid the Fee and
                            subscribed to such Premium Services. By subscribing to receive any of these Premium
                            Services, you additionally agree to the following terms and conditions:</p>
                        <ul className="listloweralpha">
                            <li>You agree to pay any Fee at the rates in effect when the charges are incurred;</li>
                            <li>You must provide us with complete and accurate payment information. You can pay by
                                either Credit / Debit card / Net Banking through an internet payment gateway. By
                                submitting credit or debit card payment details to the Company you warrant that you are
                                entitled to purchase the Premium Services using those payment details. In the case of
                                unauthorized payments, the Company reserves the right to suspend or terminate your
                                access to the Premium Services. If we do not receive payment authorization or any
                                authorization is subsequently canceled, we may immediately terminate or suspend your
                                access to any Premium Services.
                            </li>
                            <li>Your purchase may be subject to foreign exchange fees or differences in prices based on
                                location (e.g. exchange rates, bank charges).
                            </li>
                            <li>We may store and continue billing your payment method (e.g. credit card) even after it
                                has expired, to avoid interruptions in your Premium Services.
                            </li>
                            <li>If you purchase a subscription to our Premium Services, your payment method
                                automatically will be charged at the start of each subscription period for the Fees and
                                taxes applicable to that period. To avoid future charges, please cancel your
                                subscription to such Premium Services before the renewal date.
                            </li>
                            <li>All your purchases of Premium Services are subject to our refund policy herein.</li>
                            <li>We may calculate taxes payable by you based on the billing information that you provide
                                us at the time of purchase.
                            </li>
                        </ul>
                        <p>The Company will try to process your subscription to such Premium Services promptly but does
                            not guarantee that the Premium Services will be available to you by any specified time. A
                            contract with you for rendering the Premium Services will come into effect when the Company
                            sends you an email message confirming your subscription details.</p>
                        <p>You can get a copy of your invoice through your account settings under "Purchase
                            History".</p>
                        <p>The delivery of Premium Services from the Company will be directly on the Website. Members
                            who have subscribed to such Premium Services may also log in to the Website to view the
                            Premium Services that they have subscribed to.</p>


                        <div className="text-center"><h2>7. REFUND POLICY</h2></div>
                        <p>The Company does not offer any refund of the Fees and any other type of fees or charges under
                            any circumstances whatsoever. However, any change or modification in this no-refund policy
                            shall be at the Company's sole discretion.</p>

                        <div className="text-center"><h2>8. CANCELLATION</h2></div>
                        <p>Premium Services once subscribed to and paid for, cannot be canceled. A request for
                            termination of Premium Services may be given by the Member, however, no Fee or any other
                            amounts shall be refunded by the Company.</p>
                        <p>You agree that your subscription to the Premium Services may automatically renew for an
                            additional period until explicitly canceled by you. Any cancellation of your subscription to
                            our Premium Services must be done at least 1 (one) day prior to the end of the subscription
                            period to allow for adequate processing time.</p>

                        <div className="text-center"><h2>9. TAXES</h2></div>
                        <p>You take full responsibility for all taxes and Fees of any nature associated with the Premium
                            Services, including any indirect taxes by whatever name called (service tax or value added
                            tax or goods and services tax, etc.) related to the subscription of the Premium Services.
                            Except for collecting the indirect taxes payable on the Fee and remitting it to the tax
                            authorities, the Company shall not be liable for any taxes or other fees to be paid in
                            accordance with or related to the Premium Services. </p>

                        <div className="text-center"><h2>10. TERM AND TERMINATION</h2></div>
                        <p>You agree to avail the Services (including the Premium Services) offered on this Website for
                            a period commencing on the day you become a Member and expiring on the day you delete your
                            account ("<strong>Term</strong>"). You agree that the Term shall expire only upon explicit
                            deletion of your account.</p>
                        <p>You may cancel or terminate this Agreement any time whatsoever via by deleting your account
                            or writing to us at <a href="mailto:support@wenaturalists.com"
                                                   target="_blank">support@wenaturalists.com</a>.</p>
                        <p>This Agreement and the license rights granted hereunder shall remain in full force and effect
                            unless terminated or canceled for any reason whatsoever. Termination or cancellation of this
                            Agreement shall not affect any right or relief to which the Company may be entitled, at law
                            or in equity. Upon termination of this Agreement, all rights granted to you will terminate
                            and revert to the Company.</p>
                        <p>We may revoke the Services (including the Premium Services) at any time, without penalty and
                            without notice, if you fail to comply with any of the terms of this Agreement. </p>
                        <p>Notice of revocation of the Services and/or Premium Services by the Company may be sent to
                            the primary e-mail address associated with your account. Upon termination, the Company has
                            the right but no obligation to delete all data, files, or other Information that is stored
                            in your account.</p>

                        <p>The following shall survive termination:</p>
                        <ul className="listloweralpha">
                            <li>Our rights to use and disclose your feedback;</li>
                            <li>Members' and/or users' rights to further re-share content and information you shared
                                through the Services and/or Premium Services;
                            </li>
                            <li>Restrictions and Permissions of User, Disclaimer and Limitation of Liability (under the
                                Terms & Conditions) shall survive the termination of this Agreement;
                            </li>
                            <li>Any amounts owed by the Company prior to termination remain owed after termination.</li>
                        </ul>

                        <div className="text-center"><h2>11. USE OF COMMUNICATION FACILITIES</h2></div>
                        <p>This Website contains messages/bulletin boards, WeBuzz, forums, chat rooms, group posts,
                            project postings, or other message or communication facilities (collectively, "<strong>Communication
                                Facilities</strong>"), you agree to use the Communication Facilities only to send and
                            receive messages, Information and materials that are proper and related to the particular
                            Communication Facility. Any messages and materials that you share, or post may be seen by
                            other users. Including but without limitation, you agree that when using a Communication
                            Facility, you shall not do any of the following:</p>
                        <ul className="listloweralpha">
                            <li>Publish, post, distribute, or disseminate any defamatory, infringing, obscene, indecent,
                                or unlawful material or information.
                            </li>
                            <li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as
                                rights of privacy and publicity) of others.
                            </li>
                            <li>Upload files that contain viruses, corrupted files, or any other similar software or
                                programs that may damage the operation of another's computer or files that contain
                                software or other material protected by intellectual property laws (or by rights of
                                privacy of publicity) unless you own or control the rights thereto or have received all
                                necessary consents.
                            </li>
                            <li>Download any file posted by another user of a Communication Facility that you know, or
                                reasonably should know, cannot be legally distributed in such manner.
                            </li>
                            <li>Conduct contests, surveys, or forward chain letters or spam.</li>
                            <li>Not to misuse by posting malicious and ill-intentioned messages</li>
                        </ul>
                        <p>In the event you violate the manner in which you post any materials or Information or
                            messages on our Communication Facilities for any reason whatsoever, we shall respond or
                            provide an acknowledgment to the complainant within 36 (thirty six) hours of becoming aware
                            of any such violation or within 36 (thirty six) hours of any such violation being brought to
                            our notice by any other user, person or entity and initiate appropriate action under law
                            which may include disabling such information. Further, we shall preserve records of such
                            violative materials and/or Information and/or messages for a period of 90 (ninety) days for
                            investigation purposes and in accordance with the law. We have the right to immediately
                            terminate your account and restrict your use and access to the Website, the Services
                            (including the Premium Services).</p>
                        <p>Where we have made settings available, we will honor the choices you make about who can see
                            messages, Information and materials posted by you (e.g., sharing content only to your
                            connections, profile visibility on search engines, or opting not to notify others of your
                            profile updates). For project searches, by default, we do not notify your connections or the
                            public. So, if you apply for a project through any of the Services, our default is to share
                            it only with the project poster.</p>
                        <p>We are not a storage service and we do not promise to store or keep showing any messages,
                            material, or the Information that you have posted. You agree that we have no obligation to
                            store, maintain or provide you a copy of any messages, materials or information that you
                            provide, except to the extent required by applicable law and as noted in our <a
                                href="/PrivacyPolicy">Privacy Policy</a>.</p>
                        <p>We expect you to exercise due caution before transacting on any message or material posted on
                            the Communication Facilities. The messages and materials posted on the Communication
                            Facilities are the views of the individuals posting them; The Company or the Associate
                            Websites do not assume any liability on account of any message posted therein.</p>

                        <div className="text-center"><h2>12. EXCLUSIVE REMEDY</h2></div>
                        <p>In the event of any problem with the Website, the Services (including the Premium Services)
                            and/or the Content, you agree that your sole and exclusive remedy is to cease using the
                            Website, the Services and/or Premium Services. Under no circumstances shall the Company
                            and/or its associate entities and/or Associate Websites be liable in any way for your use of
                            the Website, the Services and/or Premium Services, on or through the Website, including, but
                            not limited to, any errors or omissions, any infringement of the intellectual property
                            rights or other rights of third parties, or for any loss or damage of any kind incurred as a
                            result of, or related to, the use of the Website, the Services and/or Premium Services
                            available on or through the Website.</p>

                        <div className="text-center"><h2>13. PROJECTS</h2></div>
                        <p>Please note that while our Services include project information and the Website may contain
                            information, postings or contact details of persons in relation to the project information
                            accessible through our Services, any such projects and/or training events and/or workshops
                            and any other events hosted by third parties are not under the control of the Company. The
                            Company is not responsible for the acts or omissions of any third parties that may host such
                            projects and/or training events and/or workshops and any other events. The Company does not
                            warrant or guarantee the availability, completeness, usefulness, safety, reliability of any
                            such third-party projects and/or training events and/or workshops, and any other events. You
                            acknowledge that you access and attend such third-party projects and/or training events
                            and/or workshops and any other events solely at your own risk. You agree that under no
                            circumstances will the Company be liable in any way for any errors or omissions in any such
                            projects and/or training events and/or workshops and any other events, or for any loss or
                            damage of any kind incurred as a result of your attendance of any such third-party projects
                            and/or training events and/or workshops and any other events.</p>

                        <div className="text-center"><h2>14. OTHER REMEDIES</h2></div>
                        <p>You acknowledge that a violation or attempted violation of this Agreement will cause such
                            damage to the Company and/or the Associate Websites as will be irreparable, the exact amount
                            of which would be impossible to ascertain and for which there will be no adequate remedy at
                            law. Accordingly, you agree that the Company shall be entitled as a matter of right to an
                            injunction issued by any court of competent jurisdiction, restraining such violation or
                            attempted violation of this Agreement by you, or your affiliates, partners, or agents, as
                            well as to recover from you any and all costs and expenses sustained or incurred by the
                            Company in obtaining such an injunction, including, without limitation, reasonable
                            attorney's fees. You agree that no bond or other security shall be required in connection
                            with such an injunction.</p>
                        <p>In no event shall you be entitled to rescission, injunctive or other equitable relief, or to
                            enjoin or restrain the operation of the Company, the exploitation of any advertising or
                            other materials issued in connection therewith, or the exploitation of the Website or any
                            Content used or displayed through the Website.</p>

                        <div className="text-center"><h2>15. COMMUNICATION METHODS</h2></div>
                        <p>Please note that the Company reserves the right to communicate with you in the mode and
                            manner it deems appropriate. Any communications with you may be in the form of emails and/or
                            notifications and/or notices sent by the Company to you, at its sole discretion. While you
                            may opt to switch off certain notifications and manage your preferences, you agree and
                            confirm that the Company reserves the right to communicate with you regarding the Services,
                            the Website, the Agreement, the <a href="/TermsConditions">Terms and Conditions</a> and
                            the <a href="/PrivacyPolicy">Privacy Policy</a>, in any mode or manner whatsoever. You
                            acknowledge and agree that any communication sent to you by the Company in any mode or
                            manner chosen by the Company shall be deemed to be an adequate manner of communicating with
                            you.</p>

                        <div className="text-center"><h2>16. MISCELLANEOUS</h2></div>
                        <ul className="listloweralpha">
                            <li>If any portion of this Agreement is found to be unenforceable, the remaining portion
                                will remain in full force and effect.
                            </li>
                            <li>Our failure to enforce any part of this Agreement shall not be considered as a waiver of
                                any rights and claims that we may be entitled to in any manner whatsoever.
                            </li>
                            <li>You will not transfer any of your rights or obligations under this Agreement to anyone
                                else without our prior written consent.
                            </li>
                            <li>All of our rights and obligations under this Agreement are freely assignable by us in
                                connection with a merger, acquisition, or sale of assets, or by operation of law or
                                otherwise.
                            </li>
                            <li>This Agreement does not confer any third-party beneficiary rights.</li>
                            <li>A printed version of this Agreement and of any related notice given in electronic form
                                shall be admissible in judicial or administrative proceedings based upon or relating to
                                this Agreement to the same extent and subject to the same conditions as other business
                                documents and records originally generated and maintained in printed form.
                            </li>
                        </ul>

                        <div className="text-center"><h2>17. GOVERNING LAW AND JURISDICTION</h2></div>
                        <p>This Agreement shall be governed by and construed in accordance with laws of the Republic of
                            India. You explicitly agree that irrespective of the country you are a citizen/resident of
                            and whether or not you are a resident of India, you shall be bound by the laws and
                            regulations relating to data protection of the Republic of India. You explicitly agree that
                            the courts at Mumbai, Maharashtra, India shall have exclusive jurisdiction over any disputes
                            arising under hereunder.</p>

                        <div className="text-center"><h2>18. RELATIONSHIP</h2></div>
                        <p>You understand and agree that no joint venture, partnership, employment, or agency
                            relationship exists between you and us on account of you using the Website or us rendering
                            the Services (including the Premium Services).</p>
                    </div>
                </section>

            </div>
        </main>
    }

    namespace.privacyPolicy = () => {
        return <main className="dgcontainer hm3col">
            <div className="container">

                <section className="globalinn" id="privacyPolicyPdf">
                    <div className="pagetitle bkstatcpgttl text-center">
                        <h1>PRIVACY POLICY</h1>
                    </div>

                    <div className="globalPgCont">
                        <p>Your privacy is very important to Dscovr Journeys Private Limited ("<strong>Company</strong>"
                            or "<strong>we</strong>" or "<strong>us</strong>"). The following Privacy Policy explains
                            how we collect, use and safeguard the information you ("<strong>user</strong>" or
                            "<strong>you</strong>") provide to us through or in association with your use of the Website
                            www.wenaturalists.com ("<strong>Website</strong>"), its related features and functions and
                            Content as defined in the <a href="/TermsConditions">Terms and Conditions</a></p>

                        <p>Our Website allows nature enthusiasts and professionals, institutions and government bodies
                            to engage with each other, share experiences and learning, create economic opportunity, and
                            grow with a network of trusted relationships. By visiting this Website and/or accessing the
                            Content, you agree to be bound by the terms and conditions of this Privacy Policy. If you do
                            not agree please do not use or access our Website. This Privacy Policy is subject to change
                            at any time without notice. The Company reserves all rights to change, modify, update, or
                            alter ("<strong>update</strong>") this Privacy Policy at any point of time with or without
                            notifying you. Your continued use of the Website means that you accept any updated terms and
                            conditions that we come up with. If you do not wish to accept any update to this Privacy
                            Policy, then you shall immediately stop accessing and/or using the Website. To make sure you
                            are aware of any changes, please review this Privacy Policy periodically.</p>

                        <p>By mere use of the Website, you expressly consent to our use and disclosure of your personal
                            information in accordance with this Privacy Policy. This Privacy Policy is incorporated into
                            and subject to the <a href="/UserAgreement">User Agreement</a> and the <a
                                href="/TermsConditions">Terms and Conditions</a>.</p>
                        <p>In furtherance of our efforts to protect your privacy, the Company and this Privacy Policy
                            are in compliance with the Information Technology Act, 2000 and the rules formulated
                            thereto.</p>
                        <p>The Company endeavors to ensure that the information that you submit to us remains private
                            and that it is used only for the purposes described below. We aim to provide a safe and
                            secure experience for all of our users and Members (as defined below).</p>
                        <p>Please read the following statement to learn about our information gathering and
                            dissemination practices.</p>

                        <div className="text-center"><h2>1. TYPES OF INFORMATION WE COLLECT</h2></div>
                        <p>When you create an account on the Website you register yourself as a member to access certain
                            services as per the <a href="/UserAgreement">User Agreement</a> including any paid or
                            premium services rendered to you by the Company ("<strong>Services</strong>") and provide us
                            with personal data ("<strong>Member</strong>"). We will process such personal data on the
                            basis that it is necessary to do so to perform the contract you enter with us. We will
                            process personal data on the basis set out below as it is in our legitimate interests to do
                            so following your registration.</p>
                        <p>We can collect two types of information, 'Submitted Information' and 'Aggregate Information'
                            (collectively, "User Information"). </p>

                        <h5>(A) Submitted Information - Information you give us and information we collect from
                            you:</h5>
                        <ul className="listroman">
                            <li><u>Account Information:</u> When you create an account with us and register as a Member,
                                you provide us with data including your name, email address, mobile number, and
                                password.
                            </li>
                            <li><u>Payment Information:</u> When you access certain premium Services by paying the
                                subscription fees, you provide us with payment and billing-related information payment
                                and billing information such as your credit card or debit card information.
                            </li>
                            <li><u>Profile Information:</u> When you register as a Member and create a profile on our
                                Website, you provide us information on your profile such as your education, work
                                experience, skills and specializations, causes you believe in, photographs, city or area
                                and endorsements. You may provide us sensitive information relating to you such as your
                                race, ethnicity, political opinions, religious or philosophical beliefs, trade union
                                membership, physical or mental health, sexual orientation, or criminal record, however,
                                you are not required to provide this sensitive information. Any information that you add
                                to your profile is visible to other users of the Website. Please do not post any
                                information on your profile that you would not like others to view.
                            </li>
                            <li><u>Posted Information:</u> You may give us information about you by filling in forms on
                                the Website, responding to a survey or poll, submitting a resume or other similar
                                documents, filling out a project application, or by corresponding with us (for example
                                by email). Further, when you choose to import your address book and sync your contacts
                                and calendars with your account, we use such information to keep growing your network,
                                suggest connections for you and others and provide you with information about events
                                (such as timing, locations, and other attendees). We will keep posted information till
                                you remove such posted information off the Website, or for as long as you want to keep
                                your account with us. You don't have to post any such information, however, if you
                                don't, it may limit your ability to grow and engage with your network on our Website.
                            </li>
                            <li><u>Application Information:</u> When you use the Services or interact with any of our
                                customers, partners, Members who are employers/prospective employers on the Website, and
                                use applicant tracking systems, you submit certain job application data such as your job
                                title and work email address. Further, we receive information about your visits and
                                interaction with services provided by other users when you log in to your account or
                                visit others' services that include some of our plugins (such as "Apply with
                                WeNaturalists") or our ads, cookies or similar technologies.
                            </li>
                            <li><u>Communication Information:</u> This Website contains messages/bulletin boards,
                                WeBuzz, forums, chat rooms, group posts, project postings, or other message or
                                communication facilities (collectively, "<strong>Communication Facilities</strong>"). We
                                collect information about you when you send, receive, or engage with messages and/or use
                                our Communication Facilities in connection with our Website. If you get a request from
                                another user for a connection, we track whether you have accepted the request and may
                                send you reminders about it. We also use automatic scanning technology on messages and
                                Communication Facilities to support and protect our Website, to suggest possible
                                responses to messages, and to block content from our Website that violates our <a
                                    href="/UserAgreement">User Agreement</a>, <a href="/TermsConditions">Terms and
                                    Conditions</a>, or <a href="/CommunityGuidelines">Community Guidelines</a>.
                            </li>
                            <li><u>Other:</u> We may also ask you for information when you report a problem with the
                                Website. If you contact us, we may keep a record of that correspondence. We will use
                                telephone recordings or transcripts of communications to check your instructions to us,
                                analyze, assess and improve the Services, for training and quality purposes and for the
                                purposes of investigating any complaint you may make, or as evidence in any dispute
                                between you and us. We may also store details of your visits to the Website including,
                                but not limited to, traffic data, location data, weblogs, and other communication data,
                                whether this is required for our billing purposes or otherwise and the resources that
                                you access. Whenever we introduce new features to our Website and/or the Services, we
                                may require the collection of new information from you.
                            </li>
                        </ul>

                        <h5>(B) Aggregate Information - Information we collect about you and your device:</h5>
                        <p>Each time you use our Website and/or access the Services, we may automatically collect the
                            following information: technical information, including the type of device or mobile device
                            or computer or desktop you use, your internet protocol (IP) address(es), a unique device
                            identifier (for example, your device IMEI number, the MAC address of the device's wireless
                            network interface, or mobile phone number used by the device), mobile network information,
                            your mobile operating system, the type of mobile browser you use and time zone setting. If
                            you access our Website from a mobile device, that device will send us data about your
                            location based on your phone settings. We will ask you to opt-in before we use GPS or other
                            tools (such as Bluetooth) to identify your location. We also collect usage data when you
                            visit our Website and access the Content and/or the Services including collecting data when
                            you click on content, ads, perform a search, share articles, or apply for projects. We use
                            log-ins, cookies, device information and internet protocol (IP) addresses to identify you
                            and log your use.</p>
                        <p>Do not add or submit any User Information on the Website in any manner whatsoever, if you do
                            not wish the Website to collect it.</p>

                        <div className="text-center"><h2>2. WHAT WE DO WITH THE INFORMATION</h2></div>
                        <h5>(A) Submitted Information:</h5>
                        <p>We use Submitted Information to try to provide and personalize the Services, authorize your
                            access to the Services, to improve the quality of the Content that we provide on the
                            Website, to personalize your online experience and to build a strong community. </p>
                        <p>Your name and any other information you choose to make available in connection with any
                            materials posted by you on the Website and on your profile will be published on the Website
                            and will be disclosed to other users of the Website and to other third parties who may have
                            access to or otherwise see a display of such information on the Website, subject to privacy
                            settings opted by you.</p>
                        <p>Subject to the privacy settings opted by you:</p>
                        <ul className="listroman">
                            <li>When, how, and what extent of your Submitted Information we choose to disclose to other
                                users of the Website will be at our sole discretion and you hereby provide the Company
                                with your express consent to make such disclosures as it deems appropriate.
                            </li>
                            <li>We may use such Submitted Information for internal or external marketing and promotional
                                purposes. We will also share your Submitted Information with companies, organizations,
                                and individuals outside of the Company for any direct marketing purposes.
                            </li>
                        </ul>
                        <p>We do not share your Submitted Information with any third-party advertisers or ad networks
                            except for (i) hashed IDs or device identifiers (to the extent they are personal data in
                            some countries); (ii) with your separate permission or (iii) your profile information that
                            already visible to any users of the Services. However, if you click on an ad on our Website
                            or view an ad, the advertiser may receive information of such action through cookies and be
                            able to identify you. Advertising partners can associate Submitted Information collected by
                            the advertiser directly from you with hashed IDs or device identifiers received from us. In
                            such instances, we seek to contractually require such advertising partners to obtain your
                            explicit, opt-in consent before doing so.</p>

                        <p><u>We may also use Submitted Information about you including but not limited to the following
                            ways:</u></p>
                        <ul className="listloweralpha">
                            <li>To keep you informed about news, events, and ideas related to topics you are interested
                                in and other Members that you are connected to;
                            </li>
                            <li>To personalize the Services by recommending relevant portions of the same and
                                personalizing content on your feed and suggesting other Members that you can follow or
                                connect with;
                            </li>
                            <li>To suggest skills, specialization, interests, and causes you could add to your profile
                                and that you could use to pursue an opportunity available through our Website, to
                                suggest hashtags that you can follow and to suggest content related to the same;
                            </li>
                            <li>To recommend forums and Buzzrooms based on your profile;</li>
                            <li>To notify other Members of any updates to your profile, information that you may have
                                posted, projects or activities that you have participated in, your new connections,
                                social actions (such as likes, comments, follows and shares), new features of the
                                Website that you may have used or if you were mentioned or published in any news,
                                publications or other media;
                            </li>
                            <li>Provide your Submitted Information to those looking to be hired to recommend projects
                                and allow you to grow your network by recommending you connect with other related
                                members who may work at the same company or location as you or who have similar skills,
                                interests or connections;
                            </li>
                            <li>Provide your Submitted Information to other Members and recruiters who are looking to
                                hire to recommend profiles and people who fit into their project postings;
                            </li>
                            <li>To communicate with you and send you messages about the availability of the Services,
                                security or other service-related issues, to explain to you how to use the Services, to
                                send you reminders, to suggest jobs, assignments, events or training and to send
                                promotional messages from us or our affiliates. You may not opt-out of receiving service
                                messages from us, including security and legal notices. Further, we may also use your
                                Submitted Information to enable communication between you and other users of our Website
                                such as for connection requests, groups and messages between connections;
                            </li>
                            <li>To send you invitations and communications, promote registration of Members and network
                                growth, to suggest you add contacts from your phone books as connections, to suggest
                                linking the Website calendar to projects, to suggest syncing your phone calendar, to
                                promote engagement and our Services;
                            </li>
                            <li>To show you sponsored ads on our Website. If you take any social action on these
                                sponsored ads, your social action may be viewed by other users and the advertiser.
                                Subject to your profile settings, we may display any social action on an ad and display
                                your Submitted Information to your connections. We may also display your social actions
                                on the Services;
                            </li>
                            <li>To notify you about changes to the Services;</li>
                            <li>To conduct research and development for the Services, for other research such as social,
                                economic, demographic, and other trends, project availability and skills, and
                                specializations needed for these projects. We may also work with trusted third parties
                                to perform this research;
                            </li>
                            <li>To conduct polls and surveys; and</li>
                            <li>To investigate, respond to, and resolve complaints and issues related to the Services.
                            </li>
                        </ul>
                        <p><u>Social Actions and Profile Information:</u> Subject to your setting opted by you, users
                            may share information through social actions like posts, likes, follows, and comments. Such
                            information may include your name and photograph. Articles or posts shared by you can be
                            viewed, reshared, and found by other users. Users may even be able to view posts shared by
                            you on our Website and Communication Facilities. Further, any information you share through
                            any pages such as Business pages and Community pages on our Website will be viewable by others
                            who visit such pages. Every time you follow a person or organization, you become visible to
                            them as a follower. When you engage in communication and send messages, we let you know when
                            the receiver of the message has acted on it. Further, Members are also notified when a user
                            views their profile. Subject to your settings, other platforms/websites may view your
                            profile. When you opt to link your account with other platforms/websites, they may view your
                            Submitted Information. The sharing and use of such Submitted Information by these
                            platforms/websites will be described in or linked to another page if you opt to link your
                            account on our Website with your account on any other platforms/websites. For example, you
                            may link your 'Google' account to share content from our Website. Please note that such
                            third-party platforms/websites have their own privacy policies and the use of your
                            information by such platforms/websites will be governed by their privacy policies.</p>

                        <h5>(B) Aggregate Information:</h5>
                        <p>We collect Aggregate Information to diagnose technical problems on our Website, to help us
                            administer the Website, to permit us to provide data regarding the volume of use on, and
                            frequency of visitors to, the Website, to gather broad demographic information and to
                            improve our Website to assure that it is as appealing as we can make it for as many people
                            as possible. </p>
                        <p>We may also use User Information such as your demographic data to produce and share insights
                            that do not identify you in order to generate statistics about our Members or publish
                            demographics about our visitors for the Services or create demographic workforce
                            insights.</p>
                        <p>We use your User Information (including your communication) for security purposes or to
                            prevent or investigate possible fraud or other violations of our <a href="/UserAgreement">User
                                Agreement</a>, <a href="/TermsConditions">Terms and Conditions</a>, <a
                                href="/CommunityGuidelines">Community Guidelines</a>, and/or attempts to harm our
                            Members, users, or others.</p>
                        <p>We also maintain archives of the jobs/assignment/projects/events/training related information
                            on the Website. Members may access or view such archives from the options menu on the
                            Website.</p>
                        <p>Whether or not you provide User Information is completely your own choice. You are not
                            obligated to answer any question that is presented to you. Of course, if you choose not to
                            provide User Information, you might be unable to access certain Services, offers, and
                            Content on our Website.</p>

                        <div className="text-center"><h2>3. DISCLOSURE OF INFORMATION</h2></div>
                        <p>We may disclose your Submitted Information to (i) the Company's current and future
                            entertainment and sponsorship, advertisement and development partners and strategic
                            alliances, and (ii) the Company's contractors, vendors, consultants and professional
                            advisors to the extent necessary for them to perform services on our behalf, and only under
                            disclosure restrictions. Further, the Website and other websites operated by the Company and
                            its affiliates ("<strong>Associate Websites</strong>") share infrastructure, systems, and
                            technology to provide a relevant, consistent, and safe experience across all our content and
                            services. We may use User Information for the content and services of our Associate
                            Websites, to develop the Services and to understand how users of the Website and Associate
                            Websites use and interact with our content and services. </p>
                        <p>Furthermore, while it is the Company's policy to respect the privacy of its users, including
                            information about user's use of the Services, including the contents of users' email and
                            messages, the Company reserves the right to monitor, edit or disclose any User Information
                            about the user, his/her use of the Services, or such email contents, in the event that we
                            believe that such action is necessary in order to (i) conform to legal requirements or
                            comply with legal process or when required by law or a court order or to investigate,
                            prevent or take action regarding suspected or actual illegal activities or to assist
                            government enforcement agencies; (ii) to protect and defend the Company's rights; (iii) to
                            enforce any agreement you have or may have with the Company; (iv) to act to protect the
                            interests of our stakeholders and others (v) to investigate and defend the Company, Website
                            and/or Associate Websites against any third-party claims or allegations; (vi) to protect the
                            security or integrity of our Website; or (vii) to exercise or protect the rights and safety
                            of our users, personnel or others. </p>
                        <p>Additionally, we may transfer your User Information as part of the assets of the Company, if
                            the Company is, or parts of its business are, sold to, merged with, or acquired by, a third
                            party. </p>

                        <div className="text-center"><h2>4. LEGAL BASIS FOR USING THIS INFORMATION</h2></div>
                        <p>We have appropriate legal bases on which to collect, use, and share User Information about
                            you. This may include consent (where you have given consent) and contract (where processing
                            is necessary for the performance of a contract with you, such as delivering our core
                            services to you).</p>
                        <h5>(A) Performance of a contract:</h5>
                        <p>We need you to give us the User Information you provide and to collect information about you
                            from third parties for the purposes of: </p>
                        <ul>
                            <li>Giving you access to the Website;</li>
                            <li>Providing the Services to you;</li>
                            <li>Enabling us to provide a secure Website to our users; and</li>
                            <li>Analyzing our users' behavior and preferences, and information collected from users'
                                devices in order to have the information required to be able to improve the Services we
                                provide to our users and the user experience on our Website.
                            </li>
                        </ul>

                        <h5>(B) Consent:</h5>
                        <p>We will always seek your consent to process certain types of Submitted Information where we
                            are legally required to do so. Where we rely on your consent to process Submitted
                            Information, you have the right to withdraw or decline your consent at any time. This will
                            not affect the lawfulness of the processing of your Submitted Information before your
                            withdrawal of the same, if any. Any change in consents to processing the Submitted
                            Information shall not apply retrospectively and shall be implemented by us
                            prospectively.</p>

                        <div className="text-center"><h2>5. SECURITY</h2></div>
                        <p>Where we have given you (or where you have chosen) a login ID and password which enables you
                            to access your account on the Website, you are responsible for keeping this password
                            confidential. We ask you not to share your password with anyone.</p>
                        <p>We implement security safeguards designed to protect your data, such as HTTPS. Protecting the
                            security of your User Information that you share with us is of the utmost importance to us.
                            Unfortunately, however, no data transmission over the Internet or data storage can be
                            guaranteed to be 100% secure. As a result, while we strive to protect your User Information,
                            we cannot guarantee or warrant the security of any User Information you transmit to or from
                            our Website, and you do so at your own risk.</p>

                        <div className="text-center"><h2>6. COOKIES</h2></div>
                        <p>Our Website uses 'cookies' " to collect data to recognize you and your devices and the
                            devices where you have accessed the Services. Our policy on the manner and mode in which we
                            collect data using cookies is outlined in the <a href="/CookiePolicy">Cookie Policy</a>.
                            The <a href="/CookiePolicy">Cookie Policy</a> is incorporated into this Privacy Policy by
                            reference.</p>


                        <div className="text-center"><h2>7. LINKS TO THIRD PARTY SITES OR PRODUCTS</h2></div>
                        <p>Our Website may contain links to sites operated and maintained by third parties. The Company
                            has no control whatsoever over such third parties, their sites, or their products or
                            services. Privacy policies for such linked sites may be different from our privacy policy.
                            You access such linked sites or products or services at your own risk. You should always
                            read the privacy policy for a linked site or product or service before disclosing any
                            personal information on such site or in exchange for such product or service.</p>

                        <div className="text-center"><h2>8. DELETING INFORMATION ABOUT YOU</h2></div>
                        <p>We also offer you settings to control and manage the Submitted Information we have about you.
                            You may change or correct your Submitted Information on your account and profile or ask us
                            to change, update or fix it. You may also procure a copy of certain portions of your
                            Submitted Information available on the Website in a machine-readable form for your personal
                            records including Submitted Information such as your profile information, your chats and
                            messages, and information related to projects and research.</p>
                        <p>Please note that any Submitted Information that you may remove or delete from our Website may
                            continue to appear on or be retained by a third-party website or platform over which we have
                            no control. You agree that we shall in no manner whatsoever be held liable or responsible
                            for any Submitted Information that is retained and/or continues to appear on any third-party
                            website and/or platform.</p>

                        <div className="text-center"><h2>9. HOW LONG WE KEEP YOUR INFORMATION</h2></div>
                        <p>We generally retain your Submitted Information as long as you keep your account open or as
                            needed to provide you the Services. While your account remains active, we keep your
                            Submitted Information for no longer than is necessary depending on the purpose for which we
                            are using it. However, we may retain your Submitted Information: (i) conform to legal
                            requirements or comply with legal process or when required by law or court order; (ii) to
                            protect and defend the Company's rights; (iii) to enforce any agreement you have or may have
                            with the Company; (iv) to act to protect the interests of our stakeholders and others; (v)
                            to maintain security and prevent fraud and abuse; or (vi) fulfill your request to
                            unsubscribe from further messages from us.</p>
                        <p>Submitted Information you have shared with other users will remain visible after you close
                            your account or delete the information from your own profile or mailbox. Please note that we
                            do not control any data that any other user may have copied from our Services. Groups
                            content, Communities content, and ratings or review content associated with closed accounts may
                            show your name, but this will not be clickable and any link from the same will be disabled.
                            Your profile may continue to be displayed in the services of others (e.g., search engine
                            results) until they refresh their cache.</p>
                        <p>How long we keep your User Information will depend on the purpose for which we use it. While
                            you are a Member, we will only retain your User Information for as long as is necessary for
                            those purposes.</p>
                        <p>After termination of your account, we may continue to use the Aggregate Information (which
                            does not identify individual users) which is aggregated with anonymized data of other users.
                            We use this Aggregated Information for data analysis, profiling, and research purposes, for
                            example, to gain insights about our users. We may also keep your email address to ensure
                            that you no longer receive any communications from us as well as your name, date of birth
                            and latest address for fraud prevention purposes and for the exercise or defense of a legal
                            claim.</p>

                        <div className="text-center"><h2>10. DISPUTE RESOLUTION</h2></div>
                        <p>In the event of any dispute, difference or claim arising out of this Privacy Policy the same
                            shall be settled in accordance with the laws of the Republic of India through a regular
                            judicial process and the courts of India shall have exclusive jurisdiction.</p>

                        <div className="text-center"><h2>11. CONTACT</h2></div>
                        <p>Questions, comments, and requests regarding our Privacy Policy or the User Information we
                            hold about you are welcomed and should be sent to <a href="mailto:support@wenaturalists.com"
                                                                                 target="_blank">support@wenaturalists.com</a>.
                        </p>

                        <div className="text-center"><h2>12. GRIEVANCE OFFICER</h2></div>
                        <p>In accordance with Information Technology Act 2000 and rules made there under, the name and
                            contact details of the Grievance Officer are provided below:</p>
                        <p>Name – Nicole Menezes<br/>
                            Company Name – Dscovr Journeys Private Limited<br/>
                            Address : 505, Acme Plaza, Andheri Kurla Road, Chakala, Andheri East, Mumbai 400059,
                            Maharashtra, India.<br/>
                            Phone - +91 9920605555<br/>
                            Email Address – <a href="mailto:grievance@wenaturalists.com"
                                               target="_blank">grievance@wenaturalists.com</a></p>
                        <p>Should you have any complaints or grievances regarding the Website, you can notify us by
                            writing to us on the contact details mentioned above and we shall endeavor to redress the
                            complaints at the earliest.</p>
                    </div>
                </section>

            </div>
        </main>
    }

    namespace.acceptCookies = () => {
        return <main className="dgcontainer hm3col">
            <div className="container">

                <section className="globalinn">
                    <div className="pagetitle bkstatcpgttl text-center">
                        <h1>COOKIE POLICY</h1>
                    </div>

                    <div className="globalPgCont">
                        <p>This Cookie Policy explains how we use cookies provided to us through or in association
                            with your use of the website www.wenaturalists.com ("<strong>Website</strong>"), its
                            related features and functions and content as defined in the <a href="/TermsConditions">Terms
                                and Conditions</a> and also explains how we use similar technologies to collect and
                            use data as part of our Services and which includes our sites, communications, mobile
                            applications and off-site Services, such as our ad services and the "Apply with
                            WeNaturalists" and "Share with WeNaturalists" plugins or tags
                            ("<strong>Technologies</strong>"). In the spirit of transparency, this Cookie Policy
                            provides detailed information about how and when we use these Technologies.</p>
                        <p>Please note that any users and third parties such as our customers, partners, and service
                            providers may also use cookies and similar Technologies, over which we have no
                            control.</p>
                        <p>You can block cookies by activating the setting on your browser which allows you to
                            refuse the setting of all or some cookies. However, if you use your browser settings to
                            block all cookies (including essential cookies) you may not be able to access all or
                            parts of the Website. Unless you have adjusted your browser setting so that it will
                            refuse cookies, our system will issue cookies as soon you visit the Website. Further,
                            some browsers may allow you to review and erase cookies as well, including the cookies
                            related to this Website. By visiting this Website and continuing to use this Website you
                            are agreeing to the use of Technologies for the purposes described in this Cookie
                            Policy. If you do not agree please do not use or access our Website.</p>
                        <p>This Cookie Policy is subject to change at any time without notice. The Company reserves
                            all rights to change, modify, update, or alter ("update") this Cookie Policy at any
                            point of time with or without notifying you. Your continued use of the Website means
                            that you accept any updated terms and conditions that we come up with. If you do not
                            wish to accept any update to this Cookie Policy, then you shall immediately stop
                            accessing and/or using the Website. To make sure you are aware of any changes, please
                            review this Cookie Policy periodically.</p>
                        <p>This Cookie Policy is incorporated into and subject to the <a href="/PrivacyPolicy">Privacy
                            Policy</a>.</p>


                        <div className="text-center"><h2>1. TECHNOLOGIES USED</h2></div>
                        <h5>(A) Cookies:</h5>
                        <p>Our Website uses "cookies" to collect data to recognize you and your devices and devices
                            where you have accessed the Services (as defined in the <a href="/PrivacyPolicy">Privacy
                                Policy</a>). A cookie is a small amount of data that is sent to your browser from an
                            Internet server and stored on your computer. It enables the Website features and
                            functionality. Any visitor may receive cookies from us or cookies from third parties
                            such as our customers, partners, or service providers. We or third parties may also
                            place cookies in your browser when you visit third-party sites that display ads, host
                            our plugins (for example, the "Share with WeNaturalists" button) or tags.</p>
                        <p>Generally, we use cookies to remind us of who you are, tailor the Content and the
                            Services to suit your personal interests, estimate our audience size, track your status
                            in our promotions, and/or analyzing your visiting patterns. We also collect (or rely on
                            others who collect) information about your device (such as IP address, operating system,
                            and browser information) where you have not engaged with the Content so we can provide
                            our users with relevant ads and better understand their effectiveness.</p>
                        <p><u>We use two types of cookies: </u></p>
                        <ul className="listroman">
                            <li><strong>Persistent Cookies</strong>: A persistent cookie may help us recognize you
                                as an existing user, so it's easier to return to our Website without having to log
                                in again. A persistent cookie stays in your browser and will be read by us when you
                                return to our Websites or an Associate Website (as described in the <a
                                    href="/PrivacyPolicy">Privacy Policy</a>).
                            </li>
                            <li><strong>Session Cookies</strong>. Session cookies last only as long as the session
                                (usually the current visit to a website or a browser session).
                            </li>
                        </ul>
                        <h5>(B) Pixels:</h5>
                        <p>A pixel is a tiny image that may be found within web pages and emails, requiring a call
                            (which provides device and visit information) to our servers in order for the pixel to
                            be rendered in those web pages and emails. We use pixels to learn more about your
                            interactions with email content or web content, such as whether you interact with ads or
                            posts. Pixels can also enable us and third parties to place cookies on your browser.</p>
                        <h5>(C) Local Storage:</h5>
                        <p>Local storage allows an application or a website to store information locally on your
                            device(s). We may use local storage to improve the experience when you use our Website
                            and to, inter alia, remember your preferences, speed up the functionality of our Website
                            and to enable certain features on our Website.</p>
                        <h5>(D) Other Similar Technologies:</h5>
                        <p>Please note that we also use other tracking technologies for similar purposes as
                            described in this Cookie Policy. Such similar technologies may include mobile
                            advertising IDs and tags. The similar technologies referred to in this Cookie Policy
                            include pixels, local storage, and other tracking technologies.</p>
                        <p>Please note that the names of Technologies may change over time.</p>

                        <div className="text-center"><h2>2. USE OF TECHNOLOGIES</h2></div>
                        <p><u>The Technologies mentioned herein are used for the following purposes:</u></p>
                        <h5>(A) For Authentication Purposes:</h5>
                        <p>We use Technologies to recognize you when you visit our Website. If you are a member (as
                            described in the <a href="/PrivacyPolicy">Privacy Policy</a>) and are logged into your
                            account on our Website, the technologies, we use, assist us in showing you the correct
                            information, help us to personalize your experience in line with your settings and allow
                            us to identify you and verify your account.</p>
                        <h5>(B) For Security Purposes:</h5>
                        <p>We use Technologies to make your interactions with our Website faster and more secure
                            such as to enable and support our security features and functions, ensure the safety of
                            your account when you register as a Member on our Website and to help up identify any
                            suspicious and/or malicious activities and violation of our <a href="/UserAgreement">User
                                Agreement</a>.</p>
                        <h5>(C) For our Preferences, Features, and Services:</h5>
                        <p>We use Technologies to enable the functionality of our Website. This includes, inter
                            alia, assisting you in filling out forms with more ease on our Website and providing you
                            with features, insights, and customized content in line with our plugins. We also use
                            Technologies to remember information about your browser and your preferences such as
                            your language of preference and your communications preferences. We may also use local
                            storage to speed up our Website functionality.</p>
                        <h5>(D) For Customized Content:</h5>
                        <p>We use Technologies to customize your experience on our Website. For example, we may use
                            cookies to remember previous searches so that when you return to our Website, we can
                            offer additional information that relates to your previous search.</p>
                        <h5>(E) For Plug-ins On And Off Our Website:</h5>
                        <p>We use Technologies to enable our Website plugins both on and off our Website and
                            Associate Websites.</p>
                        <p>For example, our plugins, including the "Apply with WeNaturalists" button or the "Share"
                            button may be found on our Website or Associate Websites or third-party sites. Our
                            plugins use Technologies to provide analytics and to recognize you on our Website,
                            Associate Websites, and third-party sites. If you interact with a plugin, the plugin
                            will use cookies to identify you and initiate your request to apply.</p>
                        <h5>(F) For Advertising Purposes:</h5>
                        <p>Technologies help us show relevant advertising to you more effectively, both on and off
                            our Website and/or Associate Websites, and to measure the performance of such ads. We
                            use these Technologies to learn whether the content has been shown to you or whether
                            someone who was presented with an ad later came back and took an action (e.g. made a
                            purchase) on another site. Similarly, our partners or service providers may use these
                            Technologies to determine whether we have shown an ad or a post and how it performed or
                            provide us with information about how you interact with ads.</p>
                        <h5>(G) For Performance, Analytics, and Research Purposes:</h5>
                        <p>Technologies help us learn more about how well our Website and plugins perform in
                            different locations. We or our service providers may use Technologies to understand,
                            improve, and research products, features, and services. We may even use Technologies to
                            understand the manner in which you navigate through our Website and/or Associate
                            Websites or when you access our Website from other sites, applications, or devices.</p>
                        <p>Further, we or our service providers use Technologies to determine and measure the
                            performance of ads or posts on and off our Website and to learn whether you have
                            interacted with our Associate Websites, content or emails and to then provide analytics
                            based on those interactions.</p>
                        <p>We also use Technologies to provide aggregated information to our customers and partners
                            as described in our <a href="/PrivacyPolicy">Privacy Policy</a>.</p>

                        <div className="text-center"><h2>3. THIRD PARTIES USING TECHNOLOGIES IN CONNECTION WITH OUR
                            WEBSITE</h2></div>
                        <p>Third parties such as our customers, partners, and service providers may use Technologies
                            in connection with our Website and for purposes which may include their own marketing
                            purposes. Further, we may also work with third parties to help us provide our Website
                            and for our own marketing purposes.</p>
                        <p>Third parties may also use Technologies in connection with our off-site Website, such as
                            WeNaturalists ad services, plugins, or tags. </p>

                        <div className="text-center"><h2>4. YOUR CHOICES</h2></div>
                        <p>You have choices on how we use Technologies. However, any limitations you set on our
                            ability to use Technologies may affect your experience using our Website and its
                            features and functions and our Website may also no longer be personalized to you. You
                            may also be restricted from saving your custom settings or preferences such as your
                            account log in information.</p>

                        <div className="text-center"><h2>5. OPT-OUT OF TARGETED ADVERTISING</h2></div>
                        <p>You have choices regarding the personalized ads you may see. Some mobile device operating
                            systems such as Android and iOS provide the ability to opt-out of the use of mobile
                            advertising IDs for ads personalization. You can learn how to use these controls by
                            visiting the manufacturer's website for both Android and iOS.</p>

                        <div className="text-center"><h2>6. BROWSER CONTROLS</h2></div>
                        <p>Most browsers allow you to control cookies through their settings, which may be adapted
                            to reflect your consent to the use of cookies. Further, most browsers also enable you to
                            review and erase cookies, including our Website cookies. To learn more about browser
                            controls, please consult the documents that your browser manufacturer provides.</p>
                    </div>
                </section>

            </div>
        </main>
    }

    namespace.termsAndCondition = () => {
        return <main className="dgcontainer hm3col">
            <div className="container">

                <section className="globalinn">
                    <div className="pagetitle bkstatcpgttl text-center">
                        <h1>TERMS AND CONDITIONS</h1>
                    </div>

                    <div className="globalPgCont">
                        <p>This section contains the Terms and Conditions of www.wenaturalists.com
                            ("<strong>Website</strong>") which is owned and operated by Dscovr Journeys Private Limited
                            ("<strong>Company</strong>" or "<strong>we</strong>" or "<strong>us</strong>"). </p>
                        <p>Our Website allows nature enthusiasts and professionals, institutions and government bodies
                            to engage with each other, share experiences and learning, create economic opportunity, and
                            grow with a network of trusted relationships. By using this Website and any of its pages,
                            you ("<strong>you</strong>" or "<strong>user</strong>") agree to and shall abide by all of
                            the terms and conditions set forth below which shall be binding on you, your heirs,
                            executors, administrators and/or assigns. In the event you do not agree to any of the terms
                            and conditions mentioned herein, we advise you to stop using this Website.</p>
                        <p>The Company allows you to access the Website and all contents provided on this Website
                            including all information, software, graphics, function, and other materials are subject to
                            the terms and conditions outlined below. By accessing or using the Website or any part
                            thereof, you and the entity you are authorized to represent are explicitly agreeing to the
                            terms and conditions below. The Company reserves all rights to change, modify, update, or
                            alter ("<strong>update</strong>") these Terms and Conditions at any point of time with or
                            without notifying you. Your continued use of the Website means that you accept any updated
                            terms and conditions that we come up with. If you do not wish to accept any update to these
                            Terms and Conditions, then you shall immediately stop accessing and/or using the Website.
                            Please re-visit the Terms and Conditions at our Website from time to time to stay abreast of
                            any changes that we may introduce.</p>

                        <div className="text-center"><h2>USER ELIGIBILITY</h2></div>
                        <p>The Website is provided by the Company and available only to entities and persons who have
                            reached the age of legal majority and are competent to enter into legally binding
                            agreement(s) under the applicable law. Further, you must not be restricted by the Company
                            from accessing the Website. If you do not qualify, you are not permitted to use the
                            Website.</p>

                        <div className="text-center"><h2>SCOPE</h2></div>
                        <p>Your use of the Website is governed by these Terms and Conditions, except to the extent that
                            certain terms are the subject of a separate agreement. Specific terms or agreements may
                            apply to the use of certain services and other items provided to you via the Website. Any
                            such agreements will accompany the applicable services or are listed in association
                            therewith or via a hyperlink associated therewith.</p>

                        <div className="text-center"><h2>PRIVACY POLICY AND USER AGREEMENT</h2></div>
                        <p>The use of our Website is also governed by our <a href="/PrivacyPolicy">Privacy
                            Policy</a> and the <a href="/UserAgreement">User Agreement</a>. The <a
                            href="/PrivacyPolicy">Privacy Policy</a> covers the use of cookies and the <a
                            href="/UserAgreement">User Agreement</a> governs the use of the Website by those users of
                            this Website who have created an account on this Website and can access all of the services
                            provided by us. The <a href="/PrivacyPolicy">Privacy Policy</a> and <a
                                href="/UserAgreement">User Agreement</a> are incorporated into these Terms and
                            Conditions by reference.</p>

                        <div className="text-center"><h2>INTELLECTUAL PROPERTY</h2></div>
                        <p>All the content and information provided on the Website in any form and capable of being
                            stored on a computer that appears on, or forms part of, including its any trademarks,
                            information related to any services, contact details, graphics, functions and other
                            materials available, whether publicly posted or privately transmitted as well as all
                            derivative works thereof; including the Website's design structure and compilation and all
                            content such as text, images, audio, video, scripts, code, documents, databases, the mascot
                            and the source code ("<strong>Content</strong>"), belongs to the Company or its content
                            partners or licensors.</p>
                        <p>The Company or its partners/licensors own all intellectual property rights (including
                            copyright and database rights) in the Content. You agree to abide by the copyright law and
                            all other applicable laws of India and acknowledge that the Website and the Content in
                            source code form remains a confidential trade secret of the Company, therefore, you agree
                            not to modify the Website and/or Content or attempt to decipher, decompile, disassemble or
                            reverse engineer the Website and/or the Content, except to the extent applicable laws
                            specifically prohibit such restriction.</p>
                        <p>You may retrieve and view the Content on a computer screen, PDA or mobile telephone, print
                            individual pages on paper (but not photocopy them) and store such pages in electronic form
                            on disk or your mobile telephone (but not on any server or other storage device connected to
                            a network) for your personal, non-commercial use.</p>
                        <p>You shall not use the Website for any unlawful purpose.</p>
                        <p>Except as indicated above, you shall not reproduce, publish, broadcast, transmit, modify,
                            adapt, create derivative works of, store, archive or in any way commercially exploit any of
                            the Content. Without limitation, you shall not do any of the following without prior written
                            permission from us (and neither shall you allow a third party to do any of the same):</p>
                        <ul>
                            <li>Violate the intellectual property rights of others, including copyrights, patents,
                                trademarks, trade secrets, or other proprietary rights. For example, you may not copy or
                                distribute the posts or contents of other users of the Website;
                            </li>
                            <li>Violate the intellectual property or other rights of the Company, including, without
                                limitation, (i) copying or distributing our Content or (ii) copying or distributing our
                                technology, unless it is released under open-source licenses; (iii) using our trademark,
                                tradename, and logos such as 'WeNaturalists' in any business name, email, or URL;
                            </li>
                            <li>Create a database in electronic or structured manual form by systematically downloading
                                and storing all or any of the Content;
                            </li>
                            <li>Deep-link to, frame, spider, harvest or scrape the Content or otherwise copy profiles
                                and other data from the Content or otherwise access the Content for similar purposes;
                            </li>
                            <li>Use any machine, electronic, web-based or similar device to read or extract the Content
                                by automated techniques; or
                            </li>
                            <li>Use the Content in a searchable, machine-readable database.</li>
                        </ul>
                        <p>The Website as well as the Content is the valuable, exclusive property of the Company, and
                            nothing therein shall be construed as transferring or assigning any such ownership rights to
                            you or any other person or entity. All Content is the proprietary, confidential property of
                            the Website and cannot be repeated for any reason outside the Website, without our prior
                            consent. You agree not to repeat or rebroadcast in any way any of the Content for any reason
                            whatsoever. You agree that if you do repeat or re-post any of the Content by any means, you
                            will be exclusively liable under criminal law and shall also be liable for actual and
                            punitive damages as determined by the Company and additional damages to be determined by a
                            court of law.</p>
                        <p>You shall not rent, lease, sublicense, distribute, transfer, copy, reproduce, publicly
                            display, publish, adapt, store, monetize or time-share the Website, any part thereof, or any
                            of the Content and related data received or accessed therefrom to or through any other
                            person or entity unless separately and specifically authorized in writing by the Company
                            prior to such act. In addition, you shall not remove, alter or obscure any copyright, legal
                            or proprietary notices in or on any portions of the Website without prior written
                            authorization.</p>

                        <div className="text-center"><h2>RESTRICTIONS AND PERMISSIONS ON USE</h2></div>
                        <p>In addition to other restrictions set forth in these Terms and Conditions, you agree
                            that:</p>
                        <ul>
                            <li>You will not violate the <a href="/CommunityGuidelines">Community Guidelines</a>;</li>
                            <li>You will not place false or misleading information on the Website;</li>
                            <li>You will not use or access any service, information, application or software available
                                via the Website in a manner not expressly permitted by us;
                            </li>
                            <li>You shall not disguise the origin of information transmitted through the Website or
                                disclose any information that you do not have the consent to disclose;
                            </li>
                            <li>You will not input or upload to the Website any information that may contain viruses,
                                Trojan horses, worms, time bombs or other computer programming routines that are
                                intended to damage, interfere with, intercept or expropriate the Website or the Content
                                or that infringes the intellectual property rights of another;
                            </li>
                            <li>You will not copy, use, disclose or distribute any information obtained from the
                                Content, whether directly or through third parties (such as search engines), without the
                                consent of the Company;
                            </li>
                            <li>You will not imply or state that you are affiliated with or endorsed by the Company
                                without our express consent;
                            </li>
                            <li>You will not use bots or other automated methods to access the Content, add or download
                                contacts, send or redirect messages;
                            </li>
                            <li>You will not monitor the Content availability, performance or functionality for any
                                competitive purpose;
                            </li>
                            <li>You will not engage in "framing," "mirroring," or otherwise simulating the appearance or
                                function of the Content;
                            </li>
                            <li>You will comply with all applicable laws related to your use and access of the Website
                                and the Content;
                            </li>
                            <li>You will use the Content in a professional and responsible manner;</li>
                            <li>You expressly undertake not to host, display, upload, modify, publish, transmit, update
                                or share any information that is (i) grossly harmful, harassing, blasphemous defamatory,
                                obscene, pornographic, paedophilic, libelous, invasive of another's privacy, hateful, or
                                racially, ethnically objectionable, disparaging, relating or encouraging money
                                laundering or gambling, or otherwise unlawful in any manner whatever; (ii) harms minors
                                in any way; (iii) impersonates another person; (iv) threatens the unity, integrity,
                                defense, security or sovereignty of India, friendly relations with foreign states, or
                                public order or causes incitement to the commission of any cognizable offense or
                                prevents investigation of any offense or is insulting any other nation.
                            </li>
                        </ul>

                        <div className="text-center"><h2>VIOLATIONS</h2></div>
                        <p>You acknowledge that non-compliance of the aforesaid obligations and/or the rules,
                            regulations, terms, conditions, agreements, <a href="/UserAgreement">User
                                Agreement</a> and <a href="/PrivacyPolicy">Privacy Policy</a> applicable to the access
                            and usage of the Website, shall give the Company the right to immediately terminate the
                            access or usage rights of the users to the Website and remove non-compliant information.</p>
                        <p>Any violation of these Terms and Conditions may result in civil or criminal liability, and
                            the Company may, in addition to any remedy that it may have at law or in equity, immediately
                            terminate permission for any user or, if necessary, for all users to use the Website. The
                            Company reserves the right to terminate or move material, information, messages, or
                            communications that, in the Company's sole discretion, may be illegal, or that may subject
                            the Company to liability or that may violate these Terms and Conditions.</p>
                        <p>The Company may investigate any suspected or alleged violation of these Terms and Conditions
                            and cooperate with the law, law enforcement authorities and/or third parties in such
                            investigation.</p>

                        <div className="text-center"><h2>LINKS</h2></div>
                        <p>The links on this Website may allow you to leave the Website. Any third-party site links on
                            this Website are only for your convenience and the linked sites are not under the control of
                            the Company. The Company has not reviewed, nor approved these sites and is not responsible
                            for the contents or omissions of any linked site or any links contained in a linked site.
                            The Company does not warrant or guarantee the availability, completeness, correctness,
                            accuracy, usefulness, timeliness, or reliability of, or the results of your use of or
                            reliance upon, any information or material contained in such third-party sites. If any link
                            is misleading or changed or removed, we are not responsible for that either. You acknowledge
                            that you access and use such third-party sites solely at your own risk. You agree that under
                            no circumstances will the Company be liable in any way for any information or material
                            contained in any third-party sites linked to or from its site. The Company is not
                            responsible for any errors or omissions therein, or for any loss or damage of any kind
                            incurred as a result of your use of or reliance upon any information or material contained
                            in such third-party sites.</p>

                        <div className="text-center"><h2>SUBMISSIONS</h2></div>
                        <p>All questions, comments, suggestions, feedback, or other information
                            ("<strong>Comments</strong>") submitted to the Company via the Website shall be deemed and
                            remain the property of the Company. The Company and its affiliates shall be free to use, for
                            any purpose, any ideas, concepts, know-how, or techniques contained in such Comments. You
                            hereby grant us and our affiliates, a worldwide, perpetual, irrevocable, royalty-free
                            license to reproduce, display, perform, distribute, publish, modify, edit or otherwise use
                            such Comments as we deem appropriate, for any and all commercial and/or non-commercial
                            purposes, in our sole discretion. The Company shall not be subject to any obligations of
                            confidentiality regarding such Comments except where specifically agreed in the <a
                                href="/PrivacyPolicy">Privacy Policy</a>.</p>

                        <div className="text-center"><h2>DISCLAIMERS</h2></div>
                        <h5><u>Usage Disclaimer:</u></h5>
                        <p>You expressly agree that use of the Website is at your sole risk.</p>
                        <p>The Company and/or its respective suppliers may make improvements and/or changes in this
                            Website and/or the Content at any time. This Website and the Content may be temporarily
                            unavailable from time to time due to required maintenance, telecommunications interruptions,
                            or other disruptions. The Company shall not be liable to you or any third party should the
                            Company exercise its right to modify, suspend or discontinue any or all of the Content on
                            this Website. </p>
                        <p>The Company and/or its respective associated entities make no representations about the
                            suitability of the Content for any purpose. All such Content is provided "as is" without
                            warranty of any kind. The Company hereby disclaim all warranties and conditions with regard
                            to these Content including all implied warranties and conditions of merchantability, fitness
                            for a particular purpose, title, non-infringement, and availability.</p>
                        <h5><u>Information Disclaimer:</u></h5>
                        <p>You acknowledge that the information provided through the Website is compiled from sources,
                            which are beyond the control of the Company. Though such information is recognized by the
                            parties to be generally reliable, the parties acknowledge that inaccuracies or typographical
                            errors may occur, and the Company does not warrant the accuracy of the Content. For this
                            reason, as well as the possibility of human and mechanical errors and other factors, you
                            acknowledge that the Website is provided to you on an "as is, with all faults" basis. The
                            Company expressly disclaims any and all warranties, whether express, oral, implied,
                            statutory or otherwise, of any kind to the users and/or any third party, including any
                            implied warranties of accuracy, timeliness, completeness, merchantability, and fitness for a
                            particular purpose, as well as any warranties arising by virtue of custom of trade or course
                            of dealing and any implied warranties of title or non-infringement. </p>
                        <p>You assume all risk of errors and/or omissions on the Website, including the transmission or
                            translation of information. You assume full responsibility for implementing sufficient
                            procedures and checks to satisfy your requirements for the accuracy of the Website,
                            including the information, and for maintaining any means, which you may require for the
                            reconstruction of lost data or subsequent manipulations or analyses of the information.</p>

                        <div className="text-center"><h2>LIMITATION OF LIABILITY</h2></div>
                        <p>To the extent permitted by applicable law and to the extent that the Company is otherwise
                            found to be responsible for any damages, we shall be responsible for actual damages only. To
                            the extent permitted by law, in no event shall the Company, its affiliates, licensors,
                            suppliers or any third parties mentioned at the Website be liable for any incidental,
                            indirect, exemplary, punitive and/or consequential damages, lost profits, and/or damages
                            resulting from lost data or business interruption resulting from the use of and/or inability
                            to use the Website or the Content whether based on warranty, contract, tort, delict, or any
                            other legal foundation, and whether or not the Company is advised of the possibility of such
                            damages. To the extent permitted by law, the remedies stated for you in these Terms and
                            Conditions are exclusive and are limited to those expressly provided for herein.</p>

                        <div className="text-center"><h2>COMPLIANCE WITH LAW</h2></div>
                        <p>You agree to use the Website in strict compliance with all applicable laws, rulings, and
                            regulations and in a manner that does not, in the sole discretion of the Company, reflect
                            negatively on the goodwill or reputation of the Company. You agree to take no action which
                            might cause the Company to be in breach of any laws, rulings, or regulations applicable to
                            us.</p>
                        <p>The Company and the Website are based in India. India and certain other jurisdictions control
                            the export of products and information. You agree to comply with all such applicable
                            restrictions and not to export or re-export the Content (including any software or any
                            services) to countries or persons prohibited under India or other applicable export control
                            laws or regulations. If you access and download the Content (including any software or any
                            services), you represent that you are not in a country where such export is prohibited or
                            are not a person or entity to which such export is prohibited. You are solely responsible
                            for compliance with the laws of your local jurisdiction and any other applicable laws
                            regarding the import, export, or re-export of the Content (including any software or any
                            services).</p>


                        <div className="text-center"><h2>INDEMNIFICATION</h2></div>
                        <p>You shall indemnify, defend and hold harmless the Company (including its and their officers,
                            directors, employees, affiliates, group companies, agents, representatives or
                            subcontractors) from any and all claims and losses imposed on, incurred by or asserted as a
                            result of or related to: (a) your access and use of the Website (b) any non-compliance by
                            you with the terms and conditions hereof; or (c) any third party actions related to your
                            receipt and use of the Content, whether authorized or unauthorized.</p>

                        <div className="text-center"><h2>CONTACT INFORMATION</h2></div>
                        <p>For general inquiries, you may contact us online at <a
                            href="mailto:support@wenaturalists.com" target="_blank">support@wenaturalists.com</a>. For
                            notices or service of process, you may write to us at the following address:</p>
                        <p>Dscovr Journeys Private Limited<br/>
                            505, Acme Plaza,<br/>
                            Andheri Kurla Road, Andheri East,<br/>
                            Mumbai 400 059,<br/>
                            Maharashtra, India.</p>

                        <div className="text-center"><h3>GOVERNING LAW AND JURISDICTION</h3></div>
                        <p>These terms and conditions shall be governed by and construed in accordance with the laws of
                            the Republic of India. You explicitly agree that irrespective of the country you are a
                            citizen/resident of and whether or not you are a resident of India, you shall be bound by
                            the laws and regulations relating to data protection of the Republic of India. You
                            explicitly agree that only courts at Mumbai, Maharashtra, India shall have exclusive
                            jurisdiction over any disputes arising under hereunder.</p>

                        <div className="text-center"><h2>GRIEVANCE OFFICER</h2></div>
                        <p>In accordance with Information Technology Act 2000 and rules made thereunder, the name and
                            contact details of the Grievance Officer are provided below:</p>
                        <p>Name – Nicole Menezes<br/>
                            Company Name – Dscovr Journeys Private Limited<br/>
                            Address : 505, Acme Plaza, Andheri Kurla Road, Chakala, Andheri East, Mumbai 400059,
                            Maharashtra, India.<br/>
                            Phone - +91 9920605555<br/>
                            Email Address – <a href="mailto:grievance@wenaturalists.com"
                                               target="_blank">grievance@wenaturalists.com</a></p>
                        <p>Should you have any complaints or grievances regarding the Website, you can notify us by
                            writing to us on the contact details mentioned above and we shall endeavor to redress the
                            complaints at the earliest.</p>
                    </div>
                </section>

            </div>
        </main>
    }

    namespace.refundPolicy = () => {
        return <main className="dgcontainer hm3col">
            <div className="container">

                <section className="globalinn">
                    <div className="pagetitle bkstatcpgttl text-center">
                        <h1>Refund Policy</h1>
                    </div>

                    <div className="globalPgCont">
                        <p>Except as required by law, any payment made to WeNaturalists.com on account of fees, taxes or any other charges in any
                            manner is “non-refundable”. Please refer to the details below for further clarifications. </p>

                        <h2>WeNaturalists Premium Membership Subscription</h2>
                        <p>Any payment made towards WeNaturalists Premium Membership Subscription is non-refundable. In case of cancellation of
                            service, all premium features will continue to be available to the user until the end of the subscription period and
                            shall be discontinued upon the expiry of the subscription period. </p>

                        <p>WeNaturalists Premium Membership Subscription will be auto-renewed at the end of each subscription period if your
                            payment option permits such auto renewals. Therefore, you must make a request for cancellation before the expiry of the
                            current subscription cycle. </p>

                        <h2>WeNaturalists Sponsored Content/ Ads Payment</h2>
                        <p>Any payment made towards the promotion of content on WeNaturalists platform is non-refundable. </p>

                        <h2>Third-Party Payment</h2>
                        <p>In the event, the payment is made to a third party, via WeNaturalists.com, the payments and refund terms are governed by
                            terms and conditions of the respective service provider or third party. WeNaturalists acts as a market place
                            facilitating the payment process and is not responsible for the actions, delivery, decisions and policies of the third
                            party. </p>

                        <h2>Technical Issues</h2>
                        <p>User may face technical issues during payment resulting in double deduction or a failed payment. Any such deduction
                            shall be refunded by the respective bank and/ or the service provider as the case may be, subject to their respective
                            policies. However, you must right to WeNaturalists.com support [support link] within 12 hours of such deduction.</p>

                        <h5>Please Note:</h5>
                        <p>In the event of delay in raising an issue with WeNaturalists.com of double deduction or excess payment on account of 
                            the Premium Membership Subscription after 12 hours, the excess payment received shall be adjusted against additional 
                            subsequent period of subscription. </p>

                        <p>Similarly, in case of Excess payment on account of advertising on WeNaturalists ad network, the excess amount shall 
                            not be refunded but will be available as an advertising credit which can be used in future.</p>
                    </div>
                </section>

            </div>
        </main>
    }

    namespace.studioTermsConditions = () => {
        return <main className="dgcontainer hm3col">
            <div className="container">

                <section className="globalinn">
                    <div className="pagetitle bkstatcpgttl text-center">
                        <h1>Studio Terms & conditions</h1>
                    </div>

                    <div className="globalPgCont">
                        <h2>About WeNaturalists Studio</h2>
                        <p>WeNaturalists Studio is a feature that allows a user to monetize content generated /owned by the user. It can be across
                            media forms - video, audio, pictures; or documents. With the Studio, WeNaturalists aims to create a natural solution to
                            entrepreneurship, a path towards sustainability for the professional and organizations in nature.</p>

                        <h2>Who can post in a Studio?</h2>
                        <p>Subscribers to WeNaturalists Pro subscription under <em>Professional, Enterprise or Grande subscription</em> plan can post in
                            Studio.</p>

                        <h2>What is the validity of the Studio usage?</h2>
                        <p>The usage of Studio is valid until subscribers are subscribed to a required WeNaturalists Pro subscription plan. In
                            absence of a required subscription plan, the member shall not be able to post or transact in the studio.</p>
                        <p>In the event member decides against renewing the subscription, the Studio and its content shall remain intact should you
                            decide to renew subscription in future.</p>

                        <h2>Responsible Content Posting by Members</h2>
                        <p>WeNaturalists exists to spread nature consciousness, empower the people of nature, and provide them with an ecosystem
                            that improves our natural it is critical that Members of WeNaturalists are responsible for the content published in the
                            Studio, similarly anywhere else on WeNaturalists. WeNaturalists in no circumstance</p>

                        <h2>Content Ownership</h2>
                        <p>By using Studio as a feature on WeNaturalists, members undertake that the content belongs to the member and the member
                            is not infringing on any copyright.</p>

                        <h2>Members subscribing to the content posted in Studio must note that,</h2>
                        <p>WeNaturalists does not take responsibility of any content posted in studio nor confirms to its authenticity in any form.</p>
                        <p>WeNaturalists does not check, verifies or conforms to the ownership details of the content and hence cannot confirm if
                            the member has violated any law or if the content is infringed. Therefore, the members are advised to raise their
                            queries related to quality, ownership or delivery with the studio specific helpdesk or the project admin.</p>

                        <h2>Content must meet the WeNaturalists Community Guidelines</h2>
                        <p>Members using studio are expected to adhere to the <a href="/CommunityGuidelines">Community Guidelines</a>. Failing which they will be solely
                            responsible for any action by WeNaturalists, or by authorities under prevailing law of the land, or WeNaturalists
                            members through reported content.</p>

                        <h2>Responsibility towards the Commercial Transaction</h2>
                        <p>All commercial transactions between members related to the studio content are the sole responsibility of members.
                            WeNaturalists does not take any responsibility of commercial transaction, or assessment of the worth of the transacted
                            content in Studio. Members are independently responsible towards applicable laws related to a financial exchange and any
                            taxes associated with any transaction.</p>
                        <p>WeNaturalists does not participate in any transaction except providing its members with an interface to transact.</p>
                        <p>The subscribers of any such studio content is expected to contact Studio specific helpdesk for any query.</p>
                    </div>
                </section>

            </div>
        </main>
    }
    return namespace;
})();

export default StaticPagesCommonFunction;
