import React, { memo } from 'react';
import { authService } from "../auth";
import * as FileSaver from "file-saver";
import Scrollbars from 'react-custom-scrollbars';
import { getFileIcon, isWebView, postMsg } from './CommonFunctions';
import AuthModalComponent from '../LoginExtra/AuthModalComponent';
import { withRouter } from 'react-router-dom';

class CommonAttachmentSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            showModalList: false,
            loginRequired: false,
            pathname: props.location?.pathname
        }
    }

    openModalList = () => {
        this.setState({
            showModalList: true
        }, () => {
            window.$("#attachlistpop" + this.props.itemId).modal("show");
        })
    }
    closeModalList = () => {
        this.setState({
            showModalList: false
        })
        window.$("#attachlistpop" + this.props.itemId).modal("hide");
    }

    download_file_pdf = (evt, url) => {
        evt.preventDefault();
        //let filename = this.getFileNameFromUrl(url);
        var parsedUrl = url.substring(url.lastIndexOf("/") + 1);
        let filename = parsedUrl.substring(parsedUrl.indexOf("-") + 1);

        FileSaver.saveAs(url, filename);
    };

    handleLogin = () => {
        this.setState({ loginRequired: true });
        window.$("#loginModal").modal("show");
    }

    handleLoginClose = () => {
        window.$("#loginModal").modal("hide");
        this.setState({ loginRequired: false });
    };

    render() {
        const { showModalList, loginRequired, loginSuccess } = this.state;
        return (
            <>
                <a href="javascript:void(0)" onClick={this.openModalList} className="fattach">
                    <i className="iLink" />
                    {this.props.attachments.length === 1 ? this.props.attachments.length + " File " : this.props.attachments.length + " Files "}{" "}Attached
                </a>

                {showModalList &&
                    <div id={"attachlistpop" + this.props.itemId} className="modal fade dgpopup attachlistpop" tabindex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="forwcontwrap">
                                        <div className="forwcontwraptop">
                                            <h4>Attachments</h4>
                                            <Scrollbars
                                                className={"forwcontlistmain contactlist"}
                                                autoHide
                                                autoHeight
                                                autoHeightMin={0}
                                                autoHeightMax={322}
                                                universal={true}
                                                renderTrackHorizontal={(props) => (
                                                    <div {...props} className="track-horizontal" />
                                                )}
                                                renderTrackVertical={(props) => (
                                                    <div {...props} className="track-vertical" />
                                                )}
                                            >
                                                <ul>
                                                    {this.props.attachments.map((attachment) => {
                                                        var url = attachment.attachmentUrl.substring(attachment.attachmentUrl.lastIndexOf("/") + 1);
                                                        return (
                                                            <li>
                                                                <a href="javascript:void(0)" className="fattach" target={loginSuccess ? "_blank" : ""} onClick={(e) => { loginSuccess ? (isWebView() ? postMsg("download", attachment.attachmentUrl) : this.download_file_pdf(e, attachment.attachmentUrl)) : this.handleLogin() }}>
                                                                    {/* <i className="iLink" /> */}
                                                                    {getFileIcon(url)}
                                                                    {url.substring(url.indexOf("-") + 1)}
                                                                </a>
                                                            </li>
                                                        );
                                                    }
                                                    )}
                                                </ul>
                                            </Scrollbars>
                                        </div>
                                        <div className="forwcontwrapbott">
                                            <button className="butt btnsecondary close" onClick={(e) => this.closeModalList()}>Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {loginRequired && <AuthModalComponent handleLoginClose={this.handleLoginClose} pathname={this.state.pathname} history={this.props.history} />}

            </>
        )
    }
}

export default withRouter(memo(CommonAttachmentSection));