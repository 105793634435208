import React, { Component } from "react";
import { authService } from "../auth";
import axios from "axios";
import { getCDNUrl, getOptimizedImage, rankFormatFunction } from "../commons/CommonFunctions";
import { redirecToProfile } from "../project/component/Studio/StudioContentCommonFunction";
import { withRouter } from "react-router-dom";

class BadgeLeaderBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            topLeaderBoardData: {}
        }
    }

    getTopLeaderBoardDataList = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_userServiceURL}/badge/userList?userId=${this.state.userData.userId}&page=0&size=3`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response.data.message === 'Success!') {
                this.setState({
                    topLeaderBoardData: response?.data?.body
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    redirectToDashboard = () => {
        this.props.history.push({
            pathname: '/dashboard',
            state: {
                redirectFromFeeds: true
            }
        })
    }

    componentDidMount() {
        this.getTopLeaderBoardDataList();
    }

    render() {
        const { topLeaderBoardData } = this.state;
        const { showPublic } = this.props;
        return (
            <>
                {showPublic && topLeaderBoardData?.content?.length > 0 &&
                    <div className="leaderboards">
                        <div className="leaderboards-inner">
                            <div className="row align-leaderboards">
                                <div className="col-7">
                                    <h3 className="leaderboards-title">Leaderboards</h3>
                                </div>
                                {topLeaderBoardData?.totalElements > 3 && 
                                    <div className="col-5">
                                        <h3 className="leaderboards-viewall" onClick={() => this.redirectToDashboard()} style={{ cursor: "pointer" }}>View all <i className="fa fa-angle-right" aria-hidden="true"></i> </h3>
                                    </div>
                                }
                            </div>
        
                            <div className="leaderboards-inner-bg">
                                {topLeaderBoardData?.content?.map((item) => {
                                    return (
                                        <div className="rxlgh">
                                            <div className="rxlgh-profile-left">
                                                <figure>
                                                    <a href="javascript:void(0)" onClick={() => redirecToProfile(item?.customUrl)}><img src={item?.originalProfileImage ? getCDNUrl(getOptimizedImage(item, "originalProfileImage")) : 'https://cdn.dscovr.com/images/users1.png'} alt={item.username} className="rxlgh-profile-images " /></a>
                                                    {/* {item.rank === 1 && <span class="topmark"> <img src="https://cdn.dscovr.com/images/topmark.png" alt="mark" /> </span>} */}
                                                </figure>
                                            </div>
                                            <div className="rxlgh-profile-right">
                                                <h5 className="rxlgh-title" onClick={() => redirecToProfile(item?.customUrl)} style={{ cursor: "pointer" }}>{item?.username}</h5>
                                                <p className="ranks-btn">Rank 0{item?.rank}</p>
                                                <p className="rxlgh-subtitle">{item?.persona}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default withRouter(BadgeLeaderBoard);