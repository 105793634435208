export const SET_PREF = "/SET_PREF";
export const SET_PREF_SUCCESS = "/SET_PREF_SUCCESS";

let initialState = {
    pref: {}
};

const prefReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case "/SET_PREF_SUCCESS":
            state = {pref: {value: action.payload}, isLoaded: true};
            return state;
        case "/SET_PREF":
            state = {pref: {value: action.payload}, isLoaded: false};
            return state;
        default:
            return state;
    }
};

export default prefReducer;

export function setPref(response) {
    return {type: SET_PREF, payload: response};
}

export function setPrefOnSuccess(response) {
    return {type: SET_PREF_SUCCESS, payload: response};
}
