import React from 'react'
import $ from 'jquery';
import { authService } from "../src/auth";
import { getCDNUrl, isMobileDevice, isWebView } from './commons/CommonFunctions'
import { Link, NavLink } from "react-router-dom";
import Chat from "./FaqChat/chat";
import { connect } from 'react-redux';
import { setWpondYearData } from './reducer/wpond/WpondReducer';

class StaticHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            pathname: ''
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((window.location.pathname === '/message' || window.location.pathname === '/message-user') && isMobileDevice()) {
            this.setState({
                pathname: 'message'
            })
        }
        if(this.props.getYearDetails && this.props.yearData?.year) {
            this.props.getYearDetails(this.props.yearData);
        }
    }

    componentDidMount() {
        this.props.setWpondYearData(true);
        window.$(document).on('contextmenu', "img:not('.logotop')", function () {
            return false;
        })

        window.$(".usernotification .btn.point").on('click', function (event) {
            window.$('.usernotification').addClass('active');
        });
        window.$(document).on('click', function (event) {
            if (!window.$(event.target).closest('.usernotification').length) {
                window.$('.usernotification').removeClass('active');
            }
        });

        window.$('#header .iCross').hide();
        window.$("#header .iHamburger").on('click', function (event) {
            window.$('.leftpanel').addClass('navactive');
            window.$('.dgcontainer').addClass('navactive');
            window.$('.staticPage').addClass('navactive');
            window.$('#header .iHamburger').hide();
            window.$('#header .iCross').show();
        });
        $("body, html").on('click', function (event) {
            if (!$(event.target).closest('#header .iHamburger, .read-more-less-btn').length) {
                $('.leftpanel').removeClass('navactive');
                $('.dgcontainer').removeClass('navactive');
                window.$('.staticPage').removeClass('navactive');
                $('.dropdown-menu.show').removeClass('show');
                window.$('#header .iHamburger').show();
                window.$('#header .iCross').hide();
            }
        });
        window.$('.usermenu .dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.usermenu .dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.usermenu .dropdown-menu').addClass('show');
            e.stopPropagation();
        });

        window.$('.topDropdownMob .dropdown > a').on("click", function (e) {
            // var submenu = $(this);
            // $('.topDropdownMob .dropdown-menu').removeClass('show');
            // submenu.next('.topDropdownMob .dropdown-menu').addClass('show');
            e.stopPropagation();
            $(this).next('.dropdown-menu').toggleClass('show');
        });
    }

    render() {

        return <header id="header" className="nologinpage">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-lg-3 logowrap">
                        {/* {
                            !isWebView() ?
                                <div className="logotop">
                                    <Link to={"/"}>
                                        <img className="logotop"
                                             src='https://cdn.dscovr.com/images/logoWeNaturalists.svg'
                                             alt=""/>
                                    </Link>
                                </div>
                                :
                                <div className="logotop">
                                    <Link to={"/login"}>
                                        <img className="logotop"
                                             src='https://cdn.dscovr.com/images/logoWeNaturalists.svg'
                                             alt=""/>
                                    </Link>
                                </div>
                        } */}
                        <div className="logotop">
                            <Link to={"/"}>
                                <img className="logotop"
                                    src='https://cdn.dscovr.com/images/logoWeNaturalists.svg'
                                    alt="" />
                            </Link>
                        </div>
                    </div>


                    <div className="col-lg-9 dgmenuwrap">
                        <div className="dgnav">
                            <div className="dgnavinn">
                                <ul id="menu" className="menu">
                                    {/* <li><NavLink to="/explore" activeClassName="active">Explore</NavLink></li>
                                    <li><a href="/about-us" >About Us</a></li>
                                    <li><Link to={"/how-it-works"}>How It Works</Link></li> */}
                                    {!this.state.loginSuccess ? <>
                                        <li className="loginBtn"><Link to={"/login"}>Login</Link></li>
                                        <li className="signupBtn"><Link to={{ pathname: "/signup", state: { signUpSource: this.props.signUpSource }}} className="signActive">Signup</Link></li>
                                    </> : null}
                                </ul>
                            </div>
                        </div>
                        <div className="usertoppanel">
                            <div className="usermenu">
                                <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"><i className="iMeatballs"/></button>
                                <div className="dropdown-menu fadeIn animated">
                                    <Link className="dropdown-item" to="/forum-poll-draft">Drafts</Link>
                                    <Link className="dropdown-item" to="/FaQ">FAQ & Support</Link>
                                    <Link className="dropdown-item" to="/Feedback">Feedback</Link>
                                    <a className="dropdown-item" href="/about-us">About Us</a>
                                    <Link className="dropdown-item" to="/how-it-works">How It Works</Link>
                                    <Link className="dropdown-item" to="/settings">Settings & Privacy</Link>
                                    <div className="dropdown-item dropdown-submenu">
                                        <a data-toggle="dropdown" href="javascript:void(0)">Terms of Use</a>
                                        <div className="dropdown-menu fadeIn animated">
                                            <Link className="dropdown-item" to="/UserAgreement">User
                                                agreement</Link>
                                            <Link className="dropdown-item" to="/PrivacyPolicy">Privacy
                                                Policy</Link>
                                            <Link className="dropdown-item" to="/TermsConditions">Terms &
                                                conditions</Link>
                                            <Link className="dropdown-item" to="/CookiePolicy">Cookie Policy</Link>
                                            <Link className="dropdown-item" to="/CommunityGuidelines">Community
                                                Guidelines</Link>
                                            <Link className="dropdown-item" to="/refund-policy">Refund Policy</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {this.props.notFound !== true && <i className="iHamburger iMeatballs"/>} */}
                            <i className="iHamburger" />
                            <i className="iCross"
                                style={{ display: this.props.hamburgerVisibility === false ? 'none' : '' }} />
                        </div>
                    </div>

                </div>
            </div>
            <Chat />
        </header>
    }
}

const mapStateToProps = (state) => {
    return {
        yearData: state.WpondReducer?.yearData

    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        setWpondYearData: (state) => dispatch(setWpondYearData(state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticHeader)
