import React from 'react'
import {LazyLoadImage, trackWindowScroll} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
class PrivateProjectHidePage extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (

            <div className="err404contbx">
                <LazyLoadImage
                    effect="blur"
                    src={"https://cdn.dscovr.com/BGOverlay.webp"}
                    onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/BGOverlay.png"}}
                    className="img-fluid" alt="Error image"/>
                <span className="divider_1"/>
                <h2>{this.props.myStudioPage ? "Content Restricted." : "It's a private project."}</h2>
            </div>
        );

    }
}

export default PrivateProjectHidePage;
