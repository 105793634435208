import React from 'react'
import FooterDiv from "../FooterDiv";
import FaqHeader from "../FaqHeader";
import './main-faq.min.css';
import {authService} from "../auth";
import axios from "axios";
import {ReactTinyLink} from "react-tiny-link";
import NotFound from "../404";
import LeftPanel from '../LeftPanel';
import {Link} from "react-router-dom";
import Share from "../commons/Share";

class FaQDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            faqPageSize: 20,
            pageNo: 0,
            pagination: false,
            search: '',
            loginSuccess: authService.isAuthenticated(),
            localStorageData: authService.getUserDetailsFromLocalStorage(),
            faqDetailData: [],
            successMessage: '',
            notFoundstatusCode: ''
        };
        this.getFaq = this.getFaq.bind(this);
        this.handelSearchChange = this.handelSearchChange.bind(this);

    }

    componentDidMount() {
        this.getFaq();
        this.addFaQCount();

        // window.$(".feqtopsearch").sticky({topSpacing: 65, responsiveWidth: true});
        // if (window.$('#sticky-wrapper').hasClass('sticky-wrapper'))
        //     window.$('.projectinn').css({'padding-bottom' : '150px'});
        var navPos = window.$('.feqtopsearch').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.feqtopsearch').addClass('is-sticky');
            } else {
                window.$('.feqtopsearch').removeClass('is-sticky');
            }
        });
            
    }

    getFaq() {
        this.setState({'faqData': {}});
        let section;
        if (this.props.match.params.sec === 'FEED') {
            section = 'POSTING';
        } else if (this.props.match.params.sec === 'PROFILE') {
            section = 'PROFILE';
        } else if (this.props.match.params.sec === 'NETWORK') {
            section = 'COMPANY';
        } else if (this.props.match.params.sec === 'MESSAGES') {
            section = 'CHATS';
        } else if (this.props.match.params.sec === 'EXPLORE') {
            section = 'EXPLORE';
        } else if (this.props.match.params.sec === 'PROJECTS') {
            section = 'PROJECT';
        } else if (this.props.match.params.sec === 'CAUSES') {
            section = 'SHARING';
        } else if (this.props.match.params.sec === 'FORUMANDPOLL') {
            section = 'FORUM';
        } else if (this.props.match.params.sec === 'SUPPORT') {
            section = 'CommonQuestions';
        }
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/public/faq/get?&section=' + section + "&searchParam=" + this.state.search,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.setState({'faqData': res.body});
                this.setState({'successMessage': res.message});
            }
            this.setState({'faqDetailData': this.state.faqData});
        }).catch((err) => {
            if (err && err.response && err.response.status) {
                this.setState({
                    'notFoundstatusCode': err.response.status
                })
            }
            this.setState({
                isLoaded: true,
                error: {message: err.response.data.message, err: err.response}
            });
        });
    }

    addFaQCount() {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/public/faq/addCount?&id=' + this.props.match.params.id,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true
        }).then((response) => {

        }).catch((err) => {
            this.setState({
                isLoaded: true,
                error: {message: err.response.data.message, err: err.response}
            });
        });
    }

    handelSearchChange = (e) => {
        e.preventDefault();
        this.state.search = e.target.value;
        this.setState({'faqDetailData': ''});
        this.getFaq();
    }
    isValidUrl = (str) => {
        let regexp = /^(https?|ftp):\/\/(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        return regexp.test(str)
    }

    render() {
        return <div className="wrapper faqpage staticPage">
            <FaqHeader/>
            <main className="dgcontainer hm3col">
                <div className="container">
                    {this.state.notFoundstatusCode === 404 ? <NotFound/> :
                    <>
                        <div className="feqtopsearch faqdetls">
                            <div>
                                <form action="">
                                    <input type="text" className="search_query form-control"
                                        id="search" name="search" value={this.state.search || ''}
                                        placeholder="Search" onChange={this.handelSearchChange}
                                        autoComplete="off"/>
                                </form>
                            </div>
                        </div>
                        <div className="giveFeedbackWrap">
                            <Link to="/FaQ" className="goback">Go Back</Link>
                            <div className="pagetitle"><h1><i
                                className="iEdit_16pt"/> {this.props.match.params.sec === "FORUMANDPOLL" ? "FORUM AND POLLS" : this.props.match.params.sec}
                            </h1></div>
                            {this.state.faqDetailData.length === 0 && this.state.successMessage === 'Success!' &&
                            <div className="zdata">
                                <div className="desc">
                                    <h4>No Result Found</h4>
                                </div>
                            </div>}
                            {this.state.faqDetailData.length > 0 &&
                            <div className="feedbdetailswrap dgcard">
                                <div id="accordion">
                                    {this.state.faqDetailData && this.state.faqDetailData.map((data, index) => {
                                        return <div className="card" key={index}>
                                            <div className="card-header" id={'heading-' + index}>
                                                <h5><a role="button" data-toggle="collapse" href={'#collapse-' + index}
                                                    aria-expanded="false">
                                                    {data.question}
                                                </a>
                                                {data.canShare &&
                                                <Share shareUrl={"/faq-details/"}
                                                    hideShareOnPortalBtn={true}
                                                    entityId={data.id}
                                                    entityType={"FAQ"}
                                                    shareId={data.slug}/>}
                                                </h5>
                                            </div>
                                            
                                            <div id={'collapse-' + index} className="collapse " data-parent="#accordion"
                                                aria-labelledby={'heading-' + index}>
                                                <div className="card-body">
                                                    <div className="feedbdetailinn">
                                                        <p dangerouslySetInnerHTML={{__html: data.answer}}/>
                                                    </div>
                                                    {data.link && this.isValidUrl(data.link) &&
                                                    <div className="postarticledesc">
                                                        <ReactTinyLink
                                                            cardSize="small"
                                                            showGraphic={true}
                                                            maxLine={2}
                                                            minLine={1}
                                                            url={data.link}
                                                            proxyUrl={"https://cors-anywhere.beegram.net"}/>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            }
                            <div className="faqbottwrap">
                                <div className="faqbottgrid">
                                    <i className="iCall_Service"/>
                                    <h3>Contact Support</h3>
                                    <p>Do you have more questions? Feel free to contact <a href="#contactInfopop1"
                                                                                        data-toggle="modal">Support</a>.
                                    </p>
                                </div>
                                <div id="contactInfopop1" className="modal fade dgpopup">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-body">

                                                <div className="contactInfopop">
                                                    <button type="button" className="close" data-dismiss="modal"
                                                            aria-label="Close"><span
                                                        aria-hidden="true">&times;</span></button>
                                                    <div className="contactInfopoprow"><span>Phone Number</span><p><a
                                                        href="tel:9920605555">+91 9920605555</a></p></div>
                                                    <div className="contactInfopoprow"><span>Email</span><p><a
                                                        href="mailto:support@wenaturalists.com"
                                                        target="_blank">support@wenaturalists.com</a></p></div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>}
                </div>
            </main>
            <LeftPanel/>
            <FooterDiv/>
        </div>
    }
}

export default FaQDetail
