import React from "react";
import { Component } from "react";
import { authService } from "../../../auth";
import axios from "axios";
class StudioDeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            processingDeleteRequest: false
        }
    }

    deleteStudioPost = () => {
        let postData = {};
        this.setState({processingDeleteRequest: true})
        if(this.props.studioPageType === "INTERESTED") {
            postData.postId = this.props.entityId;
            postData.otherUserId = this.props.userId;
            postData.ignore = true;
        }
        axios({
            method: 'POST',
            url: this.props.studioPageType !== "INTERESTED" ? `${process.env.REACT_APP_userServiceURL}/backend/paidContentPost/delete/${this.props.entityId}` : `${process.env.REACT_APP_userServiceURL}/backend/studioPayment/updateIgnored`,
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {
                this.props.triggerDeleteClose();
                this.setState({processingDeleteRequest: false});
                if(this.props.studioPage === "DETAILS") {
                    window.location.href = `/project/feeds/${this.props.originalEntitySlug}`;
                } else {
                    this.props.studioItemListDataAfterDelete(this.props.entityId);
                }
            }
        }).catch((err) => {
            console.log(err);
            this.setState({processingDeleteRequest: false})
        });
    }

    componentWillUnmount() {
        window.$("#confirmpopForDeletePost").modal("hide");
    }

    render() {
        const { processingDeleteRequest } = this.state;
        return (
            <div className="modal fade dgpopup confirmpop" id="confirmpopForDeletePost" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" onClick={() => this.props.triggerDeleteClose()}><span aria-hidden="true">&times;</span></button>
                        <div className="confirm-popup-modal-body">{`Are you sure you want to ${this.props.studioPageType !== "INTERESTED" ? "delete" : "ignore"} this studio?`}</div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary"
                                disabled={processingDeleteRequest}
                                onClick={() => this.deleteStudioPost()}>Yes
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={() => this.props.triggerDeleteClose()}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StudioDeleteModal;