import React, { Component, useState } from 'react';
import { Link } from "react-router-dom";
import { authService } from "../../../auth";
import { getCDNUrl } from "../../../commons/CommonFunctions";
import * as _ from "lodash";
import dateUtil from "date-and-time";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {redirectToProjects, nFormatter} from './HashtagsCommonFunction';
import SavePostButton from '../../../post/SavePostButton';
import { setProjectDetails } from '../../../reducer/showAllReducer';
import { projectPageClickRequest } from '../../../reducer/projectPageClick/projectPageClick';
import { connect } from 'react-redux';

class CommonHashTagsProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
        }
    }

    getOptimizedImage = (project) => {
        if (project.resizedImages && project.resizedImages['300x300'] != null) {
            return project.resizedImages['300x300'];
        }
        return project.coverImage;
    }

    

    setProjectDetails = function (projectDetails) {
        this.props.setProjectDetails(projectDetails);
        this.props.projectPageClickRequest({"projectId": projectDetails.projectId || projectDetails.id, "userId": this.state.userData.userId, "type": "PROJECT"});
        window.$("#projectDetails").modal("show");
    }

    render() {
        return (
            <div className={this.props.data.projectType !== "JOB" && this.props.data.projectType !== "ASSIGNMENT" ? "projeventlist" : "projectlist"}>
                {this.props.data.projectType !== "JOB" && this.props.data.projectType !== "ASSIGNMENT" ?
                    <div className="projeventlistinn">
                        <figure className="eventimg" onClick={(e) => !this.props.data.feedPageShow && this.props.pageType === "BOOKMARK" ? this.setProjectDetails(this.props.data) : redirectToProjects(this.props.data.slug)} style={{ cursor: 'pointer' }}>
                            <LazyLoadImage effect="blur"
                                src={this.props.data.project && this.props.data.project.coverImage && !_.isNull(this.props.data.project.coverImage) ? getCDNUrl(this.getOptimizedImage(this.props.data.project)) : 'https://cdn.dscovr.com/images/project-default.jpg'}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "https://cdn.dscovr.com/images/project-default.jpg"
                                }}
                                alt="" />
                            {this.props.data.renumeration > 0 &&
                                <div
                                    className="price">{this.props.data.project && this.props.data.project.currency} {nFormatter(this.props.data.renumeration, 2)}</div>}
                        </figure>
                        {this.props.pageType === "BOOKMARK" && 
                            <SavePostButton key={this.props.data.projectId} id={this.props.data.projectId} bookMarkFlag={true} entityType={"PROJECT"} onSuccessBookmarkPost={this.props.onSuccessUnsaveBookmarkPost} pageType={this.props.pageType} />
                        }
                        <div className="projeventdesc" onClick={(e) => !this.props.data.feedPageShow && this.props.pageType === "BOOKMARK" ? this.setProjectDetails(this.props.data) : redirectToProjects(this.props.data.slug)} style={{ cursor: 'pointer' }}>
                            <h4>{this.props.data.title}</h4>
                            <div className="d-flex">
                                {this.props.data.project && this.props.data.project.beginningTime &&
                                    <div
                                      className="date">{dateUtil.format(new Date(this.props.data.project.beginningTime), "DD")}<span>{dateUtil.format(new Date(this.props.data.project.beginningTime), "MMM")}</span><span>{dateUtil.format(new Date(this.props.data.project.beginningTime), "YYYY")}</span>
                                    </div>
                                }
                                <div className="projeventdesctxt">
                                    <p>{this.props.data.shortBrief}</p>
                                </div>
                            </div>
                        </div>
                        <div className="projeventpriceloc"><span className="address">{this.props.data.country}</span></div>
                    </div> :
                    <div className="projectlistinn">
                        <div className="projectlisttop">
                            <div className="projheadOuter">
                                 <div className= {"projhead" + (this.props.data.userType === "COMPANY" ? " orgnzName" : "")}>
                                    <figure className="complogo" onClick={(e) => !this.props.data.feedPageShow && this.props.pageType === "BOOKMARK" ? this.setProjectDetails(this.props.data) : redirectToProjects(this.props.data.slug)} style={{ cursor: 'pointer' }}>
                                        <LazyLoadImage effect="blur"
                                            src={this.props.data.project && this.props.data.project.coverImage && !_.isNull(this.props.data.project.coverImage) ? getCDNUrl(this.getOptimizedImage(this.props.data.project)) : 'https://cdn.dscovr.com/images/project-default.jpg'}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "https://cdn.dscovr.com/images/project-default.jpg"
                                            }}
                                            alt="" />
                                    </figure>
                                    <div>
                                        <h5>
                                            <Link to={!_.isEmpty(this.props.data.params) && this.props.data.params.userType === "circle" ? `/community/${this.props.data.params.circleSlug}` : `/profile/${this.props.data.customUrl}`}>{this.props.data.creatorName}</Link>
                                        </h5>
                                        <span className="address">{this.props.data.country}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.pageType === "BOOKMARK" && 
                            <SavePostButton key={this.props.data.projectId} id={this.props.data.projectId} bookMarkFlag={true} entityType={"PROJECT"} onSuccessBookmarkPost={this.props.onSuccessUnsaveBookmarkPost} pageType={this.props.pageType} />
                        }
                        <div className="projectlistmid" onClick={(e) => !this.props.data.feedPageShow && this.props.pageType === "BOOKMARK" ? this.setProjectDetails(this.props.data) : redirectToProjects(this.props.data.slug)} style={{ cursor: 'pointer' }}>
                            <h4>{this.props.data.title}</h4>
                            <p>{this.props.data.shortBrief}</p>
                        </div>
                        <div className="projposttime">
                            <div>Posted on <strong>{dateUtil.format(new Date(this.props.data.project.createTime), "DD MMM YYYY")}</strong></div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        applied
    } = state.home;
    return {
        home: state.home,
        projectPageClickProgress: state.projectPageClickReducer.projectPageClickProgress
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setProjectDetails: (projectDetails) => dispatch(setProjectDetails(projectDetails)),
        projectPageClickRequest: (state) => dispatch(projectPageClickRequest(state))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonHashTagsProject);