import React from 'react'
import { Redirect } from 'react-router-dom';

function RedirectionComponent(props) {

    var new_url = props?.match?.url;
    new_url = new_url.replace('/circle/','/community/');

    return (
        <Redirect to={`${new_url}`} />
    )
}

export default RedirectionComponent;