import React from "react";
import { authService } from "../../auth";
import MemberItem from "../../commons/MemberItem";
import Header from "../../Header";
import LeftPanel from "../../LeftPanel";

import "../../network/main-networks.min.css";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import $ from "jquery";
import Loader from "react-loader-spinner";
import { isWebView } from "../../commons/CommonFunctions";

class NomineeVotersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            nomineeVoterList: {},
            nomineeVoterPageNo: 0,
            nomineeVoterSuccess: false,
            thresholdHeight: 1000
        };
    }

    fetchNomineeVoter = (page, size, concatWithPrevious) => {
        let cancelToken;
        // Cancel Token Creation
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request")
        }
        cancelToken = axios.CancelToken.source()

        axios({
            url: `${process.env.REACT_APP_userServiceURL}/wpondNominationVote/getVoter?id=${this.props.match.params.nomineeId}&year=${this.props.location.state?.year}&slug=${this.props.location.state?.slug}&page=${page}&size=${size}`,
            method: "GET",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
            cancelToken: cancelToken.token
        }).then((response) => {
            if (
                response &&
                response.data &&
                response.data.body &&
                response.data.status === "200 OK"
            ) {
                let pageData = response.data.body;
                if (concatWithPrevious) {
                    pageData.content = this.state.nomineeVoterList.content.concat(pageData.content);
                }
                this.setState({
                    nomineeVoterList: pageData,
                    nomineeVoterSuccess: false
                })
            }
        }).catch((error) => {
            console.log(error)
            this.setState({
                nomineeVoterSuccess: false
            })
        });
    };

    componentDidMount() {
        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
        if(this.props.location.state?.slug && this.props.location.state?.year) {
            this.fetchNomineeVoter(this.state.nomineeVoterPageNo, 24, false);
        } else {
            window.location.href = "/";
        }
    }

    seeMoreOfNomineeVoter() {
        if (this.state.nomineeVoterList && this.state.nomineeVoterList.content) {
            let pageable = this.state.nomineeVoterList.pageable;
            this.setState({
                nomineeVoterSuccess: true,
                nomineeVoterPageNo: pageable.pageNumber + 1
            }, () => {
                this.fetchNomineeVoter(this.state.nomineeVoterPageNo, 24, true)
            });
        }
    }

    redirectToProfile = (customUrl) => {
        window.location.href = "/profile/" + customUrl;
    };

    render() {

        return (
            <div className={"wrapper projectpage networkpage"/*   + (isWebView() ? " mobileView" : "") */}>
                {/* !isWebView() &&  */<Header />}

                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <LeftPanel />

                            <div className="col-md-9">
                                <div className="projectinn followersall">
                                    <div className="projtopbar">
                                        <div className="projtopbarleft">
                                            <ul id="tabs" className="nav">
                                                <li className="nav-item">
                                                    <a
                                                        href="javascript:void(0)"
                                                        data-toggle="tab"
                                                        className="nav-link active"
                                                        style={{ cursor: 'default' }}
                                                    >
                                                        <h1>Voters List</h1>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="invitationbott">
                                        <div className="peoplefollow globalfollow profFollowList">
                                            {this.state.nomineeVoterList && this.state.nomineeVoterList.content && (
                                                <InfiniteScroll
                                                    loadMore={this.seeMoreOfNomineeVoter.bind(this)}
                                                    hasMore={!this.state.nomineeVoterSuccess && !this.state.nomineeVoterList.last}
                                                    threshold={this.state.thresholdHeight}
                                                    loader={<span style={{ textAlign: "center", display: "block" }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "inline-block",
                                                                marginTop: "100px"
                                                            }}
                                                        >
                                                            <Loader
                                                                type="Grid"
                                                                color="#dcde63"
                                                                height={60}
                                                                width={60}
                                                                timeout={8000}
                                                            />
                                                        </div>
                                                    </span>}
                                                >
                                                    <ul>
                                                        {this.state.nomineeVoterList && this.state.nomineeVoterList.content && this.state.nomineeVoterList.content.map((data, index) => {
                                                            return (<li key={index}>
                                                                <figure className="users"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.redirectToProfile(data.customUrl)
                                                                    }} style={{ cursor: 'pointer' }}><a href=""><img
                                                                        src={data.imageUrl ? data.imageUrl : data.type === "COMPANY" ? "https://cdn.dscovr.com/images/DefaultBusiness.png" : "https://cdn.dscovr.com/images/users1.png"} onError={(e) => { e.target.onerror = null; e.target.src = data.type == "COMPANY" ? "https://cdn.dscovr.com/images/DefaultBusiness.png" : "https://cdn.dscovr.com/images/users1.png" }} alt="" />
                                                                    </a></figure>
                                                                <div
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.redirectToProfile(data.customUrl)
                                                                    }}  >
                                                                    <h5 className="name"><span style={{ cursor: 'pointer' }}>{data.username}</span></h5>
                                                                    <span class="persona">{data.persona && data.persona}</span>
                                                                    <span className="mutual">{data.addressDetail && data.addressDetail.country}</span>
                                                                </div>
                                                            </li>)
                                                        })}
                                                    </ul>
                                                </InfiniteScroll>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default NomineeVotersList;
