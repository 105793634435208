import React from 'react'
import {authService} from "./auth";
import $ from 'jquery';
import axios from 'axios';
import httpService from './AxiosInterceptors';
import {Link} from "react-router-dom";
import Notification from "./notification/Notification";
import { getCDNUrl, isMobileDevice, userAgentGetData} from "./commons/CommonFunctions";
import Chat from "./FaqChat/chat";
import { setWpondYearData } from './reducer/wpond/WpondReducer';
import { connect } from 'react-redux';

httpService.setupInterceptors();

class FaqHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            pathname:'',
            year: ""
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if((window.location.pathname === '/message' || window.location.pathname === '/message-user') && isMobileDevice()){
            this.setState({
                pathname:'message'
            })
        }
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImage;
    }

    componentDidMount() {   
        window.$(document).on('contextmenu', "img:not('.logotop')", function () {
            return false;
        });
        window.$('#header .iCross').hide();
        window.$("#header .iHamburger").on('click', function (event) {
            window.$('.leftpanel').addClass('navactive');
            window.$('.dgcontainer').addClass('navactive');
            window.$('.staticPage').addClass('navactive');
            window.$('#header .iHamburger').hide();            
            window.$('#header .iCross').show();
        });
        $("body, html").on('click', function (event) {
            if (!$(event.target).closest('#header .iHamburger, .read-more-less-btn').length) {
                $('.leftpanel').removeClass('navactive');
                $('.dgcontainer').removeClass('navactive');
                window.$('.staticPage').removeClass('navactive');
                $('.dropdown-menu.show').removeClass('show');
                window.$('#header .iHamburger').show();            
                window.$('#header .iCross').hide();
            }
        });
        window.$('.usermenu .dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.usermenu .dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.usermenu .dropdown-menu').addClass('show');
            e.stopPropagation();
        });
        window.$(".usernotification .btn.point").on('click', function (event) {
            window.$('.usernotification').addClass('active');
        });
        window.$(document).on('click', function (event) {
            if (!window.$(event.target).closest('.usernotification').length) {
                window.$('.usernotification').removeClass('active');
            }
        });
        this.props.setWpondYearData(this.state.loginSuccess);
    }


    render() {
        return <header id="header">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-lg-3 logowrap">
                        <div className="logotop">
                            <Link to="/">
                                <img className="logotop"
                                     src='https://cdn.dscovr.com/images/logoWeNaturalists.svg'
                                     alt=""/>
                            </Link>
                        </div>

                    </div>
                    <div className="col-md-9 col-lg-9 htoprt">
                        <div className="usertoppanel">
                            <Link to={"/profile/" + this.state.userData.customUrl} className="userprofile btn" style={{textTransform: this.state.userData.type !== "COMPANY" ? "capitalize" : "none"}}>
                                <figure><img
                                    src={(this.state.userData && this.state.userData.profileImage && this.state.userData.profileImage !== '') ? getCDNUrl(this.getOptimizedImage(this.state.userData)) : (this.state.userData.type != "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                    alt=""/></figure>
                                <span>{this.state.userData && this.state.userData.type === 'INDIVIDUAL' ? this.state.userData.firstName : this.state.userData && this.state.userData.companyName}</span>
                            </Link>
                            <Notification/>
                            <div className="usermenu">
                                <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"><i className="iMeatballs"/></button>
                                <div className="dropdown-menu topDropdownMob fadeIn animated">
                                {this.props.yearData && (this.props.yearData.status === "UPCOMING" ||  (this.props.yearData.status === "COMPLETED" && this.props.yearData.showInThreeDot)) && <Link className="dropdown-item" to={`/people-of-nature-award/${this.props.yearData.status === "UPCOMING" ? "upcoming" : "event"}/${this.props.yearData.year}`}>People of Nature Awards</Link>}
                                    <Link className="dropdown-item" to="/wenaturalists-pro">WeNaturalists Pro</Link>
                                    <Link className="dropdown-item" to="/dashboard-settings">Dashboard and Settings</Link>
                                    {/* <div className="dropdown-item dropdown-submenu">
                                        <a data-toggle="dropdown" href="javascript:void(0)">Manage</a>
                                        <div className="dropdown-menu fadeIn animated">
                                            <Link className="dropdown-item" to="/manage-invitation">Manage Invitations</Link>
                                            <Link className="dropdown-item" to="/forum-poll-draft">Drafts</Link>
                                        </div>
                                    </div> */}
                                    <div className="dropdown-item dropdown-submenu">
                                        <a data-toggle="dropdown" href="javascript:void(0)">About WeNaturalists</a>
                                        <div className="dropdown-menu fadeIn animated">
                                            <a className="dropdown-item" href="/about-us">About Us</a>
                                            <Link className="dropdown-item" to="/how-we-impacts">How We Impacts</Link>
                                            <Link className="dropdown-item" to="/how-it-works">How It Works</Link>
                                            <Link className="dropdown-item" to="/world-people-of-nature-day">People of Nature Day</Link>
                                        </div>
                                    </div>
                                    <div className="dropdown-item dropdown-submenu">
                                        <a data-toggle="dropdown" href="javascript:void(0)">Terms of Use</a>
                                        <div className="dropdown-menu fadeIn animated">
                                            <Link className="dropdown-item" to="/UserAgreement">User agreement</Link>
                                            <Link className="dropdown-item" to="/PrivacyPolicy">Privacy Policy</Link>
                                            <Link className="dropdown-item" to="/TermsConditions">Terms &
                                                conditions</Link>
                                            <Link className="dropdown-item" to="/CookiePolicy">Cookie Policy</Link>
                                            <Link className="dropdown-item" to="/CommunityGuidelines">Community Guidelines</Link>
                                            <Link className="dropdown-item" to="/refund-policy">Refund Policy</Link>
                                            <Link className="dropdown-item" to="/studio-terms-conditions">Studio Terms & conditions</Link>
                                        </div>
                                    </div>
                                    <div className="dropdown-item dropdown-submenu">
                                        <a data-toggle="dropdown" href="javascript:void(0)">Support</a>
                                        <div className="dropdown-menu fadeIn animated">
                                            <Link className="dropdown-item" to="/FaQ">FAQ & Support</Link>
                                            <Link className="dropdown-item" to="/Feedback">Feedback</Link>
                                            {this.state.userData && this.state.userData.type && this.state.userData.type==='COMPANY'?
                                            <Link className="dropdown-item" to="/organization-privacy-settings">Settings & Privacy</Link> :
                                            <Link className="dropdown-item" to="/privacy-settings">Settings & Privacy</Link>}                                            
                                            <Link className="dropdown-item" to="/signout">Sign Out</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.props.notFound !== true && <i className="iHamburger"/> /* iMeatballs */}
                            <i className="iCross"
                                    style={{display: this.props.hamburgerVisibility === false ? 'none' : ''}}/>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.pathname === 'message' ? <></>:<Chat/>}
        </header>

    }
}

const mapStateToProps = (state) => {
    return {
        yearData: state.WpondReducer?.yearData

    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        setWpondYearData: (state) => dispatch(setWpondYearData(state))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(FaqHeader);
