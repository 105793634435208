import React, { Component } from "react";
import axios from "axios";
import { authService } from "../../../auth";
import StudioListData from "../../component/Studio/StudioListData";
import Header from "../../../Header";
import PrivateProjectHidePage from "../../../static/PrivateProjectHidePage";
import ManageStudioHelpDeskTopBar from "./ManageStudioHelpDeskTopBar";
import LeftPanelSettings from "../../../settings/LeftPanelSettings";
import OrganizationAdmin from "../../../commons/OrganizationAdmin";
class ManageStudioHidden extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            actionRestrictedPage: false,
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    componentDidMount() {
        document.title = this.state.userData.type === "COMPANY" ? this.state.userData.companyName : this.state.userData.firstName +" "+ this.state.userData.lastName + " Hidden Studio on WeNaturalists.";
        if(this.state.userData.customUrl !== this.props.match.params.customUrl) {
            this.setState({
                actionRestrictedPage: true
            })
        }
    }


    render() {
        const { userId } = this.state;
        return (
            <div className="projectpage myStudio settingspage">
               
                <div className="wrapper">
                    <Header />
                    <main className="dgcontainer hm3col">
                        <div className="container">
                            {this.state.actionRestrictedPage ? 
                            <PrivateProjectHidePage myStudioPage={true} />
                            : <div className="row">
                                <LeftPanelSettings sectionName={"STUDIO"} userId={userId} />
                                <div className="col-md-9">
                                    <div className="projectinn">
                                        <ManageStudioHelpDeskTopBar manageStudioTab="HIDDEN_STUDIO" userName = {this.props.match.params.customUrl} userId={userId} />
                                        {userId && <OrganizationAdmin userId={userId} />}
                                        <div className="projdetlswrap">
                                            <div className="paidProjWrap">
                                                <StudioListData studioPageType={"HIDDEN_STUDIO"} userId={userId} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

export default (ManageStudioHidden);