import React, {Component} from 'react';
import {authService} from "../auth";
import axios from "axios";
import "./styles/EndorsementPopUp.min.css";
import uuid from "react-uuid";


class MembershipRequestPopupforMember extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            isSubmitted: false,
            errors: {},
            isLoading: false,
            modalEntityId: this.props.entityId + uuid(),
        }

    }

    componentDidMount() {
        window.$('#membership_member_request' + this.state.modalEntityId).modal('show');
    }
    componentWillUnmount() {
        window.$('#membership_member_request' + this.state.modalEntityId).modal('hide');
    }
    hideModal = () => {
        window.$('#membership_member_request' + this.state.modalEntityId).modal('hide');
        this.props.triggerMembershipForMemberPopUpClose();
    }


    acceptRequest=(event)=>{
        event.preventDefault();
        this.setState({isLoading: true, isSubmitted: false});
        let postData = {
            circleId: this.props.entityId,
            userId: this.state.userData.userId
        }
        axios({
            method:'post',
            //url: process.env.REACT_APP_userServiceURL + '/backend/circle/accept/request/member',
            url: process.env.REACT_APP_userServiceURL + '/circle/accept/request/member',
            data:postData,
            header:{ 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response)=>{
            if (response && response.data && response.data.status === '202 ACCEPTED') {
                this.setState({isLoading: false});
                this.hideModal();
                this.props.updateNotificationData(this.props.notificationId);
            }
        }).catch((err)=>{
            console.log(err)
        })

    }

    declineRequest=(event)=>{
        event.preventDefault();
        this.setState({isLoading: true});
        let postData = {
            circleId: this.props.entityId,
            userId: this.state.userData.userId
        }
        axios({
            method:'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/circle/decline/request/member',
            data:postData,
            header:{ 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response)=>{
            if (response && response.data && response.data.status === '202 ACCEPTED') {
                this.hideModal();
                this.props.updateNotificationData(this.props.notificationId);
                this.setState({isLoading: false});
            }
        }).catch((err)=>{
            console.log(err)
        })

    }


    render() {
        return <>
            <div className="modal fade dgpopup confirmpop" id={'membership_member_request'+this.state.modalEntityId} tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" onClick={this.hideModal} aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                        <div className="confirm-popup-modal-body">
                            Are you sure you want to accept Membership request for {this.props.entityTitle} community?
                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.acceptRequest}
                                    disabled={this.state.isLoading}>Yes
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={this.declineRequest}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>


    }
}

export default MembershipRequestPopupforMember;


