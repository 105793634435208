import React from "react";

export const subscriptionType = ["BRONZE", "SILVER", "GOLD", "PLATINUM", "CORPORATE"];

export const checkEnableSubscription = (data, type) => {
    return (
        <div className={type}>
            <div className="allTick">
                <i className={'iFollowTick ' + ((data || data > 0) ? 'enable' : '')}></i>
            </div>
        </div>
    )
}

export const getSubscriptionDetails = (data, type, flag) => {
    return (
        <div className={"textall " + (type)}>
            <p>{(flag === false || data === 0 || data == undefined) ? "-" : data > 0 && data < 99999 ? data : "Unlimited"} </p>
        </div>
    )
}

export const partitionSection = () => {
    return (
        <div className="col-12">
            <div className="row bkpriceacrdnrow">
                <div className="col-md-3">
                    <div className="gaphigtmntn">

                    </div>
                </div>
                <div className="col-md-3">
                    <div className="brnzplnbx">

                    </div>
                </div>
                <div className="col-md-3">
                    <div className="slvrplnbx">

                    </div>
                </div>
                <div className="col-md-3">
                    <div className="slvrplnbx">

                    </div>
                </div>
                <div className="col-md-3">
                    <div className="goldplnbx">

                    </div>
                </div>
                <div className="col-md-3">
                    <div className="pltnmplnbx">

                    </div>
                </div>
            </div>
        </div>
    )
}

export const getSubscriptionDetailsWithCurrency = (data, indian, type) => {
    return (
        <div className={"textall " + (type)}>
            <p>{data > 0 ? <>{indian ? <>&#8377;</> : <>&#36;</>}{data}</> : "-"}</p>
        </div>
    )
}

export const getSubscriptionType = (type) => {
    if (type === "BRONZE") {
        return "Standard";
    } else if (type === "SILVER") {
        return "Professional";
    } else if (type === "GOLD") {
        return "Enterprise";
    } else if (type === "PLATINUM") {
        return "Grand";
    } else {
        return "Corporate";
    }
}

export const checkPermissionCheckbox = (data, type, entityType) => {
    return (
        <div className={type}>
            <div className="allTick">
                <i className={'iFollowTick ' + (data > 0 ? 'enable' : '')}></i>
                <i className={'iFollowTick ' + (data > 1 ? 'enable' : '')}></i>
                <i className={'iFollowTick ' + (data > 2 ? 'enable' : '')}></i>
                <i className={'iFollowTick ' + (data > 3 ? 'enable' : '')}></i>
                {entityType === "PROJECT" && 
                    <>
                        <i className={'iFollowTick ' + (data > 4 ? 'enable' : '')}></i>
                        <i className={'iFollowTick ' + (data > 5 ? 'enable' : '')}></i>
                    </>
                }
            </div>
        </div>
    )
}