import React, {PureComponent} from 'react';


class ShareContentRemovedPreview extends PureComponent {

    render() {

        return (
            <div className="sharepostpreview removepost">
                <div className="forumrow">
                    <h4><i className="iCaution"/> Content does not exist or has been removed.</h4>
                </div>
            </div>
        );
    }
}

export default ShareContentRemovedPreview
