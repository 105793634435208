import React from "react";
import axios from "axios";
import {authService} from "../auth";
import uuid from "react-uuid";
import {convertToFirstCapital} from "../utils/StringUtility";
import mappingUtils from "../project/component/commons/utils/mappingUtils";

class AdminTransferModel extends React.Component {

    constructor(props) {
        super(props);
        let userData = authService.getUserDetailsFromLocalStorage();
        this.state = {
            userData: userData,
            details: '',
            error: '',
            modalEntityId: this.props.entityId + uuid(),
            isDisabled : true
        }
    }



    acceptTransferAdmin=(event)=>{
        this.setState({'isSubmitted': true});
        event.preventDefault();
        let postData={
            entityId: this.props.entityId,
            adminId: this.state.userData && this.state.userData.userId,
            superAdminId:this.props.superAdminId,
            notificationId:this.props.notificationId,

        }
        axios({
            method:'post',
            url:process.env.REACT_APP_userServiceURL + '/admin/accept/transfer/right',
            headers:{ 'Content-Type': 'application/json' },
            data:postData,
            withCredentials: true
        }).then((response)=>{
            let res = response.data
            if(res && res.status === '202 ACCEPTED'){
                this.setState({
                    'successMsg': ' Now you are Super Admin of the ' +mappingUtils.getProjectTypeValue(this.props.projectType)+ ' ' +this.props.entityTitle,
                    'isSubmitted': false
                });
                if(this.props.updateNotificationData){
                    this.props.updateNotificationData(this.props.notificationId)
                }

            } else {
                this.setState({'isSubmitted': false});

            }
        }).catch((err)=>{
            console.log(err)
            this.setState({'isSubmitted': false});
        })
    }

    declineTransferAdmin = (event) => {
        event.preventDefault();
        this.setState({isLoading: true, isSubmitted: false})
        let postBody = {
            entityId: this.props.entityId,
            adminId: this.state.userData && this.state.userData.userId,
            superAdminId:this.props.superAdminId,
            notificationId:this.props.notificationId,
        };
        axios({
            method: 'post',
            url:process.env.REACT_APP_userServiceURL + '/backend/admin/decline/transfer/right',
            headers: {'Content-Type': 'application/json'},
            data: postBody,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.status === '202 ACCEPTED') {
                this.hideModal();
                if(this.props.updateNotificationData){
                    this.props.updateNotificationData(this.props.notificationId)
                }
            }
            this.setState({isLoading: false});
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    isSubmitted: false,
                    error: {message: err.response.data.message, err: err.response}
                });
            }
        });
        setTimeout(() => {
            this.setState({
                error: '',
                isLoading: false
            });
        }, 5000);
    };


    componentDidMount() {
        window.$('#transferAdminChangeRequest' + this.state.modalEntityId).modal('show');
    }

    componentWillUnmount() {
        this.hideModal();
    }


    hideModal = () => {
        this.setState({
            isSubmitted: false,
            error: ''
        });
        window.$('#transferAdminChangeRequest'+this.state.modalEntityId).modal('hide');
        this.props.triggerOpenAdminTransferRequestPopUpClose();
    }

    hideModalAndRefreshPage = () => {
        this.setState({
            isSubmitted: false,
            details: '',
            reason: '',
            error: ''
        });
        window.$('#transferAdminChangeRequest' + this.state.modalEntityId).modal('hide');
        this.props.triggerOpenAdminTransferRequestPopUpClose();
    }

    render() {
        return (
            <div id={"transferAdminChangeRequest" + this.state.modalEntityId} className="modal fade dgpopup confirmpop" data-backdrop="static" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button type="button" className="close"  aria-label="Close" onClick={this.hideModal}><span
                            aria-hidden="true">&times;</span></button>

                        {
                            this.state.error && this.state.error !== '' ?
                                <>
                                    <div className="confirm-popup-modal-body">{this.state.error}</div>
                                    <div className="modal-footer confirm-popup-modal-footer">
                                        <button type="button" className="btn btn-secondary"
                                                onClick={this.hideModal}>Cancel
                                        </button>
                                    </div>
                                </>
                                : (this.state.successMsg && this.state.successMsg !== '' ?
                                <>
                                    <div className="confirm-popup-modal-body"><span className="default-caps">{this.props.username} </span>{this.state.successMsg}</div>
                                    <div className="modal-footer confirm-popup-modal-footer">
                                        <button type="button" className="btn btn-secondary"
                                                onClick={this.hideModalAndRefreshPage}>Ok
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="confirm-popup-modal-body">Are you sure you want to be the Super Admin for the {mappingUtils.getProjectTypeValue(this.props.projectType)} <span>{this.props.entityTitle}</span>?</div>
                                    <div className="modal-footer confirm-popup-modal-footer">
                                        <button type="button" className="btn btn-primary"
                                                onClick={this.acceptTransferAdmin}
                                                disabled={this.state.isSubmitted}
                                                data-toggle="modal">{this.state.isSubmitted ? "Loading..." : "ACCEPT"}
                                        </button>
                                        <button type="button" className="btn btn-secondary"
                                                onClick={this.declineTransferAdmin}
                                                disabled={this.state.isSubmitted}
                                                data-toggle="modal">{this.state.isSubmitted ? "Loading..." : "IGNORE"}
                                        </button>
                                    </div>
                                </>)
                        }
                    </div>
                </div>
            </div>
        );
    }
}



export default AdminTransferModel;
