import React, { Component } from 'react';
import {authService} from "../auth";

class ConnectionNotFoundError extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            messages: null,
            isLoading: true,
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage()
        };

    }

    componentWillUnmount() {
        window.$('#connectionNotFound').modal('hide');
    }
    close = (event)=>{
        event.preventDefault();
        window.$('#connectionNotFound').modal('hide');
    }

    redirect = (event)=>{
        event.preventDefault();
        window.location.href=this.props.redirectionLink;
    }

    render() {
        return <>
            <div className="modal fade dgpopup confirmpop" id="connectionNotFound" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="confirm-popup-modal-body">
                            <p>{this.props.message}</p>
                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            {this.props.redirectionRequired &&
                            <button type="button" className="btn btn-primary" onClick={this.redirect}>Proceed</button>
                            }
                            <button type="button" className="btn btn-secondary" onClick={this.close}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

}

export default ConnectionNotFoundError


