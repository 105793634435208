export const SET_NETWORK_COUNT = "SET_NETWORK_COUNT";
export const SET_NETWORK_COUNT_SUCCESS = "SET_NETWORK_COUNT_SUCCESS";

let initialState = {
    networkCountResult: {}
};

const networkCountReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_NETWORK_COUNT_SUCCESS:
            state = {networkCountResult: {value: action.payload}, isLoaded: true};
            return state;
        case SET_NETWORK_COUNT:
            state = {networkCountResult: {value: action.payload}, isLoaded: false};
            return state;
        default:
            return state;
    }
};

export default networkCountReducer;

export function setNetworkCount(response) {
    return {type: SET_NETWORK_COUNT, payload: response};
}

export function setNetworkCountOnSuccess(response) {
    return {type: SET_NETWORK_COUNT_SUCCESS, payload: response};
}
