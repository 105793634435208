import React, {Component} from 'react';
import Header from "../Header";
import './css/main-hiddencontent.min.css';
import {Link, NavLink, withRouter} from "react-router-dom";
import LeftPanelSettings from '../settings/LeftPanelSettings';
import axios from "axios";
import { authService } from '../auth';
import OrganizationAdmin from '../commons/OrganizationAdmin';
import CleverTapUtils from '../commons/CleverTapUtils';
import Breadcumbs from '../Breadcumbs';

class HideContentAbout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    componentDidMount() {
        document.title = "Explore a new world of Naturalists | WeNaturalists";
        CleverTapUtils.eventClick({}, "View Hidden Events/Activities");
    }
    
    render() {
        const { managePermission, userId } = this.state;
        return (
            <div className="wrapper projectpage forumpage hiddenCpage settingspage">
                <Header/>
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <LeftPanelSettings userId={userId} />
                            <div className="col-md-9">     
                                <Breadcumbs crumbs={[{ "name": "Dashboard Settings", "path": "/dashboard-settings" }, { "name": `Hidden Content`, "path": null } ]} />                           
                                <div className="projectinn hiddenAbout">
                                    {userId && <OrganizationAdmin userId={userId} isNotTopSpace={true} isBottomSpace={true} />}
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="manegeHiddWrap">
                                                <h2 class="h2ttl">Hidden Activities</h2>

                                                <div className="hiddLinkTab">
                                                    <NavLink to={{pathname: '/hidden-content/feeds', state: {userId: userId}}} className="ntwLinkTabgrid dgcard">
                                                        <span>
                                                            <i className="iWN_Feeds_OL" />
                                                            <p>Feeds</p>
                                                        </span>
                                                    </NavLink>
                                                    <NavLink to={{pathname: '/hidden-content/project', state: {userId: userId}}} className="ntwLinkTabgrid dgcard">
                                                        <span>
                                                            <i className="iProjects_OL" />
                                                            <p>Projects</p>
                                                        </span>
                                                    </NavLink>
                                                    <NavLink to={{pathname: '/hidden-content/forum-poll', state: {userId: userId}}} className="ntwLinkTabgrid dgcard">
                                                        <span>
                                                            <i className="iForumPoll_Ol" />
                                                            <p>Forum and Poll</p>
                                                        </span>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-5">
                                            <div className="hiddTXTbox">
                                                <h2 class="h2ttl">What are Hidden Activities?</h2>
                                                <p>You can hide activities that you may not want to see or be visible in your profile to others . You may unhide whenever you want to.</p>
                                                <div className="bottinfo">
                                                    <p>Navigate <Link to="/how-it-works">“How It Works”</Link> to know more</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="hiddenPageBG">
                                        <img src="https://cdn.dscovr.com/BGOverlay.webp"
                                            onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/BGOverlay.png"}}
                                            alt=""/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default HideContentAbout;