//project imports
import React, { Component } from 'react';
import Header from "../../Header";
import LeftPanel from "../../LeftPanel";
import { authService } from "../../auth";
import ExploreTopBar from '../ExploreTopBar';
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import IndividualHashTagsCategory from './IndividualHashTagsCategory';
import { userAgentGetData } from '../../commons/CommonFunctions';
import NotFound from "../../404";
import CommonHashTagsStudio from './Common/CommonHashTagsStudio';
import Breadcumbs from '../../Breadcumbs';

class AllIndividualHashTagsStudios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            hashTagsStudios: {},
            hashTagsStudioPageNo: 0,
            studioSuccess: false,
            thresholdHeight: 1000,
            userDetails: {},
            pageNotExist: false,
        }
    }

    getUserDetailsByCustomUrl = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/profile/get_by_custom_url?customUrl=' + this.props.match.params.customurl + '&otherUserId=' + this.state.userData.userId + userAgentGetData(),
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                this.setState({
                    userDetails: response.data.body
                }, () => {
                    this.hashTagsStudios(response.data.body.userId, 0, 24, false);
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    hashTagsStudios = (userid, page, limit, concatWithPrevious) => {
        let cancelToken;
        // Cancel Token Creation
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request");
        }
        cancelToken = axios.CancelToken.source();
        this.setState({
            studioSuccess: false
        })
        axios({
            method: "get",
            url: `${process.env.REACT_APP_userServiceURL}/tags/user/studio/list?userId=${userid}&otherUserId=${this.state.userData.userId}&hashTag=${this.props.match.params.slug}&page=${page}&size=${limit}&auth=${this.state.loginSuccess}`,
            withCredentials: true,
            cancelToken: cancelToken.token,
        }).then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
                let pageData = response.data.body;
                if (concatWithPrevious && this.state.hashTagsStudios && this.state.hashTagsStudios.content) {
                    pageData.content = this.state.hashTagsStudios.content.concat(pageData.content);
                }
                this.setState({
                    hashTagsStudios: pageData,
                    studioSuccess: false
                })
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                studioSuccess: false
            })
        });
    };

    // Fetch Data In Infinite Scroller
    loadmoreHashTagsPhotos = () => {
        if (this.state.hashTagsStudios && this.state.hashTagsStudios.content) {
            this.setState({
                studioSuccess: true
            })
            let pageable = this.state.hashTagsStudios.page.pageable;
            this.setState({
                hashTagsStudioPageNo: pageable.pageNumber + 1
            }, () => {
                this.hashTagsStudios(this.state.userDetails.userId, pageable.pageNumber + 1, 24, true);
            })
        }
    };

    getHashtagsDetails = () => {
        let postData = {
            hashtag: this.props.match.params.slug
        }
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/recommend/statushashtag/`,
            headers: { 'Content-Type': 'application/json' },
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.status === 200) {
                this.getUserDetailsByCustomUrl();
            }
            if (response && response.data && response.data.status === 410) {
                this.setState({
                    pageNotExist: true
                })
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                pageNotExist: err.response && err.response.status === 410 ? true : false
            })
        });
    }

    componentDidMount() {
        this.getHashtagsDetails();
        //hashTagsPhoto(hashTagsPhotoPageNo, 100, false);
        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
    }

    render() {
        const { pageNotExist, userDetails, studioSuccess, thresholdHeight } = this.state;
        return (
            <div className="wrapper projectpage explorepage">
                <Header />
                <main className="dgcontainer hm3col">
                    <div className="container">
                        {pageNotExist ?
                            <NotFound /> :
                            <div className="row">
                                <LeftPanel />
                                <div className="col-md-9">
                                    <div className={"projectinn explore trending exploreExtra" + (userDetails.type === "COMPANY" ? ' orgnzHash' : '')}>
                                        {this.props.match.params.slug && userDetails?.customUrl && <Breadcumbs crumbs={[{ "name": `${userDetails?.userName}`, "path": `/profile/${userDetails?.customUrl}` }, { "name": `Hashtag`, "path": `/profile-hashtag/${userDetails?.customUrl}` }, { "name": `${this.props.match.params.slug}`, "path": `/profile-hashtag/${this.props.match.params.slug}/${userDetails?.customUrl}` }, { "name": `${this.props.match.params.slug}(Studios)`, "path": null } ]} />}
                                        <ExploreTopBar tagName={"Studios"} isIndividualHashtag={true} key={userDetails} userDetails={userDetails} />
                                        <IndividualHashTagsCategory tagName={this.props.match.params.slug} tagType="studios" customUrl={this.props.match.params.customurl} profileInfo={userDetails} hashTagType="PROFILE" />

                                        <div className="exploregenerel expladventwrap trnd alltrndBlgs">
                                            {this.state.hashTagsStudios && this.state.hashTagsStudios.page && (
                                                <InfiniteScroll
                                                    loadMore={this.loadmoreHashTagsPhotos.bind(this)}
                                                    hasMore={!studioSuccess && !this.state.hashTagsStudios?.page?.last}
                                                    threshold={thresholdHeight}
                                                >
                                                    <div className="row all">
                                                        {this.state.hashTagsStudios &&
                                                            this.state.hashTagsStudios.content &&
                                                            this.state.hashTagsStudios.content.map((item, idx) => {
                                                                return (
                                                                    <div className="col-md-4">
                                                                        <CommonHashTagsStudio item={item} key={item.id} idx={idx} />
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </InfiniteScroll>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </main>
            </div>
        )
    }
}

export default React.memo(AllIndividualHashTagsStudios)