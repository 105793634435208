import React from "react";
import ReadMoreReadLess from "./ReadMoreReadLess";
import ReportAbuse from "./ReportAbuse";
import { authService } from "../auth";
import TimeAgo from "javascript-time-ago";
import * as FileSaver from "file-saver";
import uuid from "react-uuid";
import axios from "axios";
import { getCDNUrl, getFileIcon, redirectToProfile } from "./CommonFunctions";
import { Scrollbars } from "react-custom-scrollbars";

// English.
import en from "javascript-time-ago/locale/en";
import { Link } from "react-router-dom";
import MentionTextArea from "./MentionTextArea";
import AddReplyCommentBox from "./AddReplyCommentBox";
import MutualConnect from "./MutualConnect";
import Follow from "./Follow";
import UserReactionComponent from "../UserReaction/UserReactionComponent";
import EntityFollow from "./EntityFollow";

TimeAgo.addLocale(en);

const timeAgo = new TimeAgo("en-US");

class ReplyCommentBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: authService.getUserDetailsFromLocalStorage(),
      editedText: props.item.userComment,
      isEditDisable: true,
      disable: false,
      deleted: false,
      hideReplyBox: false,
    };
  }

  componentDidMount() {
    window.$(".commenttype .form-control, .commenttypeBtnsInn").on("click", function (event) {
      window.$(".commenttype").addClass("active");
    });
    window.$(document).on("click", function (event) {
      if (!window.$(event.target).closest(".commenttype form").length) {
        window.$(".commenttype").removeClass("active");
      }
    });

    //window.$(".textarea-scrollbar").scrollbar();

    window
      .$(".forumcommtvlistwrap.scrollbar-primary .editbtn")
      .on("click", function (event) {
        window
          .$(this)
          .closest(".forumcommtvlistwrap.scrollbar-primary")
          .addClass("current");
      });
  }

  addHideReplyCommentBox = () => {
    this.setState({ hideReplyBox: false });
  };

  getOptimizedImage(personalInfo) {
    if (
      personalInfo.resizedProfileImages &&
      personalInfo.resizedProfileImages["200x200"] != null
    ) {
      return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.profileImage;
  }

  triggerReportClose = () => {
    this.setState({ openReportModal: null });
  };

  onCommentClose = () => {
    this.setState({
      isEditDisable: true,
      editedText: this.props.item.userComment,
    });
    window
      .$(".forumcommtvlistwrap.scrollbar-primary.current")
      .removeClass("current");
  };

  download_file_pdf = (evt, url) => {
    evt.preventDefault();
    var parsedUrl = url.substring(url.lastIndexOf("/") + 1);
    let filename = parsedUrl.substring(parsedUrl.indexOf("-") + 1);
    FileSaver.saveAs(url, filename);
  };

  componentWillUnmount() {
    window.$("#confirmpopForDeleteComment" + this.props.item.id).modal("hide");
  }

  openDeleteModal = () => {
    window.$("#confirmpopForDeleteComment" + this.props.item.id).modal("show");
  };

  deleteComment = (id) => {
    axios({
      method: "get",
      url:
        process.env.REACT_APP_userServiceURL +
        "/backend/comment/delete?commentId=" +
        id,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        this.setState({ disable: false });
        let res = response.data;
        if (res.status === "202 ACCEPTED") {
          window
            .$("#confirmpopForDeleteComment" + this.props.item.id)
            .modal("hide");
          this.setState({ deleted: true });
          if (this.props.callback) {
            this.props.callback(0, 5);
          }
        }
      })
      .catch((err) => {
        this.setState({ disable: false });
        this.setState({
          isLoaded: true,
          error: { message: err.response.data.message, err: err.response },
        });
      });
  };

  handleCommentSubmit = () => {
    let formData = {
      commentId: this.props.item.id,
      description: this.state.editedText
        ? this.state.editedText
        : this.state.editedText,
    };
    axios({
      method: "post",
      url: process.env.REACT_APP_userServiceURL + "/backend/comment/edit",
      data: formData,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        let res = response.data;
        if (res.status === "202 ACCEPTED") {
          this.setState({ isEditDisable: true });
        }
      })
      .catch((err) => {
        this.setState({
          isLoaded: true,
          error: { message: err.response.data.message, err: err.response },
        });
      });
    window
      .$(".forumcommtvlistwrap.scrollbar-primary.current")
      .removeClass("current");
  };
  handleCommentChange = (event) => {
    this.setState({ editedText: event.target.value });
    if (event.target.value.length > 0) {
      this.setState({ disable: false });
    } else {
      this.setState({ disable: true });
    }
  };
  scriptCall = (event) => {
    window.$(".commenttype.inn").addClass("active");
  };
  openUpdateModal = (id) => {
    this.setState({ isEditDisable: false });
  };

  addHideReplyCommentBox = () => {
    this.setState({ hideReplyBox: false });
  };

  hideReplyBox = () => {
    if (this.state.hideReplyBox === false) {
      this.setState({
        hideReplyBox: true,
      });
    } else if (this.state.hideReplyBox === true) {
      this.setState({
        hideReplyBox: false,
      });
    }
  };

  render() {
    var url = window.location.pathname;
    var utcSeconds = this.props.item.time;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds / 1000);
    let uniqueKey = uuid();
    return (
      <>
        {this.state.isEditDisable === false ? (
          <span className="overlay"></span>
        ) : null}
        <div className="forumcommtvlist">
          {!this.state.deleted && (
            <div className="frcommtlisttop">
              <div className={"forumcommtvlisttop" + (this.props.item.userType !== "INDIVIDUAL" ? " orgnz" : "")}>
                <div className="fuserblk">
                  <figure onClick={(e) => {
                        e.preventDefault();
                        redirectToProfile(this.props.item.customUrl, this.props.item.userId, this.props.item.userType, this.state.userData.userId)
                    }} style={{cursor: 'pointer'}}>
                      <img
                        src={
                          this.props.item.profileImage
                            ? getCDNUrl(this.getOptimizedImage(this.props.item))
                            : this.props.item.userType !== "COMPANY"
                            ? "https://cdn.dscovr.com/images/users1.png"
                            : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                        }
                        alt=""
                      />
                  </figure>
                  <div>
                    <h3>
                      <a href="javascript:void(0)" onClick={(e) => redirectToProfile(this.props.item.customUrl, this.props.item.userId, this.props.item.userType, this.state.userData.userId)}
                       style={{ margin: "0" }}
                      >
                        {this.props.item.userName}
                      </a>
                      {this.props.item.connectDepth && !this.props.item.deactivated &&
                      this.props.item.userId != this.state.userData.userId &&
                      this.props.item.userType !== "COMPANY" ? (
                        <MutualConnect
                          connectDepth={this.props.item.connectDepth}
                        ></MutualConnect>
                      ) : null}
                      {this.props.item.connectStatus === "PENDING_CONNECT" ? (
                        <>
                          <a
                            href="javascript:void(0)"
                            className="follow follow-link active"
                          >
                            <i className="iTickedUser" /> Pending
                          </a>
                        </>
                      ) : (
                        this.props.item && !this.props.item.deactivated &&
                        (!this.props.item.connectDepth ||
                          (this.props.item.connectDepth &&
                            this.props.item.connectDepth < 1)) && (
                          this.props.item.userType !== "CIRCLE" ? 
                          <Follow
                            followed={this.props.item.followed}
                            userId={this.props.item.userId}
                            callback_fn={() =>
                              this.props.callback_fn(this.props.item.userId)
                            }
                          /> :
                          <EntityFollow entityType={'CIRCLE'} 
                              entityId={this.props.item.userId}
                              followed={this.props.item.followed}
                              userId={this.state.userData.userId}
                          />
                        )
                      )}
                    </h3>
                    <span>{timeAgo.format(d, "mini-now")}</span>
                  </div>
                </div>
                <div className="fdate">
                  {/* {dateFormat(this.props.item.time, "mmmm d 'at' h:MM tt")} */}
                  <div className="contxmenu">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="dropdown"
                    >
                      <i className="iKebab" />
                    </button>
                    <div className="dropdown-menu fadeIn animated">
                      {this.state.userData.userId ===
                        this.props.item.userId && (
                        <span
                          className="dropdown-item editbtn"
                          onClick={(e) => {
                            this.openUpdateModal(this.props.item.id);
                          }}
                        >
                          <i className="iEditBox" /> Edit
                        </span>
                      )}
                      {this.state.userData.userId ===
                        this.props.item.userId && (
                        <span
                          className="dropdown-item"
                          onClick={(e) => {
                            this.openDeleteModal(this.props.item.id);
                          }}
                        >
                          <i className="iTrashBin" /> Delete
                        </span>
                      )}
                      {this.state.userData.userId !==
                        this.props.item.userId && (
                        <a
                          className="dropdown-item"
                          href="javascript:void(0)"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              openReportModal: this.props.item.id,
                            });
                          }}
                        >
                          <i className="iReportComment_OL" /> Report
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade dgpopup confirmpop"
                id={"confirmpopForDeleteComment" + this.props.item.id}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    {/* <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Delete Comment Confirmation</h5>                                    
                                </div> */}
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="confirm-popup-modal-body">
                      Are you sure you want to delete this comment?
                    </div>
                    <div className="modal-footer confirm-popup-modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.deleteComment(this.props.item.id)}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="forumcommtvlistdesc">
                {(this.state.isEditDisable && this.state.editedText != "")? (
                  <ReadMoreReadLess
                    id={this.props.item.id}
                    description={this.state.editedText}
                  />
                ) : (
                  <div className="commenttype inn">
                    <form action="">
                      <MentionTextArea
                        className="form-control edit textarea-scrollbar scrollbar-outer"
                        value={this.state.editedText
                          .replace(/<br\/>/g, "\n")
                          .replace(/&nbsp;/g, " ")}
                        onChange={this.handleCommentChange}
                        onClick={this.scriptCall}
                        placeholder="Write a comment..."
                      ></MentionTextArea>
                      <div className="commenttypeBtns">
                        <div className="commenttypeBtnsInn">
                          <button
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleCommentSubmit();
                            }}
                            disabled={
                              this.state.disable ||
                              !this.state.editedText ||
                              this.state.editedText.trim("\n") === ""
                            }
                            className="butt"
                          >
                            <i className="iSend_fl" />
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="text-right">
                      <button
                        className="btn cancel"
                        onClick={this.onCommentClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
                <div className="attachImgall">
                    {this.props.item.attachmentIds.map((attachment, index) => {
                      return (
                        attachment.attachmentType === "IMAGE" && (
                          <a data-fancybox={this.props.item.id} href={attachment.attachmentUrl} className="pdescattachfile" key={index}>
                              <img src={getCDNUrl(attachment.attachmentUrl)} />
                          </a>
                        )
                      );
                    })}
                </div>
                <div className="forumcommtvlistbott">                  
                  {this.props.item.attachmentIds &&
                  this.props.item.attachmentIds.length > 0 ? (
                    <a
                      href={"#attachlistCommtpop" + uniqueKey}
                      className="fattach"
                      data-toggle="modal"
                    >
                      <i className="iLink" />
                      {this.props.item.attachmentIds.length === 1
                        ? this.props.item.attachmentIds.length + " File "
                        : this.props.item.attachmentIds.length + " Files "}{" "}
                      Attached
                    </a>
                  ) : (
                    <div className="fattach" />
                  )}
                  <div
                    id={"attachlistCommtpop" + uniqueKey}
                    className="modal fade dgpopup attachlistpop"
                    tabindex="-1"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="forwcontwrap">
                            <div className="forwcontwraptop">
                              <h4>Attachments</h4>
                              <Scrollbars
                                className={"forwcontlistmain contactlist"}
                                autoHide
                                autoHeight
                                autoHeightMin={0}
                                autoHeightMax={322}
                                universal={true}
                                renderTrackHorizontal={(props) => (
                                  <div
                                    {...props}
                                    className="track-horizontal"
                                  />
                                )}
                                renderTrackVertical={(props) => (
                                  <div {...props} className="track-vertical" />
                                )}
                              >
                                <ul>
                                  {this.props.item.attachmentIds.map(
                                    (attachment) => {
                                      var url =
                                        attachment.attachmentUrl.substring(
                                          attachment.attachmentUrl.lastIndexOf(
                                            "/"
                                          ) + 1
                                        );
                                      return (
                                          <li>
                                          <a
                                            href="javascript:void(0)"
                                            className="fattach"
                                            target="_blank"
                                            onClick={(e) => {
                                              this.download_file_pdf(
                                                e,
                                                attachment.attachmentUrl
                                              );
                                            }}
                                          >
                                            {/* <i className="iLink" /> */}
                                            {getFileIcon(attachment.attachmentUrl)}
                                            {/* {attachment.attachmentUrl.substring(attachment.attachmentUrl.lastIndexOf('/', attachment.attachmentUrl.indexOf("-") + 1)+1)} */}
                                            {url.substring(
                                              url.indexOf("-") + 1
                                            )}
                                          </a>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </Scrollbars>
                            </div>

                            <div className="forwcontwrapbott">
                              <button
                                className="butt btnsecondary close"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="forumrowbright">
                    <a
                      href="javascript:void(0)"
                      class="reply"
                      onClick={() => this.hideReplyBox()}
                    >
                      Reply
                    </a>
                    <UserReactionComponent item={this.props.item} key={this.props.item.id} />
                    {/*<a href="#commentbox" className="comments">503</a>*/}
                  </div>
                </div>
              </div>
              {this.state.hideReplyBox === true ? (
                <AddReplyCommentBox
                  commentId={this.props.commentId}
                  addHideReplyCommentBox={this.addHideReplyCommentBox}
                  getAllComments={this.props.callback}
                  replyId={this.props.item.id}
                />
              ) : null}
            </div>
          )}
        </div>
        {this.state.openReportModal &&
          this.state.openReportModal === this.props.item.id && (
            <ReportAbuse
              entityId={this.props.item.id}
              entityType={"COMMENT"}
              triggerReportClose={this.triggerReportClose}
            />
          )}
      </>
    );
  }
}

export default ReplyCommentBox;
