import React, { Component } from "react";
import uuid from "react-uuid";
import { authService } from "../auth";
import axios from "axios";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import PaymentPaypal from "../subscription/PaymentPaypal";

const ErorrMsgPopUp = withReactContent(alertPopUp)

class DonationTopUpPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            totalAmount: ""
        }
        this.razorPaymentStart = this.razorPaymentStart.bind(this);
    }

    onTopUpPaymentSuccess = (payment, details) => {
        window.$('#donationTopUpPayment').modal('hide');
        let postData = {
            paymentGatewayType: 'PAYPAL',
            orderId: payment.orderID,
            paymentId: details.purchase_units[0].payments.captures[0].id,
            amount: details.purchase_units[0].payments.captures[0].amount.value,
            entityType: this.props.entityConfigData?.type,
            userId: this.props.userId || this.state.userData.userId,
            status: 'SUCCESS',
        }
        let entityList = [];
        this.props.additionalDaysPlanList && this.props.additionalDaysPlanList.map((entity) => {
            entityList.push({"title": entity.entityName, "entityId": entity.entityId, "startTime": entity.startTime, "additionalDaysTopUp": entity.additionalDaysTopUp})
        })
        postData.entityList = entityList;
        this.updateRazorPayPaymentRequest(postData);
    }

    onTopUpPaymentCancel = (payment) => {
        window.$('#donationTopUpPayment').modal('hide');
        this.setState({
            topUpModalFlag: false
        })
        let data = {
            paymentGatewayType: 'PAYPAL',
            orderId: payment.orderID,
            amount: this.state.totalAmount,
            userId: (this.props.location && this.props.location.state && this.props.location.state.userId) || this.state.userData.userId,
            status: 'CANCEL'
        }
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_userServiceURL}/payment/update_studio_cancel_payment`,
            headers: { 'Content-Type': 'application/json' },
            data: data,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.status === '200 OK') {
                this.getStudioData();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    onError = (err) => {
        console.log("Error!", err);
    }

    razorPaymentStart() {
        window.$("#donationTopUpPayment").modal("hide");
        let data = {
            amount: this.state.totalAmount,
            paymentGatewayType: 'RAZOR_PAY',
            userId: this.props.userId || this.state.userData.userId,
            currencyType: this.props.entityConfigData.indian ? 'INR' : 'USD'
        }
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_userServiceURL}/payment/create_order`,
            headers: { 'Content-Type': 'application/json' },
            data: data,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.status === '200 OK') {
                let orderDetails = res.body;
                this.setState({ 'orderDetails': orderDetails })
                const userId = this.props.userId || this.state.userData.userId;
                const entityType = this.props.entityConfigData?.type;
                let entityList = [];
                this.props.additionalDaysPlanList && this.props.additionalDaysPlanList.map((entity) => {entityList.push({"title": entity.entityName, "entityId": entity.entityId, "startTime": entity.startTime, "additionalDaysTopUp": entity.additionalDaysTopUp})})
                var _this = this;
                let options = {
                    "key": orderDetails.key,
                    "amount": orderDetails.amount,
                    "currency": orderDetails.currency,
                    "name": orderDetails.username,
                    "contact": orderDetails.mobile,
                    "email": orderDetails.email,
                    "description": "Donation Top Up Purchase",
                    "image": "https://cdn.dscovr.com/images/logoWeNaturalists.svg",
                    "order_id": orderDetails.orderId,
                    "handler": function (response) {
                        let data = {
                            paymentGatewayType: 'RAZOR_PAY',
                            orderId: response.razorpay_order_id,
                            paymentId: response.razorpay_payment_id,
                            signature: response.razorpay_signature,
                            userId: userId,
                            entityType: entityType,
                            entityList: entityList,
                            status: 'SUCCESS'
                        }
                        _this.updateRazorPayPaymentRequest(data);
                    },
                    "prefill": {
                        "name": orderDetails.username,
                        "email": orderDetails.email,
                        "contact": orderDetails.mobile
                    },
                    "notes": {
                        "address": "Razorpay Corporate Office"
                    },
                    "theme": {
                        "color": "#3399cc"
                    }
                };
                let rzp1 = new window.Razorpay(options);
                rzp1.on('payment.failed', function (response) {
                    let data = {
                        paymentGatewayType: 'RAZOR_PAY',
                        orderId: response.error.metadata.order_id,
                        paymentId: response.error.metadata.payment_id,
                        status: 'FAILED',
                        code: response.error.code,
                        description: response.error.description,
                        source: response.error.source,
                        step: response.error.step,
                        userId: userId,
                        reason: response.error.reason


                    }
                    this.updateRazorPayPaymentRequest(data);
                    alert("Payment failed");
                });
                rzp1.open();
                console.log("Payment failed Details ", orderDetails)
            }

        }).catch((err) => {
            if (err && err.response) {
                this.setState({
                    error: { message: err.response.data.message, err: err.response }
                })
            }
            this.setState({
                isLoaded: true
            });
        });
    };

    updateRazorPayPaymentRequest = (data) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_userServiceURL}/payment/update_addtional_days_donation`,
            headers: { 'Content-Type': 'application/json' },
            data: data,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm()
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <p>{"Your payment was successful"}</p>,
                        didClose: () => {
                            this.props.onSuccessDonationPayment(res.body);
                        }
                    })
                })
            }

        }).catch((err) => {
            if (err && err.response) {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm()
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <p>{"Payment failed"}</p>,
                        didClose: () => {
                            this.props.onCloseDonationPayment();
                        }
                    })
                })
            }
        });
    }

    componentDidMount() {
        let totalAmount = this.props.additionalDaysPlanList && this.props.additionalDaysPlanList.map(item => item.additionalDaysTopUp?.amount).reduce((prev, curr) => prev + curr, 0);
        this.setState({
            totalAmount: totalAmount
        })
    }

    render() {
        const { additionalDaysPlanList } = this.props;
        return (
            <>
                <div id="donationTopUpPayment" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="pGatewayWrap donationTopUpPayment">
                                    <button type="button" className="close" aria-label="Close" onClick={this.props.onCloseDonationPayment}>
                                        <i class="iCross" />
                                    </button>
                                    <div className="pGatewayWrapInn">
                                        <div className="pGateDesc">
                                            <div className='title'><h2><span>TopUp Payment</span></h2></div>
                                            <div className='orderTable table-responsive-sm'>
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th><p>Particulars</p></th>
                                                                            <th><p>No of days</p></th>
                                                                            <th><p>Amount</p></th>
                                                                        </tr>
                                                                        {additionalDaysPlanList && additionalDaysPlanList.map((entity) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>
                                                                                        <p>{entity.entityName}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{entity.additionalDaysTopUp?.timeFrame} days</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{this.props.entityConfigData?.indian ? <>&#8377;</> : <>&#36;</>}{entity.additionalDaysTopUp?.amount}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="topAmountInfo">
                                                <h2>Total Amount</h2>
                                                <p className="unitPrice"><strong>{this.props.entityConfigData?.indian ? <>&#8377;</> : <>&#36;</>}{this.state.totalAmount}</strong></p>
                                            </div>

                                            <div className='stTopUpPopBott'>
                                                {this.state.totalAmount && (
                                                    this.props.entityConfigData?.indian ?
                                                        <div className="pgOption">
                                                            <span><a href="javascript:void(0)" onClick={e => { e.preventDefault(); this.razorPaymentStart(); }}><img src="https://cdn.dscovr.com/images/payment-logo1.png" alt="" /></a></span>
                                                        </div> :
                                                        <PaymentPaypal total={this.state.totalAmount}
                                                            key={uuid()}
                                                            description={'Donation Top Up Purchase'}
                                                            onSuccess={this.onTopUpPaymentSuccess}
                                                            onCancel={this.onTopUpPaymentCancel}
                                                            onError={this.onError} />)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default DonationTopUpPayment;