export const userReactionTypeList = [{ label: "All", value: "ALL" }, { label: "Like", value: "LIKE" }, { label: "Celebrate", value: "CELEBRATE" }, { label: "Love", value: "LOVE" }, { label: "Support", value: "SUPPORT" }, { label: "Insightful", value: "INSIGHTFUL" }, { label: "Sad", value: "SAD" }];

export const userReactionIcon = (type) => {
    if(type === "LIKE") {
        return "icoLike";
    } else if(type === "CELEBRATE") {
        return "icoCelebrate";
    } else if(type === "LOVE") {
        return "icoLove";
    } else if(type === "SUPPORT") {
        return "icoSupport";
    } else if(type === "INSIGHTFUL") {
        return "icoInsightful";
    } 
    return "icoSad";
}

export const userReactionEventCount  = (type, eventData) => {
    if(type === "LIKE") {
        return eventData?.like;
    } else if(type === "CELEBRATE") {
        return eventData?.celebrate;
    } else if(type === "LOVE") {
        return eventData?.love;
    } else if(type === "SUPPORT") {
        return eventData?.support;
    } else if(type === "INSIGHTFUL") {
        return eventData?.insightful;
    } else if(type === "SAD") {
        return eventData?.sad;
    }
    return eventData?.all;
}

export const userReactionClassName = (type) => {
    if(type === "CELEBRATE") {
        return "icoArea icoCelebrate";
    } else if(type === "LOVE") {
        return "icoArea icoLove";
    } else if(type === "SUPPORT") {
        return "icoArea icoSupport";
    } else if(type === "INSIGHTFUL") {
        return "icoArea icoInsightful";
    } else if(type === "SAD") {
        return "icoArea icoSad";
    }
    return "icoArea icoLike";
}