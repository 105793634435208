import React, { memo, Component } from "react";
import { authService } from "../../auth";
import _, { cloneDeep } from "lodash";
import axios from "axios";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getResizedImage } from "../../project/component/Studio/StudioContentCommonFunction";

const ErorrMsgPopUp = withReactContent(alertPopUp);

class UserBankDetailsPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            instituteName: "",
            paymentLink: "",
            qrCodeId: null,
            qrCodeImageInfo: [],
            isUpdateDisabled: false,
            nickName: "",
            errors: {},
            paymentLinkValidFlag: true,
            editPayment: true
        }
    }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (_.isUndefined(this.state.instituteName) || _.isEmpty((this.state.instituteName || "").toString()) || _.isNull(this.state.instituteName)) {
            errors["instituteName"] = "Please enter institution name.";
            formIsValid = false;
        }
        if ((_.isUndefined(this.state.paymentLink) || _.isEmpty((this.state.paymentLink || "").toString()) || _.isNull(this.state.paymentLink)) && (_.isUndefined(this.state.qrCodeId) || _.isEmpty((this.state.qrCodeId || "").toString()) || _.isNull(this.state.qrCodeId))) {
            errors["qrCodeId"] = "Please enter payment link or QR code.";
            formIsValid = false;
        }
        if (this.state.paymentLink) {
            let paymentLink = this.state.paymentLink;
            let urlValid = paymentLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (urlValid == null) {
                errors["paymentLink"] = "Please enter a valid url";
                formIsValid = false;
            }
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trimStart()
        })
    }

    handleValidPaymentLink = () => {
        if(this.state.paymentLink && (this.props.bankData.paymentUrl !== this.state.paymentLink)) {
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_userServiceURL}/backend/userBankDetail/check/valid/link?paymentLink=${this.state.paymentLink}`,
                withCredentials: true
            }).then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.setState({
                        paymentLinkValidFlag: response.data.body
                    })
                }
    
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    removeQrCode = () => {
        this.setState({
            qrCodeImageInfo: [],
            qrCodeId: null
        })
    }

    uploadQRAttachment = (e) => {
        let maxFileSize = 100 * 1024 * 1024;
        let _progressInfos = this.state.qrCodeImageInfo;
        _progressInfos = _progressInfos ? _progressInfos : []
        let promises = []
        if (e.target.files && e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
                let fileSize = e.target.files[i].size;
                if (fileSize > maxFileSize) {
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm();
                        }
                    }).then((result) => {
                        return ErorrMsgPopUp.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            title: <><p>{'Uploaded file exceeds limit of 100MB'}</p></>,
                        })
                    })
                    document.getElementById("attachmentFileUploader").value = null;
                    setTimeout(() => {
                        this.setState({
                            error: ''
                        });
                    }, 5000);
                } else {
                    _progressInfos.push({
                        size: e.target.files[i].size,
                        fileName: e.target.files[i].name,
                        attachmentUrl: URL.createObjectURL(e.target.files[i])
                    });
                    this.setState({
                        qrCodeImageInfo: _progressInfos
                    })

                    const fileName = document.getElementById("attachmentFileUploader").value;
                    const idxDot = fileName.lastIndexOf(".") + 1;
                    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                    const allowedAttachmentsTypeArr = ['jpg', 'jpeg', 'png'];
                    if (allowedAttachmentsTypeArr.includes(extFile)) {
                        promises.push(this.uploadAttachmentsFile(e.target.files[i]))
                    } else {
                        ErorrMsgPopUp.fire({
                            didOpen: () => {
                                ErorrMsgPopUp.clickConfirm();
                            }
                        }).then((result) => {
                            return ErorrMsgPopUp.fire({
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                title: <><p>{'Only ' + allowedAttachmentsTypeArr.join("/") + ' files are allowed!'}</p></>,
                            })
                        })
                        document.getElementById("attachmentFileUploader").value = null;
                        this.setState({
                            error: '',
                            displayError: false,
                            qrCodeImageInfo: []
                        });
                    }
                }

            }
        }
        setTimeout(() => {
            this.setState({
                error: "",
            });
        }, 5000);
    }

    uploadAttachmentsFile(currentFile) {
        return new Promise((resolve, reject) => {
            this.setState({ error: 'File Uploading...' });
            const formData = new FormData();
            formData.append("file", currentFile);
            formData.append("userId", this.props.userId);
            const onUploadProgress = (event) => {
                let progressInfos = cloneDeep(this.state.qrCodeImageInfo);
                this.setState({ qrCodeImageInfo: progressInfos });

            };
            formData.append("attachmentType", "IMAGE");
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_userServiceURL}/backend/userBankDetail/uploadAttachment`,
                data: formData,
                withCredentials: true,
                onUploadProgress
            }).then(response => {
                this.setState({ error: '' });
                this.setState({
                    qrCodeId: response.data.body.id
                });
                resolve();
            }).catch((err) => {
                console.log(err);
                this.setState({ error: 'Please try again...' });
                reject(err)
                setTimeout(() => {
                    this.setState({
                        error: ''
                    });
                }, 5000);
            });
        });
    }

    handleBankDetailsUpdate = () => {
        if (this.validateForm() && this.state.paymentLinkValidFlag) {
            let data = {
                institutionName: this.state.instituteName,
                paymentUrl: this.state.paymentLink,
                qrCode: this.state.qrCodeId,
                userId: this.props.userId,
                nickName: this.state.nickName
            }
            if(this.props.formType === "EDIT") {
                data.id = this.props.bankData.id
            }
            axios({
                url: `${process.env.REACT_APP_userServiceURL}/backend/userBankDetail/${this.props.formType === "EDIT" ? "update" : "create"}`,
                method: "POST",
                data: data,
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.props.getUserBankDetails();
                    setTimeout(() => {
                        this.props.onCloseBankDetailsModal();
                    }, 100);
                }
            }).catch((error) => {
                console.log(error)
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm();
                    }
                }).then((result) => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <><p>{error.response.data.message}</p></>,
                    })
                })
            });
        }
    }

    componentDidMount() {
        if(this.props.formType === "EDIT") {
            let data = this.props.bankData
            this.setState({
                instituteName: data.institutionName,
                paymentLink: data.paymentUrl,
                bankDetailsUpdateFlag: true,
                qrCodeId: data.qrCode && data.qrCode.id,
                qrCodeImageInfo: data.qrCode ? [data.qrCode] : [],
                nickName: data.nickName,
                editPayment: data.editLink
            })
        }
    }

    render() {
        const {instituteName, paymentLink, isUpdateDisabled, qrCodeImageInfo, nickName, editPayment } = this.state;
        return (
            <div id="addBankDetailsPop" className="modal fade dgpopup" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="addBankDetailsPop">
                                <button type="button" className="close topCrossModal" aria-label="Close" onClick={this.props.onCloseBankDetailsModal}>
                                    <i className="iCross" />
                                </button>
                                <h2>Bank Details</h2>
                                <div class="formwrap">
                                    <div class="form-group passfield">
                                        <div className="passfieldinn">
                                            <label>Nick Name</label>
                                            <input type="text" class="form-control custom-input" placeholder="" name="nickName" value={nickName} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div class="form-group passfield">
                                        <div className="passfieldinn">
                                            <label>Institution Name <sup className="required-field">*</sup></label>
                                            <input type="text" class="form-control custom-input" placeholder="" name="instituteName" value={instituteName} onChange={this.handleChange} />
                                            {this.state.errors["instituteName"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors["instituteName"]}</div>}
                                        </div>
                                    </div>
                                    <div class="form-group passfield">
                                        <div className="passfieldinn">
                                            <label>Add Payment Link</label>
                                            <input type="text" class="form-control custom-input" placeholder="" name="paymentLink" value={paymentLink} onChange={!editPayment ? "javascript:void(0)" : this.handleChange} onBlur={this.handleValidPaymentLink} disabled={!editPayment} />
                                            {this.state.errors["paymentLink"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors["paymentLink"]}</div>}
                                            {!this.state.paymentLinkValidFlag && <div className="login-feedback-error mt-1" style={{ display: "block" }}>Payment Link Already Used</div>}
                                        </div>
                                    </div>
                                    {(editPayment || (qrCodeImageInfo && qrCodeImageInfo.length > 0)) && 
                                        <div class="form-group passfield">
                                            <div className="passfieldinn">
                                                <label>Add QR Code</label>
                                                {qrCodeImageInfo && qrCodeImageInfo.length === 0 && editPayment && 
                                                    <div className='addQrBtn'>
                                                        <a href="javascript:void(0)" class="attachfile" onClick={(e) => { this.refs.qrImageUploader.click(); }}>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                id="attachmentFileUploader"
                                                                onChange={(e) => this.uploadQRAttachment(e)}
                                                                ref="qrImageUploader"
                                                                style={{ display: "none" }}
                                                            /> Add QR Code</a>
                                                    </div>
                                                }
                                                {qrCodeImageInfo && qrCodeImageInfo.length > 0 &&
                                                    <div className='qrBox'>
                                                        {qrCodeImageInfo.map((attachment) => {
                                                            return (
                                                                <figure>
                                                                    <img src={getResizedImage(attachment)} alt='' />
                                                                    {editPayment &&
                                                                        <button type="button" onClick={this.removeQrCode} className="propicdel btn">
                                                                            <i className="iTrashBin" />
                                                                        </button>
                                                                    }
                                                                </figure>
                                                            )
                                                        })}

                                                    </div>
                                                }
                                                {this.state.errors["qrCodeId"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors["qrCodeId"]}</div>}
                                            </div>
                                        </div>
                                    }

                                    <div class="formbottom">
                                        <button type="button" class="btn btn-primary" onClick={this.handleBankDetailsUpdate}>
                                            {this.props.formType === "EDIT" ? "Update" : "Add"}
                                        </button>
                                        <button class="btn btn-cancel collapsed" onClick={this.props.onCloseBankDetailsModal}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default memo(UserBankDetailsPopUp);