import React, { Component } from "react";
import DonationTargetPopUp from "./DonationTargetPopUp";
class DonationTargetComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            donationTargetPopUp: false,
            donationTargetData: this.props.donateNowConfig?.targetAmount,
            donationBarData: this.props.donationBarData
        }
    }

    componentDidMount() {

    }

    onClickDonationTargetPopUp = () => {
        this.setState({
            donationTargetPopUp: true
        }, () => {
            window.$("#donationTargetPop").modal("show");
        })
    }

    onCloseDonationTargetPopUp = () => {
        window.$("#donationTargetPop").modal("hide");
        this.setState({
            donationTargetPopUp: false
        })
    }

    successDonationTargetData = (targetData) => {
        let targetDataDetails = {"targetAmount": targetData.targetAmount, "minimumAmount": targetData.minimumAmount, "controlTargetAmount": targetData.controlTargetAmount, "controlMinimumAmount": targetData.controlMinimumAmount, "currencyType": targetData.currencyType};
        let donationBarData = this.state.donationBarData;
        if(targetData.targetAmount > 0) {
            donationBarData.maxValue = targetData.targetAmount;
        }
        this.setState({
            donationTargetData: targetDataDetails,
            donationBarData: donationBarData
        })
        this.onCloseDonationTargetPopUp();
        this.props.onSuccessDonationTargetUpdate(donationBarData);
    }

    componentWillUnmount() {
        this.onCloseDonationTargetPopUp();
    }

    render() {
        const { donationTargetPopUp, donationTargetData, donationBarData } = this.state;
        const { donateNowConfig, userId } = this.props;
        return (
            <>
                <button className={'btn moreText2_Btn'} onClick={() => this.onClickDonationTargetPopUp()}>Fund Raise Target</button>
                {donationTargetPopUp && <DonationTargetPopUp onCloseDonationTargetPopUp={this.onCloseDonationTargetPopUp} successDonationTargetData={this.successDonationTargetData} donationTargetData={donationTargetData} donateNowConfig={donateNowConfig} indian={this.props.indian} donationBarData={donationBarData} />}
            </>
        )
    }
}

export default DonationTargetComponent;