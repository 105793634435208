import React, { useEffect, useState } from "react";
import { authService } from "../../auth";
import Header from "../../Header";
import LeftPanel from "../../LeftPanel";
import ExploreTopBar from '../ExploreTopBar';
import HashTagsCategory from './HashTagsCategory';
import NotFound from "../../404";
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import CommonHashTagsDocuments from "./Common/CommonHashTagsDocuments";
import BypassHeader from "../../BypassHeader";
import LeftPanelUnauth from "../../commons/LeftPanelUnauth";
import Breadcumbs from "../../Breadcumbs";

const AllHashTagsDocuments = (props) => {

    const [userData, setUserData] = useState(authService.getUserDetailsFromLocalStorage());
    const [pageNotExist, setPageNotExist] = useState(false);
    const [tagName, setTagName] = useState('');
    const [hashTagsDocuments, setHashTagsDocuments] = useState({});
    const [otherSuccess, setOtherSuccess] = useState(false)
    const [thresholdHeight, setThresholdHeight] = useState(1000);
    const [hashTagsDocumentsPageNo, setHashTagsDocumentsPageNo] = useState(0)
    const [loginSuccess] = useState(authService.isAuthenticated());
    const [signUpNeed, setSignUpNeed] = useState(false);
    

    useEffect(() => {
        tagNameValue();
        getHashtagsDetails();
        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
    }, [])

    const tagNameValue = () => {
        setTagName(props.match.params.slug);
    }

    let cancelToken;
    const hashTagsDocument = (page, limit, concatWithPrevious) => {
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request")
        }
        cancelToken = axios.CancelToken.source()

        setOtherSuccess(false)
        let url = `${process.env.REACT_APP_userServiceURL}/unauth/getNewsFeed/hashTag/viewAll/?hashTag=${props.match.params.slug}&category=POST&newsFeedType=DOCUMENT&page=${page}&size=${limit}&auth=${loginSuccess}`;
        if(loginSuccess) {
            url = `${process.env.REACT_APP_userServiceURL}/tags/getNewsFeed/hashTag/viewAll?otherUserId=${userData.userId}&hashTag=${props.match.params.slug}&category=POST&newsFeedType=DOCUMENT&page=${page}&size=${limit}&auth=${loginSuccess}`;
        }
        axios({
            method: 'get',
            url: url,
            withCredentials: true,
            cancelToken: cancelToken.token
        }).then((response) => {
            if (response && response.data && response.data.status === '200 OK') {
                let pageData = response.data.body;
                if (concatWithPrevious && hashTagsDocuments && hashTagsDocuments.content) {
                    pageData.content = hashTagsDocuments.content.concat(pageData.content)
                }
                setHashTagsDocuments(pageData)
                setOtherSuccess(false)
            }
        }).catch((error) => {
            console.log(error)
            setOtherSuccess(false)
        })
    }

    const loadmoreDocuments = () => {
        if (hashTagsDocuments && hashTagsDocuments.content) {
            setOtherSuccess(true)
            let pageable = hashTagsDocuments.page.pageable;
            setHashTagsDocumentsPageNo(pageable.pageNumber + 1)
            hashTagsDocument(pageable.pageNumber + 1, 24, true)
        }
    }

    const getHashtagsDetails = () => {
        let postData= {
            hashtag: props.match.params.slug
        }
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_userServiceURL}/recommend/statushashtag/`,
            headers: { 'Content-Type': 'application/json' },
            data: postData,
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.status === 200) {
                hashTagsDocument(hashTagsDocumentsPageNo, 24, false)
            }
            if (response && response.data && response.data.status === 410) {
                setPageNotExist(true);
            }
        }).catch((err) => {
            console.log(err);
            setPageNotExist(err.response && err.response.status === 410 ? true : false)
        });

    }

    const signUpRequired = () => {
        setSignUpNeed(true);
        window.$('#signupModal').modal('show');
    }

    return (
        <div className={"wrapper projectpage explorepage" + (loginSuccess ? "" : " nologinpage")}>
            {loginSuccess ? <Header /> : <BypassHeader />}
            <main className="dgcontainer hm3col">
                <div className="container">
                    {pageNotExist ? 
                        <NotFound/> :
                        <div className="row">
                            {loginSuccess ? <LeftPanel /> : <LeftPanelUnauth signUpRequired={signUpRequired} />}
                            <div className="col-md-9">
                                <div className="projectinn explore trending exploreExtra">
                                    {tagName && <Breadcumbs crumbs={[{ "name": `Explore`, "path": `/explore-daily-edition` }, { "name": `Hashtag`, "path": `/explore-hashtag` }, { "name": `${tagName}`, "path": `/explore-hashtag/${tagName}` }, { "name": `Documents`, "path": null } ]} />}
                                    <ExploreTopBar tagName={"Documents"} />
                                    {loginSuccess && <HashTagsCategory tagName={tagName} tagType="documents" />}
                                    <div className="dailyeditionblk explLatarticle trndBlgs alltrndBlgs expladventwrap trnd docmnts" style={{paddingTop: '0px'}}>
                                        {hashTagsDocuments && hashTagsDocuments.page && (
                                                <InfiniteScroll
                                                    loadMore={loadmoreDocuments.bind(this)}
                                                    hasMore={!otherSuccess && !hashTagsDocuments.page.last}
                                                    threshold={thresholdHeight}
                                                >
                                                    <div className="row all">
                                                        {hashTagsDocuments &&
                                                            hashTagsDocuments.content &&
                                                            hashTagsDocuments.content.map((item, index) => {
                                                                return (
                                                                    <div className="col-md-4">
                                                                        <CommonHashTagsDocuments item={item} key={item.id} />
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </InfiniteScroll>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </main>
        </div>
    )
}


export default AllHashTagsDocuments;