import React, {PureComponent} from 'react';
import * as _ from "lodash";
import {convertUnicode, getCDNUrl} from "../commons/CommonFunctions";

class CirclePostItem extends PureComponent {

    getOptimizedImage = (item) => {
        if (item.resizedImages && item.resizedImages['300x300'] != null) {
            return item.resizedImages['300x300'];
        }
        return item.profileImage;
    }

    render() {

        return (
            <li className="postCardBlk">
                <a href="javascript:void(0)" onClick={this.props.setAsUser}>
                    <figure className="usimg">
                        <img
                            src={!_.isUndefined(this.props.item.profileImage) && !_.isNull(this.props.item.profileImage) ? getCDNUrl(this.getOptimizedImage(this.props.item)) : "https://cdn.dscovr.com/images/circleDefault.webp"}
                            onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/circleDefault.png"}}
                            alt=""/>
                    </figure>
                    <div>
                        <h5>{convertUnicode(this.props.item.title)}</h5>
                        <span>Community</span>
                    </div>
                </a>
            </li>
        );
    }
}

export default CirclePostItem;
