import axios from 'axios';
import Picker from "emoji-picker-react";
import { cloneDeep } from 'lodash';
import React, { Component } from 'react'
import uuid from 'react-uuid';
import stripHtml from 'string-strip-html';
import { authService } from '../auth';
import AutoSuggest from '../AutoSuggest';
import { postNotFoundFunction } from '../commons/CommonFunctions';
import MentionTextArea from '../commons/MentionTextArea';

export default class CommonPostEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            isPostUpdating: false,
            modalEntityId: this.props.entityId + uuid(),
            editedText: this.props.description,
            tags: this.props.tags,
            documentTitle: this.props.documentTitle,
            smileDisplay: false,
            errors: {}
        }
    }

    check_words=(e)=> {
        const BACKSPACE = 8;
        const DELETE = 46;
        const MAX_WORDS = 400;
        const valid_keys = [BACKSPACE, DELETE];
        const words = this.value && this.value.split(' ');

        if (words && words.length >= MAX_WORDS && valid_keys.indexOf(e.keyCode) === -1) {
            e.preventDefault();
            words.length = MAX_WORDS;
            this.value = words.join(' ');
        }
    }

    handlePostTextUpdate = (event) => {
        this.setState({'editedText': event.target.value});
        this.check_words(event)
    }

    handleTagsChange = (tags) => {
        let hashTagsList = [];
        if (tags && tags.length > 0) {
            tags && tags.map(function (data) {
                data = data.replace(/[`~!@$%^&*()|+\=?;:'",.#<>\{\}\[\]\\\/\s]/gi, '');
                if (data != "") {
                    hashTagsList.push(data);
                }
            });
        }
        this.setState({ 'tags': hashTagsList });
    }

    onDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags })
    }

    onAddition(tag) {
        const tags = [].concat(this.state.tags, tag)
        this.setState({ tags })
    }

    handleSmileDisplay = () => {
        this.setState({
            smileDisplay: this.state.smileDisplay ? false : true
        })
    }

    onToggleEmojiWindow = () => {
        const { smileDisplay } = this.state;
        this.setState({ smileDisplay: !smileDisplay });
    };

    onEmojiClick = (event, emojiObject) => {
        let editedText = cloneDeep(this.state.editedText)
        editedText = editedText + ' ' + emojiObject.emoji
        this.setState({
            'editedText': editedText
        })
    }

    hideUpdateModal = () => {
        this.setState({ editedText: this.state.description });
        window.$('#' + this.state.modalEntityId + "toppostwrap").modal('hide');
    }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        var regex = /\s+/gi;
        if ((!this.state.editedText.trim() || this.state.editedText.trim() === "") && this.state.tags.length === 0 && this.props.postType !== "PPT" && this.props.postType !== "PDF") {
            errors["postText"] = "Please Post something to publish";
            formIsValid = false;
          }
        if (stripHtml(this.state.editedText).trim().replace(regex, ' ').split(' ').length > 400
        ) {
            errors["postText"] = "Please enter upto 400 words";
            formIsValid = false;
        }
        if(this.props.postType === "PPT" && !this.state.documentTitle) {
            errors["documentTitle"] = "Please add document title";
            formIsValid = false;
        }
        if (this.props.postType === "PPT" && this.state.documentTitle && (this.state.documentTitle).replace(/\s/g, "").length > 60) {
            errors["documentTitle"] = "Document title should not be greater than 60 characters";
            formIsValid = false;
        }
        this.setState({
            errors: errors
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 5000);
        }
        return formIsValid;
    }

    handlePostUpdate = () => {
        if (this.validateForm()) {
            this.setState({ isPostUpdating: true })
            const formData = new FormData();
            formData.append("postId", this.props.entityId);
            formData.append("description", this.state.editedText && this.state.editedText.replace(/(http(s))/g, " $1").trim('\n'));
            formData.append("attachmentTitle", this.state.documentTitle && this.state.documentTitle.replace(/\s+/g, ' ').trim());
            formData.append("hashTags", this.state.tags ? this.state.tags.map((item) => {
                return item.replace(/#/g, '')
            }) : []);
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/backend/post/edit',
                data: formData,
                withCredentials: true
            }).then(response => {
                if (response && response.status === 202) {
                    this.props.onSuccessUpdatePost({"description": this.state.editedText && this.state.editedText.replace(/(http(s))/g, " $1").trim('\n'), "tags": this.state.tags, "documentTitle": this.state.documentTitle});
                    window.$('#' + this.state.modalEntityId + "toppostwrap").modal('hide');
                    this.setState({ isPostUpdating: false, description: this.state.editedText, smileDisplay: false });
                } else {
                    this.setState({ isPostUpdating: false, smileDisplay: false })
                }
            }).catch((err) => {
                console.log(err);
                this.setState({ isPostUpdating: false, smileDisplay: false })
                if (err.response.data.status === "404 NOT_FOUND") {
                    postNotFoundFunction(this.props.entityId);
                }
            });
        }
        setTimeout(() => {
            this.setState({
                error: '',
                errors: {},
            });
        }, 5000);
    }
    
    hideUpdateModal = () => {
        window.$("#"+this.state.modalEntityId+"toppostwrap").modal("hide");
        this.props.triggerEditClose();
    }

    onHandleChange = (event) => {
        this.setState({
            documentTitle: event.target.value.trimStart()
        })
    }

    componentDidMount() {
        document.querySelector(".wrapper").addEventListener("click", event => {
            const parent = document.querySelector(".emoji-picker-react");
            const { smileDisplay } = this.state;
            if (smileDisplay && !parent.contains(event.target)) {
                this.onToggleEmojiWindow();
            }
        });
        window.$("#"+this.state.modalEntityId+"toppostwrap").modal("show");
    }

    componentWillUnmount() {
        this.hideUpdateModal();
    }

    render() {
        var regex = /\s+/gi;
        const { modalEntityId, editedText, isPostUpdating, smileDisplay, tags, errors } = this.state;
        let Stripbody = stripHtml(this.state.editedText).trim().replace(regex, ' ').split(' ').length
        return (
            <div id={modalEntityId + "toppostwrap"} className="modal fade dgpopup openPostbox"
                data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <section className="toppostwrap">
                                <div className="toppostinn">
                                    <div className="topposthead">
                                    </div>
                                    <div className="toppostpopbody">
                                        <div className="toppostpoptop">
                                            <div className="tpbfieldlogo">
                                                <img src="https://cdn.dscovr.com/images/quotes-logo.webp"
                                                    onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/quotes-logo.png" }}
                                                    alt="" />
                                            </div>
                                            <div className="tpbfield">
                                                <form action="">
                                                    <MentionTextArea
                                                        value={editedText}
                                                        onChange={this.handlePostTextUpdate}
                                                        placeholder="Write here...">
                                                    </MentionTextArea>
                                                    {this.props.postType === "PPT" && 
                                                        <div className="presentation-title">
                                                            <label>Add Document title <sup className="required-field">*</sup></label>
                                                            <input type="text" class="form-control custom-input" placeholder="Add document title here..." name="documentTitle" value={this.state.documentTitle} onChange={this.onHandleChange} />
                                                            <span className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors["documentTitle"]}</span>
                                                        </div>
                                                    }
                                                    {smileDisplay && <Picker pickerStyle={{ width: '100%', height: '200px' }} onEmojiClick={this.onEmojiClick} />}
                                                    {errors["postText"] && <span className="login-feedback-error mt-1"
                                                        style={{ display: "block" }}>{errors["postText"]}</span>}
                                                    <div className="toppostpopbott">
                                                        <div className="tagfield">
                                                            <AutoSuggest hashTags={tags}
                                                                onDelete={this.onDelete}
                                                                onAddition={this.onAddition}
                                                                handleTagsChange={this.handleTagsChange} />
                                                        </div>
                                                        <div className="toppostpopfeatures">
                                                            <a href="javascript:void(0)" className="attchbtn"
                                                                onClick={this.handleSmileDisplay}>
                                                                <i className="iEmoji" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottpostpopbody">
                                        <div className="bottpostpopright">
                                            {Stripbody > 400 ?
                                                <></> :
                                                <div className="charechterinfo">{400 - Stripbody} {400 - Stripbody <= 1 ? 'word ' : 'words '}
                                                    left
                                                </div>}
                                            <a href="javascript:void(0)" className="btn cancel"
                                                onClick={this.hideUpdateModal}>Cancel</a>
                                            <input type="submit" onClick={this.handlePostUpdate}
                                                className="btn"
                                                value={isPostUpdating ? "Loading..." : "UPDATE"}
                                                disabled={isPostUpdating} />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
