import React,{useRef,useEffect, useState} from 'react';

function PaymentPaypal(props) {
    const paypal = useRef();

    const [totalAmount, setTotalAmount] = useState(props.total);

    useEffect(()=>{
        
        window.paypal.Buttons({
            style: {
                layout: 'horizontal',
                color:  'silver',
                shape:  'pill',
                label:  'paypal'
            },
            createOrder: (data, actions, err)=>{
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [
                        {
                            description: props.description,
                            amount: {
                                currency_code: "USD",
                                value: totalAmount
                            }
                        }
                    ]
                })
            },
            onApprove: async (data, actions) =>{
                return actions.order.capture().then(function(details) {
                    props.onSuccess(data,details)
                });
            },

            onCancel: function (data) {
                props.onCancel()
            },
            onError:(err)=>{
                console.log(err)
                props.onError()
            }
        }).render(paypal.current)
    },[])

    return (
        <span className='pypl'><div ref={paypal} ></div></span>
    );
}

export default PaymentPaypal;