import React, { memo } from "react";
import { Component } from "react";
import { authService } from "../../../auth";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import LikeMemberItem from "../../../commons/LikeMemberItem";

class StudioViewClickPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
        }
    }

    componentWillUnmount() {
        window.$("#studioViewClickPopUp").modal("hide");
    }

    render() {
        const {viewClickUserData, modalType} = this.props;
        return (
            <div id={"studioViewClickPopUp"} className="modal fade dgpopup likesblk reqendorse" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="similarAllpopWrap forwcontwrap" style={{ maxWidth: "424px" }}>
                                <div>
                                    <div className="similarAllpopHeader">
                                        <h2>{modalType === 'View' ? 'Studio Views' : modalType === 'Click' ? 'Studio Clicks' : 'Studio Subscribers'}</h2>
                                    </div>
                                    <div className={"similarAllpopBody"}>
                                        <Scrollbars
                                            className={"forwcontlistmain"}
                                            autoHide
                                            autoHeight
                                            autoHeightMin={0}
                                            autoHeightMax={322}
                                            universal={true}
                                            renderTrackHorizontal={(props) => (
                                                <div {...props} className="track-horizontal" />
                                            )}
                                            renderTrackVertical={(props) => (
                                                <div {...props} className="track-vertical" />
                                            )}
                                        >
                                            {" "}
                                            {viewClickUserData && viewClickUserData.content && viewClickUserData.content.length > 0 && (
                                                <>
                                                    {viewClickUserData.content.map((data, index) => {
                                                        return (
                                                            <LikeMemberItem item={data} key={data.userId} />
                                                        );
                                                    })}
                                                </>
                                            )}

                                            {viewClickUserData && viewClickUserData.content && viewClickUserData.content.length === 0 && 
                                            <div className="zdata">
                                                <div className="desc">
                                                    <h4>No data available.</h4>
                                                </div>
                                            </div>}
                                            
                                            {viewClickUserData && viewClickUserData.page && !viewClickUserData.page.last && 
                                            <div className="btnblock text-center">
                                                <Link className="btn morebtnsm" to={`/studio/${modalType.toLowerCase()}/list/${this.props.studioId}/${this.props.entityId}`}>View All</Link>
                                            </div>}
                                        </Scrollbars>
                                    </div>
                                </div>
                                <div className="forwcontwrapbott">
                                    <div>
                                        <button className="butt btnsecondary close"  onClick={(e) => {this.props.triggerViewClickCLose()}}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default memo(StudioViewClickPopUp);