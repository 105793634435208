import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { authService } from "../auth";
import Header from "../Header";
import axios from "axios";
import Loader from "react-loader-spinner";
import * as _ from "lodash";
import httpService from "../AxiosInterceptors";
import InfiniteScroll from "react-infinite-scroller";
import { CapitalizeFirstLetter } from "../utils/StringUtility";
import { getCDNUrl } from "../commons/CommonFunctions";
import UserBlockComponent from "../commons/UserBlockComponent";
import LeftPanelSettings from "../settings/LeftPanelSettings";
import OrganizationAdmin from "../commons/OrganizationAdmin";
import CleverTapUtils from "../commons/CleverTapUtils";
import Breadcumbs from "../Breadcumbs";

httpService.setupInterceptors();

class BlockedUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
            blockUserData: {},
            page: 0,
            size: 12,
            thresholdHeight: 1000,
            userSuccess: false,
            responseMessage: "",
            managePermission: false,
            userId: this.props?.location?.state?.userId ? this.props?.location?.state?.userId : authService.getUserDetailsFromLocalStorage().userId
        }
    }

    getBlockUserList = (page, size, concatWithPrevious) => {
        let cancelToken;
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request")
        }
        cancelToken = axios.CancelToken.source();
        this.setState({
            userSuccess: true
        })
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_userServiceURL}/block/user/list?userId=${this.state.userId}&page=${page}&size=${size}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then(response => {
            if (response && response.data && response.data.message === 'Success!') {
                let pageData = response.data.body;
                if (concatWithPrevious) {
                    pageData.content = this.state.blockUserData.content.concat(pageData.content);
                }
                this.setState({
                    blockUserData: pageData,
                    userSuccess: false,
                    responseMessage: response.data.message
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getMoreBlockUserData = () => {
        if (this.state.blockUserData && this.state.blockUserData.content && !this.state.blockUserData?.last) {
            let pageable = this.state.blockUserData && this.state.blockUserData.pageable;
            this.setState({
                userSuccess: true,
                page: pageable.pageNumber + 1
            }, () => {
                this.getBlockUserList(this.state.page, this.state.size, true)
            });
        }
    }

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.imageUrl;
    }

    redirectToProfile = (customUrl) => {
        this.props.history.push({
            pathname: '/profile/'+customUrl  
        })
    }

    onSuccessBlockUnblockUser = (id) => {
        let blockUserDataList = this.state.blockUserData;
        const index = blockUserDataList && blockUserDataList?.content.findIndex((data) => data.id === id);
        if(index > -1) {
            blockUserDataList.content.splice(index, 1)
        }
        this.setState({
            blockUserData: blockUserDataList
        })
    }

    componentDidMount() {
        this.getBlockUserList(0, this.state.size, false);
        CleverTapUtils.eventClick({}, "Display Blocked Users");
        document.title = "Explore a new world of Naturalists | WeNaturalists";
    }

    render() {
        const { loginSuccess, blockUserData, thresholdHeight, userSuccess, responseMessage, managePermission, userId } = this.state;
        if (!loginSuccess) {
            return <Redirect to="/login" />;
        } else {
            return (
                <div className="wrapper projectpage networkpage settingspage">
                    <Header />
                    <main className="dgcontainer hm3col">
                        <div className="container">
                            <div className="row">
                                <LeftPanelSettings userId={userId} />
                                <div className="col-md-9">
                                    
                                    <div className="projectinn followersall stdGlobalPage">
                                        <Breadcumbs crumbs={[{ "name": "Dashboard Settings", "path": "/dashboard-settings" }, { "name": `Blocked Users`, "path": null } ]} />
                                        <div className="projtopbar">
                                            <div className="projtopbarleft">
                                                <h1>Blocked Users</h1>
                                            </div>
                                        </div>

                                        {userId && <OrganizationAdmin userId={userId} isBottomSpace={true} />}
                                        
                                        <div className="invitationbott">
                                            {responseMessage === "Success!" && blockUserData?.content && blockUserData?.content.length === 0 && (
                                                <div class="zdata zfeed">
                                                    <div className="imgbox">
                                                        <img
                                                            src="https://cdn.dscovr.com/images/zroProjectsMyProject-new.webp"
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src =
                                                                "https://cdn.dscovr.com/images/zroProjectsMyProject-new.png";
                                                            }}
                                                            alt=""
                                                            width={500}
                                                            />
                                                    </div>
                                                    <div class="desc">
                                                        <p>
                                                            No data available
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            {blockUserData?.content && blockUserData.content.length > 0 && (
                                                <div className="peoplefollow globalfollow">
                                                    <InfiniteScroll
                                                        loadMore={this.getMoreBlockUserData.bind(this)}
                                                        hasMore={!userSuccess && !blockUserData.last}
                                                        threshold={thresholdHeight}
                                                        loader={<span style={{ textAlign: "center", display: "block" }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "inline-block",
                                                                    marginTop: "100px"
                                                                }}
                                                            >
                                                                <Loader
                                                                    type="Grid"
                                                                    color="#dcde63"
                                                                    height={60}
                                                                    width={60}
                                                                    timeout={8000}
                                                                />
                                                            </div>
                                                        </span>}
                                                    >
                                                        <ul>
                                                            {blockUserData?.content && blockUserData?.content.map((item) => (
                                                                <li>
                                                                    <figure onClick={(e) => this.redirectToProfile(item.customUrl)} className="users">
                                                                        <img src={item?.imageUrl ? getCDNUrl(this.getOptimizedImage(item)) : (item.type === "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.webp" : "https://cdn.dscovr.com/images/DefaultBusiness.webp")} alt="" />
                                                                    </figure>
                                                                    <div>
                                                                        <h5>
                                                                            <span onClick={(e) => this.redirectToProfile(item.customUrl)}>{item.type === "COMPANY" ? item.username : CapitalizeFirstLetter(item.username)}</span>
                                                                        </h5>
                                                                        {(item.persona || !_.isEmpty(item.addressDetail)) && 
                                                                            <span className="pfollowlist">
                                                                                <p>
                                                                                    {!_.isEmpty(item.addressDetail) && <span className="address">{item.addressDetail?.country}</span>}
                                                                                    {item.persona && <span>{item.persona}</span>}
                                                                                </p>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                    <div className="contxmenu">
                                                                        <button type="button" className="btn"
                                                                            data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false">
                                                                            <i className="iKebab" /></button>
                                                                        <div className="dropdown-menu fadeIn animated">
                                                                            <UserBlockComponent userDetails={item} key={item.id} pageName={"BLOCK_LIST"} onSuccessBlockUnblockUser={this.onSuccessBlockUnblockUser} />
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </InfiniteScroll>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            )
        }
    }
}

export default BlockedUserList;