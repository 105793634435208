import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCDNUrl } from "../../../commons/CommonFunctions";
import { redirectToProfile, redirectToCircle, getFormattedDate, renderTaggedUsers, options } from './HashtagsCommonFunction';
import CommonHashTagUserSection from "./CommonHashTagUserSection";
import "react-image-gallery/styles/css/image-gallery.css";
import { authService } from "../../../auth";
import MutualConnect from "../../../commons/MutualConnect";
import Follow from "../../../commons/Follow";
import { renderToString } from "react-dom/server";
import PresentationPostView from "../../../post/PresentationPostView";
import Linkify from "react-linkify";
import stripHtml from "string-strip-html";
import ReactHtmlParser from "react-html-parser";

const CommonHashTagsDocuments = (props) => {
    const [userData, setUserData] = useState(authService.getUserDetailsFromLocalStorage())
    const [loginSuccess] = useState(authService.isAuthenticated());

    useEffect(() => {
    }, [])

    const getOptimizedImage = (personalInfo) => {
        if (
            personalInfo.resizedProfileImages &&
            personalInfo.resizedProfileImages["200x200"] != null
        ) {
            return personalInfo.resizedProfileImages["200x200"];
        }
        return personalInfo.userProfileImage;
    };

    let linkifiedHtml =
        '<p class="pdtlsLink">' +
        renderTaggedUsers(renderToString(
            <Linkify
                componentDecorator={(
                    decoratedHref,
                    decoratedText,
                    key
                ) => (
                    <a
                        target="blank"
                        href={decoratedHref}
                        key={key}
                    >
                        {decoratedText}
                    </a>
                )}
            >
                {props.item.description
                    ? stripHtml(props.item.description)
                    : ""}
            </Linkify>
        )).replace(/#+/g, '#') +
        "</p>";
    linkifiedHtml = renderToString(
        ReactHtmlParser(linkifiedHtml, options)
    );

    return (
        <div className="explstorieslist">
            <div className="explstoriesdesc">
                <div className="postbookMhead">
                    {!props.item.userEntityType ? (
                        <figure
                            style={{ cursor: "pointer" }}
                            className="complogo"
                            onClick={(e) => { e.preventDefault(); redirectToProfile(props.item.userCustomUrl) }}
                        >
                            <img
                                src={props.item.userProfileImage && props.item.userProfileImage !== "" ?
                                    getCDNUrl(getOptimizedImage(props.item))
                                    : props.item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                }
                                alt=""
                            />
                        </figure>
                    ) : (
                        <figure
                            style={{ cursor: "pointer" }}
                            className="complogo"
                            onClick={(e) => { e.preventDefault(); redirectToCircle(props.item.params && props.item.params["circleSlug"]) }}
                        >
                            <img
                                src={props.item.params && props.item.params["circleImage"] != null
                                    ? getCDNUrl(props.item.params && props.item.params["circleImage"])
                                    : "https://cdn.dscovr.com/images/circleDefault.webp"
                                }
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "https://cdn.dscovr.com/images/circleDefault.png";
                                }}
                                alt=""
                            />
                        </figure>
                    )}

                    <div style={{ paddingRight: '0' }}>
                        <CommonHashTagUserSection item={props.item} />

                        <div className="d-flex hpostdtls">
                            {props.item && props.item.country &&
                                <span className="address">
                                    <i className="iLocation" />{props.item && props.item.country}
                                </span>
                            }
                            <span className="designation" style={{ cursor: 'pointer' }}>
                                <Link to={`/post/${props.item.id}`}>{getFormattedDate(props.item.createTime)}</Link>
                            </span>

                            {loginSuccess ? ((props.item.connectDepth && props.item.userId != userData.userId) ?
                                <MutualConnect connectDepth={props.item.connectDepth}></MutualConnect> : null) : null
                            }
                            {loginSuccess && (props.item.connectDepth !== 1 && props.item.connectDepth !== 2) ?
                                props.item && (!props.item.connectDepth || (props.item.connectDepth && props.item.connectDepth < 1)) && !props.item.userEntityType ?
                                    <Follow followed={props.item.followed} userId={props.item.userId} deactivated={props.item.deactivated} /> : null : null
                            }
                        </div>

                    </div>
                </div>
                <h4>
                    {props.item.hashTags && props.item.hashTags.map((hashTag, index) => {
                        return <span> <a href={'/explore-hashtag/' + hashTag} className="hashLinkPost">{hashTag}</a></span>
                    })
                    }
                </h4>
                {props.item.description &&
                    <div className="descinn">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: linkifiedHtml,
                            }}
                        />
                    </div>
                }
                {props.item.attachmentIds?.length > 0 && (props.item.attachmentIds[0].attachmentType === "PPT" || props.item.attachmentIds[0].attachmentType === "PDF") &&
                    <PresentationPostView attachments={props.item.attachmentIds[0]} />
                }
            </div>
        </div>
    )
}
export default CommonHashTagsDocuments;