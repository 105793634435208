import React from 'react'
import axios from 'axios'
import {authService} from '../auth';
import httpService from '../AxiosInterceptors';
import {Redirect, withRouter} from "react-router-dom";
import * as _ from "lodash";
import {cloneDeep} from "lodash";
import Form from "react-bootstrap/Form";
import {
    GetMessageForCategory,
    GetMessageForEmail,
    GetMessageForEvent,
    GetMessageForNotification
} from "../utils/StringUtility";
import OrganizationAdmin from '../commons/OrganizationAdmin';
import CleverTapUtils from '../commons/CleverTapUtils';
import Breadcumbs from '../Breadcumbs';

httpService.setupInterceptors();

class NotificationSettingDetails extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            errors: {},
            obj: {},
            emailObj: {},
            otherObj: {},
            settingDetails: {},
            notificationType: 'DASHBOARD',
            isActive: true,
            userType: this.props?.location?.state?.userId && this.props?.location?.state?.userId !== authService.getUserDetailsFromLocalStorage().userId ? "COMPANY" : authService.getUserDetailsFromLocalStorage().type
        };
    }

    componentDidMount() {
        window.$('.knotiacrdncrd .collapse').on('shown.bs.collapse', function () {
            window.$(this).parent().addClass('active');
        });

        window.$('.knotiacrdncrd .collapse').on('hidden.bs.collapse', function () {
            window.$(this).parent().removeClass('active');
        });
        this.setInitialMethod();
        this.getSettingDetails(this.state.notificationType);
    }

    changeValue = (userEvent, originalCategory, notificationSwitchValue) => {
        let settingDetails = cloneDeep(this.state.settingDetails);
        if (!settingDetails[userEvent]) {
            settingDetails[userEvent] = {};
        }
        settingDetails[userEvent][originalCategory] = notificationSwitchValue;

        if (originalCategory === "ALL") {
            for (let i = 0; i < this.state.obj[userEvent].length; i++) {
                let category = this.state.obj[userEvent][i];
                settingDetails[userEvent][category] = notificationSwitchValue;
            }
        }
        this.setState({settingDetails: settingDetails})
    }

    getSettingDetails = (notificationType) => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_userServiceURL + '/backend/notificationSetting/list?userId=' + this.props.userId + "&notificationType=" + notificationType,
            withCredentials: true
        }).then(response => {
            if (response && response.data.body !== null) {
                this.setState({"settingDetails": response.data.body});
            }

        }).catch((err) => {
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    error: {message: err.response.data.message, err: err.response}
                });
            }
        });
        CleverTapUtils.eventClick({}, notificationType === "DASHBOARD" ? "Manage Notification Settings" : "Manage Email Settings");
    }

    handleTabChange = (evt, value) => {
        this.setState({'notificationType': value}, () => {
            this.getSettingDetails(this.state.notificationType)
        });
    }


    setInitialMethod() {
        let obj = {};
        obj['LIKE'] = ['ALL', 'POLL', 'FORUM', 'PROJECT', 'POST', 'COMMENT'];
        obj['COMMENT'] = ['ALL', 'FORUM', 'POST'];
        obj['POSTSHARE'] = ['ALL', 'POLL', 'FORUM', 'POST', 'PROJECT'];
        obj['PROJECT_CREATE'] = ['PROJECT'];

        let otherObj = {};
        otherObj['LIKE_REPLY'] = {};
        otherObj['LIKE_REPLY']['COMMENT'] = ['COMMENT'];
        otherObj['COMMENT_REPLY'] = {};
        otherObj['COMMENT_REPLY']['COMMENT'] = ['COMMENT'];
        otherObj['POST_AS_CAUSE'] = {};
        otherObj['POST_AS_CAUSE']['POST'] = ['POST'];
        otherObj['POST_AS_PROJECT'] = {};
        otherObj['POST_AS_PROJECT']['POST'] = ['POST'];
        otherObj['MAKE_ADMIN'] = {};
        otherObj['MAKE_ADMIN']['MESSAGING'] = ['MESSAGING'];
        otherObj['VOTE'] = {};
        otherObj['VOTE']['POLL'] = ['POLL'];
        if( this.state.userType == "COMPANY") {
            otherObj['REQUEST_RECEIVED'] = {};
            otherObj['REQUEST_RECEIVED']['EMPLOYEE'] = ['EMPLOYEE'];
        }
        if( this.state.userType !== "COMPANY") {
            otherObj['REQUEST_RECEIVED_ADMIN'] = {};
            otherObj['REQUEST_RECEIVED_ADMIN']['EMPLOYEE'] = ['EMPLOYEE'];
        }
        otherObj['REQUEST_ACCEPTED'] = {};
        otherObj['REQUEST_ACCEPTED']['EMPLOYEE'] = ['EMPLOYEE'];
        otherObj['ADD'] = {};
        otherObj['ADD']['ENDORSEMENT'] = ['ENDORSEMENT'];
        otherObj['POLL_CREATE'] = {};
        otherObj['POLL_CREATE']['POLL'] = ['POLL'];
        otherObj['FORUM_CREATE'] = {};
        otherObj['FORUM_CREATE']['FORUM'] = ['FORUM'];
        otherObj['MENTION'] = {};
        otherObj['MENTION']['POST'] = ['POST'];
        otherObj['POSTCOMMENTMENTION'] = {};
        otherObj['POSTCOMMENTMENTION']['POST'] = ['POST'];
        otherObj['POSTLIKEMENTION'] = {};
        otherObj['POSTLIKEMENTION']['POST'] = ['POST'];

        let emailObj = {};
        emailObj['NETWORK'] = ['CONNECT_INVITATION', 'CONNECT_INVITATION_RESEND'];
        /* emailObj['PROJECT'] =['PROJECT_CREATE',
                              'OUTSIDE_WN_PARTICIPANT_REQUEST',
                              'OFFLINE_PARTICIPANT_REQUEST',
                              'PROJECT_CANCELLED',
                              'PROJECT_APPLY'] ;*/
        emailObj['POLL'] = ['POLL_CREATE'];
        emailObj['FORUM'] = ['FORUM_CREATE'];
        if( this.state.userType !== "COMPANY") {
            emailObj['EMPLOYEE'] = ['REQUEST_RECEIVED_ADMIN'];
        }
        /*emailObj ['EXPLORE'] = ['WEEKLY_EXPLORE_NEWS'] ;*/

        this.setState({
            obj: obj,
            otherObj: otherObj,
            emailObj: emailObj
        });
    }


    updateSetting = (evt, userEvent, category) => {
        let notificationSwitchValue = false;
        notificationSwitchValue = !this.getIsChecked(userEvent, category);
        let postBody = {
            "userId": this.props.userId,
            "notificationCategory": category,
            "userEvent": userEvent,
            "notificationType": this.state.notificationType,
            "active": notificationSwitchValue
        };
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/backend/notificationSetting/create',
            data: postBody,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 201) {
                this.changeValue(userEvent, category, notificationSwitchValue);
            }
        }).catch((err) => {
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    error: {message: err.response.data.message, err: err.response}
                });
            }
        });
    }

    getEmailData() {
        return (
            <div className="">
                <div className="projectinn settings">
                    <div className="settcard dgcard knotisettbx" style={{marginTop: "0"}}>
                        <div className="privecyrow notidesc">
                            <p>Emails are a great way to keep you updated on activities of your connects and members you
                                follow. You may change your settings as per your requirements. </p>
                        </div>
                        {Object.entries(this.state.emailObj).map(([category, index]) => {
                            return <div className="privecyrow" key={category}>
                                <div className="row">
                                    <div className="col-md-5">
                                        <h2>{category == "NETWORK" ? "Networking" :
                                            category == "FORUM_POLL" ? "Forum/Poll" : category == "FORUM" ? "Forum" : category == "POLL" ? "Poll" : category == "EMPLOYEE" ? "Employee" : category}</h2>
                                        <p>{GetMessageForCategory(category)}</p>
                                    </div>
                                    <div className="col-md-7">
                                        {this.state.emailObj[category].map((userEvent, idx) => {
                                            return <div className="privacySettrow " key={userEvent + "-" + category}>
                                                <label>{GetMessageForEmail(userEvent, category)}</label>
                                                <span><Form.Check
                                                    type="switch"
                                                    id={'custom-switch' + userEvent + "_" + category}
                                                    checked={this.getIsChecked(userEvent, category)}
                                                    onChange={(e) => this.updateSetting(e, userEvent, category)}
                                                >
                                                           </Form.Check></span>

                                            </div>

                                        })}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }

    getIsChecked(userEvent, category) {
        if (!this.state.settingDetails || this.state.settingDetails[userEvent] == undefined) {
            return true;
        }
        if (category === 'ALL') {
            if (this.state.settingDetails && this.state.settingDetails[userEvent]) {
                let allSetting = true;
                Object.keys(this.state.settingDetails[userEvent]).forEach((categoryKey) => {
                    // if (categoryKey !== "ALL") {
                    //     allSetting &= this.state.settingDetails[userEvent][categoryKey];
                    // }
                    allSetting &= this.state.settingDetails[userEvent][categoryKey];
                })
                return allSetting;
            }
        } else {
            let isActiveForAll = this.state.settingDetails[userEvent]['ALL'];
            let isActiveForUserEventCategory = this.state.settingDetails[userEvent][category];

            if (isActiveForUserEventCategory !== undefined && isActiveForUserEventCategory !== null) {
                return isActiveForUserEventCategory;
            } else if (isActiveForAll !== undefined && isActiveForAll !== null) {
                return isActiveForAll;
            }
        }
        return true;
    }


    getNotificationData() {
        return (
            <div className="">
                <div className="projectinn settings">
                    <div className="settcard dgcard knotisettbx" style={{marginTop: "0"}}>
                        <div className="privecyrow notidesc">
                            <p>Notifications makes it easy to keep a track of events that occur on WeNaturalists related
                                to you,
                                your connects and the members you follow.
                                You may change settings as per your requirements.</p>
                        </div>
                        {Object.entries(this.state.obj).map(([userEvent, index]) => {
                            return <div className="privecyrow" key={userEvent}>
                                <div className="row">
                                    <div className="col-md-5">
                                        <h2>{userEvent == "POSTSHARE" ? "Sharing" :
                                            userEvent == "COMMENT" ? "Comment" :
                                                userEvent == "LIKE" ? "Like" :
                                                    userEvent == "OTHERS" ? "Others" :
                                                        userEvent == "PROJECT_CREATE" ? "Project Create" : userEvent}</h2>
                                        <p>{GetMessageForEvent(userEvent)}</p>
                                    </div>
                                    <div className="col-md-7">
                                        {this.state.obj[userEvent].map((category, idx) => {
                                            return <div className="privacySettrow" key={userEvent + "-" + category}>
                                                <label>{GetMessageForNotification(userEvent, category)}</label>
                                                <span><Form.Check
                                                    type="switch"
                                                    id={'custom-switch' + userEvent + "_" + category}
                                                    checked={this.getIsChecked(userEvent, category)}
                                                    onChange={(e) => this.updateSetting(e, userEvent, category)}
                                                >
                                                   </Form.Check></span>
                                            </div>

                                        })}
                                    </div>
                                </div>
                            </div>
                        })}
                        <div className="privecyrow">
                            <div className="row">
                                <div className="col-md-5">
                                    <h2>Others</h2>
                                    <p>You will receive notifications when members wish to engage with you</p>
                                </div>
                                <div className="col-md-7">

                                    {
                                        Object.keys(this.state.otherObj).map((userEvent) => {
                                            return <>
                                                {Object.keys(this.state.otherObj[userEvent]).map((ignore) => {
                                                    return <div className="knotiunderLine"
                                                                key={userEvent + "_" + ignore}>
                                                        {this.state.otherObj[userEvent][ignore].map((category) => {
                                                            return <div className="privacySettrow"
                                                                        key={userEvent + "-" + category + "_1"}>


                                                                <label>{GetMessageForNotification(userEvent, category)}</label>
                                                                <span><Form.Check
                                                                    type="switch"
                                                                    id={'custom-switch' + userEvent + "_" + category}
                                                                    checked={this.getIsChecked(userEvent, category)}
                                                                    onChange={(e) => this.updateSetting(e, userEvent, category)}
                                                                >
                                                           </Form.Check></span>

                                                            </div>

                                                        })}
                                                    </div>
                                                })
                                                }
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )

    }

    render() {
        if (!this.state.loginSuccess) {
            return <Redirect to='/login'/>
        } else {
            return <div className="col-md-9">     
                <Breadcumbs crumbs={[{ "name": "Dashboard Settings", "path": "/dashboard-settings" }, { "name": `Manage Notification`, "path": `/notification-setting`, handleTabFunc: (e) => this.handleTabChange(e, "DASHBOARD") }, { "name": `${this.state.notificationType === "DASHBOARD" ? "Notification" : "Email"}`, "path": "/notification-setting" } ]} />           
                <div className="projectinn settings">

                    <div className="settcard dgcard">
                        <div className="settingstopbar">
                            <div className="mcustHoriz">
                                <ul id="tabs" className="nav">
                                    <li className="nav-item">
                                        <a onClick={(e) => this.handleTabChange(e, 'DASHBOARD')} className={"nav-link " + (this.state?.notificationType === "DASHBOARD" ? " active" : "")}>Notification</a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={(e) => this.handleTabChange(e, 'EMAIL')} className={"nav-link " + (this.state?.notificationType === "EMAIL" ? " active" : "")}>Email</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {this.props.userId && <OrganizationAdmin userId={this.props.userId} />}
                        <div id="tabsContent" className="tab-content">
                            
                            {this.state?.notificationType === "DASHBOARD" && 
                                <div id="tab1" className="tab-pane fade active show">
                                    {this.getNotificationData()}
                                </div>
                            }
                            {this.state?.notificationType === "EMAIL" && 
                                <div id="tab2" className="tab-pane fade active show">
                                    {this.getEmailData()}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        }
    }
}

export default withRouter(NotificationSettingDetails)
