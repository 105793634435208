import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { Link, NavLink } from "react-router-dom";
import Chat from "./FaqChat/chat";
import { isMobileDevice, restrictedPageRedirection, userAgentGetData } from "./commons/CommonFunctions";
import { connect } from 'react-redux';
import { setWpondYearData } from './reducer/wpond/WpondReducer';

class BypassHeader extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            pathname: '',
            year: ""
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((window.location.pathname === '/message' || window.location.pathname === '/message-user') && isMobileDevice()) {
            this.setState({
                pathname: 'message'
            })
        }
    }

    componentDidMount() {
        window.$(document).on('contextmenu', "img:not('.logotop')", function () {
            return false;
        });
        // $("#header .iHamburger").on('click', function (event) {
        //     $('.leftpanel').addClass('navactive');
        //     $('.dgcontainer').addClass('navactive');
        // });
        // $("body, html").on('click', function (event) {
        //     if (!$(event.target).closest('.leftpanel, #header .iHamburger').length) {
        //         $('.leftpanel').removeClass('navactive');
        //         $('.dgcontainer').removeClass('navactive');
        //         $('.dropdown-menu.show').removeClass('show');
        //     }
        // });


        window.$('#header .iCross').hide();
        window.$("#header .iHamburger").on('click', function (event) {
            window.$('.leftpanel').addClass('navactive');
            window.$('.dgcontainer').addClass('navactive');
            window.$('.staticPage').addClass('navactive');
            window.$('#header .iHamburger').hide();
            window.$('#header .iCross').show();
        });
        $("body, html").on('click', function (event) {
            if (!$(event.target).closest('#header .iHamburger, .read-more-less-btn').length) {
                $('.leftpanel').removeClass('navactive');
                $('.dgcontainer').removeClass('navactive');
                window.$('.staticPage').removeClass('navactive');
                $('.dropdown-menu.show').removeClass('show');
                window.$('#header .iHamburger').show();
                window.$('#header .iCross').hide();
            }
        });
        window.$('.topDropdownMob .dropdown > a').on("click", function (e) {
            // var submenu = $(this);
            // $('.topDropdownMob .dropdown-menu').removeClass('show');
            // submenu.next('.topDropdownMob .dropdown-menu').addClass('show');
            e.stopPropagation();
            $(this).next('.dropdown-menu').toggleClass('show');
        });


        window.$('.usermenu .dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.usermenu .dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.usermenu .dropdown-menu').addClass('show');
            e.stopPropagation();
        });
        this.props.setWpondYearData(false);
    }

    render() {
        return <>
            <header id="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 logowrap">
                            <div className="logotop">
                                <Link to={"/"}>
                                    <img
                                        className="logotop"
                                        src={"https://cdn.dscovr.com/images/logoWeNaturalists.svg"} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-9 dgmenuwrap">
                            <div className="dgnav">
                                <div className="dgnavinn">
                                    <ul id="menu" className="menu">
                                        <li><NavLink exact activeClassName="active" to="/login">Login</NavLink></li>
                                        <li><NavLink exact activeClassName="active" to="/signup" className="signActive">Signup</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="usertoppanel">
                                <div className="usermenu">
                                    <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"><i className="iMeatballs" /></button>
                                    <div className="dropdown-menu topDropdownMob fadeIn animated">                                        
                                        {this.props.yearData && (this.props.yearData.status === "UPCOMING" ||  (this.props.yearData.status === "COMPLETED" && this.props.yearData.showInThreeDot)) && <Link className="dropdown-item" to={`/people-of-nature-award/${this.props.yearData.status === "UPCOMING" ? "upcoming" : "event"}/${this.props.yearData.year}`}>People of Nature Awards</Link>}
                                        <div className="dropdown-item dropdown-submenu">
                                            <a data-toggle="dropdown" href="javascript:void(0)">About WeNaturalists</a>
                                            <div className="dropdown-menu fadeIn animated">
                                                <a className="dropdown-item" href="/about-us">About Us</a>
                                                <Link className="dropdown-item" to="/how-we-impacts">How We Impacts</Link>
                                                <Link className="dropdown-item" to="/how-it-works">How It Works</Link>
                                                <Link className="dropdown-item" to="/world-people-of-nature-day">People of Nature Day</Link>
                                            </div>
                                        </div>
                                        <div className="dropdown-item dropdown-submenu">
                                            <a data-toggle="dropdown" href="javascript:void(0)">Terms of Use</a>
                                            <div className="dropdown-menu fadeIn animated">
                                                <Link className="dropdown-item" to="/UserAgreement">User agreement</Link>
                                                <Link className="dropdown-item" to="/PrivacyPolicy">Privacy Policy</Link>
                                                <Link className="dropdown-item" to="/TermsConditions">Terms & conditions</Link>
                                                <Link className="dropdown-item" to="/CookiePolicy">Cookie Policy</Link>
                                                <Link className="dropdown-item" to="/CommunityGuidelines">Community Guidelines</Link>
                                                <Link className="dropdown-item" to="/refund-policy">Refund Policy</Link>
                                                <Link className="dropdown-item" to="/studio-terms-conditions">Studio Terms & conditions</Link>
                                            </div>
                                        </div>
                                        <div className="dropdown-item dropdown-submenu">
                                            <a data-toggle="dropdown" href="javascript:void(0)">Support</a>
                                            <div className="dropdown-menu fadeIn animated">
                                                <Link className="dropdown-item" to="/FaQ">FAQ & Support</Link>                                                
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                                {/* this.props.notFound !== true &&  */<i className="iHamburger" /> /* iMeatballs */}
                                <i className="iCross"
                                    style={{ display: this.props.hamburgerVisibility === false ? 'none' : '' }} />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.pathname === 'message' ? <></> : <Chat />}
            </header>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        yearData: state.WpondReducer?.yearData

    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        setWpondYearData: (state) => dispatch(setWpondYearData(state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BypassHeader);
