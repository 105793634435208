import React, { Component } from 'react';
import {authService} from "../auth";
import {Redirect} from "react-router-dom";

class ProfileDeactivationUpdateConfirmPopUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            messages: null,
            isLoading: true,
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            data: {},
            message: '',
            success: false,
            redirectMainPage:false

        };

    }

    componentWillUnmount() {
        window.$('#profiledeactivationUpdateConfirmPopup').modal('hide');
    }

    redirect = (event)=>{
        event.preventDefault();
        window.$('#profiledeactivationUpdateConfirmPopup').modal('hide');
        this.setState({'redirectMainPage':true});

    }

    render() {
        if(this.state.redirectMainPage){
            return  <Redirect
                to={{
                    pathname: "/security-and-login"
                }}
            />
        }

        return <>

            <div className="modal fade dgpopup confirmpop" id="profiledeactivationUpdateConfirmPopup" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="confirm-popup-modal-body">
                            <p>Your request for transfer of admin rights has been submitted successfully.</p>
                        </div>
                        <div className="modal-footer confirm-popup-modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.redirect}>OK</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    }


}

export default ProfileDeactivationUpdateConfirmPopUp


