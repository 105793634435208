import axios from "axios";
import { Apis } from "../config/api";

export const getFaqById = async (id) => {
    try {
        const response = await axios.get(Apis.GetFaqById(id));
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getFaqListData = async (data) => {
    try {
        const response = await axios.get(Apis.GetFaqListData(data));
        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response?.data;
    }
}