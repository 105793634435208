import React, { Component } from 'react';
import axios from "axios";
import { authService } from "../../../auth";
import * as _ from "lodash";
import { convertUnicode } from "../../../commons/CommonFunctions";
import InfiniteScroll from "react-infinite-scroller";
import { Redirect, withRouter } from "react-router-dom";
import { getProjectProfileVisibility } from '../../../services/ProjectApi';
import ProfileVisibility from '../../../commons/ProfileVisibility';


class ProjectVisibilitySection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            projectData: {},
            thresholdHeight: 1000,
            projectSuccess: false,
            isView: false,
            feedPageShow: true,
            currentData: {},
            openProfileVisibilityModal: false
        }
    }

    componentDidMount() {

    }

    setProjectDetails = (item) => {
        this.setState({
            projectId: item.projectId,
            feedPageShow: item.feedPageShow,
            slug: item.slug,
            isView: true,
        })
        this.props.history.push({
            pathname: `/project/feeds/${item.slug}`
        });
    };


    handleUnHideModal = (id) => {
        let data = {
            userId: this.state.userId,
            activityId: id
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/hidden/unhide',
            data: data,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 202) {
                if (this.props.getUpcomingProject) {
                    this.props.getUpcomingProject();
                }

            } else {
                this.setState({ 'isSubmitted': false });
            }
        }).catch((err) => {
            if (err && err.response && err.response.status === 409) {
                this.setState({ 'error': 'Entity has already been unhidden', 'isSubmitted': false });
            } else {
                this.setState({ 'isSubmitted': false });
            }
        });
    }

    eventsAndTrainingBlock = (item) => {
        return (

            <div key={item.projectId + "opportunity"} className="col-md-4" style={{padding: "10px"}}>
                <div className="projectHiddenCol">
                    <figure className="bannimg" style={item.feedPageShow ? { cursor: 'pointer' } : { cursor: 'default' }} onClick={(e) => this.setProjectDetails(item)}>
                        <img
                            src={!_.isNull(item.coverImage) && !_.isUndefined(item.coverImage) ? item.coverImage : 'https://cdn.dscovr.com/images/project-default.webp'} onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/project-default.jpg" }}
                            alt="" />
                    </figure>
                    <div className="projeventdesc">
                        <span style={{ cursor: 'pointer' }} onClick={(e) => this.setProjectDetails(item)}>{item.type}</span>
                        <h4 onClick={(e) => this.setProjectDetails(item)}><a
                            style={{ cursor: 'pointer' }}>

                            {item?.title.split("\\n").map(title => (<>{convertUnicode(title)}<br /></>))}
                        </a></h4>
                    </div>
                    {this.projectVisibility(item)}
                </div>
            </div>
        );
    };

    completedBlock = (item) => {
        return (
            <div className="col-md-4" style={{padding: "10px"}}>
                <div className="projectHiddenCol">
                    <figure className="bannimg" style={item.feedPageShow ? { cursor: 'pointer' } : { cursor: 'default' }} onClick={(e) => this.setProjectDetails(item)}>
                        <img
                            src={!_.isNull(item.coverImage) && !_.isUndefined(item.coverImage) ? item.coverImage : 'https://cdn.dscovr.com/images/project-default.webp'} onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/project-default.jpg" }}
                            alt="" />
                    </figure>
                    <div className="projeventdesc">
                        <span style={item.feedPageShow ? { cursor: 'pointer' } : { cursor: 'default' }} onClick={(e) => this.setProjectDetails(item)}>{item.type}</span>
                        <h4 style={item.feedPageShow ? { cursor: 'pointer' } : { cursor: 'default' }} onClick={(e) => this.setProjectDetails(item)}>{convertUnicode(item?.title)}</h4>
                    </div>
                    {this.projectVisibility(item)}
                </div>
            </div>
        );
    };

    projectVisibility = (item) => {
        return (
            <a className="unhidebtn" href="javascript:void(0)" onClick={(e) => {e.preventDefault(); this.setState({ openProfileVisibilityModal: item.id, currentData: item});}}><i class="iEye_OL" /></a>
        )
    }

    fetchProjectProfileVisibility = async (page, limit, concatWithPrevious) => {
        let cancelToken;
        // Cancel Token Creation
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Cancelling the previous request")
        }
        cancelToken = axios.CancelToken.source();
        let response = await getProjectProfileVisibility({ userId: this.props.userId, showOnlyMyPost: this.props.showOnlyMyPost, page: page, size: limit });
        if (response?.message === "Success!") {
            let pageData = response.body
            if (concatWithPrevious && this.state.projectData && this.state.projectData.content) {
                pageData.content = this.state.projectData.content.concat(pageData.content)
            }
            this.setState({
                projectData: pageData,
                projectSuccess: false
            })
        } else {
            this.setState({
                projectSuccess: false
            })
        }
    }

    loadMoreProject = () => {
        if (this.state.projectData?.content && !this.state.projectData?.last) {
            this.setState({
                projectSuccess: true
            })
            let pageable = this.state.projectData?.pageable;
            this.fetchProjectProfileVisibility(pageable.pageNumber + 1, 12, true)
        }
    }

    triggerProfileVisibilityClose = () => {
        this.setState({
            openProfileVisibilityModal: null
        })
    }

    triggeredProfileVisibilityCloseOnSuccess = () => {
        this.fetchProjectProfileVisibility(0, 12, false);
    }

    componentDidMount() {
        this.fetchProjectProfileVisibility(0, 12, false);
    }

    render() {
        const { currentData, openProfileVisibilityModal } = this.state;
        if (this.state.isView && this.state.feedPageShow) {
            if (this.state.slug) {
                return <Redirect to={{ pathname: '/project/feeds/' + this.state.slug, state: { userId: this.state.userId } }} push />
            } else {
                return <Redirect to={{ pathname: '/project/feeds/' + this.state.projectId, state: { userId: this.state.userId } }} push />
            }
        }
        return (
            <div className="projgeneralsec projupcomingwrap">
                <div className="row">
                    {this.state.projectData?.content?.length === 0 &&
                        <div className="zdata col-12" style={{ marginTop: '-10px', textAlign: 'center' }}>
                            <div class="imgbox">
                                <img src="https://cdn.dscovr.com/images/zroProjectsOpportunity-new.webp"
                                onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/zroProjectsOpportunity-new.png"}} alt="" width={350} />
                            </div>
                            <div className="desc">
                                <p style={{ margin: '0px' }}>All your projects are visible in your profile</p>
                            </div>
                        </div>
                    }
                </div>
                <InfiniteScroll
                    loadMore={this.loadMoreProject.bind(this)}
                    hasMore={!this.state.projectSuccess && !this.state.projectData?.last}
                    threshold={this.state.thresholdHeight}
                >
                    <div className="row all">
                        {this.state.projectData && this.state.projectData.content?.map((item) => {
                            return (item && (item.secondaryEntityType === 'EVENT' || item.secondaryEntityType === 'TRAINING' || item.secondaryEntityType === 'EXPEDITION'
                                || item.secondaryEntityType === 'STORYBOOK' || item.secondaryEntityType === 'FUNDRAISE')) ?
                                this.eventsAndTrainingBlock(item)
                                : this.completedBlock(item)
                        })}
                    </div>
                </InfiniteScroll>
                {openProfileVisibilityModal && 
                    <ProfileVisibility
                        entityId={currentData.id}
                        entityType={currentData.secondaryEntityType}
                        visibleToProfileChecked={currentData.visibleToProfile}
                        triggerProfileVisibilityClose={this.triggerProfileVisibilityClose}
                        triggeredProfileVisibilityCloseOnSuccess={
                            this.triggeredProfileVisibilityCloseOnSuccess
                        }
                    />
                }
            </div>
        );
    }
}

export default withRouter(ProjectVisibilitySection);