import React from 'react';
import axios from "axios";

export const UserService = {
    setOtherUserData,
    setUserProfileData,
    fetchOtherUserData,
    getUserProfileData,
    getOtherUserData
};

function setUserProfileData(data) {
    localStorage.setItem('userProfileData', JSON.stringify(data));

}

function setOtherUserData(data) {
    localStorage.setItem('otherUserData', JSON.stringify(data));

}

function fetchOtherUserData(userId) {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_userServiceURL + `/user/userDetails/${userId}`,
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
    }).then(response => {
        if (response && response.data && response.data.message === 'Success!') {
            localStorage.setItem('otherUserData', JSON.stringify(response.data.body));
        }
    }).catch((err) => {
        console.log(err);
    });

}

function getUserProfileData() {
    let userData = localStorage.getItem('userProfileData');
    if (userData === undefined || userData === null) {
        return null;
    }
    return JSON.parse(userData);
}

function getOtherUserData() {
    let userData = localStorage.getItem('otherUserData');
    if (userData === undefined || userData === null) {
        return null;
    }
    return JSON.parse(userData);
}





