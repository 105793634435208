import React, { Component } from 'react';
import { authService } from '../../../auth';
import * as _ from "lodash";
import { projectFromTypeList } from '../../../commons/CommonFunctions';
import withReactContent from "sweetalert2-react-content";
import alertPopUp from "sweetalert2";
import { donationTopUpForEntity } from '../../../Donation/DonationCommonFunction';
import { withRouter } from 'react-router-dom';
import CleverTapUtils from '../../../commons/CleverTapUtils';

const ErorrMsgPopUp = withReactContent(alertPopUp);

class ProjectTypeSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            projectDonationConfig: this.props.projectDonationConfig,
            subscriptionConfig: this.props.subscriptionConfig,
            nominationData: {}
        }
    }

    addANewProjectHandler = async (activeForm, formType) => {
        let modal = '#projadd' + activeForm;
        if (activeForm !== "fundraise" || (activeForm === "fundraise" && (this.state.projectDonationConfig?.donateNowAvailable || this.state.subscriptionConfig?.projectConfig?.raiseFundCreate))) {
            window.$("#addpostpop").modal("hide");
            this.setState({ activeForm: activeForm }, () => {
                this.setState({ refresh: true });
                if (activeForm !== 'offLine') {
                    this.props.history.push({
                        pathname: '/project/create-projects',
                        state: {
                            formId: "projadd" + activeForm.toLowerCase(),
                            Key: activeForm,
                            type: activeForm,
                            mode: "insert",
                            isOffLine: formType === "ONLINE" ? false : true,
                            formVO: this.state.formVO,
                            isOfflineProject: formType === "ONLINE" ? false : true,
                            refresh: this.state.refresh,
                            nominationData: this.state?.nominationData
                        }
                    })
                } else {
                    window.$(modal).modal('show');
                }
                if(this.props.pageType === "PROJECT" && formType !== "ONLINE") {
                    CleverTapUtils.eventClick({}, "Filter Projects");
                }
                CleverTapUtils.eventClick({}, "Create "+activeForm) ;
            });
        } else {
            const swalval = await ErorrMsgPopUp.fire(donationTopUpForEntity(this.state.projectDonationConfig?.currentPlan, "PROJECT"));
            if (swalval && swalval.value === true) {
                this.props.history.push({
                    pathname: '/wenaturalists-pro',
                    state: {
                        userId: this.state.userData.userId
                    }
                })
            }
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.nominationData?.awardSection) {
            this.setState({
                nominationData: nextProps.nominationData
            })
        }
    }

    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }

    render() {
        const { formType, pageType } = this.props;
        return (
            <>
                {projectFromTypeList && projectFromTypeList.map((item) => {
                    return (
                        ((formType === "ONLINE" && item.value !== "storybook") || (formType === "OFFLINE" && item.value !== "job")) &&
                        <div className={pageType === "PROJECT" ? '' : "col-6 col-sm-4"}>
                            <a href="javascript:void(0)" className={pageType === "PROJECT" ? "dropdown-item" : "postCard"} name={item.value} onClick={(e) => this.addANewProjectHandler(item?.value, formType)}>
                                {item.name}
                            </a>
                        </div>
                    )
                })}
            </>
        )
    }
}

export default withRouter(ProjectTypeSection);
