import React, { Component } from "react";
import { getCDNUrl, getOptimizedArticleImage } from "../commons/CommonFunctions";

class ArticleUnauthItem extends Component {
    constructor(props) {
        super(props);
        this.state={

        }
    }

    redirectToArticleDetail = (url) => {
        if (url !== undefined && url !== null) {
            if (window.ReactNativeWebView) {
                window.location.href = url;
            } else {
                window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000");
            }
        }
    }

    render() {
        return (
            <div className="hpostconttop">
                <span style={{position: 'absolute',
                            top: '0',
                            height: '100%',
                            width: '100%',
                            zIndex: '22', cursor: 'pointer'}} onClick={e => {
                                e.preventDefault();
                                this.redirectToArticleDetail('/explore-detail/article/' + this.props.item.slug)
                            }}></span>                
                <div className="postarticleblock">
                    <div className="postarticleimg">
                        {
                            this.props.item.attachmentIds && this.props.item.attachmentIds[0] && this.props.item.attachmentIds[0].attachmentUrl ?
                                <img
                                    src={getCDNUrl(getOptimizedArticleImage(this.props.item.attachmentIds[0]))}
                                    alt="" /> :
                                <img src="https://cdn.dscovr.com/images/banner-explore-blog-small.webp"
                                    onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.dscovr.com/images/banner-explore-blog-small-new.png" }}
                                    alt="" />
                        }
                    </div>
                    <div className="postarticledesc">
                        <h2 className="articlePostTitle">{this.props.item.title}</h2>
                        <div className="padesctxt"
                            dangerouslySetInnerHTML={{
                                __html: this.props.item.description
                                    ? this.props.item.description
                                        .replace(/&nbsp;/g, ' ')
                                        .replace(/(\r\n|\r|\n)/g, '<br>')
                                    : ''
                            }} />
                        <div className="text-center"><a href="javascript:void(0)"
                            onClick={e => {
                                e.preventDefault();
                                this.redirectToArticleDetail('/explore-detail/article/' + this.props.item.slug)
                            }}
                            className="more btn">read more</a></div>
                    </div>
                </div>
                {this.props.item.hashTags && this.props.item.hashTags.length > 0 && (
                    <div className="hposthash" style={{ padding: '0 15px' }}>
                        {this.props.item.hashTags.map((item) => {
                            return (
                                <div key={item} className="hposthashitem">
                                    <a href={"/explore-hashtag/" + item}>
                                        <i className="iHashtag" />
                                        {item}
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

export default ArticleUnauthItem;