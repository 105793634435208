import React, { Component } from 'react';
import $ from 'jquery';
import Header from "../Header";
import StaticHeader from "../StaticHeader";
import { Link, NavLink } from "react-router-dom";
import { authService } from "../auth";
import axios from "axios";
import { ReactTinyLink } from "react-tiny-link";
import LeftPanel from "../LeftPanel";
import AuthModalComponent from '../LoginExtra/AuthModalComponent';
import SignUpModal from "../SignUpModal/signUpModal";
import UnauthShare from "../commons/UnauthShare";
import LeftPanelUnauth from "../commons/LeftPanelUnauth";

import { getCDNUrl, convertUnicode, nFormatter, isWebView, postMsg, getVolunterProjectName } from "../commons/CommonFunctions";
import dateUtil from "date-and-time";
import * as _ from "lodash";
import Linkify from "react-linkify";
import uuid from "react-uuid";
import mappingUtils from "../project/component/commons/utils/mappingUtils";
import { renderToString } from 'react-dom/server';
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ProjectDetailsCommonModal from '../UnauthPageItem/ProjectDetailsCommonModal';
import FeedsUnauthItem from '../Feeds/FeedsUnauthItem';
import { getFaqById } from '../services/FaqApi';

class FaQBypassDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            signUpRequired: false,
            faqDetails: {},

            unAuthPost: [],
            unAuthCircle: [],

            isModalOpen: false,
            projectId: undefined,
            opportunities: [],
            projectDetail: {},
        }
    }

    getCheckFaqSlugId = async () => {
        const specialChars = /[-]/;
        if(specialChars.test(this.props.match.params.id)) {
            this.getFaqDetailsBySlug();
        } else {
            let response = await getFaqById(this.props.match.params.id);
            if(response?.message === "Success!") {
                this.props.history.push({
                    pathname: `/faq-details/${response?.body?.slug}`,
                })
            }
        }
    }

    componentDidMount() {
        this.getCheckFaqSlugId();

        window.$('#header .iCross').hide();
        window.$("#header .iHamburger").on('click', function (event) {
            window.$('.leftpanel').addClass('navactive');
            window.$('.dgcontainer').addClass('navactive');
            window.$('.staticPage').addClass('navactive');
            window.$('#header .iHamburger').hide();
            window.$('#header .iCross').show();
        });
        $("body, html").on('click', function (event) {
            if (!$(event.target).closest('#header .iHamburger, .read-more-less-btn').length) {
                $('.leftpanel').removeClass('navactive');
                $('.dgcontainer').removeClass('navactive');
                window.$('.staticPage').removeClass('navactive');
                $('.dropdown-menu.show').removeClass('show');
                window.$('#header .iHamburger').show();
                window.$('#header .iCross').hide();
            }
        });
        window.$('.usermenu .dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.usermenu .dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.usermenu .dropdown-menu').addClass('show');
            e.stopPropagation();
        });

        if (!this.state.loginSuccess) {
            this.getUnauthTrendingOppurtunity(0, 3);
            this.getUnAuthTrendingCircle();
            this.getUnAuthFeedsFetch();
        }

        setTimeout(() => {
            this.setState({ ...this.state })
        }, 1500);
    }

    getUnAuthFeedsFetch = () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_userServiceURL + '/unauth/getNewsFeed/trending?newsFeedType=TOP&page=0&size=3',
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.status === "200 OK") {
                    this.setState({
                        unAuthPost: response.data.body.content
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getUnAuthTrendingCircle = () => {
        let postBody = {
            "page": 0,
            "size": 4
        }
        axios({
            method: "post",
            url: process.env.REACT_APP_userServiceURL + '/circle/trending-circle',
            data: postBody,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.status === "200 OK") {
                    this.setState({
                        unAuthCircle: response.data.body.content
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getFaqDetailsBySlug = () => {
        axios({
            method: 'get',
            url:`${process.env.REACT_APP_userServiceURL}/backend/${this.state.loginSuccess ? "faq/get-by-slug" : "unauth/getByQuestionSlug"}?slug=${this.props.match.params.id}`,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                this.setState({
                    faqDetails: res.body
                })
                document.title = res.body.question
            }
        }).catch((error) => {
            this.setState({
                isLoaded: true,
                error: { message: error.response.data.message, err: error.response }
            });
        })
    }

    // for trending opportunity (Projects) starts ***********************************

    getUnauthTrendingOppurtunity = (page, size) => {
        let trendingOpportunityURL = process.env.REACT_APP_userServiceURL + '/unauth/trending-opportunity/';
        let url = trendingOpportunityURL + "?page=" + page + "&size=" + size;
        axios({
            method: 'get',
            url: url,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }).then((response) => {
            if (response && response.data && response.data.body && response.data.body.content) {
                this.setState({
                    opportunities: response.data.body.content
                })
            }
        }).catch((err) => {
            console.log("Error:", err);
        })
    }

    getUnauthProjectDetails = (slug) => {
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL +
                "/unauth/find-project-description-details-by-slug/" +
                slug,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            let res = response.data;
            if (res.status === "200 OK") {
                this.setState({ projectDetail: res.body }, () => {
                    if (this.state.projectDetail && this.state.projectDetail.project) {
                        if (this.state.projectDetail.project.title) {
                            document.title = this.state.projectDetail.project.title + ' | New opportunities and projects to explore | WeNaturalists';
                        } else if (this.state.projectDetail.project.title) {
                            document.title = this.state.projectDetail.project.title + ' | New opportunities and projects to explore | WeNaturalists';
                        } else if (this.state.projectDetail.project.title) {
                            document.title = this.state.projectDetail.project.sessionTitle + ' | New opportunities and projects to explore | WeNaturalists';
                        } else if (this.state.projectDetail.project.title) {
                            document.title = this.state.projectDetail.project.title + ' | New opportunities and projects to explore | WeNaturalists';
                        }
                    }
                });
                window.$("#projectDetails").modal("show");
            }
        }).catch((err) => {
            if (err && err.response) {
                this.setState({
                    notFoundstatusCode: err.response.status,
                    error: { message: err.response.data.message, err: err.response },
                });
            }
            this.setState({
                isLoaded: true,
            });
        });
    };

    getCost = (project) => {
        let fees = '';
        if (project && project.cost || project.remuneration || project.renumeration) {
            fees = project.cost || project.remuneration || project.renumeration
        }
        return fees;
    }

    renderTaggedUsers = (text) => {
        if (!text || text.length === 0) {
            return text;
        }

        text = text.split("@@@__").join("<a href=\"/profile/");
        text = text.split("^^__").join("\">")
        text = text.split("&&__").join("\">")
        text = text.split("&amp;&amp;__").join("\">")
        text = text.split("&amp;&amp;__").join("\">")
        text = text.split("@@@^^^").join("</a>");
        text = text.split("###__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/explore-hashtag/");
        text = text.split("&&__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/explore-hashtag/");
        text = text.split("###^^^").join("</a>");
        return text;
    }

    modalOpen = (slug) => {
        this.setState({ isModalOpen: true }, () => {
            this.setState({
                slug: '/project/feeds/' + slug
            })
            this.getUnauthProjectDetails(slug);
        })
    }

    openModalClose = () => {
        this.setState({
            slug: '',
            isModalOpen: false
        })
        window.$('#projectDetails').modal('hide');
    }

    matchHashTags = (text) => {
        if (!text || text.length === 0) {
            return text;
        }
        var string = text;
        var regex = /(#[a-z\d-_]+)/gi;
        var matches = string.matchAll(regex);
        for (var match of matches) {
            if (match && match.length > 1) {
                string = string.replace(match[0], '<span class="hposthashitem" ' +
                    'style="font-family: Montserrat,sans-serif;font-weight: 500;font-size: 12px;line-height: 15px;color: #bfc52e;">' +
                    '<a href="/explore-hashtag/"'+match[0].substr(1)+'><i class="iHashtag"></i>' + match[0].substr(1) + '</span>');
            }
        }
        return string;
    }

    // for trending opportunity (Projects) ends ***********************************

    loginRequired = (slug) => {
        this.setState({ 'loginRequired': true, 'pathname': slug })
        window.$('#loginModal').modal('show');
    }
    handleLoginClose = () => {
        this.setState({ 'loginRequired': false })
        window.$('#loginModal').modal('hide');
    }

    signUpRequired = () => {
        this.setState({ 'signUpRequired': true })
        window.$('#signupModal').modal('show');
    }

    handleSignUpClose = () => {
        this.setState({ 'signUpRequired': false })
        window.$('#signupModal').modal('hide');
    }

    getOptimizedImage = (personalInfo, defaultImg) => {
        if (
          personalInfo.resizedProfileImages &&
          personalInfo.resizedProfileImages["200x200"] != null
        ) {
          return personalInfo.resizedProfileImages["200x200"];
        }
        return defaultImg;
    };

    handleApply = (slug) => {
        this.setState(
          {
            pathname: slug,
            loginRequired: true,
          },
          () => {
            window.$("#loginModal").modal("show");
          }
        );
    };


    render() {

        const utcSeconds = this.state.faqDetails && this.state.faqDetails.createTime;
        let noOfDaysPosted = mappingUtils.getNoOfDaysAfterPosted(
            this.state.projectDetail && this.state.projectDetail.dateOfPosting
        );
        let title =
            this.state.projectDetail &&
            this.state.projectDetail.project &&
            this.state.projectDetail.project.title;
        let location =
            this.state.projectDetail &&
            this.state.projectDetail.project &&
            this.state.projectDetail.project.location;
        let fees =
            (this.state.projectDetail &&
                this.state.projectDetail.project &&
                this.state.projectDetail.project.cost);

        let attachments = [];
        if (this.state.projectDetail && this.state.projectDetail.project && this.state.projectDetail.project.attachments && this.state.projectDetail.project.attachments.length > 0) {
            let attachedFiles = this.state.projectDetail.project.attachments;
            attachedFiles.map((data, index) => {
                let value = {
                    name: data.substring(data.lastIndexOf('/') + 1),
                    url: data
                }
                attachments.push(value);

            })
        }
        let showProjectType;
        let projectType = this.state.projectDetail && this.state.projectDetail.participationDetails && this.state.projectDetail.participationDetails.projectType;
        let secondaryEntityType = this.state.projectDetail && this.state.projectDetail.participationDetails &&
            this.state.projectDetail.participationDetails.secondaryEntityType;
        if (secondaryEntityType && projectType && !_.isNull(secondaryEntityType) && !_.isNull(projectType)) {
            if (projectType.toUpperCase() === 'VOLUNTEER') {
                showProjectType = getVolunterProjectName(secondaryEntityType);
            } else {
                showProjectType = projectType;
            }

        } else {
            showProjectType = projectType;
        }

        let role = this.state.projectDetail && this.state.projectDetail.project && (this.state.projectDetail.project.jobRole || this.state.projectDetail.project.assignmentRole);
        let qualifications = this.state.projectDetail && this.state.projectDetail.project && (this.state.projectDetail.project.qualifications || this.state.projectDetail.project.qualificationRequired);
        let typeContent;
        if (this.state.projectDetail && this.state.projectDetail.participationDetails && this.state.projectDetail.participationDetails.secondaryEntityType === 'EVENT') {
            typeContent = "Mementos or Event Kits"
        } else if (this.state.projectDetail && this.state.projectDetail.participationDetails && this.state.projectDetail.participationDetails.secondaryEntityType === 'JOB') {
            typeContent = "Remarks"
        } else if (this.state.projectDetail && this.state.projectDetail.participationDetails && this.state.projectDetail.participationDetails.secondaryEntityType === 'ASSIGNMENT') {
            typeContent = "Certification or Accolades"
        } else if (this.state.projectDetail && this.state.projectDetail.participationDetails && this.state.projectDetail.participationDetails.secondaryEntityType === 'TRAINING') {
            typeContent = "Certification or Accolades"
        }

        let endDate = ''

        if (this.state.projectDetail && this.state.projectDetail.endDate > 0) {
            endDate = dateUtil.format(new Date(this.state.projectDetail.endDate), "DD MMM YYYY")
        } else {
            endDate = 'NA';
        }
        let faculties = this.state.projectDetail &&
            this.state.projectDetail &&
            this.state.projectDetail.faculties;
        const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds / 1000);
        let linkfiedHtml1 = "<p class=\"pdtlsLink\">" + renderToString(<Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                </a>
            )}>{location && location.address
                ? location.address.replace(/<br\/>/g, '\n').replace(/&nbsp;/g, ' ')
                : ''}</Linkify>).replace(/&nbsp;/g, ' ')
            .replace(/(\r\n|\r|\n)/g, '<br>')
            .replace(/(\\n)/g, '<br>') + "</p>"
        let linkfiedHtml = "<p class=\"pdtlsLink\">" + this.renderTaggedUsers(this.matchHashTags(renderToString(<Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                </a>
            )}>{this.state.faqDetails.description
                ? this.state.faqDetails.description
                : ''}</Linkify>))).replace(/&nbsp;/g, ' ')
            .replace(/(\r\n|\r|\n)/g, '<br>')
            .replace(/(\\n)/g, '<br>') + "</p>"

        return (
            <div className="wrapper staticPage nologinpage">
                {!this.state.loginSuccess ? <StaticHeader /> : <Header />}
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <section className="giveFeedbackWrap">
                            <Link to="/FaQ" className="goback">Go Back</Link>
                            <div className="globalPgCont" style={{ marginTop: '0' }}>
                                <div className="feedbdetailswrap faqBypas">
                                    {this.state.faqDetails &&
                                        <><h5>{this.state.faqDetails.question}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: this.state.faqDetails.answer }} />
                                            <div className="postarticledesc">
                                                {this.state.faqDetails.link && <ReactTinyLink
                                                    cardSize="small"
                                                    showGraphic={true}
                                                    maxLine={2}
                                                    minLine={1}
                                                    url={this.state.faqDetails.link}
                                                    proxyUrl={"https://cors-anywhere.beegram.net"} />}
                                            </div>
                                            {this.state.faqDetails.canShare &&
                                                <UnauthShare shareUrl="/faq-details/"
                                                    entityId={this.state.faqDetails.id}
                                                    entityType={"FAQ"}
                                                    shareId={this.state.faqDetails.slug} />}
                                            {/*{this.state.faqDetails.section && this.state.faqDetails.section.map((data)=>{
                                            return <p>{data}</p>
                                        })}*/}
                                        </>
                                    }
                                </div>
                            </div>
                        
                            {!this.state.loginSuccess ?
                            <>
                            <div className="unAuthSummrow">
                                <div className="topDesc">
                                    <h2 className="h2ttl">Trending Opportunities <Link className="link" onClick={() => this.setState({ loginRequired: true })}>View All</Link></h2>
                                </div>
                                <div className="explLatarticle">
                                    <OwlCarousel
                                        id={"unauthProjectcarousel"}
                                        autoWidth={true}
                                        nav={false}
                                        margin={12}
                                        items={3}
                                        loop={false}
                                        rewind={true}
                                    >
                                        {this.state.opportunities && this.state.opportunities.map((data, index) => {
                                            if (index < 3) {
                                                return (data.secondaryEntityType === 'JOB' || data.secondaryEntityType === 'ASSIGNMENT' ?
                                                    <div className="item">
                                                        <div className="projectlist">
                                                            <div className="projectlistinn">
                                                                <div className="projectlisttop">
                                                                    <div className="projheadOuter">
                                                                        <div className="projhead"
                                                                            onClick={(e) => this.modalOpen(data.project.slug)} style={{ cursor: 'pointer' }}>
                                                                            <figure className="complogo"
                                                                                onClick={(e) => this.modalOpen(data.project.slug)} style={{ cursor: 'pointer' }}>
                                                                                <LazyLoadImage
                                                                                    effect="blur"
                                                                                    src={data && data.project && !_.isNull(data.project.coverImage) && !_.isUndefined(data.project.coverImage) ? data.project.coverImage : 'https://cdn.dscovr.com/images/project-default.webp'}
                                                                                    onError={(e) => {
                                                                                        e.target.onerror = null;
                                                                                        e.target.src = "https://cdn.dscovr.com/images/project-default.jpg"
                                                                                    }}
                                                                                    alt="" />
                                                                            </figure>
                                                                            <div>
                                                                                <h5>
                                                                                    {data && unescape(data.creatorName)}
                                                                                </h5>
                                                                                {data && data.project && data.project.location && data.project.location.city &&
                                                                                    <span className="address">
                                                                                        {data.project.location && data.project.location.country}
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {((data && data.project && (data.project.cost || data.project.remuneration || data.project.renumeration) > 0 && data.project.isDisclosed === false) || (data.project.participationCount > 0)) &&
                                                                        <div className="projectpricetime">

                                                                            {data && this.getCost(data.project) > 0 && data.project.isDisclosed === false &&
                                                                                <span
                                                                                    className="price"> {data.project.currency} {nFormatter(this.getCost(data.project), 2)} {" "}{!_.isNull(data.project.packageType) && !_.isUndefined(data.project.packageType) && data.project.packageType.toLowerCase() !== 'custom' && data.project.packageType}</span>
                                                                            }

                                                                            {data.project.participationCount > 0 ?
                                                                                <>
                                                                                    {data.project.participationCount === 0 || data.project.participationCount === 1 ?
                                                                                        <span className="perticipent">
                                                                                            {unescape(data.project.participationCount)} Applicant
                                                                            </span> :
                                                                                        <span className="perticipent">
                                                                                            {unescape(data.project.participationCount)} Applicants
                                                                            </span>
                                                                                    }</> :
                                                                                <></>}
                                                                        </div>}
                                                                </div>
                                                                <div className="projectlistmid">
                                                                    <h4>
                                                                        <a href="javascript:void(0)"
                                                                            onClick={(e) => this.modalOpen(data.project.slug)}>

                                                                            <span>{data && data.project && data.project.title && data.project.title.split("\\n").map(title => (<>{convertUnicode(title)}<br /></>))}</span>
                                                                            {/*{title}*/}
                                                                        </a>
                                                                    </h4>
                                                                    <a href="javascript:void(0)"
                                                                        onClick={(e) => this.modalOpen(data.project.slug)}
                                                                        style={{ cursor: 'pointer' }}><p
                                                                            style={{ cursor: 'pointer' }}>{data && data.project && data.project.shortBrief && data.project.shortBrief.split("\\n").map(shortBrief => (<>{convertUnicode(shortBrief)}<br /></>))}</p>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : <div className="item">
                                                        <div className="projeventlist">
                                                            <div className="projeventlistinn">
                                                                <figure className="eventimg"
                                                                    href="javascript:void(0)"
                                                                    onClick={(e) => this.modalOpen(data.project.slug)} style={{ cursor: 'pointer' }}>
                                                                    <img
                                                                        src={data && data.project && !_.isNull(data.project.coverImage) && !_.isUndefined(data.project.coverImage) ? data.project.coverImage : 'https://cdn.dscovr.com/images/project-default.webp'}
                                                                        onError={(e) => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = "https://cdn.dscovr.com/images/project-default.jpg"
                                                                        }}
                                                                        alt="" />
                                                                    {data && this.getCost(data.project) > 0 && data.project.isDisclosed === false &&
                                                                        <span
                                                                            className="price"> {data.project.currency} {nFormatter(this.getCost(data.project), 2)} {" "}{!_.isNull(data.project.packageType) && !_.isUndefined(data.project.packageType) && data.project.packageType.toLowerCase() !== 'custom' && data.project.packageType}</span>
                                                                    }
                                                                </figure>
                                                                <div className="projeventdesc">
                                                                    <h4><a href="javascript:void(0)"
                                                                        onClick={(e) => this.modalOpen(data.project.slug)}
                                                                        style={{ cursor: 'pointer' }}>
                                                                        {data && data.project && data.project.title && data.project.title.split("\\n").map(title => (<>{convertUnicode(title)}<br /></>))}
                                                                        {/*{title}*/}
                                                                    </a></h4>
                                                                    <div className="d-flex"
                                                                        style={{ cursor: 'default' }}>

                                                                        <div
                                                                            className="date">{dateUtil.format(new Date(data && data.project.beginningTime), "DD")}
                                                                            <span>{!_.isUndefined(data.project.beginningTime) && !_.isNull(data.project.beginningTime) && dateUtil.format(new Date(data && data.project.beginningTime), "MMM").toUpperCase()}</span>
                                                                            <span>{!_.isUndefined(data.project.beginningTime) && !_.isNull(data.project.beginningTime) && dateUtil.format(new Date(data && data.project.beginningTime), "YYYY").toUpperCase()}</span>
                                                                        </div>

                                                                        <div className="projeventdesctxt">
                                                                            <a href="javascript:void(0)"
                                                                                onClick={(e) => this.modalOpen(data.project.slug)}
                                                                                style={{ cursor: 'pointer' }}>
                                                                                <p>{data && data.project && data.project.shortBrief && data.project.shortBrief.split("\\n").map(shortBrief => (<>{convertUnicode(shortBrief)}<br /></>))}</p>
                                                                            </a>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                            }
                                        })}


                                    </OwlCarousel>
                                </div>
                            </div>

                            <div className="unAuthSummrow">
                                <div className="topDesc">
                                    <h2 className="h2ttl">Trending Community <Link className="link" onClick={() => this.setState({ loginRequired: true })}>View All</Link></h2>
                                </div>
                                <div className="explLatarticle">
                                    <OwlCarousel
                                        id={"unauthCirclecarousel"}
                                        autoWidth={true}
                                        nav={false}
                                        margin={24}
                                        items={3}
                                        loop={false}
                                        rewind={true}
                                    >
                                        {this.state.unAuthCircle && this.state.unAuthCircle.map((item) => {
                                            return (
                                                <div className="item" key={item.id}>
                                                    <div className="CirclefrYouEachbx">
                                                        <a href={"/community/" + item.slug}>
                                                            <div className="CirclefrYouImgbx">
                                                                <LazyLoadImage
                                                                    effect="blur" src={(item.resizedCoverImages && item.resizedCoverImages.compressed) ? item.resizedCoverImages.compressed : "https://cdn.dscovr.com/images/prof-banner.webp"}
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = "https://cdn.dscovr.com/images/prof-banner.png"
                                                                    }} alt="" />
                                                                <figure>
                                                                    <LazyLoadImage
                                                                        effect="blur" src={(item.resizedProfileImages && item.resizedProfileImages.compressed) ? item.resizedProfileImages.compressed : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                                                        onError={(e) => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = "https://cdn.dscovr.com/images/circleDefault.png"
                                                                        }} alt="" />
                                                                </figure>
                                                            </div>
                                                        </a>
                                                        <div className="CirclefrYouDescbx">
                                                            <div className="circleTitleBl"><h5><span><a href={"/community/" + item.slug}>{item.title}</a></span></h5></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>

                            <div className="unAuthSummrow">
                                <div className="topDesc">
                                    <h2 className="h2ttl">Trending Feeds <Link className="link" onClick={() => this.setState({ loginRequired: true })}>View All</Link></h2>
                                </div>
                                <div className="explLatarticle">
                                    <OwlCarousel
                                        id={"unauthFeedcarousel"}
                                        autoWidth={true}
                                        margin={12}
                                        items={3}
                                        loop={false}
                                        rewind={true}
                                    >
                                        {this.state.unAuthPost.map((item) => {
                                            return (
                                                <div className="item" key={item.id}>
                                                    <FeedsUnauthItem item={item} key={item.id} />
                                                </div>
                                            )
                                        })}
                                    </OwlCarousel>
                                </div>
                            </div>
                            
                            <div className="bottomFloatButton">
                                <a href="javascript:void(0)" onClick={this.signUpRequired.bind(this)} className="bkloginBtn">
                                    <span>Join Us</span> to discover your passion for nature</a>
                            </div> </> : ''}
                        </section>

                        {!this.state.loginSuccess ?
                            <LeftPanelUnauth signUpRequired={this.signUpRequired} /> :
                            <LeftPanel />}
                    </div>
                </main>

                {this.state.isModalOpen && (
                <ProjectDetailsCommonModal projectDetail={this.state.projectDetail} openModalClose={this.openModalClose} handleApply={this.handleApply} />
                )}

                {this.state.loginRequired === true ? <AuthModalComponent handleLoginClose={this.handleLoginClose} pathname={this.state.pathname} history={this.props.history} /> : null}
                {this.state.signUpRequired === true ? <SignUpModal handleSignUpClose={this.handleSignUpClose} history={this.props.history} /> : null}
            </div>
        );
    }
}

export default FaQBypassDetails;
