import axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { authService } from '../auth';
import ReportAbuse from '../commons/ReportAbuse';
import FeaturePostButton from './FeaturePostButton';
import PinPostButton from './PinPostButton';
import SavePostButton from './SavePostButton';
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CommonDeleteModal from './CommonDeleteModal';
import CommonPostEditModal from './CommonPostEditModal';
import PostRedirectionPop from '../PostRedirectionPop';
import { connect } from 'react-redux';
import { requestPostCreationFulfilled } from '../reducer/post';

const ErorrMsgPopUp = withReactContent(alertPopUp)

class CommonPostMenuSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            openReportModal: null,
            openDeleteModal: null,
            openEditModal: null,
            isPostRedirectionModalOpen: false
        }
    }

    handleHideModal = (id) => {
        let data = {
            userId: this.state.userData.userId,
            activityId: id,
            entityType: this.props.postType !== "FORUM" && this.props.postType !== "POLL" ? 'POST' : this.props.postType,
        }
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_userServiceURL}/hidden/hide`,
            data: data,
            withCredentials: true
        }).then(response => {
            if (response && response.status === 201) {
                this.props.removePostListItem(id);
            }
        }).catch((err) => {
            if (err && err.response && err.response.status === 409) {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm();
                    }
                }).then((result) => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <p>{"Entity has already been hidden"}</p>,
                    })
                })
            }
        });
    }

    onSuccessUpdatePost = (data) => {
        this.triggerEditClose();
        this.props.onSuccessUpdatePost(data);
        this.setState({
            isPostRedirectionModalOpen: true
        }, () => {
            window.$("#AcceptPopUp").modal("show");
            setTimeout(() => {
                window.$("#AcceptPopUp").modal("hide");
                this.setState({
                    isPostRedirectionModalOpen: false
                })
            }, 60000);
        })
    }

    triggerReportClose = () => {
        this.setState({ openReportModal: null });
    }

    triggerDeleteClose = () => {
        this.setState({ openDeleteModal: null })
    }

    triggerEditClose = () => {
        this.setState({ openEditModal: null })
    }

    refreshAllData = () => {
        this.props.requestPostCreationFulfilled();
    }

    render() {
        const { item, postType } = this.props;
        const { userData, openReportModal, openDeleteModal, openEditModal, isPostRedirectionModalOpen } = this.state;
        return (
            <div className="contxmenu">
                <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"><i className="iKebab" /></button>
                <div className="dropdown-menu fadeIn animated">
                    <SavePostButton key={item.id} id={item.id} bookMarkFlag={item.bookMark} entityType={"POST"} />
                    <PinPostButton key={item.id} item={item} onSuccessPinPostRequest={this.props.onSuccessPinPostRequest} />
                    <FeaturePostButton key={item.id} item={item} />
                    {item.canEdit &&
                        <>
                            {item.postType !== "FORUM" && item.postType !== "POLL" &&
                                <>
                                    {!item.paidPost && (postType == "PHOTO" || postType == "VIDEO" || postType === "PPT") && <Link className="dropdown-item" to={`/feed/post-studio/${item.id}`}><i className="istudio" style={{ fontSize: '13px' }} /> Publish in Studio</Link>}
                                    <a className="dropdown-item" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({openEditModal: item.id});
                                    }} href="javascript:void(0)"><i className="iEditBox" /> Edit Post</a>
                                </>
                            }
                            <a className="dropdown-item" onClick={(e) => { e.preventDefault(); this.setState({ openDeleteModal: item.id }); }} href="javascript:void(0)"><i className="iTrashBin" /> Delete Post</a>
                        </>
                    }
                    <span className="dropdown-item" onClick={e => { this.handleHideModal(item.id) }}>
                        <i className="iHide" style={{ fontSize: '13px' }} /> Hide
                    </span>
                    {(userData.userId !== item.userId) && item.canReport &&
                        <a className="dropdown-item" href="javascript:void(0)" onClick={(e) => {
                            e.preventDefault();
                            this.setState({ openReportModal: item.id });
                        }}><i className="iReportComment_OL" /> Report</a>
                    }
                </div>

                {openReportModal && openReportModal === item.id &&
                    <ReportAbuse entityId={item.id}
                        entityType={"POST"}
                        postSection={this.props.postSection}
                        currentTab={this.props.currentTab}
                        //refreshAllData={this.refreshAllData}
                        removePostListItem={this.props.removePostListItem}
                        triggerReportClose={this.triggerReportClose} />
                }
                {openDeleteModal && 
                    <CommonDeleteModal entityId={item.id} triggerDeleteClose={this.triggerDeleteClose} removePostListItem={this.props.removePostListItem} />
                }
                {openEditModal &&
                    <CommonPostEditModal entityId={item.id} description={this.props.description} tags={this.props.tags} documentTitle={this.props.documentTitle ? this.props.documentTitle : ""} triggerEditClose={this.triggerEditClose} onSuccessUpdatePost={this.onSuccessUpdatePost} postType={postType} />
                }

                {isPostRedirectionModalOpen && <PostRedirectionPop postId={item.id} type={"UPDATE"} />}

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestPostCreationFulfilled: (value) => dispatch(requestPostCreationFulfilled(value))
    };
};

export default connect(null, mapDispatchToProps)(CommonPostMenuSection);