import React, { memo } from "react";
import { Component } from "react";
import { authService } from "../../auth";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { showErrorMessage } from "../../commons/CommonFunctions";
import UserBankDetailsPopUp from "./UserBankDetailsPopUp";
import ViewBankDetailsPopUp from "./ViewBankDetailsPopUp";
import ReactTooltip from "react-tooltip";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CleverTapUtils from "../../commons/CleverTapUtils";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const actionBankDeleteSwal = () => {
    return ({
        title: <><p>Are you sure you want to delete this bank account ?</p></>,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
    })
};

const actionBankDetailsMessage = (message) => {
    return ({
        title: <><p>{message}</p></>,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Ignore',
        allowOutsideClick: false,
    })
}
class UserBankDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            isUpdateDisabled: false,
            bankDetailsUpdateFlag: false,
            userBankDetailsList: [],
            collapseOpen: false,
            bankDetailsPopUpFlag: false,
            editBankData: {},
            formType: "",
            locationStateData: props.location?.state,
            pageRedirectFlag: false,
            viewBankDetailsPopUpFlag: false,
            selectBankData: {}
        }
    }

    getUserBankDetails = (collapseOpen) => {
        if (!collapseOpen) {
            axios({
                url: `${process.env.REACT_APP_userServiceURL}/backend/userBankDetail/getUserBankDetails?userId=${this.props.userId}&metaDataRequired=true`,
                method: "GET",
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.body && response.data.message === "Success!") {
                    this.setState({
                        userBankDetailsList: response.data.body
                    })
                }
            }).catch((error) => {
                console.log(error)
            });
            CleverTapUtils.eventClick({}, "View Payment Details");
        }
        this.setState({
            collapseOpen: !collapseOpen
        })
    }

    confirmDeleteBank = (bankData, status)=>{
        const deleteConfirm = async () => {
            const swalval = await ErorrMsgPopUp.fire(actionBankDeleteSwal());
            if (swalval && swalval.value === true) {
                this.handleBankStatus(bankData, status);
            }
        };
        deleteConfirm();
    }

    handleBankStatus = (bankData, status) => {
        let postData = {
            bankId: bankData.id,
            userId: this.props.userId
        }
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/backend/userBankDetail/${status === "PRIMARY" ? 'make/primary' : status === "DELETE" ? 'delete' : status === "DEACTIVATE" ? 'deactivate' : 'reactivate'}`,
            method: "POST",
            data: postData,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                if(/* status === "DEACTIVATE" ||  */status === "REACTIVATE"/*  || status === "DELETE" */) {
                    let userBankDetailsList = this.state.userBankDetailsList;
                    const index = userBankDetailsList && userBankDetailsList.findIndex((item) => item.id === bankData.id);
                    if(index > -1) {
                        if(status === "DEACTIVATE") {
                            userBankDetailsList[index].status = "INACTIVE";
                            userBankDetailsList[index].primary = false;
                        } else if(status === "REACTIVATE") {
                            userBankDetailsList[index].status = "ACTIVE";
                        } else  {
                            userBankDetailsList.splice(index, 1);
                        } 
                    }
                    this.setState({
                        userBankDetailsList: userBankDetailsList
                    })
                } else {
                    this.getUserBankDetails(false);
                }
            }
        }).catch((error) => {
            console.log(error);
            if(error?.response?.data?.status === "409 CONFLICT") {
                if(error?.response?.data?.message === "Maximum Active Limit Reached Exception") {
                    showErrorMessage("Maximum 5 bank accounts are allowed to use.")
                } else if(error?.response?.data?.message === "Bank is already inactive state.") {
                    showErrorMessage("Bank is already inactive state.")
                } else {
                    this.actionBankDetailsFire(bankData, "Your bank is linked to a entity. Please transfer all the linked references to deactivate this bank account.");
                }
            }
        });
    }

    actionBankDetailsFire = async (bankData, message) => {
        const swalval = await ErorrMsgPopUp.fire(actionBankDetailsMessage(message));
        if(swalval && swalval.value === true) {
            this.viewBankDataDetails(bankData);
        }
    }

    viewBankDataDetails = (bankData) => {
        this.setState({
            selectBankData: bankData,
            viewBankDetailsPopUpFlag: true
        }, () => {
            window.$("#viewBankDetailsPopUp").modal("show");
        })
    }

    onClickAddBankDetails = (type, id) => {
        if(type === "EDIT") {
            axios({
                url: `${process.env.REACT_APP_userServiceURL}/backend/userBankDetail/getById?id=${id}`,
                method: "GET",
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.setState({
                        editBankData: response.data.body,
                        bankDetailsPopUpFlag: true,
                        formType: type
                    }, () => {
                        window.$("#addBankDetailsPop").modal("show");
                    })
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            this.setState({
                editBankData: {},
                bankDetailsPopUpFlag: true,
                formType: type
            }, () => {
                window.$("#addBankDetailsPop").modal("show");
            })
            CleverTapUtils.eventClick({}, "Add Bank Account");
        }
    }

    onCloseBankDetailsModal = () => {
        window.$("#addBankDetailsPop").modal("hide");
        this.setState({
            bankDetailsPopUpFlag: false,
            formType: ""
        })

        if (this.state.locationStateData && (this.state.locationStateData.pageName === "DETAILS" || this.state.locationStateData.pageName === "SUBSCRIPTION")) {
            let locationStateData = this.state.locationStateData;
            this.props.history.push({
                pathname: locationStateData.pageName === "DETAILS" ? (locationStateData.entityType === "PROJECT" ? `/project/feeds/${locationStateData.slug}` : `/community/${locationStateData.slug}`) : "/wenaturalists-pro",
                state: {
                    pageName: "ADD_BANK",
                    entityType: locationStateData.entityType
                }
            })
        }
    }

    onCloseViewBankListDetails = (flag) => {
        if(flag) {
            this.getUserBankDetails();
        }
        window.$("#viewBankDetailsPopUp").modal("hide");
        this.setState({
            viewBankDetailsPopUpFlag: false,
            selectBankData: {}
        })
    }

    render() {
        const { bankDetailsPopUpFlag, collapseOpen, userBankDetailsList, editBankData, formType, viewBankDetailsPopUpFlag, selectBankData } = this.state;
        const { userId } = this.props;
        let activeBankCount = userBankDetailsList && userBankDetailsList.filter((item) => item.status === "ACTIVE").length;
        return (
            <div class="card">
                <div class="card-header" id="heading-2">
                    <div class="left">
                        <h2>Payment Details</h2>
                        <p>Add a Payment Link or a QR Code that will allow you to receive amounts in your account directly.</p>
                    </div>
                    <div class="right">
                        <a role="button" data-toggle="collapse" href="#bankDetails" aria-expanded="false" class="btn edit txtViewIco" onClick={(e) => this.getUserBankDetails(collapseOpen)}>View</a>
                    </div>
                </div>
                <div id="bankDetails" class="collapse" data-parent="#accordion" aria-labelledby="heading-2">
                    <div class="card-body">
                        <div class="settSequritybox password" style={{ maxWidth: 'none', margin: '0' }}>
                            <div className="bankListWrap">
                                <div className="row">
                                    {userBankDetailsList && userBankDetailsList.map((bankData) => {
                                        return (
                                            <div className="col-sm-6">
                                                <div className="bankListBlk">
                                                    <div className="bankListBlkInn">
                                                        <figure><i class="fa fa-university" aria-hidden="true" /></figure>
                                                        <div>
                                                            <h5>{bankData.institutionName}</h5>
                                                            {bankData.primary && <span>Primary Account</span>}
                                                        </div>
                                                        {bankData.status !== "ACTIVE" && 
                                                            <>
                                                                <span style={{margin: '0 5px'}}>
                                                                    <i className="ideactivated" data-tip="msg to show" data-for={"deactivated"} data-place='top' style={{color: 'red'}} />
                                                                </span>
                                                                <ReactTooltip
                                                                    className="rtoolTip"
                                                                    place="bottom"
                                                                    id={"deactivated"}
                                                                    type="dark"
                                                                    textColor="#00394D"
                                                                    effect="solid"
                                                                    backgroundColor="#ffffff">Deactivated</ReactTooltip>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="contxmenu">
                                                        <button type="button" className="btn"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false"><i
                                                            className="iKebab"/></button>
                                                        <div className="dropdown-menu fadeIn animated">
                                                            <a href="javascript:void(0)" className="dropdown-item" onClick={(e) => this.onClickAddBankDetails("EDIT", bankData.id)}>
                                                                <i className="iEditBox" style={{fontSize: "13px"}}/> Edit
                                                            </a>
                                                            {!bankData.primary && bankData.status === "ACTIVE" && 
                                                                <a href="javascript:void(0)" className="dropdown-item" onClick={(e) => this.handleBankStatus(bankData, "PRIMARY")}>
                                                                    <i className="iprimary" style={{fontSize: "14px"}}/> Make Primary
                                                                </a>
                                                            }
                                                            <a href="javascript:void(0)" className="dropdown-item" onClick={(e) => this.handleBankStatus(bankData, bankData.status === "ACTIVE" ? "DEACTIVATE" : "REACTIVATE")}>
                                                                <i className={bankData.status === "ACTIVE" ? "ideactivated" : "iReactivate"} style={{fontSize: "13px"}}/> {bankData.status === "ACTIVE" ? "Deactivate" : "Reactivate"}
                                                            </a>
                                                            {bankData.delete && 
                                                                <a href="javascript:void(0)" className="dropdown-item" onClick={(e) => this.confirmDeleteBank(bankData, "DELETE")}>
                                                                    <i className="iTrashBin" style={{fontSize: "13px"}}/> Delete
                                                                </a>
                                                            }
                                                            {(bankData?.projectList?.length > 0 || bankData?.circleList?.length > 0 || bankData?.studioList?.length > 0) &&
                                                                <a href="javascript:void(0)" className="dropdown-item" onClick={(e) => this.viewBankDataDetails(bankData)}>
                                                                    <i className="iEye_OL" style={{fontSize: "13px"}}/> View
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            {/* activeBankCount < 5 && */
                                <div className='addNewHlpDesk'>
                                    <span>
                                        <button className="btn" onClick={(e) => this.onClickAddBankDetails("ADD")}>
                                            <i class="fa fa-university" aria-hidden="true" /> Add Bank Account
                                        </button>
                                    </span>
                                </div>
                            }

                            {bankDetailsPopUpFlag && <UserBankDetailsPopUp bankData={editBankData} formType={formType} onCloseBankDetailsModal={this.onCloseBankDetailsModal} getUserBankDetails={this.getUserBankDetails} userId={userId} />}

                            {viewBankDetailsPopUpFlag && <ViewBankDetailsPopUp entityDetailsList={selectBankData} onCloseViewBankListDetails={this.onCloseViewBankListDetails} userId={userId} />}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default memo(withRouter(UserBankDetails));