import React, {PureComponent} from 'react';
import {authService} from "../auth";
import $ from 'jquery';
import BusinessPageItem from "./BusinessPageItem";
import * as _ from "lodash";
import {getCDNUrl} from "../commons/CommonFunctions";

class BusinessPageList extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            businessPageList: props.businessPageList ? props.businessPageList : []
        }
    }

    componentDidMount() {
        window.$('.dropdown-submenu > a').on("click", function (e) {
            var submenu = $(this);
            $('.dropdown-submenu .dropdown-menu').removeClass('show');
            submenu.next('.dropdown-menu').addClass('show');
            e.stopPropagation();
        });
    };

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.profileImageUrl;
    }

    render() {
        return (
            // <li className="dropdown-item dropdown-submenu">
            //     <a href="javascript:void(0)" data-toggle="dropdown">
            //         <figure className="ico">
            //             <i className="iBusiness" style={{fontSize: '18px'}}/>
            //         </figure>
            //         <div>
            //             <h5>Organization Pages</h5>
            //         </div>
            //     </a>
            //     {
            //         this.props.businessPageList && this.props.businessPageList.businessPage && this.props.businessPageList.businessPage.length > 0 &&
            //         <ul className="dropdown-menu">

            //             {
            //                 this.props.businessPageList.businessPage.map((item) =>
            //                     <BusinessPageItem key={item.companyId} item={item}
            //                                       setAsUser={() => this.props.setAsUser(item.companyId, 'Company', item.companyName, null, !_.isNull(item.profileImageUrl) && !_.isUndefined(item.profileImageUrl) ? getCDNUrl(this.getOptimizedImage(item)) : 'https://cdn.dscovr.com/images/DefaultBusiness.png')}/>
            //                 )
            //             }

            //         </ul>
            //     }
            // </li>

            <>
                {
                    this.props.businessPageList && this.props.businessPageList.businessPage && this.props.businessPageList.businessPage.length > 0 &&
                    <>
                        {/* <h5 className='titleList'><span>Organization Pages</span></h5> */}
                        {
                            this.props.businessPageList.businessPage.map((item) =>
                                <BusinessPageItem key={item.companyId} item={item}
                                                  setAsUser={() => this.props.setAsUser(item.companyId, 'Company', item.companyName, null, !_.isNull(item.profileImageUrl) && !_.isUndefined(item.profileImageUrl) ? getCDNUrl(this.getOptimizedImage(item)) : 'https://cdn.dscovr.com/images/DefaultBusiness.png')}/>
                            )
                        }

                    </>
                }
            </>
        );
    }
}

export default BusinessPageList;
