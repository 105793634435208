import React, {Component} from 'react';
import Header from "../Header";
import LeftPanel from "../LeftPanel";
import {NavLink, withRouter} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import LeftPanelSettings from '../settings/LeftPanelSettings';
import axios from "axios";
import { authService } from '../auth';
import OrganizationAdmin from '../commons/OrganizationAdmin';
import Breadcumbs from '../Breadcumbs';

class HideContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: authService.isAuthenticated(),
            userData: authService.getUserDetailsFromLocalStorage(),
            managePermission:false
        }
    }
    componentDidMount() {
        // window.$(".projtopbar").sticky({topSpacing: 65, responsiveWidth: true});
        // if (window.$('#sticky-wrapper').hasClass('sticky-wrapper'))
        //     window.$('.projectinn').css({'padding-bottom' : '150px'});
        
        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
    }

    render() {
        const { userId } = this.props;
        return (
            <div className="wrapper projectpage forumpage hiddenCpage explorepage settingspage">
                <Header/>
                <main className="dgcontainer hm3col">
                    <div className="container">
                        <div className="row">
                            <LeftPanelSettings userId={userId} />
                            <div className="col-md-9">                                
                                <div className="projectinn">
                                    <Breadcumbs crumbs={[{ "name": "Dashboard Settings", "path": "/dashboard-settings" }, { "name": `Hidden Content`, "path": `/hidden-content` }, { "name": window.location.pathname === '/hidden-content/feeds' ? `Feed` : window.location.pathname === '/hidden-content/project' ? "Project" : "Forum and Polls" , "path": null } ]} />
                                    <div className="projtopbar">
                                        <div className="projtopbarleft">
                                            <ul id="tabs" className="nav">

                                                {window.location.pathname === '/hidden-content/feeds' && <li className="nav-item">
                                                    <a href="javascript:void(0)" className="nav-link active">
                                                        Feeds
                                                    </a>
                                                </li>}
                                                {window.location.pathname === '/hidden-content/project' && <li className="nav-item">
                                                    <a href="javascript:void(0)" className="nav-link active">
                                                        Projects
                                                    </a>
                                                </li>}
                                                {window.location.pathname === '/hidden-content/forum-poll' && <li className="nav-item">
                                                    <a href="javascript:void(0)" className="nav-link active">
                                                        Forum and Polls
                                                    </a>
                                                </li>}
                                            </ul>
                                            <ul className="ntwLinkIcoTab">
                                                <li><NavLink to={{pathname: '/hidden-content/feeds', state: {userId: userId}}} data-tip="Feeds" data-place='bottom' activeClassName="active"><i className="iWN_Feeds_OL" /></NavLink></li>
                                                <li><NavLink to={{pathname: '/hidden-content/project', state: {userId: userId}}} data-tip="Projects" data-place='bottom' activeClassName="active"><i className="iProjects_OL" /></NavLink></li>
                                                <li><NavLink to={{pathname: '/hidden-content/forum-poll', state: {userId: userId}}} data-tip="Forum and Poll" data-place='bottom' activeClassName="active"><i className="iForumPoll_Ol" /></NavLink></li>
                                            </ul>                                            
                                        </div>
                                    </div>
                                    <ReactTooltip
                                        className="rtoolTip"
                                        place="bottom"
                                        type="dark"
                                        textColor="#00394D"
                                        backgroundColor="#ffffff"/>

                                    {userId && <OrganizationAdmin userId={userId} />}

                                    <div>
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default HideContent;