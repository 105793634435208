import React, { Component } from "react";
import { dateTimeConvert, detailsPageRedirection, getCDNUrl, getOptimizedImage, redirectToCircle } from "../commons/CommonFunctions";
import TimeAgo from "javascript-time-ago";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ArticleUnauthItem from "./ArticleUnauthItem";
import VideoesUnauthItem from "./VideoesUnauthItem";
import AudioUnauthItem from "./AudioUnauthItem";
import PhotosUnauthItem from "./PhotosUnauthItem";
import LinkUnauthItem from "./LinkUnauthItem";
import PresentationUnauthItem from "./PresentationUnauthItem";
import { withRouter } from "react-router-dom";

const timeAgo = new TimeAgo("en-US");

class FeedsUnauthItem extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    redirectToProfile = (item) => {
        this.props.history.push({
            pathname: !item.userEntityType ? `/profile/${item.userCustomUrl}` : `/community/${item?.params?.circleSlug}`
        })
    }

    render() {
        return (
            <div className="hpostblock">
                <span></span>
                <figure className="userimg" onClick={(e) => detailsPageRedirection(this.props.item?.id)} style={{cursor: 'pointer'}}>  {/* onClick={(e) => this.redirectToProfile(this.props.item)} */}
                    <LazyLoadImage effect="blur" src={!this.props.item?.userEntityType ? (this.props.item && (this.props.item?.originalProfileImage || this.props.item?.userProfileImage) ? getCDNUrl(getOptimizedImage(this.props.item, this.props.item?.originalProfileImage ? "originalProfileImage" : "userProfileImage")) : this.props.item.userType === "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png") : this.props.item?.params.circleImage ? getCDNUrl(this.props.item?.params.circleImage) : "https://cdn.dscovr.com/images/circleDefault.png"} alt="" />
                </figure>
                <div className="hpostblockinn">
                    <div className="hposthead" onClick={(e) => detailsPageRedirection(this.props.item?.id)} style={{cursor: 'pointer'}}>
                        <h3>
                            <a href="javascript:void(0)">{this.props.item?.userEntityType ? this.props.item?.params && this.props.item?.params.circleTitle : this.props.item?.userName}</a> {/* onClick={(e) => this.redirectToProfile(this.props.item)} */}
                        </h3>
                        {this.props.item?.persona && this.props.isFeed &&
                            <div className="hpostPersona">{this.props.item?.persona}</div>}
                        <div className="hpostdtls">
                            {this.props.item?.country &&
                                <span className="address">
                                    <i className="iLocation"></i>
                                    {this.props.item?.country}
                                </span>}
                            <span>
                                <a href="javascript:void(0)"> {/* onClick={(e) => detailsPageRedirection(this.props.item?.id)} */}
                                    {timeAgo.format(
                                        dateTimeConvert(this.props.item?.createTime),
                                        "mini-now"
                                    )}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="hpostcontent">
                        {this.props.item.postType === "ARTICLE" ?
                            <ArticleUnauthItem item={this.props.item} key={this.props.item.id} /> :
                            (this.props.item.attachmentIds && this.props.item.attachmentIds.length > 0) ?
                                (this.props.item.attachmentIds[0].attachmentType === 'VIDEO' ?
                                    <VideoesUnauthItem item={this.props.item} key={this.props.item.id} /> :
                                    (this.props.item.attachmentIds[0].attachmentType === 'AUDIO' ?
                                        <AudioUnauthItem enablePostSharing={true} item={this.props.item} key={this.props.item.id} /> :
                                        this.props.item.attachmentIds[0].attachmentType === 'PPT' || this.props.item.attachmentIds[0].attachmentType === 'PDF' ?
                                            <PresentationUnauthItem item={this.props.item} key={this.props.item.id} /> :
                                            <PhotosUnauthItem item={this.props.item} key={this.props.item.id} />
                                    )
                                ) : (this.props.item && this.props.item.attachmentIds.length === 0 && this.props.item.paidPost && (this.props.item.attachmentType === "IMAGE" || this.props.item.attachmentType === "VIDEO"))
                                ? this.props.item.attachmentType === "IMAGE" ?
                                    <PhotosUnauthItem item={this.props.item} key={this.props.item.id} />
                                    : 
                                    this.props.item.attachmentType === "VIDEO" ?
                                        <VideoesUnauthItem item={this.props.item} key={this.props.item.id} /> : 
                                    <></> :
                                    this.props.item.postType === "LINK" ? 
                                        <LinkUnauthItem item={this.props.item} key={this.props.item.id} /> : 
                            <></>
                        }
                        <div className="hpostcontbott" onClick={(e) => this.props.loginRequired()}>
                            <div class="likesblk" style={{ margin: "0" }}>
                                <div class="like icons">
                                    <span className="slIcoTxtpart">
                                        {this.props.item?.likesCount}&nbsp;
                                        <span class="art_btn_txt">Like</span>
                                    </span>
                                </div>
                            </div>
                            <div className="expCommnt">
                                <div class="comments collapser icons">
                                    <span class="art_btn_bx">
                                        {this.props.item?.commentCount}&nbsp;
                                        <span class="art_btn_txt">Comment</span>
                                    </span>
                                </div>
                            </div>
                            {!this.props.item.paidPost &&
                                <div class="sharedrop">
                                    <div class="share icons">
                                        <span className="slIcoTxtpart">{this.props.item && this.props.item.sharesCount > 0 && this.props.item.sharesCount} <span> Share</span></span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FeedsUnauthItem);