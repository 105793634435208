import React from 'react'
import { NavLink } from "react-router-dom";

class FooterDiv extends React.PureComponent {

    redirectionFunction = (type, url) => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: type,
            }));
            window.location.href = "/#";
        }else {
            window.open(url, '_blank' );
        }
    }
    
    render() {
        return <footer className="footer" style={{position:'inherit'}}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7 ftlinks">
                        <ul>
                            <li><a href="javascript:void(0)" onClick={(e) => this.redirectionFunction("cookiePolicy", '/CookiePolicy')}>Cookie Policy</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-5 copyright">All Rights Reserved. 2019 © Dscovr Journeys</div>
                </div>
            </div>
        </footer>
    }
}

export default FooterDiv 
