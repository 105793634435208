import React, { Component } from "react";
import axios from "axios";
import { authService } from "../../../auth";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import Breadcumbs from "../../../Breadcumbs";
class ManageStudioHelpDeskTopBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            userPostCount: {},
            noDataPresent: false,
            tabType: props?.match?.path === "/:customUrl/studio" ? "My Studio" : props?.match?.path === "/:customUrl/subscribed" ? "Subscribed" : props?.match?.path === "/:customUrl/interested" ? "Interested" :
            props?.match?.path === "/:customUrl/helpdesk" ? "Help Desk" : "Hidden"
        }
    }

    getUserStudioCount = () => {
        axios({
            url: `${process.env.REACT_APP_userServiceURL}/paidContentPost/getUserPostCount?userId=${this.props.userId}`,
            method: "GET",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (
                response &&
                response.data &&
                response.data.body &&
                response.data.status === "200 OK"
            ) {
                this.setState({
                    userPostCount: response.data.body
                }, () => {
                    if ((this.props.manageStudioTab === "INTERESTED" && this.state.userPostCount.interested === 0) || (this.props.manageStudioTab === "SUBSCRIBED" && this.state.userPostCount.subscribed === 0) || (this.props.manageStudioTab === "HELP_DESK" && this.state.userPostCount.helpDesk === 0) || (this.props.manageStudioTab === "HIDDEN_STUDIO" && this.state.userPostCount.hidden === 0)) {
                        this.setState({
                            noDataPresent: true
                        })
                    }
                })
            }
        }).catch((error) => {
            console.log(error)
        });
    }

    componentDidMount() {
        var navPos = window.$('.projtopbar').offset().top - 64;
        window.$(window).scroll(function () {
            var windscroll = window.$(window).scrollTop();
            if (windscroll >= navPos) {
                window.$('.projtopbar').addClass('is-sticky');
            } else {
                window.$('.projtopbar').removeClass('is-sticky');
            }
        });
        this.getUserStudioCount();
        
    }

    render() {
        const { userData, userPostCount, noDataPresent } = this.state;
        const { userId } = this.props;
        return (
            <>
                {noDataPresent && <Redirect to={`/${userData.customUrl}/studio`} />}
                <Breadcumbs crumbs={[{ "name": "Dashboard Settings", "path": "/dashboard-settings" }, { "name": "Manage Studio", "path": `/${userData.customUrl}/studio` }, { "name": `${this.state.tabType}`, "path": null } ]} />
                <div className="projtopbar">
                    <div className="projtopbarleft">
                        <div className="mcustHorizMenu">
                            <div className="mcustHorizinn">
                                <ul id="tabs" className="nav">
                                    <li><NavLink to={{pathname: `/${userData.customUrl}/studio`, state: {userId: userId}}} activeClassName="active">My Studio</NavLink></li>
                                    {userPostCount && userPostCount.subscribed > 0 &&
                                        <li><NavLink to={{pathname: `/${userData.customUrl}/subscribed`, state: {userId: userId}}} activeClassName="active">Subscribed</NavLink></li>
                                    }
                                    {userPostCount && userPostCount.interested > 0 &&
                                        <li><NavLink to={{pathname: `/${userData.customUrl}/interested`, state: {userId: userId}}} activeClassName="active">Interested</NavLink></li>
                                    }
                                    {userPostCount && userPostCount.helpDesk > 0 &&
                                        <li><NavLink to={{pathname: `/${userData.customUrl}/helpdesk`, state: {userId: userId}}} activeClassName="active">Help Desk</NavLink></li>
                                    }
                                    {userPostCount && userPostCount.hidden > 0 && 
                                        <li><NavLink to={{pathname: `/${userData.customUrl}/hidden-studio`, state: {userId: userId}}} activeClassName="active">Hidden </NavLink></li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(ManageStudioHelpDeskTopBar);