export const POST_CREATION_REQUEST_FULFILLED = "POST_CREATION_REQUEST_FULFILLED";

let initialState = {
    postCreationResult: {}
};

const postReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case POST_CREATION_REQUEST_FULFILLED:
            state = {postCreationResult: {value: action.payload}, isLoaded: true};
            return state;
        default:
            return state;
    }
};

export default postReducer;

export function requestPostCreationFulfilled(response) {
    return {type: POST_CREATION_REQUEST_FULFILLED, payload: response};
}
