import clevertap from 'clevertap-web-sdk';

const CleverTapUtils = (function (namespace = {}) {
    let postData = {
        "MSG-email": false,                
        "MSG-push": false,                  
        "MSG-sms": false,                   
        "MSG-whatsapp": false,             
    }
    namespace.onUserLogin = function (data, type) {
        
        clevertap.onUserLogin.push({
            "Site": data
        })
    };
    namespace.eventClick = function (data, eventType) {
       
        clevertap.event.push(eventType, data);
    };
    return namespace;
})();
export default CleverTapUtils;