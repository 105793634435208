//project imports
import React, { Component } from 'react';
import { authService } from "../../../auth";
import { getCDNUrl, getOptimizedArticleImage } from "../../../commons/CommonFunctions";
import { Link, NavLink } from "react-router-dom";
import MutualConnect from "../../../commons/MutualConnect";


// Third Party imports
import { LazyLoadImage } from "react-lazy-load-image-component";
import Follow from "../../../commons/Follow";
import EntityFollow from "../../../commons/EntityFollow";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import {redirectToCircle, redirectToProfile, getFormattedDate, renderTaggedUsers} from './HashtagsCommonFunction';
import SavePostButton from '../../../post/SavePostButton';
import FeaturePostButton from '../../../post/FeaturePostButton';
import CommonHashTagUserSection from './CommonHashTagUserSection';
import UserReactionComponent from '../../../UserReaction/UserReactionComponent';
import Share from '../../../commons/Share';
import ExploreCommentSection from '../../ExploreCommentSection';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

class CommonHashTagsBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            loginSuccess: authService.isAuthenticated(),
        }
    }

    getCoverImgUrl = (arttachmentList) => {
        var attachmentUrl = "https://cdn.dscovr.com/images/banner-explore-blog-small-new.png";
        if (arttachmentList !== undefined) {
            for (var each of Object.values(arttachmentList)) {
                if (each.attachmentType === "COVER_IMAGE") {
                    attachmentUrl = getCDNUrl(getOptimizedArticleImage(each));
                    break;
                }
            }
        }
        return attachmentUrl;
    }

    getCuratedCoverImage = (item) => {
        var attachmentUrl = "https://cdn.dscovr.com/images/banner-explore-blog-small-new.png";
        if (item.resizedProfileImages && item.resizedProfileImages['300x300'] != null) {
            attachmentUrl = item.resizedProfileImages['300x300'];
            return attachmentUrl;
        }
        return attachmentUrl;
    }

    getOptimizedImage = (personalInfo) => {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo?.profileImage || personalInfo?.userProfileImage;
    }

    //Handle Detail page redirection
   handleDetailPage = (evt, news_url, blogId) => {
    let postBody = {
      userId: this.state.userData && this.state.userData.userId,
      entityId: blogId,
      timestamp: new Date().valueOf(),
      entityType: "BLOG",
    };
   // props.pageClickRequest(postBody);
    let userAgent = navigator.userAgent;
    let mobileandroid = /wenat_android/.test(userAgent);
    let mobileios = /wenat_ios/.test(userAgent);
    if (news_url !== undefined && news_url !== null) {
      if (mobileios || mobileandroid) {
        window.location.href = news_url;
      } else {
        window.open(
          news_url,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=1000,height=1000"
        );
      }
    }
  };

    render() {
        let linkifiedHtml =
            '<p class="pdtlsLink forum-pdtlsLink">' +
            renderTaggedUsers(
                this.props.item && this.props.item.description && this.props.item.description !== ""
                    ? this.props.item.description
                    : ""
            )
                .replace(/<img[^>]*>/g, "")
                .replace(/<h2[^>]*>/g, "")
                .replace(/&nbsp;/g, " ")
                .replace(/(\r\n|\r|\n)/g, "<br>")
                .replace(/(\\n)/g, "<br>") +
            "</p>";
        return (
            <div className="explstorieslist">
                <figure className="explstoriesimg">
                    {this.props.item.curatedPost ? 
                    <a
                        onClick={(e) =>
                        this.handleDetailPage(
                            e,
                            "/explore-detail/blog/" +
                            this.props.item.slug,
                            this.props.item.id
                        )
                        }
                        href="javascript:void(0)"
                    >
                    <LazyLoadImage
                        effect="blur"
                        src={getCDNUrl(this.props.item.curatedPost ? this.getCuratedCoverImage(this.props.item) :
                            this.getCoverImgUrl(this.props.item.attachmentIds)
                        )}
                        alt=""
                    />
                </a> :
                    <Link to={"/post/" + this.props.item.id}>
                        <LazyLoadImage
                            effect="blur"
                            src={getCDNUrl(
                                this.getCoverImgUrl(this.props.item.attachmentIds)
                            )}
                            alt=""
                        />
                    </Link>}
                </figure>
                <div className="explstoriesdesc" style={{position: 'relative'}}>
                    {this.props.pageType === "FEATURE_POST" && /* (this.props.item.userId === this.state.userData.userId) && */
                        <FeaturePostButton key={this.props.item.id} item={this.props.item} pageType={this.props.pageType} onSuccessFeaturePost={this.props.onSuccessFeaturePost} />
                    }
                    <div className="postbookMhead">
                        {!this.props.item.userEntityType ? (
                            <figure
                                style={{ cursor: "pointer" }}
                                className="complogo"
                                onClick={(e) => this.props.item.curatedPost ? "javascript:void(0)" :
                                    redirectToProfile(
                                        this.props.item.userCustomUrl
                                    )
                                }
                            >
                                <img
                                    src={this.props.item.curatedPost ? "https://cdn.dscovr.com/images/welogoNew.webp" :
                                        this.props.item.userProfileImage &&
                                            this.props.item.userProfileImage !== ""
                                            ? getCDNUrl(
                                                this.getOptimizedImage(this.props.item)
                                            )
                                            : this.props.item.userType !== "COMPANY"
                                                ? "https://cdn.dscovr.com/images/users1.png"
                                                : "https://cdn.dscovr.com/images/DefaultBusiness.png"
                                    }
                                    alt=""
                                />
                            </figure>
                        ) : (
                            <figure
                                style={{ cursor: "pointer" }}
                                className="complogo"
                                onClick={(e) => {
                                    e.preventDefault();
                                    redirectToCircle(
                                        this.props.item.params &&
                                        this.props.item.params["circleSlug"]
                                    );
                                }}
                            >
                                <img
                                    src={
                                        this.props.item.params &&
                                            this.props.item.params["circleImage"] !=
                                            null
                                            ? getCDNUrl(
                                                this.props.item.params &&
                                                this.props.item.params[
                                                "circleImage"
                                                ]
                                            )
                                            : "https://cdn.dscovr.com/images/circleDefault.webp"
                                    }
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                            "https://cdn.dscovr.com/images/circleDefault.png";
                                    }}
                                    alt=""
                                />
                            </figure>
                        )}
                        <div style={{ paddingRight: '0' }}>
                            {/* {!this.props.item.userEntityType ? (
                                <h5 className= {"compuser" + (this.props.item.userType === "COMPANY" ? " orgnzName" : "")}
                                    onClick={(e) => this.props.item.curatedPost ? "javascript:void(0)" :
                                        redirectToProfile(
                                            this.props.item.userCustomUrl
                                        )
                                    }
                                    style={{ cursor: 'pointer' }}>{this.props.item.curatedPost ? "EDITOR'S DESK" : this.props.item.userName}</h5>
                            ) : (
                                <h5
                                    className="compuser"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToCircle(
                                            this.props.item.params &&
                                            this.props.item.params["circleSlug"]
                                        );
                                    }}
                                >
                                    {this.props.item.params["circleTitle"]}
                                </h5>
                            )} */}
                            <CommonHashTagUserSection  item = {this.props.item} />
                            <div className="d-flex hpostdtls">
                                {this.props.item && this.props.item.country &&
                                    <span className="address"><i
                                        className="iLocation" />{this.props.item && this.props.item.country}</span>}
                                {this.props.item.curatedPost ?
                                    <span className="designation">
                                        {getFormattedDate(
                                                this.props.item.createTime
                                            )}
                                    </span> : 
                                    <span className="designation" style={{ cursor: 'pointer' }}>
                                    <Link to={"/post/" + this.props.item.id}>
                                        {getFormattedDate(
                                            this.props.item.createTime
                                        )}
                                    </Link>
                                </span>}
                                {this.state.loginSuccess === true ? ((this.props.item.connectDepth && this.props.item.userId != this.state.userData.userId) ?
                                    <MutualConnect connectDepth={this.props.item.connectDepth}></MutualConnect> : null) : null}
                                {this.state.loginSuccess === true && (this.props.item.connectDepth !== 1 && this.props.item.connectDepth !== 2) ? !this.props.item.userEntityType ? (this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === "PENDING_CONNECT" ?
                                    <>
                                        <NavLink className="follow follow-link active" to="/connected" data-tip="msg to show" data-place='bottom' data-for={'Pending' + this.props.item.userId}><i className="iTickedUser" /> Pending</NavLink>
                                    </>
                                    : this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === 'REQUEST_RECEIVED' ?
                                        <>
                                            <NavLink className="follow follow-link active" to="/manage-invitation" data-tip="msg to show" data-place='bottom' data-for={'Pending' + this.props.item.userId}><i className="iTickedUser" /> Pending</NavLink>
                                        </>
                                        : this.props.item.userConnectStatus && this.props.item.userConnectStatus.connectStatus === 'DEADLOCK_PENDING_STATUS' ?
                                            <></>

                                            : this.props.item && (!this.props.item.connectDepth || (this.props.item.connectDepth && this.props.item.connectDepth < 1)) && !this.props.item.curatedPost &&
                                            <Follow followed={this.props.item.followed} userId={this.props.item.userId} deactivated={this.props.item.deactivated} />
                                ) : /*((this.state.loginSuccess === true && this.props.item.userEntityType != null && this.props.item.userEntityType === "CIRCLE") ?
                                    <EntityFollow entityType={'CIRCLE'} entityId={this.props.item.params['circleId']}
                                        followed={this.props.item.followed}
                                        userId={this.state.userData.userId}
                                        isCircle={true} /> : null)*/ null : null
                                }
                            </div>
                        </div>
                    </div>
                    {this.props.pageType === "BOOKMARK" && 
                        <SavePostButton key={this.props.item.id} id={this.props.item.id} bookMarkFlag={true} entityType={"POST"} onSuccessBookmarkPost={this.props.onSuccessUnsaveBookmarkPost} pageType={this.props.pageType} />
                    }                    
                    <h4>
                        {this.props.item.hashTags && this.props.item.hashTags.map((hashTag, index) => {
                            return <span>
                                <a href={'/explore-hashtag/' + hashTag} className="hashLinkPost">{hashTag}</a>
                            </span>
                        })
                        }
                    </h4>
                    <h3>
                        {this.props.item.curatedPost ? 
                        <a
                            onClick={(e) =>
                            this.handleDetailPage(
                                e,
                                "/explore-detail/blog/" +
                                this.props.item.slug,
                                this.props.item.id
                            )
                            }
                            href="javascript:void(0)"
                        >
                            {this.props.item.title}
                      </a> : 
                        <Link to={"/post/" + this.props.item.id}>
                            {this.props.item.title}
                        </Link>}
                    </h3>
                    <div className="descinn">
                        {/*<Link to={"/post/" + this.props.item.id}>*/}
                        <ResponsiveEllipsis
                            unsafeHTML={linkifiedHtml}
                            maxLine="4"
                            ellipsis='...'
                            trimRight
                            basedOn='words'
                        />
                        {/*</Link>*/}
                    </div>
                </div>
                {this.state.loginSuccess && this.props.pageType === "PROFILE_BLOG" &&
                    <div className="postbookMbott">
                        <div className="w-100">
                        <UserReactionComponent item={this.props.item} key={this.props.item.id} />
                        <ExploreCommentSection item={this.props.item} userEntityCount={this.props.userEntityCount} />
                        <Share
                            shareUrl="/post/" hideShareOnPortalBtn={this.props.item.postVisibility !== "ANYONE"}
                            key={this.props.item.id}
                            entityId={this.props.item.id}
                            entityType={"POST"}
                            shareId={this.props.item.id}
                            sharesCount={this.props.item.sharesCount}
                        />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default CommonHashTagsBlog;