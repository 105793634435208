import React, {Component} from 'react';
import {getCDNUrl} from "../commons/CommonFunctions";
import stripHtml from "string-strip-html";

var dateFormat = require('dateformat');

class FeedSearchItem extends Component {

    getOptimizedImage(personalInfo) {
        if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
            return personalInfo.resizedProfileImages['200x200'];
        }
        return personalInfo.userProfileImage;
    }

    getFeedOptimizedImage(data) {
        if (data.resizedImages && data.resizedImages['300x300'] != null) {
            return data.resizedImages['300x300'];
        }
        return data.attachmentUrl;
    }

    redirect = () => {
        document.body.classList.remove('searchDropActive');
        window.location.href = '/post/' + this.props.item.postId
    }

    renderTaggedUsers = (text) => {
        if (!text || text.length === 0) {
            return;
        }
        text = text.split("@@@__").join("<a href=\"/profile/");
        text = text.split("^^__").join("\">")
        text = text.split("&&__").join("\">")
        text = text.split("&amp;&amp;__").join("\">")
        text = text.split("&amp;&amp;__").join("\">")
        text = text.split("@@@^^^").join("</a>");
       text = text.split("###__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/explore-hashtag/");
        text = text.split("&&__").join("<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/explore-hashtag/");
        text = text.split("###^^^").join("</a>");
        return text;
    }

    render() {

        let description = this.props.item.description;

        if (description) {
            description = stripHtml(this.renderTaggedUsers(this.props.item.description));
        }
        return (
            <li>
                <a href="javascript:void(0)" onClick={this.redirect}>
                    <div className="srchpostmain">
                       {/* <figure className="users">
                            {
                                this.props.item.attachmentIds && this.props.item.attachmentIds.length > 0
                                    ? (this.props.item.attachmentIds[0].attachmentType === 'AUDIO'
                                        ? <i className="iRecorder"/>
                                        : (this.props.item.attachmentIds[0].attachmentType === 'VIDEO'
                                                ? <img
                                                    src={(this.props.item.attachmentIds[0].thumbnails
                                                        && this.props.item.attachmentIds[0].thumbnails.length > 0)
                                                        ? this.props.item.attachmentIds[0].thumbnails[0]
                                                        : <i className="iVid"/>}
                                                    alt=""/>
                                                :
                                                <img src={this.getFeedOptimizedImage(this.props.item.attachmentIds[0])}
                                                     alt=""/>
                                        )
                                    )
                                    : (this.props.item.postType === 'LINK'
                                    ? <i className="iGlobe"/>
                                    : <i className="iTxEdi__AddQuote"/>)
                            }
                        </figure>*/}
                        <figure className="users"><img
                            src={this.props.item.userProfileImage ?
                                getCDNUrl(this.getOptimizedImage(this.props.item)) : (this.props.item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                            alt=""/>
                        </figure>
                        <div>
                            {/*<h5>{description && description.length <= 30
                                ? description
                                : (description.substring(0, 30) + "...")}</h5>*/}
                                <h5>{this.props.item.userName}</h5>
                            <div className="userblk">

                                {!this.props.item.userEntityType ?
                                    <>
                                        {/*<figure className="userimg"><img
                                            src={this.props.item.userProfileImage ?
                                                getCDNUrl(this.getOptimizedImage(this.props.item)) : (this.props.item.userType !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png")}
                                            alt=""/>
                                        </figure>*/}
                                        {/*<div>{this.props.item.userName}</div>*/}
                                        <div>{description && description.length <= 30
                                            ? description
                                            : (description.substring(0, 30) + "...")}</div>
                                    </>
                                    :
                                    this.props.item && this.props.item.params ?
                                        <>
                                            <figure className="userimg"><img
                                                src={this.props.item.params['circleImage'] != null
                                                    ? getCDNUrl(this.props.item.params && this.props.item.params['circleImage'])
                                                    : "https://cdn.dscovr.com/images/circleDefault.webp"}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = "https://cdn.dscovr.com/images/circleDefault.png"
                                                }}
                                                alt=""/>
                                            </figure>
                                            <div>{this.props.item.params['circleTitle']}</div>
                                        </> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="srchposttime">
                        <span>
                            {
                                this.props.item.attachmentIds && this.props.item.attachmentIds.length > 0
                                    ? (this.props.item.attachmentIds[0].attachmentType === 'AUDIO'
                                        ? <i className="iRecorder"/>
                                        : (this.props.item.attachmentIds[0].attachmentType === 'VIDEO'
                                                ? <i className="iVid"/> :
                                                this.props.item.attachmentIds[0].attachmentType === 'PPT' || this.props.item.attachmentIds[0].attachmentType === 'PDF'
                                                ? <i className={this.props.item.attachmentIds[0].attachmentType === 'PPT' ? "iPPT_New" : "iPDF"} style={{fontSize: '20px'}} />
                                                : <i className="iCamera"/>
                                        )
                                    )
                                    : (this.props.item.postType === 'LINK'
                                    ? <i className="iLink"/>
                                    : <i className="iPenEdit"/>)
                            }
                        </span> {dateFormat(this.props.item.createTime, "dd mmm yyyy")}
                    </div>
                </a>
            </li>
        );
    }
}

export default FeedSearchItem;
